import React, { Component, useState, useEffect } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip1 from 'react-bootstrap/Tooltip';
import emptylist from "../media/undraw_no_data_re_kwbl.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

const Auth = localStorage.getItem("Auth");
const org = localStorage.getItem("org");

function IntegrationLab() {


  const [errorMessage, setErrorMessage] = useState("");

  const [apisetup, setapisetup] = useState({
    integration_name: "",
    url: "",
    method: "GET",
    headers: {},
    body_type: "None",
    body: {},
    variable_names: [],
    varialbe_and_value: {},
  });

  const [apiresponse, setapiresponse ] = useState({
    Response_code: "",
  });

  const [getapi, setgetapi ] = useState([]);

  const [headerKey, setHeaderKey] = useState("");
  const [headerValue, setHeaderValue] = useState("");

  const [process_options, setProcess_options] = useState({
    lead : [],
    call : [],
    task : [],
    api : []
  }) 

  console.log(process_options,'process_options');

  function getIntegrationNames(integrations) {
    return integrations.map(integration => integration.integration_name);
}

function getScriptNames(scriptnames) {
  return scriptnames.map(scriptname => scriptname.script_type_name);
}


function getTaskNames(tasknames) {
  return tasknames.map(taskname => taskname.heading);
}


  const tostMsg = (item) => {
    toast.success(item, {
      position: "bottom-left",
      autoClose: 1000,
    });
  };


  const addHeaderData = () => {
    if (headerKey && headerValue) {
      setapisetup((prevSetup) => ({
        ...prevSetup,
        headers: {
          ...prevSetup.headers,
          [headerKey]: headerValue,
        },
      }));
      setHeaderKey("");
      setHeaderValue("");
    }
  };

  const removeHeaderData = (key) => {
    setapisetup((prevSetup) => {
      const updatedHeaders = { ...prevSetup.headers };
      delete updatedHeaders[key];
      return {
        ...prevSetup,
        headers: updatedHeaders,
      };
    });
  };





  //variable in the api///

  const [fields, setFields] = useState([]);

  console.log(fields, 'fields');


  const renderTooltip1 = (props) => (
    <Tooltip1 id="button-Tooltip1" {...props}>
      {props}
    </Tooltip1>
  );


  const handleAddField = () => {
    setFields(prevFields => [...prevFields, { showValue: false, type: "text" }]);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
  };

  const handleCheckboxChange = (index, checked) => {
    const updatedFields = [...fields];
    updatedFields[index].showValue = checked;
    setFields(updatedFields);
  };

  const handleClearField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(fields);
  };


  function changeApiSetup(e) {
    setapisetup({ ...apisetup, [e.target.name]: e.target.value });
  }



  /////////////////////////edit body object////////////////////////////

  const [bodyKey, setBodyKey] = useState("");
  const [bodyValue, setBodyValue] = useState("");


  function prettifyApiResponse(response) {
    try {
        // Parse the JSON response
        const parsedResponse = JSON.parse(response);

        // Prettify the JSON with indentation of 2 spaces
        const prettifiedResponse = JSON.stringify(parsedResponse, null, 2);

        // Return the prettified response
        return prettifiedResponse;
    } catch (error) {
        // If parsing fails, return the original response
        console.error('Error parsing JSON:', error);
        return response;
    }
}

  const addBodyData = () => {
    if (bodyKey && bodyValue) {
      setapisetup((prevSetup) => ({
        ...prevSetup,
        body: {
          ...prevSetup.body,
          [bodyKey]: bodyValue,
        },
      }));
      setBodyKey("");
      setBodyValue("");
    }
  };

  const removeBodyData = (key) => {
    setapisetup((prevSetup) => {
      const updatedBody = { ...prevSetup.body };
      delete updatedBody[key];
      return {
        ...prevSetup,
        body: updatedBody,
      };
    });
  };

  
  function mergeObjects(labelType, labelValue) {

    console.log(labelValue);

    const output = [];
  
    for (const [label, type] of Object.entries(labelType)) {
      const obj = { showValue: false, label, type };
  
      if (labelValue[label]) {
        obj.showValue = true;
        obj.value = labelValue[label];
      }
  
      if (obj.showValue || obj.value !== null) {
        output.push(obj);
      }
    }
  
    return output;
  }

  

  function selectApi(integrationName){

    

    for (let i = 0; i < getapi.length; i++) {

      console.log(i);

      if (getapi[i].integration_name == integrationName) {

        //////////////Set api values///////////////////

        setapisetup({integration_name: getapi[i].integration_name,
        url: getapi[i].url,
        method: getapi[i].method,
        headers: getapi[i].headers,
        body_type: getapi[i].body_type,
        body: getapi[i].body,
        variable_names: [],
        varialbe_and_value: {},
        automatic : false,
        automatic_config : {},
        after_process : false, 
        set_process_type: "",   
        set_process_name: "" });

        setFields(mergeObjects(getapi[i].variable_names, getapi[i].varialbe_and_value));
      }

    }
    
  }


  function deleteApi(integrationName){


    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .put('/Delete_Save_Integration',
      {  "integration_name": integrationName },
       { headers })
      .then((data) => {

        if (data.status == "200") {
          getAllApi();
        }       

      })
      .catch((err) => console.log(err));


  }

  function testapi( ) {


    const filteredArray1 = {};

    for (let i = 0; i < fields.length; i++) {
      const obj = fields[i];
  
      if (obj.showValue) {
        
        filteredArray1[obj.label] = obj.value;
      }
    }

    

  const formattedArray2 = {};

  for (let i = 0; i < fields.length; i++) {
    const obj = fields[i];

    formattedArray2[obj.label] = obj.type;
  }




    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/integration_lab',
      {
        integration_name: apisetup.integration_name,
        url: apisetup.url,
        method: apisetup.method,
        headers: apisetup.headers,
        body_type: apisetup.body_type,
        body: apisetup.body,
        variable_names: formattedArray2,
        varialbe_and_value: filteredArray1,
      }
      , { headers })
      .then((data) => {
        setapiresponse({
          Response_code: data.status,
          Response: data.data,
        });
       
        setErrorMessage(data.data.message);
      })
      .catch((data) => setapiresponse({
        Response_code: data.status,
        Response: data.data,
      }));
    
    
  }

  function newapi(){

    setapisetup({
      integration_name: "",
      url: "",
      method: "GET",
      headers: {},
      body_type: "None",
      body: {},
      variable_names: [],
      varialbe_and_value: {},
    });

    setFields([]);

  }

  function saveapi( ){


    const filteredArray1 = {};

  for (const obj of fields) {
    if (obj.showValue === true && obj.label && obj.value) {
      filteredArray1[obj.label] = obj.value;
    }

  }

 

  const formattedArray2 = {};

  for (const obj of fields) {
    if (obj.label && obj.type) {
      formattedArray2[obj.label] = obj.type;
    }
   
  }



    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/To_Save_Intigration_Info',
      {
        integration_name: apisetup.integration_name,
        url: apisetup.url,
        method: apisetup.method,
        headers: apisetup.headers,
        body_type: apisetup.body_type,
        body: apisetup.body,
        variable_names: formattedArray2,
        varialbe_and_value: filteredArray1,
      }
      , { headers })
      .then((data) => {
       

        if (data.status == "200") {
          
          tostMsg();
          getAllApi();


        }

       
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
    
    
  }
   

  function getAllApi(){


    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .get('/Get_Saved_Intigration',
       { headers })
      .then((data) => {
       
        setgetapi(data.data);

        const integrationNames = getIntegrationNames(data.data);
        
        setProcess_options(prevState => ({
          ...prevState,
          api: integrationNames
        }));


        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));


  }


  function getScript(){

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`, 
    };

    axios.get(`/get_all_script_types?orgID=${org}`, {headers} ).then((data)=>{

    const scriptNames = getScriptNames(data.data);

    setProcess_options(prevState => ({
      ...prevState,
      call: scriptNames
    }));

    }).catch()

  }

  function getTask() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/GetTaskHeadings?organisationID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          
          const taskNames = getTaskNames(data.data);

          setProcess_options(prevState => ({
            ...prevState,
            task: taskNames
          }));


        }
      })
      .catch((err) => console.log(err));
  }

  function getSops() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {


          setProcess_options(prevState => ({
            ...prevState,
            lead: data.data
          }));
          
         
        }
      })
      .catch((err) => console.log(err));
  }


  
  useEffect(() => {
    getSops();
    getAllApi();
    getScript();
    getTask();
  }, [1]);


  return (
    <>
      

      <div className="container-fluid">
<div className="row">

<div className="col-3 border-end">
<button className="btn bg-dark text-white border-dark pop" onClick={()=>newapi()} type="button">
<i className="bi bi-plus-circle"></i> Add Integration
  </button>
  <br/><br/>
  <mark>
   <i className="bi bi-view-list"></i> List of Integrations
                  </mark>
                  <br/><br/>
<ol className="list-group">
{getapi &&
                        getapi?.map((item) => (
                          <li className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark">
                          <div className="ms-2 me-auto">
                            {item.integration_name}
                          </div>
                         <i
                            title="View"
                            className="bi bi-eye-fill"
                           onClick={()=>selectApi(item.integration_name)}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>
                          <i
                            title="Delete"
                            className="bi bi-trash3-fill"
                            onClick={()=>deleteApi(item.integration_name)}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                           
                          ></i>
                        </li>
                        ))}

</ol>
{getapi ==''?<div align='center'><br/><br/><br/><br/><img
          src={emptylist}  height={120}
        /><p><br/>Apps not available</p></div> :null}

    </div>
    <div className="col-6">
    <h4><i className="bi bi-cpu"></i> Integration Lab <span className="badge bg-danger">beta</span></h4> 
    <p className="text-secondary">Integrate APIs with Sales Sunday Middle Wear - SSMW </p>
    <br/> 
    <div className="row">
    <div className="col-11">
        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Integration Name *
  </label>
  <input
    type="text" 
    name="integration_name" value={apisetup.integration_name} onChange={changeApiSetup}
    className="form-control form-control-sm border-dark"
    id="exampleFormControlInput1"
    placeholder="Email Service - XYZ"
  />
</div>

        </div>
    </div>
      <div className="row">
        <div className="col-3">
        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Method * 
  </label>
  <select className="form-select form-select-sm border-dark" name="method" value={apisetup.method} onChange={changeApiSetup} aria-label="Default select example">
  <option value="GET">GET</option>
  <option value="POST">POST</option>
  
</select>

</div>

        </div>
        <div className="col-8">
        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1"  className="form-label">
  Target URL* (Add query parameter below.)
  </label>
  <input
    type="url" 
    name="url" value={apisetup.url} onChange={changeApiSetup}
    className="form-control form-control-sm  border-dark"
    id="exampleFormControlInput1"
    placeholder="https://"
  />
</div>

        </div>
        
        
        <div>
          <p>Add custom values to be passed in the api <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('This variables could be added to your api body, If your api is GET method this variables will be used as query parameter automatically.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></p>
      <button onClick={handleAddField} type="button" className="btn btn-dark btn-sm">
      <i className="bi bi-plus-circle-fill"></i> Add Variables
</button>


      
      <form onSubmit={handleSubmit}>
      <br/>
        {fields?.map((field, index) => (
          <div key={index}>
       
            <div className="mb-3">

<div className="row">
            <div className="col-4"> 
  <input
    type="text" 
    value={field.label || ''}
    onChange={(event) => handleFieldChange(index, 'label', event.target.value)}
    className="form-control form-control-sm border-dark"
    id="exampleFormControlInput1"
    placeholder="Label"
  /></div>

<div className="col-2"> 
   
   <select className="form-select form-select-sm border-dark" value={field.type || ''} onChange={(event) => handleFieldChange(index, 'type', event.target.value)} aria-label="Default select example">
  <option value="text">Text</option>
  <option value="number">Number</option>
  <option value="email">Email</option>
  <option value="date">Date</option>
  <option value="textarea">Textarea</option>
  <option value="url">Url</option>
  <option value="password">Password</option>
  <option value="week">Week</option>
  
</select>

 </div>


 <div className="col-3"> <label>
              <input
                type="checkbox"
                checked={field.showValue}
                onChange={(event) => handleCheckboxChange(index, event.target.checked)}
              />
              {" "}Set Value
            </label></div>


            {field.showValue && (
              <div className="col-2"> 
              <input
              type="text" 
              value={field.value || ''}
              onChange={(event) => handleFieldChange(index, 'value', event.target.value)}
              className="form-control form-control-sm border-dark"
              id="exampleFormControlInput1"
              placeholder="Value"
            />
            </div>
             
            )}

<div className="col-1">
<button type="button" className="btn btn-dark btn-sm" onClick={() => handleClearField(index)}>
<i className="bi bi-x-lg"></i>
            </button>
</div>
</div>
</div>
           
          </div>
        ))}
      </form>
    </div>

    <div className="container">
    <br/>
  <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        className="nav-link active"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#home"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
      >
        Header
      </button>
    </li>
{
  apisetup.method == 'POST'?<li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#profile"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
      >
        Body
      </button>
    </li>:null
}

    
  </ul>
  <div className="tab-content" id="myTabContent">
    <div
      className="tab-pane fade show active"
      id="home"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
       <div className="col-11">
        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
    <br/>
  Header * (Set header in array form)
  </label>
  <div>
    <div className="row">
    <div className="col-4"><input
        type="text"
        value={headerKey}
        className="form-control form-control-sm  border-dark"
        onChange={(e) => setHeaderKey(e.target.value)}
        placeholder="Header Key"
      /></div>
    <div className="col-4">
    <input
        type="text"
        value={headerValue}
        className="form-control form-control-sm  border-dark"
        onChange={(e) => setHeaderValue(e.target.value)}
        placeholder="Header Value"
      />
    </div>
    <div className="col-1">
    <button className="btn btn-dark btn-sm" onClick={addHeaderData}><i className="bi bi-plus"></i></button>
    </div>
    </div>
    
      
     
     
      <div>
        <br/>
        <div className="card border border-dark">
        <div className="card-header">
          Header
        </div>
        <div className="card-body">
        {Object.entries(apisetup.headers)?.map(([key, value]) => (
          <div key={key}>
            {key}: {value}
            <button type="button" className="btn btn-transperent btn-sm"  onClick={() => removeHeaderData(key)}><i className="bi bi-x-circle-fill"></i></button>
          </div>
        ))}
        </div>
        
        </div>
   
      </div>

    </div>

</div>

        </div>
    </div>
    {apisetup.method == 'POST'?<div
      className="tab-pane fade"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
<br/>
<div className="col-3">
        
  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Body Method * 
  </label>
  <select className="form-select form-select-sm border-dark" name="body_type" value={apisetup.body_type} onChange={changeApiSetup} aria-label="Default select example">
  <option value="None">None</option>
  <option value="x-www-form-urlencoded">x-www-form-urlencoded</option>
  <option value="JSON">JSON</option>
</select>

</div>
        </div>
        {apisetup.body_type == 'None' || apisetup.body_type == ''?null
        :<div className="col-11">
        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
   Body <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1("All your variables are listed below add variables in this format '{Name}'")}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
  </label>
  <br/>
  <p><b>Variables:{" "}</b>  {fields &&
                          fields?.map((item) => (
                            <><span className="badge bg-primary">{item.label}</span>{" "}</>
                          ))}</p>

              <div className="row">
              <div className="col-4"><input
        type="text"
        className="form-control form-control-sm  border-dark"
        value={bodyKey}
        onChange={(e) => setBodyKey(e.target.value)}
        placeholder="Body Key"
      /></div>
      <div className="col-4">
      <input
        type="text"
        className="form-control form-control-sm  border-dark"
        value={bodyValue}
        onChange={(e) => setBodyValue(e.target.value)}
        placeholder="Body Value"
      />
      </div>
      <div className="col-1">
      <button className="btn btn-dark btn-sm" onClick={addBodyData}><i className="bi bi-plus"></i></button>
      </div>  
    </div>   
    <br/>
    <div className="card border border-dark">
      <div className="card-header">
          Body
        </div>
        <div className="card-body">

        {Object.entries(apisetup.body)?.map(([key, value]) => (
          <div key={key}>
            {key}: {value}
            <button className="btn btn-transperent btn-sm" onClick={() => removeBodyData(key)}><i className="bi bi-x-circle-fill"></i></button>
          </div>
        ))}
        </div>
      </div>  

</div>

        </div>}
      

    </div>:null
    }
    
  </div>
</div>

<div className="row mb-2">
<div className="col-12"><div className="form-check form-switch">
  <input
    className="form-check-input"s
    type="checkbox"
    id="flexSwitchCheckChecked"
    defaultChecked=""
  />
  <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
  Do you want to add after api call process
  </label>
</div></div>
</div>

<><div className="row">
        <div className="col-4">
        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Process type:
  </label>
  <select className="form-select form-select-sm border-dark" name="set_process_type" value={apisetup.set_process_type} onChange={changeApiSetup} aria-label="Default select example">
  <option value="view">View</option>
  <option value="lead">Add lead</option>
  <option value="call">Add call</option>
  <option value="task">Add task</option>
  <option value="api">Call api</option>
  
</select>

</div>

        </div>
        <div className="col-5">
          {apisetup.set_process_type == 'view' || apisetup.set_process_type == '' || apisetup.set_process_type == null?null:<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Process category:
  </label>
  <select className="form-select form-select-sm border-dark" name="set_process_name" value={apisetup.set_process_name} onChange={changeApiSetup}  aria-label="Default select example">
  <option>Select process category</option>
  {process_options[apisetup.set_process_type]?.map((item)=>(<option value={item}>{item}</option>))}
  
</select>

</div>}
        
        </div>
        
        </div>
        <div className="row">
          <div className="col-11">
          <div className="card border border-dark mb-2">
      <div className="card-header">
          Set value path:
        </div>
        <div className="card-body">

        
        </div>
      </div>  
      <br/>
          </div>
        </div>

        
        </>

        

<div className="d-grid gap-2 d-md-block">
<button onClick={testapi} className="btn btn-light btn-sm border-dark pop" type="button">
  <i className="bi bi-gear"></i> Test API
  </button>
  &nbsp;&nbsp;&nbsp;
  <button onClick={saveapi} className="btn btn-dark btn-sm pop" type="button">
  <i className="bi bi-cloud-arrow-up"></i> Save API
  </button>
</div>





      </div>
<br/><br/>


    </div>
    <div className="col-3">
<br/>


<div className="card shadow rounded-3 border border-dark">


<span class="position-absolute top-0 start-50 translate-middle p-2 badge bg-info text-dark border border-dark">DEBUG</span>

<div className="card-header">User Interface</div>
  <div className="card-body">
    <h5><b>{apisetup.integration_name}</b></h5>



{fields &&
                        fields?.map((item) => (
                           <>{
                            item.showValue == false?<>
                           <div className="mb-3">
  <label htmlFor="exampleInputEmail1" className="form-label">
    {item.label}
  </label>
  <input
    type={item.type}
    className="form-control form-control-sm"
    id="exampleInputEmail1"
    aria-describedby="emailHelp"
  />
</div>
                            </>
                            :null
                           }</>
                        ))}

<button onClick={testapi} className="btn btn-dark btn-sm border-dark pop" type="button">
  Submit
  </button>
  
  </div>
  
</div>
<br/>

<div className="card shadow rounded-3 border border-dark bg-dark text-light">
  <div className="card-header">API Test Response</div>
  <div className="card-body"><h1><b>{apiresponse.Response_code}</b></h1>
  <p className="text-secondary">Response code</p>

  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "600px" }}
              ><p>

              {prettifyApiResponse(apiresponse.Response)}
              
            </p></div>
  
  </div>
  
</div>
<br/>






    </div>

    </div>

    </div>
    <ToastContainer />
    </>
  );
}


export default IntegrationLab;
