import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import phoneimg from "../media/undraw_phone_call_re_hx6a.svg";
import emailimg from "../media/undraw_envelope_re_f5j4.svg";


function DatabasedAction({ input }) {
    const [modalShowAction, setModalShowAction] = useState(false);
    const [actionType, setActionType] = useState('');
    const imageRef = useRef(null);
    const videoRef = useRef(null);
    const audioRef = useRef(null);

    function checkStringType(input) {
        const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
        const imagePattern = /\.(jpeg|jpg|gif|png|bmp|webp)(\?.*)?$/i;
        const audioPattern = /\.(mp3|wav|ogg|m4a|flac)(\?.*)?$/i;
        const videoPattern = /\.(mp4|avi|mov|wmv|flv|webm|mkv)(\?.*)?$/i;
        const pdfPattern = /\.pdf(\?.*)?$/i;
        const phonePattern = /^\+?[1-9]\d{10,14}$/; // E.164 format for phone numbers with 10-15 digits
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

        if (emailPattern.test(input)) {
            return 'email';
        } else if (phonePattern.test(input)) {
            return 'phone';
        } else if (youtubePattern.test(input)) {
            return 'video';
        } else if (urlPattern.test(input)) {
            if (imagePattern.test(input)) {
                return 'image';
            } else if (audioPattern.test(input)) {
                return 'audio';
            } else if (videoPattern.test(input)) {
                return 'video';
            } else if (pdfPattern.test(input)) {
                return 'pdf';
            } else {
                return 'url';
            }
        } else {
            return null;
        }
    }

    const detectedActionType = checkStringType(input);

    function handleClick() {
        setActionType(detectedActionType);
        setModalShowAction(true);
    }

    function handleFullScreen() {
        if (actionType === 'image' && imageRef.current) {
            if (imageRef.current.requestFullscreen) {
                imageRef.current.requestFullscreen();
            } else if (imageRef.current.mozRequestFullScreen) { // Firefox
                imageRef.current.mozRequestFullScreen();
            } else if (imageRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                imageRef.current.webkitRequestFullscreen();
            } else if (imageRef.current.msRequestFullscreen) { // IE/Edge
                imageRef.current.msRequestFullscreen();
            }
        } else if (actionType === 'video' && videoRef.current) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.mozRequestFullScreen) { // Firefox
                videoRef.current.mozRequestFullScreen();
            } else if (videoRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) { // IE/Edge
                videoRef.current.msRequestFullscreen();
            }
        }
    }

    return (
        <div>
            <p>
                {detectedActionType && (
                    <div onClick={handleClick}>
                        <button type="button" className="btn btn-transparent btn-sm">
                            <i className={`bi bi-${detectedActionType === 'email' ? 'envelope' : 
                                           detectedActionType === 'phone' ? 'phone-fill' : 
                                           detectedActionType === 'image' ? 'image' : 
                                           detectedActionType === 'audio' ? 'file-music-fill' : 
                                           detectedActionType === 'video' ? 'camera-reels-fill' : 
                                           detectedActionType === 'pdf' ? 'filetype-pdf' : 
                                           'link-45deg'}`}></i>
                        </button>
                    </div>
                )}
            </p>
            <Modal
                show={modalShowAction}
                onHide={() => setModalShowAction(false)}
                size='xl'
                backdrop="static"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {actionType === 'email' && <><i className="bi bi-envelope"></i> Email View</>}
                        {actionType === 'phone' && <><i className="bi bi-phone-fill"></i> Phone View</>}
                        {actionType === 'image' && <><i className="bi bi-image"></i> Image View</>}
                        {actionType === 'audio' && <><i className="bi bi-file-music-fill"></i> Audio View</>}
                        {actionType === 'video' && <><i className="bi bi-camera-reels-fill"></i> Video View</>}
                        {actionType === 'pdf' && <><i className="bi bi-filetype-pdf"></i> PDF View</>}
                        {actionType === 'url' && <><i className="bi bi-link-45deg"></i> URL View</>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {actionType === 'email' && <><div className='row'>
                            <div className='col-8'>
                                <div className='container'>
                                    <div align='center'>
                                        <img src={emailimg} ref={imageRef} className="img-fluid" style={{ height: '300px', width: 'auto' }} alt="..." />
                                    </div>
                                    <br />
                                   
                                </div>
                            </div>
                            <div className='col-4' align='left'>
                                <p className='text-secondary'>Options: </p>
                                <a href={"https://mail.google.com/mail/?view=cm&fs=1&to=".concat(input)}><button className="btn btn-dark pop btn-sm me-md-2" onClick={handleFullScreen}>
                                <i className="bi bi-envelope"></i> Send Email 
                                        </button></a>
                               
                                <button className="btn btn-dark pop btn-sm position-relative" type="button" disabled>
            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
            Coming soon!
  <span className="visually-hidden">unread messages</span>
</span>
             <i className="bi bi-robot"></i> Compose Email
            </button>
                        
                            </div>
                        </div></>}
                        {actionType === 'phone' && <><div className='row'>
                            <div className='col-8'>
                                <div className='container'>
                                    <div align='center'>
                                        <img src={phoneimg} ref={imageRef} className="img-fluid" style={{ height: '300px', width: 'auto' }} alt="..." />
                                    </div>
                                    <br />
                                    
                                </div>
                            </div>
                            <div className='col-4' align='left'>
                                <p className='text-secondary'>Options: </p>
                                <a href={"tel:".concat(input)}><button className="btn btn-dark pop btn-sm me-md-2" onClick={handleFullScreen}>
                                <i className="bi bi-phone"></i> Make a Call 
                                        </button></a>
                              
                            </div>
                        </div></>}       
                {actionType === 'image' && <><div className='row'>
                            <div className='col-8'>
                                <div className='container'>
                                    <div align='center'>
                                        <img src={input} ref={imageRef} className="img-fluid" style={{ height: '500px', width: 'auto' }} alt="..." />
                                    </div>
                                    <br />
                                  
                                  
                                </div>
                            </div>
                            <div className='col-4' align='left'>
                                <p className='text-secondary'>Options: </p>
                                <button className="btn btn-dark pop btn-sm me-md-2" onClick={handleFullScreen}>
                                            <i className="bi bi-arrows-fullscreen"></i> Expand Image
                                        </button>
                                <a href={input} target="_blank" rel="noopener noreferrer">
                                    <button className="btn btn-dark pop btn-sm me-md-2" type="button">
                                        <i className="bi bi-link-45deg"></i> Visit link
                                    </button>
                                </a>
                                <a href={input} download="SalesSunday_Picture">
                                    <button className="btn btn-dark pop btn-sm" type="button">
                                        <i className="bi bi-download"></i> Download
                                    </button>
                                </a>
                        
                            </div>
                        </div></>}
                        {actionType === 'audio' && <>
<div className='row'>
<div className='col-8'>
<div className='container'>
<div align='center'>
<audio src={input} ref={audioRef} className="img-fluid" style={{ width: '100%' }} controls alt="..." />
</div>
<br />

</div>
</div>
<div className='col-4' align='left'>
<p className='text-secondary'>Options: </p>
<button className="btn btn-dark pop btn-sm me-md-2" onClick={handleFullScreen}>
<i className="bi bi-arrows-fullscreen"></i> Expand Audio
</button>
<a href={input} target="_blank" rel="noopener noreferrer">
<button className="btn btn-dark pop btn-sm me-md-2" type="button">
<i className="bi bi-link-45deg"></i> Visit link
</button>
</a>
<a href={input} download="SalesSunday_Audio">
<button className="btn btn-dark pop btn-sm" type="button">
<i className="bi bi-download"></i> Download
</button>
</a>
</div>
</div>

</>}
                    {actionType === 'video' && (
                        <div className='row'>
                            <div className='col-8'>
                                <div className='container'>
                                    <div align='center'>
                                        <video src={input} ref={videoRef} className="img-fluid" style={{ height: '500px', width: 'auto' }} controls alt="..." />
                                    </div>
                                    <br />
                                   
                                </div>
                            </div>
                            <div className='col-4' align='left'>
                                <p className='text-secondary'>Options: </p>
                                <button className="btn btn-dark pop btn-sm me-md-2" >
                                            <i className="bi bi-arrows-fullscreen"></i> Expand Video
                                        </button>
                                <a href={input} target="_blank" rel="noopener noreferrer">
                                    <button className="btn btn-dark pop btn-sm me-md-2" type="button">
                                        <i className="bi bi-link-45deg"></i> Visit link
                                    </button>
                                </a>
                                <a href={input} download="SalesSunday_Video">
                                    <button className="btn btn-dark pop btn-sm" type="button">
                                        <i className="bi bi-download"></i> Download
                                    </button>
                                </a>
                            </div>
                        </div>
                    )}
                    {actionType === 'pdf' && <>
                        <div className='row'>
    <div className='col-8'>
        <div className='container'>
            <div align='center'>
                <iframe src={input} className="img-fluid" style={{ height: '500px', width: '100%' }} title="PDF Viewer" />
            </div>
            <br />
           
        </div>
    </div>
    <div className='col-4' align='left'>
        <p className='text-secondary'>Options: </p>
    
        <a href={input} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-dark pop btn-sm me-md-2" type="button">
                <i className="bi bi-link-45deg"></i> Visit link
            </button>
        </a>
       
            <button className="btn btn-dark pop btn-sm position-relative" type="button" disabled>
            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
            Coming soon!
  <span className="visually-hidden">unread messages</span>
</span>
             <i className="bi bi-robot"></i> Ask question
            </button>
       
    </div>
</div>

                        </>}
                    {actionType === 'url' && (
                        <div align='center'>
                            <a href={input} target="_blank" rel="noopener noreferrer">{input}</a>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DatabasedAction;
