const initState = {
  home: {
    data: [],
    feed: [],
    status: {},
  },
  data: {},
  records: {},
  report: {},
  scriptLab: {},
  questions: {},
  traning: {},
  counter: {
    value: 0,
  },
  socket: {
    wsURL: null,
    isSocketActive: false,
  },
};

export default initState;
