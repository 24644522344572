import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from "axios";
import empty from "../media/undraw_server_cluster_jwwq.svg";

const Auth = localStorage.getItem("Auth");

const SearchLab = () => {

  const [filters, setFilters] = useState([]);
  const [currentLogic, setCurrentLogic] = useState('and');
  const [sort, setSort] = useState({ field: '', order: 'asc' });
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingFilter, setEditingFilter] = useState({ field: '', operator: '', value: '' });

  const [field, setField] = useState('');
  const [operator, setOperator] = useState('');
  const [value, setValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const [insertQuery, setInsertQuery] = useState('');
  const [querylist, setQuerylist] = useState([]);

  const [editQuery,seteditQuery] = useState('');

  const [selectedList, setSelectedList] = useState({
    dataBase: "leads",
    dataDisplay: {count:false,table:false},
  });


  const [dataOptions, setdataOptions] = useState({
   "leads" : [
    { name: 'contactPerson', type: 'string' },
    { name: 'title', type: 'string' },
    { name: 'value', type: 'number' },
    { name: 'currency', type: 'string' },
    { name: 'pipeline', type: 'string' },
    { name: 'pipelineCurrentStage', type: 'string' },
    { name: 'expectedClosingDate', type: 'Date' },
    { name: 'Lead_Source', type: 'string' },
    { name: 'owner', type: 'string' },
    { name: 'Description', type: 'string' },
    { name: 'visibleTo', type: 'string' },
    { name: 'phone', type: 'Array' },
    { name: 'email', type: 'Array' },
    { name: 'lead_status', type: 'string' },
    { name: 'organization_id', type: 'string' },
    { name: 'ownerId', type: 'string' },
    { name: 'TicketId', type: 'number' },
    { name: 'addedAt', type: 'Date' },
    { name: 'addedAtUTC', type: 'Date' }
  ],
   "calls" : [{ name: 'script_category', type: 'string' },
    { name: 'added_by_user', type: 'string' },
    { name: 'added_by_username', type: 'string' },
    { name: 'customer_name', type: 'string' },
    { name: 'contact_number', type: 'string' },
    { name: 'email_address', type: 'string' },
    { name: 'call_brief', type: 'string' },
    { name: 'description', type: 'string' },
    { name: 'call_status', type: 'string' },
    { name: 'call_again', type: 'boolean' },
    { name: 'call_again_date', type: 'string' },
    { name: 'next_call_user', type: 'string' },
    { name: 'next_call_username', type: 'string' },
    { name: 'next_call_script_category', type: 'string' },
    { name: 'contact_search_at', type: 'string' },
    { name: 'call_started_at', type: 'string' },
    { name: 'call_ended_at', type: 'string' },
    { name: 'added_at', type: 'string' },
    { name: 'TicketId', type: 'string' },
    { name: 'added_at_utc', type: 'Date' },
    { name: 'call_back_info', type: 'Object' }],
   "tasks" : [
    { name: 'added_by_user', type: 'string' },
    { name: 'added_by_username', type: 'string' },
    { name: 'customer_name', type: 'string' },
    { name: 'email_address', type: 'string' },
    { name: 'contact_number', type: 'string' },
    { name: 'taskHeading', type: 'string' },
    { name: 'added_at', type: 'string' },
    { name: 'added_at_utc', type: 'Date' },
    { name: 'description', type: 'string' },
    { name: 'assineTO_userID', type: 'string' },
    { name: 'assineTO_userName', type: 'string' },
    { name: 'lastDate', type: 'string' },
    { name: 'Contact_Source', type: 'string' },
    { name: 'TicketId', type: 'string' },
    { name: 'taskLastDate', type: 'Object' },
    { name: 'taskStatus', type: 'string' },
    { name: 'isActive', type: 'boolean' }
  ],
   "actions" : [],
  })

  const handleSelectbuildQuery = (e) => {
    setSelectedList((prevState) => ({
      ...prevState,
      dataBase: e.target.value,
    }));

    setFilters([]);
    setSort({});
  };

  const handleCheckboxbuildQuery = (e) => {
    const { name, checked } = e.target;
    setSelectedList((prevState) => ({
      ...prevState,
      dataDisplay: {
        ...prevState.dataDisplay,
        [name]: checked,
      },
    }));
  };
  

  const [errorMessage, setErrorMessage] = useState("");

  const handleFilterChange = useCallback(
    (filter) => {
      if (editingIndex !== null) {
        handleFilterEdit(editingIndex, filter);
      } else {
        setFilters((prevFilters) => [...prevFilters, { ...filter, logic: currentLogic }]);
      }
      setEditingIndex(null);
      setEditingFilter({ field: '', operator: '', value: '' });
    },
    [currentLogic, editingIndex]
  );

  const handleFilterEdit = useCallback((index, updatedFilter) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, i) => (i === index ? { ...updatedFilter, logic: currentLogic } : filter))
    );
  }, [currentLogic]);

  const startEditingFilter = useCallback((index) => {
    setEditingIndex(index);
    setEditingFilter(filters[index]);
    setField(filters[index].field);
    setOperator(filters[index].operator);
    setValue(filters[index].value);
  }, [filters]);

  const handleFilterDelete = useCallback((index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
    setEditingIndex(null);
  }, []);

  const handleSortChange = useCallback((event) => {
    const { name, value } = event.target;
    setSort((prevSort) => ({
      ...prevSort,
      [name]: value,
    }));
  }, []);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    handleFilterChange({ field, operator, value });
    setField('');
    setOperator('');
    setValue('');
  };

  const handleSortSubmit = (e) => {
    e.preventDefault();
    setSort({ field: sortField, order: sortOrder });
    setSortField('');
    setSortOrder('asc');
  };

  const buildQuery = useMemo(() => {
    const andConditions = [];
    const orConditions = [];

    filters.forEach(({ field, operator, value, logic }) => {
      const condition = { [field]: { [operator]: value } };

      if (logic === 'and') {
        andConditions.push(condition);
      } else if (logic === 'or') {
        orConditions.push(condition);
      }
    });

    const query = {};
    if (andConditions.length) {
      query.$and = andConditions;
    }
    if (orConditions.length) {
      query.$or = orConditions;
    }
    return query;
  }, [filters]);

  console.log(buildQuery,'buildQuery');

  const buildSort = useMemo(() => {
    const { field, order } = sort;
    return field ? { [field]: order === 'asc' ? 1 : -1 } : {};
  }, [sort]);
 

  function addToArray(value, array) {
    if (typeof value !== 'string' || !Array.isArray(array)) {
        throw new TypeError('Invalid input: Expected a string and an array.');
    }

    // Remove leading and trailing whitespace from the value
    const trimmedValue = value.trim();

    // Remove any empty values from the array
    array = array.filter(item => item.trim() !== '');

    // Check if the trimmed value is empty
    if (trimmedValue === '') {
        return array;
    }

    // Capitalize the first letter and make the rest lowercase
    const formattedValue = trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1).toLowerCase();

    // Check if the formatted value already exists in the array
    if (!array.includes(formattedValue)) {
        array.push(formattedValue);
    }

    return array;
}



function updateeditquery(item) {
  // Set the editQuery state to the name of the selected item
  seteditQuery(item.name);

  // Set the selected list state to the query list from the selected item
  setSelectedList(item.QueryList);

  // Set the filters state to the dataQuery from the selected item, if available
  // if (item.QueryList.dataQuery) {
  //   setFilters(item.QueryList.dataQuery.$and || item.QueryList.dataQuery.$or || []);
  // }

  // Set the sort state to the dataSort from the selected item, if available
  if (item.QueryList.dataSort) {
    const sortField = Object.keys(item.QueryList.dataSort)[0];
    const sortOrder = item.QueryList.dataSort[sortField] === 1 ? 'asc' : 'desc';
    setSort({ field: sortField, order: sortOrder });
  }
}

function addToQueryArray(value, array) {
  if (!value.name || value.name.trim() === "") {
      console.log("Name cannot be empty.");
      return array;
  }

  // Capitalize the first letter of the name
  value.name = value.name.charAt(0).toUpperCase() + value.name.slice(1);
  value.dataBase = "";
  value.dataDisplay = {count:false,table:false};
  value.dataLogic = "";
  value.dataQuery = "";
  value.dataSort = "";

  // Check if the name already exists in the array
  const nameExists = array.some(item => item.name === value.name);

  if (nameExists) {
      console.log("Duplicate name found. Cannot add to the array.");
      return array;
  }

  // Add the value to the array if no duplicate found
  array.push(value);
  return array;
}

function updateToQueryArray(value, array) {
  if (!value.name || value.name.trim() === "") {
      console.log("Name cannot be empty.");
      return array;
  }

  // Capitalize the first letter of the name
  value.name = value.name.charAt(0).toUpperCase() + value.name.slice(1);

  // Find the index of the existing object with the same name
  const existingIndex = array.findIndex(item => item.name === value.name);

  if (existingIndex !== -1) {
      // Replace the existing object with the new value
      array[existingIndex] = value;
      console.log("Existing name found. Replaced the value in the array.");
  } else {
      // Add the value to the array if no duplicate found
      array.push(value);
  }

  return array;
}


function addQuery(type) {

  let item;

  if(type=='insert'){
    

    item = (addToQueryArray(insertQuery, querylist));

  }else{

    let item1 = selectedList

    item1.dataQuery = buildQuery;

    item1.dataSort = buildSort;

    let updatetemp = {name:editQuery, QueryList:item1}

    item = (updateToQueryArray(updatetemp, querylist));

  }
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Auth}`,
  };
  axios
    .post("/searchquery/", 
      {'QueryList':item}, {headers}
    )
    .then((data) => {
      if (data.status == "200") {

        fetchquerys();
       
      }
      setErrorMessage(data.data.message);
    })
    .catch((err) => console.log(err));
}

  function fetchquerys() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/getsearchquery", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          
          if(data.data.status == 'not found'){
            setQuerylist([]);
          }else{
            setQuerylist(data.data.data.QueryList);
          }
          

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  useEffect(() => {
    fetchquerys();

  }, []);

  return (<>
  <div className="container-fluid">
  <blockquote className="blockquote mb-0">
  <p><i className="bi bi-search"></i> Search Lab</p>
</blockquote>
<p>Design serach query for custom tables and cards.</p>
<br/>
  <div className="row">
  <div className="col-2 border-end">
  <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                  
                  <button
                    type="button"
                    className={"btn btn-dark bg-gradient btn-sm" }
                    id="popsript"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseScript"
                    aria-expanded="false"
                    aria-controls="flush-collapseScript"
                  >
                    {" "}
                    <i className="bi bi-plus-circle-fill"></i> Add query
                  </button>

    
                </div>
                <br />
        <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item bg-transparent">
                    <div
                      id="flush-collapseScript"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                    
                        <h5>Enter query name
                        &nbsp;
                        </h5>
                        <div className="input-group input-group-sm mb-3">
        <input type="text" name='sopname'  onChange={(e) => {
  setInsertQuery(prevState => ({
    ...prevState,
    name: e.target.value
  }));
}}   className="form-control  border-dark" placeholder="Query name" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button onClick={()=>addQuery('insert')} className="btn bg-dark text-white" type="button" id="button-addon2">Submit</button>
      </div>
                        
            
                    </div>
                  </div>
                  <div></div>
                </div>
                <br />
  <mark>
  <i className="bi bi-binoculars"></i> Saved search query
                  </mark>
  <div
                  className="overflow-auto p-0"
                  style={{ maxWidth: "1000px", maxHeight: "800px" }}
                >
                  <ol className="list-group">

                  {querylist &&
                            querylist?.map((item, index) => (<><li  className="list-group-item d-flex justify-content-between align-items-start text-dark border border-dark">
                              <div className="ms-2 me-auto">
                                {item.name}
                              </div>
                              <i
                                title="View"
                                className="bi bi-eye-fill"
                                onClick={() => updateeditquery(item)}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              ></i>
                              
    
                              <i
                                title="Delete"
                                className="bi bi-trash3-fill"
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              ></i>
                            </li></>))}
                     
                  </ol>
                </div>
  </div>
  <div className="col-10 p-0">

    {editQuery==''?<><div align="center">
            <br/><br/><br/><br/><br/>
            <img src={empty} height={350} />
            </div></>:<><div className="container mt-4 ">
          

          <div className="row">
            <div className="col-md-4">
              <div className='card border-dark'>
              <div className='card-header bg-dark text-white'>
              <i className="bi bi-funnel"></i> Add filter
              </div>
              <div className='card-body bg-light'>
              <form onSubmit={handleFilterSubmit} className="p-2">
    
              <div className="row">
              <div className='col-sm-12'>
            <div className="mb-3">
              <label htmlFor="category" className="form-label">
                <i className="bi bi-table"></i> Select type:
              </label>
              <select
                id="category"
                className="form-select"
                value={selectedList.dataBase}
                onChange={handleSelectbuildQuery}
              >
                <option value="leads">Leads</option>
                <option value="calls">Calls</option>
                <option value="tasks">Tasks</option>
                {/* <option value="actions">Actions</option> */}
              </select>
            </div>
          </div>
    
    
          <div className='col-sm-12'>
            <div className="mb-3">
              <label htmlFor="dataType" className="form-label">
                <i className="bi bi-123"></i> Select data type:
              </label>
              <div className="mb-3 col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="count"
                    name="count"
                    checked={selectedList.dataDisplay.count}
                    onChange={handleCheckboxbuildQuery}
                  />
                  <label className="form-check-label" htmlFor="count">
                    Count
                  </label>
                </div>
              </div>
              <div className="mb-3 col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="table"
                    name="table"
                    checked={selectedList.dataDisplay.table}
                    onChange={handleCheckboxbuildQuery}
                  />
                  <label className="form-check-label" htmlFor="table">
                    Table
                  </label>
                </div>
              </div>
            </div>
          </div>
    
    
    </div>
    
    <div className="row">
    <div className='col-sm-6'>
    
    <div className="mb-3">
      <label htmlFor="exampleFormControlInput1" className="form-label">
      <i className="bi bi-gear-wide-connected"></i> Logic :
      </label>
      <select
                    id="logic"
                    className="form-select"
                    value={currentLogic}
                    onChange={(e) => setCurrentLogic(e.target.value)}
                  >
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </select>
    </div>
    
    
    </div>
    </div>
               
    
    <label htmlFor="exampleFormControlInput1" className="form-label">
    <i className="bi bi-code-slash"></i> Query :
      </label>     
    
    <div className="input-group mb-3">
      
      <select className="form-select" id="inputGroupSelect01" value={field}
                    onChange={(e) => setField(e.target.value)}>
        <option selected="">Field...</option>
    
        {dataOptions[selectedList.dataBase].map((item, index) => (
      <option value={item.name}>{item.name}</option>
      ))}
        
      
      </select>
      <select className="form-select" id="inputGroupSelect01" value={operator}
                    onChange={(e) => setOperator(e.target.value)}>
        <option selected="">Operator...</option>
        <option value="">Select Operator</option>
                    <option value="$eq">Equals</option>
                    <option value="$ne">Not Equals</option>
                    <option value="$gt">Greater Than</option>
                    <option value="$lt">Less Than</option>
                    <option value="$gte">Greater Than or Equals</option>
                    <option value="$lte">Less Than or Equals</option>
      </select>
      <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="form-control"
      placeholder='Value...'
      aria-label="Text input with segmented dropdown button"
    />
    
    </div>
    
                <button type="submit" className="btn btn-primary btn-sm">Add Filter</button>
    
              </form>
              <hr/>
       
              <form onSubmit={handleSortSubmit} className="p-2">
                <p><i className="bi bi-sort-up-alt"></i> Sort by :</p>
                <div class="input-group mb-3">
     <select class="form-select" id="inputGroupSelect03" aria-label="Example select with button addon" value={sortField}
                      onChange={(e) => setSortField(e.target.value)}>
         <option selected="">Field...</option>
    
         {dataOptions[selectedList.dataBase]
        .filter(item => item.type === 'number' || item.type === 'Date')
        .map((item, index) => (
            <option key={index} value={item.name}>{item.name}</option>
        ))
    }
      </select>
       <select class="form-select" id="inputGroupSelect03" aria-label="Example select with button addon" value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}>
         <option value="asc">Ascending</option>
         <option value="desc">Descending</option>
      </select>
      <button type="submit" className="btn btn-primary" id="button-addon2">Set Sort</button>
    </div>
                 
                 
                </form>
                <div className='row'> <div className='col-12'><button type="button" className="btn btn-dark col-12 btn-sm pop" onClick={addQuery}><i className="bi bi-cloud-arrow-up"></i> Save</button></div></div> 
              
    
                </div>
              </div>
            
                
                <br/>
                
    
             
            </div>
    
            <div className="col-md-4">
              <div className="card border-dark">
              <div className='card-header bg-dark text-white'>
              <i className="bi bi-braces-asterisk"></i> Current Query
              </div>
                <div className="card-body bg-light">
                 
                  <p>Search query :</p>
                  <pre>{JSON.stringify(buildQuery, null, 2)}</pre>
                  <p>Sort query :</p>
                  <pre>{JSON.stringify(buildSort, null, 2)}</pre>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <h5><i className="bi bi-list"></i> Filters</h5>
    
    <ul className="list-group">
      {filters.map((filter, index) => (
        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
          <span>
            {filter.logic.toUpperCase()} - {filter.field} {filter.operator} {filter.value}
          </span>
          <div>
            <button
              className="btn btn-sm btn-warning me-2"
              onClick={() => startEditingFilter(index)}
            >
              Edit
            </button>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => handleFilterDelete(index)}
            >
              Delete
            </button>
          </div>
        </li>
      ))}
    </ul>
            </div>
    
    
          </div>
    
          <div className="row mt-4">
            <div className="col-md-6">
              
            </div>
          </div>
        </div></>}

  

  </div>
  </div>
  </div>
  
  </> 
);
};

export default SearchLab;
