import React from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Weekreport from "../components/weekreport";
import Mapdiv from "../components/Mapdiv";
import Card from "../components/Card";
import TargetComp from "../components/TargetComp";
import TableCard from "../components/TableCard";

const Dashboard = () => {
  const layout = [
    { i: "mapdiv", x: 0, y: 0, w: 4, h: 15 },
    { i: "weekreport", x: 4, y: 0, w: 4, h: 11 },
    { i: "cardvalue", x: 8, y: 0, w: 4, h: 5},
    { i: "targetcard", x: 8, y: 5, w: 4, h: 7},
    { i: "table", x: 4, y: 10, w: 8, h: 8},
    // You can add more grid items here if needed
  ];



  return (
    <><h4 className="p-2"><i className="bi bi-laptop"></i> Manager Dashboard</h4><GridLayout
    className="layout"
    layout={layout}
    cols={12}
    rowHeight={30}
    width={window.innerWidth}
    draggableHandle=".drag-handle"
  >
    <div key="weekreport" style={{border: "1px solid #ccc", padding: "10px" }}>
    <span class="drag-handle badge border border-dark text-dark position-absolute top-0 start-50 translate-middle p-2 bg-light"><i className="bi bi-grip-horizontal"></i></span>
    <p align='center' className="text-secondary"><i className="bi bi-calendar-week"></i> Week based performance</p>
      <Weekreport />
    </div>

    <div key="mapdiv" style={{ border: "1px solid #ccc", padding: "10px" }}>
    <span class="drag-handle badge border border-dark text-dark position-absolute top-0 start-50 translate-middle p-2 bg-light"><i className="bi bi-grip-horizontal"></i></span>
  
      <Mapdiv />
    </div>

    <div key="cardvalue" style={{ border: "1px solid #ccc", padding: "10px" }}>
    <span class="drag-handle badge border border-dark text-dark position-absolute top-0 start-50 translate-middle p-2 bg-light"><i className="bi bi-grip-horizontal"></i></span>
    <p align='center' className="text-secondary"><i className="bi bi-calendar"></i> Monthly overview cards</p>
      <Card />
    </div>
    <div key="targetcard" style={{ border: "1px solid #ccc", padding: "10px" }}>
    <span class="drag-handle badge border border-dark text-dark position-absolute top-0 start-50 translate-middle p-2 bg-light"><i className="bi bi-grip-horizontal"></i></span>
    <p align='center' className="text-secondary"><i className="bi bi-bullseye"></i> &nbsp;Target status</p>
   
      <TargetComp />
    </div>
    <div key="table" style={{ border: "1px solid #ccc", padding: "10px" }}>
    <span class="drag-handle badge border border-dark text-dark position-absolute top-0 start-50 translate-middle p-2 bg-light"><i className="bi bi-grip-horizontal"></i></span>
    <p align='center' className="text-secondary"><i className="bi bi-exclamation-triangle-fill"></i> &nbsp;Partners Atrisk</p>
   
    <TableCard/>
    </div>
    {/* Add more grid items here if needed */}
  </GridLayout><br/></>
    
  );
};

export default Dashboard;
