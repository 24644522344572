/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
import React from "react";
import { Link } from "react-router-dom";

export default function dropdown({ valuecopy, view, value }) {
  const copy = (value) => {
    window.navigator.clipboard.writeText(value);
  };

  return (
    <>
      <div className="btn-group dropend">
        <b data-bs-toggle="dropdown" aria-expanded="false">
          {value}
        </b>
        <ul className="dropdown-menu">
          <li>
            <a
              className="dropdown-item"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p
                style={{ marginTop: "0px", marginBottom: "0px" }}
                onClick={() => copy(valuecopy)}
              >
                {valuecopy}
              </p>
              <i
                className="bi bi-clipboard2"
                style={{ cursor: "pointer" }}
                onClick={() => copy(valuecopy)}
              ></i>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              style={{ display: "flex", justifyContent: "space-between" }}
              rel="noreferrer"
            >
              <Link
                target="_blank"
                to={`/callinfo?phoneno=${valuecopy}`}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {view}
              </Link>{" "}
              <i className="bi bi-eye-fill"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
