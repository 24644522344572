import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
} from "react-router-dom";
//import { Offline, Online } from "react-detect-offline";
import { useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Call from "./pages/Call";
import Data from "./pages/Data";
import Navbar from "./components/Navbar.js";
import Relax from "./pages/Relax";
import Report from "./pages/Report";
import SharedCallView from "./pages/sharedCallView";
import Leadcapture from "./pages/Leadcapture";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Callinfo from "./pages/Callinfo";
import Learn from "./pages/Learn";
import Training from "./pages/Training";
import Notification from "./pages/Notification";
import Setup from "./pages/Setup";
import Script from "./pages/Editscript";
import Questions from "./pages/Questions";
import Podcast from "./pages/Podcast";
import DataHub from "./pages/DataHub.js";
import Integration from "./pages/Integration.js";
import PersonalizedTraining from "./pages/PersonalizedTraining.js";
import Offlinepage from "./pages/Offlinepage.js";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment } from "./redux/slices/counterReducer";
import SetupProgressbar from "./pages/setupprogressbar";
import TermsConditions from "./pages/Terms&Conditions";
import { ProvideWsConnection } from "../src/pages/socket";
import { getProgressBarStatus } from "../src/redux/slices/homeSlice";
import { useEffect } from "react";
import Sop from "./pages/Sop";
import Sopboard from "./pages/Sopboard";
import Checkup from "./pages/Checkup";
import Taskboard from "./pages/Taskboard";
import IntegrationLab from "./pages/IntegrationLab";
import OtherApps from "./components/OtherApps";
import AddLead from "./components/AddLead";
import LeadDetails from "./components/LeadDetails";
import SetTasks from "./components/SetTasks";
import AddCategory from "./components/AddCategory";
import Ticket from "./pages/Ticket";
import LearnForm from "./components/LearnForm.js";
import TestPage from "./pages/Test";
import Profile_cust from "./components/Profile_cust.js";
import SearchLab from "./pages/SearchLab.js";
import Automation from "./pages/Automation.js";
import Dashboard from "./pages/Dashboard.js";

import { SharedStateProvider } from './store';
const access_token = localStorage.getItem("Auth");
const admin = localStorage.getItem("admin");

function App() {
  const [contactNo, setcontactNo] = useState(null);
  const count = useSelector((state) => state.counterReducer.value);
  const ObjectStatus = useSelector((state) => state.Home.status);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProgressBarStatus({ access_token }));
  }, []);

  const token = localStorage.getItem("token");
  if (!token) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} exact></Route>
          <Route path="/terms&conditions" element={<TermsConditions />}></Route>
          <Route path="/viewsource" element={<SharedCallView />}></Route>
          <Route path="/leadcapture" element={<Leadcapture />}></Route>
          <Route path="/ticket" element={<Ticket />}></Route>
        </Routes>
      </Router>
    );
  }

  return (
    <SharedStateProvider><ProvideWsConnection>
    <Offlinepage>
      <Router>
        <Navbar setcontactNo={setcontactNo} contactNo={contactNo} />
        <br/>
        <OtherApps  />

{/* 
        {admin =='true' &&
        Object.values(ObjectStatus).length > 0 &&
        Object.values(ObjectStatus).filter((item) => item).length != 3 &&
        Object.values(ObjectStatus).filter((item) => item).length != 4 ? (
          <SetupProgressbar />
        ) : (

        <><br/></>        
        )} */}

<SetupProgressbar />
        <Routes>
          <Route path="/" element={<Home />} exact></Route>
          <Route path="/call" element={<Call/>} exact></Route>
          <Route path="/callrecord" element={<Data />}></Route>
          <Route path="/relax" element={<Relax />}></Route>
          <Route path="/report" element={<Report />}></Route>
          <Route path="/addcategory" element={<AddCategory />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/Callinfo" element={<Callinfo setcontactNo={setcontactNo} contactNo={contactNo} />}></Route>
          <Route path="/Callinfo?phoneno={no}" element={<Callinfo setcontactNo={setcontactNo} contactNo={contactNo} />}></Route>
          <Route path="/learn" element={<Learn />}></Route>
          <Route path="/training" element={<Training />}></Route>
          <Route path="/notification" element={<Notification />}></Route>
          <Route path="/setup" element={<Setup />}></Route>
          <Route path="/soplab" element={<Script />}></Route>
          <Route path="/questions" element={<Questions />}></Route>
          <Route path="/podcast" element={<Podcast />}></Route>
          <Route path="/datahub" element={<DataHub />}></Route>
          <Route path="/integration" element={<Integration />}></Route>
          <Route path="/personalizedtraining" element={<PersonalizedTraining />}></Route>
          <Route path="/viewsource" element={<SharedCallView />}></Route>
          <Route path="/leadcapture" element={<Leadcapture />}></Route>
          <Route path="/sop" element={<Sop />}></Route>
          <Route path="/sopboard" element={<Sopboard />}></Route>
          <Route path="/taskboard" element={<Taskboard />}></Route>
          <Route path="/checkup" element={<Checkup />}></Route>
          <Route path="/integrationLab" element={<IntegrationLab />}></Route>
          <Route path="/addlead" element={<AddLead />}></Route>
          <Route path="/leaddetails" element={<LeadDetails />}></Route>
          <Route path="/settasks" element={<SetTasks />}></Route>
          <Route path="/addcategory" element={<AddCategory />}></Route>
          <Route path="/learnform" element={<LearnForm />}></Route>
          <Route path="/profilecust" element={<Profile_cust />}></Route>
          <Route path="/searchlab" element={<SearchLab />}></Route>
          <Route path="/automation" element={<Automation />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
        </Routes>
      </Router>
    </Offlinepage>
  </ProvideWsConnection></SharedStateProvider>
    
  );
}

export default App;
