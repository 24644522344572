import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const truncate = (str, len) => {
  return str == null || str == undefined
    ? ""
    : str.length > len
    ? str.substring(0, len - 3) + ".."
    : str;
};


export function tocallvalue(item){
  if (item.call_brief == null) {
    return "&calltype=tocall";
  } else {
    return "&calltype=callback";
  }
};


export function progressElement(item) {
  if (item.call_status == "None")
    return (
      <div className="progress border border-dark">
        <div
          className="progress-bar progress-bar-striped"
          role="progressbar"
          style={{ width: "0%" }}
          aria-valuenow={0}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          0%
        </div>
      </div>
    );
  else if (item.call_status == "Salute & Confirmation")
    return (
      <div className="progress border border-dark">
        <div
          className="progress-bar progress-bar-striped bg-primary text-white"
          role="progressbar"
          style={{ width: "20%" }}
          aria-valuenow={20}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          20%
        </div>
      </div>
    );
  else if (item.call_status == "Pitch and Information")
    return (
      <div className="progress border border-dark">
        <div
          className="progress-bar progress-bar-striped bg-danger text-white"
          role="progressbar"
          style={{ width: "40%" }}
          aria-valuenow={40}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          40%
        </div>
      </div>
    );
  else if (item.call_status == "Ask Question & Talk")
    return (
      <div className="progress border border-dark">
        <div
          className="progress-bar progress-bar-striped bg-warning text-dark"
          role="progressbar"
          style={{ width: "60%" }}
          aria-valuenow={60}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          60%
        </div>
      </div>
    );
  else if (item.call_status == "Objection Handling")
    return (
      <div className="progress border border-dark">
        <div
          className="progress-bar progress-bar-striped bg-darker text-white progress-bar-animated"
          role="progressbar"
          style={{ width: "80%" }}
          aria-valuenow={80}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          80%
        </div>
      </div>
    );
  else if (item.call_status == "Closing")
    return (
      <div className="progress border border-dark">
        <div
          className="progress-bar progress-bar-striped bg-success text-white progress-bar-animated"
          role="progressbar"
          style={{ width: "100%" }}
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          100%
        </div>
      </div>
    );
  return item.call_status;
}

export function alterElement(item) {
  var added_at = item.added_at;
  if(item.added_at == '' ||  item.added_at ==null ){
    added_at = '';
  }else{
    added_at = added_at.substring(5, 16);
    var month = item.added_at.substring(5, 7);
    var day = item.added_at.substring(8, 10);
    var time = item.added_at.substring(11, 16);
    added_at = time.concat(' ', day,'/', month ); 
  }
  return added_at;
}

export function alterCallBack(item) {
  var cb = item;
  
  if(item == '' || item == null){

    cb = '';


  }else{

    cb = cb.replace("T", " ");
  var month = cb.substring(5, 7);
  var day = cb.substring(8, 10);
  var time = cb.substring(11, 16);  
  cb = time.concat(' ', day,'/', month )
  
}

  
  return cb;
}

export function changeElement(item) {
  if (item.call_brief == "Interested") return <b>{item.call_brief}</b>;
  return item.call_brief;
}

export function changeFace(item) {
  if (item == "intense") {
    return (
      <i className="bi bi-emoji-angry-fill text-danger" title="Intense"></i>
    );
  } else if (item == "unhappy") {
    return <i className="bi bi-emoji-frown-fill text-dark" title="Unhappy"></i>;
  } else if (item == "normal") {
    return (
      <i className="bi bi-emoji-smile-fill text-primary" title="Normal"></i>
    );
  } else if (item == "happy") {
    return (
      <i className="bi bi-emoji-laughing-fill text-success" title="Happy"></i>
    );
  }
}


export function generateavatar(text, foreground = "white", background = "black") {
  text = text.charAt(0);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = 200;
  canvas.height = 200;

  context.fillStyle = background;
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.font = "bold 100px verdana";
  context.fillStyle = foreground;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL("image/png");
}

export function alterPercent(item) {
  let similar = Math.round(item * 100) / 100;
  similar = similar * 100;

  return similar;
}



export function alterProgress(item) {
  let similar = Math.round(item * 100) / 100;
  return similar * 100;
}

