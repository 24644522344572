import counterReducer from "./slices/counterReducer";
import HomeSlice from "./slices/homeSlice";
import DataSlice from "./slices/dataSlice";
import socketSlice from "./slices/socketSlice";
import navSlice from "./slices/navSlice";
const reducers = {
  Home: HomeSlice,
  Data: DataSlice,
  Nav: navSlice,
  counterReducer: counterReducer,
  socket: socketSlice,
};
export default reducers;
