import React, { Component, useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from "axios";

import emptyapps from "../media/undraw_walking_in_rain_5lo3.svg";


function OtherApp() {
  const [show, setShow] = useState(false);
  const [integrationlist, setIntegrationlist] = useState([{}]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  const Auth = localStorage.getItem("Auth");

///////////////what is the stage of option/////////////////////

  const [optionview, setoptionview] = useState('option');

  const [appresponse, setappresponse] = useState({});

  const [currentapp, setcurrentapp] = useState({});

  const [currentappvar, setcurrentappvar] = useState({});

  const [inputdata, setinputdata] = useState({});

  function updataInputData(e) {
    setinputdata({ ...inputdata, [e.target.name]: e.target.value });
  }



  function changeoptionview(item, view){


    setoptionview(view);

    if(item != ''){


      setcurrentapp(item);
    }


    if(view == 'option'){

    }else if(view == 'open'){


      getappsvariable(item.integration_name);
    


    }else if(view == 'response'){

      

    }






  }

  function renderInputs(variableNames) {
    
    const inputs = [];
    
    for (const name in variableNames) {
      if (variableNames.hasOwnProperty(name)) {
        const type = variableNames[name];

        

        const id = name.toLowerCase();

          inputs.push(
            <div className="mb-3" key={id}>
            <label htmlFor="exampleInputEmail1" className="form-label">
              {name}
            </label>

{type=='textarea'?<input
type={type}
value={inputdata[name]}
onChange={updataInputData}

className="form-control form-control-sm"
id={id}
name={name}
aria-describedby="emailHelp"
/>
:  <textarea
value={inputdata[name]}
type={type}
onChange={updataInputData}
rows="3"
className="form-control form-control-sm"
id={id}
name={name}
aria-describedby="emailHelp"
/>}

            
          </div>
            
          );

       
  
      
        
      }
    }

   
  
    return <div>{inputs}</div>;
  }


  function getOtherApps(){


    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .get('/Get_Saved_Intigration',
       { headers })
      .then((data) => {
        setIntegrationlist(data.data);
  
      })
      .catch((err) => console.log(err));


  }

  function convertToTable(data) {
    // Check if the input is an array
    if (Array.isArray(data)) {
      // Create the table element
      const table = document.createElement('table');
      
      // Create the table header
      const thead = document.createElement('thead');
      const headerRow = document.createElement('tr');
      Object.keys(data[0]).forEach(key => {
        const th = document.createElement('th');
        th.textContent = key;
        headerRow.appendChild(th);
      });
      thead.appendChild(headerRow);
      table.appendChild(thead);
      
      // Create the table body
      const tbody = document.createElement('tbody');
      data.forEach(item => {
        const row = document.createElement('tr');
        Object.values(item).forEach(value => {
          const cell = document.createElement('td');
          cell.textContent = value;
          row.appendChild(cell);
        });
        tbody.appendChild(row);
      });
      table.appendChild(tbody);
      
      return table;
    }
    
    // Check if the input is an object
    if (typeof data === 'object' && data !== null) {
      // Create the table element
      const table = document.createElement('table');
      
      // Create the table body
      const tbody = document.createElement('tbody');
      Object.entries(data).forEach(([key, value]) => {
        const row = document.createElement('tr');
        
        const keyCell = document.createElement('td');
        keyCell.textContent = key;
        row.appendChild(keyCell);
        
        const valueCell = document.createElement('td');
        valueCell.textContent = value;
        row.appendChild(valueCell);
        
        tbody.appendChild(row);
      });
      table.appendChild(tbody);
      
      return table;
    }
    
    // Return the input as it is if it's neither an array nor an object
    return data;
  }


  
  function runapi() {


    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/execute_the_api',{
        "integration_name": currentapp.integration_name,
        "varialbe_and_value": inputdata
      }, { headers })
      .then((data) => {
        if (data.status == "200") {

          setappresponse(data.data);
          changeoptionview('', 'response')
         
        }
        
      })
      .catch((err) => console.log(err));
    
    
  }

  function emptyObjectValues(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = '';
      }
    }
  }

  function getappsvariable(item){


    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .get('/Get_the_varible_names?integration_name='.concat(item),
       { headers })
      .then((data) => {
       

        setcurrentappvar(data.data);
      
      

        emptyObjectValues(data.data);


        setinputdata(data.data);
       
  
      })
      .catch((err) => console.log(err));


  }



  useEffect(() => {
    getOtherApps();
  }, [1]);

  return (
    <>
      <Button className="vertical-button border border-light" size="md" variant="dark" onClick={handleShow}>
      <i className="bi bi-grid-fill"></i> Integrations
      </Button>

      <Offcanvas className="viewup scorebg text-light" show={show} onHide={handleClose} placement={'end'}>
        <Offcanvas.Header closeButton closeVariant='white' className='text-light'>
          <Offcanvas.Title><i className="bi bi-grid-fill"></i>  Integrations <span className="badge bg-danger">beta</span></Offcanvas.Title>
         
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="text-light"> All your apps in one place!</p>
        

{
optionview =='option'?
<div
                className="overflow-auto p-1 bg-transparent"
                style={{ maxWidth: "500px", maxHeight: "600px" }}
              ><ol className="list-group">


{integrationlist!=''?
<>{integrationlist &&
                          integrationlist?.map((item,index) => (

                            <li className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark">
                            <div className="ms-2 me-auto">
                            <i className="bi bi-app"></i> <b>{item.integration_name}</b>
                            </div>
                           <i
                              title="View"
                              className="bi bi-eye-fill"
                              onClick={()=>changeoptionview(item, 'open')}  
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            ></i>
                    
                          </li>

                            ))}</>
:<><br/><br/><br/><img
src={emptyapps}
height={300}
/><p align='center'><br/>No apps found</p></> }

</ol>
   </div>

:optionview =='open'?
<>

<h4 className="text-light" align='right'>
<i  onClick={()=>changeoptionview('','option')}   className="bi bi-x-circle-fill"></i>
<br/></h4>

<h4 align='left'> <i className="bi bi-app"></i> {currentapp.integration_name}</h4>
<br/>

{renderInputs(currentappvar)}

<br/>

<button type="button" onClick={()=>runapi()} className="btn btn-light">Submit</button>

</>

:optionview =='response'?
<>
<h4 className="text-light" align='right'>
<i  onClick={()=>changeoptionview('','option')}   className="bi bi-x-circle-fill"></i>
<br/></h4>

<h4 className="text-light">
<i  onClick={()=>changeoptionview('','open')}   className="bi-arrow-left-circle"></i>  {currentapp.integration_name}
<br/></h4>


<br/><p>Response</p><br/>

<div
                className="overflow-auto p-1 bg-transparent"
                style={{ maxWidth: "500px", maxHeight: "500px" }}
              >

{convertToTable(appresponse)}

              </div>




</>

:null

}



 
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OtherApp;