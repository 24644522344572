import React, { Component } from "react";


function PersonalizedTraining() {


  return (
    <>
     

      <div align="center">
        <br/><br/>
      <img src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/10.+Coming+soon.svg" height={350} />
      <p align='center'><b>Personalized Training</b> from the professionals (Coming Soon).</p>
      </div>
    </>
  );
}

export default PersonalizedTraining;
