import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useSelector, useDispatch } from "react-redux";
import {
  getTasks,
  getScriptAll,
  getUserData,
  getAdminCard,
  getUserCard,
  getToCallAdmin,
  getToCallUser,
  getContactNumber,
  updateTasks,
  deleteContactToCall,
  addNewContact,
  generateNewFile,
  uploadConatctFile,
  addNewTask,
  todayCallAdmin,
  adminCallDues,
  getAllTaskAdmin,
  todayCallAdminsSriptCategory,
  getAllContact,
  getfollowContact,
  uploadCallRecoding,
  getAllTaskAdminFilter,
  pendingTodaysAdmintask,
  adminTaskDues,
  deteleCallRecord,
  getSourceListData,
} from "../redux/slices/dataSlice";



const admin = localStorage.getItem("admin");
const organisation_name = localStorage.getItem("organisation_name");
const user_name = localStorage.getItem("user_name");
const org = localStorage.getItem("org");
const user_id = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");


function Taskboard() {

  
  const audioRef = useRef();
  const dispatch = useDispatch();
  const [alltasks, setalltasks] = useState([]);

  const [Highlight, setHighlight] = useState({
    day: "",
  });

  const [prospectstatus, setprospectstatus] = useState({
    active: "",
  });

  const [History, setHistory] = useState();
  const [trackId, setTrackId] = useState();
  const [RecLink, setRecLink] = useState("");

  const [editTask, setEditTask] = useState({
    taskID: "",
    taskStatus: "",
    remark: "",
  });


  function changeProspectData(e) {
    setprospectstatus({ ...prospectstatus, [e.target.name]: e.target.value });
  }

  function editTaskData(e) {
    setEditTask({ ...editTask, [e.target.name]: e.target.value });
  }

  function setDefaultTask(item) {
    setEditTask({ taskID: item._id, taskStatus: item.taskStatus, remark: "" });
  }

  const updateSong = (source) => {
    setRecLink(source);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  const renderPopover = (props) => (
    <Popover id="popover-basic">
    <Popover.Body>
    {props}
    </Popover.Body>
    </Popover>
  );

  function openNav(item, itemId) {
    setHistory();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("mySidenav").style.width = "500px";

    document.getElementById("trackbtn").click();
  }

    
  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);
  
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
  
    canvas.width = 200;
    canvas.height = 200;
  
    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);
  
    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);
  
    return canvas.toDataURL("image/png");
  }

  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  function rec() {
    document.getElementById("rec").style.display = "block";
  }
  function rec_back() {
    document.getElementById("rec").style.display = "none";
  }
  

  function uploadAudio() {
    dispatch(
      uploadCallRecoding({
        access_token,
        data: audioformdata,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
       
        document.getElementById("close1").click();
      }
    });
  }

  function updateTask() {
    dispatch(
      updateTasks({
        access_token,
        data: editTask,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        getalltasks();

      }
    });
  }
  
  let formdata = new FormData();
  let audioformdata = new FormData();

  function updataAudioFile(e) {
    const uploaded_file = e.target.files[0];

    audioformdata.append("uploaded_file", uploaded_file);
    //audioformdata.append("Recordid", '644aa9bda435914ea27a2222');
  }



  function changeFace(item) {
    if (item == "intense") {
      return (
        <i className="bi bi-emoji-angry-fill text-danger" title="Intense"></i>
      );
    } else if (item == "unhappy") {
      return <i className="bi bi-emoji-frown-fill text-dark" title="Unhappy"></i>;
    } else if (item == "normal") {
      return (
        <i className="bi bi-emoji-smile-fill text-primary" title="Normal"></i>
      );
    } else if (item == "happy") {
      return (
        <i className="bi bi-emoji-laughing-fill text-success" title="Happy"></i>
      );
    }
  }


  function changeRec(item) {
    if (item.call_recoding == true) {
      return (
        <button
          onClick={() => getRec(item)}
          className="border-0 bg-transparent"
        >
          <i className="bi bi-play-circle-fill"></i>
        </button>
      );
    } else if (item.call_recoding == false) {
      return (
        <button
          className="border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#audioModal"
        >
          <i className="bi bi-cloud-arrow-up"></i>
        </button>
      );
    } else {
      return (
        <button
        className="border-0 bg-transparent"
        data-bs-toggle="modal"
        data-bs-target="#audioModal"
      >
        <i className="bi bi-cloud-arrow-up"></i>
      </button>
      );
    }
  }

  function progressElement(item) {
    if (item.call_status == "None")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped"
            role="progressbar"
            style={{ width: "0%" }}
            aria-valuenow={0}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            0%
          </div>
        </div>
      );
    else if (item.call_status == "Salute & Confirmation")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-primary text-white"
            role="progressbar"
            style={{ width: "20%" }}
            aria-valuenow={20}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            20%
          </div>
        </div>
      );
    else if (item.call_status == "Pitch and Information")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-danger text-white"
            role="progressbar"
            style={{ width: "40%" }}
            aria-valuenow={40}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            40%
          </div>
        </div>
      );
    else if (item.call_status == "Ask Question & Talk")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-warning text-dark"
            role="progressbar"
            style={{ width: "60%" }}
            aria-valuenow={60}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            60%
          </div>
        </div>
      );
    else if (item.call_status == "Objection Handling")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-darker text-white progress-bar-animated"
            role="progressbar"
            style={{ width: "80%" }}
            aria-valuenow={80}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            80%
          </div>
        </div>
      );
    else if (item.call_status == "Closing")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-success text-white progress-bar-animated"
            role="progressbar"
            style={{ width: "100%" }}
            aria-valuenow={100}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            100%
          </div>
        </div>
      );
    return item.call_status;
  }


  function getRec(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/CallRecoding?".concat(
           "docucmet_id=",
          item.id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateSong(data.data);
          rec();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  function getcellhighlight(item){

    const today = new Date();
   
   
    const fiveDaysAgo = new Date(today.getTime() - (Highlight.day * 24 * 60 * 60 * 1000));
   
   const otherDate = new Date(item); 
   
   
   if(Highlight.day ==''){
   
       return 'card border-secondary mb-3 shadow';
   
   
   }else{
   
     if (otherDate > fiveDaysAgo) {
       return 'card border-secondary mb-3 shadow';
     } else {
       return 'card border-danger mb-3 shadow border-3';
     }
   
   }
   
   
     }


     function taskcard(item, type){
      
      return(<div className={getcellhighlight(item.added_at_utc)}  align='left'>
  
      <div className="card-body text-dark bg-light">
        <h6 className="card-title">      {item.customer_name=="" || item.customer_name==null?item.contact_number:item.customer_name
                          }
                          {returncontact(item)}</h6>
    
    
    
        <p className="card-text">
        {item.taskHeading}
        <br/>
        <span onClick={() =>
                              openNav(item.contact_number, item._id)
                            } className="badge bg-dark text-white cursor">Track      <i
                                  className="bi bi-geo-fill"
                                /></span> {" "}
                                {item.taskStatus == 'Assigned'?  <span  onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#editmodalToggle"} className="badge bg-primary text-white cursor"><i className="bi bi-person-lines-fill"></i> {item.taskStatus} <i className="bi bi-pencil-square"></i></span>
                                :item.taskStatus == 'InProgress'?  <span  onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#editmodalToggle"} className="badge bg-warning text-dark cursor"><i className="bi bi-fan"></i> {item.taskStatus} <i className="bi bi-pencil-square"></i></span>
                                :item.taskStatus == 'Canceled'?  <span  onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#editmodalToggle"} className="badge bg-danger text-white cursor"><i className="bi bi-x-lg"></i> {item.taskStatus} <i className="bi bi-pencil-square"></i></span>
                                :item.taskStatus == 'Completed'? <span  onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#editmodalToggle"} className="badge bg-success text-white cursor"><i className="bi bi-check-lg"></i> {item.taskStatus} <i className="bi bi-pencil-square"></i></span>
                                :null
                                }
                               
                                  <br/><br/>
        <i className="bi bi-person-circle"></i> {item.added_by_username}
        </p>
      </div>
      <div className="card-footer text-muted">
      <i className="bi bi-clock"></i> {alterElement(item)}
    </div>
    </div>);

     }
     
  function returncontact(item){

    return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
    <i className="bi bi-caret-down-fill"></i>
            </OverlayTrigger>

  } 

  function searchContact(item) {
    let phoneno = item;
    dispatch(
      getContactNumber({
        access_token,
        org,
        phoneNo: phoneno,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setHistory(res.payload.data.call_history);
      }
    });
  }

  function alterElement(item) {
    var added_at = item.added_at;
    if(item.added_at == '' ||  item.added_at ==null ){
      added_at = '';
    }else{
      added_at = added_at.substring(5, 16);
      var month = item.added_at.substring(5, 7);
      var day = item.added_at.substring(8, 10);
      var time = item.added_at.substring(11, 16);
      added_at = time.concat(' ', day,'/', month ); 
    }
    return added_at;
  }


  function getalltasks() {

    let endpoint;

    if(prospectstatus.active == 'true'){

      endpoint = '/get_task_without_limit?isActive=true';


    }else if(prospectstatus.active == 'false'){

      endpoint = '/get_task_without_limit?isActive=false';

    }else{
      endpoint = '/get_task_without_limit';
    }
    

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(endpoint, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          setalltasks(data.data);
         
        }
        
      })
      .catch((err) => console.log(err));
  }

  function getcellcount(item1){

  

      const count = alltasks.flat().filter(item => item.taskStatus === item1 ).length;

      return count;

  

  }


  useEffect(() => {
    getalltasks();
  }, [1]);

  return (
    <div className="container">
      <div className="container">
      <h5 id="AiModalLabel"><i className="bi bi-bicycle"></i> Task Board <span className="badge bg-primary">New</span></h5>
      <p>Efficiently track and manage tasks with real-time status updates</p>
      <br/>
      </div>
      <div className="row flex-row-reverse">
        <div className="col-3">
        <div className="input-group input-group-sm ">
  <select
    className="form-select border-dark"
    id="inputGroupSelect04"
    onChange={changeProspectData}
    name='active'
    value={prospectstatus.active}
    aria-label="Example select with button addon"
  >
    <option value={true}>Active</option>
    <option value={false}>Not Active</option>
  </select>
  <button className="btn btn-dark" onClick={getalltasks} type="button">
  <i className="bi bi-sliders"></i> Set
  </button>
</div>
        </div>
      </div>
      <br/>
<div className="row">
<div className="col-3 border border-top-0 border-bottom-0 border-dark">
  <div className="card mb-4">
    <div className="card-header bg-dark text-white"><i className="bi bi-person-lines-fill"></i> Assigned <div className="position-absolute top-50 end-0 translate-middle-y"><span className="badge bg-primary text-white me-3">{ 
    getcellcount('Assigned')
    }</span></div></div>
    </div>
  

    {alltasks &&
                                alltasks?.map((item) => (

                                  item.taskStatus == 'Assigned'?<>
                                  
                                  {taskcard(item,'Assigned')}

                                  </>

                                  :null


                                  

                                 
                                ))}

   

  
</div>
<div className="col-3 border border-top-0 border-bottom-0 border-dark">
<div className="card mb-4">
    <div className="card-header bg-dark text-white"><i className="bi bi-fan"></i> In Progress 
    <div className="position-absolute top-50 end-0 translate-middle-y"><span className="badge bg-warning text-dark me-3">{ 
    getcellcount('InProgress')
    }</span></div></div>
  </div>

  {alltasks &&
                                alltasks?.map((item) => (

                                  item.taskStatus == 'InProgress'?<>
                                  
                                  {taskcard(item,'InProgress')}

                                  </>

                                  :null


                                  

                                 
                                ))}
  
</div>
<div className="col-3 border border-top-0 border-bottom-0 border-dark">
<div className="card mb-4">
    <div className="card-header bg-dark text-white"> <i className="bi bi-x-lg"></i>Canceled 
    <div className="position-absolute top-50 end-0 translate-middle-y"><span className="badge bg-danger text-white me-3">{ 
    getcellcount('Canceled')
    }</span></div>
    </div>
  </div>
  
  {alltasks &&
                                alltasks?.map((item) => (

                                  item.taskStatus == 'Canceled'?<>
                                  
                                  {taskcard(item,'Canceled')}

                                  </>

                                  :null


                                  

                                 
                                ))}


</div>
<div className="col-3 border border-top-0 border-bottom-0 border-dark">
<div className="card mb-4">
    <div className="card-header bg-dark text-white"><i className="bi bi-check-lg"></i> Completed 
    <div className="position-absolute top-50 end-0 translate-middle-y">
    <span className="badge bg-success text-white me-3">{ 
    getcellcount('Completed')
    }</span></div></div>
  </div>


  {alltasks &&
                                alltasks?.map((item) => (

                                  item.taskStatus == 'Completed'?<>
                                  
                                  {taskcard(item,'Completed')}

                                  </>

                                  :null


                                  

                                 
                                ))}
  
</div>
</div>
<div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              <div className="row">
                                <br/>
                               
                                </div>
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
 <div
            className="modal fade"
            id="audioModal"
            tabIndex={-1}
            aria-labelledby="audioModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <p>Upload Call Recording.</p>

                  <input
                    type="file"
                    onChange={updataAudioFile}
                    name="uploaded_file"
                  />

                  <button onClick={uploadAudio}>Submit</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>
            <div
                                className="modal fade"
                                id={"editmodalToggle"}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex={-1}
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header border-0">
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div className="row bg-transparent">
                                        <div className="mb-1 col-12 bg-transparent">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            <i className="bi bi-file-earmark-text-fill" />
                                            Change Status:
                                          </label>

                                          <input
                                            name="taskID"
                                            type="hidden"
                                            value={editTask.taskID}
                                            onChange={editTaskData}
                                          ></input>

                                          <select
                                            name="taskStatus"
                                            value={editTask.taskStatus}
                                            onChange={editTaskData}
                                            className="form-select mb-3 col-6 border border-success"
                                            aria-label="Default select example"
                                            required
                                          >
                                            <option selected>
                                              Select Task Status
                                            </option>
                                            <option value="Assigned">
                                              Assigned
                                            </option>
                                            <option value="In Progress">
                                              In Progress
                                            </option>
                                            <option value="Canceled">
                                             Canceled  
                                            </option>
                                            <option
                                              className="bg-success text-white"
                                              value="Completed"
                                            >
                                              Completed
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-1 col-12">
                                          <div className="mb-1">
                                            <label
                                              for="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-text-fill" />
                                              Remarks
                                            </label>
                                            <textarea
                                              name="remark"
                                              value={editTask.remark}
                                              onChange={editTaskData}
                                              className="form-control border border-success"
                                              id="exampleFormControlTextarea1"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer border-0">
                                      <button
                                        onClick={updateTask}
                                        className="btn btn-dark"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>

        <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              <div className="row">
                                <br/>
                               
                                </div>
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
    </div>
    
  );
}

export default Taskboard;
