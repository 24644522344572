import React, { Component } from "react";
import relaxvideo from "../media/relax.mp4";

const user_name = localStorage.getItem("user_name");

function Relax() {
  function playrelaxvid() {
    document.getElementById("relaxvid").play();
  }

  return (
    <>
      

      <div onClick={playrelaxvid} align="center" style={{ backgroundColor: '#fcfcfb' }}>
        <video id="relaxvid" width="1000">
          <source src={relaxvideo} type="video/mp4" />
        </video>
        <div className="container" align='center' style={{ backgroundColor: '#fcfcfb' }}>
<figure className="text-center">
  <blockquote className="blockquote">
  <p><strong>{user_name}</strong>! feeling stressed?</p>
  </blockquote>
  <figcaption className="blockquote-footer">
    Sunday
  </figcaption>
</figure>
      </div>
      <br/><br/>
      </div>
      
    </>
  );
}

export default Relax;
