import axios from "axios";
import { createContext, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateWSConnection } from "../redux/slices/socketSlice";
const wsConnectionContext = createContext();
export function ProvideWsConnection({ children }) {
  const wsConnection = useProvideWsConnection();
  return (
    <wsConnectionContext.Provider value={wsConnection}>
      {children}
    </wsConnectionContext.Provider>
  );
}

export const useWsConnection = () => {
  return useContext(wsConnectionContext);
};

function useProvideWsConnection() {
  const socketRef = useRef(null);
  const socket = useSelector((state) => state.socket);

  const location = useParams();
  const dispatch = useDispatch();

  const connectSocket = () => {
    var config = {
      method: "get",
      url: "",
    };

    axios(config)
      .then((response) => {
        dispatch(
          updateWSConnection({
            wsURL: response.data.data.url,
            isSocketActive: true,
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (socket.wsURL) {
      socketRef.current = new WebSocket(socket.wsURL);
      socketRef.current.onopen = () => {
        // This is intentional
        console.log("socket connection successful");
      };
      socketRef.current.onclose = () => {
        console.log("socket closed");
        dispatch(updateWSConnection({ wsURL: null, isSocketActive: false }));
      };
      socketRef.current.onerror = () => {
        console.log("socket  error");

        dispatch(updateWSConnection({ wsURL: null, isSocketActive: false }));
        connectSocket("apurv9@gmail.com"); // token_auth_change
      };
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [socket.wsURL]);

  useEffect(() => {
    if (socketRef && socketRef.current) {
      socketRef.current.onmessage = (e) => {
        let data = JSON.parse(e?.data);
        console.log("datadatadatadatasocket", data);
      };
    }
  }, [socket.wsURL, location]);
  // Return the user object and wsConnection methods
  return {
    connectSocket,
  };
}
