import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Auth = localStorage.getItem("Auth");

const DynamicFormBuilder = () => {



  const [formFields, setFormFields] = useState([
    {
      name: '',
      type: 'text',
      options: [''],
      setting: 'All SOPs',
      desc: ''
    }
  ]);

  
  const [feedSopCategory, setSopCategory] = useState([]);
 

  const addField = () => {
    const newField = {
      name: '',
      type: 'text',
      options: [''],
      setting: 'All SOPs',
      desc: ''
    };
    setFormFields([...formFields, newField]);
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...formFields];
    updatedFields[index][fieldName] = value;
    setFormFields(updatedFields);
  };

  const handleOptionChange = (fieldIndex, optionIndex, value) => {
    const updatedFields = [...formFields];
    updatedFields[fieldIndex].options[optionIndex] = value;
    setFormFields(updatedFields);
  };


  const handleSettingChange = (index, value) => {
    const updatedFields = [...formFields];
    updatedFields[index].setting = value;
    setFormFields(updatedFields);
  };

  const addOption = (index) => {
    const updatedFields = [...formFields];
    updatedFields[index].options.push('');
    setFormFields(updatedFields);
  };

  const removeOption = (fieldIndex, optionIndex) => {
    const updatedFields = [...formFields];
    updatedFields[fieldIndex].options.splice(optionIndex, 1);
    setFormFields(updatedFields);
  };
  const removeField = (index) => {
    const updatedFields = [...formFields];
    updatedFields.splice(index, 1);
    setFormFields(updatedFields);
  };

  const handleSopCheckboxChange = (index, sop) => {
    const updatedFields = [...formFields];
    const selectedSops = updatedFields[index].selectedSops || [];

    if (selectedSops.includes(sop)) {
      updatedFields[index].selectedSops = selectedSops.filter(selectedSop => selectedSop !== sop);
    } else {
      updatedFields[index].selectedSops = [...selectedSops, sop];
    }

    setFormFields(updatedFields);
  };

  
  const tostSuccess = (msg) => {
    var msg = msg;

    toast.success(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tostWarn = (msg) => {
    var msg = msg;

    toast.error(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  ////send form data 

  function sendformdata() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/addformfields", {
        "data_format": formFields
    }, { headers })
      .then((data) => {
        if (data.status == "200") {
        
          tostSuccess("Form data added successful!");
       

        }
        
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  //////// Get all SOPs

  function getAllSop() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setSopCategory(data.data);
        }
      })
      .catch((err) => console.log(err));
  }


  ////get form data

  function getFormData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_addformfields",
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setFormFields(data.data.data_format);
        }

        // setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log("error", err));
  }



  const renderFields = () => {
    return formFields?.map((field, index) => (
      <div key={index}>
<div className='row'>

  <div className='col-4'>
  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Field name:
  </label>
  <input
    type="text"
    value={field.name}
    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
    className="form-control form-control-sm border-dark"
    id="exampleFormControlInput1"
    placeholder="Field name"
  />
</div>
  </div>
  <div className='col-3'>

  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Field type:
  </label>
  <select class="form-select form-select-sm border-dark" aria-label="Default select example"  value={field.type}
            onChange={(e) => handleFieldChange(index, 'type', e.target.value)}>
   <option value="text">Text</option>
            <option value="textarea">Textarea</option>
            <option value="number">Number</option>
            <option value="email">Email</option>
            <option value="date">Date</option>
            <option value="datetime-local">Date & time</option>
            <option value="select">Select from option</option>
</select>
  </div>

    
  </div>
  <div className='col-3'>

    {
     field.type =='select'?<>
     <div>
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Field options:
  </label>
          
          {field.options?.map((option, optionIndex) => (
            <> <div key={optionIndex}>
              <div className="input-group input-group-sm mb-1">
  <input
    type="text"
    value={option}
              className="form-control border-dark form-control-sm"
              onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
    placeholder={'Option '+ (optionIndex + 1) }
    aria-label="Recipient's username"
    aria-describedby="button-addon2"
  />
  <button
    className="btn btn-dark"
    type="button"
    id="button-addon2"
    onClick={() => removeOption(index, optionIndex)}
  >
    <i className="bi bi-x"></i>
  </button>
</div>
            
          </div>
          </>
           
            
          ))}
          <span className="badge bg-dark cursor" onClick={() => addOption(index)}>Add Option</span>

        </div>

     </>
    :null

    }
  
  </div>
  <div className='col-1'>
  <br/>
  {/* <button className='btn btn-transparent border-0'><i className="bi bi-gear-fill"></i></button> */}
  <div className="dropdown">
  <button
    className="btn btn-transparent border-0' dropdown-toggle"
    type="button"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="bi bi-gear-fill"></i>
  </button>
  <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
    <li>
      <div className="dropdown-item text-secondary" >
      <i className="bi bi-eye"></i> Input fields display settings

      </div>
    </li>
    <li>
      <div className="dropdown-item" >
      <div className="mb-3">
              <label htmlFor="settings" className="form-label">Settings:</label>
              <select
                id="settings"
                className="form-select form-select-sm border-dark"
                value={field.setting}
                onChange={(e) => handleSettingChange(index, e.target.value)}
              >
                <option value="All SOPs">All SOPs</option>
                <option value="Custom SOPs">Custom SOPs</option>
              </select>
            </div>
            {field.setting === 'Custom SOPs' && (
              <div>
                <label className="form-label">Select SOPs:</label>
                {feedSopCategory.map((sop, sopIndex) => (
                  <div className="form-check" key={sopIndex}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`sop-${sopIndex}`}
                      checked={(field.selectedSops || []).includes(sop)}
                      onChange={() => handleSopCheckboxChange(index, sop)}
                    />
                    <label className="form-check-label" htmlFor={`sop-${sopIndex}`}>{sop}</label>
                  </div>
                ))}
              </div>
            )}
     
      </div>
    </li>

    
  </ul>
</div>


  </div>
  <div className='col-1'>
  <br/>
  <button className='btn btn-transparent border-0'  onClick={() => removeField(index)}><i className="bi bi-x-circle-fill"></i></button>

  </div>
</div>

<div className='row'>
  <></>
</div>

    
    
      </div>
    ));
  };


  useEffect(()=>{

    getFormData();
    getAllSop();

  },[1])

  return (
    <div>
     
      {renderFields()}
     
      <div align='right'><button className='btn btn-sm btn-outline-dark' onClick={addField}>Add Field</button></div>
     
     <hr/>
      
     <button className='btn btn-sm btn-dark' onClick={sendformdata}>Submit</button>
      {/* This JSON output shows the current state of formFields */}
    </div>
  );
};

export default DynamicFormBuilder;
