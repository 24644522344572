import React from "react";
import logo from "../media/Sales.gif";
import { useState, useEffect } from "react";
import axios from "axios";
import lighthouse from "../media/undraw_lighthouse_re_7r60.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  getselect_options
} from "../redux/slices/leadSlice";


export default function Leadcapture() {

  const dispatch = useDispatch();
  var url_string = window.location.href;
  var url = new URL(url_string);
  var og = url.searchParams.get("org");
  var org = og;
  var Tid = url.searchParams.get("Tid");
  var FTid = url.searchParams.get("FTid");
  const [errorMessage, setErrorMessage] = useState("");
  const [orgData, setOrgData] = useState({
    organisation_type: "",
    organisation_category: "",
    total_employees: "",
    gst: "",
    state: "",
    pin_code: "",
    billing_address: "",
  });
  const [feedOrg, setOrgFeed] = useState("");
  const [profilepic, setProfilePic] = useState("");

  const [leaddata, setLeadData] = useState([]);

  const [selectoptions, setSelectoptions] = useState({});
  
 
  function fetchOrgData() {
    
    const headers = {
      "Content-Type": "text/plain",
      
    };
    axios
      .get("/Source_Get_organisation?organisation_id=".concat(og), { headers })
      .then((data) => {
        if (data.status == "200") {
          setOrgFeed(data.data[0]);
          setProfilePic(data.data[0].ImageLink);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

   
  function get_lead_details() {
    
    const headers = {
      "Content-Type": "text/plain",
      
    };
    axios
      .get("/get_lead_detail_open?Tid=".concat(Tid,'&FTid=',FTid ), { headers })
      .then((data) => {
        if (data.status == "200") {
          setLeadData(data.data.ticket_data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getOptionData() {
    dispatch(getselect_options({  org })).then((res) => {
      if (res.payload.status == 200) {
        setSelectoptions(res.payload.data);
      }
    });
    
  }
  
  function calculatesopPercentage(arrayName, targetStage) {


    // Your provided object
    const stagesObject = selectoptions;
  
    // Get the array based on the provided arrayName
    const targetArray = stagesObject[arrayName];
  
    if (targetArray) {
      // Find the index of the targetStage in the array
      const stageIndex = targetArray.indexOf(targetStage);
  
      // Calculate the percentage
      const percentage = ((stageIndex + 1) / targetArray.length) * 100;
  
      console.log(percentage,'heelo');
      return percentage.toFixed(0);
    }
  }

  
  useEffect(() => {
    fetchOrgData();
    get_lead_details();
    getOptionData();

  }, []);

    return(
        <>
            <nav className="navbar">
    <div className="container-fluid">
      <a className="navbar-brand" href="#">
        {profilepic!=""? <img src={profilepic} alt="" height={40} className="d-inline-block align-text-top" />: <img src={logo} alt="" width={55} height={40} className="d-inline-block align-text-top" />}
       
        &nbsp; {feedOrg.organisation_name}
      </a>
    
    </div>
  </nav>

  {leaddata?.map((item)=>(<div className="container">
        <div align='center' >
          <br/>
      <div className="card col-sm-4 col-12 border-dark">
        <div className="card-header">
        <i className="bi bi-ticket-fill"></i> {item.title}
        </div>
        <div className="card-body">
    
  <div className="row">
  <div className="col-4"><h2><b>{item.TicketId}</b></h2>
          <p className="text-secondary" align ='center' >Ticket Id</p></div>
  <div className="col-8" align='right'>
    <p className="text-dark"> {item.pipeline} </p>
    <h5><span class="badge bg-dark"><i className="bi bi-geo-fill"></i> {item.pipelineCurrentStage}</span></h5>
  </div>
  </div>
          
          <hr/><br/>

  <div align='left' className="container">
    <div className="row">
    <div className="col-6">
        
        <p><i className="bi bi-person-circle"></i> Contact Person: </p>
   
      </div>
      <div className="col-6" align='right'>
      <p> <b>{item.contactPerson}</b></p>
     
      </div>
    </div>
    <div className="row">
    <div className="col-6">
        
        <p><i className="bi bi-signpost"></i> Progress </p>
   
      </div>
      <div className="col-6" align='right'>
      <div className="progress border border-dark progress-bar-striped">
  <div
    className="progress-bar progress-bar progress-bar-striped progress-bar-animated bg-dark"
    role="progressbar"
    style={{ width: `${calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)}%` }}
    aria-valuenow={calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)}
    aria-valuemin={0}
    aria-valuemax={100}
  >
    {calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)} %
  </div>
</div>
     
      </div>
    </div>
    <div className="row">
    <div className="col-6">
        
        <p><i className="bi bi-building"/> Organization: </p>
   
      </div>
      <div className="col-6" align='right'>
      <p> <b>{item.organization}</b></p>
     
      </div>
    </div>
    <div className="row">
    <div className="col-6">
        
        <p><i className="bi bi-calendar"></i> Expected closing: </p>
   
      </div>
      <div className="col-6" align='right'>
      <p> <b>{item.expectedClosingDate}</b></p>
     
      </div>
    </div>
    
    </div>
    <div align='center'><img src={lighthouse} height={150} /></div>
        </div>
      </div>
      </div>
      </div>))}
  


      <div className="footer">
        <p>
          Powered by <a href='http://salessunday.com/' target="_blank"><b>SalesSunday</b></a> | <i class="bi bi-lock-fill"></i> Secure connection.
        </p>
      </div>

        </>
    );

}