import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../media/Sales.gif";
import scriptview from "../media/undraw_working_late_re_0c3y.svg";
import nosuggestion from "../media/undraw_blank_canvas_re_2hwy.svg";
import DropDown from "./dropDown";
import { getProgressBarStatus } from "../redux/slices/homeSlice";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip1 from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";
import empty from "../media/undraw_robotics_kep0.svg";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const org = localStorage.getItem("org");
const Auth = localStorage.getItem("Auth");
const admin = localStorage.getItem("admin");



function Editscript() {
  //////////Get Category//////////////////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const [feedOrgCategory, setOrgCategory] = useState();
  const [feedOrgTask, setOrgTask] = useState();

  const [errorMessage, setErrorMessage1] = useState("");

  /////////////////////Set values of pitch////////////////////////////////////////////

  const [short_intro, updateshort_intro] = useState("");
  const [ask_permission, updateask_permission] = useState("");
  const [confirmation, updateconfirmation] = useState("");
  const [hero_line, updatehero_line] = useState("");
  const [if_no, updateif_no] = useState("");
  const [if_yes, updateif_yes] = useState("");
  const [short_pitch, updateshort_pitch] = useState("");
  const [if_postive, updateif_postive] = useState("");
  const [if_nagative, updateif_nagative] = useState("");
  const [objection, updateobjection] = useState("");
  const [ask_questions, updateask_questions] = useState("");
  const [conclusion, updateconclusion] = useState("");
  const [call_savior, updatecall_savior] = useState("");
  const [versionId1, updateversionId1] = useState("");
  const [versionId2, updateversionId2] = useState("");
  const [versionId3, updateversionId3] = useState("");
  const [versionId4, updateversionId4] = useState("");
  const [versionId5, updateversionId5] = useState("");
  const [versionId6, updateversionId6] = useState("");
  const [livecat, updatelivecat] = useState("");
  const [livetaskcat, updatelivetaskcat] = useState("");
  const [currentcat, Setcurrentcat] = useState("");
  const [pitchCatData, setPitchData] = useState({});
  const [taskdesc, settaskdesc] = useState('');
  const [task_type, settask_type] = useState('');

  const [isStateUpdated, setIsStateUpdated] = useState(false);



  const [scoreData, setscoreData] = useState({
    "In Valid": 0,
    "Cut Call": 0,
    "Didn't Pick": 0,
    "Call Later": 0,
    "Interested": 0,
    "Not Interested": 0,
  });


  const [scoreTask, setscoreTask] = useState({
    "Assigned": 0,
    "InProgress": 0,
    "Canceled": 0,
    "Completed": 0,
  });



  const [livegoal, updategoaldata] = useState({
    organisation_id: org,
    your_goal: "",
  });
  const [scripttext, updatescripttext] = useState("");
  const [suggtext, updatesuggtext] = useState("");
  const [trashData, setTrash] = useState([]);

  const [inputFields, setInputFields] = useState([{}]);
  const [instruction, setInstruction] = useState([...Array(6).fill({})]);
  let [invalid, setinvalid] = useState({});
  let [callCut, setCallCut] = useState({});
  let [didntPick, setDidntPick] = useState({});
  let [callLater, setCallLater] = useState({});
  let [interest, setInterest] = useState({});
  let [notInterest, setnotInterest] = useState({});

  const audioRef = useRef(null);
  const audioRef2 = useRef(null);
  const audioRef3 = useRef(null);
  const audioRef4 = useRef(null);
  const audioRef5 = useRef(null);
  const audioRef6 = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [isPlaying4, setIsPlaying4] = useState(false);
  const [isPlaying5, setIsPlaying5] = useState(false);
  const [isPlaying6, setIsPlaying6] = useState(false);

  const [coin, setCoin] = useState('n/a');

  const [scriptreq, setscriptreq] = useState({
    calltype: "",
    written_language: "",
    spoken_language: "",
    description: "",
  });

  const [taskreq, settaskreq] = useState({
    tasktype: "Email", 
    written_language: "",
    spoken_language: "",
    subtype: "", 
    description: "",
  });

   const [scriptresp, setscriptresp] = useState({
    "Intro": {"short_intro" :""},
    "Pitch": "",
    "consulting questions and talk": "",
    "possible objections": [],
    "call closing": ""
});

const [taskresp, settaskresp] = useState({
  "Description": "",
  "Template": "",
});

const subTypeList = {

 "Email": [
  {"name": "Job Offer", "description": "Extending an offer of employment to a candidate."},
  {"name": "Job Description", "description": "Providing details of job roles and responsibilities."},
  {"name": "Interview Invitation", "description": "Requesting a candidate to attend an interview."},
  {"name": "Interview Feedback", "description": "Informing a candidate about the outcome of their interview."},
  {"name": "Onboarding Information", "description": "Details and instructions for new hires."},
  {"name": "Employee Promotion", "description": "Notifying an employee about their promotion."},
  {"name": "Termination Notice", "description": "Informing an employee about the termination of their employment."},
  {"name": "Performance Review", "description": "Scheduling or providing feedback from performance evaluations."},
  {"name": "Policy Updates", "description": "Informing employees about changes in company policies."},
  {"name": "Training and Development", "description": "Inviting employees to training sessions or workshops."},
  {"name": "Cold Email", "description": "Reaching out to potential clients or customers who have not been previously contacted."},
  {"name": "Follow-Up Email", "description": "Following up on a previous interaction or meeting."},
  {"name": "Sales Proposal", "description": "Presenting a business proposal to a potential client."},
  {"name": "Product Launch Announcement", "description": "Informing clients and customers about a new product or service."},
  {"name": "Newsletter", "description": "Providing updates, news, and information to subscribers."},
  {"name": "Promotional Email", "description": "Offering discounts, special deals, or promotions."},
  {"name": "Feedback Request", "description": "Asking customers for their feedback or reviews."},
  {"name": "Payment Confirmation", "description": "Confirming receipt of payment."},
  {"name": "Invoice", "description": "Sending a bill for goods or services rendered."},
  {"name": "Receipt", "description": "Providing proof of payment."},
  {"name": "Account Statement", "description": "Detailing account activities and balances."},
  {"name": "Expense Report Submission", "description": "Requesting approval for expenses incurred."},
  {"name": "Budget Approval", "description": "Requesting approval for budgets and financial plans."},
  {"name": "Order Confirmation", "description": "Confirming receipt of an order."},
  {"name": "Shipping Notification", "description": "Informing customers about the shipping status of their order."},
  {"name": "Delivery Confirmation", "description": "Confirming delivery of goods or services."},
  {"name": "Supply Chain Updates", "description": "Providing updates on supply chain activities and issues."},
  {"name": "Maintenance Schedule", "description": "Informing about scheduled maintenance activities."},
  {"name": "IT Support Request", "description": "Requesting technical assistance or support."},
  {"name": "System Downtime Notification", "description": "Informing about scheduled or unscheduled system downtimes."},
  {"name": "Password Reset", "description": "Instructions for resetting passwords."},
  {"name": "Security Alert", "description": "Notifying about security incidents or vulnerabilities."},
  {"name": "Software Update", "description": "Informing about software updates or patches."},
  {"name": "Meeting Invitation", "description": "Requesting attendance at a meeting."},
  {"name": "Agenda", "description": "Providing the agenda for an upcoming meeting."},
  {"name": "Minutes of Meeting", "description": "Summarizing the discussions and decisions of a meeting."},
  {"name": "Company Announcements", "description": "Informing employees about important company news and events."},
  {"name": "Holiday Notice", "description": "Informing about company holidays or office closures."},
  {"name": "Thank You Email", "description": "Expressing gratitude for various reasons."}
],
"SMS": [
  {"name": "Interview Invitation", "description": "Requesting a candidate to attend an interview."},
  {"name": "Interview Reminder", "description": "Reminding a candidate about their scheduled interview."},
  {"name": "Job Offer Notification", "description": "Informing a candidate about a job offer."},
  {"name": "Onboarding Reminder", "description": "Reminding new hires about onboarding details."},
  {"name": "Policy Updates", "description": "Informing employees about changes in company policies."},
  {"name": "Training Session Reminder", "description": "Reminding employees about upcoming training sessions."},
  {"name": "Promotional Offer", "description": "Informing customers about discounts, deals, or special promotions."},
  {"name": "Product Launch Announcement", "description": "Notifying customers about new products or services."},
  {"name": "Event Invitation", "description": "Inviting customers to attend an event or webinar."},
  {"name": "Follow-Up", "description": "Following up on a recent interaction or purchase."},
  {"name": "Feedback Request", "description": "Asking customers for their feedback or reviews."},
  {"name": "Abandoned Cart Reminder", "description": "Reminding customers to complete their purchase."},
  {"name": "Payment Confirmation", "description": "Confirming receipt of payment."},
  {"name": "Invoice Reminder", "description": "Reminding customers about unpaid invoices."},
  {"name": "Expense Approval", "description": "Notifying employees about the approval of their expense reports."},
  {"name": "Budget Approval Notification", "description": "Informing employees about the approval of budgets."},
  {"name": "Order Confirmation", "description": "Confirming receipt of a customer’s order."},
  {"name": "Shipping Notification", "description": "Informing customers about the shipping status of their order."},
  {"name": "Delivery Confirmation", "description": "Confirming the delivery of goods."},
  {"name": "Maintenance Schedule", "description": "Informing employees about scheduled maintenance activities."},
  {"name": "Supply Chain Updates", "description": "Providing updates on supply chain activities."},
  {"name": "System Downtime Notification", "description": "Informing about scheduled or unscheduled system downtimes."},
  {"name": "Password Reset", "description": "Sending instructions for resetting passwords."},
  {"name": "Security Alert", "description": "Notifying about security incidents or vulnerabilities."},
  {"name": "Software Update", "description": "Informing about software updates or patches."},
  {"name": "IT Support Ticket Status", "description": "Updating employees on the status of their IT support tickets."},
  {"name": "Meeting Reminder", "description": "Reminding employees about an upcoming meeting."},
  {"name": "Company Announcements", "description": "Informing employees about important company news and events."},
  {"name": "Holiday Notice", "description": "Informing employees about company holidays or office closures."},
  {"name": "Event Reminder", "description": "Reminding employees or customers about upcoming events."},
  {"name": "Thank You Message", "description": "Expressing gratitude for various reasons."}
],
"Whatsapp" : [
  {"name": "Interview Invitation", "description": "Requesting a candidate to attend an interview."},
  {"name": "Interview Reminder", "description": "Reminding a candidate about their scheduled interview."},
  {"name": "Job Offer Notification", "description": "Informing a candidate about a job offer."},
  {"name": "Onboarding Reminder", "description": "Reminding new hires about onboarding details."},
  {"name": "Policy Updates", "description": "Informing employees about changes in company policies."},
  {"name": "Training Session Reminder", "description": "Reminding employees about upcoming training sessions."},
  {"name": "Promotional Offer", "description": "Informing customers about discounts, deals, or special promotions."},
  {"name": "Product Launch Announcement", "description": "Notifying customers about new products or services."},
  {"name": "Event Invitation", "description": "Inviting customers to attend an event or webinar."},
  {"name": "Follow-Up", "description": "Following up on a recent interaction or purchase."},
  {"name": "Feedback Request", "description": "Asking customers for their feedback or reviews."},
  {"name": "Abandoned Cart Reminder", "description": "Reminding customers to complete their purchase."},
  {"name": "Payment Confirmation", "description": "Confirming receipt of payment."},
  {"name": "Invoice Reminder", "description": "Reminding customers about unpaid invoices."},
  {"name": "Expense Approval", "description": "Notifying employees about the approval of their expense reports."},
  {"name": "Budget Approval Notification", "description": "Informing employees about the approval of budgets."},
  {"name": "Order Confirmation", "description": "Confirming receipt of a customer’s order."},
  {"name": "Shipping Notification", "description": "Informing customers about the shipping status of their order."},
  {"name": "Delivery Confirmation", "description": "Confirming the delivery of goods."},
  {"name": "Maintenance Schedule", "description": "Informing employees about scheduled maintenance activities."},
  {"name": "Supply Chain Updates", "description": "Providing updates on supply chain activities."},
  {"name": "System Downtime Notification", "description": "Informing about scheduled or unscheduled system downtimes."},
  {"name": "Password Reset", "description": "Sending instructions for resetting passwords."},
  {"name": "Security Alert", "description": "Notifying about security incidents or vulnerabilities."},
  {"name": "Software Update", "description": "Informing about software updates or patches."},
  {"name": "IT Support Ticket Status", "description": "Updating employees on the status of their IT support tickets."},
  {"name": "Meeting Reminder", "description": "Reminding employees about an upcoming meeting."},
  {"name": "Company Announcements", "description": "Informing employees about important company news and events."},
  {"name": "Holiday Notice", "description": "Informing employees about company holidays or office closures."},
  {"name": "Event Reminder", "description": "Reminding employees or customers about upcoming events."},
  {"name": "Thank You Message", "description": "Expressing gratitude for various reasons."}
]



}



  function updatascriptreq(e) {
    setscriptreq({ ...scriptreq, [e.target.name]: e.target.value });
  }

  function updatataskreq(e) {
    settaskreq({ ...taskreq, [e.target.name]: e.target.value });
  }

  const tostWaitMsg = () => {
  
  

      toast('🦄 Process Initiated!', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
  };    

  const tostError = (msg) => {
  
  
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
};    


  const handleTogglePlayback = () => {
    if (isPlaying) {
      // If we're currently playing, pause playback
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      // If we're currently paused, start playback
      audioRef.current.play();
      setIsPlaying(true);
    }
  };
  const handleTogglePlayback2 = () => {
    if (isPlaying2) {
      // If we're currently playing, pause playback
      audioRef2.current.pause();
      setIsPlaying2(false);
    } else {
      // If we're currently paused, start playback
      audioRef2.current.play();
      setIsPlaying2(true);
    }
  };
  const handleTogglePlayback3 = () => {
    if (isPlaying3) {
      // If we're currently playing, pause playback
      audioRef3.current.pause();
      setIsPlaying3(false);
    } else {
      // If we're currently paused, start playback
      audioRef3.current.play();
      setIsPlaying3(true);
    }
  };
  const handleTogglePlayback4 = () => {
    if (isPlaying4) {
      // If we're currently playing, pause playback
      audioRef4.current.pause();
      setIsPlaying4(false);
    } else {
      // If we're currently paused, start playback
      audioRef4.current.play();
      setIsPlaying4(true);
    }
  };
  const handleTogglePlayback5 = () => {
    if (isPlaying5) {
      // If we're currently playing, pause playback
      audioRef5.current.pause();
      setIsPlaying5(false);
    } else {
      // If we're currently paused, start playback
      audioRef5.current.play();
      setIsPlaying5(true);
    }
  };
  const handleTogglePlayback6 = () => {
    if (isPlaying6) {
      // If we're currently playing, pause playback
      audioRef6.current.pause();
      setIsPlaying6(false);
    } else {
      // If we're currently paused, start playback
      audioRef6.current.play();
      setIsPlaying6(true);
    }
  };

  const addInputField = () => {
    setInputFields([...inputFields, {}]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, evnt) => {
    const { value } = evnt.target;
    const list = [...inputFields];

    list[index] = value;
    setInputFields(list);
  };
  const renderTooltip1 = (props) => (
    <Tooltip1 id="button-Tooltip1" {...props}>
      {props}
    </Tooltip1>
  );

  const obj = { organisation_id: org, script_types: inputFields };

  const [inputFieldsOH, setInputFieldsOH] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  const [OHobj, setOHobj] = useState({
    organisation_id: org,
    script_catagory: livecat,
    questions_set: inputFieldsOH,
  });

  const addInputFieldOH = () => {
    setInputFieldsOH([
      ...inputFieldsOH,
      {
        question: "",
        answers: "",
      },
    ]);
  };

  const tostSuccess = (msg) => {
    var msg = msg;

    toast.success(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tostWarn = (msg) => {
    var msg = msg;

    toast.error(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  let removeInputFieldsOH = (i) => {
    let rows = [...inputFieldsOH];
    rows.splice(i, 1);
    setInputFieldsOH(rows);
  };

  let handleChangeOH = (i, e) => {
    let rows = [...inputFieldsOH];
    rows[i][e.target.name] = e.target.value;
    setInputFieldsOH(rows);
  };

  const [voteData, setVoteData] = useState({
    part1up: "",
    part1down: "",
    part2up: "",
    part2down: "",
    part3up: "",
    part3down: "",
    part4up: "",
    part4down: "",
    part5up: "",
    part5down: "",
    part6up: "",
    part6down: "",
  });


  const [Part1, setPart1] = useState({
    organisation_id: org,
    script_catagory: "",
    short_intro: "",
    confirmation: "",
    hero_line: "",
    ask_permission: "",
    if_yes: "",
    if_no: "",
  });
  const [Part2, setPart2] = useState({
    organisation_id: org,
    script_catagory: "",
    shot_pitch: "",
    if_postive: "",
    if_nagative: "",
  });
  const [Part3, setPart3] = useState({
    organisation_id: org,
    script_catagory: "",
    ask_questions: "",
  });

  const [Part5, setPart5] = useState({
    organisation_id: org,
    script_catagory: "",
    conclusion: "",
  });
  const [Part6, setPart6] = useState({
    organisation_id: org,
    script_catagory: "",
    call_savior: "",
  });

  const [inputFields2, setInputFields2] = useState([
    {
      heading: "",
      description: "",
    },
  ]);

  const obj2 = { organisation_id: org, headings: inputFields2 };

  let handleChange2 = (i, e) => {
    let rows = [...inputFields2];
    rows[i][e.target.name] = e.target.value;
    setInputFields2(rows);
  };

  const addInputField2 = () => {
    setInputFields2([
      ...inputFields2,
      {
        heading: "",
        description: "",
      },
    ]);
  };
  let removeInputFields2 = (i) => {
    let rows = [...inputFields2];
    rows.splice(i, 1);
    setInputFields2(rows);
  };

  
  const [data_vote, set_data_vote] = useState([
    {
      name: "Step 1",
      down: 0,
      up: 0,
    },
    {
      name: "Step 2",
      down: 0,
      up: 0,
    },
    {
      name: "Step 3",
      down: 0,
      up: 0,
    },
    {
      name: "Step 4",
      down: 0,
      up: 0,
    },
    {
      name: "Step 5",
      down: 0,
      up: 0,
    },
    {
      name: "Call Savior",
      down: 0,
      up: 0,
    },
  ]);

  const [data_performance, set_data_performance] = useState([
    {
      name: "None",
      call: 0,
    },
    {
      name: "Salute & Con",
      call: 0,
    },
    {
      name: "Pitch & Info",
      call: 0,
    },
    {
      name: "Question & Talk",
      call: 0,
    },
    {
      name: "Objection",
      call: 0,
    },
    {
      name: "Call Closing",
      call: 0,
    },
  ]);

  
  const [filterDate2, setFilterDate2] = useState({
    organisation_id: org,
    script_category: "",
    staring_date: "",
    ending_date: "",
  });

  

  function checkadmin(){


    if(admin != 'true'){

      window.location.replace("https://dashboard.salessunday.com/");

    }


  }


  function sendData(e) {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/add_more_scripts_type", obj, { headers })
      .then((data) => {
        if (data.status == "200") {
          setInputFields([{ fullName: " " }]);
          getScript();

          

          dispatch(getProgressBarStatus({ access_token: Auth }));
          tostSuccess(data.data.massage);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }


  function updataData(e) {
    e.preventDefault();
    setPart1({ ...Part1, [e.target.name]: e.target.value });
    setPart2({ ...Part2, [e.target.name]: e.target.value });
    setPart3({ ...Part3, [e.target.name]: e.target.value });
    setPart5({ ...Part5, [e.target.name]: e.target.value });
    setPart6({ ...Part6, [e.target.name]: e.target.value });
  }

  function updateGoal(e) {
    e.preventDefault();
    updategoaldata({ ...livegoal, [e.target.name]: e.target.value });
  }

  function updateScore(e) {
    e.preventDefault();
    setscoreData({ ...scoreData, [e.target.name]: e.target.value });
  }


  function updateTaskScore(e) {
    e.preventDefault();
    setscoreTask({ ...scoreTask, [e.target.name]: e.target.value });
  }


  function changeStatus(script_name) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .put(
        "/changestatus_scripts",
        { organisation_id: org, script_types_name: script_name },
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          getScript();
          toast.success(`Script status update successfully`, {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getScript() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_all_script_types?orgID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgCategory(data.data);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getTask() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/GetTaskHeadings?organisationID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgTask(data.data);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }
  function getScriptGoals(pitchCatData) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/Get_ScriptGoal?organisation_id=".concat(org) +
          `&scripts_types=${pitchCatData.pitchCat}`,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updategoaldata(data.data);
        }

        // setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log("errerrerrerr", err));
  }
  
  function sendscore(script) {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Add_callEndingScore", {
        "scripts_types": script,
        "callscore": [
          {
            "call_brief": "In Valid",
            "score": parseInt(scoreData["In Valid"])
          },
          {
            "call_brief": "Cut Call",
            "score": parseInt(scoreData["Cut Call"])
          },
          {
            "call_brief": "Didn't Pick",
            "score": parseInt(scoreData["Didn't Pick"])
          },
          {
            "call_brief": "Call Later",
            "score": parseInt(scoreData["Call Later"])
          },
          {
            "call_brief": "Interested",
            "score": parseInt(scoreData["Interested"])
          },
          {
            "call_brief": "Not Interested",
            "score": parseInt(scoreData["Not Interested"])
          }
        ]
      }, { headers })
      .then((data) => {
        if (data.status == "200") {
        
          tostSuccess("Score added successful!");
       

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }


  function sendtaskdesc() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Addtaskdetails", {
        "TaskHeading": livetaskcat,
        "desc": taskdesc,
        "task_type": task_type

      }, { headers })
      .then((data) => {
        if (data.status == "200") {

          tostSuccess("Description added successful!");
       

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getTaskDesc(pitchCatData) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_set_task_desc?TaskHeading=".concat(pitchCatData),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          settaskdesc(data.data.desc);
          
          if (!data.data.task_type) {
            settask_type("");
            console.log('present');
        }else{
          
          console.log('not present');
          settask_type(data.data.task_type);
        }
        }

        // setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log("error", err));
  }

  
  function sendtaskscore() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Add_TaskScores", {
        "TaskHeading": livetaskcat,
        "taskScore": [
          {
            "TaskStatus": "Assigned",
            "score": parseInt(scoreTask["Assigned"])
          },
          {
            "TaskStatus": "InProgress",
            "score": parseInt(scoreTask["InProgress"])
          },
          {
            "TaskStatus": "Canceled",
            "score": parseInt(scoreTask["Canceled"])
          },
          {
            "TaskStatus": "Completed",
            "score": parseInt(scoreTask["Completed"])
          },
         
        ]
      }, { headers })
      .then((data) => {
        if (data.status == "200") {
        
          tostSuccess("Score added successful!");
       

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getScriptCallingInstrucation(pitchCatData) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/Get_script_callEnding_instruction?organisation_id=".concat(org) +
          `&scripts_types=${pitchCatData.pitchCat}`,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setinvalid(data.data.instruction[0]);
          setCallCut(data.data.instruction[1]);
          setDidntPick(data.data.instruction[2]);
          setCallLater(data.data.instruction[3]);
          setInterest(data.data.instruction[4]);
          setnotInterest(data.data.instruction[5]);
        }

        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }
  const getTrashData = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_deleted_script_types", { headers })
      .then((data) => {
        if (data.status == "200") {
          setTrash(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    checkadmin();
    getScript();
    getTask();
    getTrashData();
  }, [1]);

  function removeSpcae(item) {
    var callCategory = item;
    callCategory = callCategory.split(" ").join("");
    return callCategory;
  }

  const [errorMessage1, setErrorMessage] = useState("");

  function updataData1({ name, value }) {
    setPitchData({ ...pitchCatData, [name]: value });
  }

  const getpart1Latest = (pitchCatData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/par1_latest?orgid=".concat(
          org,
          "&script_catagory=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updatelivecat(
            pitchCatData.activeScript
              ? pitchCatData.activeScript
              : pitchCatData.pitchCat
          );
          updateversionId1(data.data.version_id);
          updateshort_intro(data.data.short_intro);
          Part1.short_intro = data.data.short_intro;
          updateask_permission(data.data.ask_permission);
          Part1.ask_permission = data.data.ask_permission;
          updateconfirmation(data.data.confirmation);
          Part1.confirmation = data.data.confirmation;
          updatehero_line(data.data.hero_line);
          Part1.hero_line = data.data.hero_line;
          updateif_no(data.data.if_no);
          Part1.if_no = data.data.if_no;
          updateif_yes(data.data.if_yes);
          Part1.if_yes = data.data.if_yes;
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };
  const getpart2Latest = (pitchCatData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/par2_latest?orgid=".concat(
          org,
          "&script_catagory=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateversionId2(data.data.version_id);
          updateshort_pitch(data.data.shot_pitch);
          Part2.shot_pitch = data.data.shot_pitch;
          updateif_nagative(data.data.if_nagative);
          Part2.if_nagative = data.data.if_nagative;
          updateif_postive(data.data.if_postive);
          Part2.if_postive = data.data.if_postive;
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };

  const getpart3Latest = (pitchCatData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/par3_latest?orgid=".concat(
          org,
          "&script_catagory=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateversionId3(data.data.version_id);
          updateask_questions(data.data.ask_questions);
          Part3.ask_questions = data.data.ask_questions;
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };
  const getpart4Latest = (pitchCatData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/par4_latest?orgid=".concat(
          org,
          "&script_catagory=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setInputFieldsOH(data.data.questions_set);
          updateversionId4(data.data.version_id);
          updateobjection(data.data.questions_set);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };
  const getpart5Latest = (pitchCatData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/par5_latest?orgid=".concat(
          org,
          "&script_catagory=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateversionId5(data.data.version_id);
          updateconclusion(data.data.conclusion);
          Part5.conclusion = data.data.conclusion;
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };
  const getpart6Latest = (pitchCatData) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/par6_latest?orgid=".concat(
          org,
          "&script_catagory=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateversionId6(data.data.version_id);
          updatecall_savior(data.data.call_savior);
          Part6.call_savior = data.data.call_savior;
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };
  function sendPitchCat(value) {
    updatelivetaskcat();
    Setcurrentcat(value);
    let pitchCatData = { pitchCat: value };
    setPitchData(pitchCatData);
    getScriptGoals(pitchCatData);
    getScriptCallingInstrucation(pitchCatData);
    scriptVote(value);
    callPerformance(value);
    setVoteData({
      part1up: "0",
      part1down: "0",
      part2up: "0",
      part2down: "0",
      part3up: "0",
      part3down: "0",
      part4up: "0",
      part4down: "0",
      part5up: "0",
      part5down: "0",
      part6up: "0",
      part6down: "0",
    });

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    getpart1Latest(pitchCatData);

    axios
      .get(
        "/get_scrips_votes_count?orgid=".concat(
          org,
          `&script_catagory=${value}`
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setVoteData({
            part1up: data.data[0].up_vote_count,
            part1down: data.data[0].down_vote_count,
            part2up: data.data[1].up_vote_count,
            part2down: data.data[1].down_vote_count,
            part3up: data.data[2].up_vote_count,
            part3down: data.data[2].down_vote_count,
            part4up: data.data[3].up_vote_count,
            part4down: data.data[3].down_vote_count,
            part5up: data.data[4].up_vote_count,
            part5down: data.data[4].down_vote_count,
            part6up: data.data[5].up_vote_count,
            part6down: data.data[5].down_vote_count,
          });

          getscriptscore(value);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));

    getpart2Latest(pitchCatData);
    getpart3Latest(pitchCatData);
    getpart4Latest(pitchCatData);
    getpart5Latest(pitchCatData);
    getpart6Latest(pitchCatData);
  }

  function sendTaskCat(value){

    updatelivecat('');
    updatelivetaskcat(value);
    getTaskDesc(value);

    gettaskscore(value);




  }

  const deleteScript = (value) => {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .put(`/Delete_ScriptsType?script_types_name=${value}`, {}, { headers })
      .then((data) => {
        if (data.status == "200") {
          getScript();
          getTrashData();

          toast.success("Script deleted successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };


  const gettaskscore = (value) => {
    const headers = {
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(`/get_set_task_score?TaskHeading=${value}`, { headers })
      .then((data) => {
        if (data.status == "200") {

          setscoreTask({
            "Assigned": data.data.taskScore[0].score,
            "InProgress": data.data.taskScore[1].score,
            "Canceled": data.data.taskScore[2].score,
            "Completed": data.data.taskScore[3].score,
          });
        
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };




  const restoredeleteScript = (value) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .put(`/Restore_ScriptsType?script_types_name=${value}`, {}, { headers })
      .then((data) => {
        if (data.status == "200") {
          getScript();
          getTrashData();

          toast.success("Script restored successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  };

  function scriptStatus(item) {
    const script_status = item.isactivate;

    if (script_status == false) {
      return (
        <i
          className="i bi-toggle2-off"
          style={{ cursor: "pointer" }}
          onClick={() => changeStatus(item.script_type_name)}
        ></i>
      );
    } else if (script_status == true) {
      return (
        <i
          className="i bi-toggle2-on"
          style={{ cursor: "pointer" }}
          onClick={() => changeStatus(item.script_type_name)}
        ></i>
      );
    }
  }


  function toclearcat(){
    updatelivecat('');
    updatelivetaskcat('');

    
  }

  function sendPart1Data(e) {
    if (e) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post("/script_part1", Part1, { headers })
      .then((data) => {
        if (data.status == "200") {
          dispatch(getProgressBarStatus({ access_token: Auth }));
          toast.success("Salute & Confirmation updated successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
          getpart1Latest(pitchCatData);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }
  function sendPart2Data(e) {
    if (e) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/script_part2", Part2, { headers })
      .then((data) => {
        if (data.status == "200") {
          // window.location.reload();
          getpart2Latest(pitchCatData);
          toast.success("Pitch and information updated successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }
  function sendPart3Data(e) {
    if (e) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/script_part3", Part3, { headers })
      .then((data) => {
        if (data.status == "200") {
          getpart3Latest(pitchCatData);
          toast.success("Consult updated successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function sendPart4Data(item, item2) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/script_part4", {
        organisation_id: org,
        script_catagory: item,
        questions_set: item2,
      }, { headers })
      .then((data) => {
        if (data.status == "200") {
          getpart4Latest(pitchCatData);
          toast.success("Objection handling updated successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function sendPart5Data(e) {
    if (e) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/script_part5", Part5, { headers })
      .then((data) => {
        if (data.status == "200") {
          // window.location.reload();
          getpart5Latest(pitchCatData);
          toast.success("Call Ending updated successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }
  function sendPart6Data(e) {
    if (e) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/script_part6", Part6, { headers })
      .then((data) => {
        if (data.status == "200") {
          // window.location.reload();
          getpart6Latest(pitchCatData);
          toast.success("Call savior updated successfully", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function submitTask() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/add_MoreTaskHeadings", obj2, { headers })
      .then((data) => {
        if (data.status == "200") {
          getTask();
          tostSuccess(data.data.message);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  function checkWords() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/CheckWords?text=".concat(scripttext), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          updatesuggtext(data.data);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function stringChange(item) {

    //for (const [key, value] of Object.entries(item)) { return <td>{key}: {value}</td>; }

    let sugg = JSON.stringify(item);
    let lengthsugg = Object.keys(item).length;
    let wordssugg = Object.keys(item);

    wordssugg = JSON.stringify(wordssugg);

    sugg = sugg.replaceAll("{", " ");
    sugg = sugg.replaceAll("}", " ");
    sugg = sugg.replaceAll('"', "");
    sugg = sugg.replaceAll(".,", ". #");
    sugg = sugg.replaceAll(":", " : ");

    wordssugg = wordssugg.replaceAll("[", " ");
    wordssugg = wordssugg.replaceAll("]", " ");
    wordssugg = wordssugg.replaceAll('"', "");
    wordssugg = wordssugg.replaceAll(",", ", ");

    return (
      <div>
        {" "}
        {
          item==''?<div><img
          src={nosuggestion}  height={300}
        /></div>:<><div className="badge_circle totalsugg">
          <span className="circle__content">
            <b>{lengthsugg}</b>
          </span>
        </div>
        <p>
          words to change: 
          <b>{wordssugg}</b>
        </p>
        <p>{sugg}</p>
        <br /></>

        }
        
      </div>
    );
  }

  document.body.onkeyup = function (e) {
    if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
      checkWords();
    }
  };

  function updateScriptText(item) {
    updatescripttext(item);
  }

  function getProgress(item1, item2) {
    item1 = (item1 / (item1 + item2)) * 100;
    return item1;
  }

  function addGoal() {
    let livegoalObj = {
      ...livegoal,
      scripts_types: pitchCatData.pitchCat,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Add_Your_Goal_To_Script", livegoalObj, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          toast.success(" Goal Changed!", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getscriptscore(value) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_set_script_score?scripts_types=".concat(value), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          setscoreData({
            "In Valid": data.data.callscore[0].score,
            "Cut Call": data.data.callscore[1].score,
            "Didn't Pick": data.data.callscore[2].score,
            "Call Later": data.data.callscore[3].score,
            "Interested": data.data.callscore[4].score,
            "Not Interested": data.data.callscore[5].score,
          });

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  
  function gettokenvalue() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_sunday_currency_count", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          let x = Math.round(data.data);

          setCoin(x);


        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

 function submitScript(){


  
  setPart1({
    organisation_id: org,
    script_catagory: livecat,
    short_intro: scriptresp["Intro"]["short_intro"],
    confirmation: scriptresp["Intro"]["confirmation"],
    hero_line: scriptresp["Intro"]["hero_line"],
    ask_permission: scriptresp["Intro"]["ask_permission"],
    if_yes: scriptresp["Intro"]["if_yes"],
    if_no: scriptresp["Intro"]["if_no"],
  });
  setPart2({
    organisation_id: org,
    script_catagory: livecat,
    shot_pitch: scriptresp["Pitch"]["short_pitch"],
    if_postive: "",
    if_nagative: "",
  });
  setPart3({
    organisation_id: org,
    script_catagory: livecat,
    ask_questions: scriptresp["consulting questions and talk"],
  });
  setPart5({
    organisation_id: org,
    script_catagory: livecat,
    conclusion: scriptresp["call closing"],
  });
  setPart6({
    organisation_id: org,
    script_catagory: livecat,
    call_savior: "",
  });

  setInputFieldsOH(scriptresp["possible objections"]);

 // Indicate that the state has been updated
 setIsStateUpdated(true);
 }

 useEffect(() => {
  if (isStateUpdated) {
    let e = null;
    sendPart1Data(e);
    sendPart2Data(e);
    sendPart3Data(e);
    sendPart4Data(livecat, scriptresp["possible objections"]);
    sendPart5Data(e);
    sendPart6Data(e);

    // Reset the flag
    setIsStateUpdated(false);
  }
}, [isStateUpdated]);




  function valueInstrucation(index, instructn) {
    if (instructn[index]?.action?.task.length > 0) {
      return `task ${instructn[index]?.action?.task}`;
    } else {
      return `script ${instructn[index]?.action?.script}`;
    }
  }

  const handleClick = (e) => {
    if (e.target.value.search("script") === 0) {
      let obj = {
        call_brief: "In Valid",
        action: {
          script: e.target.value.slice(7),
          task: "",
        },
      };
      setinvalid(obj);
    } else {
      let obj1 = {
        call_brief: "In Valid",
        action: {
          script: "",
          task: e.target.value.slice(5),
        },
      };
      setinvalid(obj1);
    }
  };

  const handleClickCut = (e) => {
    if (e.target.value.search("script") === 0) {
      let obj = {
        call_brief: "Cut Call",
        action: {
          script: e.target.value.slice(7),
          task: "",
        },
      };
      setCallCut(obj);
    } else {
      let obj1 = {
        call_brief: "Cut Call",
        action: {
          script: "",
          task: e.target.value.slice(5),
        },
      };
      setCallCut(obj1);
    }
  };
  const handleClickPick = (e) => {
    if (e.target.value.search("script") === 0) {
      let obj = {
        call_brief: "Didn't Pick",
        action: {
          script: e.target.value.slice(7),
          task: "",
        },
      };
      setDidntPick(obj);
    } else {
      let obj1 = {
        call_brief: "Didn't Pick",
        action: {
          script: "",
          task: e.target.value.slice(5),
        },
      };
      setDidntPick(obj1);
    }
  };

  const handleClickLater = (e) => {
    if (e.target.value.search("script") === 0) {
      let obj = {
        call_brief: "Call Later",
        action: {
          script: e.target.value.slice(7),
          task: "",
        },
      };
      setCallLater(obj);
    } else {
      let obj1 = {
        call_brief: "Call Later",
        action: {
          script: "",
          task: e.target.value.slice(5),
        },
      };
      setCallLater(obj1);
    }
  };
  const handleClickInterest = (e) => {
    if (e.target.value.search("script") === 0) {
      let obj = {
        call_brief: "Interested",
        action: {
          script: e.target.value.slice(7),
          task: "",
        },
      };
      setInterest(obj);
    } else {
      let obj1 = {
        call_brief: "Interested",
        action: {
          script: "",
          task: e.target.value.slice(5),
        },
      };
      setInterest(obj1);
    }
  };
  const handleClicknotInterest = (e) => {
    if (e.target.value.search("script") === 0) {
      let obj = {
        call_brief: "Not Interested",
        action: {
          script: e.target.value.slice(7),
          task: "",
        },
      };
      setnotInterest(obj);
    } else {
      let obj1 = {
        call_brief: "Not Interested",
        action: {
          script: "",
          task: e.target.value.slice(5),
        },
      };
      setnotInterest(obj1);
    }
  };

  const handleUpdate = () => {
    let finalinstruction = [
      invalid,
      callCut,
      didntPick,
      callLater,
      interest,
      notInterest,
    ];

    let instructionObj = {
      organisation_id: org,
      scripts_types: pitchCatData.pitchCat,
      instruction: finalinstruction,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Add_callEnding_instruction", instructionObj, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          tostSuccess(data.data.message);
          // window.location.reload();
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  };

  const duplicateData = (type, selected) => {
    let pitchCatData = { pitchCat: selected, activeScript: currentcat };
    if (type === "part1") {
      getpart1Latest(pitchCatData);
    } else if (type === "part2") {
      getpart2Latest(pitchCatData);
    } else if (type === "part3") {
      getpart3Latest(pitchCatData);
    } else if (type === "part4") {
      getpart4Latest(pitchCatData);
    } else if (type === "part5") {
      getpart5Latest(pitchCatData);
    } else if (type === "part6") {
      getpart6Latest(pitchCatData);
    }
    toast.success(`Script copied from ${selected}`, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  //gpt script

  function getCallScript() { 

    tostWaitMsg();
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post('/Sunday_Script_GPT',scriptreq, { headers })
      .then((data) => {
        if (data.status == "200") {
          setscriptresp(data.data);
          gettokenvalue();
        }
      })
      .catch((err) => console.log(err));
  }

  function gettaskTemp() { 

    tostWaitMsg();
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post('/Sunday_Task_GPT',taskreq, { headers })
      .then((data) => {
        if (data.status == "200") {
          settaskresp(data.data);
          gettokenvalue();
        }
      })
      .catch((err) => console.log(err));
  }

  function scriptVote(script) {
    var script = script;


    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_scrips_votes_count?orgid=".concat(
          org,
          "&script_catagory=",
          script
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          set_data_vote([
            {
              name: "Step 1",
              down: data.data[0].down_vote_count,
              up: data.data[0].up_vote_count,
            },
            {
              name: "Step 2",
              down: data.data[1].down_vote_count,
              up: data.data[1].up_vote_count,
            },
            {
              name: "Step 3",
              down: data.data[2].down_vote_count,
              up: data.data[2].up_vote_count,
            },
            {
              name: "Step 4",
              down: data.data[3].down_vote_count,
              up: data.data[3].up_vote_count,
            },
            {
              name: "Step 5",
              down: data.data[4].down_vote_count,
              up: data.data[4].up_vote_count,
            },
            {
              name: "Call Savior",
              down: data.data[5].down_vote_count,
              up: data.data[5].up_vote_count,
            },
          ]);

          //////////////////////////////Suggestion Call///////////////////////////////
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function callPerformance(script) {
    var script = script;

    filterDate2.script_category = script;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/get_call_performances/", filterDate2, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          set_data_performance([
            {
              name: "None",
              call: data.data.none,
            },
            {
              name: "Salute & Con",
              call: data.data.salute_confirmation,
            },
            {
              name: "Pitch & Info",
              call: data.data.pitch_information,
            },
            {
              name: "Question & Talk",
              call: data.data.ask_question_talk,
            },
            {
              name: "Objection",
              call: data.data.objection_handling,
            },
            {
              name: "Call Closing",
              call: data.data.closing,
            },
          ]);

          //////////////////////////////Suggestion Call///////////////////////////////
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="container">
      
      
      <div className="row">
        <div className="col-12 col-sm-4">
          <div>
            <br/>
            <ul className="nav nav-tabs border border-0" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={toclearcat}
                >
                  <i className="bi bi-card-heading"></i> All Calls
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={toclearcat}
                >
                  {" "}
                  <i className="bi bi-bicycle"></i> All Tasks
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
              
                <div className="conatiner">
                  <br />
                  <button
                    type="button"
                    className={ feedOrgCategory == ''? "btn btn-dark bg-gradient click-me-btn btn-sm":"btn btn-dark bg-gradient btn-sm" }
                    id="popsript"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseScript"
                    aria-expanded="false"
                    aria-controls="flush-collapseScript"
                  >
                    {" "}
                    <i className="bi bi-plus-circle-fill"></i> Add Category
                  </button>
                </div>
                <br />

                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item bg-transparent border-0">
                    <div
                      id="flush-collapseScript"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div>
                      <mark>
                        What calls do you make?
                        &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('You can mention all the call that your team does. Eg: Sales call, Follow up call, Cold call, Payment confirmation call, Facebook lead call, Verification call.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                        </mark>
                        {inputFields?.map((data, index) => {
                          const { fullName } = data;
                          return (
                            <div className="row my-3" key={index}>
                              <div className="col-sm-10">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    onChange={(evnt) =>
                                      handleChange(index, evnt)
                                    }
                                    value={fullName}
                                    name="fullName"
                                    className="form-control form-control-sm"
                                    placeholder="Category"
                                  />
                                </div>
                              </div>

                              <div className="col">
                                {inputFields.length !== 1 ? (
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={removeInputFields}
                                  >
                                    x
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={addInputField}
                            >
                              <i className="bi bi-plus-circle"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={sendData}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <p>
                  <mark>
                    <i className="bi bi-file-text-fill"></i> All call
                    categories <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('Add all type of calls that you make. Eg: Sales Call, Follow up call, Payment confirmation call')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                </p>
                <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "500px" }}
                >
                  <div className="conatiner">
                    <ol className="list-group">
                      {feedOrgCategory &&
                        feedOrgCategory?.map((item) => (
                          item.script_type_name == currentcat?<li className="list-group-item d-flex justify-content-between align-items-start text-white bg-primary border border-dark">
                          <div className="ms-2 me-auto">
                            {item.script_type_name}
                          </div>
                          {scriptStatus(item)}
                          <i
                            title="View"
                            className="bi bi-eye-fill"
                            onClick={() => {
                              sendPitchCat(item.script_type_name);
                            }}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>
                          <i
                            title="Delete"
                            className="bi bi-trash3-fill"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={() => {
                              deleteScript(item.script_type_name);
                            }}
                          ></i>
                        </li>
                          :<li className="list-group-item d-flex justify-content-between align-items-start text-dark bg-white border border-dark">
                          <div className="ms-2 me-auto">
                            {item.script_type_name}
                          </div>
                          {scriptStatus(item)}
                          <i
                            title="View"
                            className="bi bi-eye-fill"
                            onClick={() => {
                              sendPitchCat(item.script_type_name);
                            }}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>
                          <i
                            title="Delete"
                            className="bi bi-trash3-fill"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={() => {
                              deleteScript(item.script_type_name);
                            }}
                          ></i>
                        </li>
                        ))}
                    </ol>
                  </div>
                </div>

         
                <div className="conatiner ">
                 
                  <span className="badge bg-light text-dark border border-dark"  data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"><i className="bi bi-trash3-fill"></i> Trash</span>
                </div>
                <br />

                <div className="collapse" id="collapseExample">
                  <p>
                    <mark>
                      <i className="bi bi-file-text-fill"></i> All deleted
                      script categories <i className="bi bi-arrow-down"></i>
                    </mark>
                  </p>
                  <div
                    className="overflow-auto p-3"
                    style={{ maxWidth: "1000px", maxHeight: "500px" }}
                  >
                    <div className="conatiner">
                      <ol className="list-group">
                        {trashData &&
                          trashData?.map((item) => (
                            <li className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark">
                              <div className="ms-2 me-auto">
                                {item.script_type_name}
                              </div>
                              <i
                                title="View"
                                className="bi bi-eye-fill"
                                onClick={() => {
                                  sendPitchCat(item.script_type_name);
                                }}
                                style={{
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                              ></i>
                              <i
                                title="Restore"
                                className="bi bi-arrow-counterclockwise"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  restoredeleteScript(item.script_type_name);
                                }}
                              ></i>
                            </li>
                          ))}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                
                <div className="conatiner">
                  <br />
                  <button
                    type="button"
                    className={ feedOrgTask == ''? "btn btn-dark bg-gradient click-me-btn btn-sm":"btn btn-dark bg-gradient btn-sm" }
                    id="popsript"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTag"
                    aria-expanded="false"
                    aria-controls="flush-collapseScript"
                  >
                    {" "}
                    <i className="bi bi-plus-circle-fill"></i> Add Task
                  </button>
                </div>
                <br />
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item bg-transparent border-0">
                    <div
                      id="flush-collapseTag"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div>
                        <mark>Add Tasks &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('You can mention all the tasks that your team does. Eg: Document verification, Send proposal, Kyc check, Collect documents')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                        {inputFields2?.map((element, index) => {
                          return (
                            <div className="row my-3" key={index}>
                              <div className="col-sm-10">
                                <div className="form-group text-info">
                                  <input
                                    type="text"
                                    onChange={(e) => handleChange2(index, e)}
                                    value={element.heading || ""}
                                    name="heading"
                                    className="form-control form-control-sm border border-secondary"
                                    placeholder="Task heading"
                                  />
                                </div>
                                <br />
                                <div className="form-group text-info">
                                  <input
                                    type="text"
                                    onChange={(e) => handleChange2(index, e)}
                                    value={element.description || ""}
                                    name="description"
                                    className="form-control form-control-sm border border-secondary"
                                    placeholder="Description"
                                  />
                                </div>
                              </div>

                              <div className="col">
                                {inputFields2.length !== 1 ? (
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={removeInputFields2}
                                  >
                                    x
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={() => addInputField2()}
                            >
                              <i className="bi bi-plus-circle"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => submitTask()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <p>
                  <mark>
                    <i className="bi bi-bicycle"></i> All task categories{" "}
                    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('Add all type of tasks that your team does. Eg: Fix a demo, Send proposal, Document verification')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                </p>
                <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "700px" }}
                >
                  <div className="conatiner">
                    <ol className="list-group">
                      {feedOrgTask &&
                        feedOrgTask?.map((item) => (
                          <li className="list-group-item list-group-item-action border-dark bg-white" aria-current="true">
        <div className="d-flex w-100 justify-content-between">
        
         <div className="ms-2 me-auto">
            <p className="mb-1"><b>{item.heading}</b></p>
        <small >{item.description}</small>
         </div>
      
        <i
                            title="View"
                            className="bi bi-eye-fill"
                            onClick={() => {
                              sendTaskCat(item.heading);
                            }}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>
                          </div>
      </li>
                        ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-8">
        <br />
         
          <div className="col-12">
          
      
            
            

            {livecat != "" ? (
              <>
              <div><mark>
              <i className="bi bi-laptop-fill"></i>  You can plan your call here.{" "}
              <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1("Plan your calls in sections so that you won't miss out on important points.")}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark></div>
                  <br/>
                  <div className="row">
                <div className="col-12 col-sm-8">
                <div className="input-group mb-3 ">
                  <input
                    type="text"
                    className="form-control border-dark"
                    onChange={updateGoal}
                    name="your_goal"
                    value={livegoal.your_goal}
                    placeholder=" What is the goal behind this call?"
                    aria-label="Script Goal"
                    aria-describedby="button-addon2"
                  />
                  <button
                    onClick={addGoal}
                    className="btn bg-dark text-white"
                    type="button"
                    id="button-addon2"
                  >
                    {" "}
                    <i className="bi bi-arrow-repeat"></i> Change
                  </button>
                </div>
                </div>
                <div className="col-12 col-sm-4" align='right'>
                <button type="button" onClick={gettokenvalue} className="btn btn-info border-dark  bg-gradient position-relative" data-bs-toggle="modal" data-bs-target="#AiModal" ><i className="bi bi-robot"></i> Ai Generator <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
  beta
  <span className="visually-hidden">unread messages</span>
</span>
</button>&nbsp;
                <button type="button" className="btn btn-warning border-dark bg-gradient" data-bs-toggle="modal" data-bs-target="#TutorialModal" ><i className="bi bi-easel2-fill"></i> Tutorial</button>
                </div>
                </div>
               
               
                <br />
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingOne"
                    >
                      <button
                        className="accordion-button border border-dark"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          className="bi bi-1-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
                          />
                        </svg>
                        &nbsp; Salute & Confirmation - Greeting 
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body bg-dark text-white">
                        
                        {/* <button
                          type="button"
                          className="btn btn-info"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                        >
                          {" "}
                          <lord-icon
                            src="https://cdn.lordicon.com/alzqexpi.json"
                            trigger="hover"
                            colors="primary:#ffffff,secondary:#ffffff,tertiary:#e8b730,quaternary:#30c9e8,quinary:#ebe6ef"
                            state="hover-2"
                            style={{ width: "50px", height: "50px" }}
                          ></lord-icon>
                        </button> */}
                      

                        <span className="badge bg-light text-dark cursor" data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"><i className="bi bi-pencil"></i> Edit</span>
                        
                        {" "}
                        {short_intro} &nbsp;{confirmation}&nbsp;
                        <br />
                        <br /> <b>{hero_line}</b>
                        &nbsp;
                        
                        <br /> {ask_permission}
                     
                        <br/><br/>
                          {" "}
                          <span className="badge bg-secondary">If Yes:</span> {if_yes}
                          <br />
                          <span className="badge bg-secondary">If No:</span> {if_no}
                         
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingTwo"
                    >
                      <button
                        className="accordion-button border border-dark collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          className="bi bi-2-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                        </svg>
                        &nbsp; Pitch and Information
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body bg-dark text-white">
                       
                        {/* <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                          className="btn btn-transperent"
                        >
                          {" "}
                          <lord-icon
                            src="https://cdn.lordicon.com/alzqexpi.json"
                            trigger="hover"
                            colors="primary:#ffffff,secondary:#ffffff,tertiary:#e8b730,quaternary:#30c9e8,quinary:#ebe6ef"
                            state="hover-2"
                            style={{ width: "50px", height: "50px" }}
                          ></lord-icon>
                        </button>{" "} */}
                       

                        <span className="badge bg-light text-dark cursor" data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"><i className="bi bi-pencil"></i> Edit</span>

                        &nbsp; {short_pitch}
                        <br />
                        <br />
                        
                        <span className="badge bg-secondary">If Postive:</span> {if_postive}
                          <br />
                          <span className="badge bg-secondary">If Negative:</span> {if_nagative}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingThree"
                    >
                      <button
                        className="accordion-button border border-dark collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          className="bi bi-3-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                        </svg>
                        &nbsp; Consult - Ask Question and Talk
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body bg-dark text-white">
                       
                        {/* <button
                          type="button"
                          className="btn btn-transperent"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"
                        >
                          {" "}
                          <lord-icon
                            src="https://cdn.lordicon.com/alzqexpi.json"
                            trigger="hover"
                            colors="primary:#ffffff,secondary:#ffffff,tertiary:#e8b730,quaternary:#30c9e8,quinary:#ebe6ef"
                            state="hover-2"
                            style={{ width: "50px", height: "50px" }}
                          ></lord-icon>
                        </button> */}
                        

                        <span className="badge bg-light text-dark cursor" data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"><i className="bi bi-pencil"></i> Edit</span>

                        &nbsp;
                        <p style={{ whiteSpace: "pre-line" }}>
                          {" "}
                          {ask_questions}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingFour"
                    >
                      <button
                        className="accordion-button border border-dark collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          className="bi bi-4-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176c-.218.352-.438.703-.657 1.055ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z" />
                        </svg>
                        &nbsp; Objection Handling
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body bg-dark text-white">
                        
                        {/* <button
                          type="button"
                          className="btn btn-transperent"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal4"
                        >
                          {" "}
                          <lord-icon
                            src="https://cdn.lordicon.com/alzqexpi.json"
                            trigger="hover"
                            colors="primary:#ffffff,secondary:#ffffff,tertiary:#e8b730,quaternary:#30c9e8,quinary:#ebe6ef"
                            state="hover-2"
                            style={{ width: "50px", height: "50px" }}
                          ></lord-icon>
                        </button> */}
                        
                        <span className="badge bg-light text-dark cursor" data-bs-toggle="modal"
                          data-bs-target="#exampleModal4"><i className="bi bi-pencil"></i> Edit</span>
                        <br />
                        {objection &&
                          objection?.map((item) => (
                            <div>
                              <strong>{item.question}</strong>
                              <br />
                              <br />
                              <p>{item.answer}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingFive"
                    >
                      <button
                        className="accordion-button border border-dark collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          className="bi bi-5-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z" />
                        </svg>
                        &nbsp; Call Ending  (call to action)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body bg-dark text-white">
                     
                        {/* <button
                          type="button"
                          className="btn btn-transperent"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal5"
                        >
                          {" "}
                          <lord-icon
                            src="https://cdn.lordicon.com/alzqexpi.json"
                            trigger="hover"
                            colors="primary:#ffffff,secondary:#ffffff,tertiary:#e8b730,quaternary:#30c9e8,quinary:#ebe6ef"
                            state="hover-2"
                            style={{ width: "50px", height: "50px" }}
                          ></lord-icon>
                        </button>{" "} */}
                        
                        
                        <span className="badge bg-light text-dark cursor" data-bs-toggle="modal"
                          data-bs-target="#exampleModal5"><i className="bi bi-pencil"></i> Edit</span>
                        &nbsp;
                        <p style={{ whiteSpace: "pre-line" }}> {conclusion}</p>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-headingFive"
                    >
                      <button
                        className="accordion-button border border-dark collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        Call Savior
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body bg-dark text-white">
                       
                        {/* <button
                          type="button"
                          className="btn btn-transperent"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal6"
                        >
                          {" "}
                          <lord-icon
                            src="https://cdn.lordicon.com/alzqexpi.json"
                            trigger="hover"
                            colors="primary:#ffffff,secondary:#ffffff,tertiary:#e8b730,quaternary:#30c9e8,quinary:#ebe6ef"
                            state="hover-2"
                            style={{ width: "50px", height: "50px" }}
                          ></lord-icon>
                        </button> */}
                     
                        <span className="badge bg-light text-dark cursor" data-bs-toggle="modal"
                          data-bs-target="#exampleModal6"><i className="bi bi-pencil"></i> Edit</span>
                        &nbsp;
                        <p style={{ whiteSpace: "pre-line" }}> {call_savior}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <p align='center'><mark><i className="bi bi-dpad-fill"></i> Set Instructions: </mark> </p>
                <br />

                <div className='row'>
                  <div className="col-12 col-sm-6"> <div className="card text-right border-dark">
                  <div className="card-header">What to do after this call? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('Select call script/task to be performed according to the call brief of this call. For example if call brief of sales call is interested perform send proposal task.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></div>
                  <div className="card-body">
                    {/* <div className="row mb-3">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-4 col-form-label"
                      >
                        In Valid:
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="form-select bg-white text-dark border border-dark"
                          id="inputGroupSelect04"
                          name="In Valid"
                          value={valueInstrucation(0, instruction)}
                          aria-label="Example select with button addon"
                          onChange={(e) => handleClick(e, 0)}
                        >
                          <option selected>Select Script/Task</option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option
                                className="bg-secondary text-white"
                                value={`script ${item.script_type_name}`}
                              >
                                {item.script_type_name}
                              </option>
                            ))}
                          {feedOrgTask &&
                            feedOrgTask?.map((item) => (
                              <option
                                className="bg-primary text-white"
                                value={`task ${item.heading}`}
                              >
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-4 col-form-label"
                      >
                        Cut Call:
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="form-select bg-white text-dark border border-dark"
                          id="inputGroupSelect04"
                          name="Cut Call"
                          value={valueInstrucation(1, instruction)}
                          aria-label="Example select with button addon"
                          onChange={(e) => handleClick(e, 1)}
                        >
                          <option selected>Select Script/Task</option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option
                                className="bg-secondary text-white"
                                value={`script ${item.script_type_name}`}
                              >
                                {item.script_type_name}
                              </option>
                            ))}
                          {feedOrgTask &&
                            feedOrgTask?.map((item) => (
                              <option
                                className="bg-primary text-white"
                                value={`task ${item.heading}`}
                              >
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-4 col-form-label"
                      >
                        Didn't Pick:
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="form-select bg-white text-dark border border-dark"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          name="Didn't Pick"
                          value={valueInstrucation(2, instruction)}
                          onChange={(e) => handleClick(e, 2)}
                        >
                          <option selected>Select Script/Task</option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option
                                className="bg-secondary text-white"
                                // value={item.script_type_name}
                                value={`script ${item.script_type_name}`}
                              >
                                {item.script_type_name}
                              </option>
                            ))}
                          {feedOrgTask &&
                            feedOrgTask?.map((item) => (
                              <option
                                className="bg-primary text-white"
                                value={`task ${item.heading}`}
                              >
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-4 col-form-label"
                      >
                        Call Later:
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="form-select bg-white text-dark border border-dark"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          name="Call Later"
                          value={valueInstrucation(3, instruction)}
                          onChange={(e) => handleClick(e, 3)}
                        >
                          <option selected>Select Script/Task</option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option
                                className="bg-secondary text-white"
                                value={`script ${item.script_type_name}`}
                              >
                                {item.script_type_name}
                              </option>
                            ))}
                          {feedOrgTask &&
                            feedOrgTask?.map((item) => (
                              <option
                                className="bg-primary text-white"
                                value={`task ${item.heading}`}
                              >
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-4 col-form-label"
                      >
                        Interest:
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="form-select bg-white text-dark border border-dark"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          name="Interest"
                          value={valueInstrucation(4, instruction)}
                          onChange={(e) => handleClick(e, 4)}
                        >
                          <option selected>Select Script/Task</option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option
                                className="bg-secondary text-white"
                                value={`script ${item.script_type_name}`}
                              >
                                {item.script_type_name}
                              </option>
                            ))}
                          {feedOrgTask &&
                            feedOrgTask?.map((item) => (
                              <option
                                className="bg-primary text-white"
                                value={`task ${item.heading}`}
                              >
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-4 col-form-label"
                      >
                        Not Interest:
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="form-select bg-white text-dark border border-dark"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          name="Not Interest"
                          value={valueInstrucation(5, instruction)}
                          onChange={(e) => handleClick(e, 5)}
                        >
                          <option selected>Select Script/Task</option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option
                                className="bg-secondary text-white"
                                value={`script ${item.script_type_name}`}
                              >
                                {item.script_type_name}
                              </option>
                            ))}
                          {feedOrgTask &&
                            feedOrgTask?.map((item) => (
                              <option
                                className="bg-primary text-white"
                                value={`task ${item.heading}`}
                              >
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div> */}
                    <DropDown
                      label={"In Valid"}
                      handleChange={handleClick}
                      feedOrgCategory={feedOrgCategory}
                      feedOrgTask={feedOrgTask}
                      valueObj={invalid}
                    />
                    <DropDown
                      label={"Cut Call"}
                      handleChange={handleClickCut}
                      feedOrgCategory={feedOrgCategory}
                      feedOrgTask={feedOrgTask}
                      valueObj={callCut}
                    />
                    <DropDown
                      label={"Didn't Pick"}
                      handleChange={handleClickPick}
                      feedOrgCategory={feedOrgCategory}
                      feedOrgTask={feedOrgTask}
                      valueObj={didntPick}
                    />
                    <DropDown
                      label={"Call Later"}
                      handleChange={handleClickLater}
                      feedOrgCategory={feedOrgCategory}
                      feedOrgTask={feedOrgTask}
                      valueObj={callLater}
                    />
                    <DropDown
                      label={"Interested"}
                      handleChange={handleClickInterest}
                      feedOrgCategory={feedOrgCategory}
                      feedOrgTask={feedOrgTask}
                      valueObj={interest}
                    />
                    <DropDown
                      label={"Not Interested"}
                      handleChange={handleClicknotInterest}
                      feedOrgCategory={feedOrgCategory}
                      feedOrgTask={feedOrgTask}
                      valueObj={notInterest}
                    />
                    {/* <a href="#" className="btn btn-dark">
                      Update
                    </a> */}
                    <button onClick={handleUpdate} className="btn btn-dark btn-sm">
                    <i className="bi bi-fan"></i> Update
                    </button>
                  </div>
                </div></div>
                <div className="col-12 col-sm-6">
                <div className="card text-right border-dark">
                  <div className="card-header">Set score for this call (0-10) <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('Your team will earn points on the bases of the calls brief.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></div>
                  <div className="card-body">

                  <div className="row mb-3">
                  <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                  In Valid:
      </label>
      <div className="col-sm-3">
      <input onChange={updateScore}
                    name="In Valid"
                    value={scoreData["In Valid"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
      </div>
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                  Cut Call:
      </label>
      <div className="col-sm-3">
      <input onChange={updateScore}
                    name="Cut Call"
                    value={scoreData["Cut Call"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
      </div>
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                  Didn't Pick:
      </label>
      <div className="col-sm-3">
      <input onChange={updateScore}
                    name="Didn't Pick"
                    value={scoreData["Didn't Pick"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
      </div>
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                  Call Later:
      </label>
      <div className="col-sm-3">
      <input onChange={updateScore}
                    name="Call Later"
                    value={scoreData["Call Later"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
      </div>
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                  Interested:
      </label>
      <div className="col-sm-3">
      <input onChange={updateScore}
                    name="Interested"
                    value={scoreData["Interested"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
      </div>
                  </div>
                  <div className="row mb-3">
                  <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                  Not Interested:
      </label>
      <div className="col-sm-3">
      <input onChange={updateScore}
                    name="Not Interested"
                    value={scoreData["Not Interested"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
      </div>
                  </div>

                  <button
                          type="button"
                          onClick={()=>sendscore(pitchCatData.pitchCat)}
                          className="btn btn-dark btn-sm"
                        >
                          <i className="bi bi-controller"></i> Set
                        </button>
</div>
</div>

                </div>
                </div>
                <br />
                <p align='center'><mark><i className="bi bi-rulers"></i> Measure script performance: </mark> </p>
                <br />
                <div className='row'>
                  <div className="col-6">
                  <div className="card text-right border-dark">
                  <div className="card-header">Script votes by team <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('All the votes made by your team will be reflected here.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></div>
                  <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                  <BarChart
                     
                      data={data_vote}
                     
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="up" fill="#0275d8" />
                      <Bar dataKey="down" fill="#d9534f" />
                    </BarChart>
                    </ResponsiveContainer>
</div>
</div>
                 
                    
                  </div>
                  <div className="col-6">
                  <div className="card text-right border-dark">
                  <div className="card-header">Call length <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('Till what part your prospects stay on the call.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></div>
                  <div className="card-body">
                  <ResponsiveContainer width="100%" height={200}>
                  <LineChart
                      data={data_performance}
                     
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line dataKey="call" fill="#8884d8" />
                    </LineChart>
                    </ResponsiveContainer>
</div>
</div>
                 
                    
                  </div>
                  
                </div>

               
                <br />

                <div className="modal fade" id="AiModal" tabIndex={-1} aria-labelledby="AiModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="AiModalLabel"><i className="bi bi-robot"></i> Script Generator <span class="badge rounded-pill bg-danger">Beta</span></h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
             
              <div className="row">
        <div className="col-12 col-sm-4">
          <br/>
<div className="container">
<div className="card shadow bg-gradient bg-light">
  <div className="card-body">
    <div className="row">
      <div className="col-2">  <img src={logo} alt="" width="50" height="36"></img></div>
      <div className="col-10">
      <h4>
          Design with
          <small className="text-primary">  SundayAi</small>
</h4>
      </div>
    </div>

       
          <br/>
          <div className="row">
          <div className="col-6 col-sm-6">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-phone-fill"></i> Spoken Language:
  </label>
  <select className="form-select border border-secondary" type="text" name='spoken_language'  value={scriptreq.spoken_language}
                                    onChange={updatascriptreq}  aria-label="Default select example">
  <option selected="">Select Language</option>
  <option value="English">English</option>
  <option value="Hindi">Hindi</option>
  <option value="Marathi">Marathi</option>
  <option value="Telugu">Telugu</option>
  <option value="Tamil">Tamil</option>
</select>
</div>
          </div>
          <div className="col-6 col-sm-6">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-pen-fill"></i> Written Language:
  </label>
  <select className="form-select border border-secondary" name='written_language'  value={scriptreq.written_language}
                                    onChange={updatascriptreq}  aria-label="Default select example">
  <option selected="">Select Language</option>
  <option value="English">English</option>
  <option value="Hindi">Hindi</option>
  <option value="Marathi">Marathi</option>
  <option value="Tamil">Tamil</option>
  <option value="Telugu">Telugu</option>
  <option value="Tamil">Tamil</option>
</select>
</div>
          </div>
          </div>
          


<div className="row">
<div className="col-12">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-tags-fill"></i> Call Type:
  </label>
  <select className="form-select border border-secondary" name='calltype'  value={scriptreq.calltype}
                                    onChange={updatascriptreq}  aria-label="Default select example">
  <option selected="">Select call type</option>
  <option value="Cold call">Cold call</option>
  <option value="Warm calls">Warm calls</option>
<option value="Follow-up calls">Follow-up calls</option>
<option value="Cross-selling and upselling calls">Cross-selling and upselling calls</option>
<option value="Renewal or retention calls">Renewal or retention calls</option>
<option value="Appointment setting calls">Appointment setting calls</option>
<option value="Qualification calls">Qualification calls</option>
<option value="Service calls">Service calls</option>
<option value="Maintenance calls">Maintenance calls</option>
<option value="Quality control calls">Quality control calls</option>
<option value="Order management calls">Order management calls</option>
<option value="Vendor management calls">Vendor management calls</option>
<option value="Training calls">Training calls</option>
<option value="Feedback calls">Feedback calls</option>
<option value="Sales support calls">Sales support calls</option>

</select>
</div>
</div>
</div>

<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">
  <i className="bi bi-text-paragraph"></i> Description:
  </label>
  <textarea
    className="form-control border border-white inside2"
    name='description'  value={scriptreq.description}
                                    onChange={updatascriptreq} 
    id="exampleFormControlTextarea1"
    rows={6}
    defaultValue={""}
  />
  <label htmlFor="exampleFormControlTextarea1" className="form-label text-dark">
  <b>Note: Better script with a detailed description. Script generation might take few minutes.</b>
  </label>
</div>

<button type="button" className="btn btn-dark pop" onClick={getCallScript}>
<i className="bi bi-arrow-right"></i> Generate 
</button>

  </div>
  
</div>
</div>
          
        </div>
        <div className="col-12 col-sm-8">
        {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <h3><span className="badge bg-warning text-dark bg-gradient border border-secondary shadow"><i className="bi bi-cash"></i> {coin}</span></h3>

        </div> */}
          {
            scriptresp["Intro"]["short_intro"] ==''? <div align="center">
            <br/><br/><br/><br/><br/>
            <img src={empty} height={350} />
            </div>
            :<div className="container">
            
            <br/>
            <div className="card border border-0 shadow-lg">
            <div class="card-header bg-dark text-white">
            <i className="bi bi-robot"></i> Script Generator
    </div>
            <div className="card-body">
      
<div className="row justify-content-md-end">
  
  <div className="col-5" align='right'>
    <div className="dropdown mb-2">
    <button className="btn btn-dark me-md-2 btn-sm" type="button" onClick={() => {navigator.clipboard.writeText(scriptresp)}}>
    <i className="bi bi-clipboard"></i>
    </button>
    <button onClick={getCallScript} className="btn btn-dark me-md-2 btn-sm" type="button">
    <i className="bi bi-arrow-clockwise"></i>
    </button>
<button
  className="btn btn-dark border border-dark btn-sm"
  type="button"
  id="dropdownMenu2"
  data-bs-toggle="collapse"
  data-bs-target="#collapseimport"
  aria-expanded="false"
  aria-controls="collapseimport"
>
  <i className="bi bi-cloud-download"></i> Import 
</button>
</div>

<div className="collapse" id="collapseimport">


<div className="card border-0">
<div className="card-body border-0" align='left'>


 <p>
Overwrite to excisting call category: 

<div className="input-group input-group-sm mb-3">
<select className="form-select border-secondary bg-light" id="inputGroupSelect02"
onChange={(e)=>updatelivecat(e.target.value)}
>
<option selected="">Choose...</option>
{feedOrgCategory &&
               feedOrgCategory?.map((item) => (
                 <><option value={item.script_type_name}>{item.script_type_name}</option></>
               ))}
</select>

<button
className="btn btn-dark"
type="button"
onClick={submitScript}
id="button-addon2"
>
Submit
</button>
</div>


</p>  
</div>

</div>
</div>
</div>
  
</div>

<div>

</div>
  
     
 
      <span className="badge bg-primary">Salute & Confirmation - Greeting</span>
           
      <p>{scriptresp["Intro"]["short_intro"]} {scriptresp["Intro"]["confirmation"]} <b>{scriptresp["Intro"]["hero_line"]}</b>
        <br/>{scriptresp["Intro"]["ask_permission"]}<br/><br/><i className="bi bi-signpost-split"></i> <span className="text-secondary">If yes:</span> {scriptresp["Intro"]["if_yes"]}<br/><i className="bi bi-signpost-split"></i> <span className="text-secondary">If no:</span> {scriptresp["Intro"]["if_no"]}</p>

        <span className="badge bg-danger">Pitch and Information</span>
       
   <p><b>{scriptresp["Pitch"]["short_pitch"]}</b></p>

   
    
    <span className="badge bg-warning text-dark">Consult - Ask Question and Talk</span>
    <p><pre>{scriptresp["consulting questions and talk"]}</pre></p>

    <span className="badge bg-secondary ">Objection Handling</span>
    <p>{scriptresp["possible objections"].map((item)=>(
      <><p><b>{item["question"]}</b><br/>{item["answer"]}</p></>
    ))}</p>
<span className="badge bg-success">Call Ending  (call to action)</span>
    <p>{scriptresp["call closing"]}</p>


  
            </div></div>
            </div>
          }
          

        </div>
        </div>
               

                  </div>
           
            </div>
          </div>
        </div>
        <>

 
  {/* Modal */}

</>

       

          <div className="modal fade" id="TutorialModal" tabIndex={-1} aria-labelledby="TutorialModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="TutorialModalLabel"><i className="bi bi-easel2-fill"></i> Script lab tutorial</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
              <blockquote className="blockquote" align='center'>
    <p><i className="bi bi-arrow-right-circle"></i> How to design a script like a pro?</p>
  </blockquote>
                <div align='center'>
                <iframe width={840} height={472.5} src="https://www.youtube.com/embed/fyRHuGpLk3g" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </div>

                  </div>
              <div className="modal-footer border-0">
                <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                
              </div>
            </div>
          </div>
        </div>
              </>
            ) :
            livetaskcat != ""?(
              <> <mark>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <>
  {/* Button trigger modal */}
 
  <button type="button" onClick={gettokenvalue} className="btn btn-info border-dark  bg-gradient position-relative" data-bs-toggle="modal" data-bs-target="#modeltaskAI" ><i className="bi bi-robot"></i> Ai Generator <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
  beta
  <span className="visually-hidden">unread messages</span>
</span>
</button>
  {/* Modal task ai */}
  <div
    className="modal fade"
    id="modeltaskAI"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content">
        <div className="modal-header border-0">
          <h5 className="modal-title" id="staticBackdropLabel">
          <i className="bi bi-robot"></i> Task template Generator <span class="badge rounded-pill bg-danger">Beta</span>
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
             
              <div className="row">
        <div className="col-12 col-sm-4">
          <br/>
<div className="container">
<div className="card shadow bg-gradient bg-light">
  <div className="card-body">
    <div className="row">
      <div className="col-2">  <img src={logo} alt="" width="50" height="36"></img></div>
      <div className="col-10">
      <h4>
          Design with
          <small className="text-primary">  SundayAi</small>
</h4>
      </div>
    </div>

       
          <br/>
          <div className="row">
<div className="col-12">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-bicycle"></i> Task Type:
  </label>
  <select className="form-select border border-secondary" name='tasktype'  value={taskreq.tasktype}
                                    onChange={updatataskreq}  aria-label="Default select example">
  <option selected="">Select task type</option>
  <option value="Email">Email</option>
  <option value="SMS">SMS</option>
  <option value="Whatsapp">Whatsapp</option>


</select>
</div>
</div>
</div>
          <div className="row">
          <div className="col-6 col-sm-6">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-phone-fill"></i> Spoken Language:
  </label>
  <select className="form-select border border-secondary" type="text" name='spoken_language'  value={taskreq.spoken_language}
                                    onChange={updatataskreq}  aria-label="Default select example">
  <option selected="">Select Language</option>
  <option value="English">English</option>
  <option value="Hindi">Hindi</option>
  <option value="Marathi">Marathi</option>
  <option value="Telugu">Telugu</option>
  <option value="Tamil">Tamil</option>
</select>
</div>
          </div>
          <div className="col-6 col-sm-6">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-pen-fill"></i> Written Language:
  </label>
  <select className="form-select border border-secondary" name='written_language'  value={taskreq.written_language}
                                    onChange={updatataskreq}  aria-label="Default select example">
  <option selected="">Select Language</option>
  <option value="English">English</option>
  <option value="Hindi">Hindi</option>
  <option value="Marathi">Marathi</option>
  <option value="Tamil">Tamil</option>
  <option value="Telugu">Telugu</option>
  <option value="Tamil">Tamil</option>
</select>
</div>
          </div>
          </div>
          

          <div className="row">
<div className="col-12">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-tags-fill"></i> Task subtype:
  </label>
  <select className="form-select border border-secondary" name='subtype'  value={taskreq.subtype}
                                    onChange={updatataskreq}  aria-label="Default select example">
  <option selected="">Select subtype</option>
  {subTypeList[taskreq.tasktype]?.map((item)=>(<><option value={`${item["name"]} - ${item["description"]}` }>{item["name"]} - {item["description"]}</option></>))}



</select>
</div>
</div>
</div>         




<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">
  <i className="bi bi-text-paragraph"></i> Description:
  </label>
  <textarea
    className="form-control border border-white inside2"
    name='description'  value={taskreq.description}
                                    onChange={updatataskreq} 
    id="exampleFormControlTextarea1"
    rows={6}
    defaultValue={""}
  />
  <label htmlFor="exampleFormControlTextarea1" className="form-label text-dark">
  <b>Note: Better template with a detailed description.</b>
  </label>
</div>

<button type="button" className="btn btn-dark pop" onClick={gettaskTemp}>
<i className="bi bi-arrow-right"></i> Generate 
</button>

  </div>
  
</div>
</div>
          
        </div>
        <div className="col-12 col-sm-8">
        {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <h3><span className="badge bg-warning text-dark bg-gradient border border-secondary shadow"><i className="bi bi-cash"></i> {coin}</span></h3>

        </div> */}
          {
            taskresp["Description"] ==''? <div align="center">
            <br/><br/><br/><br/><br/>
            <img src={empty} height={350} />
            </div>
            :<div className="container">
            
            <br/>
            <div className="card border border-0 shadow-lg">
            <div class="card-header border-0 bg-dark text-white">
            <i className="bi bi-robot"></i> Task Generator
    </div>
            <div className="card-body">
      
<div className="row justify-content-md-end">
  
  <div className="col-5" align='right'>
    <div className="dropdown mb-2">
    <button className="btn btn-dark me-md-2 btn-sm" type="button" onClick={() => {navigator.clipboard.writeText(scriptresp)}}>
    <i className="bi bi-clipboard"></i>
    </button>
    <button onClick={gettaskTemp} className="btn btn-dark me-md-2 btn-sm" type="button">
    <i className="bi bi-arrow-clockwise"></i>
    </button>
<button
  className="btn btn-dark border border-dark btn-sm"
  type="button"
  id="dropdownMenu2"
  data-bs-toggle="collapse"
  data-bs-target="#collapseimport"
  aria-expanded="false"
  aria-controls="collapseimport"
>
  <i className="bi bi-cloud-download"></i> Import 
</button>
</div>

<div className="collapse" id="collapseimport">


<div className="card border-0">
<div className="card-body border-0" align='left'>


 <p>
Overwrite to excisting task category: 

<div className="input-group input-group-sm mb-3">
<select className="form-select border-secondary bg-light" id="inputGroupSelect02"
onChange={(e)=>updatelivecat(e.target.value)}
>
<option selected="">Choose...</option>
{feedOrgTask &&
               feedOrgTask?.map((item) => (
                 <><option value={item.heading}>{item.heading}</option></>
               ))}
</select>

<button
className="btn btn-dark"
type="button"
onClick={submitScript}
id="button-addon2"
>
Submit
</button>
</div>


</p>  
</div>

</div>
</div>
</div>
  
</div>

<div>

</div>
  
     
<span className="badge bg-white border border-dark text-dark mb-2">Description</span>
    <p><pre>{taskresp["Description"]}</pre></p>

    <span className="badge bg-dark mb-2">Template</span>
    
    <p><pre>{taskresp["Template"]}</pre></p>


  
            </div></div>
            </div>
          }
          

        </div>
        </div>
               

                  </div>
       
      </div>
    </div>
  </div>
</>

                </div>
               
               

              <i className="bi bi-laptop-fill"></i>  Set task description guide.{" "}
              <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1("You can utilize this area to create message and email templates that will make your teams job easier")}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark> <div className="card border border-dark">
                
  <div className="card-body">
  <div className="mb-3 col-6">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  Task type:
  </label>
  <select
    className="form-select form-select-sm"
    aria-label=".form-select-sm example"
    value={task_type} onChange={(e)=>settask_type(e.target.value)}
  > 
  <option selected="" value="">Select task type</option>
   <option value="Set Visit">Set Visit</option>
<option value="Send SMS">Send SMS</option>
<option value="Send Email">Send Email</option>
<option value="Send WhatsApp">Send WhatsApp</option>
<option value="Set virtual meeting">Set virtual meeting</option>
<option value="Send LinkedIn Message">Send LinkedIn Message</option>
<option value="Prepare Presentation">Prepare Presentation</option>
<option value="Schedule Next Follow-up">Schedule Next Follow-up</option>
<option value="Send Proposal">Send Proposal</option>
<option value="Gather Customer Feedback">Gather Customer Feedback</option>
<option value="Prepare Contract">Prepare Contract</option>
<option value="Send Invoice">Send Invoice</option>
<option value="Arrange Product Demo">Arrange Product Demo</option>
<option value="Collect Required Documents">Collect Required Documents</option>
<option value="Research Client Background">Research Client Background</option>
<option value="Review Client Requirements">Review Client Requirements</option>
<option value="Send Meeting Reminder">Send Meeting Reminder</option>
<option value="Provide Post-Meeting Summary">Provide Post-Meeting Summary</option>

  </select>
</div>

  <div className="mb-3">
  <label for="exampleFormControlTextarea1" className="form-label">Create task template: </label>


  {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" value={taskdesc} onChange={(e)=>settaskdesc(e.target.value)}></textarea> */}

  <div className="editor-container">
  <CKEditor
        editor={ClassicEditor}
        data={taskdesc}
        onChange={(event, editor) => {
          const data = editor.getData();
          settaskdesc(data);
        }}
        config={{
          // You can customize the toolbar or other configurations here
          toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        }}
      />
    </div>

</div>

<button type="button" className="btn btn-dark btn-sm" onClick={sendtaskdesc}>Submit</button>

  </div>
                <></>
                              </div><br/>
              <div className="d-flex">
              <div className="col-12 col-sm-6">
              <div className="card text-right border-dark">
                <div className="card-header">Set score for this task (0-10) <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip1('Your team will earn points on the bases of the calls brief.')}
  >
    <i className="bi bi-info-circle-fill text-dark"></i>
  </OverlayTrigger></div>
                <div className="card-body">

                <div className="row mb-3">
                <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                Assigned:
    </label>
    <div className="col-sm-3">
    <input onChange={updateTaskScore}
                  name="Assigned"
                  value={scoreTask["Assigned"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
    </div>
                </div>
                <div className="row mb-3">
                <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                In Progress:
    </label>
    <div className="col-sm-3">
    <input onChange={updateTaskScore}
                  name="InProgress"
                  value={scoreTask["InProgress"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
    </div>
                </div>
                <div className="row mb-3">
                <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                Canceled:
    </label>
    <div className="col-sm-3">
    <input onChange={updateTaskScore}
                  name="Canceled"
                  value={scoreTask["Canceled"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
    </div>
                </div>
                <div className="row mb-3">
                <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
                Completed:
    </label>
    <div className="col-sm-3">
    <input onChange={updateTaskScore}
                  name="Completed"
                  value={scoreTask["Completed"]} className="form-control form-control-sm" type="number" placeholder="Score" aria-label=".form-control-sm example" />
    </div>
                </div>

                <button
                        type="button"
                        onClick={()=>sendtaskscore()}
                        className="btn btn-dark"
                      >
                        <i className="bi bi-controller"></i> Set
                      </button>
</div>
</div>

              </div>
              </div>
              </>
            )
            
            :(
              <div align="center">
                
               
<br />
                <br />

                <h5 id="AiModalLabel"><i className="bi bi-phone-fill"></i> Design everyday calls and tasks</h5>
                
                <br />
                <br />
                <br />
                <br />
                <img
                  src={scriptview}  height={350}
                />
                <p><br/>Please choose a call or task</p>
              </div>
            )}

            <div>
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-8">
                          <h5>Salute & Confirmation - Greeting </h5>

                          <form onSubmit={sendPart1Data} id="regForm">
                            <div className="row g-2">
                              <div className="col-md">
                                <input
                                  type="hidden"
                                  name="short_intro"
                                  value={(Part1.script_catagory = livecat)}
                                  onChange={updataData}
                                />
                                <div className="form-floating text-dark">
                                  <textarea
                                    className="form-control border border-secondary"
                                    name="short_intro"
                                    value={Part1.short_intro}
                                    onChange={(e) => {
                                      updataData(e);
                                      updateScriptText(Part1.short_intro);
                                    }}
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                    required
                                  />
                                  <label htmlFor="floatingTextarea">
                                    Short intro
                                  </label>
                                </div>
                              </div>
                              <div className="col-md">
                                <div className="form-floating text-dark">
                                  <textarea
                                    className="form-control  border border-secondary"
                                    name="confirmation"
                                    value={Part1.confirmation}
                                    onChange={(e) => {
                                      updataData(e);
                                      updateScriptText(Part1.confirmation);
                                    }}
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                    
                                  />
                                  <label htmlFor="floatingTextarea">
                                    Confirmation (check identity of client)
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="form-floating text-dark">
                              <textarea
                                className="form-control border border-secondary"
                                name="hero_line"
                                placeholder="Leave a comment here"
                                value={Part1.hero_line}
                                onChange={(e) => {
                                  updataData(e);
                                  updateScriptText(Part1.hero_line);
                                }}
                                id="floatingTextarea"
                                style={{ height: "100px" }}
                               
                              />
                              <label htmlFor="floatingTextarea">
                                Hero line that defines your talk or product
                                (very important)
                              </label>
                            </div>
                            <br />
                            <div className="form-floating text-dark">
                              <textarea
                                className="form-control border border-secondary"
                                name="ask_permission"
                                value={Part1.ask_permission}
                                onChange={(e) => {
                                  updataData(e);
                                  updateScriptText(Part1.ask_permission);
                                }}
                                placeholder="Leave a comment here"
                                id="floatingTextarea"
                              />
                              <label htmlFor="floatingTextarea">
                                Ask permission to continue - optional
                              </label>
                            </div>
                            <br />
                            <div className="row g-2">
                              <div className="col-md">
                                <div className="form-floating text-dark">
                                  <textarea
                                    className="form-control  border border-danger"
                                    name="if_yes"
                                    value={Part1.if_yes}
                                    onChange={updataData}
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                   
                                  />
                                  <label htmlFor="floatingTextarea">
                                    If yes - optional
                                  </label>
                                </div>
                              </div>
                              <div className="col-md">
                                <div className="form-floating text-dark">
                                  <textarea
                                    className="form-control border border-danger"
                                    name="if_no"
                                    value={Part1.if_no}
                                    onChange={updataData}
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                   
                                  />
                                  <label htmlFor="floatingTextarea">
                                    If no - optional
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="dropdown">
                                  <label>
                                    <mark>Similar Script Part? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('If you think the content of this part could be similar to any of your previous script part, you can  copy it to this script, edit it later and save time.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                                    &nbsp;&nbsp;
                                  </label>
                                  <button
                                    className="btn btn-dark dropdown-toggle btn-sm"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-clipboard-check-fill"></i>{" "}
                                    Duplicate
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-dark"
                                    aria-labelledby="dropdownMenuButton2"
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {feedOrgCategory &&
                                      feedOrgCategory?.map((item) => (
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              duplicateData(
                                                "part1",
                                                item.script_type_name
                                              )
                                            }
                                          >
                                            {" "}
                                            {item.script_type_name}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div>
                                  <audio ref={audioRef}>
                                    <source
                                      src="https://salessunday-image.s3.ap-south-1.amazonaws.com/scriptAudio/part1.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                  <div
                                    className="btn btn-info btn-sm"
                                    onClick={handleTogglePlayback}
                                  >
                                    {isPlaying ? (
                                      <i className="bi bi-pause-circle-fill"></i>
                                    ) : (
                                      <i className="bi bi-play-circle-fill"></i>
                                    )}
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-dark btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  <i className="bi bi-arrow-right"></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div
                          className="col-4 sugg border-start overflow-auto"
                          id="sugg"
                        >
                          <p>
                            Suggestions <i className="bi bi-robot"></i>
                          </p>

                          {stringChange(suggtext)}

                          <img src={logo} width="55" height="40" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal2"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-8">
                          <h5>Pitch & Information</h5>
                          <br />
                          <form onSubmit={sendPart2Data} id="regForm">
                            <div className="form-floating">
                              <input
                                type="hidden"
                                name="short_intro"
                                value={(Part2.script_catagory = livecat)}
                                onChange={updataData}
                              />
                              <textarea
                                className="form-control border border-secondary"
                                name="shot_pitch"
                                value={Part2.shot_pitch}
                                onChange={(e) => {
                                  updataData(e);
                                  updateScriptText(Part2.shot_pitch);
                                }}
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: "200px" }}
                                
                              />
                              <label htmlFor="floatingTextarea2">
                                Short pitch that makes sense to the customer
                              </label>
                            </div>
                            <br />
                            <div className="row g-2">
                              <div className="col-md">
                                <div className="form-floating">
                                  <textarea
                                    className="form-control border border-danger"
                                    name="if_postive"
                                    value={Part2.if_postive}
                                    onChange={updataData}
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                 
                                  />
                                  <label htmlFor="floatingTextarea">
                                    If postive - optional
                                  </label>
                                </div>
                              </div>
                              <div className="col-md">
                                <div className="form-floating">
                                  <textarea
                                    className="form-control border border-danger"
                                    name="if_nagative"
                                    value={Part2.if_nagative}
                                    onChange={updataData}
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                  
                                  />
                                  <label htmlFor="floatingTextarea">
                                    If negative - optional
                                  </label>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="dropdown">
                                  <label>
                                    <mark>Similar Script Part? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('If you think the content of this part could be similar to any of your previous script part, you can  copy it to this script, edit it later and save time.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                                    &nbsp;&nbsp;
                                  </label>
                                  <button
                                    className="btn btn-dark dropdown-toggle btn-sm"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-clipboard-check-fill"></i>{" "}
                                    Duplicate
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-dark"
                                    aria-labelledby="dropdownMenuButton2"
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {feedOrgCategory &&
                                      feedOrgCategory?.map((item) => (
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              duplicateData(
                                                "part2",
                                                item.script_type_name
                                              )
                                            }
                                          >
                                            {" "}
                                            {item.script_type_name}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div>
                                  <audio ref={audioRef2}>
                                    <source
                                      src="https://salessunday-image.s3.ap-south-1.amazonaws.com/scriptAudio/part2.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                  <div
                                    className="btn btn-info btn-sm"
                                    onClick={handleTogglePlayback2}
                                  >
                                    {isPlaying2 ? (
                                      <i className="bi bi-pause-circle-fill"></i>
                                    ) : (
                                      <i className="bi bi-play-circle-fill"></i>
                                    )}
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-dark btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  <i className="bi bi-arrow-right"></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="col-4 sugg border-start">
                          <p>
                            Suggestions <i className="bi bi-robot"></i>
                          </p>

                          {stringChange(suggtext)}

                          <img src={logo} width="55" height="40" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal3"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-8">
                          <h5>Consult - Ask Question and Talk</h5>
                          <br />
                          <form onSubmit={sendPart3Data} id="regForm">
                            <div className="form-floating">
                              <input
                                type="hidden"
                                name="short_intro"
                                value={(Part3.script_catagory = livecat)}
                                onChange={updataData}
                              />
                              <textarea
                                name="ask_questions"
                                value={Part3.ask_questions}
                                onChange={(e) => {
                                  updataData(e);
                                  updateScriptText(Part3.ask_questions);
                                }}
                                className="form-control border border-secondary"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: "300px" }}
                               
                              />
                              <label htmlFor="floatingTextarea2">
                                Question the customer so that he can paint a
                                picture in his head.
                              </label>
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="dropdown">
                                  <label>
                                    <mark>Similar Script Part? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('If you think the content of this part could be similar to any of your previous script part, you can  copy it to this script, edit it later and save time.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                                    &nbsp;&nbsp;
                                  </label>
                                  <button
                                    className="btn btn-dark dropdown-toggle btn-sm"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-clipboard-check-fill"></i>{" "}
                                    Duplicate
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-dark"
                                    aria-labelledby="dropdownMenuButton2"
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {feedOrgCategory &&
                                      feedOrgCategory?.map((item) => (
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              duplicateData(
                                                "part3",
                                                item.script_type_name
                                              )
                                            }
                                          >
                                            {" "}
                                            {item.script_type_name}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div>
                                  <audio ref={audioRef3}>
                                    <source
                                      src="https://salessunday-image.s3.ap-south-1.amazonaws.com/scriptAudio/part3.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                  <div
                                    className="btn btn-info btn-sm"
                                    onClick={handleTogglePlayback3}
                                  >
                                    {isPlaying3 ? (
                                      <i className="bi bi-pause-circle-fill"></i>
                                    ) : (
                                      <i className="bi bi-play-circle-fill"></i>
                                    )}
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-dark btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  <i className="bi bi-arrow-right"></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="col-4 sugg border-start">
                          <p>
                            Suggestions <i className="bi bi-robot"></i>
                          </p>

                          {stringChange(suggtext)}

                          <img src={logo} width="55" height="40" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal4"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <h5>Objection Handling</h5>
                      <br />

                      <div className="tab">
                        {inputFieldsOH?.map((element, index) => {
                          return (
                            <div className="row my-3" key={index}>
                              <div className="col-sm-8">
                                <div className="form-group text-info">
                                  <input
                                    type="text"
                                    onChange={(e) => handleChangeOH(index, e)}
                                    value={element.question || ""}
                                    name="question"
                                    className="form-control border border-secondary"
                                    placeholder="Question"
                                  />
                                </div>
                                <br />
                                <div className="form-group text-info">
                                  <input
                                    type="text"
                                    onChange={(e) => handleChangeOH(index, e)}
                                    value={element.answer || ""}
                                    name="answer"
                                    className="form-control  border border-secondary"
                                    placeholder="Answer"
                                  />
                                </div>
                              </div>

                              <div className="col">
                                {inputFieldsOH.length !== 1 ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => removeInputFieldsOH(index)}
                                  >
                                    x
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            <div className="dropdown">
                              <label>
                                <mark>Similar Script Part? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('If you think the content of this part could be similar to any of your previous script part, you can  copy it to this script, edit it later and save time.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>&nbsp;&nbsp;
                              </label>
                              <button
                                className="btn btn-dark dropdown-toggle btn-sm"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-clipboard-check-fill"></i>{" "}
                                Duplicate
                              </button>
                              <ul
                                className="dropdown-menu dropdown-menu-dark"
                                aria-labelledby="dropdownMenuButton2"
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                }}
                              >
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <li>
                                      <div
                                        className="dropdown-item"
                                        onClick={() =>
                                          duplicateData(
                                            "part4",
                                            item.script_type_name
                                          )
                                        }
                                      >
                                        {" "}
                                        {item.script_type_name}
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                          <div className="row" style={{ marginLeft: "30px" }}>
                            <div className="col-sm-12">
                              <audio ref={audioRef4}>
                                <source
                                  src="https://salessunday-image.s3.ap-south-1.amazonaws.com/scriptAudio/part4.mp3"
                                  type="audio/mp3"
                                />
                              </audio>
                              <div
                                className="btn btn-info btn-sm"
                                onClick={handleTogglePlayback4}
                              >
                                {isPlaying4 ? (
                                  <i className="bi bi-pause-circle-fill"></i>
                                ) : (
                                  <i className="bi bi-play-circle-fill"></i>
                                )}
                              </div>
                              &nbsp;&nbsp;
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => addInputFieldOH()}
                              >
                                <i className="bi bi-plus-circle"></i> Add
                              </button>
                              &nbsp;&nbsp;
                              <button
                                className="btn btn-dark btn-sm"
                                data-bs-dismiss="modal"
                                onClick={() => sendPart4Data(livecat, inputFieldsOH)}
                              >
                                <i className="bi bi-arrow-right"></i> Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal5"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-8">
                          <h5>Call Ending  (call to action)</h5>
                          <br />
                          <form onSubmit={sendPart5Data} id="regForm">
                            <div className="form-floating">
                              <input
                                type="hidden"
                                name="short_intro"
                                value={(Part5.script_catagory = livecat)}
                                onChange={updataData}
                              />
                              <textarea
                                name="conclusion"
                                value={Part5.conclusion}
                                onChange={(e) => {
                                  updataData(e);
                                  updateScriptText(Part5.conclusion);
                                }}
                                className="form-control border border-secondary"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: "200px" }}
                               
                              />
                              <label htmlFor="floatingTextarea2">
                                How to close your call? And what to do next?{" "}
                              </label>
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="dropdown">
                                  <label>
                                    <mark>Similar Script Part? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('If you think the content of this part could be similar to any of your previous script part, you can  copy it to this script, edit it later and save time.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                                    &nbsp;&nbsp;
                                  </label>
                                  <button
                                    className="btn btn-dark dropdown-toggle btn-sm"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-clipboard-check-fill"></i>{" "}
                                    Duplicate
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-dark"
                                    aria-labelledby="dropdownMenuButton2"
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {feedOrgCategory &&
                                      feedOrgCategory?.map((item) => (
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              duplicateData(
                                                "part5",
                                                item.script_type_name
                                              )
                                            }
                                          >
                                            {" "}
                                            {item.script_type_name}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div>
                                  <audio ref={audioRef5}>
                                    <source
                                      src="https://salessunday-image.s3.ap-south-1.amazonaws.com/scriptAudio/part5.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                  <div
                                    className="btn btn-info btn-sm"
                                    onClick={handleTogglePlayback5}
                                  >
                                    {isPlaying5 ? (
                                      <i className="bi bi-pause-circle-fill"></i>
                                    ) : (
                                      <i className="bi bi-play-circle-fill"></i>
                                    )}
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-dark btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  <i className="bi bi-arrow-right"></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="col-4 sugg border-start">
                          <p>
                            Suggestions <i className="bi bi-robot"></i>
                          </p>

                          {stringChange(suggtext)}

                          <img src={logo} width="55" height="40" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal6"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-8">
                          <h5>Call Savior</h5>
                          <br />
                          <form onSubmit={sendPart6Data} id="regForm">
                            <div className="form-floating">
                              <input
                                type="hidden"
                                name="short_intro"
                                value={(Part6.script_catagory = livecat)}
                                onChange={updataData}
                              />
                              <textarea
                                name="call_savior"
                                value={Part6.call_savior}
                                onChange={(e) => {
                                  updataData(e);
                                  updateScriptText(Part6.call_savior);
                                }}
                                className="form-control border border-secondary"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: "300px" }}
                              />
                              <label htmlFor="floatingTextarea2">
                                Your reps will refer this section when your
                                prospect shows less interest.{" "}
                              </label>
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div className="dropdown">
                                  <label>
                                    <mark>Similar Script Part? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('If you think the content of this part could be similar to any of your previous script part, you can  copy it to this script, edit it later and save time.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                                    &nbsp;&nbsp;
                                  </label>
                                  <button
                                    className="btn btn-dark dropdown-toggle btn-sm"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-clipboard-check-fill"></i>{" "}
                                    Duplicate
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-dark"
                                    aria-labelledby="dropdownMenuButton2"
                                    style={{
                                      height: "200px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {feedOrgCategory &&
                                      feedOrgCategory?.map((item) => (
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              duplicateData(
                                                "part6",
                                                item.script_type_name
                                              )
                                            }
                                          >
                                            {" "}
                                            {item.script_type_name}
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div>
                                  <audio ref={audioRef6}>
                                    <source
                                      src="https://salessunday-image.s3.ap-south-1.amazonaws.com/scriptAudio/part6.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                  <div
                                    className="btn btn-info btn-sm"
                                    onClick={handleTogglePlayback6}
                                  >
                                    {isPlaying6 ? (
                                      <i className="bi bi-pause-circle-fill"></i>
                                    ) : (
                                      <i className="bi bi-play-circle-fill"></i>
                                    )}
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-dark btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  <i className="bi bi-arrow-right"></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="col-4 sugg border-start">
                          <p>
                            Suggestions <i className="bi bi-robot"></i>
                          </p>

                          {stringChange(suggtext)}

                          <img src={logo} width="55" height="40" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bottomAI">
      <a
                                  href={
                                    "https://web.whatsapp.com/send/?text=I need help to build my sales and operations process&type=phone_number&app_absent=0&phone=%2B918554988039"
                                  }
                                  target="whatsapp"
                                > <button
                type="button"
                className="btn btn-dark rounded-circle"
              >
                <h4>
                <i className="bi bi-headset"></i>
                </h4>
              </button>
              </a>
            </div>
      <ToastContainer />
    </div>
  );
}

export default Editscript;
