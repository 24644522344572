// src/CustomerDashboard.js

import React from 'react';

const Profile_cust = () => {

  return (
   <>
   <div className='container'><div className='row'>
   <div className='col-3'>
    <div className='card'>
      <div className='card-header'>
        User information
      </div>
      <div className='card-body'>

      <table className="table table-striped">
<thead>
<tr>
<th scope="col">Lead Details</th>
<th></th>
<th className="d-flex bd-highlight"> 

</th>
</tr>
</thead>
<tbody>

<tr>
<td><i className="bi bi-person-circle"></i> Contact Person</td><td>:</td>
<td> </td>
</tr>
<tr>
<td><i className="bi bi-building"/> Organization</td><td>:</td>
<td> </td>
</tr>
<tr>
<td><i className="bi bi-phone-fill"></i> Phone</td><td>:</td>
<td>  </td>
</tr>
<tr>
<td><i className="bi bi-envelope-fill"></i> Email</td><td>:</td>
<td>   </td>
</tr>
<tr>
<td><i className="bi bi-fan"></i> Priority</td><td>:</td>
<td></td>
</tr>
<tr>
<td><i className="bi bi-funnel"></i> Pipeline</td><td>:</td>
<td><b>LeadData.pipeline</b> 
</td>
</tr>
<tr>
<td><i className="bi bi-geo-fill"></i> Current Stage</td><td>:</td>
<td><b> 
</b></td>
</tr>
<tr>
<td><i className="bi bi-bezier2"></i> Lead Source</td><td>:</td>
<td>LeadData.Lead_Source</td>
</tr>
<tr>
<td><i className="bi bi-cash-coin"></i> Value</td><td>:</td>
<td>
<td> <b> </b></td>
<td className="text-secondary">  </td>          

         </td>
</tr>
<tr>
<td><i className="bi bi-calendar"></i> Expected closing</td><td>:</td>
<td></td>
</tr>
<tr>
<td><i className="bi bi-calendar-check"></i> Added at</td><td>:</td>
<td>LeadData.addedAt</td>
</tr>




</tbody>
</table>

      </div>
    </div>

   </div>
   <div className='col-4 border-end'></div>
   <div className='col-4'></div>
   </div></div>
   
   </>
  );
};

export default Profile_cust;
