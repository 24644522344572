import React, { Component, useEffect, useState,useMemo, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import avatar from "../media/147144.png";
import avatar1 from "../media/1234.png";
import support from "../media/support.png";
import company from "../media/company.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router'
import {
  NavLink,
} from "react-router-dom";
import { getProgressBarStatus } from "../redux/slices/homeSlice";
import {Upload_Organization_Image} from "../redux/slices/dataSlice";
import TreeDiagram from "../components/TreeDiagram";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid



const user_email = localStorage.getItem("user_email");
const organisation_name = localStorage.getItem("organisation_name");
const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const joining_date = localStorage.getItem("joining_date");
const role = localStorage.getItem("role");
const admin = localStorage.getItem("admin");
const token = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");
let formdata = new FormData();
const user_id = localStorage.getItem("token");
const access_token = localStorage.getItem("Auth");






const tostMsg = (msg) => {
  toast.success(msg, {
    position: "bottom-left",
    autoClose: 1000,
  });
};

const tostWaitMsg = () => {
  toast.info("Please Wait", {
    position: "bottom-left",
    autoClose: 1000,
  });
};



function iconRenderer(params, icon, headerName) {
  return (
    <div className="ag-header-cell">
      <i className={icon}></i>
      <span>{` ${headerName}`}</span>
    </div>
  );
}


function Profile() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const gridRef = useRef();
  
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%", minHeight: "350px" }), []);
  
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    role: "",
    title: "",
    password: "",
    role: "Member",
    parent_Id: "",
    organisation_name: organisation_name,
    organisation_id: org,
    team: "",
    EmployeeId: "",
    dateofjoining: "",
    dateofresignation: "",
    TimeZone:"Asia/Kolkata",
    IsEmployee: true,
    LocationName: "",
    Address: "",
    City: "",
    State: "",
    Country: "India",
    ZipCode: "",
    Device: {"web":false, "phone":false},
    Permissiontemplate: ""
  });


  const [editUserData, setEditUserData] = useState({
    user_id: "",
    name: "",
    email: "",
    mobile: "",
    role: "",
    title: "",
    password: "",
    role: "Member",
    parent_Id: "",
    organisation_name: organisation_name,
    organisation_id: org,
    team: "",
    EmployeeId: "",
    dateofjoining: "",
    dateofresignation: "",
    TimeZone:"Asia/Kolkata",
    IsEmployee: true,
    LocationName: "",
    Address: "",
    City: "",
    State: "",
    Country: "India",
    ZipCode: "",
    Device: {"web":false, "phone":false},
    Permissiontemplate: ""

  });


  const [view, setView] = useState('1');
  const [subView, setsubView] = useState('1');

  const handleItemClick = (newView) => {
    setView(newView);
  };

  const handleItemClick2 = (newView) => {
    setsubView(newView);
  };


  const [errorMessage, setErrorMessage] = useState("");

  const [orgData, setOrgData] = useState({
    organisation_name: organisation_name,
    organisation_type: "",
    organisation_category: "",
    total_employees: "",
    gst: "",
    state: "",
    pin_code: "",
    billing_address: "",
  });
  const [feedOrg, setOrgFeed] = useState("");
  const [profilepic, setProfilePic] = useState("");

  const [feed, setFeed] = useState([]);


  const [columnDefs] = useState([
    { 
      headerName: 'Emp Id', 
      field: "EmployeeId", 
      sortable: true, 
      filter: true,
      width: 100,
      // headerComponent: (params)=>iconRenderer(params, "bi bi-building",' User Name'),
      
    },
    { 
      headerName: 'User Name', 
      field: "user_name", 
      sortable: true, 
      filter: true,
      // headerComponent: (params)=>iconRenderer(params, "bi bi-building",' User Name'),
      
    },
    { 
      headerName: "Title", 
      field: "title", 
      sortable: true, 
      filter: true
      
    },
    { 
      headerName: "Email id", 
      field: "email", 
      sortable: true, 
      filter: true
      
    },
    { 
      headerName: "Role", 
      field: "role", 
      sortable: true, 
      filter: true
      
    },
    { 
      headerName: "Team", 
      field: "team", 
      sortable: true, 
      filter: true
      
    },
    { 
      headerName: "Admin", 
      field: "admin", 
      sortable: true, 
      filter: true
      
    },
    { 
      headerName: "Joining Date", 
      field: "joining_date", 
      sortable: true, 
      filter: true
      
    },
    { 
      headerName: "Mobile", 
      field: "mobile", 
      sortable: true, 
      filter: true
      
    },
    {
      headerName: "Actions",
      field: 'button',
      
      cellRenderer: (params) => {
        return (
          <span
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setusersetdata(params.data)}
          >
            <i className="bi bi-gear"></i> 
          </span>
        );
      }
    }
  ]);


 


// const defaultColDef = useMemo(() => {
//   return {
//       filter: 'agTextColumnFilter',
//       floatingfilter: true
//   };
// }, []);

  const [feedSopCategory, setSopCategory] = useState();
  const [feedOrgCategory, setOrgCategory] = useState();

  const [details, setdetails] = useState('true');

  const [myoperator, setMyoperator] = useState({
    partner_name: 'myoperator',
    api_token: "",
    secret_key: "",
    x_api_key: "",
    campaigns_id: ""
  });

  const [checkedIds, setCheckedIds] = useState([]);

  const [insertRole, setInsertRole] = useState('');
  const [roleslist, setRoleslist] = useState([]);


  const [insertTeam, setInsertTeam] = useState('');
  const [teamlist, setTeamlist] = useState([]);


  const [insertTemp, setInsertTemp] = useState({name:"", permissions:{'pipeline':[],'call_category':[],'task_category':[],'report':[],'training':[],'Apps':[]}});
  const [templist, setTemplist] = useState([]);

  const [editTemp,seteditTemp] = useState('');


  const [selectedList, setSelectedList] = useState({
    pipeline: [],
    call_category: [],
    task_category: [],
    report: [],
    training: [],
    Apps: []
  });

  console.log(selectedList, 'selectedList');

  const handleCheckboxChange2 = (item, listtype) => {
    if (selectedList[listtype].includes(item)) {
      // If item is already selected, remove it from the specified listtype array
      setSelectedList((prevState) => ({
        ...prevState,
        [listtype]: prevState[listtype].filter((selectedItem) => selectedItem !== item)
      }));
    } else {
      // If item is not selected, add it to the specified listtype array
      setSelectedList((prevState) => ({
        ...prevState,
        [listtype]: [...prevState[listtype], item]
      }));
    }
  };


  const [integrationlist, setIntegrationlist] = useState([{}]);

  const [feedOrgTask, setOrgTask] = useState();

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);



  function updateedittemp(item){

    seteditTemp(item.name);
    setSelectedList(item.permissions)

  }


  function addToArray(value, array) {
    if (typeof value !== 'string' || !Array.isArray(array)) {
        throw new TypeError('Invalid input: Expected a string and an array.');
    }

    // Remove leading and trailing whitespace from the value
    const trimmedValue = value.trim();

    // Remove any empty values from the array
    array = array.filter(item => item.trim() !== '');

    // Check if the trimmed value is empty
    if (trimmedValue === '') {
        return array;
    }

    // Capitalize the first letter and make the rest lowercase
    const formattedValue = trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1).toLowerCase();

    // Check if the formatted value already exists in the array
    if (!array.includes(formattedValue)) {
        array.push(formattedValue);
    }

    return array;
}

function addToTempArray(value, array) {
  if (!value.name || value.name.trim() === "") {
      console.log("Name cannot be empty.");
      return array;
  }

  // Capitalize the first letter of the name
  value.name = value.name.charAt(0).toUpperCase() + value.name.slice(1);

  // Check if the name already exists in the array
  const nameExists = array.some(item => item.name === value.name);

  if (nameExists) {
      console.log("Duplicate name found. Cannot add to the array.");
      return array;
  }

  // Add the value to the array if no duplicate found
  array.push(value);
  return array;
}


function updateToTempArray(value, array) {
  if (!value.name || value.name.trim() === "") {
      console.log("Name cannot be empty.");
      return array;
  }

  // Capitalize the first letter of the name
  value.name = value.name.charAt(0).toUpperCase() + value.name.slice(1);

  // Find the index of the existing object with the same name
  const existingIndex = array.findIndex(item => item.name === value.name);

  if (existingIndex !== -1) {
      // Replace the existing object with the new value
      array[existingIndex] = value;
      console.log("Existing name found. Replaced the value in the array.");
  } else {
      // Add the value to the array if no duplicate found
      array.push(value);
  }

  return array;
}





////////To select member for admin/////////

const handleCheckboxChange = (id) => {
  // Check if the ID is already in the array
  if (checkedIds.includes(id)) {
    setCheckedIds(checkedIds.filter(checkedId => checkedId !== id));
  } else {
    setCheckedIds([...checkedIds, id]);
  }
};  


  function updataMyOperatorData(e) {
    setMyoperator({ ...myoperator, [e.target.name]: e.target.value });
  }

  function updataEditUser(e) {
    setEditUserData({ ...editUserData, [e.target.name]: e.target.value });
  }

  function getSops() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setSopCategory(data.data);
        }
        
      })
      .catch((err) => console.log(err));
  }

  function getScript() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_all_script_types?orgID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgCategory(data.data);
        }

      })
      .catch((err) => console.log(err));
  }


  function addroles() {


    let item = (addToArray(insertRole, roleslist));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/roles/", 
        {'roles':item}, {headers}
      )
      .then((data) => {
        if (data.status == "200") {

          tostMsg("Updated successfully!");
          fetchroles();
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function addteam() {


    let item = (addToArray(insertTeam, teamlist));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/teams/", 
        {'teams':item}, {headers}
      )
      .then((data) => {
        if (data.status == "200") {

          tostMsg("Updated successfully!");
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function addtemp(type) {

    let item;

    if(type=='insert'){

      item = (addToTempArray(insertTemp, templist));

    }else{

      let updatetemp = {name:editTemp, permissions:selectedList}

      item = (updateToTempArray(updatetemp, templist));

    }
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/temp/", 
        {'temp':item}, {headers}
      )
      .then((data) => {
        if (data.status == "200") {

          tostMsg("Updated successfully!");
          fetchtemp();
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function fetchroles() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/getroles", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          
          if(data.data.status == 'not found'){
            setRoleslist([]);
          }else{
            setRoleslist(data.data.data.roles);
          }
          

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }


  function fetchteams() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/getteams", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          if(data.data.status == 'not found'){
            setTeamlist([]);
          }else{
            setTeamlist(data.data.data.teams);
          }


        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function fetchtemp() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/gettemp", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          if(data.data.status == 'not found'){
            setTemplist([]);
          }else{
            setTemplist(data.data.data.temp);
          }
          

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function getOtherApps(){


    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .get('/Get_Saved_Intigration',
       { headers })
      .then((data) => {
        setIntegrationlist(data.data);
  
      })
      .catch((err) => console.log(err));


  }

  function getTask() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/GetTaskHeadings?organisationID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgTask(data.data);
        }
        
      })
      .catch((err) => console.log(err));
  }


  function postMyoperator() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Add_Integration_info", 
        myoperator, {headers}
      )
      .then((data) => {
        if (data.status == "200") {

          tostMsg("Updated successfully!");
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function setusersetdata(item){

    console.log(item, 'item edit user');


    setEditUserData({
      user_id: item?.id || '',
      name: item?.user_name || '',
      email: item?.email || '',
      mobile: item?.mobile || '',
      role: item?.role || '',
      title: item?.title || '',
      parent_Id: item?.parent_Id || '',
      organisation_name: organisation_name || '',
      organisation_id: org || '',
      team: item?.team || '',
      EmployeeId: item?.EmployeeId || '',
      dateofjoining: item?.dateofjoining || '',
      dateofresignation: item?.dateofresignation || '',
      TimeZone: item?.TimeZone || '',
      IsEmployee: item?.IsEmployee || false,
      LocationName: item?.LocationName || '',
      Address: item?.Address || '',
      City: item?.City || '',
      State: item?.State || '',
      Country: item?.Country || '',
      ZipCode: item?.ZipCode || '',
      Device: {
        web: item?.Device?.web || false,
        phone: item?.Device?.phone || false,
      },
      Permissiontemplate: item?.Permissiontemplate || ''
    });
    
  
    
  

    if(item.role == 'Admin' ){

      console.log(item.userId);
      

      setCheckedIds(item.userId);

    }

  }


  const [call_data, set_call_data] = useState([
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
  ]);

  function getCall() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_user_call_count?userid=".concat(token), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          let user_call = Object.values(data.data);
          let user_date = Object.keys(data.data);

          set_call_data([
            {
              name: user_date[6],
              call: user_call[6],
            },
            {
              name: user_date[5],
              call: user_call[5],
            },
            {
              name: user_date[4],
              call: user_call[4],
            },
            {
              name: user_date[3],
              call: user_call[3],
            },
            {
              name: user_date[2],
              call: user_call[2],
            },
            {
              name: user_date[1],
              call: user_call[1],
            },
            {
              name: user_date[0],
              call: user_call[0],
            },
          ]);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  const validateForm = () => {
    let formErrors = {};

    // Name validation
    if (!userData.name) {
      formErrors.name = "Name is required";
    }

    // Email validation
    if (!userData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      formErrors.email = "Email is invalid";
    }

    // Mobile validation
    if (!userData.mobile) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(userData.mobile)) {
      formErrors.mobile = "Mobile number must be 10 digits";
    }

    // Role validation
    if (!userData.role) {
      formErrors.role = "Role is required";
    }

    // Password validation
    if (!userData.password) {
      formErrors.password = "Password is required";
    } else if (userData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters";
    }



    // Date of Joining validation
    if (!userData.dateofjoining) {
      formErrors.dateofjoining = "Date of joining is required";
    }

    // Country validation
    if (!userData.Country) {
      formErrors.Country = "Country is required";
    }

    // ZipCode validation
    if (userData.ZipCode && !/^\d{5,6}$/.test(userData.ZipCode)) {
      formErrors.ZipCode = "Zip code must be 5 or 6 digits";
    }

    setErrors(formErrors);

    // Return true if no errors
    return Object.keys(formErrors).length === 0;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [userData]);



  useEffect(() => {
    getCall();
    getSops();
    getScript();
    fetchteams();
    fetchtemp();
    getOtherApps();
    getTask();   
  }, []);

  function updataData(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }

  const handleDeviceChange = (event) => {
    const { id, checked } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      Device: {
        ...prevState.Device,
        [id]: checked,
      },
    }));
  };

  const handleDeviceChangeUpdate = (event) => {
    const { id, checked } = event.target;
    setEditUserData((prevState) => ({
      ...prevState,
      Device: {
        ...prevState.Device,
        [id]: checked,
      },
    }));
  };


  function sendOrgData(e) {
    setOrgData({ ...orgData, [e.target.name]: e.target.value });
  }

  //////////Get Users//////////////////////////////////////////////////////////////////////////////

  function fetchUserData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/getusers?organisation_id=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setFeed(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }
  useEffect(() => {
    fetchUserData();
    fetchroles();
  }, []);

  function sendData(e) {
   

    if(userData.role == 'Admin' || userData.role == 'Super Admin'){

      userData.admin = true; 

      if(userData.role == 'Admin'){

        userData['userId'] = checkedIds;

      }

    }else{
      
      userData.admin = false;

    } 
    

    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/adduser/", userData, { headers })
      .then((data) => {
        if (data.status == "200") {
          dispatch(getProgressBarStatus({ access_token: Auth }));
          tostMsg("User Added Successfully!");
          fetchUserData();
          setUserData({
            name: "",
            email: "",
            role: "",
            title: "",
            password: "",
            mobile: "",
            parent_Id: "",
            organisation_name: organisation_name,
            organisation_id: org,
          });
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function sendOData(e) {
    tostWaitMsg();
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/organisation/", orgData, { headers })
      .then((data) => {
        if (data.status == "200") {
          tostMsg("User Added Successfully!");
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function updataFile(e) {
    const file = e.target.files[0];

    formdata.append("Image_file", file);
    //setfile({...filedata , [e.target.name]:e.target.file[0]})
  }

  function uploadPicture() {
    

    dispatch(
      Upload_Organization_Image({
        access_token,
        data: formdata,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
       
        toast.success("Successfully uploaded!", {
          position: "bottom-left",
          autoClose: 1000,
        });
        navigate(0);
        document.getElementById("close").click();
      }
    });
  }

  if (admin == "true") {
    ////////////Get Organisation/////////////////////////////////////////////////////////////////////////////////////////

    function changemasking() {

      let toggle;

      if(details==true){
        toggle = false 
      }else{
        toggle = true 
      }
    
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .put("/number_massing_setting?value=".concat(toggle),{}, { headers })
        .then((data) => {
          if (data.status == "200") {
            document.getElementById("pills-contact-tab").click();
            tostMsg("Masking updated");
            
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => console.log(err));
    }

    

    function fetchOrgData(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get("/get_organisation?organisation_id=".concat(org), { headers })
        .then((data) => {
          if (data.status == "200") {
            setOrgFeed(data.data[0]);
            setdetails(data.data[0].Numbermassing);
            setProfilePic(data.data[0].ImageLink);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => console.log(err));
    }

    function generateavatar(text, foreground = "white", background = "black") {
      text = text.charAt(0);

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 200;
      canvas.height = 200;

      context.fillStyle = background;
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.font = "bold 100px verdana";
      context.fillStyle = foreground;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(text, canvas.width / 2, canvas.height / 2);

      return canvas.toDataURL("image/png");
    }


    //////////////////////////////////submit update user data///////////////////////////

    function sendUpdateddata(e) {

      if(editUserData.role == 'Admin' || editUserData.role == 'Super Admin'){

        editUserData.admin = true; 

        if(editUserData.role == 'Admin'){

          editUserData['userId'] = checkedIds;

        }

      }else{
        
        editUserData.admin = false;

      } 
      

      e.preventDefault();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .post("/update_users_info",editUserData , { headers })
        .then((data) => {
          if (data.status == "200") {

            tostMsg("Updated Successfully!");
            fetchUserData();
            
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => console.log(err));
    }




    

    return (
      <div className="container-fluid">
       
        <div className="row"><h4>Settings & Customization: </h4>
        <p>Configure your SalesSunday account for better!</p><br/>
        <br/></div>
        <div className="row border-top border-end border-bottom border-light mb-5 ist-group-flush">
        <div className="col-2 border-start border-end border-secondary p-0 ">
      <div
        className={`p-3 border-bottom cursor ${view === '1' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('1')}
      >
        <i className="bi bi-building"></i> Account Setting
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '2' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('2')}
      >
        <i className="bi bi-people-fill"></i> User & Permission
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '3' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('3')}
      >
       <i className="bi bi-pen-fill"></i> Customization
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '4' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('4')}
      >
       <i className="bi bi-phone-fill"></i> Mobile App
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '5' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('5')}
      >
       <i className="bi bi-fan"></i> Automation
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '6' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('6')}
      >
       <i className="bi bi-envelope-fill"></i> Email Settings
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '7' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('7')}
      >
      <i className="bi bi-code-slash"></i> API and Webhooks
      </div>
      <div
        className={`p-3 border-bottom cursor ${view === '8' ? 'bg-primary text-white' : ''}`}
        onClick={() => handleItemClick('8')}
      >
        <i className="bi bi-bell-fill"></i> Rules & Notification
      </div>
    </div>
          <div className="col-10">

            {view == '1'?<>
              
              <div className="col-10 p-3">
              <h5 className="text-secondary">Account Details </h5><p className="text-secondary">
              Please enter your account details so we can assist you more effectively.
                    </p>
                  <form onSubmit={sendOData}>
               

                    {/* <img className="border border-solid" src={ profilepic == ''?company: profilepic } 
                    height="80" ></img>&nbsp;&nbsp; 
                     <button type="button" className="btn btn-dark btn-sm" data-bs-toggle="modal" data-bs-target="#dpModal"><i className="bi bi-pencil-fill"></i> Edit Picture</button> */}

                    <div className="row">
                      <div className="mb-3 col-12">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Organization name:
                        </label>
                        <input
                          type="text"
                          value={organisation_name}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Sales Sunday"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-6">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Organization Type
                        </label>
                        <input
                          className="form-control"
                          value={feedOrg.organisation_type}
                          onChange={sendOrgData}
                          name="organisation_type"
                          list="datalistType"
                          id="exampleDataList"
                          placeholder="Type to search..."
                          readOnly
                        ></input>
                        <datalist id="datalistType">
                          <option value="Companies Limited by Shares">
                            Companies Limited by Shares
                          </option>
                          <option value="One Person Companies (OPC)">
                            One Person Companies (OPC)
                          </option>
                          <option value="Private Companies">
                            Private Companies
                          </option>
                          <option value="Public Companies">
                            Public Companies
                          </option>
                          <option value="Holding and Subsidiary Companies">
                            Holding and Subsidiary Companies
                          </option>
                          <option value="Associate Companies">
                            Associate Companies
                          </option>
                          <option value="Government Companies">
                            Government Companies
                          </option>
                          <option value="Proprietary">Proprietary</option>
                          <option value="Limited Liability Partnership(LLP)">
                            Limited Liability Partnership(LLP)
                          </option>
                          <option value="Company Branch">Company Branch</option>
                        </datalist>
                      </div>
                      <div className="mb-3 col-6">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Category of Working
                        </label>
                        <input
                          className="form-control"
                          value={feedOrg.organisation_category}
                          onChange={sendOrgData}
                          name="organisation_category"
                          list="datalistCategory"
                          id="exampleDataList"
                          placeholder="Type to search..."
                          readOnly
                        ></input>
                        <datalist id="datalistCategory">
                          <option value="Aerospace">Aerospace</option>
                          <option value="Transport">Transport</option>
                          <option value="Computer">Computer</option>
                          <option value="Telecommunication">
                            Telecommunication
                          </option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Construction">Construction</option>
                          <option value="Education">Education</option>
                          <option value="Pharmaceutical">Pharmaceutical</option>
                          <option value="Food">Food</option>
                          <option value="Health care">Health care</option>
                          <option value="Hospitality">Hospitality</option>
                          <option value="Entertainment">Entertainment</option>
                          <option value="News Media">News Media</option>
                          <option value="Energy">Energy</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Music">Music</option>
                          <option value="Mining">Mining</option>
                          <option value="Electronics">Electronics</option>
                          <option value="Software">Software</option>
                        </datalist>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-6">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Total Employees{" "}
                        </label>
                        <input
                          type="text"
                          value={feedOrg.total_employees}
                          onChange={sendOrgData}
                          name="total_employees"
                          className="form-control"
                          id="exampleFormControlInput1"
                          readOnly
                        />
                      </div>
                      <div className="mb-3 col-6">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          GST (optional)
                        </label>
                        <input
                          type="text"
                          value={feedOrg.gst}
                          onChange={sendOrgData}
                          name="gst"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="GST Number"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-6">
                        <label htmlFor="exampleDataList" className="form-label">
                          State
                        </label>
                        <input
                          className="form-control"
                          value={feedOrg.state}
                          onChange={sendOrgData}
                          name="state"
                          list="datalistOptions"
                          id="exampleDataList"
                          placeholder="Type to search..."
                          readOnly
                        ></input>
                        <datalist id="datalistOptions">
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </datalist>
                      </div>
                      <div className="mb-3 col-6">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Pin Code
                        </label>
                        <input
                          type="number"
                          value={feedOrg.pin_code}
                          onChange={sendOrgData}
                          name="pin_code"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="122002"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-12">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Billing Address
                      </label>
                      <input
                        type="text"
                        value={feedOrg.billing_address}
                        onChange={sendOrgData}
                        name="billing_address"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="21 jump street, California"
                        readOnly
                      />
                    </div>
                  </form>
                </div>
            </>:view == '2'?<>
        
            <div className="row">
            <div className="col-3 p-0 border-end border-secondary"> 
            
  <div
    className={`p-3 border-bottom cursor ${subView === '2' ? 'bg-dark text-white' : ''}`}
    onClick={() => handleItemClick2('2')}
  >
    <i className="bi bi-person-badge"></i> Titles
  </div>
  <div
    className={`p-3 border-bottom cursor ${subView === '5' ? 'bg-dark text-white' : ''}`}
    onClick={() => handleItemClick2('5')}
  >
    <i className="bi bi-key-fill"></i> Permission templates
  </div>
  <div
    className={`p-3 border-bottom cursor ${subView === '3' ? 'bg-dark text-white' : ''}`}
    onClick={() => handleItemClick2('3')}
  >
    <i className="bi bi-people"></i> Teams
  </div>
  <div
    className={`p-3 border-bottom cursor ${subView === '1' ? 'bg-dark text-white' : ''}`}
    onClick={() => handleItemClick2('1')}
  >
    <i className="bi bi-person-circle"></i> Users
  </div>
  <div
    className={`p-3 border-bottom cursor ${subView === '4' ? 'bg-dark text-white' : ''}`}
    onClick={() => handleItemClick2('4')}
  >
    <i className="bi bi-diagram-3-fill"></i> Org hierarchy
  </div>
  
      
      </div>
            <div className="col-9 mt-2">
           
           {subView =='1'?<>
           
           <div className="row">
            <div className="col-8"><h5 className="text-dark">All users</h5><p className="text-secondary">
                     Manage SalesSunday users
                    </p></div><div className="col-4" align='right'><button  data-bs-toggle="modal" href="#exampleModalToggle"  className="btn btn-dark btn-sm"><i className="bi bi-person-plus-fill"></i> Add Team </button></div></div>
                    
                    
                   
               

               <div
  className="ag-theme-quartz mb-2" // applying the Data Grid theme
  style={gridStyle} // the Data Grid will fill the size of the parent container
 >
    <AgGridReact
                rowData={feed}
                ref={gridRef}
                columnDefs={columnDefs}
                enableCellTextSelection={true}
                // defaultColDef={defaultColDef}
                rowSelection="multiple"
                // suppressRowClickSelection={true}
                suppressClipboardPaste={true}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={[ 50, 100, 500]}
            />
 </div>
               
               </>:subView =='2'?<>
               
               <div className="row">
                <div className="col-5">
                <h5 className="text-dark">Add titles</h5>
                <p className="text-secondary">Insert title in your organization</p>

                <div className="input-group mb-3">
  <input
    type="text"
    className="form-control"
    placeholder="Title name"
    aria-label="Title name"
    aria-describedby="button-addon2"
    onChange={(e)=>{setInsertRole(e.target.value)}}
  />
  <button
    className="btn btn-dark"
    type="button"
    id="button-addon2"
    onClick={addroles}
  >
    <i className="bi bi-plus-lg"></i>
  </button>
</div>


                
                </div>
                <div className="col-5">
                <mark>
                <i className="bi bi-person-lines-fill"></i> List of added titles:</mark>

                <ul className="list-group ">
                {roleslist &&
                            roleslist?.map((item, index) => (
                              <> <li className="list-group-item d-flex justify-content-between">{item} <i class="bi bi-trash3-fill"></i></li></>
              ))}
                 </ul>


                </div>
               </div>
               </>:subView =='3'?<>

               <div className="row">
                <div className="col-4">
                <h5 className="text-dark">Add team</h5>
                <p className="text-secondary">Create team in your organization</p>

                <div className="input-group mb-3">
  <input
    type="text"
    className="form-control"
    placeholder="Team name"
    aria-label="Role name"
    aria-describedby="button-addon2"
    onChange={(e)=>{setInsertTeam(e.target.value)}}
  />
  <button
    className="btn btn-dark"
    type="button"
    id="button-addon2"
    onClick={addteam}
  >
    <i className="bi bi-plus-lg"></i>
  </button>
</div>


                
                </div>
                <div className="col-4">
                <mark>
                <i className="bi bi-person-lines-fill"></i> List of added teams:</mark>

                <ul className="list-group ">

                {teamlist &&
                            teamlist?.map((item, index) => (
                              <>
 <li className="list-group-item d-flex justify-content-between">{item}<div> &nbsp; <i class="bi bi-trash3-fill"></i></div></li>
                </>
              ))}
                 </ul>


                </div>
               
               </div>

               
               </>:subView =='4'?<>
               <br/>
            <p align='center'><i className="bi bi-diagram-3-fill"></i> Organizational Hierarchy Visualization </p>
          <TreeDiagram data={feed}/>
        
               </>:subView =='5'?<>

<div className="row">
 <div className="col-4">
 <h5 className="text-dark">Create permission template</h5>
 <p className="text-secondary">Set access based on responsibilities</p>

 <div className="input-group mb-3">
<input
type="text"
className="form-control"
placeholder="Template name"
aria-label="Template name"
aria-describedby="button-addon2"
onChange={(e) => {
  setInsertTemp(prevState => ({
    ...prevState,
    name: e.target.value
  }));
}}
/>
<button
className="btn btn-dark"
type="button"
id="button-addon2"
onClick={()=>addtemp('insert')}
>
<i className="bi bi-plus-lg"></i>
</button>
</div>


 
 </div>
 <div className="col-4">
 <mark>
 <i className="bi bi-person-lines-fill"></i> List of added templates:</mark>

 <ul className="list-group">
  {templist &&
    templist.map((item, index) => (
      <li
        key={index}  // Added key prop here
        className={`list-group-item d-flex justify-content-between ${item.name === editTemp ? 'bg-dark text-white' : ''}`}
      >
        {item.name}
        <div>
          <span onClick={() => updateedittemp(item)}>  {/* Wrapped in an arrow function */}
            <i className="bi bi-eye-fill"></i>
          </span>
          &nbsp;
          <i className="bi bi-trash3-fill"></i>
        </div>
      </li>
    ))}
</ul>


 </div>
 {editTemp?<> <div className="col-4">
 <p className="text-secondary"><i className="bi bi-list"></i> List of functionality:</p>
 <div
    className="overflow-auto "
    style={{ maxWidth: "1000px", maxHeight: "700px" }}
  >

   <mark>
   <i className="bi bi-bezier"></i> Pipelines access:</mark>
   
   <ul className="list-group">
      {feedSopCategory.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`flexCheckDefault-${index}`}
              checked={selectedList.pipeline.includes(item)}
              onChange={() => handleCheckboxChange2(item, "pipeline")}
            />
            <label className="form-check-label" htmlFor={`flexCheckDefault-${index}`}>
              {item}
            </label>
          </div>
        </li>
      ))}
    </ul>
<br/>
<mark>
   <i className="bi bi-file-text-fill"></i> All call categories:</mark>
   <ul className="list-group">
   {feedOrgCategory.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`flexCheckDefault-${index}`}
              checked={selectedList.call_category.includes(item.script_type_name)}
              onChange={() => handleCheckboxChange2(item.script_type_name,'call_category')}
            />
            <label className="form-check-label" htmlFor={`flexCheckDefault-${index}`}>
            {item.script_type_name}
            </label>
          </div>
        </li>
      ))}
 
</ul>
<br/>
<mark>
   <i className="bi bi-file-text-fill"></i> Reports:</mark>
   <ul className="list-group">
   {["Daily Report", "KPIs", "Targets", "Performance, Status, Script"].map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`flexCheckDefault-${index}`}
              checked={selectedList.report.includes(item)}
              onChange={() => handleCheckboxChange2(item,'report')}
            />
            <label className="form-check-label" htmlFor={`flexCheckDefault-${index}`}>
            {item}
            </label>
          </div>
        </li>
      ))}
 
</ul>
<br/>
  <mark>
   <i className="bi bi-file-text-fill"></i> Training:</mark>
   <ul className="list-group">
   {["Quick training"].map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`flexCheckDefault-${index}`}
              checked={selectedList.training.includes(item)}
              onChange={() => handleCheckboxChange2(item,'training')}
            />
            <label className="form-check-label" htmlFor={`flexCheckDefault-${index}`}>
            {item}
            </label>
          </div>
        </li>
      ))}
</ul>
<br/>
   <mark>
   <i className="bi bi-file-text-fill"></i> Integrate App:</mark>
   <ul className="list-group">
   {integrationlist.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`flexCheckDefault-${index}`}
              checked={selectedList.Apps.includes(item.integration_name)}
              onChange={() => handleCheckboxChange2(item.integration_name,'Apps')}
            />
            <label className="form-check-label" htmlFor={`flexCheckDefault-${index}`}>
            {item.integration_name}
            </label>
          </div>
        </li>
      ))}
</ul>
<br/>
   <mark>
   <i className="bi bi-file-text-fill"></i> Task list:</mark>
   <ul className="list-group">
   {feedOrgTask.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`flexCheckDefault-${index}`}
              checked={selectedList.task_category.includes(item.heading)}
              onChange={() => handleCheckboxChange2(item.heading,'task_category')}
            />
            <label className="form-check-label" htmlFor={`flexCheckDefault-${index}`}>
            {item.heading}
            </label>
          </div>
        </li>
      ))}
   
</ul>



</div>
<br/>
<div className="mt-2"> 
<button
    className="btn btn-dark btn-sm"
    type="button"
    id="button-addon2"
    onClick={()=>addtemp('update')}
  >Update</button>
</div>

 </div></>:null}


</div>


</>:null}

            </div>
            </div></>
            :view == '3'?<>
           
                      <div className="col-10 p-3">
                      <br/><h5 className="text-secondary">Customization </h5><p className="text-secondary">
                      Personalize your SalesSunday Dashboard  
                    </p><br/>
                    <p>For data view customization: &nbsp;&nbsp; <NavLink to="/searchlab"><button type="button" className="btn btn-sm btn-dark"><i className="bi bi-table"></i> Search Lab</button></NavLink></p>
                      <div className="card bg-light col-10">
        <div className="card-body">
        <h5 class="card-title">Mask contact number:</h5>
      <div className="row"><div className="col-10"> <p>Your members won't get to see customers contact number. eg: 8149920*** </p></div><div className="col-2" > 
      <h3>
        
        {
          details ==true?<i className="bi bi-toggle-off" onClick={changemasking}></i>
          :<i className="bi bi-toggle-on" onClick={changemasking}></i>

        }

        </h3>
      </div></div>
       
       
        </div>
      </div>


                      </div>
            </>
            :view == '4'?<>
            
            <div className="container p-3"><div className="row">
              
      <div className="mb-3 col-12">
      <h5 className="text-secondary">Mobile App </h5><p className="text-secondary">
             Alter functionality for better efficiency.
                    </p><br/>
      <div className="form-check">
  <input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="flexCheckDefault"
    checked/>
  <label className="form-check-label" htmlFor="flexCheckDefault">
    Lead Module
  </label>
</div>
      </div>
      <div className="mb-3 col-12">
      <div className="form-check">
  <input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="flexCheckDefault"
    checked/>
  <label className="form-check-label" htmlFor="flexCheckDefault">
    Call Module
  </label>
</div>
      </div>
      <div className="mb-3 col-12">
      <div className="form-check">
  <input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="flexCheckDefault"
    checked/>
  <label className="form-check-label" htmlFor="flexCheckDefault">
    Task Module
  </label>
</div>
      </div>
      <div className="mb-3 col-12">
      <div className="form-check">
  <input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="flexCheckDefault"
    checked/>
  <label className="form-check-label" htmlFor="flexCheckDefault">
    Training Module
  </label>
</div>

      </div>
      <div className="mb-3 col-12">
      <div className="form-check">
  <input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="flexCheckDefault"
    checked/>
  <label className="form-check-label" htmlFor="flexCheckDefault">
    Gamification & target Module
  </label>
</div>

      </div>
     
      </div>
      <button type="button" className="btn btn-dark">Submit</button>

      </div>
            </>
            :view == '5'?<>
            <div className="mb-3 col-12">
      <h5 className="text-secondary"> Automation </h5><p className="text-secondary">
             Automate action based on user behaviour 
                    </p><br/>
                    <p>To build automation: &nbsp;&nbsp; <NavLink to="/Automation"><button type="button" className="btn btn-sm btn-dark"><i className="bi bi-fan"></i> Automation</button></NavLink>
                    </p>
                    </div>
            </>
            :view == '6'?<>
            </>
            :view == '7'?<>
            <br />
            <div className="col-6"><div className="p-3">
            <h5 className="text-secondary">API and Webhooks </h5><p className="text-secondary">
             Integrate 3rd party tools and application
                    </p><br/>
                    <p>For advance interactions: &nbsp;&nbsp; <NavLink to="/integrationlab"><button type="button" className="btn btn-sm btn-dark"><i className="bi bi-cpu-fill"></i> Integration Lab</button></NavLink>
                    </p><div className="card bg-light">
        <div className="card-body">
        <h5 class="card-title">My Operator</h5>
        <p>Integrate cloud calling inside salessunday.</p>
        <div className="col-12">
        <div className="row">
        <div className="col-6"> <div className="mb-2">
        <label htmlFor="exampleInputEmail1" className="form-label">Api token</label>
        <input  type="text" name='api_token' value={myoperator.api_token} onChange={updataMyOperatorData} className="form-control form-control-sm"/>
      </div></div>
        <div className="col-6"><div className="mb-2">
        <label htmlFor="exampleInputEmail1" className="form-label">Secret key</label>
        <input type="text" name='secret_key' value={myoperator.secret_key} onChange={updataMyOperatorData} className="form-control form-control-sm"  />
      </div></div>

      <div className="col-6">
      <div className="mb-2">
        <label htmlFor="exampleInputEmail1" className="form-label">X Api key</label>
        <input type="text" name='x_api_key' value={myoperator.x_api_key} onChange={updataMyOperatorData} className="form-control form-control-sm"  />
      </div>
      </div>

      <div className="col-6">
      <div className="mb-2">
        <label htmlFor="exampleInputEmail1" className="form-label">Campaign Id</label>
        <input type="text" name='campaigns_id' value={myoperator.campaigns_id} onChange={updataMyOperatorData} className="form-control form-control-sm"  />
      </div>
      </div>

        </div>
       

      <button type="button" onClick={postMyoperator}  class="btn btn-dark btn-sm">Submit</button>

      </div>
        </div>
      </div>
      </div></div>
                      
            </>:null}

          

          </div>
        </div>

       
        
        <ToastContainer />
        <div
            className="modal fade"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={-1}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              
              <div className="modal-content">
              <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                    <i className="bi bi-person-badge"></i> Add Team
                    </h5>
                    </div>
             
                  <div className="modal-body">
                    
                  <div className="d-flex align-items-start">
  <div
    className="nav flex-column nav-pills col-3 me-3"
    id="v-pills-tab"
    role="tablist"
    aria-orientation="vertical"
  >
    <button
      className="nav-link active"
      id="v-pills-details-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-details"
      type="button"
      role="tab"
      aria-controls="v-pills-details"
      aria-selected="true"
    >
      User Details
    </button>
    <button
      className="nav-link"
      id="v-pills-work-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-work"
      type="button"
      role="tab"
      aria-controls="v-pills-work"
      aria-selected="false"
    >
      Work Details
    </button>
    <button
      className="nav-link"
      id="v-pills-location-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-location"
      type="button"
      role="tab"
      aria-controls="v-pills-location"
      aria-selected="false"
    >
      Location Details
    </button>
    <button
      className="nav-link"
      id="v-pills-permission-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-permission"
      type="button"
      role="tab"
      aria-controls="v-pills-permission"
      aria-selected="false"
    >
      Manage Permission
    </button>
  </div>
  <div className="tab-content" id="v-pills-tabContent">
    <div
      className="tab-pane fade show active"
      id="v-pills-details"
      role="tabpanel"
      aria-labelledby="v-pills-details-tab"
    >
    <div className="container"><div className="row">
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-circle"></i> Full Name
                            </label>
                            <input
                              type="text"
                              value={userData.name}
                              onChange={updataData}
                              name="name"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Rahul Sharma"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-phone-fill"></i> Contact Number
                            </label>
                            <input
                              type="text"
                              value={userData.mobile}
                              onChange={updataData}
                              name="mobile"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="8149900444"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-envelope-fill"></i> Email Id
                            </label>
                            <input
                              type="email"
                              value={userData.email}
                              onChange={updataData}
                              name="email"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="rahul12345@gmail.com"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-badge-fill"></i> Title
                            </label>
                            <select class="form-select form-select-sm" aria-label="Default select example" value={userData.title}
                              onChange={updataData}
                              name="title"> 
                              <option value=''> None</option>
                              {roleslist &&
                            roleslist?.map((item, index) => (
                              <> <option value={item}>{item}</option></>
              ))}
</select>
                          </div>
                          
                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-lines-fill"></i> Role
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={userData.role}
                              onChange={updataData}
                              name="role"> 
  <option value="Member">Member</option>
  <option value="Admin">Admin - Manager</option>
  <option value="Super Admin">Super Admin</option>
</select>
                          </div>




                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-lock-fill"></i> Password
                            </label>
                            <input
                              type="password"
                              value={userData.password}
                              onChange={updataData}
                              name="password"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="**********"
                            />
                          </div>
                          {userData.role == 'Admin' || userData.role == 'Member'?<div className="col-12">
      <div className="mb-2">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i class="bi bi-people-fill"></i> Set user parent (Reporting manager)
                            </label>
                          <div className="overflow-auto p-1 mb-2 bg-transparent"
                style={{ maxWidth: "500px", maxHeight: "300px" }}>
                  <select class="form-select form-select-sm" aria-label="Default select example" value={userData.parent_Id}
                              onChange={updataData}
                              name="parent_Id"> 
<option value=''> None</option>
                            {feed &&
                            feed?.map((item, index) => (

                              <>
                              {
                               <><option value={item.id}>{item.user_name} - {item.role}</option></>
                              }</>

                              
                              ))}</select> </div>

</div>
                          </div>:null }
                        </div>
                       </div>
     
 
    
    </div>
    <div
      className="tab-pane fade"
      id="v-pills-work"
      role="tabpanel"
      aria-labelledby="v-pills-work-tab"
    >
      <div className="container"><div className="row">
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-badge"></i> Employee Id
                            </label>
                            <input
                              type="text"
                              value={userData.EmployeeId}
                              onChange={updataData}
                              name="EmployeeId"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Emp1012"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-people-fill"></i> Team
                            </label>
                            <select class="form-select form-select-sm" aria-label="Default select example" value={userData.team}
                              onChange={updataData}
                              name="team"> 
<option value=''> None</option>
                            {teamlist &&
                            teamlist?.map((item, index) => (

                              <>
                              {
                               <><option value={item}>{item}</option></>
                              }</>

                              
                              ))}
                              
                             </select> 
                            
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-calendar"></i> Date of Joining
                            </label>
                            <input
                              type="date"
                              value={userData.dateofjoining}
                              onChange={updataData}
                              name="dateofjoining"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder=""
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-calendar"></i> Date of Resignation
                            </label>
                            <input
                              type="date"
                              value={userData.dateofresignation}
                              onChange={updataData}
                              name="dateofresignation"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder=""
                            />
                          </div>
                          
                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-lines-fill"></i> Time Zone
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={userData.TimeZone}
                              onChange={updataData}
                              name="TimeZone"> 
<option value="Pacific/Midway">(UTC-11:00) Midway Island</option>
  <option value="America/Adak">(UTC-10:00) Hawaii-Aleutian</option>
  <option value="Etc/GMT+10">(UTC-10:00) Hawaii</option>
  <option value="Pacific/Marquesas">(UTC-09:30) Marquesas Islands</option>
  <option value="Pacific/Gambier">(UTC-09:00) Gambier Islands</option>
  <option value="America/Anchorage">(UTC-09:00) Alaska</option>
  <option value="America/Ensenada">(UTC-08:00) Tijuana, Baja California</option>
  <option value="Etc/GMT+8">(UTC-08:00) Pitcairn Islands</option>
  <option value="America/Los_Angeles">(UTC-08:00) Pacific Time (US & Canada)</option>
  <option value="America/Denver">(UTC-07:00) Mountain Time (US & Canada)</option>
  <option value="America/Chihuahua">(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
  <option value="America/Dawson_Creek">(UTC-07:00) Arizona</option>
  <option value="America/Belize">(UTC-06:00) Saskatchewan, Central America</option>
  <option value="America/Cancun">(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
  <option value="Chile/EasterIsland">(UTC-06:00) Easter Island</option>
  <option value="America/Chicago">(UTC-06:00) Central Time (US & Canada)</option>
  <option value="America/New_York">(UTC-05:00) Eastern Time (US & Canada)</option>
  <option value="America/Havana">(UTC-05:00) Cuba</option>
  <option value="America/Bogota">(UTC-05:00) Bogota, Lima, Quito, Rio Branco</option>
  <option value="America/Caracas">(UTC-04:30) Caracas</option>
  <option value="America/Santiago">(UTC-04:00) Santiago</option>
  <option value="America/La_Paz">(UTC-04:00) La Paz</option>
  <option value="Atlantic/Stanley">(UTC-04:00) Faukland Islands</option>
  <option value="America/Campo_Grande">(UTC-04:00) Brazil</option>
  <option value="America/Goose_Bay">(UTC-04:00) Atlantic Time (Goose Bay)</option>
  <option value="America/Glace_Bay">(UTC-04:00) Atlantic Time (Canada)</option>
  <option value="America/St_Johns">(UTC-03:30) Newfoundland</option>
  <option value="America/Araguaina">(UTC-03:00) UTC-3</option>
  <option value="America/Montevideo">(UTC-03:00) Montevideo</option>
  <option value="America/Miquelon">(UTC-03:00) Miquelon, St. Pierre</option>
  <option value="America/Godthab">(UTC-03:00) Greenland</option>
  <option value="America/Argentina/Buenos_Aires">(UTC-03:00) Buenos Aires</option>
  <option value="America/Sao_Paulo">(UTC-03:00) Brasilia</option>
  <option value="America/Noronha">(UTC-02:00) Mid-Atlantic</option>
  <option value="Atlantic/Cape_Verde">(UTC-01:00) Cape Verde Is.</option>
  <option value="Atlantic/Azores">(UTC-01:00) Azores</option>
  <option value="Europe/Belfast">(UTC+00:00) Greenwich Mean Time : Belfast</option>
  <option value="Europe/Dublin">(UTC+00:00) Greenwich Mean Time : Dublin</option>
  <option value="Europe/Lisbon">(UTC+00:00) Greenwich Mean Time : Lisbon</option>
  <option value="Europe/London">(UTC+00:00) Greenwich Mean Time : London</option>
  <option value="Africa/Abidjan">(UTC+00:00) Monrovia, Reykjavik</option>
  <option value="Europe/Amsterdam">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
  <option value="Europe/Belgrade">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
  <option value="Europe/Brussels">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</option>
  <option value="Africa/Algiers">(UTC+01:00) West Central Africa</option>
  <option value="Africa/Windhoek">(UTC+01:00) Windhoek</option>
  <option value="Asia/Beirut">(UTC+02:00) Beirut</option>
  <option value="Africa/Cairo">(UTC+02:00) Cairo</option>
  <option value="Asia/Gaza">(UTC+02:00) Gaza</option>
  <option value="Africa/Blantyre">(UTC+02:00) Harare, Pretoria</option>
  <option value="Asia/Jerusalem">(UTC+02:00) Jerusalem</option>
  <option value="Europe/Minsk">(UTC+02:00) Minsk</option>
  <option value="Asia/Damascus">(UTC+02:00) Syria</option>
  <option value="Europe/Moscow">(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
  <option value="Africa/Addis_Ababa">(UTC+03:00) Nairobi</option>
  <option value="Asia/Tehran">(UTC+03:30) Tehran</option>
  <option value="Asia/Dubai">(UTC+04:00) Abu Dhabi, Muscat</option>
  <option value="Asia/Yerevan">(UTC+04:00) Yerevan</option>
  <option value="Asia/Kabul">(UTC+04:30) Kabul</option>
  <option value="Asia/Yekaterinburg">(UTC+05:00) Ekaterinburg</option>
  <option value="Asia/Tashkent">(UTC+05:00) Tashkent</option>
  <option value="Asia/Kolkata">(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
  <option value="Asia/Katmandu">(UTC+05:45) Kathmandu</option>
  <option value="Asia/Dhaka">(UTC+06:00) Astana, Dhaka</option>
  <option value="Asia/Novosibirsk">(UTC+06:00) Novosibirsk</option>
  <option value="Asia/Rangoon">(UTC+06:30) Yangon (Rangoon)</option>
  <option value="Asia/Bangkok">(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
  <option value="Asia/Krasnoyarsk">(UTC+07:00) Krasnoyarsk</option>
  <option value="Asia/Hong_Kong">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
  <option value="Asia/Irkutsk">(UTC+08:00) Irkutsk, Ulaan Bataar</option>
  <option value="Australia/Perth">(UTC+08:00) Perth</option>
  <option value="Australia/Eucla">(UTC+08:45) Eucla</option>
  <option value="Asia/Tokyo">(UTC+09:00) Osaka, Sapporo, Tokyo</option>
  <option value="Asia/Seoul">(UTC+09:00) Seoul</option>
  <option value="Asia/Yakutsk">(UTC+09:00) Yakutsk</option>
  <option value="Australia/Adelaide">(UTC+09:30) Adelaide</option>
  <option value="Australia/Darwin">(UTC+09:30) Darwin</option>
  <option value="Australia/Brisbane">(UTC+10:00) Brisbane</option>
  <option value="Australia/Hobart">(UTC+10:00) Hobart</option>
  <option value="Asia/Vladivostok">(UTC+10:00) Vladivostok</option>
  <option value="Australia/Lord_Howe">(UTC+10:30) Lord Howe Island</option>
  <option value="Pacific/Norfolk">(UTC+11:00) Norfolk Island</option>
  <option value="Asia/Magadan">(UTC+11:00) Magadan</option>
  <option value="Pacific/Noumea">(UTC+11:00) New Caledonia</option>
  <option value="Pacific/Auckland">(UTC+12:00) Auckland, Wellington</option>
  <option value="Pacific/Fiji">(UTC+12:00) Fiji, Kamchatka, Marshall Is.</option>
  <option value="Pacific/Chatham">(UTC+12:45) Chatham Islands</option>
  <option value="Pacific/Tongatapu">(UTC+13:00) Nuku'alofa</option>
  <option value="Pacific/Kiritimati">(UTC+14:00) Kiritimati</option>
</select>
                          </div>

                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-lines-fill"></i> Is Employee
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={userData.IsEmployee}
                              onChange={updataData}
                              name="IsEmployee"> 
  <option value={true}>Yes</option>
  <option value={false}>No</option>
</select>
                          </div>




                          
                          
                        </div>
                       </div>
     
    </div>
    <div
      className="tab-pane fade"
      id="v-pills-location"
      role="tabpanel"
      aria-labelledby="v-pills-location-tab"
    >
      <div className="container"><div className="row">
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-geo-alt-fill"></i> Location Name
                            </label>
                            <input
                              type="text"
                              value={userData.LocationName}
                              onChange={updataData}
                              name="LocationName"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Mumbai Western"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-geo-fill"></i> Address
                            </label>
                            <input
                              type="text"
                              value={userData.Address}
                              onChange={updataData}
                              name="Address"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="1234 Elm Street Springfield, IL 400001"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-pin-map-fill"></i>  City
                            </label>
                            <input
                              type="text"
                              value={userData.City}
                              onChange={updataData}
                              name="City"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Mumbai"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-map-fill"></i> State
                            </label>
                            <input
                              type="text"
                              value={userData.State}
                              onChange={updataData}
                              name="State"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Maharashtra"
                            />
                          </div>
                          
                          
                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              <i className="bi bi-globe2"></i> Country
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={userData.Country}
                              onChange={updataData}
                              name="Country"> 
<option value="India">India</option>
<option value="Australia">Australia</option>
<option value="Brazil">Brazil</option>
<option value="China">China</option>
<option value="France">France</option>
<option value="Germany">Germany</option>
<option value="Italy">Italy</option>
<option value="Japan">Japan</option>
<option value="Mexico">Mexico</option>
<option value="Netherlands">Netherlands</option>
<option value="New Zealand">New Zealand</option>
<option value="Russia">Russia</option>
<option value="South Africa">South Africa</option>
<option value="South Korea">South Korea</option>
<option value="Spain">Spain</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="Argentina">Argentina</option>
<option value="Belgium">Belgium</option>
<option value="Chile">Chile</option>
<option value="Colombia">Colombia</option>
<option value="Denmark">Denmark</option>
<option value="Egypt">Egypt</option>
<option value="Finland">Finland</option>
<option value="Greece">Greece</option>
<option value="Hungary">Hungary</option>
<option value="Indonesia">Indonesia</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Malaysia">Malaysia</option>
<option value="Norway">Norway</option>
<option value="Pakistan">Pakistan</option>
<option value="Philippines">Philippines</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Singapore">Singapore</option>
</select>
                          </div>

                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-hash"></i> Zip Code
                            </label>
                            <input
                              type="text"
                              value={userData.ZipCode}
                              onChange={updataData}
                              name="ZipCode"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="503605"
                            />
                          </div>

                        </div>
                       </div>
    </div>
    <div
      className="tab-pane fade"
      id="v-pills-permission"
      role="tabpanel"
      aria-labelledby="v-pills-permission-tab"
    >
      <div className="container">
        <p><i className="bi bi-laptop-fill"></i> Select device access: </p>
        <div className="row">
        <div className="mb-3 col-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="web"
            checked={userData.Device.web}
            onChange={handleDeviceChange}
          />
          <label className="form-check-label" htmlFor="web">
            Web application
          </label>
        </div>
      </div>
      <div className="mb-3 col-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="phone"
            checked={userData.Device.phone}
            onChange={handleDeviceChange}
          />
          <label className="form-check-label" htmlFor="phone">
            Phone application
          </label> 
        </div>
      </div>
      <div className="mb-3 col-6">
      <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-badge"></i> Select permission template
                            </label>
                            
                          <select class="form-select form-select-sm" aria-label="Default select example" value={userData.Permissiontemplate}
                              onChange={updataData}
                              name="Permissiontemplate"> 
  <option value="">Default</option>
  {templist &&
                            templist?.map((item, index) => (<><option value={item.name}>{item.name}</option></>))}
</select>

      </div>
      </div>
      </div>
    </div>
  </div>
</div>

                  </div>
                  <div className="modal-footer"> <button
                          onClick={sendData}
                          className="btn btn-dark btn-sm"
                          data-bs-target="#exampleModalToggle2"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          disabled={!isFormValid}
                        >
                          <i className="bi bi-plus-circle"></i> Add
                        </button></div>
                
              </div>
            </div>
          </div>
        <div className="modal fade" id="dpModal" tabIndex={-1} aria-labelledby="dpModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="dpModalLabel">Set profile picture</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
              <div className="input-group mb-3">
        <input type="file" name="file" onChange={updataFile} className="form-control" id="inputGroupFile02" />
        <button onClick={uploadPicture} className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Uplaod</button>
      </div>
      <p><b>note</b>: Uplaod a square shape image.</p>
              </div>
            
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title" id="exampleModalLabel"><i className="bi bi-person-badge"></i> Edit user</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
                    
                  <div className="d-flex align-items-start">
  <div
    className="nav flex-column nav-pills col-3 me-3"
    id="v-pills-tab"
    role="tablist"
    aria-orientation="vertical"
  >
    <button
      className="nav-link active"
      id="v-pills-details2-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-details2"
      type="button"
      role="tab"
      aria-controls="v-pills-details2"
      aria-selected="true"
    >
      User Details
    </button>
    <button
      className="nav-link"
      id="v-pills-work2-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-work2"
      type="button"
      role="tab"
      aria-controls="v-pills-work2"
      aria-selected="false"
    >
      Work Details
    </button>
    <button
      className="nav-link"
      id="v-pills-location2-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-location2"
      type="button"
      role="tab"
      aria-controls="v-pills-location2"
      aria-selected="false"
    >
      Location Details
    </button>
    <button
      className="nav-link"
      id="v-pills-permission2-tab"
      data-bs-toggle="pill"
      data-bs-target="#v-pills-permission2"
      type="button"
      role="tab"
      aria-controls="v-pills-permission2"
      aria-selected="false"
    >
      Manage Permission
    </button>
  </div>
  <div className="tab-content" id="v-pills-tabContent">
    <div
      className="tab-pane fade show active"
      id="v-pills-details2"
      role="tabpanel"
      aria-labelledby="v-pills-details2-tab"
    >
    <div className="container"><div className="row">
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-circle"></i> Full Name
                            </label>
                            <input
                              type="text"
                              value={editUserData.name}
                              onChange={updataEditUser}
                              name="name"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Rahul Sharma"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-phone-fill"></i> Contact Number
                            </label>
                            <input
                              type="text"
                              value={editUserData.mobile}
                              onChange={updataEditUser}
                              name="mobile"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="8149900444"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-envelope-fill"></i> Email Id
                            </label>
                            <input
                              type="email"
                              value={editUserData.email}
                              onChange={updataEditUser}
                              name="email"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="rahul12345@gmail.com"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-badge-fill"></i> Title
                            </label>
                            <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.title}
                              onChange={updataEditUser}
                              name="title"> 
                              <option value=''> None</option>
                              {roleslist &&
                            roleslist?.map((item, index) => (
                              <> <option value={item}>{item}</option></>
              ))}
</select>
                          </div>
                          
                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-lines-fill"></i> Role
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.role}
                              onChange={updataEditUser}
                              name="role"> 
  <option value="Member">Member</option>
  <option value="Admin">Admin - Manager</option>
  <option value="Super Admin">Super Admin</option>
</select>
                          </div>




                         
                          {editUserData.role == 'Admin' || editUserData.role == 'Member'?<div className="col-6">
      <div className="mb-2">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i class="bi bi-people-fill"></i> Set parent (Reporting manager)
                            </label>
                          <div className="overflow-auto p-1 mb-2 bg-transparent"
                style={{ maxWidth: "500px", maxHeight: "300px" }}>
                  <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.parent_Id}
                              onChange={updataEditUser}
                              name="parent_Id"> 
<option value=''> None</option>
                            {feed &&
                            feed?.map((item, index) => (

                              <>
                              {
                               <><option value={item.id}>{item.user_name} - {item.role}</option></>
                              }</>

                              
                              ))}</select> </div>

</div>
                          </div>:null }
                        </div>
                       </div>
     
 
    
    </div>
    <div
      className="tab-pane fade"
      id="v-pills-work2"
      role="tabpanel"
      aria-labelledby="v-pills-work2-tab"
    >
      <div className="container"><div className="row">
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-badge"></i> Employee Id
                            </label>
                            <input
                              type="text"
                              value={editUserData.EmployeeId}
                              onChange={updataEditUser}
                              name="EmployeeId"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Emp1012"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-people-fill"></i> Team
                            </label>
                            <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.team}
                              onChange={updataEditUser}
                              name="team"> 
<option value=''> None</option>
                            {teamlist &&
                            teamlist?.map((item, index) => (

                              <>
                              {
                               <><option value={item}>{item}</option></>
                              }</>

                              
                              ))}
                              
                             </select> 
                            
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-calendar"></i> Date of Joining
                            </label>
                            <input
                              type="date"
                              value={editUserData.dateofjoining}
                              onChange={updataEditUser}
                              name="dateofjoining"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder=""
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-calendar"></i> Date of Resignation
                            </label>
                            <input
                              type="date"
                              value={editUserData.dateofresignation}
                              onChange={updataEditUser}
                              name="dateofresignation"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder=""
                            />
                          </div>
                          
                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-lines-fill"></i> Time Zone
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.TimeZone}
                              onChange={updataEditUser}
                              name="TimeZone"> 
<option value="Pacific/Midway">(UTC-11:00) Midway Island</option>
  <option value="America/Adak">(UTC-10:00) Hawaii-Aleutian</option>
  <option value="Etc/GMT+10">(UTC-10:00) Hawaii</option>
  <option value="Pacific/Marquesas">(UTC-09:30) Marquesas Islands</option>
  <option value="Pacific/Gambier">(UTC-09:00) Gambier Islands</option>
  <option value="America/Anchorage">(UTC-09:00) Alaska</option>
  <option value="America/Ensenada">(UTC-08:00) Tijuana, Baja California</option>
  <option value="Etc/GMT+8">(UTC-08:00) Pitcairn Islands</option>
  <option value="America/Los_Angeles">(UTC-08:00) Pacific Time (US & Canada)</option>
  <option value="America/Denver">(UTC-07:00) Mountain Time (US & Canada)</option>
  <option value="America/Chihuahua">(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
  <option value="America/Dawson_Creek">(UTC-07:00) Arizona</option>
  <option value="America/Belize">(UTC-06:00) Saskatchewan, Central America</option>
  <option value="America/Cancun">(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
  <option value="Chile/EasterIsland">(UTC-06:00) Easter Island</option>
  <option value="America/Chicago">(UTC-06:00) Central Time (US & Canada)</option>
  <option value="America/New_York">(UTC-05:00) Eastern Time (US & Canada)</option>
  <option value="America/Havana">(UTC-05:00) Cuba</option>
  <option value="America/Bogota">(UTC-05:00) Bogota, Lima, Quito, Rio Branco</option>
  <option value="America/Caracas">(UTC-04:30) Caracas</option>
  <option value="America/Santiago">(UTC-04:00) Santiago</option>
  <option value="America/La_Paz">(UTC-04:00) La Paz</option>
  <option value="Atlantic/Stanley">(UTC-04:00) Faukland Islands</option>
  <option value="America/Campo_Grande">(UTC-04:00) Brazil</option>
  <option value="America/Goose_Bay">(UTC-04:00) Atlantic Time (Goose Bay)</option>
  <option value="America/Glace_Bay">(UTC-04:00) Atlantic Time (Canada)</option>
  <option value="America/St_Johns">(UTC-03:30) Newfoundland</option>
  <option value="America/Araguaina">(UTC-03:00) UTC-3</option>
  <option value="America/Montevideo">(UTC-03:00) Montevideo</option>
  <option value="America/Miquelon">(UTC-03:00) Miquelon, St. Pierre</option>
  <option value="America/Godthab">(UTC-03:00) Greenland</option>
  <option value="America/Argentina/Buenos_Aires">(UTC-03:00) Buenos Aires</option>
  <option value="America/Sao_Paulo">(UTC-03:00) Brasilia</option>
  <option value="America/Noronha">(UTC-02:00) Mid-Atlantic</option>
  <option value="Atlantic/Cape_Verde">(UTC-01:00) Cape Verde Is.</option>
  <option value="Atlantic/Azores">(UTC-01:00) Azores</option>
  <option value="Europe/Belfast">(UTC+00:00) Greenwich Mean Time : Belfast</option>
  <option value="Europe/Dublin">(UTC+00:00) Greenwich Mean Time : Dublin</option>
  <option value="Europe/Lisbon">(UTC+00:00) Greenwich Mean Time : Lisbon</option>
  <option value="Europe/London">(UTC+00:00) Greenwich Mean Time : London</option>
  <option value="Africa/Abidjan">(UTC+00:00) Monrovia, Reykjavik</option>
  <option value="Europe/Amsterdam">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
  <option value="Europe/Belgrade">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
  <option value="Europe/Brussels">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</option>
  <option value="Africa/Algiers">(UTC+01:00) West Central Africa</option>
  <option value="Africa/Windhoek">(UTC+01:00) Windhoek</option>
  <option value="Asia/Beirut">(UTC+02:00) Beirut</option>
  <option value="Africa/Cairo">(UTC+02:00) Cairo</option>
  <option value="Asia/Gaza">(UTC+02:00) Gaza</option>
  <option value="Africa/Blantyre">(UTC+02:00) Harare, Pretoria</option>
  <option value="Asia/Jerusalem">(UTC+02:00) Jerusalem</option>
  <option value="Europe/Minsk">(UTC+02:00) Minsk</option>
  <option value="Asia/Damascus">(UTC+02:00) Syria</option>
  <option value="Europe/Moscow">(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
  <option value="Africa/Addis_Ababa">(UTC+03:00) Nairobi</option>
  <option value="Asia/Tehran">(UTC+03:30) Tehran</option>
  <option value="Asia/Dubai">(UTC+04:00) Abu Dhabi, Muscat</option>
  <option value="Asia/Yerevan">(UTC+04:00) Yerevan</option>
  <option value="Asia/Kabul">(UTC+04:30) Kabul</option>
  <option value="Asia/Yekaterinburg">(UTC+05:00) Ekaterinburg</option>
  <option value="Asia/Tashkent">(UTC+05:00) Tashkent</option>
  <option value="Asia/Kolkata">(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
  <option value="Asia/Katmandu">(UTC+05:45) Kathmandu</option>
  <option value="Asia/Dhaka">(UTC+06:00) Astana, Dhaka</option>
  <option value="Asia/Novosibirsk">(UTC+06:00) Novosibirsk</option>
  <option value="Asia/Rangoon">(UTC+06:30) Yangon (Rangoon)</option>
  <option value="Asia/Bangkok">(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
  <option value="Asia/Krasnoyarsk">(UTC+07:00) Krasnoyarsk</option>
  <option value="Asia/Hong_Kong">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
  <option value="Asia/Irkutsk">(UTC+08:00) Irkutsk, Ulaan Bataar</option>
  <option value="Australia/Perth">(UTC+08:00) Perth</option>
  <option value="Australia/Eucla">(UTC+08:45) Eucla</option>
  <option value="Asia/Tokyo">(UTC+09:00) Osaka, Sapporo, Tokyo</option>
  <option value="Asia/Seoul">(UTC+09:00) Seoul</option>
  <option value="Asia/Yakutsk">(UTC+09:00) Yakutsk</option>
  <option value="Australia/Adelaide">(UTC+09:30) Adelaide</option>
  <option value="Australia/Darwin">(UTC+09:30) Darwin</option>
  <option value="Australia/Brisbane">(UTC+10:00) Brisbane</option>
  <option value="Australia/Hobart">(UTC+10:00) Hobart</option>
  <option value="Asia/Vladivostok">(UTC+10:00) Vladivostok</option>
  <option value="Australia/Lord_Howe">(UTC+10:30) Lord Howe Island</option>
  <option value="Pacific/Norfolk">(UTC+11:00) Norfolk Island</option>
  <option value="Asia/Magadan">(UTC+11:00) Magadan</option>
  <option value="Pacific/Noumea">(UTC+11:00) New Caledonia</option>
  <option value="Pacific/Auckland">(UTC+12:00) Auckland, Wellington</option>
  <option value="Pacific/Fiji">(UTC+12:00) Fiji, Kamchatka, Marshall Is.</option>
  <option value="Pacific/Chatham">(UTC+12:45) Chatham Islands</option>
  <option value="Pacific/Tongatapu">(UTC+13:00) Nuku'alofa</option>
  <option value="Pacific/Kiritimati">(UTC+14:00) Kiritimati</option>
</select>
                          </div>

                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-lines-fill"></i> Is Employee
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.IsEmployee}
                              onChange={updataEditUser}
                              name="IsEmployee"> 
  <option value={true}>Yes</option>
  <option value={false}>No</option>
</select>
                          </div>




                          
                          
                        </div>
                       </div>
     
    </div>
    <div
      className="tab-pane fade"
      id="v-pills-location2"
      role="tabpanel"
      aria-labelledby="v-pills-location2-tab"
    >
      <div className="container"><div className="row">
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-geo-alt-fill"></i> Location Name
                            </label>
                            <input
                              type="text"
                              value={editUserData.LocationName}
                              onChange={updataEditUser}
                              name="LocationName"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Mumbai Western"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-geo-fill"></i> Address
                            </label>
                            <input
                              type="text"
                              value={editUserData.Address}
                              onChange={updataEditUser}
                              name="Address"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="1234 Elm Street Springfield, IL 400001"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-pin-map-fill"></i>  City
                            </label>
                            <input
                              type="text"
                              value={editUserData.City}
                              onChange={updataEditUser}
                              name="City"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Mumbai"
                            />
                          </div>
                          <div className="mb-3 col-6">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-map-fill"></i> State
                            </label>
                            <input
                              type="text"
                              value={editUserData.State}
                              onChange={updataEditUser}
                              name="State"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="Maharashtra"
                            />
                          </div>
                          
                          
                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              <i className="bi bi-globe2"></i> Country
                            </label>
                          <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.Country}
                              onChange={updataEditUser}
                              name="Country"> 
<option value="India">India</option>
<option value="Australia">Australia</option>
<option value="Brazil">Brazil</option>
<option value="China">China</option>
<option value="France">France</option>
<option value="Germany">Germany</option>
<option value="Italy">Italy</option>
<option value="Japan">Japan</option>
<option value="Mexico">Mexico</option>
<option value="Netherlands">Netherlands</option>
<option value="New Zealand">New Zealand</option>
<option value="Russia">Russia</option>
<option value="South Africa">South Africa</option>
<option value="South Korea">South Korea</option>
<option value="Spain">Spain</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="Argentina">Argentina</option>
<option value="Belgium">Belgium</option>
<option value="Chile">Chile</option>
<option value="Colombia">Colombia</option>
<option value="Denmark">Denmark</option>
<option value="Egypt">Egypt</option>
<option value="Finland">Finland</option>
<option value="Greece">Greece</option>
<option value="Hungary">Hungary</option>
<option value="Indonesia">Indonesia</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Malaysia">Malaysia</option>
<option value="Norway">Norway</option>
<option value="Pakistan">Pakistan</option>
<option value="Philippines">Philippines</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Singapore">Singapore</option>
</select>
                          </div>

                          <div className="mb-3 col-6">
                          <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-hash"></i> Zip Code
                            </label>
                            <input
                              type="text"
                              value={editUserData.ZipCode}
                              onChange={updataEditUser}
                              name="ZipCode"
                              className="form-control form-control-sm"
                              id="exampleFormControlInput1"
                              placeholder="503605"
                            />
                          </div>

                        </div>
                       </div>
    </div>
    <div
      className="tab-pane fade"
      id="v-pills-permission2"
      role="tabpanel"
      aria-labelledby="v-pills-permission2-tab"
    >
      <div className="container">
        <p><i className="bi bi-laptop-fill"></i> Select device access: </p>
        <div className="row">
        <div className="mb-3 col-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="web"
            checked={editUserData.Device.web}
            onChange={handleDeviceChangeUpdate}
          />
          <label className="form-check-label" htmlFor="web">
            Web application
          </label>
        </div>
      </div>
      <div className="mb-3 col-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="phone"
            checked={editUserData.Device.phone}
            onChange={handleDeviceChangeUpdate}
          />
          <label className="form-check-label" htmlFor="phone">
            Phone application
          </label> 
        </div>
      </div>
      <div className="mb-3 col-6">
      <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                             <i className="bi bi-person-badge"></i> Select permission template
                            </label>
                            
                          <select class="form-select form-select-sm" aria-label="Default select example" value={editUserData.Permissiontemplate}
                              onChange={updataEditUser}
                              name="Permissiontemplate"> 
  <option value="">Default</option>
  {templist &&
                            templist?.map((item, index) => (<><option value={item.name}>{item.name}</option></>))}
</select>

      </div>
      </div>
      </div>
    </div>
  </div>
</div>

                  </div>
                    <div className="modal-footer"> <button
                          onClick={sendUpdateddata}
                          className="btn btn-dark btn-sm"
                          data-bs-target="#exampleModalToggle2"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          
                        >
                           Update
                        </button></div>
            
          </div>
        </div>
      </div>
      
      </div>
      
    );
  } else {
    return (
      <div className="container">
         <br/>
        <div className="row">
          <div className="col-4">
            <br />
            <br />
            <div align="center">
              <div
                className="card shadow p-3 mb-5 bg-body rounded"
                style={{ width: "18rem" }}
              >
                <div className="card-body ">
                  <img
                    className="rounded-circle"
                    src={avatar}
                    width="160"
                    height="160"
                  ></img>
                  <br />
                  <br />
                  <h5 className="card-title">{user_name}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    {organisation_name}
                  </h6>
                  <p className="card-text">
                    Role: <b>{role}</b>
                    <br />
                    Email Id: <b>{user_email}</b>
                    <br />
                    Joining Date: <b>{joining_date}</b>
                  </p>
                  <br />
                </div>
              </div>
            </div>
            <br />
          </div>

          <div className="col-8">
            <div>
              <br />

    
              <br />
              <br />

              <p className="text-secondary">
                Last 7 days <i className="bi bi-calendar-event-fill"></i>
              </p>
              <ResponsiveContainer width={800} height={350}>
              <LineChart
                width={800}
                height={350}
                data={call_data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line dataKey="call" fill="#8884d8" />
              </LineChart>
              </ResponsiveContainer>
           
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
