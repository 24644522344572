import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip1 from 'react-bootstrap/Tooltip';
import treepic from "../media/undraw_designer_re_5v95.svg";
import emptytask from "../media/undraw_floating_re_xtcj.svg";
import emptylist from "../media/undraw_no_data_re_kwbl.svg";
import emptyscript from "../media/undraw_biking_kc-4-f.svg";
import {
  getScripts,
  getContactNumber,
  getUserData,
  getCallGoals,
  getPar1Latest,
  getPar2Latest,
  getPar3Latest,
  getPar4Latest,
  getPar5Latest,
  getPar6Latest,
  addsuggestions,
  addquestions,
  addNewTask,
  getseachquestions,
  upvote,
  downvote,
  updateCallDetails,
  addNewCall,
  deleteContactToCall,
  findContactDetails,
  getscriptCallEndingInstruction,
  getTasks,
  getProgressBarStatus,
} from "../redux/slices/homeSlice";
import { useNavigate } from "react-router-dom";
import { getSourceListData } from "../redux/slices/dataSlice";
import { useSelector, useDispatch } from "react-redux";
import Sunday from "../components/Sunday";
import Question from "../pages/Questions";


function Training() {

  const access_token = localStorage.getItem("Auth");
  const dispatch = useDispatch();
  let location = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip1 id="button-tooltip" {...props}>
      {props}
    </Tooltip1>
  );

  const Auth = localStorage.getItem("Auth");
  const org = localStorage.getItem("org");

  const [livecat, updatelivecat] = useState("");
  const [livescript, setlivesscript] = useState();
  const [livesop, setlivesop] = useState();
  const [livemodel, setlivemodel] = useState();
  const [livedetailmodel, setdetailmodel] = useState();
  const [livedetailmodel2, setdetailmodel2] = useState();
  const [feedSopCategory, setSopCategory] = useState();
  const [listsop, setlistsop] = useState([]);
  const [feedOrgCategory, setOrgCategory] = useState();
  const [feedOrgTask, setOrgTask] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [instruction, setinstruction] = useState([]);


  const [livegoal, updategoaldata] = useState({
    organisation_id: org,
    your_goal: "",
  });

  //////////////////////////////////////call data/////////////////////////
  const [short_intro, updateshort_intro] = useState("");
  const [ask_permission, updateask_permission] = useState("");
  const [confirmation, updateconfirmation] = useState("");
  const [hero_line, updatehero_line] = useState("");
  const [if_no, updateif_no] = useState("");
  const [if_yes, updateif_yes] = useState("");
  const [short_pitch, updateshort_pitch] = useState("");
  const [if_postive, updateif_postive] = useState("");
  const [if_nagative, updateif_nagative] = useState("");
  const [objection, updateobjection] = useState("");
  const [ask_questions, updateask_questions] = useState("");
  const [conclusion, updateconclusion] = useState("");
  const [call_savior, updatecall_savior] = useState("");

  const [taskdesc, settaskdesc] = useState('');

  //////////////////////////////////////////Call parts//////////////////////////
    const [Part1, setPart1] = useState({
    organisation_id: org,
    script_catagory: "",
    short_intro: "",
    confirmation: "",
    hero_line: "",
    ask_permission: "",
    if_yes: "",
    if_no: "",
  });
  const [Part2, setPart2] = useState({
    organisation_id: org,
    script_catagory: "",
    shot_pitch: "",
    if_postive: "",
    if_nagative: "",
  });
  const [Part3, setPart3] = useState({
    organisation_id: org,
    script_catagory: "",
    ask_questions: "",
  });

  const [Part5, setPart5] = useState({
    organisation_id: org,
    script_catagory: "",
    conclusion: "",
  });
  const [Part6, setPart6] = useState({
    organisation_id: org,
    script_catagory: "",
    call_savior: "",
  });




  function openview(item, item1){

    setlivemodel(item1);
    setdetailmodel('');

    if(item1 == 'flow'){

      getSopProcess(item);
      setlivesop(item);
    
    }else if(item1 == 'graph'){

      setlivesop(item);
      getFlow(item);
      

    }

    
  }

function opendetail(item1, item2){

  setdetailmodel(item1);

  if(item1 == 'call'){

    sendPitchCat(item2);
    
    getScriptCallingInstrucation(item2);

    getScriptGoals(item2);


  }else if(item1 == 'task'){


     getTaskDesc(item2);


  }


}

function opensecondview(item1 , item2){

  setdetailmodel2(item1);

  if(item1 == 'call'){

    sendPitchCat(item2);
    getScriptCallingInstrucation(item2);

  }

}

function getTaskDesc(pitchCatData) {
  const headers = {
    "Content-Type": "text/plain",
    Authorization: `Bearer ${Auth}`,
  };
  axios
    .get(
      "/get_set_task_desc?TaskHeading=".concat(pitchCatData),
      { headers }
    )
    .then((data) => {
      if (data.status == "200") {
        settaskdesc(data.data.desc);
      }

      // setErrorMessage1(data.data.message);
    })
    .catch((err) => console.log("error", err));
}


  function getScript() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_all_script_types?orgID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgCategory(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getTask() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/GetTaskHeadings?organisationID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgTask(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function getSopProcess(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_sop_process?SopName=".concat(item), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setlistsop(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getFlow(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_sop_reports?SopName=".concat(item), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getSops() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setSopCategory(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  ///////////////////////////////////////////get full script//////////////////////////////////////////////
  function sendPitchCat(item) {

    let livescript = item; 

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };


    dispatch(
      getPar1Latest({ access_token, org, type: livescript })
    ).then((res) => {
      if (res.payload.status == 200) {
        localStorage.setItem("current_script", livescript);
        updatelivecat(livescript);
        
        updateshort_intro(res.payload.data.short_intro);
        updateask_permission(res.payload.data.ask_permission);
        updateconfirmation(res.payload.data.confirmation);
        updatehero_line(res.payload.data.hero_line);
        updateif_no(res.payload.data.if_no);
        updateif_yes(res.payload.data.if_yes);
      }
    });

    dispatch(
      getPar2Latest({ access_token, org, type: livescript })
    ).then((res) => {
      if (res.payload.status == 200) {
       
        updateshort_pitch(res.payload.data.shot_pitch);
        updateif_nagative(res.payload.data.if_nagative);
        updateif_postive(res.payload.data.if_postive);
      }
    });

    dispatch(
      getPar3Latest({ access_token, org, type: livescript })
    ).then((res) => {
      if (res.payload.status == 200) {
       
        updateask_questions(res.payload.data.ask_questions);
      }
    });

    dispatch(
      getPar4Latest({ access_token, org, type: livescript })
    ).then((res) => {
      if (res.payload.status == 200) {
       
        updateobjection(res.payload.data.questions_set);
      }
    });

    dispatch(
      getPar5Latest({ access_token, org, type: livescript })
    ).then((res) => {
      if (res.payload.status == 200) {
       
        updateconclusion(res.payload.data.conclusion);
      }
    });

    dispatch(
      getPar6Latest({ access_token, org, type: livescript })
    ).then((res) => {
      if (res.payload.status == 200) {
        
        updatecall_savior(res.payload.data.call_savior);
      }
    });

    
  }

 function getScriptCallingInstrucation(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/Get_script_callEnding_instruction?organisation_id=".concat(org) +
          `&scripts_types=${item}`,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setinstruction(data.data.instruction);
        }

       
      })
      .catch((err) => console.log(err));
  }

  function getScriptGoals(pitchCatData) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/Get_ScriptGoal?organisation_id=".concat(org) +
          `&scripts_types=${pitchCatData}`,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updategoaldata(data.data);
        }

        // setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log("errerrerrerr", err));
  }




  useEffect(() => {
    getSops();
    getScript();
    getTask();
  }, [1]);

  return (
    <>

    <div className="container-fluid">


    <blockquote className="blockquote mb-0">
  <p><i className="bi bi-journal-text"></i> You want to learn from?</p>

</blockquote>
<p>Get a quick Sop training.</p>

    </div>
   <br/>  
    <div className="container-fluid">
    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="home">
            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="bi bi-bar-chart-steps"></i> Operating procedure</button>
          </li>
          <li className="nav-item" role="profile">
            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i class="bi bi-app"></i> Calls & Tasks</button>
          </li>
        
          <li className="nav-item" role="improvement">
            <button className="nav-link" id="pills-improvement-tab" data-bs-toggle="pill" data-bs-target="#pills-improvement" type="button" role="tab" aria-controls="pills-improvement" aria-selected="false"><i className="bi bi-robot"></i> Improvement</button>
          </li>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-home" role="home" aria-labelledby="pills-home-tab">
<br/>
<div className="row">

<div className="col-12 col-sm-3 border-end border-white">
<mark>
        <i className="bi bi-bezier"></i> All sop
                    categories <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('All your sop(standard operating procedure) will be displayed here. eg: customer onbording, KYC, application verification, etc')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                  <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "500px" }}
                >
                  <ol className="list-group">
                      {feedSopCategory &&
                        feedSopCategory?.map((item) => (

                          livesop == item?<li  className="list-group-item d-flex justify-content-between align-items-start text-dark bg-info border border-dark" onClick={()=>openview(item, 'flow')}>
                          <div className="ms-2 me-auto">
                            {item}
                          </div>

                          <i
                            title="View"
                            className="bi bi-arrow-right"
                            
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>

                          
                          
                          
                          
                        </li>:<li  className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark" onClick={()=>openview(item, 'flow')}>
                        <div className="ms-2 me-auto">
                          {item}
                        </div>


                        <i
                          title="View"
                          className="bi bi-arrow-right"
                          
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                        ></i>


                        
                    
                      </li>

                          ))}
                  
                  </ol>
                  {feedSopCategory ==''?<div align='center'><br/><br/><br/><br/><br/><br/><img
          src={emptylist}  height={150}
        /><p><br/>SOPs not available</p></div> :null}
                </div>

</div>
<div className="col-12 col-sm-3 border-end border-white">
  
{livemodel == 'flow'?
          (<div className="col-12">
       <mark>
       <i className="bi bi-geo-fill"></i> Prospect/Customer journey. <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('List of calls and task that you have to follow in the sop')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
       
        {
          listsop ==''?
          <div align='center'>
            <br/><img
          src={treepic}  height={200}
        /><br/></div>:null

        }
      <div className="col-12">
        <br/>
      <ol className="list-group">
      {listsop &&
                      listsop?.map((item, index) => (<>
                      <li className="list-group-item d-flex justify-content-between align-items-start border border-dark rounded tiledesign">
  <div className=" me-auto">
    <div className="fw-bold"><span className="badge rounded-pill bg-dark text-light">{index+1}</span> {item.process_catagory}</div>
    {item.process_desc}
<br/>
    {item.Instructions &&
                      item.Instructions?.map((item, index) => (
                        <>
                        {item.blocktype=='call'? <><span onClick={()=>opendetail(item.blocktype, item.blockname)} className="badge bg-primary border border-dark cursor"><i className="bi bi-telephone-fill"></i> {item.blockname}
   </span>&nbsp;</>:item.blocktype=='task'? <><span onClick={()=>opendetail(item.blocktype, item.blockname)} className="badge bg-info text-dark border border-dark cursor"><i className="bi bi-bicycle"></i> {item.blockname}
   </span>&nbsp;</>:null} 
                        </>
                      ))}

  </div>
  
</li>

      {/* <li className="list-group-item d-flex justify-content-between align-items-start text-dark tiledesign border border-dark">
                          <div className="ms-2 me-auto">
                          {index+1}. {item.process_catagory}                            
                          </div>
                          <div className="ms-2 me-auto">

                          {item.process_desc}
                          </div>
                      
                          <i
                            title="View"
                            
                            className="bi bi-eye-fill"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={()=>opendetail(item.process_type, item.catagory)}
                            
                          ></i>
                          
                        </li> */}
                        <div align='center'><i className="bi bi-arrow-down"></i></div></>
                        ))}
                        </ol>
                      
      </div>
      
      </div>):null
}
</div>
<div className="col">
  {
    livedetailmodel == 'call'?<>
{
  livegoal.your_goal!=''? <><mark>
  <i className="bi bi-bullseye"></i> Goal behind this call: <i className="bi bi-arrow-down"></i>
                      </mark>
                      <div className="card border-dark">
    <div className="card-body">{livegoal.your_goal}</div>
  </div>
  <br/>
  </>
  :null
}

   


    <mark>
                      <i className="bi bi-file-text-fill"></i> What to say in this call: <i className="bi bi-arrow-down"></i>
                    </mark>
                    

                    <div
              className="accordion shadow-sm"
              id="accordionPanelsStayOpenExample"
            >
             { (short_intro ==='' && confirmation ==='' && hero_line ==='' && ask_permission ==='' && if_yes ==='' && if_no ==='')?null:<div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button border border-dark"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-1-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
                      />
                    </svg>
                    &nbsp; Salute & Confirmation - Greeting   
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      {short_intro}&nbsp;{confirmation}&nbsp;
                      <br />
                      <br /> <b className="text-white">{hero_line}</b>&nbsp;
                      <br />
                      <br /> {ask_permission}
                    </blockquote>

                    <div className="card bg-secondary text-dark">
                      <div className="card-body">
                        If Yes : <b>{if_yes}</b>
                        <hr />
                        If No : <b>{if_no}</b>
                      </div>
                    </div>
                    <br />

                   
                  </div>
                </div>
              </div>}

              {(short_pitch ==='' && if_postive ==='' && if_nagative ==='')?null:<div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-2-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                    </svg>
                    &nbsp; Pitch and Information
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      <p className=" text-white">
                        <b>{short_pitch}</b>
                      </p>
                    </blockquote>

                    <br />
                    <div className="card bg-secondary text-dark">
                      <div className="card-body">
                        If Postive : <b>{if_postive}</b>
                        <hr />
                        If Negative : <b>{if_nagative}</b>
                      </div>
                    </div>
                    <br />

                  
                  </div>
                </div>
              </div>}
              {ask_questions == ''?null:<div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-3-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                    </svg>
                    &nbsp; Consult - Ask Question and Talk
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote
                      className="blockquote"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {ask_questions}
                    </blockquote>
                   
                  </div>
                </div>
              </div>}
              {objection.length ===0?null:<div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFour"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-4-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176c-.218.352-.438.703-.657 1.055ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z" />
                    </svg>
                    &nbsp; Objection Handling
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      {objection &&
                        objection?.map((item) => (
                          <div>
                            <strong>{item.question}</strong>
                            <br />
                            <br />
                            <p>{item.answer}</p>
                          </div>
                        ))}
                    </blockquote>

                
                  </div>
                </div>
              </div>}
              {conclusion ===''?null:<div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-5-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z" />
                    </svg>
                    &nbsp; Call Ending (call to action)
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote
                      className="blockquote"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {conclusion}
                      <br />
                      <br />
                    </blockquote>
                    
                  </div>
                </div>
              </div>}
              {call_savior === ''?null:<div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseSix"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Call Savior
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      {call_savior}
                    </blockquote>
                   
                  </div>
                </div>
              </div>}

              
            </div>
            {(short_intro ==='' && confirmation ==='' && hero_line ==='' && ask_permission ==='' && if_yes ==='' && if_no ==='' && short_pitch ==='' && if_postive ==='' && if_nagative ==='' && ask_questions == '' && objection.length ===0 && conclusion ==='' && call_savior === '')?
              <div align='center'><br/><img
              src={emptyscript}  height={350}
            /> <p><br/>Call assistance not found</p></div>
              :null}
<br/>
            <mark>
        <i className="bi bi-signpost-2-fill"></i> What to do after this call? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('On th bases of call brief what is the next thing that you should be doing.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>

                  <div className="conatiner">
                    <br/>
                      <ol className="list-group">
                        {instruction &&
                          instruction?.map((item) => (
                           <p>{item.call_brief} : {
                            item.action.task != ""?
                            <b>{item.action.task} <span className="badge bg-info text-dark"><i className="bi bi-bicycle"></i> Task</span> </b>
                            :null 
                           }
                           {
                            item.action.script !=""?
                            <><b>{item.action.script} </b><span className="badge bg-dark"><i className="bi bi-phone"></i> Call</span></>
                            :null 
                           } </p> 
                          ))}
                      </ol>
                    </div>


    </>
    :livedetailmodel == 'task'?<>
    
    <div className="continer">

    {taskdesc!=''?<><mark>
                      <i className="bi bi-file-text-fill"></i> What to do in this task: <i className="bi bi-arrow-down"></i>
                    </mark><div className="card border-dark">
    <div className="card-header">
    Task description
  </div>
  <div class="card-body bg-dark text-white">
  <blockquote className="blockquote">
    {taskdesc}
    </blockquote>
  </div>
</div></>
    :<><br/>
    <br/><br/><div align="center"><img
    src={emptytask}  height={250}
  />
  <p align='center'><br/> This task is empty </p>
  </div></>}

    
      

    
  
  
  </div></>
  :null
  }
</div>


</div>

          </div>
          <div className="tab-pane fade" id="pills-profile" role="profile" aria-labelledby="pills-profile-tab">


<div className="row">
  <div className="col-12 col-sm-3">
    <br/>
  <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  <i className="bi bi-card-heading"></i> All Scripts
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  {" "}
                  <i className="bi bi-bicycle"></i> All Tasks
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <br/>
                                <p>
                  <mark>
                    <i className="bi bi-file-text-fill"></i> All script
                    categories <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Add all type of calls that you make. Eg: Sales Call, Follow up call, Payment confirmation call')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                </p>
                <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "500px" }}
                >
                  <div className="conatiner">
                    <ol className="list-group">
                      {feedOrgCategory &&
                        feedOrgCategory?.map((item) => (
                          item.script_type_name == livescript?<li className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark"  onClick={()=>opensecondview('call', item.script_type_name)}>
                          <div className="ms-2 me-auto">
                            {item.script_type_name}
                          </div>
                          <i
                            title="View"
                            
                            className="bi bi-eye-fill"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            
                          ></i>
                         
                        </li>
                          :<li className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark" onClick={()=>opensecondview('call', item.script_type_name)}>
                          <div className="ms-2 me-auto">
                            {item.script_type_name}
                          </div>
                          <i
                            title="View"
                            
                            className="bi bi-eye-fill"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            
                          ></i>
                        
                          
                        </li>
                        ))}
                    </ol>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <br />
                <p>
                  <mark>
                    <i className="bi bi-bicycle"></i> All task categories{" "}
                    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Add all type of tasks that your team does. Eg: Fix a demo, Send proposal, Document verification')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                </p>
                <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "700px" }}
                >
                  <div className="conatiner">
                    <ol className="list-group">
                      {feedOrgTask &&
                        feedOrgTask?.map((item) => (
                          <a href="#" className="list-group-item list-group-item-action border-dark bg-light" aria-current="true">
        <div className="d-flex w-100 justify-content-between">
        </div>
        <p className="mb-1"><b>{item.heading}</b></p>
        <small >{item.description}</small>
      </a>
                        ))}
                    </ol>
                  </div>
                </div>
              </div>

              </div>

             


  </div>

  <div className="col-12 col-sm-9">

   {
    livedetailmodel2 =='call'?<>
    <br/><br/>
    <mark>
                      <i className="bi bi-file-text-fill"></i> What to say in this call? <i className="bi bi-arrow-down"></i>
                    </mark>
                    

                    <div
              className="accordion shadow-sm"
              id="accordionPanelsStayOpenExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button border border-dark"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-1-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
                      />
                    </svg>
                    &nbsp; Salute & Confirmation - Greeting   
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      {short_intro}&nbsp;{confirmation}&nbsp;
                      <br />
                      <br /> <b className="text-white">{hero_line}</b>&nbsp;
                      <br />
                      <br /> {ask_permission}
                    </blockquote>

                    <div className="card bg-secondary text-dark">
                      <div className="card-body">
                        If Yes : <b>{if_yes}</b>
                        <hr />
                        If No : <b>{if_no}</b>
                      </div>
                    </div>
                    <br />

                   
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-2-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                    </svg>
                    &nbsp; Pitch and Information
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      <p className=" text-white">
                        <b>{short_pitch}</b>
                      </p>
                    </blockquote>

                    <br />
                    <div className="card bg-secondary text-dark">
                      <div className="card-body">
                        If Postive : <b>{if_postive}</b>
                        <hr />
                        If Negative : <b>{if_nagative}</b>
                      </div>
                    </div>
                    <br />

                  
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-3-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                    </svg>
                    &nbsp; Consult - Ask Question and Talk
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote
                      className="blockquote"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {ask_questions}
                    </blockquote>
                   
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFour"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-4-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176c-.218.352-.438.703-.657 1.055ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z" />
                    </svg>
                    &nbsp; Objection Handling
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      {objection &&
                        objection?.map((item) => (
                          <div>
                            <strong>{item.question}</strong>
                            <br />
                            <br />
                            <p>{item.answer}</p>
                          </div>
                        ))}
                    </blockquote>

                
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-5-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z" />
                    </svg>
                    &nbsp; Call Ending (call to action)
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote
                      className="blockquote"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {conclusion}
                      <br />
                      <br />
                    </blockquote>
                    
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button border border-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseSix"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Call Savior
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body bg-dark text-light">
                    <blockquote className="blockquote">
                      {call_savior}
                    </blockquote>
                   
                  </div>
                </div>
              </div>
            </div>
            <br/>
<mark>
        <i className="bi bi-signpost-2-fill"></i> What to do after this call? <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('On th bases of call brief what is the next thing that you should be doing.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>

                  <div className="conatiner">
                    <br/>
                      <ol className="list-group">
                        {instruction &&
                          instruction?.map((item) => (
                           <p>{item.call_brief} : {
                            item.action.task != ""?
                            <b>{item.action.task} <span className="badge bg-info text-dark"><i className="bi bi-bicycle"></i> Task</span> </b>
                            :null 
                           }
                           {
                            item.action.script !=""?
                            <><b>{item.action.script} </b><span className="badge bg-dark"><i className="bi bi-phone"></i> Call</span></>
                            :null 
                           } </p> 
                          ))}
                      </ol>
                    </div>
    </>
    :null

   }


  </div>
</div>
            
          </div>
        
          <div className="tab-pane fade" id="pills-improvement" role="improvement" aria-labelledby="pills-improvement-tab">
            <br/>
            <Sunday/>

          </div>
         
        </div>
    </div>

    </>
  );
}

export default Training;
