import React from "react";

export default function alert({ heading, description, method, item, modelid }) {
  return (
    <div>
      <div
        className="modal fade"
        id={modelid}
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title" id="exampleModalLabel">
                {heading}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">{description}</div>
            <div
              className="d-grid gap-1 d-md-flex justify-content-md-end"
              style={{ margin: "6px" }}
            >
              {" "}
              <button
                type="button"
                className="btn btn-light btn-sm border-dark"
                data-bs-dismiss="modal"
                style={{ marginRight: "10px" }}
              >
                <i className="bi bi-x"></i> Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm border-dark"
                data-bs-dismiss="modal"
                onClick={() => method(item)}
              >
                <i className="bi bi-check2"></i> Proceed 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
