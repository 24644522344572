import offline from "../media/offline.png";
import React, { useState, useEffect } from "react";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div align="center">
        <br />
        <br />
        {/* <img src={offline} alt="offline" style={{ width: "30%" }} /> */}
        <h1>No Internet Connection.</h1>
        <p>Please try again later.</p>
      </div>
    );
  }
};

export default NoInternetConnection;
