import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const user_email = localStorage.getItem("user_email");
const organisation_name = localStorage.getItem("organisation_name");
const user_name = localStorage.getItem("user_name");
const joining_date = localStorage.getItem("joining_date");
const role = localStorage.getItem("role");
const admin = localStorage.getItem("admin");
const Auth = localStorage.getItem("Auth");




function Score() {


  const [scoreDate, setScoreDate] = useState('');

  console.log(scoreDate);

  const [allscore, setallscore] = useState([]);

  const [suggquestion, setsuggquestion] = useState([]);

  const [call_data, set_call_data] = useState([
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
  ]);
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("token");

  // Handler function to update the state when the date input changes
  const handleDateChange = (event) => {
    setScoreDate(event.target.value);

    getScore(event.target.value);


  };


  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);
  
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
  
    canvas.width = 200;
    canvas.height = 200;
  
    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);
  
    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);
  
    return canvas.toDataURL("image/png");
  }

  function getCall() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_user_call_count?userid=".concat(token), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          let user_call = Object.values(data.data);
          let user_date = Object.keys(data.data);

          set_call_data([
            {
              name: user_date[6],
              call: user_call[6],
            },
            {
              name: user_date[5],
              call: user_call[5],
            },
            {
              name: user_date[4],
              call: user_call[4],
            },
            {
              name: user_date[3],
              call: user_call[3],
            },
            {
              name: user_date[2],
              call: user_call[2],
            },
            {
              name: user_date[1],
              call: user_call[1],
            },
            {
              name: user_date[0],
              call: user_call[0],
            },
          ]);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function getScore(item) {

    

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_scores_for_admin?date=".concat(item), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          
          rankcheck(data.data);
       
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function rankcheck(item){

    item.sort(function(a, b){return b.Todays_Score - a.Todays_Score});

  setallscore(item);

  }

  function getQuestionSugg() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_qustion_suggetion_count", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          
          setsuggquestion(data.data);
       
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }
  
  


  useEffect(() => {
// Get the current date based on the browser's date and time
const currentDate = new Date();

// Extract year, month, and day
const year = currentDate.getFullYear();
const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
const day = currentDate.getDate().toString().padStart(2, '0');

// Format the date as "YYYY-MM-DD"
const formattedDate = `${year}-${month}-${day}`;

    setScoreDate(formattedDate);

    getScore(formattedDate);
    getQuestionSugg();
    getCall();
    
  }, [1]);

  return (

    <>
    
    <div className="row">
        

        <div className="col-6 col-sm-6">
      <div className="card bg-primary shadow-sm mb-3 bg-gradient text-dark shadow-lg rounded-3">
      <div className="card-body">
                        <h3 align="left">
                        <i className="bi bi-trophy"/>
                        {allscore &&
                                    allscore?.map((item, index) => (

                                      item.added_by_user == token? <b> {index + 1} </b>
                                      :null
                                     
                                        
                                    ))}
                         
                        </h3>
                        <p align="left" className="card-text text-dark">
                        Rank Today
                        </p>
                      </div>
        </div>
      </div>
      <div className="col-6 col-sm-6">
      <div className="card bg-info mb-3 bg-gradient text-dark shadow-lg rounded-3">
      <div className="card-body">
                        <h3 align="left">
                        <i className="bi bi-arrow-down-up"></i>
                          <b> {allscore &&
                                    allscore?.map((item, index) => (

                                      item.added_by_user == token? <>{item.Todays_Score}</>
                                      :null
                                     
                                        
                                    ))}</b>
                        </h3>
                        <p align="left" className="card-text text-dark">
                        Score Today
                        </p>
                      </div>
        </div>
      </div>
      <div className="col-6 col-sm-6">
      <div className="card bg-info mb-3 bg-gradient text-dark  shadow-lg rounded-3">
      <div className="card-body">
                        <h3 align="left">
                        <i className="bi bi-chat-dots"></i>
                          <b> {suggquestion.suggestions_count}</b>
                        </h3>
                        <p align="left" className="card-text text-dark">
                        All Suggestions
                        </p>
                      </div>
        </div>
      </div>
      <div className="col-6 col-sm-6">
        <div className="card bg-warning mb-3 bg-gradient text-dark shadow-lg rounded-3">
        <div className="card-body">
                        <h3 align="left">
                        <i className="bi bi-chat-right-text"></i>
                          <b> {suggquestion.questions_count}</b>
                        </h3>
                        <p align="left" className="card-text text-dark">
                        All Questions
                        </p>
                      </div>
        </div>
      </div>
    </div>
    <div className="row">
    <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label text-secondary">
    Select Date
  </label>
  <input type="date" className="form-control" id="exampleFormControlInput1" name="scoreDate"
        value={scoreDate}
        onChange={handleDateChange} />
</div>

    </div>

    <div className="row">

    <div className="col-sm-12">
   <p className='text-secondary'>Top 5 users <i className="bi bi-award"></i></p>
   </div>
   <ul class="list-group">
   {allscore &&
                                    allscore?.map((item, index) => (

                                      index < 5? 
                                      <> 
                                      {item.added_by_user == token?
                                       <li className="list-group-item d-flex justify-content-between align-items-center text-dark bg-light border-light">
                                       <img
                                     className="circle"
                                     src={generateavatar(
                                       item.added_by_username,
                                       "White",
                                       "#004aad"
                                     )}
                                     width="30"
                                     height="30"
                                   ></img>{" "} {item.added_by_username} - {item.Todays_Score}
     <span className="badge bg-dark text-white rounded-pill">{index + 1}</span>
   </li>
                                      :<li className="list-group-item d-flex justify-content-between align-items-center text-white bg-dark border-secondary">
                                      <img
                                    className="circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="30"
                                    height="30"
                                  ></img>{" "} {item.added_by_username} - {item.Todays_Score}
    <span className="badge bg-white text-dark rounded-pill">{index + 1}</span>
  </li>}
                                     
                                      </>
                                      :null
                                      
                                     
                                        
                                    ))}
</ul>
     
      </div>

      
              <div>
        <p align='center'>
          <div className="btn btn-transparent text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRank" aria-expanded="false" aria-controls="collapseExample">
          <i className="bi bi-chevron-double-down"></i>
          </div>
        </p>
        <div className="collapse" id="collapseRank">
          <div className="card card-body bg-transparent">
          {allscore &&
                                    allscore?.map((item, index) => (

                                      index >= 5? 
                                      <> 
                                      {item.added_by_user == token?
                                       <li className="list-group-item d-flex justify-content-between align-items-center text-dark bg-light border-light">
                                       <img
                                     className="circle"
                                     src={generateavatar(
                                       item.added_by_username,
                                       "White",
                                       "#004aad"
                                     )}
                                     width="30"
                                     height="30"
                                   ></img>{" "} {item.added_by_username} - {item.Todays_Score}
     <span className="badge bg-dark text-white rounded-pill">{index + 1}</span>
   </li>
                                      :<li className="list-group-item d-flex justify-content-between align-items-center text-white bg-dark border-secondary">
                                      <img
                                    className="circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="30"
                                    height="30"
                                  ></img>{" "} {item.added_by_username} - {item.Todays_Score}
    <span className="badge bg-white text-dark rounded-pill">{index + 1}</span>
  </li>}
                                     
                                      </>
                                      :null
                                      
                                     
                                        
                                    ))}
           
           </div>
        </div>
      </div>


      <br/>
      <p className="text-secondary">
                Last 7 day call <i className="bi bi-calendar-event-fill"></i>
              </p>
              <LineChart
                width={300}
                height={175}
                data={call_data}
               
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line dataKey="call" fill="#8884d8" />
              </LineChart>

    </>
   
  );
}

export default Score;
