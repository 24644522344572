import React, { Component } from "react";
import doctor from "../media/undraw_doctors_p6aq.svg";

function Score() {

  return (
    <>
     <div className="container">
      <br/>
      <div className="row">
        <div className="col-4">
        <div className="container">
        <h1><i className="bi bi-heart-pulse"></i></h1>

<h2>Dr. Sunday</h2>
<p className="text-secondary">Setup verification functionality that allows users to confirm if account has been configured correctly.</p>

        </div>


        </div>
        <div className="col-8">
        <div className="container bg-light border">
          <br/>

        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
<h3><span className="badge bg-dark text-light">Score: 5/5</span></h3>

</div>
<br/>
<div className="row">
  <div className="col-10"><h4><i className="bi bi-people-fill"></i> Add Team</h4> 
        <p>Add call types that you make on a daily bases that are part of your sales and operations process.</p>
<br/>
</div>
  <div className="col-2 d-flex justify-content-center"> <h3><i class="bi bi-check-circle-fill text-success"></i></h3></div>
</div>

<div className="row">
  <div className="col-10"><h4><i className="bi bi-phone-fill"></i> Add Calls</h4> 
  <p>Add call types that you make on a daily bases that are part of your sales and operations process.</p>
<br/>
</div>
  <div className="col-2 d-flex justify-content-center"> <h3><i class="bi bi-check-circle-fill text-success"></i></h3></div>
</div>
     
<div className="row">
  <div className="col-10"><h4><i className="bi bi-bicycle"></i> Add Tasks</h4> 
  <p>Add tasks types that you do on a daily bases that are part of your sales and operations process.</p>
<br/>
</div>
  <div className="col-2 d-flex justify-content-center"> <h3><i class="bi bi-check-circle-fill text-success"></i></h3></div>
</div>

<div className="row">
  <div className="col-10"><h4><i className="bi bi-file-text-fill"></i> Design call script</h4> 
  <p>Design you call script add pitch, consult and objection handaling.</p>
<br/>
</div>
  <div className="col-2 d-flex justify-content-center"> <h3><i class="bi bi-check-circle-fill text-success"></i></h3></div>
</div>

<div className="row">
  <div className="col-10"><h4><i className="bi bi-bar-chart-steps"></i> Design Sop</h4> 
  <p>Design you call script add pitch, consult and objection handaling.</p>
<br/>
</div>
  <div className="col-2 d-flex justify-content-center"> <h3><i class="bi bi-check-circle-fill text-success"></i></h3></div>
</div>

        </div>
         </div>
      </div>
     </div>

      
    </>
  );
}

export default Score;
