import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialState from "../initialstate";
import axios from "axios";
export const getProgressBarStatus = createAsyncThunk(
  "getProgressBarStatus",
  async ({ access_token }, { rejectWithValue }) => {
    try {
      return await axios.get(`/Get_Organization_SetUp_status`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScripts = createAsyncThunk(
  "getScripts",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/get_script_types?orgID=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getContactNumber = createAsyncThunk(
  "getContactNumber",
  async ({ access_token, org, phoneNo }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/find_contact?contact_number=${phoneNo}&organisation_id=${org}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getUserData = createAsyncThunk(
  "getUserData",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/getusers?organisation_id=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCallGoals = createAsyncThunk(
  "getCallGoals",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/Get_ScriptGoal?organisation_id=${org}&scripts_types=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar1Latest = createAsyncThunk(
  "getParLatest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par1_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar2Latest = createAsyncThunk(
  "getPar2Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par2_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPar3Latest = createAsyncThunk(
  "getPar3Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par3_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar4Latest = createAsyncThunk(
  "getPar4Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par4_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar5Latest = createAsyncThunk(
  "getPar5Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par5_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar6Latest = createAsyncThunk(
  "getPar6Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par6_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addsuggestions = createAsyncThunk(
  "addsuggestions",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/addsuggestions/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addquestions = createAsyncThunk(
  "addquestions",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/addquestions/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addNewTask = createAsyncThunk(
  "addNewTask",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/addNewTask`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getseachquestions = createAsyncThunk(
  "getseachquestions",
  async ({ access_token, org, question }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/seach_questions?organisation_id=${org}&questionText=${question}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const upvote = createAsyncThunk(
  "upvote",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.put(`/upvote/`, data, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downvote = createAsyncThunk(
  "downvote",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.put(`/downvote/`, data, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCallDetails = createAsyncThunk(
  "updateCallDetails",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/update_call_details/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addNewCall = createAsyncThunk(
  "addNewCall",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/add_new_call/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteContactToCall = createAsyncThunk(
  "deleteContactToCall",
  async ({ access_token, id }, { rejectWithValue }) => {
    try {
      return await axios.delete(`/deleteContactToCall?record_id=${id}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const findContactDetails = createAsyncThunk(
  "findContactDetails",
  async ({ access_token, org, contact_number }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/find_contact_details?organisation_id=${org}&contact_number=${contact_number}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getscriptCallEndingInstruction = createAsyncThunk(
  "getscriptCallEndingInstruction",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/Get_script_callEnding_instruction?organisation_id=${org}&scripts_types=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTasks = createAsyncThunk(
  "getTasks",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(`/GetTaskHeadings?organisationID=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const homeSlice = createSlice({
  name: "homepage",
  initialState: initialState.home,
  reducers: {},
  extraReducers: {
    [getUserData.fulfilled]: (state, action) => {
      state.data = action.payload.data.scripts_types;
      state.feed=action.payload.data
    },
    [getContactNumber.fulfilled]: (state, action) => {
    },
    [getProgressBarStatus.fulfilled]: (state, action) => {
      state.status = action.payload.data;
    },
  },
});

export const {} = homeSlice.actions;

export default homeSlice.reducer;
