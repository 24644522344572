import React, { Component, useEffect, useState, useRef, useMemo } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import emptyboard from "../media/undraw_amusement_park_17oe.svg";
import LeadDetails from "../components/LeadDetails";
import AddLead from "../components/AddLead";
import ImportLead from "../components/importleads";
import DynamicTable from "../components/TableComponent";
import { useSelector, useDispatch } from "react-redux";
import {
  getTasks,
  getScriptAll,
  getUserData,
  getAdminCard,
  getUserCard,
  getToCallAdmin,
  getToCallUser,
  getContactNumber,
  updateTasks,
  deleteContactToCall,
  addNewContact,
  generateNewFile,
  uploadConatctFile,
  addNewTask,
  todayCallAdmin,
  adminCallDues,
  getAllTaskAdmin,
  todayCallAdminsSriptCategory,
  getAllContact,
  getfollowContact,
  uploadCallRecoding,
  getAllTaskAdminFilter,
  pendingTodaysAdmintask,
  adminTaskDues,
  deteleCallRecord,
  getSourceListData,
} from "../redux/slices/dataSlice";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  getselect_options
} from "../redux/slices/leadSlice";
import Modal from 'react-bootstrap/Modal';
import ActionModel from '../components/actionmodel';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';



const admin = localStorage.getItem("admin");


const user_name = localStorage.getItem("user_name");
const access_token = localStorage.getItem("Auth");
const org = localStorage.getItem("org");



function Sopboard() {

  const [feed, setFeed] = useState();


  const [view, setView] = useState('grid');

  const [showLead, setShowLead] = useState(false);
  const [leadData, setLeadData] = useState(null); 
  const [leadInstruction, setLeadInstruction] = useState(null); 
  const handleCloseLead = () => {setShowLead(false)
    getLeads(filterStatus);
  };
  const handleShowLead = (data1, data) => {
   
    setLeadData(data1); // Set the data before showing the Offcanvas

    setLeadInstruction(data);
    setShowLead(true);
  };


  const [showAddLead, setShowAddLead] = useState(false);

  const handleCloseAddLead = () => setShowAddLead(false);
  const handleShowAddLead = () => setShowAddLead(true);

  const [showAction, setShowAction] = useState(false);

  const handleActionClose = () => setShowAction(false);
  const handleActionShow = () => setShowAction(true);

  const audioRef = useRef();
  const dispatch = useDispatch();
  const Auth = localStorage.getItem("Auth");


  const permissionstate = useSelector((state) => state);

  // console.log(permissionstate.Nav.data.data.data.pipeline,'state payload')
   
  const allSopCategory = permissionstate?.Nav?.data?.data?.data?.pipeline || [];

  
  const [listsop, setlistsop] = useState([]);



  const [celldate, setcelldate] = useState();

  const [selectSop, setselectSop] = useState({
    sop: "",
  });

  const [Highlight, setHighlight] = useState({
    day: "",
  });

  const [ProspectType, setProspectType] = useState({
    type: "",
  });

  const [leadfilter, setLeadfilter] = useState({});



  const [leadsUm, setLeadsUM] = useState([]);


  const [filterLeads, setFilterLeads] = useState([]);



  const [filterStatus, setFilterStatus] = useState(''); // Default filter value
  
  const [selectoptions, setSelectoptions] = useState({});


  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);


  const [actiontype, setActiontype] = useState('');

  const [apitype, setapitype] = useState('');

  const [searchobject, setsearchobject] = useState({});


  const handleSelectAction = (event) => {
    setActiontype(event.target.value);
  };


  const [downloadContact, setDownloadContact] = useState({
    staring_date: "",
    ending_date: "",
    filetype: "xlsx",
  });
  const handleChangeDownloadContact = (e) => {
    setDownloadContact({ ...downloadContact, [e.target.name]: e.target.value });
  };
  
  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
    getLeads(event.target.value);
    
  };




  function convertDateFormat(dateString) {
    const dateObj = new Date(dateString);
    
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    
    return `${day}-${month}-${year}`;
}


  function getOptionData() {
    dispatch(getselect_options({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setSelectoptions(res.payload.data);
      }
    });
    
  }

  const handleCheckboxChange = (row) => {
    const newSelectedRows = [...selectedRows];
    const index = newSelectedRows.findIndex((selectedRow) => selectedRow.Id === row.Id);

    if (index === -1) {
      newSelectedRows.push(row);
    } else {
      newSelectedRows.splice(index, 1);
    }

    setSelectedRows(newSelectedRows);
    setSelectAll(false);
  };



  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...filterLeads]);
    }

    setSelectAll(!selectAll);
  };
 
function calculatesopPercentage(arrayName, targetStage) {

  // Your provided object
  const stagesObject = selectoptions;

  // Get the array based on the provided arrayName
  const targetArray = stagesObject[arrayName];

  if (targetArray) {
    // Find the index of the targetStage in the array
    const stageIndex = targetArray.indexOf(targetStage);

    // Calculate the percentage
    const percentage = ((stageIndex + 1) / targetArray.length) * 100;

    return percentage.toFixed(0);
  }
}



  const [History, setHistory] = useState();
  const [trackId, setTrackId] = useState();
  const [RecLink, setRecLink] = useState("");

  const [formFields, setFormFields] = useState([
  ]);

  let formdata = new FormData();
  let audioformdata = new FormData();


  const columnDefs = useMemo(() => [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: "",
      field: "checkbox",
      width: 50,
      
    },
    { headerName: "#", field: "priority", width: 80,
      cellRenderer: ({ data }) => (
        <span className={data.priority === 'low' ? 'text-primary' :
          data.priority === 'medium' ? 'text-warning' :
          data.priority === 'high' ? 'text-danger' : null
        }>
          <i className="bi bi-circle-fill"></i> # {data.TicketId}
        </span>
      ),
      sortable: true, 
      filter: true,
    },
    { headerName: "Lead Title", field: "title", 
      cellRenderer: ({ data }) => (
        <span>{data.title === "" ? data.contactPerson : data.title}</span>
      ),
      sortable: true, 
      filter: true,
    },
    { headerName: "Contact Person", field: "contactPerson", width: 150,
      sortable: true, 
      filter: true,
     },
    { headerName: "Organization", field: "organization", width: 150,sortable: true, 
      filter: true, },
    { headerName: "Lead Owner", field: "owner", 
      valueFormatter: ({ value }) => removeEverythingBeforeComma(value),
      width: 150,
      sortable: true, 
      filter: true, 
    },
    {
      headerName: "Progress", 
      field: "progress",
      cellRenderer: ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div 
            className="progress border border-dark progress-bar-striped mt-2" 
            style={{ width: '100%', display: 'flex', alignItems: 'center' }} // Center align items within the progress bar
          >
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-dark"
              role="progressbar"
              style={{ 
                width: `${calculatesopPercentage(data.pipeline, data.pipelineCurrentStage)}%`, 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center' // Center the text within the progress bar
              }}
              aria-valuenow={calculatesopPercentage(data.pipeline, data.pipelineCurrentStage)}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {calculatesopPercentage(data.pipeline, data.pipelineCurrentStage)} %
            </div>
          </div>
        </div>
      ),
      width: 150,
      sortable: true, 
      filter: true,
    },
    // {
    //   headerName: "Status", field: "pipelineCurrentStage", width: 150,
    //   cellRenderer: ({ data }) => (
    //     <div className="dropdown popmenu">
    //       <span className="badge bg-light text-dark cursor"
    //         id={'dropdownMenuLink' + data.Id}
    //         data-bs-toggle="dropdown">
    //         <i className="bi bi-pencil-square"></i> {data.pipelineCurrentStage}
    //       </span>
    //       <ul className="dropdown-menu popmenu" aria-labelledby={'dropdownMenuLink' + data.Id}>
    //         {listsop && listsop.map((item1, index) => (
    //           data.pipelineCurrentStage !== item1.process_catagory ? (
    //             <li key={index}>
    //               <a className="dropdown-item" href="#" onClick={() => updateOptions(item1.process_catagory, 'pipelineCurrentStage', data)}>
    //                 {item1.process_catagory}
    //               </a>
    //             </li>
    //           ) : null
    //         ))}
    //       </ul>
    //     </div>
    //   ),
    //   sortable: true, 
    //   filter: true,
    // },
    {
      headerName: "Status", field: "pipelineCurrentStage", width: 150,
      cellRenderer: ({ data }) => (
        <div className="dropdown popmenu">
          <span className="badge bg-light text-dark cursor"
            id={'dropdownMenuLink' + data.Id}
            data-bs-toggle="dropdown">
             {data.pipelineCurrentStage}
          </span>
          
        </div>
      ),
      sortable: true, 
      filter: true,
    },
    { headerName: "Deadline", field: "expectedClosingDate",
      valueFormatter: ({ value }) => convertDateFormat(value),
      width: 150 ,
      sortable: true, 
      filter: true,
    },
    {
      headerName: "View", field: "expand",
      cellRenderer: ({ data }) => (
        <i className="bi bi-arrows-angle-expand arrowexpand" onClick={() => handleShowLead(data, data.pipeline)}></i>
      ),
      width: 100,
    
    }
  ], [filterLeads, listsop, selectAll]);


 function filterUMleads( secondArray){

  let processCatagories = selectoptions[selectSop.sop];


// Get a set of unique process_catagories
const processCatagoriesSet = new Set(processCatagories);

// Remove elements from secondArray based on matching pipelineCurrentStage and process_catagory
const filteredArray = secondArray.filter(item => !processCatagoriesSet.has(item.pipelineCurrentStage));



  setLeadsUM(filteredArray);

 }

  function changeSop(e) {
    setselectSop({ ...selectSop, [e.target.name]: e.target.value });
  }

  function changeHighlight(e) {
    setHighlight({ ...Highlight, [e.target.name]: e.target.value });
  }

  function changeProspect(e) {
    setProspectType({ ...ProspectType, [e.target.name]: e.target.value });
  }

  function changeLeadFilter(e) {
    setLeadfilter({ ...leadfilter, [e.target.name]: e.target.value });
  }



  function rec() {
    document.getElementById("rec").style.display = "block";
  }
  function rec_back() {
    document.getElementById("rec").style.display = "none";
  }
  
  function removeEverythingBeforeComma(inputString) {
    const parts = inputString.split(',');
    if (parts.length > 1) {
        return parts[1].trim();
    }
    return inputString.trim();
  }


  function fixstring(str, size){

    if (str.length > size) {
      return str.substring(0, size) + "..";
    } else {
      return str;
    }
  }
  



  function returncontact(item){

    return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
    <i className="bi bi-caret-down-"></i>
            </OverlayTrigger>

  } 

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );
  
 
  

  function getcellhighlight(item){

 const today = new Date();


 const fiveDaysAgo = new Date(today.getTime() - (Highlight.day * 24 * 60 * 60 * 1000));

const otherDate = new Date(item); 


if(Highlight.day ==''){

    return 'card border-secondary mb-3 shadow';


}else{

  if (otherDate > fiveDaysAgo) {
    return 'card border-secondary mb-3 shadow';
  } else {
    return 'card border-danger mb-3 shadow border-3';
  }

}


  }

  const renderPopover = (props) => (
    <Popover id="popover-basic">
    <Popover.Body>
    {props}
    </Popover.Body>
    </Popover>
  );


  
  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);
  
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
  
    canvas.width = 200;
    canvas.height = 200;
  
    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);
  
    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);
  
    return canvas.toDataURL("image/png");
  }

  function updataAudioFile(e) {
    const uploaded_file = e.target.files[0];

    audioformdata.append("uploaded_file", uploaded_file);
    //audioformdata.append("Recordid", '644aa9bda435914ea27a2222');
  }

  function changeFace(item) {
    if (item == "intense") {
      return (
        <i className="bi bi-emoji-angry-fill text-danger" title="Intense"></i>
      );
    } else if (item == "unhappy") {
      return <i className="bi bi-emoji-frown-fill text-dark" title="Unhappy"></i>;
    } else if (item == "normal") {
      return (
        <i className="bi bi-emoji-smile-fill text-primary" title="Normal"></i>
      );
    } else if (item == "happy") {
      return (
        <i className="bi bi-emoji-laughing-fill text-success" title="Happy"></i>
      );
    }
  }

  function changeRec(item) {
    if (item.call_recoding == true) {
      return (
        <button
          onClick={() => getRec(item)}
          className="border-0 bg-transparent"
        >
          <i className="bi bi-play-circle-fill"></i>
        </button>
      );
    } else if (item.call_recoding == false) {
      return (
        <button
          className="border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#audioModal"
        >
          <i className="bi bi-cloud-arrow-up"></i>
        </button>
      );
    } else {
      return (
        <button
        className="border-0 bg-transparent"
        data-bs-toggle="modal"
        data-bs-target="#audioModal"
      >
        <i className="bi bi-cloud-arrow-up"></i>
      </button>
      );
    }
  }

  function uploadAudio() {
    dispatch(
      uploadCallRecoding({
        access_token,
        data: audioformdata,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        document.getElementById("close1").click();
      }
    });
  }
  
  const updateSong = (source) => {
    setRecLink(source);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };


  function getLeads(item) {
    const headers = {
      "Content-Type": "application/json",
       Authorization: `Bearer ${Auth}`,
    };
    axios
      .post(
        "/get_leads", {
          "pipeline_name": selectSop.sop,
          "ltype": item
        } ,
        {headers}
      )
      .then((data) => {
        if (data.status == "200") {

  
          setFilterLeads(data.data);
          filterUMleads(data.data);
          setapitype('simple');
          setsearchobject({
            "pipeline_name": selectSop.sop,
            "ltype": item
          });
    
        }
  
      })
      .catch((err) => {
        console.log(err);
      
      });
  }

     ////get form data

     function getFormData() {

      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/get_addformfields",
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setLeadfilter({});
            setFormFields(data.data.data_format);
            
          }
  
          // setErrorMessage1(data.data.message);
        })
        .catch((err) => console.log("error", err));
    }

  function progressElement(item) {
    if (item.call_status == "None")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped"
            role="progressbar"
            style={{ width: "0%" }}
            aria-valuenow={0}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            0%
          </div>
        </div>
      );
    else if (item.call_status == "Salute & Confirmation")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-primary text-white"
            role="progressbar"
            style={{ width: "20%" }}
            aria-valuenow={20}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            20%
          </div>
        </div>
      );
    else if (item.call_status == "Pitch and Information")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-danger text-white"
            role="progressbar"
            style={{ width: "40%" }}
            aria-valuenow={40}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            40%
          </div>
        </div>
      );
    else if (item.call_status == "Ask Question & Talk")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-warning text-dark"
            role="progressbar"
            style={{ width: "60%" }}
            aria-valuenow={60}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            60%
          </div>
        </div>
      );
    else if (item.call_status == "Objection Handling")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-darker text-white progress-bar-animated"
            role="progressbar"
            style={{ width: "80%" }}
            aria-valuenow={80}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            80%
          </div>
        </div>
      );
    else if (item.call_status == "Closing")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-success text-white progress-bar-animated"
            role="progressbar"
            style={{ width: "100%" }}
            aria-valuenow={100}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            100%
          </div>
        </div>
      );
    return item.call_status;
  }

  function getRec(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/CallRecoding?".concat(
           "docucmet_id=",
          item.id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateSong(data.data);
          rec();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function openNav(item, itemId) {
    setHistory();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("mySidenav").style.width = "500px";

    document.getElementById("trackbtn").click();
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }


  function alterElement(item) {
    var added_at = item.added_at;
    if(item.added_at == '' ||  item.added_at ==null ){
      added_at = '';
    }else{
      added_at = added_at.substring(5, 16);
      var month = item.added_at.substring(5, 7);
      var day = item.added_at.substring(8, 10);
      var time = item.added_at.substring(11, 16);
      added_at = time.concat(' ', day,'/', month ); 
    }
     

    
      return added_at;

  }



  function getSopProcess(item) {

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };


      axios
        .get("/Get_sop_process?SopName=".concat(item), {
          headers,
        })
        .then((data) => {
          if (data.status == "200") {
            setlistsop(data.data);
            setLeadInstruction(data.data);

            
          }
          
        })
        .catch((err) => console.log(err));

   
  }

  function dragStarted(process_catagory, type, item){
    
    ///data transfer
    
    console.log(process_catagory);
  }

  function fetchUserData() {
    dispatch(getUserData({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setFeed(res.payload.data);
      }
    });
    
  }
  



  const draggingOver=()=>{

    console.log('dragged');

  }


  const dragDropped = () =>{

    console.log('card dragged');

  }




  function serachSop(){

    getSopProcess(selectSop.sop);
    getLeads(filterStatus);

    

  } 

  function searchContact(item) {
    let phoneno = item;
    dispatch(
      getContactNumber({
        access_token,
        org,
        phoneNo: phoneno,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setHistory(res.payload.data.call_history);
      }
    });
  }

  function removestatus(index){

 

  //listsop.forEach(obj => delete obj.status);

  listsop[index]['status'] = '';

  setlistsop(listsop);


  }

  function changeFace(item) {
    if (item == "intense") {
      return (
        <i className="bi bi-emoji-angry-fill text-danger" title="Intense"></i>
      );
    } else if (item == "unhappy") {
      return <i className="bi bi-emoji-frown-fill text-dark" title="Unhappy"></i>;
    } else if (item == "normal") {
      return (
        <i className="bi bi-emoji-smile-fill text-primary" title="Normal"></i>
      );
    } else if (item == "happy") {
      return (
        <i className="bi bi-emoji-laughing-fill text-success" title="Happy"></i>
      );
    }
  }


  function prospectcard(item2, type){

    if(type == 'type1'){

      return(<>
        <div className={getcellhighlight(item2.added_at_utc)}  align='left' style={{ maxWidth: "18rem" }}>
        <span className="position-absolute top-0 start-50 translate-middle badge  bg-primary"><i className="bi bi-phone"></i></span>
      
      
        <div className="card-body text-dark">
          <h6 className="card-title">      {item2.customer_name==""?<a
                                      href={
                                        "callinfo?phoneno=" + item2.contact_number
                                      }
                                      target="_blank"
                                    >{item2.contact_number}</a>:<a
                                    href={
                                      "callinfo?phoneno=" + item2.contact_number
                                    }
                                    target="_blank"
                                  >{item2.customer_name}</a>
                            }
                            {returncontact(item2)}</h6>
      
      
      
          <p className="card-text">
      
        
          <span  onClick={() =>
                                      openNav(item2.contact_number, item2.id)
                                    } className="badge bg-dark text-white cursor">Track      <i
                                    className="bi bi-geo-fill"
                                  /></span> {" "} <span className="badge bg-success text-white cursor"><i className="bi bi-rulers"></i> {item2.call_brief} </span>
                                  {" "}{changeFace(item2.clint_mood)}
                                    <br/><br/>
      
          <i className="bi bi-person-circle"></i> {item2.added_by_username}
          </p>
        </div>
        <div className="card-footer text-muted">
        <i className="bi bi-clock"></i> {alterElement(item2)}
      </div>
      </div>
      <br/></>);

    }else{

      return(<>
        <div className={getcellhighlight(item2.added_at_utc)}  align='left' style={{ maxWidth: "18rem" }}>
        <span className="position-absolute top-0 start-50 translate-middle badge  bg-primary"><i className="bi bi-phone"></i></span>
        <span className="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle">
        <span className="visually-hidden">New alerts</span>
      </span>
        <div className="card-body text-dark bg-light">
          <h6 className="card-title">      {item2.customer_name==""?<a
                                      href={
                                        "callinfo?phoneno=" + item2.contact_number
                                      }
                                      target="_blank"
                                    >{item2.contact_number}</a>:<a
                                    href={
                                      "callinfo?phoneno=" + item2.contact_number
                                    }
                                    target="_blank"
                                  >{item2.customer_name}</a>
                            }
                            {returncontact(item2)}</h6>
      
      
      
          <p className="card-text">
      
        
          <span  onClick={() =>
                                      openNav(item2.contact_number, item2.id)
                                    } className="badge bg-dark text-white cursor">Track      <i
                                    className="bi bi-geo-fill"
                                  /></span> {" "} <span className="badge bg-danger text-white cursor"><i className="bi bi-rulers"></i> {item2.call_brief} </span>
                                  {" "}{changeFace(item2.clint_mood)}
                                    <br/><br/>
      
          <i className="bi bi-person-circle"></i> {item2.added_by_username}
          </p>
        </div>
        <div className="card-footer text-muted">
        <i className="bi bi-clock"></i> {alterElement(item2)}
      </div>
      </div>
      <br/></>);

    }
  }

  function updateOptions (value, keynamevalue,LeadData ){

    
    let finaldata = { ...LeadData, [keynamevalue]: value };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios.post("/update_leads", finaldata, { headers }).then((data) => {
      if (data.status == "200") {

        setLeadData(finaldata);
        toast.success("Lead updated successfully!", {
          position: "bottom-left",
          autoClose: 1000,
        });
        serachSop();


      }
    });

  }

  let handleDownload = () => {
    let data = {
      organisation_id: org,
      ...downloadContact,
    };
    const headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Download_Leads", JSON.stringify(data), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `CallData.${downloadContact.filetype}`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  function submitAdvanceFilter () {

    getSopProcess(leadfilter.pipeline);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/get_leads_advance", {leadfilter}, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setFilterLeads(data.data);
          filterUMleads(data.data);
          setapitype('advance');
          setsearchobject(leadfilter);
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  function prospectcard2(item2, type){

    if(type == 'type1'){
    return(<><div className={getcellhighlight(item2.added_at_utc)}  align='left' style={{ maxWidth: "18rem" }}>
        <span className="position-absolute top-0 start-50 translate-middle badge  bg-dark"><i className="bi bi-bicycle"></i></span>
        
  
  <div className="card-body text-dark">
    <h6 className="card-title">      {item2.customer_name=="" || item2.customer_name==null?item2.contact_number:item2.customer_name
                      }
                      {returncontact(item2)}</h6>



    <p className="card-text">
    <span  onClick={() =>
                                openNav(item2.contact_number, item2.id)
                              } className="badge bg-dark text-white cursor">Track      <i
                              className="bi bi-geo-fill"
                            /></span> {" "}<span  onClick={() =>
                              openNav(item2.contact_number, item2.id)
                            } className="badge bg-success text-white cursor"><i className="bi bi-rulers"></i> {item2.taskStatus} </span>
                              <br/><br/>
    <i className="bi bi-person-circle"></i> {item2.added_by_username}
    </p>
  </div>
  <div className="card-footer text-muted">
  <i className="bi bi-clock"></i> {alterElement(item2)}
</div>
</div><br/></>); 
    }else{
    return(<><>
    <div className={getcellhighlight(item2.added_at_utc)}  align='left' style={{ maxWidth: "18rem" }}>
        <span className="position-absolute top-0 start-50 translate-middle badge  bg-dark"><i className="bi bi-bicycle"></i></span>
        <span className="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle">
  <span className="visually-hidden">New alerts</span>
</span>
  
  <div className="card-body text-dark bg-light">
    <h6 className="card-title">      {item2.customer_name=="" || item2.customer_name==null?item2.contact_number:item2.customer_name
                      }
                      {returncontact(item2)}</h6>



    <p className="card-text">
    <span  onClick={() =>
                                openNav(item2.contact_number, item2.id)
                              } className="badge bg-dark text-white cursor">Track      <i
                              className="bi bi-geo-fill"
                            /></span> {" "}<span  onClick={() =>
                              openNav(item2.contact_number, item2.id)
                            } className="badge bg-danger text-white cursor"><i className="bi bi-rulers"></i> {item2.taskStatus} </span>
                              <br/><br/>
    <i className="bi bi-person-circle"></i> {item2.added_by_username}
    </p>
  </div>
  <div className="card-footer text-muted">
  <i className="bi bi-clock"></i> {alterElement(item2)}
</div>
</div><br/></></>);
    }

  }
  


  

  useEffect(() => {
    // getSops();
    getOptionData();
    fetchUserData();
   
  }, [1]);

  return (
    <>
   
      <div className="container-fluid">
      
      

      <div className="row d-flex justify-content-between">
        <div className="col-4" align='left'>
        <mark>
        <i className="bi bi-bezier"></i> All sop
                    categories
                  </mark>
        <div className="input-group input-group-sm">
  <select
    className="form-select border border-dark"
    id="inputGroupSelect04"
    name='sop'
    value={selectSop.sop}
    onChange={changeSop}
    aria-label="Example select with button addon"
  >
    <option selected="">Choose...</option>
    {/* <option value='allsop'>All</option> */}
    {allSopCategory &&
                        allSopCategory?.map((item) => (
                          <option value={item}>{item}</option>
                          ))}
  </select>
  <select
className="form-select form-select-sm border-dark"
aria-label=".form-select-sm example"
value={filterStatus}
onChange={handleFilterChange}
>
<option value="">Active</option>
<option value="Won">Won</option>
<option value="Lost">Lost</option>
<option value="Closed">Closed</option>
</select>
<button className="btn btn-primary" type="button"  data-bs-toggle="collapse" 
      onClick={() => {
 
        getFormData();
        setselectSop({
          sop: "",
        });
      }}
      href="#collapseFilter"
      role="button"
      aria-expanded="false"
      aria-controls="collapseFilter">
 <i className="bi bi-sliders"></i>
  </button>
  <button className="btn btn-dark" type="button" onClick={serachSop}>
  <i className="bi bi-search"></i> Search
  </button>
</div>
<br/>


        </div>
        <div className="col-4" align='center'>
        <br/>
      <div className="btn-group" role="group" aria-label="Basic outlined example">
  <button type="button" className={view=='grid'?'btn btn-dark':'btn btn-outline-dark' } onClick={()=>setView('grid')}>
  <i className="bi bi-grid-3x3-gap-fill"></i>
  </button>
  <button type="button" className={view=='list'?'btn btn-dark':'btn btn-outline-dark' } onClick={()=>setView('list')}>
  <i className="bi bi-list"></i>
  </button>
  <button type="button" className={view=='advance'?'btn btn-dark':'btn btn-outline-dark' } onClick={()=>setView('advance')}>
  <i className="bi bi-list-columns"></i>
  </button>

</div>
      </div>

      
        <div className="col-4" align='center'>
          <br/>
          

<div className="d-grid gap-2 d-md-flex justify-content-md-center">
<div className="dropdown">
  <button
    className="btn btn-dark btn-sm"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="bi bi-plus-lg" /> Add lead
  </button>
  <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
    <li>
      <a className="dropdown-item cursor" onClick={handleShowAddLead}>
      <i className="bi bi-plus-circle-fill"></i> Single
      </a>
    </li>
    <li>
      <a className="dropdown-item cursor"  data-bs-toggle="modal"
    data-bs-target="#uploadlead"> <i className="bi bi-file-earmark-spreadsheet"></i> Multi</a>
    </li>
  </ul>
</div>
{admin == "true"? <button className="btn btn-success btn-sm" data-bs-toggle="modal"
                      data-bs-target="#exampleModal3" type="button">
  <i className="bi bi-file-earmark-spreadsheet"></i> Export
  </button>:null}
 
</div>




<>

  {/* Modal */}
  <div
    className="modal fade"
    id="uploadlead"
    tabIndex={-1}
    aria-labelledby="uploadleadLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
    <ImportLead/>
    </div>
  </div>

  {/* Modal download */}
  <div
                      className="modal fade"
                      id="exampleModal3"
                      tabIndex={-1}
                      aria-labelledby="exampleModal3Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header border-0">
                            <h5 className="modal-title" id="exampleModal3Label">
                              Download Contact Number
                            </h5>
                            <button
                              id="close1"
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="input-group">
                                  Start Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <input
                                    type="date"
                                    className="form-control border-secondary"
                                    placeholder="Start Date"
                                    name="staring_date"
                                    value={downloadContact.staring_date}
                                    onChange={handleChangeDownloadContact}
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  End Date :
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <input
                                    type="date"
                                    className="form-control border-secondary"
                                    placeholder="End Date"
                                    value={downloadContact.ending_date}
                                    onChange={handleChangeDownloadContact}
                                    name="ending_date"
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  Type :
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <select
                                    value={downloadContact.filetype}
                                    onChange={handleChangeDownloadContact}
                                    name="filetype"
                                    className="form-select bg-light text-black border border-dark"
                                    id="inputGroupSelect04"
                                    aria-label="Example select with button addon"
                                  >
                                    <option value="xlsx" selected>
                                      Excel
                                    </option>
                                    <option value="csv">CSV</option>
                                  </select>
                                </div>

                                <br />

                                <button className="btn bg-dark text-white pop" onClick={handleDownload}>
                                  Download
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

</>



        </div>
       
       
        
      </div>
      {/* <>{JSON.stringify(leadfilter)}</> */}

      <>

  <div className="collapse" id="collapseFilter">
    <div className="card border border-dark">
    <div className=" card-body">
      <div className="row">
      <div className="col-4">
      <div className="mb-3">
      <figcaption className="figure-caption">
      Lead type :
</figcaption>
</div>


<div className="row">
<div className="col-6">
<div className="mb-3 ">
  <select
    className="form-select form-select-sm "
    aria-label=".form-select-sm example"
    onChange={changeLeadFilter}
    name='pipeline'
    value={leadfilter.pipeline}
   

  >
    <option selected="">Lead Pipeline</option>
    {allSopCategory &&
                        allSopCategory?.map((item) => (
                          <option value={item}>{item}</option>
                          ))}
  </select>
</div>
</div>
<div className="col-6">
  { leadfilter.pipeline ==''?null:<div className="mb-3">
  <select
    className="form-select form-select-sm"
    aria-label=".form-select-sm example"
    onChange={changeLeadFilter }
    name='pipelineCurrentStage'
    value={leadfilter.pipelineCurrentStage}
  >
    <option selected="">Lead Current Stage</option>
    {selectoptions[leadfilter.pipeline] &&
                                    selectoptions[leadfilter.pipeline]?.map((item) => (
                                      <option value={item}>{item}</option>
                                      ))}
  </select>
</div> }


</div>
</div>


<div className="row">
<div className="col-6"><div className="mb-3">
  <select
    className="form-select form-select-sm"
    aria-label=".form-select-sm example"
    onChange={changeLeadFilter}
    name='lead_status'
    value={leadfilter.lead_status}
  >
    <option selected="">Lead Staus</option>
    <option value="">Active</option>
<option value="Won">Won</option>
<option value="Lost">Lost</option>
<option value="Closed">Closed</option>
  </select>
</div></div>
<div className="col-6">
<div className="mb-3">

<select
  className="form-select form-select-sm"
  aria-label=".form-select-sm example"
  onChange={changeLeadFilter}
    name='priority'
    value={leadfilter.priority}
>
  <option selected="">Lead Priority</option>
  <option value="low">Low</option>
  <option value="medium">Medium</option>
  <option value="high">High</option>
</select>
</div>
</div>
</div>
        










      </div>
      <div className="col-4">
      <figcaption className="figure-caption">
      <div className="mb-3">
      Lead information :
      </div>
</figcaption>

      <div className="row">
      <div className="col-6">
      <div className="mb-3">
  <input
    type="text"
    className="form-control form-control-sm"
    id="exampleFormControlInput1"
    placeholder="Lead Title"
    onChange={changeLeadFilter}
    name='title'
    value={leadfilter.title}
  />
</div>
      </div>
      <div className="col-6">
      <div className="mb-3">

  <select
  className="form-select form-select-sm"
  aria-label=".form-select-sm example"
  onChange={changeLeadFilter}
    name='owner'
    value={leadfilter.ownerId}
>
  <option selected="">Lead Owner</option>
  {feed &&
                                          feed?.map((item) => (
                                            <option
                                              value={item.id}
                                            >
                                              {item.user_name}-{item.role}
                                            </option>
                                          ))}

</select>
</div>
      </div>
      </div>


      <div className="row">
      <div className="col-2">
      <div className="mb-3">
  <input
    type="text"
    className="form-control form-control-sm"
    id="exampleFormControlInput1"
    placeholder="Value"
    onChange={changeLeadFilter}
    name='value'
    value={leadfilter.value}
  />
</div>
      </div>
      <div className="col-5">
      <div className="mb-3">

<input
  type="date"
  className="form-control form-control-sm"
  id="exampleFormControlInput1"
  placeholder="Lead Deadline"
  onChange={changeLeadFilter}
    name='expectedClosingDate'
    value={leadfilter.expectedClosingDate}
/>
</div>

      </div>
      <div className="col-5">
<div className="mb-3">
<input
  type="date"
  className="form-control form-control-sm"
  id="exampleFormControlInput1"
  placeholder="Lead Deadline"
  onChange={changeLeadFilter}
    name='expectedClosingDate'
    value={leadfilter.expectedClosingDate}
/>
</div>
</div>
      </div>

      </div>
      <div className="col-4">
      <figcaption className="figure-caption">
      <div className="mb-3">
      Contact details :
      </div>
</figcaption>
<div className="row">
<div className="col-6">

<div className="mb-3">
  <input
    type="text"
    className="form-control form-control-sm"
    id="exampleFormControlInput1"
    placeholder="Contact person"
    onChange={changeLeadFilter}
    name='contactPerson'
    value={leadfilter.contactPerson}
  />
</div>



</div>
<div className="col-6">

<div className="mb-3">
  <input
    type="text"
    className="form-control form-control-sm"
    id="exampleFormControlInput1"
    placeholder="Organization"
    onChange={changeLeadFilter}
    name='organization'
    value={leadfilter.organization}
  />
</div>

</div>
</div>

<div className="row">
<div className="col-6">
<div className="mb-3">
  <input
    type="text"
    className="form-control form-control-sm"
    id="exampleFormControlInput1"
    placeholder="Phone number"
  />
</div>
</div>
<div className="col-6">
<div className="mb-3">
  <input
    type="text"
    className="form-control form-control-sm"
    id="exampleFormControlInput1"
    placeholder="Email Id"
  />
</div>
</div>
</div>

      </div>
      </div>
      {formFields != ''?
 <figcaption className="figure-caption">
 <div className="mb-3">
 Other options :
 </div>
</figcaption>
:null  }
      <div className="row">
      {formFields?.map((item,index)=>(

<><div className='col-2'><div className="mb-3">



{

item.type =='select'?
<>
<select
  className="form-select form-select-sm cursor"
  id="inputGroupSelect04"
  name={item.name}
  value={leadfilter[item.name]}
onChange={changeLeadFilter}
  aria-label="Example select with button addon"
>
<><option>Select {item.name}</option></>
  {
    item.options?.map((item1,index)=>(
      <><option value={item1}>{item1}</option></>
    ))

  }

                                      
  </select>
 
</>

:<><input
type={item.type}
name={item.name}
class="form-control form-control-sm"
// value={leadfilter.expectedClosingDate}
value={leadfilter[item.name]}
onChange={changeLeadFilter}
placeholder={item.name}
/></>

}


</div></div></>

))}  

      </div>
      <div className="row">
        <div>
        <button type="button" onClick={submitAdvanceFilter} className="btn btn-dark btn-sm">Submit</button>
        </div>
      
      </div>
      </div>
    </div>
    <br/>
  </div>
  
</>
      
      </div>
     {
      listsop != ''?  
      
     <>{
      view == 'grid'?<div className="cell-wrapper border-0 bg-light" >


{/*un mapped leads coloumn */}

{leadsUm != ''?<div className="cell" style={{backgroundColor: '#ff9499'}} droppable onDragOver={(e)=>draggingOver()} onDrop={(e)=>dragDropped()} align='center'>
  <br/>
<h6 ><b>{'Unmapped leads'} </b>{" "}
<OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('This leads are associated with your SOPs but their stages are not present in your sops. Please change the current stage of the lead')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
</h6> 
  {" "}

                         
                         <hr className="light"/>
                         <br/>
                         {leadsUm?.map((item1,index)=>(

  <>
    <div className={'card border-dark mb-3 shadow tiledesign'} draggable  align='left' style={{ maxWidth: "18rem" }}>
    <span className={item1.lead_status=='Won'?'position-absolute top-0 start-50 translate-middle bg-success badge rounded-pill'
    :item1.lead_status=='Lost'?'position-absolute top-0 start-50 translate-middle bg-danger badge rounded-pill'
    :item1.lead_status=='Closed'?'position-absolute top-0 start-50 translate-middle bg-dark badge rounded-pill'
  :'position-absolute top-0 start-50 translate-middle bg-primary badge rounded-pill'
  
  }>
  {item1.lead_status} {item1.lead_status == ''?'Active':null}
</span>
<span className={item1.priority=='low'?'position-absolute top-50 start-0 translate-middle p-2 bg-primary border border-dark rounded-circle'
    :item1.priority=='medium'?'position-absolute top-50 start-0 translate-middle p-2 bg-warning border border-dark rounded-circle'
  :item1.priority=='high'?'position-absolute top-50 start-0 translate-middle p-2 bg-danger border border-dark rounded-circle'
  :null
  
  }>
  <span className="visually-hidden">New alerts</span>
</span>


    <div className="card-body text-dark bg-white rounded">

<div className="row">
<div className="col-10">

<div className="dropdown">
<span className="badge bg-dark"># {item1.TicketId}</span> &nbsp;
  <span className="badge bg-light text-secondary cursor"id={'dropdownMenuLink' + item1.Id}
    data-bs-toggle="dropdown" ><i className="bi bi-pencil-square"></i> 
    {fixstring(' ' + item1.pipelineCurrentStage,'20')}</span> 
  <ul className="dropdown-menu" aria-labelledby={'dropdownMenuLink' + item1.Id}>
    
  {listsop &&
                    listsop?.map((item, index) => (
<>{

item1.pipelineCurrentStage != item.process_catagory?<><li>
<a className="dropdown-item bg-light border-bottom" href="#" onClick={()=>updateOptions(item.process_catagory, 'pipelineCurrentStage' ,item1 )}>



{item.process_catagory} 
</a>
</li></>:null
}
</>

                      ))}
    
   
  </ul>
</div>


<h6 className="card-title"><b> {item1.title==""?<>{fixstring(item1.contactPerson, '23')}</>:<>{fixstring(item1.title, '23')}</>
                        }
                        {returncontact(item1)} </b></h6></div>
<div className="col-2"><i className="bi bi-arrows-angle-expand arrowexpand" 
onClick={()=>handleShowLead(item1, {process_catagory: '', process_desc: '', Instructions: Array(0)})}
></i></div>
</div>

     
  
  
  
      <p className="card-text text-secondary">
  
      <i className="bi bi-building"></i> {fixstring(item1.contactPerson + ' - ' + item1.organization , '26')}
       {/* {item1.contactPerson} -  {item1.organization} */}
    
                                <br/>
  
      
      </p>
      <i className="bi bi-person-circle"></i> {removeEverythingBeforeComma(item1.owner)}
    </div>
    <div className="card-footer text-muted">
    <i className="bi bi-clock"></i>  {convertDateFormat(item1.expectedClosingDate)!=''?convertDateFormat(item1.expectedClosingDate):<>N/A</>} - deadline
  </div>
  </div>
  <br/></>

))}
  </div>:null}



      {listsop &&
                    listsop?.map((item, index) => (<>

<div className="cell" droppable onDragOver={(e)=>draggingOver()} onDrop={(e)=>dragDropped()} align='center'>
  <br/>
<h6 ><b>{item.process_catagory} </b>{" "}
<OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(item.process_desc)}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
</h6> 
  {" "}

                         
                         <hr className="light"/>
                         <br/>


{filterLeads?.map((item1,index)=>(

  <>{item.process_catagory == item1.pipelineCurrentStage?<>
    <div className={'card border-secondary mb-3 shadow tiledesign'} draggable onDragStart={(e)=>dragStarted(item.process_catagory, 'pipelineCurrentStage' ,item1)} align='left' style={{ maxWidth: "18rem" }}>
    <span className={item1.lead_status=='Won'?'position-absolute top-0 start-50 translate-middle bg-success badge rounded-pill'
    :item1.lead_status=='Lost'?'position-absolute top-0 start-50 translate-middle bg-danger badge rounded-pill'
    :item1.lead_status=='Closed'?'position-absolute top-0 start-50 translate-middle bg-dark badge rounded-pill'
  :'position-absolute top-0 start-50 translate-middle bg-primary badge rounded-pill'
  
  }>
  {item1.lead_status} {item1.lead_status == ''?'Active':null}
</span>
<span className={item1.priority=='low'?'position-absolute top-50 start-0 translate-middle p-2 bg-primary border border-dark rounded-circle'
    :item1.priority=='medium'?'position-absolute top-50 start-0 translate-middle p-2 bg-warning border border-dark rounded-circle'
  :item1.priority=='high'?'position-absolute top-50 start-0 translate-middle p-2 bg-danger border border-dark rounded-circle'
  :null
  
  }>
  <span className="visually-hidden">New alerts</span>
</span>


    <div className="card-body text-dark bg-white rounded">

<div className="row">
<div className="col-10">

<div className="dropdown">
<span className="badge bg-dark"># {item1.TicketId}</span> &nbsp;
  <span className="badge bg-light text-secondary cursor"id={'dropdownMenuLink' + item1.Id}
    data-bs-toggle="dropdown" ><i className="bi bi-pencil-square"></i> 
    {fixstring(' ' + item1.pipelineCurrentStage,'20')}</span> 
  <ul className="dropdown-menu" aria-labelledby={'dropdownMenuLink' + item1.Id}>
    
  {listsop &&
                    listsop?.map((item, index) => (
<>{

item1.pipelineCurrentStage != item.process_catagory?<><li>
<a className="dropdown-item bg-light border-bottom" href="#" onClick={()=>updateOptions(item.process_catagory, 'pipelineCurrentStage' ,item1 )}>



{item.process_catagory} 
</a>
</li></>:null
}
</>

                      ))}
    
   
  </ul>
</div>


<h6 className="card-title"><b> {item1.title==""?<>{fixstring(item1.contactPerson, '23')}</>:<>{fixstring(item1.title, '23')}</>
                        }
                        {returncontact(item1)} </b></h6></div>
<div className="col-2"><i className="bi bi-arrows-angle-expand arrowexpand" onClick={()=>handleShowLead(item1, item)}></i></div>
</div>

     
  
  
  
      <p className="card-text text-secondary">
  
      <i className="bi bi-building"></i> {fixstring(item1.contactPerson + ' - ' + item1.organization , '26')}
       {/* {item1.contactPerson} -  {item1.organization} */}
    
                                <br/>
  
      
      </p>
      <i className="bi bi-person-circle"></i> {removeEverythingBeforeComma(item1.owner)}
    </div>
    <div className="card-footer text-muted">
    <i className="bi bi-clock"></i>  {convertDateFormat(item1.expectedClosingDate)!=''?convertDateFormat(item1.expectedClosingDate):<>N/A</>} - deadline
  </div>
  </div>
  <br/></>
  :null }</>

))}


                         
      </div>
  
                      </>
                      ))}







      </div>
      :view == 'advance'?<div className="container-fluid" >
      <div className="overflow-auto">
      <DynamicTable searchobject={searchobject} apitype={apitype} data={filterLeads}/>
         </div>
      
      </div>
      :<><div className="container-fluid">
        <br/>
        
                          <div className="row">
                          <div className="col-3">{selectedRows.length > 0 ? (
                            <div>
                              <div class="input-group input-group-sm mb-2">
  <select class="form-select border border-dark" id="inputGroupSelect04" aria-label="Example select with button addon" value={actiontype} onChange={handleSelectAction}>
    <option selected>Choose action</option>
    {/* <option value='1'>Bulk edit</option> */}
    <option value="2">Copy email list</option>
    <option value="3">Copy contact number list</option>
  </select>
  <button class="btn btn-dark" onClick={handleActionShow} type="button">Submit</button>
</div>
                            </div>
                          ) : null}</div>
                          </div>
                          
                          <Modal show={showAction} onHide={handleActionClose} size="lg">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Bulk select</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
         <ActionModel bulkvalue={selectedRows} actiontype={actiontype} />
          

          



        </Modal.Body>
      </Modal>
        {/* <table className="table table-striped">
  <thead>
    <tr>
    <th scope="col"> <input
              type="checkbox"
              onChange={handleSelectAllChange}
              checked={selectAll}
            /></th>
      <th scope="col">#</th>
      <th scope="col"><i className="bi bi-card-heading"></i> Lead Title</th>
      <th scope="col"><i className="bi bi-person-fill"></i> Contact person</th>
      <th scope="col"><i className="bi bi-building"></i> Organization</th>
      <th scope="col"><i className="bi bi-person-circle"></i> Lead Owner</th>
      <th scope="col"><i className="bi bi-signpost"></i> Progress</th>
      <th scope="col"><i className="bi bi-geo-fill"></i> Status</th>
      <th scope="col"><i className="bi bi-clock"></i> Deadline</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>

  {filterLeads &&
                    filterLeads?.map((item) => (

                      <tr key={item.Id}>
                       <td>
                       <input
                type="checkbox"
                onChange={() => handleCheckboxChange(item)}
                checked={selectedRows.some((selectedRow) => selectedRow.Id === item.Id)}
              />
            </td>
                      <th scope="row"><span className={item.priority=='low'?'text-primary'
    :item.priority=='medium'?'text-warning'
  :item.priority=='high'?'text-danger':null
  
  }><i className="bi bi-circle-fill"></i>
  </span> <span className="badge bg-dark"># {item.TicketId}</span>

  
  </th>
                      <td>{item.title==""?<>{item.contactPerson}</>:<>{item.title}</>
                        }</td>
                      <td>{item.contactPerson}</td>
                      <td>{item.organization}</td>
                      <td>{removeEverythingBeforeComma(item.owner)}</td>
                      <td>
                      <div className="progress border border-dark progress-bar-striped">
  <div
    className="progress-bar progress-bar progress-bar-striped progress-bar-animated bg-dark"
    role="progressbar"
    style={{ width: `${calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)}%` }}
    aria-valuenow={calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)}
    aria-valuemin={0}
    aria-valuemax={100}
  >
    {calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)} %
  </div>
</div>

                      </td>
                      <td>
                      <div className="dropdown">

  <span className="badge bg-light text-dark cursor"id={'dropdownMenuLink' + item.Id}
    data-bs-toggle="dropdown" >
      <i className="bi bi-pencil-square"></i> {item.pipelineCurrentStage}</span> 
  <ul className="dropdown-menu" aria-labelledby={'dropdownMenuLink' + item.Id}>
    
  {listsop &&
                    listsop?.map((item1, index) => (
<>{

item.pipelineCurrentStage != item1.process_catagory?<><li>
<a className="dropdown-item" href="#" onClick={()=>updateOptions(item1.process_catagory, 'pipelineCurrentStage' ,item )}>
{item1.process_catagory}
</a>
</li></>:null
}
</>

                      ))}
    
   
  </ul>
</div>
                      </td>
                      <td>{convertDateFormat(item.expectedClosingDate)}</td>
                      <td><i className="bi bi-arrows-angle-expand arrowexpand" onClick={()=>handleShowLead(item, item.pipeline)}></i></td>
                    </tr>

                    ))}

   


  </tbody>
</table> */}

<div className="ag-theme-quartz mb-2" style={{ height: 1000, width: "100%" }}>
<AgGridReact
        rowData={filterLeads}
        columnDefs={columnDefs}
        rowSelection="multiple"
        enableCellTextSelection={true}
        onRowSelected={({ api }) => setSelectedRows(api.getSelectedRows())}
        suppressRowClickSelection={true}
        suppressClipboardPaste={true}
        headerCheckboxSelection={true}
        pagination={true}
        paginationPageSize={50}
        paginationPageSizeSelector={[10, 25, 50]}
        checkboxSelection={true}
        onGridReady={params => params.api.sizeColumnsToFit()}
      />
    </div>






</div></>

     }</>
     :<div className="container" align='center'>
      <br/><br/>
      <br/>
    <img
                              src={emptyboard}
                              height={350}
                            />
                            <br/>
                            <p>No data to show!</p>
                      
    </div>
     }
<div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
              <div
            className="modal fade"
            id="audioModal"
            tabIndex={-1}
            aria-labelledby="audioModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <p>Upload Call Recording.</p>

                  <input
                    type="file"
                    onChange={updataAudioFile}
                    name="uploaded_file"
                  />

                  <button onClick={uploadAudio}>Submit</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>
            </div>
            <Offcanvas className="leadSize" show={showLead} placement={'bottom'}  onHide={handleCloseLead}>

        <LeadDetails leadData={leadData} leadType={'sopboard'} leadInstruction={leadInstruction} sopCategory={allSopCategory}/>
        
      </Offcanvas>

      <Offcanvas show={showAddLead} onHide={handleCloseAddLead}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><i className="bi bi-plus-circle-fill"></i> Add Lead</Offcanvas.Title>
        </Offcanvas.Header>
        <AddLead leadData={leadData}/>
      </Offcanvas>
      <ToastContainer />
    </>
  );
}

export default Sopboard;
