import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip1 from 'react-bootstrap/Tooltip';
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



export default function Addcategory() {

  const dispatch = useDispatch();


  const Auth = localStorage.getItem("Auth");
  const org = localStorage.getItem("org");

  const [errorMessage, setErrorMessage] = useState("");

  const [inputFields, setInputFields] = useState([{}]);


  const [inputFields2, setInputFields2] = useState([
    {
      heading: "",
      description: "",
    },
  ]);


  let removeInputFields2 = (i) => {
    let rows = [...inputFields2];
    rows.splice(i, 1);
    setInputFields2(rows);
  };


  const tostSuccess = (msg) => {
    var msg = msg;

    toast.success(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tostWarn = (msg) => {
    var msg = msg;

    toast.error(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleChange = (index, evnt) => {
    const { value } = evnt.target;
    const list = [...inputFields];

    list[index] = value;
    setInputFields(list);
  };

  let handleChange2 = (i, e) => {
    let rows = [...inputFields2];
    rows[i][e.target.name] = e.target.value;
    setInputFields2(rows);
  };

  const obj = { organisation_id: org, script_types: inputFields };
  const obj2 = { organisation_id: org, headings: inputFields2 };


  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const addInputField = () => {
    setInputFields([...inputFields, {}]);
  };

  const addInputField2 = () => {
    setInputFields2([
      ...inputFields2,
      {
        heading: "",
        description: "",
      },
    ]);
  };


  const renderTooltip1 = (props) => (
    <Tooltip1 id="button-Tooltip1" {...props}>
      {props}
    </Tooltip1>
  );




  function sendData(e) {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/add_more_scripts_type", obj, { headers })
      .then((data) => {
        if (data.status == "200") {
          setInputFields([{ fullName: " " }]);
          tostSuccess(data.data.massage);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }

  function submitTask() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/add_MoreTaskHeadings", obj2, { headers })
      .then((data) => {
        if (data.status == "200") {
          tostSuccess(data.data.message);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostWarn(err.response.data.message);
      })
      .catch((err) => console.log(err));
  }
  
  return (
    <>

<div>
                      <mark>
                        What calls do you make?
                        &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('You can mention all the call that your team does. Eg: Sales call, Follow up call, Cold call, Payment confirmation call, Facebook lead call, Verification call.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                        </mark>
                        {inputFields?.map((data, index) => {
                          const { fullName } = data;
                          return (
                            <div className="row my-3" key={index}>
                              <div className="col-sm-10">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    onChange={(evnt) =>
                                      handleChange(index, evnt)
                                    }
                                    value={fullName}
                                    name="fullName"
                                    className="form-control form-control-sm"
                                    placeholder="Category"
                                  />
                                </div>
                              </div>

                              <div className="col">
                                {inputFields.length !== 1 ? (
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={removeInputFields}
                                  >
                                    x
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={addInputField}
                            >
                              <i className="bi bi-plus-circle"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={sendData}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <br/>
                      </div>


                      <div>
                        <mark>Add Tasks &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1('You can mention all the tasks that your team does. Eg: Document verification, Send proposal, Kyc check, Collect documents')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></mark>
                        {inputFields2?.map((element, index) => {
                          return (
                            <div className="row my-3" key={index}>
                              <div className="col-sm-10">
                                <div className="form-group text-info">
                                  <input
                                    type="text"
                                    onChange={(e) => handleChange2(index, e)}
                                    value={element.heading || ""}
                                    name="heading"
                                    className="form-control form-control-sm border border-secondary"
                                    placeholder="Task heading"
                                  />
                                </div>
                                <br />
                                <div className="form-group text-info">
                                  <input
                                    type="text"
                                    onChange={(e) => handleChange2(index, e)}
                                    value={element.description || ""}
                                    name="description"
                                    className="form-control form-control-sm border border-secondary"
                                    placeholder="Description"
                                  />
                                </div>
                              </div>

                              <div className="col">
                                {inputFields2.length !== 1 ? (
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={removeInputFields2}
                                  >
                                    x
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={() => addInputField2()}
                            >
                              <i className="bi bi-plus-circle"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => submitTask()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <br/>
                      </div>


      
    </>
  );
}
