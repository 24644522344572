import React, { Component } from "react";
import relaxvideo from "../media/relax.mp4";

const user_name = localStorage.getItem("user_name");

function Card() {


  return (
    <>
      <p className="text-secondary"></p>
      <div className="row">
                  <div className="col-6 col-sm-6">
      <div className="card mb-3 text-white card_design3 shadow rounded-3">
      <div className="card-body">
                        
      
                        <p align="left" className="card-text text-secondary">
                        Partners
                            <h4 align="left" className="title text-light">
                        
                          <b> 500</b>
                          <br/>

                          <span className="small_font text-success"><i className="bi bi-graph-up-arrow"></i> 12.5% month </span>&nbsp;
                          {/* <span className="small_font text-secondary"> Since last month </span> */}
                        </h4>
                            
                      
                        </p>
                      </div>
        </div>
      </div>
      <div className="col-6 col-sm-6">
      <div className="card mb-3 text-white card_design3 shadow rounded-3">
      <div className="card-body">
                        
      
                        <p align="left" className="card-text text-secondary">
                        Distributors
                            <h4 align="left" className="title text-light">
                        
                          <b> 10</b>
                          <br/>

                          <span className="small_font text-success"><i className="bi bi-graph-up-arrow"></i> 10% month </span>&nbsp;
                          {/* <span className="small_font text-secondary"> Since last month </span> */}
                        </h4>
                            
                      
                        </p>
                      </div>
        </div>
      </div>
      </div>
    
      
    </>
  );
}

export default Card;
