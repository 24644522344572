import React, { useState, useEffect } from 'react';
import ReactFlow, { ReactFlowProvider, MiniMap, Controls, Background } from 'react-flow-renderer';



const createNodesAndEdges = (users) => { 
    const nodes = users.map(user => ({
        id: user.id,
        data: { 
            label: (
                <div>
                    <div><b>{user.user_name}</b></div>
                    <div className='text-secondary'>{user.title} ({user.role})</div>
                    
                </div>
            )
        },
        position: { x: 0, y: 0 }, // Initial position, will be adjusted later
        type: 'default'
    }));

    const edges = users
        .filter(user => user.parent_Id)
        .map(user => ({
            id: `e${user.parent_Id}-${user.id}`,
            source: user.parent_Id,
            target: user.id,
            animated: true,
            type: 'smoothstep'
        }));

    return { nodes, edges };
};

const getLayoutedElements = (nodes, edges) => {
    const dagre = require('dagre');
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 172;
    const nodeHeight = 36;
    dagreGraph.setGraph({ rankdir: 'TB' });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = 'top';
        node.sourcePosition = 'bottom';
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };
    });

    return { nodes, edges };
};

const TreeDiagram = ({ data }) => {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    useEffect(() => {
        const { nodes, edges } = createNodesAndEdges(data);
        const layoutedElements = getLayoutedElements(nodes, edges);
        setNodes(layoutedElements.nodes);
        setEdges(layoutedElements.edges);
    }, [data]);

    return (
        <ReactFlowProvider>
            <div style={{ width: '100%', height: '100vh' }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    elementsSelectable={false}
                    fitView
                    fitViewOptions={{ padding: 0.2 }}
                >
                    <MiniMap />
                    <Controls />
                    <Background />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
};

export default TreeDiagram;
