import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import logo from "../media/Sales.gif";
import empty from "../media/undraw_firmware_re_fgdy.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { getProgressBarStatus } from "../redux/slices/homeSlice";
import { update } from 'lodash';

function LearnForm() {

   const dispatch = useDispatch();

    let Org = localStorage.getItem("organisation_name");
    const Auth = localStorage.getItem("Auth");


    const [Sops, setSops] = useState({});

    const [SelectedProcess, setSelectedProcess] = useState([]);

    const [currentProcess, setCurrentProcess] = useState('');

    const [feedSopCategory, setSopCategory] = useState();

    const [NewSopName, setNewSopName] = useState(currentProcess);

    const [NewSopName2, setNewSopName2] = useState('');

    
    const tostMsg = (item) => {
      toast.success(item, {
        position: "bottom-left",
        autoClose: 1000,
      });
    };
  
    const businessTypes = [
      "Customer Support",
      "Staffing/HR companies",
      "Insurance companies",
      "Loan companies",
      "Startups",
      "E-commerce businesses",
      "SaaS businesses",
      "Consulting businesses",
      "B2B businesses",
      "Real estate agents",
      "Gyms",
      "E-learning businesses",
      "Software companies",
      "Creative agencies",
      "Freelancers",
      "Travel agencies",
      "Coaching businesses",
      "Consulting businesses (B2B)",
      "E-learning businesses (B2B)",
      "Web development businesses",
      "Digital marketing businesses",
      "Law firms",
      "Financial services firms",
      "Health and wellness businesses",
      "Other" 
    ];


    const [form, setForm] = useState({
      "Company_Name": Org,
      // "Description": "We are a leading provider of [Primary Products/Services] designed to [Purpose/Benefit], our unique approach to [Unique Selling Proposition] ensures [Customer Benefit/Outcome]. Our mission is to [Mission Statement].",
      "Description":"",
      "Sector": "",
      "Primary_language": "",
      "Use_Type":"",
      "What_is_Product":"",
      "Who_is_Customer":""
  });



    function updataform(e){
      e.preventDefault();
      setForm({...form, [e.target.name] : e.target.value });


    }


    ///// Api call to send data 
 
    function sendLearnData(){
 
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
    
      axios.post('/understand_org',form,{headers}).then(
        (data)=>{
          console.log(data.data);
          toast.success(`Details submitted`, {
            position: "top-center",
            autoClose: 1000,
          });
          sopCreate();

        }
      ).catch(
        (err)=>{
          console.log(err)

        }
      )
    }

    function sopCreate(){

      setSelectedProcess([]);
      setCurrentProcess('');

      toast('🦄 SOP builder initiated!', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
 
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
    
      axios.post('/Suday_SOP_Creator',form,{headers}).then(
        (data)=>{
          console.log(data.data);
          
            setSops(data.data);
        }
      ).catch(
        (err)=>{
          console.log(err)

        }
      )
    }

    function getSops() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get("/get_sop_name", {
          headers,
        })
        .then((data) => {
          if (data.status == "200") {
            setSopCategory(data.data);
          }
       
        })
        .catch((err) => console.log(err));
    }
    
    function setprocess(item){

      setSelectedProcess(Sops[item]);
      setCurrentProcess(item);
      setNewSopName(item)

    }




    function saveSopProcess(item1,item2) {

  
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Auth}`,
  };

  if(item2 == 'add'){
    setNewSopName(item1);
    axios
    .post("/Add_sop_name", {"SopName": [
      NewSopName
    ]}, { headers })
    .then((data) => {
      if (data.status == "200") {

        setNewSopName('');
        getSops();
        setNewSopName('');
      

      }
      
    })
    .catch((err) => console.log(err));

  }

  axios
  .post("/SetUp_the_sop_process", {
    "SopName": item1,
    "process": SelectedProcess
  }
  , { headers })
  .then((data) => {
    if (data.status == "200") {

      tostMsg('Changes saved!');

    }
    
  })
  .catch((err) => console.log(err));
}

    useEffect(()=>{
      getSops();
    },[1]);


  return (
    <div className="container">
        <div className='row'>
        <div className="col-12 col-sm-4"><div className="card shadow bg-gradient bg-light">
  <div className="card-body">
    <div className="row">
      <div className="col-2">  <img src={logo} alt="" width="50" height="36"></img></div>
      <div className="col-10">
      <h4>
          Design with
          <small className="text-primary">  SundayAi</small>
</h4>
      </div>
    </div>
    <p className='text-secondary'>Sunday Ai wants to understand your business!</p>

      
          <div className="row">
          <div className="col-12 col-sm-12">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-building"></i> Company name:
  </label>
  <input type="text" name='Company_Name' value={form.Company_Name} onChange={updataform} class="form-control border border-white inside2" id="exampleFormControlInput1" placeholder="Sales Sunday"></input>


</div>
          </div>
          <div className="col-12 col-sm-12">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-chat-right-text"></i> Describe what you business does:
  </label>
  <textarea
    className="form-control border border-white inside2"
    name='Description'  value={form.Description}
                                    onChange={updataform} 
    id="exampleFormControlTextarea1"
    rows={3}
    defaultValue={""}
  />

</div>
          </div>
          <div className="col-6 col-sm-6">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-briefcase"></i> Sector:
  </label>
  <select className="form-select border border-secondary inside" name='Sector'  value={form.Sector}
                                    onChange={updataform}  aria-label="Default select example">
  <option selected="">Select Sector</option>
  {businessTypes.map((item)=>( <option value={item}>{item}</option>))}
</select>
</div>
          </div>
          <div className="col-6 col-sm-6">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-eyeglasses"></i> Looking for?
  </label>
  <select className="form-select border border-secondary inside" name='Use_Type'  value={form.Use_Type}
                                    onChange={updataform}  aria-label="Default select example">
  <option selected="">Select Options</option>
  <option value="Sales">Sales</option>
  <option value="Operations">Operations</option>
  <option value="Sales and Operations">Sales and Operations</option>
</select>
</div>
          </div>
          <div className="col-12 col-sm-12">
          <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-megaphone"></i> Select customer conversation language:
  </label>
  <select className="form-select border border-secondary inside" name='Primary_language'  value={form.Primary_language}
                                    onChange={updataform}  aria-label="Default select example">
  <option selected="">Select Language</option>
  <option value="English">English</option>
  <option value="Hindi">Hindi</option>
  <option value="Marathi">Marathi</option>
  <option value="Tamil">Tamil</option>
  <option value="Telugu">Telugu</option>
  <option value="Tamil">Tamil</option>
</select>
</div>
          </div>       
          </div>
          


<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">
  <i className="bi bi-box"></i> What is your product?
  </label>
  <textarea
    className="form-control border border-white inside2"
    name='What_is_Product'  value={form.What_is_Product}
                                    onChange={updataform} 
    id="exampleFormControlTextarea1"
    rows={2}
    defaultValue={""}
  />

</div>

<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">
  <i className="bi bi-person-heart"></i> Who is your customer?
  </label>
  <textarea
    className="form-control border border-white inside2"
    name='Who_is_Customer'  value={form.Who_is_Customer}
                                    onChange={updataform} 
    id="exampleFormControlTextarea1"
    rows={2}
    defaultValue={""}
  />

</div>

<button type="button" className="btn btn-dark pop" onClick={sendLearnData}>
<i className="bi bi-arrow-right"></i> Generate 
</button>

  </div>
  
</div><br/></div>
<div className="col-12 col-sm-8">
   {Object.keys(Sops).length === 0?<> <div align='center' className='d-flex justify-content-center align-items-center vh-100'>
  <img src={empty} height={400} />
  </div></>:
  <div>
    
      <div className='row'>
        <div className='col-5'>
          <p><i className="bi bi-chat"></i> I have designed {Object.keys(Sops).length} process based on my understanding about your business. <i className="bi bi-arrow-down"></i></p>
          
          <ol className="list-group">
        {Object.keys(Sops).map((label, index) => (
        <>{
          label == currentProcess?
          <li key={index} onClick={()=>setprocess(label)} className="list-group-item d-flex justify-content-between align-items-start text-white bg-primary border border-dark">
  <div className="ms-2 me-auto" >
    {label}
  </div>
  <i
                            title="View"
                            className="bi bi-arrow-right"
                            onClick={()=>setprocess(label)}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>
  </li>
          :<li key={index} onClick={()=>setprocess(label)} className="list-group-item d-flex justify-content-between align-items-start text-dark bg-white border border-dark">
          <div className="ms-2 me-auto" >
            {label}
          </div>
          <i
                                    title="View"
                                    className="bi bi-arrow-right"
                                    
                                    style={{ marginLeft: "8px", cursor: "pointer" }}
                                  ></i>
          </li>
        }
        
        </>
      ))}
      </ol>
      <br/>
      <button type="button" onClick={sendLearnData} className="btn btn-dark btn-sm pop"><i className="bi bi-arrow-clockwise"></i> Redesign</button>
        </div>
        <div className='col-7'>
   
        {
          currentProcess != ''?<>
          
          <h4 align='center'>{currentProcess}</h4>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
    
    <div className="dropdown">
<button
  className="btn btn-dark border border-dark btn-sm"
  type="button"
  id="dropdownMenu2"
  data-bs-toggle="collapse"
  data-bs-target="#collapseimport"
  aria-expanded="false"
  aria-controls="collapseimport"
>
  <i className="bi bi-cloud-download"></i> Import 
</button>
</div>
<br/>
<div className="collapse" id="collapseimport">


  <div className="card">
  <div className="card-header">Add <b>{currentProcess}</b> process to your sops:</div>
  <div className="card-body" align='left'>


   <p>

   <i className="bi bi-dot"></i>  Overwrite to excisting sop: 

<div className="input-group input-group-sm mb-3">
<select className="form-select border-secondary bg-light" id="inputGroupSelect02"
name="overwrite"
// value={slectedglobalsop.overwrite}
onChange={(e)=>(setNewSopName2(e.target.value))}
>
<option selected="">Choose...</option>
{feedSopCategory &&
                 feedSopCategory?.map((item) => (
                   <><option value={item}>{item}</option></>
                 ))}
</select>

<button
className="btn btn-dark"
type="button"
onClick={()=>saveSopProcess(NewSopName2, update )}
id="button-addon2"
>
Submit
</button>
</div>


   <div align='center'>-or-</div> 

   <i className="bi bi-dot"></i> Create a new sop & import:

   <div className="input-group input-group-sm mb-3">
<input
  type="text"
  className="form-control border-secondary bg-light"
  placeholder="Sop Name"
  aria-label="Recipient's username"
  aria-describedby="button-addon2"
  name="added"
  onChange={(e)=>(setNewSopName(e.target.value))}
/>
<button
  className="btn btn-dark"
  type="button"
  id="button-addon2"
  onClick={()=>saveSopProcess(NewSopName,'add' )}
>
  Submit
</button>
</div>

</p>  
</div>

  </div>
</div>

  </div><br/><ol className="list-group">
          {SelectedProcess &&
                                SelectedProcess?.map((item, index) => (
                                  <> <div className="card tiledesign border-dark shadow"><div class="card-body" align='left'>
                                      <div className="row mb-1"><div className="col-12">
            <span className="badge rounded-pill bg-dark text-light">{index+1}</span> <b>{item.process_catagory}</b> 
              </div>
              <p>{item.process_desc}</p></div>
                                    </div></div><div align='center'><i className="bi bi-arrow-down"></i></div></>
                                  ))}
                                  </ol></>:null
        }
        
 
        </div>

      </div>
    
  </div>  }
 </div>


        </div>
        <ToastContainer />
</div>
  )
}

export default LearnForm