import React, {useState, useEffect} from 'react'
import axios from "axios";
import emptytask from "../media/undraw_floating_re_xtcj.svg";

const Auth = localStorage.getItem("Auth");

function Task(props) {

    const [taskdesc, settaskdesc] = useState('');

    function getTaskDesc(pitchCatData) {
        const headers = {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${Auth}`,
        };
        axios
          .get(
            "/get_set_task_desc?TaskHeading=".concat(pitchCatData),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              settaskdesc(data.data.desc);
            }
    
            // setErrorMessage1(data.data.message);
          })
          .catch((err) => console.log("error", err));
      }


      useEffect(()=>{
        getTaskDesc(props.data.taskname);
      },[])


  return (
    <div>
 {taskdesc!=''?<><mark>
                      <i className="bi bi-file-text-fill"></i> What to do in this task: 
                    </mark><div className="card border-dark">
    <div className="card-header">
    Task description
  </div>
  <div class="card-body bg-dark text-white">
  <blockquote className="blockquote">
    {taskdesc}
    </blockquote>
  </div>
</div><br/><br/></>
    :<><br/>
    <br/><div align="center"><img
    src={emptytask}  height={300}
  /></div><br/><br/></>}

    </div>

  )
}

export default Task