import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import avatar from "../media/147144.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import addsingle from "../media/undraw_personal_file_re_5joy.svg";
import emptycontact from "../media/undraw_treasure_of-9-i.svg";
import EditableTextField from "./editableTextField";
import { useSelector, useDispatch } from "react-redux";
import {
  getScripts,
  getContactNumber,
  getUserData,
  getCallGoals,
  getPar1Latest,
  getPar2Latest,
  getPar3Latest,
  getPar4Latest,
  getPar5Latest,
  getPar6Latest,
  addsuggestions,
  addquestions,
  addNewTask,
  getseachquestions,
  upvote,
  downvote,
  updateCallDetails,
  addNewCall,
  deleteContactToCall,
  findContactDetails,
  getscriptCallEndingInstruction,
  getTasks,
  getProgressBarStatus,
} from "../redux/slices/homeSlice";
import {
  getSourceListData
} from "../redux/slices/dataSlice";
import {addNewContact} from "../redux/slices/dataSlice";
import { Link } from "react-router-dom";
const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");
const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const user_id = localStorage.getItem("token");
const admin = localStorage.getItem("admin");


function Callinfo() {


  const dispatch = useDispatch();
  var url_string = window.location.href;
  var url = new URL(url_string);
  var phoneno = url.searchParams.get("phoneno");

  const [errorMessage2, setErrorMessage2] = useState("");

  const [countrycode, setcountrycode] = useState({
    "countries": [
      {
        "code": "91",
        "name": "India"
      },
      {
        "code": "7 840",
        "name": "Abkhazia"
      },
      {
        "code": "93",
        "name": "Afghanistan"
      },
      {
        "code": "355",
        "name": "Albania"
      },
      {
        "code": "213",
        "name": "Algeria"
      },
      {
        "code": "1 684",
        "name": "American Samoa"
      },
      {
        "code": "376",
        "name": "Andorra"
      },
      {
        "code": "244",
        "name": "Angola"
      },
      {
        "code": "1 264",
        "name": "Anguilla"
      },
      {
        "code": "1 268",
        "name": "Antigua and Barbuda"
      },
      {
        "code": "54",
        "name": "Argentina"
      },
      {
        "code": "374",
        "name": "Armenia"
      },
      {
        "code": "297",
        "name": "Aruba"
      },
      {
        "code": "247",
        "name": "Ascension"
      },
      {
        "code": "61",
        "name": "Australia"
      },
      {
        "code": "672",
        "name": "Australian External Territories"
      },
      {
        "code": "43",
        "name": "Austria"
      },
      {
        "code": "994",
        "name": "Azerbaijan"
      },
      {
        "code": "1 242",
        "name": "Bahamas"
      },
      {
        "code": "973",
        "name": "Bahrain"
      },
      {
        "code": "880",
        "name": "Bangladesh"
      },
      {
        "code": "1 246",
        "name": "Barbados"
      },
      {
        "code": "1 268",
        "name": "Barbuda"
      },
      {
        "code": "375",
        "name": "Belarus"
      },
      {
        "code": "32",
        "name": "Belgium"
      },
      {
        "code": "501",
        "name": "Belize"
      },
      {
        "code": "229",
        "name": "Benin"
      },
      {
        "code": "1 441",
        "name": "Bermuda"
      },
      {
        "code": "975",
        "name": "Bhutan"
      },
      {
        "code": "591",
        "name": "Bolivia"
      },
      {
        "code": "387",
        "name": "Bosnia and Herzegovina"
      },
      {
        "code": "267",
        "name": "Botswana"
      },
      {
        "code": "55",
        "name": "Brazil"
      },
      {
        "code": "246",
        "name": "British Indian Ocean Territory"
      },
      {
        "code": "1 284",
        "name": "British Virgin Islands"
      },
      {
        "code": "673",
        "name": "Brunei"
      },
      {
        "code": "359",
        "name": "Bulgaria"
      },
      {
        "code": "226",
        "name": "Burkina Faso"
      },
      {
        "code": "257",
        "name": "Burundi"
      },
      {
        "code": "855",
        "name": "Cambodia"
      },
      {
        "code": "237",
        "name": "Cameroon"
      },
      {
        "code": "1",
        "name": "Canada"
      },
      {
        "code": "238",
        "name": "Cape Verde"
      },
      {
        "code": " 345",
        "name": "Cayman Islands"
      },
      {
        "code": "236",
        "name": "Central African Republic"
      },
      {
        "code": "235",
        "name": "Chad"
      },
      {
        "code": "56",
        "name": "Chile"
      },
      {
        "code": "86",
        "name": "China"
      },
      {
        "code": "61",
        "name": "Christmas Island"
      },
      {
        "code": "61",
        "name": "Cocos-Keeling Islands"
      },
      {
        "code": "57",
        "name": "Colombia"
      },
      {
        "code": "269",
        "name": "Comoros"
      },
      {
        "code": "242",
        "name": "Congo"
      },
      {
        "code": "243",
        "name": "Congo, Dem. Rep. of (Zaire)"
      },
      {
        "code": "682",
        "name": "Cook Islands"
      },
      {
        "code": "506",
        "name": "Costa Rica"
      },
      {
        "code": "385",
        "name": "Croatia"
      },
      {
        "code": "53",
        "name": "Cuba"
      },
      {
        "code": "599",
        "name": "Curacao"
      },
      {
        "code": "537",
        "name": "Cyprus"
      },
      {
        "code": "420",
        "name": "Czech Republic"
      },
      {
        "code": "45",
        "name": "Denmark"
      },
      {
        "code": "246",
        "name": "Diego Garcia"
      },
      {
        "code": "253",
        "name": "Djibouti"
      },
      {
        "code": "1 767",
        "name": "Dominica"
      },
      {
        "code": "1 809",
        "name": "Dominican Republic"
      },
      {
        "code": "670",
        "name": "East Timor"
      },
      {
        "code": "56",
        "name": "Easter Island"
      },
      {
        "code": "593",
        "name": "Ecuador"
      },
      {
        "code": "20",
        "name": "Egypt"
      },
      {
        "code": "503",
        "name": "El Salvador"
      },
      {
        "code": "240",
        "name": "Equatorial Guinea"
      },
      {
        "code": "291",
        "name": "Eritrea"
      },
      {
        "code": "372",
        "name": "Estonia"
      },
      {
        "code": "251",
        "name": "Ethiopia"
      },
      {
        "code": "500",
        "name": "Falkland Islands"
      },
      {
        "code": "298",
        "name": "Faroe Islands"
      },
      {
        "code": "679",
        "name": "Fiji"
      },
      {
        "code": "358",
        "name": "Finland"
      },
      {
        "code": "33",
        "name": "France"
      },
      {
        "code": "596",
        "name": "French Antilles"
      },
      {
        "code": "594",
        "name": "French Guiana"
      },
      {
        "code": "689",
        "name": "French Polynesia"
      },
      {
        "code": "241",
        "name": "Gabon"
      },
      {
        "code": "220",
        "name": "Gambia"
      },
      {
        "code": "995",
        "name": "Georgia"
      },
      {
        "code": "49",
        "name": "Germany"
      },
      {
        "code": "233",
        "name": "Ghana"
      },
      {
        "code": "350",
        "name": "Gibraltar"
      },
      {
        "code": "30",
        "name": "Greece"
      },
      {
        "code": "299",
        "name": "Greenland"
      },
      {
        "code": "1 473",
        "name": "Grenada"
      },
      {
        "code": "590",
        "name": "Guadeloupe"
      },
      {
        "code": "1 671",
        "name": "Guam"
      },
      {
        "code": "502",
        "name": "Guatemala"
      },
      {
        "code": "224",
        "name": "Guinea"
      },
      {
        "code": "245",
        "name": "Guinea-Bissau"
      },
      {
        "code": "595",
        "name": "Guyana"
      },
      {
        "code": "509",
        "name": "Haiti"
      },
      {
        "code": "504",
        "name": "Honduras"
      },
      {
        "code": "852",
        "name": "Hong Kong SAR China"
      },
      {
        "code": "36",
        "name": "Hungary"
      },
      {
        "code": "354",
        "name": "Iceland"
      },
      {
        "code": "91",
        "name": "India"
      },
      {
        "code": "62",
        "name": "Indonesia"
      },
      {
        "code": "98",
        "name": "Iran"
      },
      {
        "code": "964",
        "name": "Iraq"
      },
      {
        "code": "353",
        "name": "Ireland"
      },
      {
        "code": "972",
        "name": "Israel"
      },
      {
        "code": "39",
        "name": "Italy"
      },
      {
        "code": "225",
        "name": "Ivory Coast"
      },
      {
        "code": "1 876",
        "name": "Jamaica"
      },
      {
        "code": "81",
        "name": "Japan"
      },
      {
        "code": "962",
        "name": "Jordan"
      },
      {
        "code": "7 7",
        "name": "Kazakhstan"
      },
      {
        "code": "254",
        "name": "Kenya"
      },
      {
        "code": "686",
        "name": "Kiribati"
      },
      {
        "code": "965",
        "name": "Kuwait"
      },
      {
        "code": "996",
        "name": "Kyrgyzstan"
      },
      {
        "code": "856",
        "name": "Laos"
      },
      {
        "code": "371",
        "name": "Latvia"
      },
      {
        "code": "961",
        "name": "Lebanon"
      },
      {
        "code": "266",
        "name": "Lesotho"
      },
      {
        "code": "231",
        "name": "Liberia"
      },
      {
        "code": "218",
        "name": "Libya"
      },
      {
        "code": "423",
        "name": "Liechtenstein"
      },
      {
        "code": "370",
        "name": "Lithuania"
      },
      {
        "code": "352",
        "name": "Luxembourg"
      },
      {
        "code": "853",
        "name": "Macau SAR China"
      },
      {
        "code": "389",
        "name": "Macedonia"
      },
      {
        "code": "261",
        "name": "Madagascar"
      },
      {
        "code": "265",
        "name": "Malawi"
      },
      {
        "code": "60",
        "name": "Malaysia"
      },
      {
        "code": "960",
        "name": "Maldives"
      },
      {
        "code": "223",
        "name": "Mali"
      },
      {
        "code": "356",
        "name": "Malta"
      },
      {
        "code": "692",
        "name": "Marshall Islands"
      },
      {
        "code": "596",
        "name": "Martinique"
      },
      {
        "code": "222",
        "name": "Mauritania"
      },
      {
        "code": "230",
        "name": "Mauritius"
      },
      {
        "code": "262",
        "name": "Mayotte"
      },
      {
        "code": "52",
        "name": "Mexico"
      },
      {
        "code": "691",
        "name": "Micronesia"
      },
      {
        "code": "1 808",
        "name": "Midway Island"
      },
      {
        "code": "373",
        "name": "Moldova"
      },
      {
        "code": "377",
        "name": "Monaco"
      },
      {
        "code": "976",
        "name": "Mongolia"
      },
      {
        "code": "382",
        "name": "Montenegro"
      },
      {
        "code": "1664",
        "name": "Montserrat"
      },
      {
        "code": "212",
        "name": "Morocco"
      },
      {
        "code": "95",
        "name": "Myanmar"
      },
      {
        "code": "264",
        "name": "Namibia"
      },
      {
        "code": "674",
        "name": "Nauru"
      },
      {
        "code": "977",
        "name": "Nepal"
      },
      {
        "code": "31",
        "name": "Netherlands"
      },
      {
        "code": "599",
        "name": "Netherlands Antilles"
      },
      {
        "code": "1 869",
        "name": "Nevis"
      },
      {
        "code": "687",
        "name": "New Caledonia"
      },
      {
        "code": "64",
        "name": "New Zealand"
      },
      {
        "code": "505",
        "name": "Nicaragua"
      },
      {
        "code": "227",
        "name": "Niger"
      },
      {
        "code": "234",
        "name": "Nigeria"
      },
      {
        "code": "683",
        "name": "Niue"
      },
      {
        "code": "672",
        "name": "Norfolk Island"
      },
      {
        "code": "850",
        "name": "North Korea"
      },
      {
        "code": "1 670",
        "name": "Northern Mariana Islands"
      },
      {
        "code": "47",
        "name": "Norway"
      },
      {
        "code": "968",
        "name": "Oman"
      },
      {
        "code": "92",
        "name": "Pakistan"
      },
      {
        "code": "680",
        "name": "Palau"
      },
      {
        "code": "970",
        "name": "Palestinian Territory"
      },
      {
        "code": "507",
        "name": "Panama"
      },
      {
        "code": "675",
        "name": "Papua New Guinea"
      },
      {
        "code": "595",
        "name": "Paraguay"
      },
      {
        "code": "51",
        "name": "Peru"
      },
      {
        "code": "63",
        "name": "Philippines"
      },
      {
        "code": "48",
        "name": "Poland"
      },
      {
        "code": "351",
        "name": "Portugal"
      },
      {
        "code": "1 787",
        "name": "Puerto Rico"
      },
      {
        "code": "974",
        "name": "Qatar"
      },
      {
        "code": "262",
        "name": "Reunion"
      },
      {
        "code": "40",
        "name": "Romania"
      },
      {
        "code": "7",
        "name": "Russia"
      },
      {
        "code": "250",
        "name": "Rwanda"
      },
      {
        "code": "685",
        "name": "Samoa"
      },
      {
        "code": "378",
        "name": "San Marino"
      },
      {
        "code": "966",
        "name": "Saudi Arabia"
      },
      {
        "code": "221",
        "name": "Senegal"
      },
      {
        "code": "381",
        "name": "Serbia"
      },
      {
        "code": "248",
        "name": "Seychelles"
      },
      {
        "code": "232",
        "name": "Sierra Leone"
      },
      {
        "code": "65",
        "name": "Singapore"
      },
      {
        "code": "421",
        "name": "Slovakia"
      },
      {
        "code": "386",
        "name": "Slovenia"
      },
      {
        "code": "677",
        "name": "Solomon Islands"
      },
      {
        "code": "27",
        "name": "South Africa"
      },
      {
        "code": "500",
        "name": "South Georgia and the South Sandwich Islands"
      },
      {
        "code": "82",
        "name": "South Korea"
      },
      {
        "code": "34",
        "name": "Spain"
      },
      {
        "code": "94",
        "name": "Sri Lanka"
      },
      {
        "code": "249",
        "name": "Sudan"
      },
      {
        "code": "597",
        "name": "Suriname"
      },
      {
        "code": "268",
        "name": "Swaziland"
      },
      {
        "code": "46",
        "name": "Sweden"
      },
      {
        "code": "41",
        "name": "Switzerland"
      },
      {
        "code": "963",
        "name": "Syria"
      },
      {
        "code": "886",
        "name": "Taiwan"
      },
      {
        "code": "992",
        "name": "Tajikistan"
      },
      {
        "code": "255",
        "name": "Tanzania"
      },
      {
        "code": "66",
        "name": "Thailand"
      },
      {
        "code": "670",
        "name": "Timor Leste"
      },
      {
        "code": "228",
        "name": "Togo"
      },
      {
        "code": "690",
        "name": "Tokelau"
      },
      {
        "code": "676",
        "name": "Tonga"
      },
      {
        "code": "1 868",
        "name": "Trinidad and Tobago"
      },
      {
        "code": "216",
        "name": "Tunisia"
      },
      {
        "code": "90",
        "name": "Turkey"
      },
      {
        "code": "993",
        "name": "Turkmenistan"
      },
      {
        "code": "1 649",
        "name": "Turks and Caicos Islands"
      },
      {
        "code": "688",
        "name": "Tuvalu"
      },
      {
        "code": "1 340",
        "name": "U.S. Virgin Islands"
      },
      {
        "code": "256",
        "name": "Uganda"
      },
      {
        "code": "380",
        "name": "Ukraine"
      },
      {
        "code": "971",
        "name": "United Arab Emirates"
      },
      {
        "code": "44",
        "name": "United Kingdom"
      },
      {
        "code": "1",
        "name": "United States"
      },
      {
        "code": "598",
        "name": "Uruguay"
      },
      {
        "code": "998",
        "name": "Uzbekistan"
      },
      {
        "code": "678",
        "name": "Vanuatu"
      },
      {
        "code": "58",
        "name": "Venezuela"
      },
      {
        "code": "84",
        "name": "Vietnam"
      },
      {
        "code": "1 808",
        "name": "Wake Island"
      },
      {
        "code": "681",
        "name": "Wallis and Futuna"
      },
      {
        "code": "967",
        "name": "Yemen"
      },
      {
        "code": "260",
        "name": "Zambia"
      },
      {
        "code": "255",
        "name": "Zanzibar"
      },
      {
        "code": "263",
        "name": "Zimbabwe"
      }
    ]
  }
  );
  

  const [Name, setName] = useState();
  const [ContactNo, setContactNo] = useState();
  const [EmailId, setEmailId] = useState();
  const [RecentCall, setRecentCall] = useState();
  const [FollowUp, setFollowUp] = useState();
  const [History, setHistory] = useState();
  const [CallHistory, setCallHistory] = useState();
  const [TaskHistory, setTaskHistory] = useState();
  const [livecat, updatelivecat] = useState("");
  const [feedOrgTask, setOrgTask] = useState();
  const [feed, setFeed] = useState();
  const [feedOrgCategory, setOrgCategory] = useState();
  const [callData, setCallData] = useState({
    added_by_user: user_id,
    added_by_username: user_name,
    script_category: "",
    customer_name: "",
    contact_number: phoneno,
    email_address: "",
    Contact_Source: "",
    call_brief: "",
    call_status: "",
    description: "",
    call_again_date: "",
    next_call_user: "",
    next_call_script_category: "",
    organisation_id: org,
    contact_search_at: "",
    call_started_at: "",
    call_ended_at: "",
    clint_mood: "",
  });

  const [addCallData, setaddCallData] = useState({
    organisation_id: org,
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: "",
    contact_number: "",
    email_address: "",
    description: "",
    next_call_user: "",
    Contact_Source: "",
    next_call_script_category: "",
    call_again_date: "",
  });

  console.log(addCallData);

  ///////////////////////////////////////////////
  const [detailsCallCategory, setdetailsCallCategory] = useState();
  const [detailsDescription, setdetailsDescription] = useState();
  const [detailsCallTime, setdetailsCallTime] = useState();
  const [detailsCallBrief, setdetailsCallBrief] = useState();
  const [detailsCallStatus, setdetailsCallStatus] = useState();
  const [ContactStatus, setContactStatus] = useState();
  console.log(ContactStatus);
  const [detailsMood, setdetailsMood] = useState();
  const [detailsDuration, setdetailsDuration] = useState();
  const [detailsName, setdetailsName] = useState("n/a");
  const [detailsEmailId, setdetailsEmailId] = useState("n/a");
  const [detailsRecentCall, setdetailsRecentCall] = useState("n/a");
  const [detailsFollowUp, setdetailsFollowUp] = useState("n/a");
  const [followUpScript, setFollowUpScript] = useState("");
  const [followUpnext_call_username, setFollowUpnext_call_username] =
    useState("");

  const [detailsCallBy, setdetailsCallBy] = useState("n/a");
  const [alreadycustomer, setalreadycustomer] = useState("wait");

  const [personsearchresult, setpersonsearchresult ] = useState([]);
   const [details, setdetails] = useState(true);

   const [filterText, setFilterText] = useState('');

  const [taskData, setTaskData] = useState({
    added_by_user: user_id,
    added_by_username: user_name,
    taskHeading: "",
    contact_number: callData.contact_number,
    email_address: callData.email_address,
    customer_name: callData.customer_name,
    Contact_Source: callData.Contact_Source,
    description: "",
    lastDate: "",
    assineTO_userName: "",
    assineTO_userID: "",
    organisation_id: org,
    
  });

  const [newcontact, setnewcontact] = useState({
    customer_name: "",
    contact_number: "",
    email_address: "",
    contact_Source: ""
  });

  const [orgtags,setorgTags] = useState([]);
  const [newtag,setnewTag] = useState({
    tagname : ''
  });


  const [ProspectType, setProspectType] = useState({
    type: '',
  });

  const [searchtagscontact, setsearchtagscontact ] = useState();

  const [sourceList, setSourceList] = useState([]);

  const handleUpdate = (data, value, keynamevalue) => {
    callData.contact_number = ContactNo;
    callData.email_address = EmailId;
    callData.customer_name = Name;
    let finaldata = { ...callData, [keynamevalue]: value };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios.post("/Update_ClintDetails", finaldata, { headers }).then((data) => {
      if (data.status == "200") {
      }
    });
  };
  


  console.log(personsearchresult);
  

  function changeCallData(e) {
    setCallData({ ...callData, [e.target.name]: e.target.value });
  }

  function changeAddCallData(e) {
    setaddCallData({ ...addCallData, [e.target.name]: e.target.value });
  }

  function changeTaskData(e) {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
  }

  function changeProspect(e) {
    setProspectType({ ...ProspectType, [e.target.name]: e.target.value });

  }

  const getSourceList = () => {
    dispatch(getSourceListData({ access_token: Auth })).then((res) => {
      if (res.payload.status == "200") {
        setSourceList(res.payload.data);
      }
    });
  };

  function getScript() {
    dispatch(getScripts({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setOrgCategory(res.payload.data.scripts_types);
      }
    });
  }

  function fetchUserData() {
    dispatch(getUserData({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setFeed(res.payload.data);
      }
    });
  }

  function sendTask() {
    dispatch(addNewTask({ access_token, data: taskData }));
  }


  function changenewtag(e) {
    setnewTag({ ...newtag, [e.target.name]: e.target.value });
  }

  function changenewcontact(e) {
    setnewcontact({ ...newcontact, [e.target.name]: e.target.value });
  }

  

  const filteredList = personsearchresult.filter((item) => {
    // Filter logic based on the filterText
    return (
      item.customer_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.contact_number.includes(filterText)
    );
  });

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  
  function masknumber(item){

    if( details == true){
     return item;
   
    }else{
     return item.slice(0, -3).concat('*** ');
    }
     } 

  function searchContact() {
    const headers = {
      "Content-Type": "text/plain",
      Authentication: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/find_contact?contact_number=".concat(
          phoneno,
          "&organisation_id=",
          org
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setName(data.data.customer_name);
          setContactNo(data.data.contact_number);
          setEmailId(data.data.email_address);
          setRecentCall(data.data.next_call);
          setFollowUp(data.data.recent_Call);
          setHistory(data.data.call_history);
          setContactStatus(data.data.contact_current_status);
          setaddCallData({
            organisation_id: org,
            added_by_user: user_id,
            added_by_username: user_name,
            customer_name: data.data.customer_name,
            contact_number: data.data.contact_number,
            email_address: data.data.email_address,
            description: "",
            next_call_user: "",
            Contact_Source: "",
            next_call_script_category: "",
            call_again_date: "",
          })
          setTaskData({
            added_by_user: user_id,
            added_by_username: user_name,
            taskHeading: "",
            contact_number: data.data.contact_number,
            email_address: data.data.email_address,
            customer_name: data.data.customer_name,
            description: "",
            lastDate: "",
            assineTO_userName: "",
            assineTO_userID: "",
            Contact_Source: "",
            organisation_id: org,
          });

          getObjectByContactNumber(phoneno, filteredList);

        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }

  function contactHistory() {
    const headers = {
      "Content-Type": "text/plain",
      Authentication: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/contact_history?contact_number=".concat(
          phoneno,
          "&organisation_id=",
          org
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setCallHistory(data.data.call_history);

        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }

  function gettaskHistory() {
    const headers = {
      "Content-Type": "text/plain",
      Authentication: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_contact_taskHistory?contact_number=".concat(
          phoneno,
          "&organisationID=",
          org
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setTaskHistory(data.data);

        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }

  function changeClientStatus() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .put(`/change_contact_status?contact_number=${phoneno}`, {}, { headers })
      .then((data) => {
        if (data.status == "200") {
          SearchHome();        
          searchContact();
        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }

    function fetchOrgData() {
  
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_organisation?organisation_id=".concat(org), { headers })
      .then((data) => {
        if (data.status == "200") {
          setdetails(data.data[0].Numbermassing);
        }
      })
      .catch((err) => console.log(err));
  }

  function gettags() {
  
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_My_Tags", { headers })
      .then((data) => {
        if (data.status == "200") {
          setorgTags(data.data.tag_list);
        }
      })
      .catch((err) => console.log(err));
  }


  function addtags(item) {

     let request;

    if(item != ''){

      request =  {
        "contact_number": phoneno,
        "tag_name": item
      }

    }else{

      request =  {
        "contact_number": phoneno,
        "tag_name": newtag.tagname
      }
    }

    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/Add_contact_tag', request, { headers })
      .then((data) => {
        if (data.status == "200") {

          setnewTag({
            type: '',
          });
          gettags();
          changePhoneno(phoneno);
         
        }
        
      })
      .catch((err) => console.log(err));
    
    
  }

  function getObjectByContactNumber(contactNumber, dataArray) {
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i].contact_number === contactNumber) {
          return dataArray[i].tags;
        }
    }
    return null; // Return null if no matching object is found
}




  function alterElement(item) {
    var cb = item;
    
    if(item == '' || item == null){
  
      cb = '';
  
  
    }else{
  
      cb = cb.replace("T", " ");
    var month = cb.substring(5, 7);
    var day = cb.substring(8, 10);
    var time = cb.substring(11, 16);  
    cb = time.concat(' ', day,'/', month )
    
  }
  
    
    return cb;
  }

  function changeElement(item) {
    if (item.call_brief == "Interested") return <b>{item.call_brief}</b>;
    return item.call_brief;
  }
  
  function alterCallBack(item) {
    var cb = item;
    
    if(item == '' || item == null){
  
      cb = '';
  
  
    }else{
  
      cb = cb.replace("T", " ");
    var month = cb.substring(5, 7);
    var day = cb.substring(8, 10);
    var time = cb.substring(11, 16);  
    cb = time.concat(' ', day,'/', month )
    
  }
  
    
    return cb;
  }

  function prospectStatus(item) {
    const prospect_status = item.isActive;

    if (prospect_status == false) {
      return (
        <i
          className="i bi-toggle2-off text-secondary"
          style={{ cursor: "pointer" }}
          // onClick={() => changeStatus(item.script_type_name)}
        ></i>
      );
    } else if (prospect_status == true) {
      return (
        <i
          className="i bi-toggle2-on"
          style={{ cursor: "pointer" }}
          // onClick={() => changeStatus(item.script_type_name)}
        ></i>
      );
    }
  }

  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  }

  function getfrequency() {
    var TimeStampDate = new Date(callData.contact_search_at);
    var LastCallDate = new Date(detailsCallTime);

    // To calculate the time difference of two dates
    var Difference_In_Time = TimeStampDate.getTime() - LastCallDate.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    var Difference_In_Hour = Difference_In_Days * 24;

    var Difference_In_Min = Difference_In_Days * 24 * 60;

    var DD = Math.trunc(Difference_In_Days);
    var DH = Math.trunc(Difference_In_Hour);
    var DM = Difference_In_Min - Math.trunc(Difference_In_Hour) * 60;
    var DM = Math.trunc(DM);

    let customermood;

    if (detailsMood == "intense") {
      customermood = '<i className="bi bi-emoji-angry" title="Intense"></i>';
    } else if (detailsMood == "unhappy") {
      customermood = '<i className="bi bi-emoji-frown" title="Unhappy">';
    } else if (detailsMood == "normal") {
      customermood = '<i className="bi bi-emoji-smile" title="Normal"></i>';
    } else if (detailsMood == "happy") {
      customermood = '<i className="bi bi-emoji-laughing" title="Happy"></i>>';
    }


    if (DD == 0) {
      if (DM == 0) {
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <h5>
                  <kbd>
                    <b>High Frequncy</b>
                  </kbd>
                </h5>
              </div>
              <div className="col-6">
                <div className="progress border border-dark" style={{ height: "15px" }}>
                  <div
                    className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="text-secondary">
                  <small>
                    {" "}
                    Last Call: <b className="text-dark">{DH}</b> hrs ago{" "}
                  </small>
                </p>
              </div>
            </div>
          </div>
        );

        let temp =
          "The last call was made" +
          DH +
          "ago. This will be annoying if the lead is not expecting a call.";

        
      } else if (DH == 0) {
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <h5>
                  <kbd>
                    <b>High Frequncy</b>
                  </kbd>
                </h5>
              </div>
              <div className="col-6">
                <div className="progress border border-dark" style={{ height: "15px" }}>
                  <div
                    className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="text-secondary">
                  <small>
                    Last Call: <b className="text-dark">{DM}</b> min ago
                  </small>
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <h5>
                  <kbd>
                    <b className="">High Frequncy</b>
                  </kbd>
                </h5>
              </div>
              <div className="col-6">
                <div className="progress border border-dark" style={{ height: "15px" }}>
                  <div
                    className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="text-secondary">
                  <small>
                    Last Call: <b className="text-dark">{DH}</b> hrs{" "}
                    <b className="text-dark">{DM}</b> min ago
                  </small>
                </p>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (DD <= 3 && DD >= 0) {
        return (
          <div className="row">
            <div className="col-6">
              <h5>
                <kbd>
                  <b>Medium Frequncy</b>
                </kbd>
              </h5>
            </div>
            <div className="col-6">
              <div className="progress border border-dark" style={{ height: "15px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                  role="progressbar"
                  style={{ width: "70%" }}
                  aria-valuenow={70}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <p className="text-secondary">
                <small>
                  {" "}
                  Last Call: <b className="text-dark">{DD}</b> days ago
                </small>
              </p>
            </div>
          </div>
        );
      } else if (DD >= 3 && DD <= 7) {
        return (
          <div className="row">
            <div className="col-6">
              <h5>
                <kbd>
                  <b>Less Frequncy</b>
                </kbd>
              </h5>
            </div>
            <div className="col-6">
              <div className="progress border border-dark" style={{ height: "15px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-primary progress-bar-animated"
                  role="progressbar"
                  style={{ width: "50%" }}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <p className="text-secondary">
                <small>
                  Last Call: <b className="text-dark">{DD}</b> days ago
                </small>
              </p>
            </div>
          </div>
        );
      } else if (DD > 7) {
        return (
          <div className="row">
            <div className="col-6">
              <h5>
                <kbd>
                  <b>Low Frequncy</b>
                </kbd>
              </h5>
            </div>
            <div className="col-6">
              <div className="progress border border-dark" style={{ height: "15px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                  role="progressbar"
                  style={{ width: "25%" }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>

              <p className="text-secondary">
                <small>
                  {" "}
                  Last Call: <b className="text-dark">{DD}</b> days ago
                </small>
              </p>
            </div>
          </div>
        );
      }
    }
  }
 

  function setStartDate(){
    const d = new Date();
    let currentTime = d.toISOString();
    callData.contact_search_at = currentTime; 
    callData.call_started_at = currentTime; 
  }

  function morebtn() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read More";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read Less";
      moreText.style.display = "inline";
    }
  }

  function getTask() {
    dispatch(
      getTasks({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setOrgTask(res.payload.data);
      }
    });
  }

  function sendToCallData(e) {
    dispatch(
      addNewContact({
        access_token,
        data: addCallData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setaddCallData({
          organisation_id: org,
          added_by_user: user_id,
          added_by_username: user_name,
          customer_name: "",
          contact_number: "",
          email_address: "",
          description: "",
          Contact_Source: "",
          next_call_user: "",
          next_call_script_category: "",
          call_again_date: "",
        });
        document.getElementById("close").click();
      }
    });
  }

  function sendContactData() {
    const d = new Date();
    let currentTime = d.toISOString();
    callData.call_ended_at = currentTime;

    let endpoint;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    if (alreadycustomer == "true") {
      endpoint = "/update_call_details/";
    } else if (alreadycustomer == "false") {
      endpoint = "/add_new_call/";
    }

    axios
      .post(endpoint, callData, { headers })
      .then((data) => {
        if (data.status == "200") {
          dispatch(getProgressBarStatus({ access_token: Auth }));
         
      

          if (taskData.taskHeading != "" || taskData.taskHeading != null) {
            sendTask();
          }

         

          setCallData({
            added_by_user: user_id,
            added_by_username: user_name,
            script_category: "",
            customer_name: "",
            contact_number: phoneno,
            email_address: "",
            Contact_Source: "",
            call_brief: "",
            call_status: "",
            description: "",
            call_again_date: "",
            next_call_user: "",
            next_call_script_category: "",
            organisation_id: org,
            call_type: "",
            contact_search_at: "",
            call_started_at: "",
            call_ended_at: "",
          });

          setTaskData({
            added_by_user: user_id,
            added_by_username: user_name,
            taskHeading: "",
            contact_number: phoneno,
            email_address: "",
            customer_name: "",
            description: "",
            lastDate: "",
            assineTO_userName: "",
            assineTO_userID: "",
            Contact_Source: "",
            organisation_id: org,
          });

          
        }
        
      })
      .catch((err) => console.log(err));
  }


  function addNewProspect() {
 

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };


    axios
      .post('/Add_new_coustomer_clint', newcontact, { headers })
      .then((data) => {
        if (data.status == "200") {
          SearchHome();
          setnewcontact({
            customer_name: "",
            contact_number: "",
            email_address: "",
            contact_Source: ""
          });

          tostSuccess('New contact added!');
        }
        
      })
      .catch((err) => console.log(err));
  }


 

  function getCallDetails() {
    dispatch(
      findContactDetails({
        access_token,
        org,
        contact_number: phoneno,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setdetailsCallCategory(res.payload.data.script_category);
        setdetailsDescription(res.payload.data.description);
        setdetailsCallTime(res.payload.data.added_at);
        setdetailsCallBrief(res.payload.data.call_brief);
        setdetailsCallStatus(res.payload.data.call_status);
        setdetailsMood(res.payload.data.clint_mood);
        setdetailsDuration();
        setdetailsName(res.payload.data.customer_name);
        setdetailsEmailId(res.payload.data.email_address);
        setdetailsRecentCall(res.payload.data.added_at);
        setdetailsFollowUp(res.payload.data.call_again_date);
        setdetailsCallBy(res.payload.data.added_by_username);
        setFollowUpScript(res.payload.data.script_category);
        setFollowUpnext_call_username(res.payload.data.next_call_username);
        callData.customer_name = res.payload.data.customer_name;
        callData.email_address = res.payload.data.email_address;
        setalreadycustomer("true");
      } else {
        setalreadycustomer("false");
        setdetailsCallCategory();
        setdetailsCallTime();
        setdetailsCallBrief();
        setdetailsCallStatus();
        setdetailsMood();
        setdetailsDuration();
        setdetailsName();
        setdetailsEmailId();
        setdetailsRecentCall("n/a");
        setdetailsFollowUp("n/a");
        setdetailsCallBy();
      }
    });
  }


  function getdetails() {
    if (alreadycustomer == "true") {
      return (
        <>
          <div className="row">
            <div className="col-10">
              <figure>
                <blockquote className="blockquote text-dark">
                  <p className="text-pop">
                    <i className="bi bi-quote"></i>
                    <b>{detailsCallCategory}</b> on{" "}
                    <b>{alterElement(detailsCallTime)}</b>.
                    The call brief was <b>{detailsCallBrief}</b>. The call went
                    till <b>{detailsCallStatus}</b>.
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer text-secondary">
                  {detailsCallBy}
                </figcaption>
              </figure>
            </div>
            <div className="col-2">
              {" "}
              <img
                className="circle-pop"
                src={generateavatar(detailsCallBy, "White", "#004aad")}
                width="40"
                height="40"
              ></img>
            </div>
            
          </div>

          <div className="col-12">{getfrequency()}</div>

         
        </>
      );
    } else if (alreadycustomer == "wait") {
      return (
        <>
          <div className="row">
            <div className="col-10">
              <figure>
                <blockquote className="blockquote text-dark">
                  <p>Please Wait.</p>{" "}
                </blockquote>
                <figcaption className="blockquote-footer text-secondary">
                  Sunday
                </figcaption>
              </figure>
            </div>
            <div className="col-2">
              {" "}
              <img
                className="circle-pop"
                src={generateavatar("Sunday", "White", "#004aad")}
                width="40"
                height="40"
              ></img>
            </div>
          </div>
          
          
        </>
      );
    } else {
      return (
        <>
          <div className="row">
            <div className="col-10">
              <figure>
                <blockquote className="blockquote text-dark">
                  <p>This contact number was not called.</p>
                </blockquote>
                <figcaption className="blockquote-footer text-secondary">
                  Sunday
                </figcaption>
              </figure>
            </div>
            <div className="col-2">
              {" "}
              <img
                className="circle-pop"
                src={generateavatar("Sunday", "White", "#004aad")}
                width="40"
                height="40"
              ></img>
            </div>
          </div>
         
         
        </>
      );
    }
  }

  function changeFace(item) {
    if (item == "intense") {
      return (
        <i className="bi bi-emoji-angry-fill text-danger" title="Intense"></i>
      );
    } else if (item == "unhappy") {
      return (
        <i className="bi bi-emoji-frown-fill text-dark" title="Unhappy"></i>
      );
    } else if (item == "normal") {
      return (
        <i className="bi bi-emoji-smile-fill text-primary" title="Normal"></i>
      );
    } else if (item == "happy") {
      return (
        <i className="bi bi-emoji-laughing-fill text-success" title="Happy"></i>
      );
    }
  }

  function progressElement(item) {
    if (item.call_status == "None")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped"
            role="progressbar"
            style={{ width: "0%" }}
            aria-valuenow={0}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            0%
          </div>
        </div>
      );
    else if (item.call_status == "Salute & Confirmation")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-primary text-white"
            role="progressbar"
            style={{ width: "20%" }}
            aria-valuenow={20}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            20%
          </div>
        </div>
      );
    else if (item.call_status == "Pitch and Information")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-danger text-white"
            role="progressbar"
            style={{ width: "40%" }}
            aria-valuenow={40}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            40%
          </div>
        </div>
      );
    else if (item.call_status == "Ask Question & Talk")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-warning text-dark"
            role="progressbar"
            style={{ width: "60%" }}
            aria-valuenow={60}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            60%
          </div>
        </div>
      );
    else if (item.call_status == "Objection Handling")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-secondary text-white progress-bar-animated"
            role="progressbar"
            style={{ width: "80%" }}
            aria-valuenow={80}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            80%
          </div>
        </div>
      );
    else if (item.call_status == "Closing")
      return (
        <div className="progress border border-dark">
          <div
            className="progress-bar progress-bar-striped bg-success text-white progress-bar-animated"
            role="progressbar"
            style={{ width: "100%" }}
            aria-valuenow={100}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            100%
          </div>
        </div>
      );
    return item.call_status;
  }

  const tostSuccess = (msg) => {
    var msg = msg;

    toast.success(msg, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  function SearchHome(){

    searchPerson( callData.contact_number, 'to_search' );


  }

   function searchPerson( ph, itemtype ) {

    let searchcall;

    if(itemtype == 'to_search'){

   

      searchcall =  {
        "to_search":"",
        "customer_name":"",
        "contact_number":"",
        "email_address":""
    };

    }

    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/search_for_contact',searchcall, { headers })
      .then((data) => {
        if (data.status == "200") {

          setpersonsearchresult(data.data);
         
        }
        
      })
      .catch((err) => console.log(err));
    
    
  }

  function changePhoneno(item){

    phoneno = item;

    SearchHome();
    setStartDate();
    getCallDetails();
    getScript();
    searchContact();
    contactHistory();
    fetchUserData();
    getTask();

  }

  useEffect(() => {

   if(admin != "true"){

      fetchOrgData();

    }
    SearchHome();
    setStartDate();
    getCallDetails();
    getScript();
    searchContact();
    contactHistory();
    fetchUserData();
    getTask();
    getSourceList();
  }, []);

  return (
    <div className="container-fluid">
      <br/>
      <div className="row">
        <div className="col-12 col-sm-3 bg-transparent">
        <mark>
        <i className="bi bi-cloud-upload-fill"></i> Upload Contacts:
                  </mark>&nbsp;
<div className="btn-group btn-group-sm" role="group" aria-label="Basic outlined example">
  <button type="button"  data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseScript"
                    aria-expanded="false"
                    aria-controls="flush-collapseScript" className="btn btn-outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-add" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
  <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
</svg> Single</button>
  <button type="button" className="btn btn-outline-success" disabled><i className="bi bi-file-earmark-spreadsheet"></i> Multi</button>
</div><br/>  
<div
                  className="accordion accordion-flush card border-0"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item bg-transparent border-0">
                    <div
                      id="flush-collapseScript"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
<h6>Add new contact</h6>

<div className="row">
<div className="col-12">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-person-circle"></i> Full Name
  </label>
  <input
    type="text"
    value={newcontact.customer_name}
    onChange={changenewcontact}
    name="customer_name"
    className="form-control form-control-sm border border-dark"
    id="exampleFormControlInput1"
    placeholder="Rahul Sharma"
  />
</div>
</div>
{/* <div className="col-4">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-globe2"></i> Code
  </label>
  <select className="form-select form-select-sm border-dark" aria-label="Default select example">
              {countrycode.countries &&
                          countrycode.countries?.map((item) => (
                            <option value={item.code}>
                             + {item.code} - {item.name}
                            </option>
                          ))}
</select>
</div>
</div> */}
<div className="col-6">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-telephone-fill"></i> Contact Number
  </label>
  <input
    type="tel"
    value={newcontact.contact_number}
    onChange={changenewcontact}
    name="contact_number"
    className="form-control form-control-sm  border border-dark"
    id="exampleFormControlInput1"
    placeholder="8473385995"
  />
</div>
</div>
<div className="col-6">
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
  <i className="bi bi-envelope-fill"></i> Email address
  </label>
  <input
    type="email"
    value={newcontact.email_address}
    onChange={changenewcontact}
    name="email_address"
    className="form-control form-control-sm  border border-dark"
    id="exampleFormControlInput1"
    placeholder="name@example.com"
  />
</div>

</div>
<div className="col-12">
<div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-bezier2"></i> Select source
                                </label>
                                <select
                                   value={newcontact.contact_Source}
                                   onChange={changenewcontact}
                                  name="contact_Source"
                                  className="form-select form-select-sm mb-3 col-6 border border-dark"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                </select>
                              </div>
</div>
<button type="button" onClick={addNewProspect} className="btn btn-dark pop">Submit</button>

</div>

                      </div>
                    </div>
                  </div>
                  <div></div>
                </div><br/>
<div className="row">
<div className="col-4">
<label>
        <i className="bi bi-toggles"></i> Status
                  </label>
        <select className="form-select border border-dark bg-info"  name='type'
    value={ProspectType.type}
    onChange={changeProspect} aria-label="Default select example">
       <option value=''> All</option>
      <option value={true}> Active </option>
      <option value={false}> Not Active</option>
                         
</select>
</div>
<div className="col-8">
  
<label>
        <i className="bi bi-search"></i> Search 
                  </label>
        <div className="input-group">
  <input
    type="text"
    className="form-control border border-dark"
    placeholder="Search Name/Contact Number/Email"
    aria-label="Recipient's username"
    value={filterText}
        onChange={handleFilterChange}
    aria-describedby="button-addon2"
  />

</div>
</div>
</div>
       

<br/>

    

<mark>
<i className="bi bi-people-fill"></i> All Prospect/Contacts
                  </mark>
        <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "1100px" }}
              >
              <ul className="list-group">
                
              {filteredList  &&
                          filteredList?.map((item) => (

                            <>{

                              ProspectType.type == ''?
<> 
<li className={item.contact_number==phoneno?'list-group-item d-flex justify-content-between align-items-start listhover bg-secondary text-light':'list-group-item d-flex justify-content-between align-items-start listhover' }>
                              <div className="me-auto">
                             
                              
                              <i className="bi bi-person-circle"></i> {item.customer_name == ''?masknumber(item.contact_number):item.customer_name}
                              <br/>
                              {item.tags  &&
                          item.tags?.map((item) => (
                            <>
                            <span className="badge bg-dark">{item}</span>&nbsp;

                            </>
                            ))}
                              </div>
                             
    <Link
                                to={
                                  `/callinfo?phoneno=` +
                                  item.contact_number
                                }
                               
                              ><i
                              title="View"
                              className="bi bi-eye-fill link-dark"
                              onClick={()=>changePhoneno(item.contact_number)}
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            ></i></Link>&nbsp; 
    
                             {prospectStatus(item)}
                            </li></>

                             
                                

                              :<>
                              {
                                ProspectType.type == String(item.isActive)?<>
                                <li className="list-group-item d-flex justify-content-between align-items-start listhover">
                              <div className="me-auto">
                             
                              
                              <i className="bi bi-person-circle"></i> {item.customer_name == ''?masknumber(item.contact_number):item.customer_name}
                              <br/>
                              {item.tags  &&
                          item.tags?.map((item) => (
                            <>
                            <span className="badge bg-dark">{item}</span>&nbsp;

                            </>
                            ))}
                              </div>
                             
    <Link
                                to={
                                  `/callinfo?phoneno=` +
                                  item.contact_number
                                }
                               
                              ><i
                              title="View"
                              className="bi bi-eye-fill link-dark"
                              onClick={()=>changePhoneno(item.contact_number)}
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            ></i></Link>&nbsp; 
    
                             {prospectStatus(item)}
                            </li>
                                </>

                                :null

                              }
                              </> 

                            }
                            </>
                            
                           
                            
                          ))}
  
</ul>
</div>
        
        </div>

        {
          phoneno != null?<div className="col-12 col-sm-9">
        
          <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-2">
          
  <button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="bi bi-plus-circle"></i> Add Interaction</button>
  <button type="button" className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal2"><i class="bi bi-alarm"></i> Assign Call/Task</button>
  <a href={"https://wa.me/+91" + ContactNo} target="_blank"><button type="button" className="btn btn-success"><i className="bi bi-whatsapp"></i></button></a>
  
        </div>
  
  <br/>
            <div className="row">
            <div className="col-12 col-sm-6">
            <div class="card bg-white border border-dark">  
          <div class="card-header card_design3 text-light">
          <i className="bi bi-phone-fill"></i> Last call details
    </div>
         
          <div className="card-body">
            
          {getdetails()}
          
          </div>    
          
  
  </div>
              </div>
              <div className="col-12 col-sm-5">
              <div class="card border border-dark">
    <div class="card-header card_design3 text-light">
    <i className="bi bi-person-fill"></i> Customer Information
    </div>
    <div class="container">
    <table className="table">
                 
                 <tbody>
                   <tr>
                     <td>
                     <i className="bi bi-person-circle"></i> Name{" "}
                     </td>
                     <td>:</td>
                     <td>
                       <code>
                         {/* <b>{Name}</b> */}
                         <EditableTextField
                           data={{ customer_name: Name }}
                           handleBlur={handleUpdate}
                           keyname="customer_name"
                           placeholder="Name"
                         />
                       </code>
                     </td>
                   </tr>
                   <tr>
                     <td>
                       <i className="bi bi-telephone-fill"></i> Contact No{" "}
                     </td>
                     <td>:</td>
                     <td><b>{ContactNo}</b></td>
                   </tr>
                   <tr>
                     <td>
                       <i className="bi bi-envelope-fill"></i> Email Id
                     </td>
                     <td>:</td>
                     <td>
                       {/* {EmailId} */}
                       <EditableTextField
                         data={{ email_address: EmailId }}
                         handleBlur={handleUpdate}
                         keyname="email_address"
                         placeholder="Email"
                       />
                     </td>
                   </tr>
                   <tr>
                     <td>
                     <i className="bi bi-person-check-fill"></i> Status
                     </td>
                     <td>:</td>
                     <td>
                       {
                         ContactStatus == false?<><i onClick={()=>changeClientStatus()} className="bi bi-toggle-off"></i> <span className="badge bg-danger">Not Active</span></>
                         :ContactStatus == true? <><i onClick={()=>changeClientStatus()} className="bi bi-toggle-on"></i> <span className="badge bg-primary">Active</span></>
                         :null
 
                       }
                     
                     </td>
                   </tr>
                   <tr>
                     <td>
                     <i className="bi bi-tag-fill"></i> Tags
                     </td>
                     <td>:</td>
                     <td> {getObjectByContactNumber(phoneno, filteredList)  &&
                          getObjectByContactNumber(phoneno, filteredList)?.map((item) => (

                            <> <span className="badge bg-dark"> {item}</span>
                            
                            </>
                            
                          ))}
                       
                     <button type="button" className='border-0 bg-transperent' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={gettags} ><i className="bi bi-plus-square"></i> </button>
                     <div
 class="collapse" id="collapseTwo"
  
>
  <div className="accordion-body">

  {orgtags  &&
                          orgtags?.map((item) => (

                            <> <span className="badge bg-secondary" onClick={()=>addtags(item)} style={{ cursor: "pointer" }}><i className="bi bi-plus"></i> {item}</span>
                            
                            </>
                            
                          ))}
                          <br/><br/>
<div className="input-group input-group-sm">
  <input
    type="text"
              value={newtag.tagname}
              onChange={changenewtag}
              name="tagname"
    className="form-control border-dark"
    placeholder="Add tag"
    aria-label="Text input with segmented dropdown button"
  />
  <button type="button" onClick={()=>addtags('')} className="btn btn-dark">
    Add
  </button>
</div>

 
  </div>
</div>

                     </td>
                   </tr>
                   
                   
                 </tbody>
               </table>
  
               
    </div>
  </div>
              </div>
            </div>
  <br/>
            <nav>
              <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  <i className="bi bi-phone-fill"></i> Call History
                </button>
                <button
                  className="nav-link"
                  onClick={gettaskHistory}
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  <i className="bi bi-bicycle"></i> Task History
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active p-3 mb-5"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div
                  className="overflow-auto"
                  style={{ maxWidth: "1000px", maxHeight: "800px" }}
                >
                  <table className="table table-success border-white caption-top">
                <caption>List of Calls:</caption>
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">
                      <i className="bi bi-compass"></i> Call Status
                      </th>
                      <th scope="col">
                        <i className="bi bi-phone-vibrate"></i> Call Brief
                      </th>
                      <th scope="col">
                        <i className="bi bi-file-earmark-text" /> Call Type
                      </th>
                      <th scope="col">
                        <i className="bi bi-calendar4-event"></i> Date
                      </th>
                      <th scope="col">
                        <i className="bi bi-balloon-fill"></i>
                      </th>
                      <th scope="col">
                        <i className="bi bi-menu-button-fill"></i> Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {CallHistory &&
                      CallHistory?.map((item) => (
                        <tr>
                          <td>
                            <b>
                              {item.call_type == "Incoming" ? (
                                <i className="bi bi-telephone-inbound-fill text-success"></i>
                              ) : (
                                <i className="bi bi-telephone-outbound-fill text-secondary"></i>
                              )}
                            </b>
                          </td>
  
                          <td>{changeElement(item)}</td>
                          <td>{progressElement(item)}</td>
                          <td>{item.script_category}</td>
                          <td>{alterElement(item.added_at)}</td>
                          <td>{changeFace(item.clint_mood)}</td>
                          <td>
                            {" "}
                           
                            <button
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#flush-" + item.id}
                              className="btn btn-transparent btn-sm"
                            >
                              Description{" "}
                              <i className="bi bi-caret-down-fill"></i>
                            </button>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item bg-transparent">
                                <div
                                  id={"flush-" + item.id}
                                  className="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    {item.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
                
              </div>
              <div
                className="tab-pane fade p-3 mb-5"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
              <div
                  className="overflow-auto"
                  style={{ maxWidth: "1000px", maxHeight: "800px" }}
                >
                <table className="table table-success border-white caption-top">
                <caption>List of Tasks: </caption>
                  <thead className="table-dark">
                    <tr>
                      <th><i className="bi bi-tags-fill"></i></th>
                      <th scope="col">
                        <i className="bi bi-bicycle"></i> Task Heading
                      </th>
                      <th scope="col">
                        <i className="bi bi-person-circle"></i> Added by
                      </th>
                      <th scope="col">
                        <i className="bi bi-person-lines-fill"></i> Assigned to
                      </th>
                      <th scope="col">
                        <i className="bi bi-calendar2-check"></i> Due Date
                      </th>
                      <th scope="col">
                        <i className="bi bi-calendar4-event"></i> Date
                      </th>
                      <th scope="col">
                        <i className="bi bi-menu-button-fill"></i>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {TaskHistory &&
                      TaskHistory?.map((item) => (
                        <tr>
                          <td>{item.taskStatus == "Assigned" ? (
                                      <span className="badge bg-primary">
                                        <i className="bi bi-person-lines-fill"></i>
                                        Assigned
                                      </span>
                                    ) : item.taskStatus == "In Progress" ? (
                                      <span className="badge bg-warning text-dark">
                                        <i className="bi bi-fan"></i> In Progress
                                      </span>
                                    ) : item.taskStatus == "Incomplete" ? (
                                      <span className="badge bg-danger">
                                        <i className="bi bi-x-lg"></i> Incomplete
                                      </span>
                                    ) : item.taskStatus == "Completed" ? (
                                      <span className="badge bg-success">
                                        <i className="bi bi-check-lg"></i>
                                        Completed
                                      </span>
                                    ) : null}</td>
                          <td>
                            <b>{item.taskHeading}</b>
                          </td>
                          <td>{item.added_by_username}</td>
                          <td><b>{item.assineTO_userName}</b></td>
                          <td>{alterElement(item.taskLastDate.datetime)}</td>
                          <td>{alterElement(item.added_at)}</td>
  
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#flush-" + item.id}
                              className="btn btn-transparent btn-sm"
                            >
                              Description{" "}
                              <i className="bi bi-caret-down-fill"></i>
                            </button>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item bg-transparent">
                                <div
                                  id={"flush-" + item.id}
                                  className="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    {item.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
          :<div className="col-12 col-sm-9" align='center'><br/><br/><br/><br/><br/><img src={emptycontact} height={350} /></div>

        }

        
        
      </div>
      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title" id="exampleModalLabel"><i class="bi bi-plus-circle"></i> Add Interaction</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
            
            <p>How was your call?</p>

<div className="row">
  <div className="mb-1 col-6">
    <select
      value={callData.call_type}
      onChange={changeCallData}
      name="call_type"
      className="form-select mb-3 border border-secondary inside"
      aria-label="Default select example"
      required
    >
      <option>Call Type </option>
      <option value="Incoming">Incoming</option>
      <option value="Outgoing">Outgoing</option>
    </select>
  </div>
  <div className="mb-1 col-6">
    <select
      value={callData.script_category}
      onChange={changeCallData}
      name="script_category"
      className="form-select mb-3 border border-secondary inside"
      aria-label="Default select example"
      required
    >
       <option>Select Category</option>
              {feedOrgCategory &&
                feedOrgCategory?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
    </select>
  </div>
</div>
<div className="row">
  {callData.call_type != null ? (
    <>
      <div className="mb-1 col-6">
        <select
          value={callData.call_brief}
          onChange={changeCallData}
          name="call_brief"
          className="form-select mb-3 border border-secondary inside"
          aria-label="Default select example"
          required
        >
          <option>Select Call Brief </option>
          <option value="In Valid">
            Invalid Number
          </option>
          <option value="Cut Call">Cut Call</option>
          <option value="Didn't Pick">
            Didn't Pick
          </option>
          <option value="Call Later">
            Call Later
          </option>
          <option value="Interested">
            Interested
          </option>
          <option value="Not Interested">
            Not Interested
          </option>
          <option value="Other">Other</option>
        </select>
      </div>

      {/* <p>
        {callData.call_brief &&
          callTaskScript(callData.call_brief)}
      </p> */}
    </>
  ) : null}

  {callData.call_brief == "Interested" ||
  callData.call_brief == "Not Interested" ||
  callData.call_brief == "Other" ? (
    <>
      {" "}
      <div className="mb-1 col-6">
        <select
          value={callData.call_status}
          onChange={changeCallData}
          name="call_status"
          className="form-select mb-3 col-6 border border-secondary inside"
          aria-label="Default select example"
          required
        >
          <option>Select Part</option>
          <option value="None">None</option>
          <option
            className="bg-primary text-white"
            value="Salute & Confirmation"
          >
            Salute & Confirmation
          </option>
          <option
            className="bg-danger text-white"
            value="Pitch and Information"
          >
            Pitch and Information
          </option>
          <option
            className="bg-warning text-dark"
            value="Ask Question & Talk"
          >
            Ask Question & Talk
          </option>
          <option
            className="bg-white text-dark"
            value="Objection Handling"
          >
            Objection Handling
          </option>
          <option
            className="bg-success text-white"
            value="Closing"
          >
            Call Closing
          </option>
        </select>
      </div>
    </>
  ) : null}
</div>
{callData.call_brief == "Interested" ||
callData.call_brief == "Not Interested" ? (
  <>
    <p>How was the person?</p>
    <div className="row">
    {callData.clint_mood == "intense"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "intense"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="intense"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood1"
          autoComplete="off"
          
          defaultChecked
        />
        <label htmlFor="clint_mood1">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-angry-fill text-primary"
              title="Intense"
            ></i>
          </h3>
        </label>
      </div>) 
        :<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "intense"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="intense"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood1"
          autoComplete="off"
          
          
          defaultChecked
        />
        <label htmlFor="clint_mood1">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-angry-fill"
              title="Intense"
            ></i>
          </h3>
        </label>
      </div> }

      {callData.clint_mood == "unhappy"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "unhappy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="unhappy"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood2"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood2">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-frown-fill text-primary"
              title="Unhappy"
            ></i>
          </h3>
        </label>
      </div>) 
        :<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "unhappy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="unhappy"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood2"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood2">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-frown-fill"
              title="Unhappy"
            ></i>
          </h3>
        </label>
      </div> }

      {callData.clint_mood == "normal"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "normal"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="normal"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood3"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood3">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-smile-fill text-primary"
              title="Normal"
            ></i>
          </h3>
        </label>
      </div>) 
        : <div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "normal"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="normal"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood3"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood3">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-smile-fill"
              title="Normal"
            ></i>
          </h3>
        </label>
      </div> }

      {callData.clint_mood == "happy"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "happy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="happy"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood4"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood4">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-laughing-fill text-primary"
              title="Happy"
            ></i>
          </h3>
        </label>
      </div>) 
        : <div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "happy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="happy"
          checked={callData.clint_mood}
          onChange={changeCallData}
          name="clint_mood"
          id="clint_mood4"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood4">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-laughing-fill"
              title="Happy"
            ></i>
          </h3>
        </label>
      </div>}    
      
      
      
      
    </div>
    <br />
  </>
) : null}

{callData.call_brief !== "" ? (
  <>
    <div className="mb-1">
      <label
        for="exampleFormControlTextarea1"
        className="form-label"
      >
        <i className="bi bi-file-text-fill" />{" "}
        Description
      </label>
      <textarea
        value={callData.description}
        onChange={changeCallData}
        name="description"
        className="form-control border border-white inside2"
        id="exampleFormControlTextarea1"
        rows="3"
      ></textarea>
    </div>
    <br />
  </>
) : null}

{callData.call_brief != "" ? (
  <div
    className="accordion accordion-flush bg-transparent "
    id="accordionFlushExample"
  >
    <div
      className="accordion-item bg-dark bg-transparent"
      id="callback"
    >
      
      <div className="d-grid gap-2 d-md-flex justify-content-md-start">
        <button
          onClick={() => sendContactData()}
          className="btn btn-dark pop"
          type="button"
        >
          <i className="bi bi-arrow-right" />
          &nbsp;Submit
        </button>
      </div>
      <br/>
      
     
    </div>
  </div>
) : null}

            </div>
        
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
        <div className="modal-header border-0">
              <h5 className="modal-title" id="exampleModalLabel"><i className="bi bi-alarm"></i> Set Activity</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">

            <div>
        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><i className="bi bi-phone"></i> Call</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><i className="bi bi-bicycle"></i> Task</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div className="row">
                      <div className="col-5">
                        <br/><br/>
                      <img
                              src={addsingle}
                              height={300}
                            />
                      </div>
                      <div className="col-6">
                        
                        <div className="row">
                        
                          <div className="col-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-person-fill"></i> Assign to:
                              </label>
                              <select
                                value={addCallData.next_call_user}
                                onChange={changeAddCallData}
                                name="next_call_user"
                                className="form-select mb-3 col-6 border border-dark"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Person</option>
                                {feed &&
                                  feed?.map((item) => (
                                    <option
                                      value={item.id + "," + item.user_name}
                                    >
                                      {item.user_name}-{item.role}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-file-text-fill"></i> Select Script
                              </label>
                              <select
                                value={addCallData.next_call_script_category}
                                onChange={changeAddCallData}
                                name="next_call_script_category"
                                className="form-select mb-3 col-6 border border-dark"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Category</option>
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <option value={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-alarm-fill"></i> Schedule
                              </label>
                              <input
                                name="call_again_date"
                                value={addCallData.call_again_date}
                                onChange={changeAddCallData}
                                type="datetime-local"
                                className="form-control border-dark"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                        </div>

                        
                        <div className="row">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                             <i className="bi bi-card-text"></i> Description
                            </label>
                            <textarea
                              value={addCallData.description}
                              onChange={changeAddCallData}
                              name="description"
                              className="form-control border-dark"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-dark"
                            onClick={sendToCallData}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      </div>
          </div>
          <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div className="row bg-transparent">
                          <div className="row">
                            <div className="col-5">
                              <br />
                              <br />
                              <br />
                              <img
                                src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/3.+Add+task.svg"
                                height={250}
                              />
                            </div>
                            <div className="col-6">
                              <div className="row">
                              
                                <div className="mb-1 col-12 bg-transparent">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-file-earmark-text-fill" />
                                    Task Category:
                                  </label>
                                  <select
                                    name="taskHeading"
                                    value={taskData.taskHeading}
                                    onChange={changeTaskData}
                                    className="form-select mb-3 col-6 border border-success"
                                    aria-label="Default select example"
                                    required
                                  >
                                    <option>Select Task</option>

                                    {feedOrgTask &&
                                      feedOrgTask?.map((item, index) => (
                                        <option
                                          value={item.heading}
                                          key={index}
                                        >
                                          {item.heading} ({item.description})
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>

                              <div className="row">
                                <div className="mb-1 col-6">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-people-fill" /> Assign
                                    task to
                                  </label>
                                  <select
                                    name="assineTO_userID"
                                    value={taskData.assineTO_userID}
                                    onChange={changeTaskData}
                                    className="form-select mb-3 col-6 border border-success"
                                    aria-label="Default select example"
                                    required
                                  >
                                    <option>Select Person</option>
                                    {feed &&
                                      feed?.map((item, index) => (
                                        <option
                                          value={item.id + "," + item.user_name}
                                          key={index}
                                        >
                                          {item.user_name}-{item.role}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="mb-1 col-6 bg-transparent">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-alarm-fill" /> Due Date
                                  </label>
                                  <input
                                    type="datetime-local"
                                    name="lastDate"
                                    value={taskData.lastDate}
                                    onChange={changeTaskData}
                                    className="form-control border border-success"
                                    id="exampleFormControlInput1"
                                    placeholder="Call Back"
                                  />
                                </div>
                              </div>

                              <div className="mb-1 col-12">
                                <div className="mb-1">
                                  <label
                                    for="exampleFormControlTextarea1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-file-text-fill" /> Task
                                    Description
                                  </label>
                                  <textarea
                                    name="description"
                                    value={taskData.description}
                                    onChange={changeTaskData}
                                    className="form-control border border-success"
                                    id="exampleFormControlTextarea1"
                                    rows={3}
                                  ></textarea>
                                </div>
                              </div>
                              <br/>
                             <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <button
                          className="btn btn-dark"
                          onClick={sendTask}
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                              </div>

                            </div>
                          </div>
                        </div>
          </div>
        </div>
      </div>

            </div>

        </div>
        </div>
        </div>
        <ToastContainer />
    </div>
    
  );
}

export default Callinfo;
