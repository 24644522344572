import { configureStore, combineReducers } from "@reduxjs/toolkit";
import reducer from "./reducers";
const combineReducer = combineReducers({
  ...reducer,
});

export const store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
