import React from 'react'

const dominname = window.location.origin;


function Ticketlink(props) {
    console.log(props);
  return (
    <div><>
    <div className="mb-3">
    <label htmlFor="exampleFormControlInput1" className="form-label"><i className="bi bi-link-45deg"></i> Share lead progress with prospect/customer</label>
    <input className="form-control form-control-sm border-dark" type="text" value={dominname+"/ticket?Tid="+props.tid+"&FTid="+props.ftid+"&org="+props.org} aria-label=".form-control-sm example" />
</div><br/></></div>
  )
}

export default Ticketlink