import { functionsIn } from "lodash";
import { useState, useEffect, useRef,useReducer} from "react";
import React, { Component } from "react";
import { Link, Router } from "react-router-dom";






function Stopwatch() {


   const [counter,setCounter] =useState(0);

   const [prevalue, setPrevious] = useState(0);
  


  function changeConunter(type){


    if(type=='inc'){

      // let temp = counter;

      // temp = temp+1;
      setPrevious(counter);
      setCounter(counter+1);


    }else{

      // let temp = counter;

      // temp = temp-1;
      setPrevious(counter);
      setCounter(counter-1);


    }


  }





  return (
    <>

<h1 align='center'>Previous : {prevalue}</h1>
<br/>


    <h1 align='center'>{counter}</h1>

    <button onClick={()=>changeConunter('inc')} >Inc</button>

    <button onClick={()=>changeConunter('desc')}>Desc</button>

      
    </>
  );
}

export default Stopwatch
