import React, { Component, useEffect, useState, useRef } from "react";

function ActionModel(props) {




  const [selectedvalue, setSelectedValue] = useState('');
  console.log(selectedvalue,'hello');

  function bulkcopy(item){


    if(item == '2'){

    
        setSelectedValue(extractUniqueValues(props.bulkvalue, 'email').toString());

  
  
    
    
      }else if(item == '3'){
    
       
        setSelectedValue(extractUniqueValues(props.bulkvalue, 'phone').toString());
    
      }

  }





  function extractUniqueValues(data, keyword) {
    // Ensure the keyword is valid and data is an array
    if (!data || !Array.isArray(data) || !keyword) {
      console.error('Invalid input data or keyword');
      return [];
    }
  
    // Use a Set to store unique values
    const uniqueValuesSet = new Set();
  
    // Iterate through the array of objects
    data.forEach((item) => {
      // Check if the keyword exists in the current item and is an array
      if (item.hasOwnProperty(keyword) && Array.isArray(item[keyword])) {
        const values = item[keyword];
  
        // Add each element of the array to the set
        values.forEach((value) => {

            if (value !== '') {
                uniqueValuesSet.add(value);
            }
          
        });
      }
    });
  
    // Convert the Set to an array and return
    return Array.from(uniqueValuesSet);
  }
  
  

  
 
     useEffect(()=>{
        bulkcopy(props.actiontype);
     })

  return (
    <>{props.actiontype == '1'?<>Bulk edit</>:props.actiontype == '2'?<><div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">
  <i className="bi bi-envelope-paper-fill"></i> Copy Email List :
  </label>
  <textarea
    className="form-control border border-dark"
    id="exampleFormControlTextarea1"
    rows={4}
    defaultValue={""}
    value={selectedvalue}
  />
</div>
 </>:props.actiontype == '3'?<><div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">
   <i className="bi bi-phone-fill"></i> Copy Phone Number List : 
  </label>
  <textarea
    className="form-control border border-dark"
    id="exampleFormControlTextarea1"
    rows={4}
    defaultValue={""}
    value={selectedvalue}
  />
</div></>:null   }</>
  )
}

export default ActionModel