import React, { Component, useEffect, useState, useRef } from "react";
import { Button } from 'react-bootstrap';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import "react-toastify/dist/ReactToastify.css";
import Ticketlink from "../components/ticketlink";
import { useSelector, useDispatch } from "react-redux";
import {
  getScripts,
  getContactNumber,
  getUserData,
  getCallGoals,
  getPar1Latest,
  getPar2Latest,
  getPar3Latest,
  getPar4Latest,
  getPar5Latest,
  getPar6Latest,
  addsuggestions,
  addquestions,
  addNewTask,
  getseachquestions,
  upvote,
  downvote,
  updateCallDetails,
  addNewCall,
  deleteContactToCall,
  getscriptCallEndingInstruction,
  getTasks,
  getProgressBarStatus,
} from "../redux/slices/homeSlice";
import {
  findContactDetails
} from "../redux/slices/homeSlice";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import SetTasks from "../components/SetTasks";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { tocallvalue, progressElement, alterElement, alterCallBack, changeElement, changeFace, generateavatar} from '../helper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import EditableTextField from "../pages/editableTextField";
import Call from "../pages/Call";
import Task from "../components/Task";
import Popover from 'react-bootstrap/Popover';
import welldone from "../media/welldone.svg";
import {
  getselect_options
} from "../redux/slices/leadSlice";
import DatabasedAction from "./DatabasedAction";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        /> */}
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);


const admin = localStorage.getItem("admin");

const org = localStorage.getItem("org");
const access_token = localStorage.getItem("Auth");
const Auth = localStorage.getItem("Auth");

const user_name = localStorage.getItem("user_name");
const token = localStorage.getItem("token");

function removeEverythingBeforeComma(inputString, props) {


  if(inputString  == null ){
    inputString = props.owner;
  }
  
  const parts = inputString.split(',');
  if (parts.length > 1) {
      return parts[1].trim();
  }
  return inputString.trim();
}





const LeadDetails = (props) => {

  
  // const detailtype = 'callview';
  const detailtype = props.leadType;

  const [LeadData, setLeadData] = useState({});
  


  const [addNewData, setAddNewData] = useState({});

  const [selectoptions, setSelectoptions] = useState({});

  console.log(selectoptions ,'arrayName12131212')


  function calculatesopPercentage(arrayName, targetStage) {

    


    // Your provided object
    const stagesObject = selectoptions;
  
    // Get the array based on the provided arrayName
    const targetArray = stagesObject[arrayName];
  
    if (targetArray) {
      // Find the index of the targetStage in the array
      const stageIndex = targetArray.indexOf(targetStage);
  
      // Calculate the percentage
      const percentage = ((stageIndex + 1) / targetArray.length) * 100;

      return percentage.toFixed(0);
    }
  }



  console.log(addNewData,'newdata');

  const propertiesToExclude = ["title", "contactPerson", "organization", "organization_id", "ownerId", "owner", "Description", "Id", "excludeArray", "excludeArray", "currency", "pipelineCurrentStage", "priority" ,"pipeline","value","TicketId","email","expectedClosingDate","lead_status","phone","visibleTo","Lead_Source",'_id','addedAt','addedAtUTC'];

  const excludeArray = Object.keys(LeadData)
    .filter(key => !propertiesToExclude.includes(key))
    .reduce((acc, key) => {
        acc[key] = LeadData[key];
        return acc;
    }, {});


  const [show, setShow] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const [tid, setTid] = useState('');

 
  const [ftid, setFTid] = useState('');

  const handleCloseLink = () => setShowLink(false);
  function showModel(item) {

    

    setTid(item.TicketId);
    setFTid(item.Id);
    setShowLink(true);

  }

  const [itype, setItype] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setItype(data);

   
  }

  const [LeadHistory, setLeadHistory] = useState();
  const [Call_History, setCallHistory] = useState();
  const [Task_History, setTaskHistory] = useState();
  const [RecLink, setRecLink] = useState("");
  const [listsop, setlistsop] = useState([]);

  const [formFields, setFormFields] = useState([
  ]);

  console.log(formFields,'formFields');

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowItem, setModalItem] = React.useState(false);

  const [modalShowType, setModalType] = React.useState('');

  const [newnotes, setnewnotes] = useState('');

  const [oldnotes, setoldnotes] = useState([]);

  const [selectedCollaboration, setSelectedCollaboration] = useState('');

  const [oldCollaborators, setoldCollaborators] = useState([]);

  let currencyname = ['INR','USD','EURO','AED']
  let prioritytype = ['low','medium','high']

  const renderPopover = (props) => (
    <Popover id="popover-basic">
    <Popover.Body>
    {props}
    </Popover.Body>
    </Popover>
  );

  
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function userNameuserId(item, type) {
    // Split the string by the hyphen
    const [user_id, user_name] = item.split('-');
  
    // Return the appropriate value based on the 'type' parameter
    if (type === "User_id") {
      return user_id;
    } else if (type === "User_name") {
      return user_name;
    } else {
      return null; // or you can throw an error if the type is invalid
    }
  }
  

  const audioRef = useRef();
  
  const filteredArray = formFields.filter(obj => !Object.keys(excludeArray).includes(obj.name));
  
  console.log(filteredArray, 'add information');
  const dispatch = useDispatch();


  const [feed, setFeed] = useState();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddNewData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };



  function getTypeAndOptions(key) {
    const element = formFields.find(el => el.name.toLowerCase().trim() === key.toLowerCase().trim());
    if (element) {
      if (element.type === 'textarea') {
        element.type = 'text';
      }
      return {
        type: element.type,
        options: element.options || [],
      };
    } else {
      return {
        type: 'text', // or any default type you prefer
        options: [],
      };
    }
  }
  

  
  function removeTimeFromString(inputString) {
    // Replace the "00:00:00" with an empty string
    let stringWithoutTime = inputString.replace(' 00:00:00', '');
  
    return stringWithoutTime;
  }


  function fetchUserData() {
    dispatch(getUserData({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setFeed(res.payload.data);
      }
    });
  }
  
  function returncontact(item){

    return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
    <i className="bi bi-caret-down-fill"></i>
            </OverlayTrigger>

  } 

  function getOptionData() {
    dispatch(getselect_options({org })).then((res) => {
      if (res.payload.status == 200) {
        setSelectoptions(res.payload.data);
      }
    });
    
  }

  
  function fetchLeadHistory(item) {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/find_ticket_history?".concat(
           "ticketId=",
          item,"&organisation_id=",
          org
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setLeadHistory(data.data.call_history);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }


  function leadDetailsCall(item) {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/get_lead_detail?".concat(
           "Tid=",
          item
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setLeadData(data.data.ticket_data[0]);
          getSopProcess(data.data.ticket_data[0].pipeline);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }
   


  
  function fetchAssignedData_Call(item) {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/find_ticket_call_assigned?".concat(
           "ticketId=",
          item,"&organisation_id=",
          org
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          setCallHistory(data.data);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }

  function fetchAssignedData_Task(item) {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/find_ticket_task_assigned?".concat(
           "ticketId=",
          item,"&organisation_id=",
          org
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          setTaskHistory(data.data.tasks);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }
  
  function rec() {
    document.getElementById("rec").style.display = "block";
  }
  function rec_back() {
    document.getElementById("rec").style.display = "none";
  }

  const updateSong = (source) => {
    setRecLink(source);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };





  function changeRec(item) {
    if (item.call_recoding == true) {
      return (
        <><button
          onClick={() => getRec(item)}
          className="border-0 bg-transparent"
        >
          <i className="bi bi-play-circle-fill"></i>
        </button>
      </>
      );
    } else if (item.call_recoding == false) {
      return (
        <button
          className="border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#audioModal"
        >
          <i className="bi bi-cloud-arrow-up"></i>
        </button>
      );
    } else {
      return (
        <button
        className="border-0 bg-transparent"
        data-bs-toggle="modal"
        data-bs-target="#audioModal"
      >
        <i className="bi bi-cloud-arrow-up"></i>
      </button>
      );
    }
  }

  function getRec(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/CallRecoding?".concat(
           "docucmet_id=",
          item.id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateSong(data.data);
          rec();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const handleUpdate = (data, value, keynamevalue) => {

    let finaldata = { ...LeadData, [keynamevalue]: value };
    
    updatelead(finaldata);
   
   
  };

  const handleSelectCollaborators = (event) => {


    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedUserName = selectedOption.text.split(' - ')[0]?.trim();
    const selectedUserId = selectedOption.value?.trim();
  
    if (selectedUserName && selectedUserId) {
      setSelectedCollaboration('');
    } else {
      console.error("Invalid selection: User name or ID is empty.");
      // Optionally, you can set an error state or show a message to the user here
    }
  };


  function updatelead(item){

    let finaldata; 

    if(item == ''){

      finaldata = LeadData;

    }else{

      finaldata = item;

    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios.post("/update_leads", finaldata, { headers }).then((data) => {
      if (data.status == "200") {

        setLeadData(finaldata);
        setAddNewData({});
        leadDetailsCall(props.leadData.TicketId);
        toast.success("Lead updated successfully!", {
          position: "bottom-left",
          autoClose: 1000,
        });
        

      }
    });

  }

  function updateOptions (value, keynamevalue ){

    let finaldata;

    if(keynamevalue == 'owner'){

      let separatedParts = value.split(',');

      let beforeComma = separatedParts[0];
      let afterComma = separatedParts[1];

      finaldata = { ...LeadData, ['ownerId']: beforeComma };
      finaldata = { ...LeadData, ['owner']: afterComma };

    }else{

      finaldata = { ...LeadData, [keynamevalue]: value };

    }
    

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios.post("/update_leads", finaldata, { headers }).then((data) => {
      if (data.status == "200") {

        setLeadData(finaldata);
        toast.success("Lead updated successfully!", {
          position: "bottom-left",
          autoClose: 1000,
        });


      }
    });

  }
  
  function getSopProcess(item) {

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };


      axios
        .get("/Get_sop_process?SopName=".concat(item), {
          headers,
        })
        .then((data) => {
          if (data.status == "200") {
            setlistsop(data.data);
            
          }
          
        })
        .catch((err) => {
          setlistsop([]);
          LeadData.pipelineCurrentStage = '';

        });

  }


    ////get form data

    function getFormData() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/get_addformfields",
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setFormFields(data.data.data_format);
          }
  
          // setErrorMessage1(data.data.message);
        })
        .catch((err) => console.log("error", err));
    }



    ////Get lead notes

    function getLeadNotes() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          `/notes?ticket_id=${props.leadData.TicketId}`,
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            
            setoldnotes(data.data.data.note);
            console.log(data.data.data.note,'notes leads');
          }
  
          // setErrorMessage1(data.data.message);
        })
        .catch((err) => console.log("error", err));
    }


    
    ////Add lead notes

    function addLeadNotes(item) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios.post(`notes/${props.leadData.TicketId}/`,item, { headers }).then((data) => {
        if (data.status == "200") {

          toast.success("Notes updated successfully!", {
            position: "bottom-left",
            autoClose: 1000,
          });
          setnewnotes('');
          getLeadNotes();
  
  
        }
      });
    }

    function insertNotes() {
      // Ensure oldnotes is an array, otherwise initialize it as an array
      let notesArray = Array.isArray(oldnotes) ? oldnotes : [];
    
      let item = {
        "ticketId": `${props.leadData.TicketId}`,
        "note": notesArray,
      };
    
      item.note.push({
        "notes": newnotes,
        "added_by": user_name,
        "added_by_id": token,
        "added_at": "20-08-2024"
      });
    
      addLeadNotes(item);
    }
    

    ////Get lead collaborators

    function getCollaborators() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          `/collaborators?ticket_id=${props.leadData.TicketId}`,
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            
            // setoldCollaborators(data.data.data.collaborators);
            if(data.data.status=="not found"){
              setoldCollaborators([]);
            }else{
              setoldCollaborators(data.data.data.collaborators);
            }
            console.log(data.data.data.collaborators,'collaborators leads');
          }
  
          // setErrorMessage1(data.data.message);
        })
        .catch((err) => console.log("error", err));
    }


    
    ////Add lead collaborators

    function addLeadCollaborators(item) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios.post(`collaborators/${props.leadData.TicketId}/`,item, { headers }).then((data) => {
        if (data.status == "200") {

          toast.success("Notes updated successfully!", {
            position: "bottom-left",
            autoClose: 1000,
          });
          setSelectedCollaboration('');
          getCollaborators();
  
  
        }
      });
    }

      ////Remove collaborators

      function delLeadCollaborators(item) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth}`,
        };
        
        // Extract user_id from the item object
        const userId = item;
        
        axios
          .delete(`collaborators/${props.leadData.TicketId}/${userId}`, { headers })
          .then((response) => {
            if (response.status === 200) {
              toast.success("Collaborator removed successfully!", {
                position: "bottom-left",
                autoClose: 1000,
              });
              setSelectedCollaboration('');
              getCollaborators(); // Fetch the updated list of collaborators
            }
          })
          .catch((error) => {
            toast.error("Failed to remove collaborator", {
              position: "bottom-left",
              autoClose: 1000,
            });
            console.error("Error removing collaborator:", error);
          });
      }
      
      function getUnselectedMembers(teamMembers, selectedMembers) {
        if (!Array.isArray(teamMembers) || !Array.isArray(selectedMembers)) {
          return [];
        }
    
        // Create a Set of selected user IDs for faster lookup
        const selectedIds = new Set(selectedMembers.map(member => member.User_id));
    
        // Filter out members whose IDs are not in the selectedIds Set
        const unselectedMembers = teamMembers.filter(member => !selectedIds.has(member.id));
    
        return unselectedMembers;
      }

      // Get unselected members
     const unselectedMembers = getUnselectedMembers(feed, oldCollaborators);


      function insertCollaborators() {
        // Ensure oldCollaborators is an array, otherwise initialize it as an array
        let collaboratorsArray = Array.isArray(oldCollaborators) ? oldCollaborators : [];
      
        let item = {
          ticketId: `${props.leadData.TicketId}`,
          collaborators: collaboratorsArray,
        };
      
        item.collaborators.push({
          User_name: userNameuserId(selectedCollaboration,'User_name'),
          User_id: userNameuserId(selectedCollaboration,'User_id'),
          added_by: user_name,
          added_by_id: token,
          added_at: "20-08-2024",
        });
      
        addLeadCollaborators(item);
      
        // Reset the select element to the default option
        const selectElement = document.getElementById("inputGroupSelect04");
        selectElement.selectedIndex = 0;
      
        // Optionally, you could also reset the newCollaborators state if needed
        setSelectedCollaboration('');
      }


   /////////////////add new data //////////////////////////////////



   function addNewobj(){



    const temp = {...LeadData,...addNewData};


    updatelead(temp);

   }

   function refreshpage(){

    fetchLeadHistory(props.leadData.TicketId);
    fetchAssignedData_Task(props.leadData.TicketId);
    fetchAssignedData_Call(props.leadData.TicketId);

   }

   const renderStages = (listsop, LeadData) => {
    if (!listsop) return null;
  
    return (
      <div
        className="overflow-x-auto pe-3 pb-1"
        style={{ maxWidth: "1000px", overflowY: "hidden", whiteSpace: "nowrap" }}
      >
        {listsop.map((item, index) => {
          // Determine the stageClass based on LeadData
          const isActiveStage = LeadData === item.process_catagory;
          const stageClass = isActiveStage ? "bg-warning text-dark" : "bg-light text-dark";
          
  
          return (
            <div key={index} style={{ display: "inline-block" }}>
            <div className={`border-end ${stageClass}   chevron small_font border-dark p-2`} style={{ display: "flex", alignItems: "center" }}>
              {item.process_catagory}
              <div className="chevron-shape"></div>
            </div>
          </div>
          
          );
        })}
      </div>
    );
  };
  
  
  


  useEffect(() => {
    
    leadDetailsCall(props.leadData.TicketId);
    fetchUserData();
    refreshpage();
    //getSopProcess(LeadData.pipeline);
    getFormData();
    getOptionData();
    getCollaborators();
    
  }, [1]);



  
  useEffect(() => {

    getSopProcess(LeadData.pipeline);
  }, [LeadData.pipeline]);

  return (
<>
{detailtype == 'sopboard'?<>
<Offcanvas.Header closeButton>
          <Offcanvas.Title>  <span className={LeadData.priority=='low'?'text-primary'
    :LeadData.priority=='medium'?'text-warning'
  :'text-danger'
  
  }>
  <i className="bi bi-circle-fill"></i>
</span> {LeadData.title} (#{LeadData.TicketId}) {
  LeadData.lead_status == 'Won'? <span className="badge bg-success">Won</span>

  :LeadData.lead_status == 'Lost'? <span className="badge bg-danger">Lost</span>
  :LeadData.lead_status == 'Closed'? <span className="badge bg-dark">Closed</span>
  :<span className="badge bg-primary">Active</span>
}
          </Offcanvas.Title>
         
        </Offcanvas.Header>
        <Offcanvas.Body>
        
        <div className="container-fluid">
          
        <div className="row">
        <div className="col-4">
        
        <div
    className="overflow-auto  pe-3"
    style={{ maxWidth: "1000px", maxHeight: "100%" }}
  >
<div className="accordion mb-3">
<div className="accordion-item border border-secondary">
  <h2 className="accordion-header" id="headingleadcollapse1">
    <div
      className="accordion-button bg-dark text-white"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#leadcollapse1"
      aria-expanded="true"
      aria-controls="leadcollapse1"
    >
      <i className="bi bi-person-fill"></i> &nbsp; Lead Details &nbsp; &nbsp; &nbsp; &nbsp;<div className="text-secondary me-md-1" type="button"  onClick={()=>refreshpage()}>
  <i className="bi bi-arrow-clockwise"></i> Refresh
</div>&nbsp; &nbsp;
<div className="text-secondary me-md-1" type="button"  onClick={()=>showModel(LeadData)}>
<i className="bi bi-share-fill"></i> Share
</div>
    </div>
  </h2>
  <div
    id="leadcollapse1"
    className="accordion-collapse border collapse show"
    aria-labelledby="headingleadcollapse1"
    data-bs-parent="#accordionExample"
  >
   
<table className="table table-striped">

<tbody>

<tr>
<td><i className="bi bi-person-circle"></i> Contact Person</td><td>:</td>
<td>  <EditableTextField
             data={{ contactPerson: LeadData.contactPerson }}
            handleBlur={handleUpdate}
             keyname="contactPerson"
             placeholder="Contact person"
           /></td>
</tr>
<tr>
<td><i className="bi bi-building"/> Organization</td><td>:</td>
<td> <EditableTextField
             data={{ organization: LeadData.organization }}
            handleBlur={handleUpdate}
             keyname="organization"
             placeholder="Organization"
           /></td>
</tr>
<tr>
<td><i className="bi bi-phone-fill"></i> Phone</td><td>:</td>
<td>  <EditableTextField
             data={{ phone: LeadData.phone }}
            handleBlur={handleUpdate}
             keyname="phone"
             placeholder="phone"
             type='array'
           /></td>
</tr>
<tr>
<td><i className="bi bi-envelope-fill"></i> Email</td><td>:</td>
<td>   <EditableTextField
             data={{ email: LeadData.email }}
            handleBlur={handleUpdate}
             keyname="email"
             placeholder="email"
             type='array'
           /> </td>
</tr>
<tr>
<td><i className="bi bi-fan"></i> Priority</td><td>:</td>
<td><EditableTextField
           data={{ priority: LeadData.priority }}
          handleBlur={handleUpdate}
           keyname="priority"
           placeholder="priority"
           type='select'
           options={prioritytype}
         /></td>
</tr>
<tr>
<td><i className="bi bi-funnel"></i> Pipeline</td><td>:</td>
<td><b>{LeadData.pipeline}</b> <span className="badge bg-dark cursor" onClick={()=>handleShow('changesop')} >Change{" "}
<OverlayTrigger
placement="bottom"
delay={{ show: 250, hide: 400 }}
overlay={renderTooltip('You want to move this lead to a diffrent process.')}
>
<i className="bi bi-info-circle-fill text-light"></i>
</OverlayTrigger>
</span>

</td>
</tr>
<tr>
<td><i className="bi bi-geo-fill"></i> Current Stage</td><td>:</td>
<td><b> 
<div className="dropdown">
<span className="badge bg-light text-dark cursor"id={'dropdownMenuLink' + LeadData.Id}
data-bs-toggle="dropdown" ><i className="bi bi-pencil-square"></i> {LeadData.pipelineCurrentStage}</span> 
<ul className="dropdown-menu" aria-labelledby={'dropdownMenuLink' + LeadData.Id}>

{listsop &&
      listsop?.map((item, index) => (
<>{

LeadData.pipelineCurrentStage != item.process_catagory?<><li>
<a className="dropdown-item" href="#" onClick={()=>updateOptions(item.process_catagory, 'pipelineCurrentStage' )}>
{item.process_catagory}
</a>
</li></>:null
}
</>

        ))}


</ul>
</div></b></td>
</tr>
<tr>
  <td><i className="bi bi-signpost"></i> Progress</td><td>:</td>
  <td align='left'><div className="progress border border-dark progress-bar-striped" style={{ width: '250px',height: '20px' }}><div
    className="progress-bar progress-bar progress-bar-striped progress-bar-animated bg-dark"
    role="progressbar"
    style={{ width: `${calculatesopPercentage(LeadData.pipeline, LeadData.pipelineCurrentStage)}%` }}
    aria-valuenow={calculatesopPercentage(LeadData.pipeline, LeadData.pipelineCurrentStage)}
    aria-valuemin={0}
    aria-valuemax={100}
  >
    {calculatesopPercentage(LeadData.pipeline, LeadData.pipelineCurrentStage)} %
  </div>
</div></td>
</tr>
<tr>
<td><i className="bi bi-bezier2"></i> Lead Source</td><td>:</td>
<td>{LeadData.Lead_Source}</td>
</tr>
<tr>
<td><i className="bi bi-cash-coin"></i> Value</td><td>:</td>
<td>
<td> <b><EditableTextField
             data={{ value: LeadData.value }}
            handleBlur={handleUpdate}
             keyname="value"
             placeholder="Value"
           /> </b></td>
<td className="text-secondary">  <EditableTextField
           data={{ currency: LeadData.currency }}
          handleBlur={handleUpdate}
           keyname="currency"
           placeholder="Currency"
           type='select'
           options={currencyname}
         /></td>          

         </td>
</tr>
<tr>
<td><i className="bi bi-calendar"></i> Expected closing</td><td>:</td>
<td><EditableTextField
             data={{ expectedClosingDate: LeadData.expectedClosingDate }}
            handleBlur={handleUpdate}
             keyname="expectedClosingDate"
             type='date'
             placeholder="Expected Closing Date"
           /></td>
</tr>
<tr>
<td><i className="bi bi-calendar-check"></i> Added at</td><td>:</td>
<td>{LeadData.addedAt}</td>
</tr>
</tbody>
</table>
  </div>
</div>
</div>

<div className="accordion mb-3">
<div className="accordion-item border border-secondary">
  <h2 className="accordion-header" id="headingleadcollapse2">
    <button
      className="accordion-button bg-light text-dark"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#leadcollapse2"
      aria-expanded="true"
      aria-controls="leadcollapse2"
    >
      <i className="bi bi-person-badge"></i> &nbsp; Collaborators
    </button>
  </h2>
  <div
    id="leadcollapse2"
    className="accordion-collapse border collapse show"
    aria-labelledby="headingleadcollapse2"
    data-bs-parent="#accordionExample"
  >





<div className="container">
<div className="pt-2 pb-2" align='right'>
    <span className="badge bg-light border border-dark cursor text-dark"   data-bs-toggle="collapse"
      data-bs-target="#collapseCollaborators"
      aria-expanded="false"
      aria-controls="collapseCollaborators"><i className="bi bi-plus-lg"></i> Add member</span>
    </div>
    <>
  <div className="collapse" id="collapseCollaborators">
    <div className="card card-body border-0 mb-2">
    <div className="input-group">
    <select
  className="form-select border-dark"
  id="inputGroupSelect04"
  aria-label="Example select with button addon"
  value={selectedCollaboration}
  onChange={(e) => setSelectedCollaboration(e.target.value)}
>
  <option value="">Choose member...</option>
  {unselectedMembers &&
        unselectedMembers.map((item, key) => (
          <option key={key} value={`${item.id}-${item.user_name}`}>
            {item.user_name}
          </option>
        ))}
</select>
  <button className="btn btn-dark" onClick={insertCollaborators} type="button">
  <i className="bi bi-plus"></i>
  </button>
</div>

    </div>
  </div>
</>
  
  {oldCollaborators.length?<><mark>Added team members </mark></>:null}
  {Array.isArray(oldCollaborators) && oldCollaborators.length > 0 ? (
  <>
    <mark>Added team members</mark>
  </>
) : null}


  
<ul className="list-group">
 
  {oldCollaborators &&
                                          oldCollaborators?.map((item,key) => (
                                            <li className="list-group-item d-flex justify-content-between align-items-center"><i className="bi bi-person-fill"></i> {item.User_name} <button class="btn btn-sm btn-transparent" type="button" onClick={()=>delLeadCollaborators(item.User_id)}><i className="bi bi-x-lg"></i></button></li>
                                          ))}
  
</ul>
</div>
<br/>
   
   

  </div>
</div>
</div>

<div className="accordion mb-3">
<div className="accordion-item border border-secondary">
  <h2 className="accordion-header" id="headingleadcollapse3">
    <button
      className="accordion-button bg-light text-dark"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#leadcollapse3"
      aria-expanded="true"
      aria-controls="leadcollapse3"
    >
      <i className="bi bi-file-text-fill"></i> &nbsp; Other Details
    </button>
  </h2>
  <div
    id="leadcollapse3"
    className="accordion-collapse border collapse show"
    aria-labelledby="headingleadcollapse3"
    data-bs-parent="#accordionExample"
  >
   <table className="table table-striped">

<tbody>
{Object.entries(excludeArray)?.map(([key, value]) => {
  const typeOptions = getTypeAndOptions(key);
  console.log(typeOptions,LeadData[key]);
  return (
    <tr key={key}>
      <td><i className="bi bi-circle-fill"></i> {key}</td>
      <td>:</td>
      <td>
        <div className="inline-components">
          <DatabasedAction input={LeadData[key]} />
          <EditableTextField
            data={{ [key]: LeadData[key] }}
            handleBlur={handleUpdate}
            keyname={key}
            type={typeOptions.type}  // Using the safely retrieved type
            placeholder={key}
          />
        </div>
      </td>
    </tr>
  );
})}

</tbody>
</table>

  </div>
</div>
</div>



{filteredArray.length != 0?
<div className="accordion mb-3">
<div className="accordion-item border border-secondary">
  <h2 className="accordion-header" id="headingleadcollapse4">
    <button
      className="accordion-button bg-light text-dark"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#leadcollapse4"
      aria-expanded="true"
      aria-controls="leadcollapse4"
    >
      <i className="bi bi-plus-lg"></i> &nbsp; Add information
    </button>
  </h2>
  <div
    id="leadcollapse4"
    className="accordion-collapse border collapse show"
    aria-labelledby="headingleadcollapse4"
    data-bs-parent="#accordionExample"
  >
   <table className="table table-striped">

<tbody>

{filteredArray
  .filter(item => {
    return (
      !item.setting ||
      item.setting === "All SOPs" ||
      (item.setting === "Custom SOPs" && item.selectedSops.includes(LeadData.pipeline))
    );
  })
  .map((item, index) => (
   <>
      <tr>
        <td>{item.name}</td>
        <td>:</td>
        <td>
          {item.type === "select" ? (
            <select
              className="form-select form-select-sm cursor"
              id="inputGroupSelect04"
              name={item.name}
              value={addNewData[item.name]}
              onChange={handleChange}
              aria-label="Example select with button addon"
            >
              <option>Select {item.name}</option>
              {item.options?.map((item1, index) => (
                <option key={index} value={item1}>
                  {item1}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={item.type}
              name={item.name}
              className="form-control form-control-sm"
              value={addNewData[item.name]}
              onChange={handleChange}
              placeholder={item.name}
            />
          )}
        </td>
      </tr>
      </>
  ))}


</tbody>
</table>



{filteredArray.length != 0?<div className="mb-1">&nbsp;&nbsp;<button type="button" className="btn btn-dark btn-sm" onClick={addNewobj}><i className="bi bi-plus"></i> Add</button></div>
:null}
<br/>

  </div>
</div>
</div>

:null}

</div>
          </div>
          <div className="col-8">

          <ul className="nav justify-content-between">
          <li className="nav-item">
  <>
  <Dropdown>
    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
    <i className="bi bi-person-badge"></i> {removeEverythingBeforeComma(LeadData.owner, props.leadData)} - Owner&nbsp;
    </Dropdown.Toggle>

    <Dropdown.Menu as={CustomMenu}>
      
      {feed &&
                                          feed?.map((item,key) => (
                                            <Dropdown.Item eventKey={key+1} onClick={()=>updateOptions(item.id + "," + item.user_name, 'owner' )}><i className="bi bi-person-fill"></i> {item.user_name}-{item.role}</Dropdown.Item>
                                          ))}
    </Dropdown.Menu>
  </Dropdown>
  
</>
  </li>
  <li className="nav-item">
  <>
  <button type="button" className={LeadData.lead_status == ''?"btn btn-primary bg-gradient btn-sm" :"btn btn-outline-primary  btn-sm"} onClick={()=>updateOptions('', 'lead_status' )}>
    <b>Active</b>
  </button>&nbsp;
  <button type="button" className={LeadData.lead_status == 'Won'?"btn btn-success bg-gradient btn-sm" :"btn btn-outline-success  btn-sm"} onClick={()=>updateOptions('Won', 'lead_status' )}>
    <b>Won</b>
  </button>&nbsp;
  <button type="button" className={LeadData.lead_status == 'Lost'?"btn btn-danger bg-gradient btn-sm" :"btn btn-outline-danger  btn-sm"} onClick={()=>updateOptions('Lost', 'lead_status' )}>
  <b>Lost</b>
  </button>&nbsp;
  <button type="button" className={LeadData.lead_status == 'Closed'?"btn btn-dark bg-gradient btn-sm" :"btn btn-outline-dark  btn-sm"} onClick={()=>updateOptions('Closed', 'lead_status' )}>
  <b>Closed</b>
  </button>
</>
  </li>
  <li className="nav-item">
  <div className="dropdown">
  <a
    className="btn btn-transparent btn-sm dropdown-toggle"
    href="#"
    role="button"
    id="dropdownMenuLink"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="bi bi-eye-fill"></i> Visibleto - <b>{LeadData.visibleTo}</b>
  </a>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
 
    <li>
      <a className="dropdown-item cursor" onClick={()=>updateOptions('All users', 'visibleTo' )}>
      <i className="bi bi-people-fill"></i> All users
      </a>
    </li>
    <li>
      <a className="dropdown-item cursor" onClick={()=>updateOptions('Item owner', 'visibleTo' )}>
      <i className="bi bi-person-fill"></i> Item owner
      </a>
    </li>
    <li>
      <a className="dropdown-item cursor" onClick={()=>updateOptions('Only admin', 'visibleTo' )}>
      <i className="bi bi-person-badge"></i> Only admin
      </a>
    </li>
  </ul>
</div>




  </li>
 
</ul>
<br/>

<div className="row">
  <div className="col-6">

{props.leadInstruction.Instructions != ''?
<div className="text-secondary mb-3">Instructions: {props.leadInstruction.Instructions &&
                      props.leadInstruction.Instructions?.map((item, index) => (
                        <>
                        {item.blocktype=='call'?   <><span className="badge bg-primary border border-dark cursor" onClick={() => {setModalShow(true); setModalType('call'); setModalItem({ 'nextcall':item.blockname,
           'contactno':LeadData.phone[0],
           'email':LeadData.email[0],
           'customer':LeadData.contactPerson,
           'calltype':"asdasdsa",
           'tid':LeadData.TicketId}) }}><i className="bi bi-telephone-fill"></i> {item.blockname}
   </span>&nbsp;</>:item.blocktype=='task'? <><span className="badge bg-info text-dark border border-dark cursor" onClick={() => {setModalShow(true); setModalType('task'); setModalItem({'taskname':item.blockname}) }}><i className="bi bi-bicycle"></i> {item.blockname}
   </span>&nbsp;</>:null} 
                        </>
                      ))}  
</div>
:null}


<Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size = 'xl'
        backdrop="static"
        aria-labelledby="example-custom-modal-styling-title"

      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          
{modalShowType == 'call'?
<h5><i className="bi bi-phone-fill" /> Smart call session <i className="bi bi-arrow-down"></i></h5>
:modalShowType == 'task'?
<h5><i className="bi bi-bicycle" /> Smart task session <i className="bi bi-arrow-down"></i></h5>
:null}
          
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
         {modalShowType == 'call'?<Call 
           data={modalShowItem}
         
         />
         
       :modalShowType == 'task'?<>
       <Task
       data={modalShowItem}
       />
       </>
      :null }
         


        </Modal.Body>
      </Modal>

      
   

                        



                  

 

<>
<>
  <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        className="nav-link active"
        id="lead_history-tab"
        data-bs-toggle="tab"
        data-bs-target="#lead_history"
        type="button"
        role="tab"
        aria-controls="lead_history"
        aria-selected="true"
      >
        <i className="bi bi-clock-history"></i> History
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="lead_notes-tab"
        data-bs-toggle="tab"
        data-bs-target="#lead_notes"
        type="button"
        role="tab"
        aria-controls="lead_notes"
        onClick={getLeadNotes}
        aria-selected="false"
      >
        <i className="bi bi-pencil-square"></i> Notes
      </button>
    </li>
    {/* <li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="lead_attachment-tab"
        data-bs-toggle="tab"
        data-bs-target="#lead_attachment"
        type="button"
        role="tab"
        aria-controls="lead_attachment"
        aria-selected="false"
      >
        <i className="bi bi-file-earmark"></i> Attachments
      </button>
    </li> */}
  </ul>
  <div className="tab-content" id="myTabContent">
    <div
      className="tab-pane fade show active"
      id="lead_history"
      role="tabpanel"
      aria-labelledby="lead_history-tab"
    >
      <br/>
      <div className="events">
  {LeadHistory &&
                    LeadHistory?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={"knob"}
                          />

                          <div className="card bg-light border-dark">
                            <div className="card-body">
                              <div className="row mb-2">
                                <div className="col-2">
                                  <img 
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-10">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called {item.customer_name ==''?<b>{item.contact_number}</b> :<b>{item.customer_name}</b> } the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={"knob"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row mb-2">
                                <div className="col-2">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-10">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                          
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={"knob"}
                          />

                          <div className="card bg-info bg-gradient border-dark">
                            <div className="card-body">
                              <div className="row mb-2">
                                <div className="col-2">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-10">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                     <div className="line" />
  </div>
    </div>
    <div
      className="tab-pane fade"
      id="lead_notes"
      role="tabpanel"
      aria-labelledby="lead_notes-tab"
    >
      

<br/>
      <div className="editor-container">
      <CKEditor
        editor={ClassicEditor}
        data={newnotes}
        onChange={(event, editor) => {
          const data = editor.getData();
          setnewnotes(data);
        }}
        onReady={editor => {
          // You can store the "editor" and use it when it is needed.
          console.log('Editor is ready to use!', editor);
        }}
      />
      <div  className="pt-2">
      <button className="btn btn-sm btn-dark" onClick={insertNotes} type="button">
  Submit
  </button>
      </div>
    </div>
   
      <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-4">
  <button className="btn btn-sm btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNotes" aria-expanded="false" aria-controls="collapseNotes" >
  <i className="bi bi-clock-history"></i> Notes History
  </button>
  
</div>


<div className="collapse" id="collapseNotes">
{oldnotes?.map((item, index)=>(<><div className="card mb-2">
  <div className="card-body"> <div dangerouslySetInnerHTML={{ __html: item.notes }} /></div>
</div></>))}
</div>
    </div>
    <div
      className="tab-pane fade"
      id="lead_attachment"
      role="tabpanel"
      aria-labelledby="lead_attachment-tab"
    >
    <br/>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">

  <button className="btn btn-sm btn-secondary" type="button">
  <i className="bi bi-cloud-arrow-up-fill"></i> Upload files
  </button>
</div>


<br/>
<table className="table">
  <thead>
    <tr>
      <th scope="col">File name</th>
      <th scope="col">Uploaded by</th>
      <th scope="col">Date</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Bank statement - 2MB
        <br/>

      </td>
      <td>Rahul Sharma</td>
      <td>04-02-2024</td>
      <td> <DatabasedAction input={"https://outgrow.co/blog/wp-content/uploads/2016/07/Nonsalesyguide-Draft1.pdf"} /></td>
      
    </tr>
  </tbody>
</table>

  
.
    </div>
  </div>
</>

    
   
  

</>
 


    </div>

  <div className="col-6">
    
 {renderStages(listsop,LeadData.pipelineCurrentStage)}
    <br/>

  <div className="d-grid gap-2 d-md-flex justify-content-md-between">
    <div> <button data-bs-toggle="collapse"
                      data-bs-target="#collapseInstruction_desc"
                      aria-expanded="false"
                      aria-controls="collapseInstruction_desc"
                      className="btn btn-sm border-secondary cursor"
                    >
                      <i className="bi bi-justify-left"></i> Stage Description <i className="bi bi-caret-down-fill"></i>
                    </button></div>
 
<div><Link
                  to={"/call?tid=" + LeadData.TicketId }
                ><button className="btn btn-dark btn-sm me-md-1" type="button">
  <i className="bi bi-phone"></i> Call
  </button></Link>
  
  <button className="btn btn-secondary border-dark btn-sm me-md-1" type="button" onClick={()=>handleShow('interaction')}>
  <i className="bi bi-plus-circle"></i> Interaction
  </button>

  <>
  {/* Example single danger button */}
  <div className="btn-group">
    <button
      type="button"
      className="btn btn-light btn-sm border-dark dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i className="bi bi-alarm"></i> Assign
    </button>
    <ul className="dropdown-menu">
      <li className="cursor">
        <a className="dropdown-item" onClick={()=>handleShow('call')}>
        <i className="bi bi-plus"></i> Add Call
        </a>
      </li>
      <li className="cursor">
        <a className="dropdown-item" onClick={()=>handleShow('task')}>
        <i className="bi bi-bicycle"></i> Tasks
        </a>
      </li>
    </ul>
  </div> 
</></div>
  




</div>

<br/>
<div className="collapse" id="collapseInstruction_desc">
    <div className="card card-body">
    {props.leadInstruction.process_desc}
    </div>
    <br/>
  </div>

<div className="text-secondary">Assigned call and tasks:</div>
<br/>
{
  Call_History == '' && Task_History == ''?<div align='center'><img
  src={welldone}
  height={300}
  /><p><br/>Calls & tasks are not assigned or completed</p><br/></div>
  :
  <><div className="card bg-dark border-dark shadow">
       
  <div class="card-header text-white">
  <i className="bi bi-phone-fill"></i> Calls
</div>
  <table className="table table-light table-striped">
<tbody>
{Call_History &&
            Call_History?.map((item, index) => (
              
              <tr>
<td> 
<i className="bi bi-person-circle"></i>&nbsp;
                  <b className="link-dark">
                  {item.customer_name==""?<>{
                   item.contact_number

                  }</>:<>{item.customer_name}</>
            }  {returncontact(item)}
                  </b>
                  <br/>{item.next_call_script_category}
                </td>
              


<td align='right'>
{item.call_again_date!=""?alterCallBack(item.call_again_date):null}
<br/>{
                item.Contact_Source == "" || item.Contact_Source == null ? null : (
                  <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                    <i className="bi bi-bezier2"></i> 
                  </OverlayTrigger>
                  
                )
               }&nbsp;
                  <a
                    href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                    target="whatsapp"
                  >
                    <i className="bi bi-whatsapp"></i>
                  </a>&nbsp;
                 
                  <Link
                    to={
                      `/call?nextcall=` +
                      item.next_call_script_category +
                      "&contactno=" +
                      item.contact_number +
                      "&email=" +
                      item.email_address +
                      "&customer=" +
                      item.customer_name +
                      tocallvalue(item) +
                      "&callId=" +
                      item._id + "&tid=" +
                      item.TicketId
                    }
                  >
                    <i className="bi bi-telephone-fill"></i>
                  </Link>&nbsp;
                  
                </td>
</tr>
              ))}


</tbody>
</table>
  </div><br/>
       <div className="card bg-dark border-dark shadow">
            <div class="card-header text-white">
            <i className="bi bi-bicycle"></i> Tasks
</div>
            <table className="table table-light table-striped">
      <tbody>
      {Task_History &&
                      Task_History?.map((item, index) => (
                        item.taskStatus != "Completed" && item.taskStatus != "Incomplete"? 
                        <tr>
                        <td><i className="bi bi-person-circle"></i>&nbsp; <b className="link-dark">
                            {item.customer_name==""?<>{
                             item.contact_number

                            }</>:<>{item.customer_name}</>
                      }  {returncontact(item)}
                            </b>
                            <br/>{item.taskHeading}
                        </td>
                        <td align="right">
                        {item.taskStatus == "Assigned" ? (
                                  <span className="badge bg-primary">
                                    <i className="bi bi-person-lines-fill"></i>
                                    Assigned
                                  </span>
                                ) : item.taskStatus == "In Progress" ? (
                                  <span className="badge bg-warning text-dark">
                                    <i className="bi bi-fan"></i> In Progress
                                  </span>
                                ) : item.taskStatus == "Incomplete" ? (
                                  <span className="badge bg-danger">
                                    <i className="bi bi-x-lg"></i> Incomplete
                                  </span>
                                ) : item.taskStatus == "Completed" ? (
                                  <span className="badge bg-success">
                                    <i className="bi bi-check-lg"></i>
                                    Completed
                                  </span>
                                ) : null}
                        <br/>{alterCallBack(item.lastDate)}
                          </td>
                       
                      </tr>  
                        :null                         
                      ))}
      </tbody>
    </table>
            </div></>
}

       



  </div>
   
</div>




          
          </div>
        </div>
        </div>


        <Modal show={showLink} onHide={handleCloseLink}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Share Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Ticketlink tid={tid} ftid={ftid} org={org}  />
        </Modal.Body>
      </Modal>


        </Offcanvas.Body>
        <>
        <Modal show={show} onHide={handleClose} size="lg">
       
        <SetTasks typeData={itype} ticket={LeadData.TicketId} leadData={LeadData}/>   
      </Modal>
      
    </>
    <ToastContainer />
        </>:<><div
    className="overflow-auto border-end"
    style={{ maxWidth: "1000px", maxHeight: "800px" }}
  >

<table className="table table-striped">

<tbody>

<tr>
<td><i className="bi bi-person-circle"></i> Contact Person</td>
<td>  <EditableTextField
             data={{ contactPerson: LeadData.contactPerson }}
            handleBlur={handleUpdate}
             keyname="contactPerson"
             placeholder="Contact person"
           /></td>
</tr>
<tr>
<td><i className="bi bi-building"/> Organization</td>
<td> <EditableTextField
             data={{ organization: LeadData.organization }}
            handleBlur={handleUpdate}
             keyname="organization"
             placeholder="Organization"
           /></td>
</tr>
<tr>
<td><i className="bi bi-phone-fill"></i> Phone</td>
<td>  <EditableTextField
             data={{ phone: LeadData.phone }}
            handleBlur={handleUpdate}
             keyname="phone"
             placeholder="phone"
             type='array'
           /></td>
</tr>
<tr>
<td><i className="bi bi-envelope-fill"></i> Email</td>
<td>   <EditableTextField
             data={{ email: LeadData.email }}
            handleBlur={handleUpdate}
             keyname="email"
             placeholder="email"
             type='array'
           /> </td>
</tr>
<tr>
<td><i className="bi bi-fan"></i> Priority</td>
<td><EditableTextField
           data={{ priority: LeadData.priority }}
          handleBlur={handleUpdate}
           keyname="priority"
           placeholder="priority"
           type='select'
           options={prioritytype}
         /></td>
</tr>
<tr>
<td><i className="bi bi-funnel"></i> Pipeline</td>
<td><b>{LeadData.pipeline}</b> 
{/* <span className="badge bg-dark cursor" onClick={()=>handleShow('changesop')} >Change{" "}
<OverlayTrigger
placement="bottom"
delay={{ show: 250, hide: 400 }}
overlay={renderTooltip('You want to move this lead to a diffrent process.')}
>
<i className="bi bi-info-circle-fill text-light"></i>
</OverlayTrigger>
</span> */}

</td>
</tr>
<tr>
<td><i className="bi bi-geo-fill"></i> Current Stage</td>
<td><b> 
<div className="dropdown">
<span className="badge bg-light text-dark cursor"id={'dropdownMenuLink' + LeadData.Id}
data-bs-toggle="dropdown" ><i className="bi bi-pencil-square"></i> {LeadData.pipelineCurrentStage}</span> 
<ul className="dropdown-menu" aria-labelledby={'dropdownMenuLink' + LeadData.Id}>

{listsop &&
      listsop?.map((item, index) => (
<>{

LeadData.pipelineCurrentStage != item.process_catagory?<><li>
<a className="dropdown-item" href="#" onClick={()=>updateOptions(item.process_catagory, 'pipelineCurrentStage' )}>
{item.process_catagory}
</a>
</li></>:null
}
</>

        ))}


</ul>
</div></b></td>
</tr>
<tr>
<td><i className="bi bi-bezier2"></i> Lead Source</td>
<td>{LeadData.Lead_Source}</td>
</tr>
<tr>
<td><i className="bi bi-cash-coin"></i> Value</td>
<td>
<td> <b><EditableTextField
             data={{ value: LeadData.value }}
            handleBlur={handleUpdate}
             keyname="value"
             placeholder="Value"
           /> </b></td>
<td className="text-secondary">  <EditableTextField
           data={{ currency: LeadData.currency }}
          handleBlur={handleUpdate}
           keyname="currency"
           placeholder="Currency"
           type='select'
           options={currencyname}
         /></td>          

         </td>
</tr>
<tr>
<td><i className="bi bi-calendar"></i> Expected closing</td>
<td><EditableTextField
             data={{ expectedClosingDate: LeadData.expectedClosingDate }}
            handleBlur={handleUpdate}
             keyname="expectedClosingDate"
             type='date'
             placeholder="Expected Closing Date"
           /></td>
</tr>
<tr>
<td><i className="bi bi-calendar-check"></i> Added at</td>
<td>{LeadData.addedAt}</td>
</tr>


{Object.entries(excludeArray)?.map(([key, value]) => (
      <tr key={key}>
          <td><i className="bi bi-circle"></i> {key}</td>
   
          <td><div className="inline-components"> <DatabasedAction 
           input={LeadData[key]}
         /> <EditableTextField
             data={{ [key]: LeadData[key] }}
            handleBlur={handleUpdate}
             keyname={key}
             type={'text'}
            //  type={getTypeAndOptions(LeadData[key])['type']}

             placeholder={key}
           />
          
         </div>
           
         </td>
          
      </tr>
  ))}
<br/>


{filteredArray.length != 0?

<thead>
<tr>
<th>Add information <i className="bi bi-arrow-down"></i></th>
</tr>
</thead>
:null}

{filteredArray?.map((item,index)=>(<>

<tr>
<td> {item.name}</td>
<td>{

item.type =='select'?
<>
<select
  className="form-select form-select-sm cursor"
  id="inputGroupSelect04"
  name={item.name}
  value={addNewData[item.name]}
onChange={handleChange}
  aria-label="Example select with button addon"
>
<><option>Select {item.name}</option></>
  {
    item.options?.map((item1,index)=>(
      <><option value={item1}>{item1}</option></>
    ))

  }

                                      
  </select>
</>

:<><input
type={item.type}
name={item.name}
class="form-control form-control-sm"
value={addNewData[item.name]}
onChange={handleChange}
placeholder={item.name}
/></>

}


</td>
</tr>


    

</>))}

<br/>
{filteredArray.length != 0?<>&nbsp;<button type="button" className="btn btn-dark btn-sm" onClick={addNewobj}>Add</button></>
:null}





</tbody>
</table>
</div></> }
</>

   
  );
};

export default LeadDetails;
