/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EditableTextField from "./editableTextField";
import axios from "axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const org = localStorage.getItem("org");
const Auth = localStorage.getItem("Auth");

const dominname = window.location.origin;



export default function shareLink({
  item,
  changeShareLinkActiveDeavtive,
  generteShareLink,
  index,
  deleteManageSource,
}) {
  const navigator = useNavigate();



  const activeDeactive = (item) => {
    if (item.IsShared == true) {
      return (
        <i
          title="Activate Link"
          className="i bi-toggle2-on"
          style={{ cursor: "pointer" }}
          onClick={() => changeShareLinkActiveDeavtive(item.Source, false)}
        ></i>
      );
    } else if (item.IsShared == false) {
      return (
        <i
          title="Activate Link"
          className="i bi-toggle2-off"
          style={{ cursor: "pointer" }}
          onClick={() => changeShareLinkActiveDeavtive(item.Source, true)}
        ></i>
      );
    }
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function onBlurUpdatePassword(data, password) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post(
        "/Set_PassWord_forSource",
        {
          "Contact_Source": data.Source,
          "password": password 
        },
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
        }
        
      })
      .catch((err) => console.log(err));
  }
  
  

  return (
    <>
      <li
        key={index}
        className="list-group-item d-flex justify-content-between align-items-start text-dark border border-dark"
      >
        <div className="ms-2 me-auto">{item.Source}</div>
        {activeDeactive(item)}
        <i
          title="Share Link"
          class="bi bi-share"
          style={{ marginLeft: "8px", cursor: "pointer" }}
          data-bs-toggle="collapse"
          data-bs-target={`#collapseExample${index}`}
          aria-expanded="false"
          aria-controls={`collapseExample${index}`}
        ></i>
        <i
          title="Delete"
          className="bi bi-trash3-fill"
          style={{ marginLeft: "8px", cursor: "pointer" }}
          onClick={() => deleteManageSource(item.Source)}
        ></i>
      </li>
      <div class="collapse" id={`collapseExample${index}`}>
        <div class="card card-body">
          <b><i className="bi bi-file-text"></i> View Link <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Share the link below to give your sources current lead status.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></b>
    <input className="form-control form-control-sm" type="text" value={dominname+"/viewsource?q="+item.Source+"&og="+org} aria-label=".form-control-sm example" />
        
          <b><i className="bi bi-shield-lock"></i> Set Password (min 6 character):</b>
          <EditableTextField
      data={item}
      handleBlur={onBlurUpdatePassword}
      keyname="password"
      placeholder="Access Key"

    />

          <b><i className="bi bi-person-plus"></i> LeadCapture Link <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Share the link below to collect lead from your sources.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger></b>
    <input className="form-control form-control-sm" type="text" value={dominname+"/leadcapture?q="+item.Source+"&og="+org} aria-label=".form-control-sm example" />
          
         
          
        </div>
      </div>
    </>
  );
}
