import React, { useRef, useState, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip1 from 'react-bootstrap/Tooltip';
import {
  ReactFlow,
  MiniMap,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import Sidebar from '../components/Sidebar';

const renderTooltip1 = (props) => (
  <Tooltip1 id="button-Tooltip1" {...props}>
    {props}
  </Tooltip1>
);

function Automation() {
  const initialNodes = [
    {
        "id": "1",
        "type": "Check Subscription Status",
        "position": {
            "x": 256,
            "y": 68.8999862670899
        },
        "data": {
            "label": "Check Subscription Status (30 days before expiry)"
        },
        "measured": {
            "width": 150,
            "height": 76
        },
        "selected": false,
        "dragging": false
    },
    {
        "id": "2",
        "type": "Send Reminder",
        "position": {
            "x": 252,
            "y": 208
        },
        "data": {
            "label": "Send First Reminder with OTT Offer"
        },
        "measured": {
            "width": 150,
            "height": 58
        },
        "selected": false,
        "dragging": false
    },
    {
        "id": "3",
        "type": "Check Renewal Status",
        "position": {
            "x": 51.028764001780985,
            "y": 383.6794917749519
        },
        "data": {
            "label": "Check Renewal Status (15 days before expiry)"
        },
        "measured": {
            "width": 150,
            "height": 58
        },
        "selected": false,
        "dragging": false
    },
    {
        "id": "4",
        "type": "Send Reminder",
        "position": {
            "x": 409.3548567662465,
            "y": 511.2903295254795
        },
        "data": {
            "label": "Send Second Reminder"
        },
        "measured": {
            "width": 150,
            "height": 40
        },
        "selected": false,
        "dragging": false
    },
    {
        "id": "5",
        "type": "Check Renewal Status",
        "position": {
            "x": 52.33868728032871,
            "y": 633.7096812390138
        },
        "data": {
            "label": "Check Renewal Status (5 days before expiry)"
        },
        "measured": {
            "width": 150,
            "height": 58
        },
        "selected": false,
        "dragging": false
    },
    {
        "id": "6",
        "type": "Send Reminder",
        "position": {
            "x": 446,
            "y": 760
        },
        "data": {
            "label": "Send Final Reminder"
        },
        "measured": {
            "width": 150,
            "height": 40
        },
        "selected": true,
        "dragging": false
    },
    {
        "id": "7",
        "type": "Check Renewal Status",
        "position": {
            "x": 250,
            "y": 900
        },
        "data": {
            "label": "Check Renewal Status Post-Expiration"
        },
        "measured": {
            "width": 150,
            "height": 58
        }
    },
    {
        "id": "8",
        "type": "Send Notification",
        "position": {
            "x": 0,
            "y": 1050
        },
        "data": {
            "label": "Send Grace Period Notification"
        },
        "measured": {
            "width": 150,
            "height": 58
        }
    },
    {
        "id": "9",
        "type": "Output",
        "position": {
            "x": 500,
            "y": 1050
        },
        "data": {
            "label": "Renewal Confirmation Sent"
        },
        "measured": {
            "width": 150,
            "height": 58
        }
    },
    {
        "id": "10",
        "type": "Check Status",
        "position": {
            "x": 250,
            "y": 1200
        },
        "data": {
            "label": "Check Status Post-Grace Period"
        },
        "measured": {
            "width": 150,
            "height": 58
        }
    },
    {
        "id": "11",
        "type": "Deactivate Subscription",
        "position": {
            "x": 250,
            "y": 1350
        },
        "data": {
            "label": "Deactivate Subscription & Send Final Notification"
        },
        "measured": {
            "width": 150,
            "height": 76
        }
    },
    {
        "id": "12",
        "type": "Schedule Call",
        "position": {
            "x": 250,
            "y": 1500
        },
        "data": {
            "label": "Schedule Call with Executive"
        },
        "measured": {
            "width": 150,
            "height": 58
        }
    },
    {
        "id": "13",
        "type": "Output",
        "position": {
            "x": 250,
            "y": 1650
        },
        "data": {
            "label": "Call Scheduled"
        },
        "measured": {
            "width": 150,
            "height": 40
        }
    }
];
  const initialEdges = [
    {
        "source": "1",
        "target": "2",
        "id": "xy-edge__1-2"
    },
    {
        "source": "2",
        "target": "3",
        "id": "xy-edge__2-3"
    },
    {
        "source": "3",
        "target": "4",
        "id": "xy-edge__3-4"
    },
    {
        "source": "4",
        "target": "5",
        "id": "xy-edge__4-5"
    },
    {
        "source": "5",
        "target": "6",
        "id": "xy-edge__5-6"
    },
    {
        "source": "6",
        "target": "7",
        "id": "xy-edge__6-7"
    },
    {
        "source": "7",
        "target": "8",
        "id": "xy-edge__7-8"
    },
    {
        "source": "7",
        "target": "9",
        "id": "xy-edge__7-9"
    },
    {
        "source": "8",
        "target": "10",
        "id": "xy-edge__8-10"
    },
    {
        "source": "10",
        "target": "11",
        "id": "xy-edge__10-11"
    },
    {
        "source": "11",
        "target": "12",
        "id": "xy-edge__11-12"
    },
    {
        "source": "12",
        "target": "13",
        "id": "xy-edge__12-13"
    }
];

  
  let id = 0;
  const getId = () => `dndnode_${id++}`;

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { screenToFlowPosition, setViewport, fitView, toObject } = useReactFlow();

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [],
  );

  const examples = {"Renewal Boost" : {
    nodes : [
      {
          "id": "1",
          "type": "Check Subscription Status",
          "position": {
              "x": 256,
              "y": 68.8999862670899
          },
          "data": {
              "label": "Check Subscription Status (30 days before expiry)"
          },
          "measured": {
              "width": 150,
              "height": 76
          },
          "selected": false,
          "dragging": false
      },
      {
          "id": "2",
          "type": "Send Reminder",
          "position": {
              "x": 252,
              "y": 208
          },
          "data": {
              "label": "Send First Reminder with OTT Offer"
          },
          "measured": {
              "width": 150,
              "height": 58
          },
          "selected": false,
          "dragging": false
      },
      {
          "id": "3",
          "type": "Check Renewal Status",
          "position": {
              "x": 51.028764001780985,
              "y": 383.6794917749519
          },
          "data": {
              "label": "Check Renewal Status (15 days before expiry)"
          },
          "measured": {
              "width": 150,
              "height": 58
          },
          "selected": false,
          "dragging": false
      },
      {
          "id": "4",
          "type": "Send Reminder",
          "position": {
              "x": 409.3548567662465,
              "y": 511.2903295254795
          },
          "data": {
              "label": "Send Second Reminder"
          },
          "measured": {
              "width": 150,
              "height": 40
          },
          "selected": false,
          "dragging": false
      },
      {
          "id": "5",
          "type": "Check Renewal Status",
          "position": {
              "x": 52.33868728032871,
              "y": 633.7096812390138
          },
          "data": {
              "label": "Check Renewal Status (5 days before expiry)"
          },
          "measured": {
              "width": 150,
              "height": 58
          },
          "selected": false,
          "dragging": false
      },
      {
          "id": "6",
          "type": "Send Reminder",
          "position": {
              "x": 446,
              "y": 760
          },
          "data": {
              "label": "Send Final Reminder"
          },
          "measured": {
              "width": 150,
              "height": 40
          },
          "selected": true,
          "dragging": false
      },
      {
          "id": "7",
          "type": "Check Renewal Status",
          "position": {
              "x": 250,
              "y": 900
          },
          "data": {
              "label": "Check Renewal Status Post-Expiration"
          },
          "measured": {
              "width": 150,
              "height": 58
          }
      },
      {
          "id": "8",
          "type": "Send Notification",
          "position": {
              "x": 0,
              "y": 1050
          },
          "data": {
              "label": "Send Grace Period Notification"
          },
          "measured": {
              "width": 150,
              "height": 58
          }
      },
      {
          "id": "9",
          "type": "Output",
          "position": {
              "x": 500,
              "y": 1050
          },
          "data": {
              "label": "Renewal Confirmation Sent"
          },
          "measured": {
              "width": 150,
              "height": 58
          }
      },
      {
          "id": "10",
          "type": "Check Status",
          "position": {
              "x": 250,
              "y": 1200
          },
          "data": {
              "label": "Check Status Post-Grace Period"
          },
          "measured": {
              "width": 150,
              "height": 58
          }
      },
      {
          "id": "11",
          "type": "Deactivate Subscription",
          "position": {
              "x": 250,
              "y": 1350
          },
          "data": {
              "label": "Deactivate Subscription & Send Final Notification"
          },
          "measured": {
              "width": 150,
              "height": 76
          }
      },
      {
          "id": "12",
          "type": "Schedule Call",
          "position": {
              "x": 250,
              "y": 1500
          },
          "data": {
              "label": "Schedule Call with Executive"
          },
          "measured": {
              "width": 150,
              "height": 58
          }
      },
      {
          "id": "13",
          "type": "Output",
          "position": {
              "x": 250,
              "y": 1650
          },
          "data": {
              "label": "Call Scheduled"
          },
          "measured": {
              "width": 150,
              "height": 40
          }
      }
  ],
edges:  [
  {
      "source": "1",
      "target": "2",
      "id": "xy-edge__1-2"
  },
  {
      "source": "2",
      "target": "3",
      "id": "xy-edge__2-3"
  },
  {
      "source": "3",
      "target": "4",
      "id": "xy-edge__3-4"
  },
  {
      "source": "4",
      "target": "5",
      "id": "xy-edge__4-5"
  },
  {
      "source": "5",
      "target": "6",
      "id": "xy-edge__5-6"
  },
  {
      "source": "6",
      "target": "7",
      "id": "xy-edge__6-7"
  },
  {
      "source": "7",
      "target": "8",
      "id": "xy-edge__7-8"
  },
  {
      "source": "7",
      "target": "9",
      "id": "xy-edge__7-9"
  },
  {
      "source": "8",
      "target": "10",
      "id": "xy-edge__8-10"
  },
  {
      "source": "10",
      "target": "11",
      "id": "xy-edge__10-11"
  },
  {
      "source": "11",
      "target": "12",
      "id": "xy-edge__11-12"
  },
  {
      "source": "12",
      "target": "13",
      "id": "xy-edge__12-13"
  }
] },

"Partner Churn" : {
  nodes : [
    {
        "id": "1",
        "type": "Check Sales",
        "position": {
            "x": 250,
            "y": 0
        },
        "data": {
            "label": "Check Sales"
        },
        "measured": {
            "width": 150,
            "height": 40
        }
    },
    {
        "id": "2",
        "type": "Condition",
        "position": {
            "x": 250,
            "y": 150
        },
        "data": {
            "label": "If Sales Below Average"
        },
        "measured": {
            "width": 150,
            "height": 40
        }
    },
    {
        "id": "3",
        "type": "Action",
        "position": {
            "x": 150,
            "y": 300
        },
        "data": {
            "label": "Change Partner Status to At Risk"
        },
        "measured": {
            "width": 200,
            "height": 40
        }
    },
    {
        "id": "4",
        "type": "Action",
        "position": {
            "x": 250,
            "y": 450
        },
        "data": {
            "label": "Assign a Visit to the CRO"
        },
        "measured": {
            "width": 200,
            "height": 40
        }
    },
    {
        "id": "5",
        "type": "Action",
        "position": {
            "x": 350,
            "y": 600
        },
        "data": {
            "label": "Send How to Sell More Training Module to partner"
        },
        "measured": {
            "width": 250,
            "height": 40
        }
    }
],
edges: [
  {
      "source": "1",
      "target": "2",
      "id": "xy-edge__1-2"
  },
  {
      "source": "2",
      "target": "3",
      "id": "xy-edge__2-3"
  },
  {
      "source": "3",
      "target": "4",
      "id": "xy-edge__3-4"
  },
  {
      "source": "4",
      "target": "5",
      "id": "xy-edge__4-5"
  }
]



}

}

  console.log(nodes,'nodes');

  console.log(edges,'edges');

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type}` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [screenToFlowPosition],
  );

  const saveFlow = useCallback(() => {
    const flow = toObject();
    localStorage.setItem('flow', JSON.stringify(flow));
    alert('Flow saved successfully!');
  }, [toObject]);

  const restoreFlow = useCallback(() => {
    const flow = JSON.parse(localStorage.getItem('flow'));
    if (flow) {
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setViewport(flow.viewport);
      fitView();
    }
  }, [setNodes, setEdges, setViewport, fitView]);

  const removeNode = useCallback((nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
  }, [setNodes, setEdges]);

  const [view, setView] = useState('Renewal Boost');

  function changestatus(item) {
    setView(item);

    const selectedExample = examples[item] || { nodes: [], edges: [] };
    
    setNodes(selectedExample.nodes);
    setEdges(selectedExample.edges);
}

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 p-0 border-end">

          <div className="p-2 ">
          <mark>
        <i className="bi bi-bezier"></i> Create Automation
                  </mark>       
          <div className="input-group input-group-sm mb-3">
        <input type="text" name='sopname'
                     className="form-control  border-dark" placeholder="Automation name" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button  className="btn bg-dark text-white" type="button" id="button-addon2"><i className="bi bi-plus"></i></button>
      </div>
                 </div>
                 
        
            <h5 className='p-2'><i className="bi bi-fan"></i> All automation</h5>
            <ol className="list-group rounded-0 border-top">
              <li
                className={`list-group-item d-flex justify-content-between align-items-start border-0 ${
                  view === 'Renewal Boost' ? 'text-white bg-primary' : ''
                }`}
                onClick={() => changestatus('Renewal Boost')}
              >
                <div className="ms-2 me-auto">Renewal Boost</div>
                <i
                  title="View"
                  className="bi bi-eye-fill"
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                ></i>
              </li>
              <li
                className={`list-group-item d-flex justify-content-between align-items-start border-0 ${
                  view === 'Partner Churn' ? 'text-white bg-primary' : ''
                }`}
                onClick={() => changestatus('Partner Churn')}
              >
                <div className="ms-2 me-auto">Partner Churn</div>
                <i
                  title="View"
                  className="bi bi-eye-fill"
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                ></i>
              </li>
              <li
                className={`list-group-item d-flex justify-content-between align-items-start border-0 ${
                  view === 'Visit Planner' ? 'text-white bg-primary' : ''
                }`}
                onClick={() => changestatus('Visit Planner')}
              >
                <div className="ms-2 me-auto">Visit Planner</div>
                <i
                  title="View"
                  className="bi bi-eye-fill"
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                ></i>
              </li>
              <li
                className={`list-group-item d-flex justify-content-between align-items-start border-0 ${
                  view === 'Partner Distribution' ? 'text-white bg-primary' : ''
                }`}
                onClick={() => changestatus('Partner Distribution')}
              >
                <div className="ms-2 me-auto">Partner Distribution</div>
                <i
                  title="View"
                  className="bi bi-eye-fill"
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                ></i>
              </li>
              
            </ol>
          </div>
          <div className="col-10">
          <div className='row border-bottom'>
            <div className='col-10'><p className='text-secondary'>All nodes: 
            <div
        className="overflow-x-auto pe-3 pb-1"
        style={{ maxWidth: "100%", overflowY: "hidden", whiteSpace: "nowrap" }}
      > {nodes.map((node) => (
      <><span key={node.id} class="badge bg-dark cursor" onClick={() => removeNode(node.id)}>{node.data.label} <i class="bi bi-x"></i> </span>  &nbsp;</>
        
      ))} </div>
    </p></div>
            <div className='col-2'>  <div className="mt-3 mb-2">
              <button className="btn btn-success border border-dark btn-sm me-2" onClick={saveFlow}>
              <i className="bi bi-cloud-check-fill"></i> Save
              </button>
              <button className="btn btn-warning border border-dark text-dark btn-sm" onClick={restoreFlow}>
              <i className="bi bi-arrow-clockwise"></i> Restore
              </button>
            </div></div>
            </div>
            <div className="dndflow">
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  fitView
                >
                  <MiniMap />
                  <Background />
                  <Controls />
                </ReactFlow>
              </div>
              <Sidebar />
            </div>
          
          </div>
        </div>
      </div>

   
    </>
  );
}

export default () => (
  <ReactFlowProvider>
    <Automation />
  </ReactFlowProvider>
);
