import React, { Component, useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  getSourceListData,
} from "../redux/slices/dataSlice";

import {
  uploadConatctFile,
  UploadLeadFile
} from "../redux/slices/dataSlice";

const admin = localStorage.getItem("admin");

const org = localStorage.getItem("org");
const access_token = localStorage.getItem("Auth");
const Auth = localStorage.getItem("Auth");
const uid = localStorage.getItem("token");
const user_name = localStorage.getItem("user_name");





const Importlead = () => {

  let formdata = new FormData();

  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const user_id = localStorage.getItem("token");

  function updataFile(e) {
    const file = e.target.files[0];

    formdata.append("file", file);
    formdata.append("organisation_id", org);
    formdata.append("added_by_user", user_id);
    formdata.append("added_by_username", user_name);
    //setfile({...filedata , [e.target.name]:e.target.file[0]})
  }


  function getSpreedSheet() {
    const headers = {
      "Content-Type": "application/json",
      responseType: "blob",
    };

    axios
      .get("/GenerateNewLeadFile?".concat("organisation_id=", org, "&Contact_Source=",''), headers)
      .then((data) => {
        if (data.status == "200") {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "AddLeads.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
       
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function uploadSpreedSheet() {
    setDisabled(true);

    dispatch(
      UploadLeadFile({
        access_token,
        data: formdata,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setDisabled(false);
        toast.success("Successfully uploaded!", {
          position: "bottom-left",
          autoClose: 1000,
        });
        document.getElementById("close1").click();
      }
    });
  }

  return (

    <div className="modal-content">
    <div className="modal-header border-0">
      <h5 className="modal-title" id="exampleModalLabel">
        Upload leads with spreadsheet
      </h5>
      <button
        id="close1"
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body">
      <div className="row">
        <div className="col-5" align='left'>
          <img
            src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/4.+Data+Upload.svg"
            height={150}
          />
        </div>
        <div className="col-7" align='left'>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-1-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
            />
          </svg>&nbsp;
          Download new spreadsheet.
     
          &nbsp;&nbsp;<span style={{ cursor: "pointer" }}
            onClick={() => getSpreedSheet()} className="badge bg-dark"><i className="bi bi-cloud-arrow-down"></i> Click Here</span>
            
          <br />
          <br />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-2-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
          </svg>&nbsp;
          Add your data accordion to instructions.
          <br />
          <br />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-3-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
          </svg>&nbsp;
          Upload spreadsheet.
          <br />
          <br />
          <div className='col-10'>
          <div className="input-group input-group-sm">
  <input
     type="file"
     onChange={updataFile}
     name="file"
    className="form-control border border-dark"
    id="inputGroupFile04"
    aria-describedby="inputGroupFileAddon04"
    aria-label="Upload"
  />
  <button
    className={formdata.file ==''?"btn btn-outline-secondary":"btn btn-dark" }
    type="button"
    onClick={uploadSpreedSheet}
            disabled={disabled}
    id="inputGroupFileAddon04"
  >
      {disabled ? (
              <div className="d-flex align-items-center">
                <strong>Loading...</strong>
                <div
                  className="spinner-border ms-auto"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "4px !important",
                  }}
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            ) : (
              "Submit"
            )}
  </button>
</div>
</div>

        

          
  
        </div>
      </div>
    </div>
  </div>

   
  );
};

export default Importlead;
