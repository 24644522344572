import React, { Component, useEffect, useState} from "react";
import logo from "../media/Sales.gif";
import axios from "axios";
import avatar from "../media/147144.png";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  NavLink,
  useNavigate,
} from "react-router-dom";
import Score from "../components/Score";
import AddLead from "../components/AddLead";
import {
  getrolepermission
} from "../redux/slices/navSlice";
import {
  getselect_options
} from "../redux/slices/leadSlice";





const organisation_name = localStorage.getItem("organisation_name");
const Auth = localStorage.getItem("Auth");
const admin = localStorage.getItem("admin");
const user_name = localStorage.getItem("user_name");
const org = localStorage.getItem("org");



export default function Navbar() {
  let navigation = useNavigate();

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [details, setdetails] = useState(true);

  
  const [coin, setCoin] = useState('n/a');

  const [search, setSearch] = useState({phoneno:""});

  const [personsearchresult, setpersonsearchresult ] = useState([]);

  const [showAddLead, setShowAddLead] = useState(false);

  const [leadData, setLeadData] = useState(null); 


  const [SetupStatus, setSetupStatus] = useState();

  const handleCloseAddLead = () => setShowAddLead(false);
  const handleShowAddLead = () => setShowAddLead(true);

  function changeSearch(e) {
    setSearch({ ...search, [e.target.name]: e.target.value });

  }

  function masknumber(item){

    if( details == true){
     return item;
   
    }else{
     return item.slice(0, -3).concat('*** ');
    }
     } 


  function Logout() {
    window.localStorage.clear();
    window.location.reload(true);
    window.location.replace("/");
  }

  function fetchOrgData() {
  
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_organisation?organisation_id=".concat(org), { headers })
      .then((data) => {
        if (data.status == "200") {
          setdetails(data.data[0].Numbermassing);
          let targetEvent = data.data[0].SetUp_status.find(event => event.Event_Name === "design_sop");
          setSetupStatus(targetEvent.iscompleted);
        }
      
      })
      .catch((err) => console.log(err));
  }
  
  function SearchHome(){

    searchPerson( search.phoneno, 'to_search' );

  }

  function searchPerson( ph, itemtype ) {

    let searchcall;

    if(itemtype == 'to_search'){

      searchcall =  {
        "to_search":ph,
        "customer_name":"",
        "contact_number":"",
        "email_address":""
    };

    }

    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/search_for_contact',searchcall, { headers })
      .then((data) => {
        if (data.status == "200") {

          setpersonsearchresult(data.data);
         
        }
        
      })
      .catch((err) => console.log(err));
    
    
  }

  function getpermission() {
    dispatch(getrolepermission({Auth })).then((res) => {
      if (res.payload.status == 200) {
        console.log(res.payload.data);
      }
    });
    
  }

  function getOptionData() {
    dispatch(getselect_options({org })).then((res) => {
      if (res.payload.status == 200) {
        // setSelectoptions(res.payload.data);
      }
    });
    
  }
  


  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  }


  function gettokenvalue() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_sunday_currency_count", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          let x = Math.round(data.data);

          setCoin(x);


        }
      })
      .catch((err) => {
        console.log(err);
        
      })
      .catch((err) => console.log(err));
  }


  useEffect(() => {

    if (admin == "true") {
      gettokenvalue();
    }

    getpermission();
    getOptionData();

    fetchOrgData();
 
 
  }, [1]);

  if (admin == "true") {
    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <div className="navbar-brand" href="#">
            <NavLink className="nav-link active" aria-current="page" to="/">
              <img src={logo} alt="" width="45" height="32"></img>
            </NavLink> 
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <NavLink className="nav-link active" aria-current="page" to="/">
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/etqbfrgp.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Home
                </NavLink> 
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/pqxdilfs.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Records
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <NavLink className="dropdown-item" to="/sopboard">
                    <i className="bi bi-person-fill"></i> Lead management
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/callrecord">
                    <i className="bi bi-journal-text"></i> Calls & tasks
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/taskboard">
                    <i className="bi bi-bicycle"></i> Task board
                      
                    </NavLink> 
                  </li>
                 
                
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/report"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/hbwqfgcf.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Report
                </NavLink> 
              </li>
             

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                   <lord-icon
                    src="https://cdn.lordicon.com/tdxlyuid.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Setup 
                  {/* {SetupStatus == false?<div className="click-me-btn2"></div> :null} */}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <NavLink className="dropdown-item" to="/sop">
                    <i className="bi bi-bezier"></i> Process setup
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/soplab">
                    <i className="bi bi-journal-text"></i> Calls & tasks setup 
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/questions">
                    <i className="bi bi-question-circle"></i> Questions & FAQs 
                    </NavLink> 
                  </li>
                
                 
                
                </ul>
              </li>
              
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/training"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/kejpvrvr.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Training
                </NavLink> 
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/vvcugubt.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  More
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <NavLink className="dropdown-item" to="/learn">
                    <i className="bi bi-building"></i> University{" "}
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/datahub">
                      <i className="bi bi-cloud-fill"></i> Data Hub <span className="badge bg-danger">beta</span>{" "}
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/relax">
                      <i className="bi bi-brightness-high-fill"></i> Relax
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/personalizedtraining">
                      <i className="bi bi-person-workspace"></i> Personalized
                      Training
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/podcast">
                      <i className="bi bi-music-player-fill"></i> Podcast
                    </NavLink> 
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://blog.salessunday.com/"
                      target="_blank"
                    >
                      <i className="bi bi-laptop-fill"></i> Blog
                    </a>
                  </li>
                  <li>
                  <NavLink className="dropdown-item" to="/integrationlab">
                  <i className="bi bi-cpu-fill"></i> Integration Lab <span className="badge bg-danger">beta</span>
                    </NavLink> 
                  </li>
                  <li>
                  <NavLink className="dropdown-item" to="/integration">
                  <i className="bi bi-envelope-paper-fill"></i> Write Us
                    </NavLink> 
                  </li>
                </ul>
              </li>
            </ul>
           
            <button
                className="btn btn-dark me-2 btn-sm border border-dark"
                onClick={handleShowAddLead}
                type="submit"
              >
                {" "}
                <i className="bi bi-plus-lg"></i>
              </button>

            <NavLink to="/call">
            <button
                className="btn btn-light bg-gradient btn-sm me-2 border border-dark"
                type="submit"
              >
                {" "}
                <i className="bi bi-telephone-fill"></i>
              </button></NavLink> 

            <div className="d-flex">
            <div className="input-group input-group-sm ">
  <input
  name="phoneno"
  value={search.phoneno}
  onChange={changeSearch}
    type="text"
    className="form-control border-dark inside2"
    placeholder="Prospect Name/Number"
    aria-label="Recipient"
    aria-describedby="button-addon2"
  />
  <button
    type="button"
    onClick={SearchHome}
    className="btn btn-dark pop rounded-end"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="bi bi-search"></i>
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
  {personsearchresult &&
                          personsearchresult.map((item) => (<li>
      <a className="dropdown-item" href={`/callinfo?phoneno=${item.contact_number}`}>
      <i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}
      </a>
    </li>))}
   
 
  </ul>
</div>

      

              
            </div>

            &nbsp;&nbsp;
            
            <button
                className="btn btn-sm btn-warning bg-gradient me-2 border border-secondary"
                type="submit"
              >
                {" "}
                <i className="bi bi-cash"></i> <b>{coin}</b>
              </button>
          
            <div className="btn-group dropdown">
              <button
                className="btn btn-light dropdown-toggle border-0 bg-transparent"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle circle-pop"
                  src={generateavatar(user_name, "White", "#004aad")}
                  id="dropdownMenuButton1"
                  width="35"
                  height="35"
                ></img>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    className="nav-link active"
                    aria-current="page"
                    to="/profile"
                  >
                    <i className="bi bi-person"></i> Profile{" "}
                  </NavLink> 
                </li>
                <li>
                  <a
                    className="nav-link cursor active"
                    aria-current="page"
                    onClick={Logout}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-door-closed"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
           
            <lord-icon onClick={handleShow} src="https://cdn.lordicon.com/coowbsor.json" trigger="hover" style={{width: '35px', height: '35px'}}>
      </lord-icon>
      <Offcanvas show={show} onHide={handleClose} className="scorebg" >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">Score Board <i className="bi bi-controller"></i></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Score/>
        </Offcanvas.Body>
      </Offcanvas>


            <div className="btn-group dropstart">
              <button
                className="btn btn-light  border-0 bg-transparent"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <h2>
                  <i className="bi bi-headset"></i>
                </h2> */}
                <lord-icon
                  src="https://cdn.lordicon.com/dqxvvqzi.json"
                  trigger="hover"
                  style={{ width: "35px", height: "35px" }}
                ></lord-icon>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="mailto:support@salessunday.com"
                    target="_blank"
                  >
                    support@salessunday.com
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link cursor active"
                    aria-current="page"
                    style={{ cursor: "pointer" }}
                    target="_blank"
                    href="tel:8149920883"
                  >
                    +91 8149920883
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <Offcanvas show={showAddLead} onHide={handleCloseAddLead}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><i className="bi bi-plus-circle-fill"></i> Add Lead</Offcanvas.Title>
        </Offcanvas.Header>
        <AddLead leadData={leadData}/>
      </Offcanvas>
      </>
    );
  } else {
    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <div className="navbar-brand" href="#">
            <NavLink className="nav-link active" aria-current="page" to="/">
            <img src={logo} alt="" width="45" height="32"></img>
            </NavLink> 
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <NavLink className="nav-link active" aria-current="page" to="/">
                  <lord-icon
                    src="https://cdn.lordicon.com/etqbfrgp.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Home
                </NavLink> 
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/pqxdilfs.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Records
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <NavLink className="dropdown-item" to="/sopboard">
                    <i className="bi bi-person-fill"></i> Lead management
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/callrecord">
                    <i className="bi bi-journal-text"></i> Calls & tasks
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/taskboard">
                    <i className="bi bi-bicycle"></i> Task board
                      
                    </NavLink> 
                  </li>
                 
                
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/relax"
                >
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/rzdoyang.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Relax
                </NavLink> 
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/training"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/kejpvrvr.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Training
                </NavLink> 
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/notification"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/mdbykuhq.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  Notification
                </NavLink> 
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/vvcugubt.json"
                    trigger="hover"
                    style={{ width: "35px", height: "35px" }}
                  ></lord-icon>{" "}
                  More
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <NavLink className="dropdown-item" to="/learn">
                    <i className="bi bi-building"></i> University{" "}
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/personalizedtraining">
                      <i className="bi bi-person-workspace"></i> Personalized
                      Training
                    </NavLink> 
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/podcast">
                      <i className="bi bi-music-player-fill"></i> Podcast
                    </NavLink> 
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://blog.salessunday.com/"
                      target="_blank"
                    >
                      <i className="bi bi-laptop-fill"></i> Blog
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <button
                className="btn btn-dark me-2 btn-sm border border-dark"
                onClick={handleShowAddLead}
                type="submit"
              >
                {" "}
                <i className="bi bi-plus-lg"></i>
              </button>
            <NavLink to="/call">
            <button
                className="btn btn-light bg-gradient btn-sm me-2 border border-dark"
                type="submit"
              >
                {" "}
                <i className="bi bi-telephone-fill"></i>
              </button></NavLink> 
            <div className="d-flex">
            <div className="input-group input-group-sm ">
  <input
  name="phoneno"
  value={search.phoneno}
  onChange={changeSearch}
    type="text"
    className="form-control border-dark inside2"
    placeholder="Recipient's username"
    aria-label="Recipient's username"
    aria-describedby="button-addon2"
  />
  <button
    type="button"
    onClick={SearchHome}
    className="btn btn-dark pop rounded-end"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="bi bi-search"></i>
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
  {personsearchresult &&
                          personsearchresult.map((item) => (<li>
      <a className="dropdown-item" href={`/callinfo?phoneno=${item.contact_number}`}>
      <i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}
      </a>
    </li>))}
   
 
  </ul>
</div>

      

              
            </div>

            &nbsp;&nbsp;

            
            <div className="btn-group dropstart">
              <button
                className="btn btn-light dropdown-toggle border-0 bg-transparent"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  className="circle-pop circle-pop"
                  src={generateavatar(user_name, "White", "#004aad")}
                  id="dropdownMenuButton1"
                  width="35"
                  height="35"
                ></img>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    className="nav-link active"
                    aria-current="page"
                    to="/profile"
                  >
                    <i className="bi bi-person"></i> Profile{" "}
                  </NavLink> 
                </li>
                <li>
                  <a
                    className="nav-link active"
                    aria-current="page"
                    onClick={Logout}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-door-closed"></i> Logout
                  </a>
                </li>
              </ul>
            </div>

            <lord-icon onClick={handleShow} src="https://cdn.lordicon.com/coowbsor.json" trigger="hover" style={{width: '35px', height: '35px'}}>
      </lord-icon>
      <Offcanvas show={show} onHide={handleClose} className="scorebg">
        <Offcanvas.Header>
          <Offcanvas.Title className="text-white">Score Board <i className="bi bi-controller"></i></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Score/>
        </Offcanvas.Body>
      </Offcanvas>
            <div className="btn-group dropstart">
              <button
                className="btn btn-light  border-0 bg-transparent"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <h2>
                  <i className="bi bi-headset"></i>
                </h2> */}
                <lord-icon
                  src="https://cdn.lordicon.com/dqxvvqzi.json"
                  trigger="hover"
                  style={{ width: "35px", height: "35px" }}
                ></lord-icon>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="mailto:support@salessunday.com"
                    target="_blank"
                  >
                    support@salessunday.com
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link cursor active"
                    aria-current="page"
                    style={{ cursor: "pointer" }}
                    target="_blank"
                    href="tel:8149920883"
                  >
                    +91 8149920883
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <Offcanvas show={showAddLead} onHide={handleCloseAddLead}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Lead</Offcanvas.Title>
        </Offcanvas.Header>
        <AddLead leadData={leadData}/>
      </Offcanvas>
      </>
    );
  }
}
