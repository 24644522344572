import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, Link } from "react-router-dom";
import login from "../media/login.gif";
import img1 from "../media/1.webp";
import img2 from "../media/2.webp";
import img3 from "../media/3.webp";
import axios from "axios";
import Home from "./Home";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const organisation_name = localStorage.getItem("organisation_name");
const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const user_id = localStorage.getItem("token");


function DarkVariantExample() {
  return (
    <Carousel variant="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img1}
          
        />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img2}
         
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img3}
         
        />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}


export default function Login() {
  const [user_id_check, updateuser_id_check] = useState("");
  const [loginstate, updatloginstate] = useState("default");
  const tostMsg = () => {
    toast.success("Sign up Successful!", {
      position: "top-center",
    });
  };

  const tostMsg2 = (item) => {
    var item = item;

    toast.error(item, {
      position: "top-center",
    });
  };

  const tostWaitMsg = () => {
    toast.info("Please Wait", {
      position: "bottom-left",
    });
  };

  const [userData, setUserData] = useState({
    name: "",
    organisation_name: "",
    role: "Super Admin",
    title: "Manager",
    parent_Id:"",
    email: "",
    mobile: "",
    business:"",
    password: "",
  });
  const [usersOTP, setUserOTP] = useState({ userotp: "", userid: "" });
  const [emailOTP, setEmailOTP] = useState({ MailID: "" });
  const [newPassword, setnewPassword] = useState({
    newpassword1: "",
    newpassword2: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const businessTypes = [
    "Customer Support",
    "Staffing/HR companies",
    "Insurance companies",
    "Loan companies",
    "Startups",
    "E-commerce businesses",
    "SaaS businesses",
    "Consulting businesses",
    "B2B businesses",
    "Real estate agents",
    "Gyms",
    "E-learning businesses",
    "Software companies",
    "Creative agencies",
    "Freelancers",
    "Travel agencies",
    "Coaching businesses",
    "Consulting businesses (B2B)",
    "E-learning businesses (B2B)",
    "Web development businesses",
    "Digital marketing businesses",
    "Law firms",
    "Financial services firms",
    "Health and wellness businesses",
    "Other"

  ];

  const[globalSop, setGlobalSop] = useState([{"Customer Support" : [
    {
        process_catagory : "Query received", 
        process_desc:"Upon receiving a customer query or ticket, ensure prompt acknowledgment, acknowledging receipt of the query or ticket within [specified timeframe, e.g., 1 hour]",
    Instructions: []},
    {
        process_catagory : "Investigation and Solution Provision", 
        process_desc:"Assign the ticket/query to the appropriate department or individual based on the nature of the inquiry. Communicate with the customer, updating them on the progress and proposed resolution steps",
    Instructions: []},
    {
        process_catagory : "Resolution and Validation", 
        process_desc:"Implement the solution and ensure its effectiveness in resolving the customer's query. Validate the solution with the customer through follow-up communication or confirmation.",
    Instructions: []},
    {
        process_catagory : "Closure and Documentation", 
        process_desc:"Once the issue is resolved and validated by the customer, close the ticket/query in the system. Document the resolution steps and any additional notes for future reference.",
    Instructions: []},
    {
        process_catagory : "Blocker Handling (If Applicable)", 
        process_desc:"Prioritize and escalate the issue to the relevant authority or higher support level. Communicate transparently with the customer about the delay or challenges faced and provide an estimated resolution time.",
    Instructions: []},
    {
        process_catagory : "Customer Satisfaction Check", 
        process_desc:"Conduct a post-resolution satisfaction check with the customer to ensure their needs were met and assess their overall satisfaction with the support received.",
    Instructions: []}
]},
{"Staffing/HR companies" : [
  {
      process_catagory : "New lead", 
      process_desc:" This is a person who has expressed interest in your services, either by signing up for your newsletter, attending a webinar, or contacting you directly.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:" This is a lead who has been assessed and deemed to be a good fit for your services.",
  Instructions: []},
  {
      process_catagory : "Submitted to client", 
      process_desc:"This is a lead who has been submitted to a client for consideration.",
  Instructions: []},
  {
      process_catagory : "Interview scheduled", 
      process_desc:"This is a lead who has been scheduled to interview with a client.",
  Instructions: []},
  {
      process_catagory : "Offer made", 
      process_desc:"This is a lead who has been offered a job by a client.",
  Instructions: []},
  {
      process_catagory : "Offer accepted", 
      process_desc:"This is a lead who has accepted a job offer from a client.",
  Instructions: []},
  {
      process_catagory : "Placed", 
      process_desc:"This is a lead who has started their new job",
  Instructions: []}
]},
{"Insurance companies" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in your insurance products or services, either by filling out a form on your website, calling your office, or meeting with an agent.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your products or services.",
  Instructions: []},
  {
      process_catagory : "Quote provided", 
      process_desc:"This is a lead who has been provided with a quote for your products or services.",
  Instructions: []},
  {
      process_catagory : "Application submitted", 
      process_desc:"This is a lead who has submitted an application for your products or services.",
  Instructions: []},
  {
      process_catagory : "Policy issued", 
      process_desc:"This is a lead who has been issued an insurance policy.",
  Instructions: []},
  {
      process_catagory : "Active customer", 
      process_desc:"This is a customer who has an active insurance policy with you.",
  Instructions: []},

]},{"Loan companies" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in a loan from your company, either by filling out a form on your website, calling your office, or meeting with a loan officer.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for a loan from your company",
  Instructions: []},
  {
      process_catagory : "Application submitted", 
      process_desc:"This is a lead who has submitted an application for a loan from your company.",
  Instructions: []},
  {
      process_catagory : "Credit check", 
      process_desc:"This is a lead whose credit has been checked to determine their eligibility for a loan.",
  Instructions: []},

  {
      process_catagory : "Approval", 
      process_desc:"This is a lead who has been approved for a loan from your company.",
  Instructions: []},
  {
      process_catagory : "Funding", 
      process_desc:"This is a lead whose loan has been funded and they have received the money.",
  Instructions: []},

]},{"Startups" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in your product or service, either by signing up for your newsletter, attending a demo, or contacting you directly.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your product or service.",
  Instructions: []},
  {
      process_catagory : "Demo scheduled", 
      process_desc:"This is a lead who has been scheduled to demo your product or service.",
  Instructions: []},
  {
      process_catagory : "Demo completed", 
      process_desc:"This is a lead who has demoed your product or service.",
  Instructions: []},
  {
      process_catagory : "Trial started", 
      process_desc:"This is a lead who has started a trial of your product or service.",
  Instructions: []},
  {
      process_catagory : "Customer", 
      process_desc:"This is a lead who has converted into a paying customer.",
  Instructions: []},

]},{"E-commerce businesses" : [
  {
      process_catagory : "New visitor", 
      process_desc:"This is a person who has visited your website for the first time.",
  Instructions: []},
  {
      process_catagory : "Abandoned cart", 
      process_desc:"This is a visitor who has added items to their cart but left your website without completing their purchase.",
  Instructions: []},
  {
      process_catagory : "Returning visitor", 
      process_desc:"This is a visitor who has visited your website more than once.",
  Instructions: []},
  {
      process_catagory : "Customer", 
      process_desc:"This is a visitor who has made a purchase from your website.",
  Instructions: []},
  {
      process_catagory : "Loyal customer", 
      process_desc:"This is a customer who has made multiple purchases from your website or who has referred other customers to your website",
  Instructions: []},

]},{"SaaS businesses" : [
  {
      process_catagory : "Free trial user", 
      process_desc:"This is a person who has signed up for a free trial of your SaaS product.",
  Instructions: []},
  {
      process_catagory : "Paid user", 
      process_desc:"This is a user who has converted from a free trial user to a paid user.",
  Instructions: []},
  {
      process_catagory : "Churned user", 
      process_desc:"This is a user who has canceled their subscription to your SaaS product.",
  Instructions: []},

]},
{"Consulting businesses" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in your consulting services, either by signing up for your newsletter, attending a webinar, or contacting you directly.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your consulting services.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your consulting services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your consulting services.",
  Instructions: []},
  {
      process_catagory : "Project completed", 
      process_desc:"This is a client who has completed a project with you.",
  Instructions: []},

]},{"B2B businesses" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a business that has expressed interest in your products or services, either by filling out a form on your website, calling your office, or attending a trade show.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your products or services.",
  Instructions: []},
  {
      process_catagory : "Demo scheduled", 
      process_desc:"This is a lead that has been scheduled to demo your products or services.",
  Instructions: []},
  {
      process_catagory : "Demo completed", 
      process_desc:"This is a lead that has demoed your products or services.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your products or services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your products or services.",
  Instructions: []},
  {
      process_catagory : "Customer", 
      process_desc:"This is a business that is actively using your products or services.",
  Instructions: []},

]},{"Real estate agents" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in buying or selling a home, either by filling out a form on a real estate agent's website, calling their office, or attending an open house.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for the real estate agent's services.",
  Instructions: []},
  {
      process_catagory : "Showing scheduled", 
      process_desc:"This is a lead who has been scheduled to see a home with the real estate agent.",
  Instructions: []},
  {
      process_catagory : "Offer made", 
      process_desc:"This is a lead who has made an offer on a home.",
  Instructions: []},
  {
      process_catagory : "Offer accepted", 
      process_desc:"This is a lead whose offer on a home has been accepted.",
  Instructions: []},
  {
      process_catagory : "Closed sale", 
      process_desc:" This is a lead who has purchased a home with the real estate agent's assistance.",
  Instructions: []},

]},{"Gyms" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in joining a gym, either by filling out a form on a gym's website, calling their office, or visiting the gym in person.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for the gym's membership plans.",
  Instructions: []},
  {
      process_catagory : "Free trial scheduled", 
      process_desc:"This is a lead who has been scheduled for a free trial of the gym's facilities and services.",
  Instructions: []},
  {
      process_catagory : "Free trial completed", 
      process_desc:"This is a lead who has completed a free trial of the gym.",
  Instructions: []},
  {
      process_catagory : "Membership purchased", 
      process_desc:"This is a lead who has purchased a membership to the gym.",
  Instructions: []},
  {
      process_catagory : "Active member", 
      process_desc:"This is a member who is actively using the gym's facilities and services.",
  Instructions: []},

]},{"E-learning businesses" : [
  {
      process_catagory : "New visitor", 
      process_desc:"This is a person who has visited your website for the first time.",
  Instructions: []},
  {
      process_catagory : "Lead", 
      process_desc:"This is a visitor who has signed up for your email list or downloaded a freebie.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your paid course or membership.",
  Instructions: []},
  {
      process_catagory : "Customer", 
      process_desc:"This is a lead who has purchased your paid course or membership.",
  Instructions: []},
  {
      process_catagory : "Active student", 
      process_desc:"This is a customer who is actively engaged in your course or membership content.",
  Instructions: []},

]},{"Software companies" : [
  {
      process_catagory : "New visitor", 
      process_desc:"This is a person who has visited your website for the first time.",
  Instructions: []},
  {
      process_catagory : "Lead", 
      process_desc:"This is a visitor who has signed up for your email list or downloaded a free trial of your software.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your paid software subscription.",
  Instructions: []},
  {
      process_catagory : "Customer", 
      process_desc:"This is a lead who has purchased a paid subscription to your software.",
  Instructions: []},
  {
      process_catagory : "Active user", 
      process_desc:"This is a customer who is actively using your software.",
  Instructions: []},

]},{"Creative agencies" : [
  {
      process_catagory : "New lead", 
      process_desc:" This is a business that has expressed interest in your creative services, either by filling out a form on your website, calling your office, or attending a networking event.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your creative services.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your creative services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your creative services.",
  Instructions: []},
  {
      process_catagory : "Project completed", 
      process_desc:"This is a client who has completed a project with you.",
  Instructions: []},

]},{"Freelancers" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a potential client who has contacted you about your freelance services.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your freelance services.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your freelance services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your freelance services.",
  Instructions: []},
  {
      process_catagory : "Project completed", 
      process_desc:"This is a client who has completed a project with you.",
  Instructions: []},

]},{"Travel agencies" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in booking a trip, either by filling out a form on a travel agency's website, calling their office, or visiting the agency in person.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for the travel agency's services.",
  Instructions: []},
  {
      process_catagory : "Quote requested", 
      process_desc:" This is a lead who has requested a quote for a trip from the travel agency.",
  Instructions: []},
  {
      process_catagory : "Quote provided", 
      process_desc:"This is a lead who has been provided with a quote for a trip from the travel agency.",
  Instructions: []},
  {
      process_catagory : "Booking made", 
      process_desc:"This is a lead who has booked a trip with the travel agency.",
  Instructions: []},

]},{"Coaching businesses" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in your coaching services, either by signing up for your email list, downloading a freebie, or scheduling a consultation.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead who has been assessed and deemed to be a good fit for your coaching services.",
  Instructions: []},
  {
      process_catagory : "Sales call scheduled", 
      process_desc:"This is a lead that has been scheduled for a sales call with you.",
  Instructions: []},
  {
      process_catagory : "Sales call completed", 
      process_desc:"This is a lead that has completed a sales call with you.",
  Instructions: []},
  {
      process_catagory : "Package purchased", 
      process_desc:"This is a lead who has purchased a coaching package from you.",
  Instructions: []},

  {
      process_catagory : "Active client", 
      process_desc:"This is a client who is actively engaged in your coaching program.",
  Instructions: []},


]},
{"Consulting businesses (B2B)" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a business that has expressed interest in your consulting services, either by filling out a form on your website, calling your office, or attending a trade show.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your consulting services.",
  Instructions: []},
  {
      process_catagory : "Discovery call scheduled", 
      process_desc:"This is a lead that has been scheduled for a discovery call with you.",
  Instructions: []},
  {
      process_catagory : "Discovery call completed", 
      process_desc:"This is a lead that has completed a discovery call with you.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your consulting services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your consulting services.",
  Instructions: []},
  {
      process_catagory : "Client", 
      process_desc:"This is a business that is actively engaged in a consulting project with you.",
  Instructions: []},

]},{"E-learning businesses (B2B)" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a business that has expressed interest in your e-learning products or services, either by filling out a form on your website, calling your office, or attending a trade show.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your e-learning products or services.",
  Instructions: []},
  {
      process_catagory : "Demo scheduled", 
      process_desc:"This is a lead that has been scheduled to demo your e-learning products or services.",
  Instructions: []},
  {
      process_catagory : "Demo completed", 
      process_desc:"This is a lead that has demoed your e-learning products or services.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your e-learning products or services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your e-learning products or services.",
  Instructions: []},
  {
      process_catagory : "Customer", 
      process_desc:"This is a business that is actively using your e-learning products or services.",
  Instructions: []},

]},{"Web development businesses" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a business that has expressed interest in your web development services, either by filling out a form on your website, calling your office, or attending a networking event.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your web development services.",
  Instructions: []},
  {
      process_catagory : "Discovery call scheduled", 
      process_desc:"This is a lead that has been scheduled for a discovery call with you.",
  Instructions: []},
  {
      process_catagory : "Discovery call completed", 
      process_desc:"This is a lead that has completed a discovery call with you.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your web development services.",
  Instructions: []},
  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your web development services.",
  Instructions: []},
  {
      process_catagory : "Client", 
      process_desc:"This is a business that is actively working with you to develop their website.",
  Instructions: []},

]},{"Digital marketing businesses" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a business that has expressed interest in your digital marketing services, either by filling out a form on your website, calling your office, or attending a trade show.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your digital marketing services.",
  Instructions: []},
  {
      process_catagory : "Consultation scheduled", 
      process_desc:"This is a lead that has been scheduled for a consultation with you.",
  Instructions: []},
  {
      process_catagory : "Consultation completed", 
      process_desc:"This is a lead that has completed a consultation with you.",
  Instructions: []},
  {
      process_catagory : "Proposal sent", 
      process_desc:"This is a lead to whom you have sent a proposal for your digital marketing services.",
  Instructions: []},

  {
      process_catagory : "Contract signed", 
      process_desc:"This is a lead who has signed a contract for your digital marketing services.",
  Instructions: []},

  {
      process_catagory : "Client", 
      process_desc:"This is a business that is actively working with you to implement their digital marketing campaigns.",
  Instructions: []},



]},{"Law firms" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person or business that has expressed interest in your law firm's services, either by filling out a form on your website, calling your office, or attending a legal seminar.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your law firm's services.",
  Instructions: []},
  {
      process_catagory : "Consultation scheduled", 
      process_desc:"This is a lead that has been scheduled for a consultation with one of your attorneys.",
  Instructions: []},
  {
      process_catagory : "Consultation completed", 
      process_desc:"This is a lead that has completed a consultation with one of your attorneys.",
  Instructions: []},
  {
      process_catagory : "Retainer signed", 
      process_desc:"This is a lead who has signed a retainer agreement with your law firm.",
  Instructions: []},
  {
      process_catagory : "Client", 
      process_desc:"This is a person or business that is actively working with your law firm on a legal matter.",
  Instructions: []},

]},
{"Financial services firms" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person or business that has expressed interest in your financial services firm's services, either by filling out a form on your website, calling your office, or attending a financial planning seminar.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your financial services firm's services.",
  Instructions: []},
  {
      process_catagory : "Consultation scheduled", 
      process_desc:"This is a lead that has been scheduled for a consultation with one of your financial advisors.",
  Instructions: []},
  {
      process_catagory : "Consultation completed", 
      process_desc:"This is a lead that has completed a consultation with one of your financial advisors.",
  Instructions: []},
  {
      process_catagory : "Account opened", 
      process_desc:"This is a lead who has opened an account with your financial services firm.",
  Instructions: []},
  {
      process_catagory : "Client", 
      process_desc:"This is a person or business that is actively working with your financial services firm to manage their finances.",
  Instructions: []},

]},{"Health and wellness businesses" : [
  {
      process_catagory : "New lead", 
      process_desc:"This is a person who has expressed interest in your health and wellness business, either by filling out a form on your website, calling your office, or attending a health seminar.",
  Instructions: []},
  {
      process_catagory : "Qualified lead", 
      process_desc:"This is a lead that has been assessed and deemed to be a good fit for your health and wellness business.",
  Instructions: []},
  {
      process_catagory : "Consultation scheduled", 
      process_desc:"This is a lead that has been scheduled for a consultation with one of your health professionals.",
  Instructions: []},
  {
      process_catagory : "Consultation completed", 
      process_desc:"This is a lead that has completed a consultation with one of your health professionals.",
  Instructions: []},
  {
      process_catagory : "Treatment plan started", 
      process_desc:"This is a lead who has started a treatment plan with your health and wellness business.",
  Instructions: []},
  {
      process_catagory : "Active client", 
      process_desc:"This is a client who is currently working with your health and wellness business to achieve their health and wellness goals.",
  Instructions: []},

]}]);


  function updataData(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }

  function updataOTP(e) {
    setUserOTP({ ...usersOTP, [e.target.name]: e.target.value });
  }
  function updatemailOTP(e) {
    setEmailOTP({ ...emailOTP, [e.target.name]: e.target.value });
  }

  function updatnewPassword(e) {
    setnewPassword({ ...newPassword, [e.target.name]: e.target.value });
  }


  const [userlog, setloginData] = useState({ email: "", password: "" });
  const [errorLogMessage, setLogErrorMessage] = useState("");

  function loginData(e) {
    setloginData({ ...userlog, [e.target.name]: e.target.value });
  }

  function sendData(e) {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post("/user_registration", userData, headers)
      .then((data) => {
        if (data.status == "200") {
          tostMsg();
          localStorage.setItem("token", data.data.id);
          localStorage.setItem("user_email", data.data.email);
          localStorage.setItem("title", data.data.title);
          localStorage.setItem(
            "organisation_name",
            data.data.organisation_name
          );
          localStorage.setItem("user_name", data.data.user_name);
          localStorage.setItem("joining_date", data.data.joining_date);
          localStorage.setItem("role", data.data.role);
          localStorage.setItem("admin", data.data.admin);
          localStorage.setItem("org", data.data.organisation_id);
          localStorage.setItem("Auth", data.data.jwttoken);
          localStorage.setItem("Setup", false);

          addScript(data.data.organisation_id, data.data.organisation_name);
          addTask(data.data.organisation_id);

          sendOTP("register", data.data.id);

          usersOTP.userid = data.data.id;

          updateuser_id_check(data.data.id);

          /////////Add logic to set prebuilt sop
          if(userData.business !='' || userData.business != "Other"){

            submitsop(data.data.jwttoken, userData.business)

          }
          

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }


  /////////Addd sops

  function submitsop(Auth,sopname) {
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/Add_sop_name", {"SopName": [
        sopname
      ]}, { headers })
      .then((data) => {
        if (data.status == "200") {
  
          saveSopProcess(Auth,sopname, globalSop[0][sopname])
  
        }
        
      })
      .catch((err) => console.log(err));
  }

  function saveSopProcess(Auth, item1, item2) {
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/SetUp_the_sop_process",{
        "SopName": item1,
        "process": item2
      }, { headers })
      .then((data) => {
        if (data.status == "200") {
  
        
  
        }
        
      })
      .catch((err) => console.log(err));
  }

  function addScript(item1, item2) {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "/add_script_types/",
        {
          organisation_id: item1,
          organisation_name: item2,
          script_types: [""],
        },
        headers
      )
      .then((data) => {
        if (data.status == "200") {
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }

  function addTask(item) {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "/add_taskHeadings/",
        {
          organisation_id: item,
          headings: [
            {
              heading: "",
              description: "",
            },
          ],
        },
        headers
      )
      .then((data) => {
        if (data.status == "200") {
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }

  function checkOTP(e) {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "/varify_otp?userid=".concat(
          usersOTP.userid,
          "&userotp=",
          usersOTP.userotp
        ),
        headers
      )
      .then((data) => {
        if (data.status == "200") {
          window.location.replace("/setup");
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }

  function sendloginData(e) {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post("/user_login/", userlog, headers)
      .then((data) => {
        if (data.status == "200") {
          localStorage.setItem("token", data.data.id);
          localStorage.setItem("user_email", data.data.email);
          localStorage.setItem(
            "organisation_name",
            data.data.organisation_name
          );
          localStorage.setItem("user_name", data.data.user_name);
          localStorage.setItem("joining_date", data.data.joining_date);
          localStorage.setItem("role", data.data.role);
          localStorage.setItem("admin", data.data.admin);
          localStorage.setItem("org", data.data.organisation_id);
          localStorage.setItem("Auth", data.data.jwttoken);

          window.location.reload();
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        tostMsg2(err.response.data.message);
      });
  }

  function sendOTP(item, item2) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (item == "register") {
      axios
        .post("/Generate_otp_userid?userid=".concat(item2), headers)
        .then((data) => {
          if (data.status == "200") {
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          tostMsg2(err.response.data.message);
        });
    } else if (item == "resend") {
      axios
        .post("/Generat_otp_mail?userid=".concat(user_id), headers)
        .then((data) => {
          if (data.status == "200") {
            console.log(data);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          tostMsg2(err.response.data.message);
        });
    } else if (item != "check" || item != "resend") {
      axios
        .post("/varify_otp?userid=".concat(user_id, "&userotp=", item), headers)
        .then((data) => {
          if (data.status == "200") {
            console.log(data);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          tostMsg2(err.response.data.message);
        });
    }
  }

  function forgotpassword(item) {
    if (item == "sendforgototp") {
      updatloginstate("sendforgototp");
    } else if (item == "default") {
      updatloginstate("default");
    }
  }

  function GetEmailOtp() {
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post("/Generat_otp_mail?MailID=".concat(emailOTP.MailID), headers)
      .then((data) => {
        if (data.status == "200") {
          updatloginstate("forgot");
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }

  function SubmitForgotOtp() {
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        "/varify_otp_mail?mailID=".concat(
          emailOTP.MailID,
          "&userotp=",
          usersOTP.userotp
        ),
        headers
      )
      .then((data) => {
        if (data.status == "200") {
          updatloginstate("newpass");
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }

  function SubmitNewPassword() {
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        "/updates_password_mail?MailID=".concat(
          emailOTP.MailID,
          "&otp=",
          usersOTP.userotp,
          "&newpassword=",
          newPassword.newpassword1
        ),
        headers
      )
      .then((data) => {
        if (data.status == "200") {

          localStorage.setItem("token", data.data.id);
          localStorage.setItem("user_email", data.data.email);
          localStorage.setItem(
            "organisation_name",
            data.data.organisation_name
          );
          localStorage.setItem("user_name", data.data.user_name);
          localStorage.setItem("joining_date", data.data.joining_date);
          localStorage.setItem("role", data.data.role);
          localStorage.setItem("admin", data.data.admin);
          localStorage.setItem("org", data.data.organisation_id);
          window.location.reload();
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        tostMsg2(err.response.data.message);
      });
  }

  return (
    <>

      <div className="container">
        <div className="row">
          <div align="center" className="col-12 col-sm-7">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <DarkVariantExample/>
           
            {/* <img src={login} width={500} alt="Sales Sunday" />*/}
            {/* <video width={500} height={500} autoPlay loop>
              <source src={loginvid} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            </div>
             {/* <p>Get <b>1 month</b> absolutely <b>free</b> on signup!</p> */}
          </div>
          
          <div className="col col-12 col-sm-5">
          <div className="d-flex justify-content-center align-items-center vh-100 ">
            <div className="container" style={{ width: "450px" }}>
              <div className="container bg-light shadow-lg rounded">
                <br />
                <ul
                  className="nav nav-pills mb-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <br />
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Login
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Signup
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {loginstate == "default" ? (
                      <form onSubmit={sendloginData}>
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            value={userlog.email}
                            onChange={loginData}
                            name="email"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="name@example.com"
                            required
                          />
                          <label htmlFor="floatingInput">
                            {" "}
                            <i className="bi bi-person-circle"></i> Email
                            address
                          </label>
                        </div>
                        <div className="form-floating">
                          <input
                            type="password"
                            value={userlog.password}
                            onChange={loginData}
                            name="password"
                            className="form-control border border-secondary bg-white"
                            id="floatingPassword"
                            placeholder="Password"
                            required
                          />
                          <label htmlFor="floatingPassword">
                            <i className="bi bi-lock-fill"></i> Password
                          </label>
                        </div>
                        <br />
                        <a
                          type="button"
                          onClick={() => forgotpassword("sendforgototp")}
                          className="btn btn-light"
                        >
                          Forgot Password ?
                        </a>

                        <br />
                        <br />
                        <button type="submit" className="btn btn-dark pop">
                          <i className="bi bi-door-open"></i> Login{" "}
                        </button>
                      </form>
                    ) : loginstate == "sendforgototp" ? (
                      <div>
                        <h3 className="">
                          <i
                            onClick={() => forgotpassword("default")}
                            className="bi bi-arrow-left-circle-fill"
                          />
                        </h3>

                        <br />
                        <br />
                        <h3>Enter email id</h3>
                        <div className="form-floating mb-3">
                          <input
                            value={emailOTP.MailID}
                            name="MailID"
                            onChange={updatemailOTP}
                            type="email"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="OTP"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-envelope-fill"></i> Enter your
                            email
                          </label>
                        </div>

                        <button
                          onClick={GetEmailOtp}
                          className="btn btn-dark pop"
                        >
                          <i className="bi bi-arrow-right"></i> Submit{" "}
                        </button>
                        <br />
                        <br />
                      </div>
                    ) : loginstate == "forgot" ? (
                      <div>
                        <h3 className="">
                          <i
                            onClick={() => forgotpassword("sendforgototp")}
                            className="bi bi-arrow-left-circle-fill"
                          />
                        </h3>
                        <br />
                        <br />

                        <h4>
                          <i className="bi bi-envelope-fill"></i> Enter your
                          OTP.
                        </h4>
                        <p>Check your registered email.</p>

                        <div className="form-floating mb-3">
                          <input
                            type="number"
                            value={usersOTP.userotp}
                            onChange={updataOTP}
                            name="userotp"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="OTP"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-laptop-fill"></i> Enter OTP
                          </label>
                        </div>
                        <p>Resend OTP</p>
                        <button
                          type="submit"
                          onClick={SubmitForgotOtp}
                          className="btn btn-dark pop"
                        >
                          <i className="bi bi-arrow-right"></i> Submit{" "}
                        </button>
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div>
                        <br />
                        <br />

                        <h4>
                          <i className="bi bi-shield-lock-fill"></i> Enter a new
                          password.
                        </h4>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            value={newPassword.newpassword1}
                            onChange={updatnewPassword}
                            name="newpassword1"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="New Password"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-key-fill"></i> Enter new
                            password
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            value={newPassword.newpassword2}
                            onChange={updatnewPassword}
                            name="newpassword2"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="New Password"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-key-fill"></i> Enter confirm
                            password
                          </label>
                        </div>
                        <button
                          type="button"
                          onClick={SubmitNewPassword}
                          className="btn btn-dark pop"
                        >
                          <i className="bi bi-arrow-right"></i> Submit{" "}
                        </button>
                        <br />
                        <br />
                      </div>
                    )}
                    <br /> <br />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {user_id_check == "" ? (
                      <form onSubmit={sendData}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            value={userData.name}
                            onChange={updataData}
                            name="name"
                            className="form-control border  border-secondary bg-white"
                            id="floatingInput"
                            placeholder="Raju Sharma"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-person-fill"></i> Full name
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            value={userData.organisation_name}
                            onChange={updataData}
                            name="organisation_name"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="Sales Sunday"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-building"></i> Organization name
                          </label>
                        </div>
                       
                        <select className="form-select border border-secondary mb-3" value={userData.business}
                            onChange={updataData}
                            name="business" aria-label="Default select example">
                        <option selected="">Select your business type?</option>
                        {businessTypes.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                      ))}
                      
                      </select>
                       

                        <div className="form-floating mb-3">
                          <input
                            type="number"
                            value={userData.mobile}
                            onChange={updataData}
                            name="mobile"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="name@example.com"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-phone-fill"></i> Contact number
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            value={userData.email}
                            onChange={updataData}
                            name="email"
                            className="form-control border border-secondary bg-white"
                            id="floatingInput"
                            placeholder="name@example.com"
                            required
                          />
                          <label htmlFor="floatingInput">
                            <i className="bi bi-envelope-fill"></i> Email
                            address
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="password"
                            value={userData.password}
                            onChange={updataData}
                            name="password"
                            className="form-control border border-secondary bg-white"
                            id="floatingPassword"
                            placeholder="Password"
                            required
                          />
                          <label htmlFor="floatingPassword">
                            <i className="bi bi-lock-fill"></i> Password
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input border border-secondary"
                            type="checkbox"
                            defaultValue
                            id="flexCheckChecked"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Accept &nbsp;
                            <Link to="/terms&conditions" target="_blank">
                              terms & conditions
                            </Link>
                          </label>
                        </div>
                        <p>
                          Note: <b>This sign up is for organization.</b>
                        </p>
                        <button type="submit" className="btn btn-dark pop">
                          <i className="bi bi-box-arrow-in-right" /> Sign Up{" "}
                        </button>
                        <br /> <br />
                      </form>
                    ) : (
                      <div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <form onSubmit={checkOTP}>
                          <h4>
                            <i className="bi bi-envelope-check"></i> OTP has
                            been sent to your email Id.
                          </h4>
                          <br />
                          <div className="form-floating mb-3">
                            <input
                              type="number"
                              value={usersOTP.userotp}
                              onChange={updataOTP}
                              name="userotp"
                              className="form-control border border-secondary"
                              id="floatingInput"
                              placeholder="OTP"
                              required
                            />
                            <label htmlFor="floatingInput">
                              <i className="bi bi-laptop-fill"></i> Enter your
                              OTP
                            </label>
                          </div>
                          <p>Resend OTP</p>
                          <button type="submit" className="btn btn-dark pop">
                            <i className="bi bi-arrow-right"></i> Submit{" "}
                          </button>
                        </form>{" "}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="footer">
        <p>
          Copyright © 2024 <b>SalesSunday</b>. All Rights Reserved.
        </p>
      </div>

      <ToastContainer />
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
