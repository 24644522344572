import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getselect_options = createAsyncThunk(
  "getselect_options",
  async ({ org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/Get_select_options?org=`.concat(org), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  } 

);