/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import rocket from "../media/undraw_maker_launch_re_rq81.svg";
import AdminUse from "../media/undraw_lightbulb_moment_re_ulyo.svg";
import TeamUse from "../media/undraw_in_the_office_re_jtgc.svg";


export default function progressbar() {

  const [isPaused, setIsPaused] = useState(false);
  const admin = localStorage.getItem("admin");

  const [accountStatus, setAccountstatus] = useState({});

  const steppoints = {"1.1": 15, "1.2": 15, "2.1": 10, "2.2": 10, "3": 10, "4": 10, "5": 7, "6": 8, "7": 5, "8": 5, "9": 5 };

  const Auth = localStorage.getItem("Auth");

  // const handleButtonClick = () => {
  //   setIsPaused((prevPaused) => !prevPaused);
  // };

  const ObjectStatus = useSelector((state) => state.Home.status);

  // let [cardData, setCardData] = useState([]);
  let [progressStatus, setprogressStatus] = useState({});

  // let ObjectDetails = {
  //   Add_scripts_or_task: {
  //     display_name: "Call & Task categories",
  //     key: "Add_scripts_or_task",
  //     route: "/soplab",
  //     description: "Add Call and task categories that you perform on a daily bases.",
  //     icon: "bi bi-tags-fill",
  //     rank: "1",
  //     button: "Sop Lab",
  //   },
  //   design_sop: {
  //     display_name: "Design Sop",
  //     key: "design_sop",
  //     route: "/sop",
  //     description: "Design a process an idea sales and operations process",
  //     icon: "bi bi-file-text-fill",
  //     rank: "2",
  //     button: "Build & Mesure",
  //   },
  //   isuse: {
  //     display_name: "Time to execute!",
  //     key: "isuse",
  //     route: "/",
  //     description:
  //       "Ahh! It's time to make your first move. Add lead or call a prospect!",
  //     icon: "bi bi-laptop-fill",
  //     rank: "3",
  //     button: "Home",
  //   },
  // };

  // let ArrayObjectData = (object) => {
  //   let array = [];
  //   Object.keys(object)?.map((elm) => {
  //     if (object[elm] == false) {
  //       array.push(ObjectDetails[elm]);
  //     }
  //   });
  //   return array;
  // };

  function setup1() {
    document.getElementById("setup1").style.display = "block";
    get_setup_status()
  }
  function setup1_back() {
    document.getElementById("setup1").style.display = "none";
  }

  function get_setup_status() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_setup_status", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setAccountstatus(data.data);
          
        }
        
      })
      .catch((err) => {
        console.log(err);
       
      });
  }

  let totalpoints = 0;



  function progressbardata(steppoints, stepstatus){
    let totalpoints = 0;
    for (const key in stepstatus) {
      if (stepstatus[key] === true && steppoints[key]) {
        totalpoints += steppoints[key];
      }
    }

    return totalpoints;


  }
  
  useEffect(() => {
    get_setup_status();
  }, []);
  return (
    <>

<button type="button" className={`btn btn-danger border border-white setup-btn`}
    onClick={() => setup1()}
    > <i className="bi bi-headset"></i> Guide</button>
       <div
            className="card w-75 setup-popup p-0 mt-1 shadow-lg border border-dark"
            id="setup1"
          >
            <div className="card-body">
              <div className="row">
              <div className="col-11">
              <>
  <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
    {admin == "true"?<li className="nav-item" role="presentation">
      <button
        className="nav-link btn-sm active"
        id="pills-home1-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home1"
        type="button"
        role="tab"
        aria-controls="pills-home1"
        aria-selected="true"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rocket-fill" viewBox="0 0 16 16">
  <path d="M10.175 1.991c.81 1.312 1.583 3.43 1.778 6.819l1.5 1.83A2.5 2.5 0 0 1 14 12.202V15.5a.5.5 0 0 1-.9.3l-1.125-1.5c-.166-.222-.42-.4-.752-.57-.214-.108-.414-.192-.627-.282l-.196-.083C9.7 13.793 8.85 14 8 14s-1.7-.207-2.4-.635q-.101.044-.198.084c-.211.089-.411.173-.625.281-.332.17-.586.348-.752.57L2.9 15.8a.5.5 0 0 1-.9-.3v-3.298a2.5 2.5 0 0 1 .548-1.562l.004-.005L4.049 8.81c.197-3.323.969-5.434 1.774-6.756.466-.767.94-1.262 1.31-1.57a3.7 3.7 0 0 1 .601-.41A.55.55 0 0 1 8 0c.101 0 .17.027.25.064q.056.025.145.075c.118.066.277.167.463.315.373.297.85.779 1.317 1.537M9.5 6c0-1.105-.672-2-1.5-2s-1.5.895-1.5 2S7.172 8 8 8s1.5-.895 1.5-2"/>
  <path d="M8 14.5c.5 0 .999-.046 1.479-.139L8.4 15.8a.5.5 0 0 1-.8 0l-1.079-1.439c.48.093.98.139 1.479.139"/>
</svg> Setup guide
      </button>
    </li>:null}
    {admin == "true"?<li className="nav-item" role="presentation">
      <button
        className="nav-link btn-sm"
        id="pills-profile1-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile1"
        type="button"
        role="tab"
        aria-controls="pills-profile1"
        aria-selected="false"
      >
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-badge-fill" viewBox="0 0 16 16">
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6m5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1z"/>
</svg>  Daily use case - Admin
      </button>
    </li>:null}
    <li className="nav-item" role="presentation">
      <button
        className="nav-link btn-sm"
        id="pills-contact1-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-contact1"
        type="button"
        role="tab"
        aria-controls="pills-contact1"
        aria-selected="false"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
</svg> Daily use case - Team
      </button>
    </li>
  </ul>
  
</>
              </div>
              <div className="col-1" align='right'><h4> <i
                    onClick={() => setup1_back()}
                    className="bi bi-x-circle-fill"
                  /></h4></div>

                  <div className="col-12">
                  <div className="tab-content" id="pills-tabContent">
    <div
      className={admin == "true"?"tab-pane fade show active":"tab-pane fade"}
      id="pills-home1"
      role="tabpanel"
      aria-labelledby="pills-home1-tab"
    >
      <div className="row">
  <div className="col-12">

   <div className="container-fluid">
     <div className="row">
     

     <blockquote className="blockquote">
     <p><i className="bi bi-trophy-fill"></i> Setup progress</p>
</blockquote>

         
         <>

<div className="progress border border-dark p-0 m-0">
<div
className={`progress-bar progress-bar-striped ${
progressbardata(steppoints, accountStatus) < 25
? 'bg-danger'
: progressbardata(steppoints, accountStatus) < 50
? 'bg-warning'
: progressbardata(steppoints, accountStatus) < 75
? 'bg-primary'
: 'bg-dark'
}`}
role="progressbar"
style={{ width: `${progressbardata(steppoints, accountStatus)}%` }}
aria-valuenow={progressbardata(steppoints, accountStatus)}
aria-valuemin={0}
aria-valuemax={100}
>
{progressbardata(steppoints,accountStatus)} %

</div>

</div>
<p>
<div className="row">
<div className="col-3 border-start border-dark">
{(progressbardata(steppoints,accountStatus)>=0 && progressbardata(steppoints,accountStatus)<=25)?<><b>Starter</b></>
:<> Starter</> }





</div>
<div className="col-3 border-start border-dark">
{(progressbardata(steppoints,accountStatus)>25 && progressbardata(steppoints,accountStatus)<=50)?<><b>Average</b></>
:<>Average</> }


</div>
<div className="col-3 border-start border-dark">
{(progressbardata(steppoints,accountStatus)>50 && progressbardata(steppoints,accountStatus)<=75)?<><b>Good</b></>
:<>Good</> }


</div>
<div className="col-3 border-start border-dark">
{(progressbardata(steppoints,accountStatus)>75 && progressbardata(steppoints,accountStatus)<=100)?<><b>Excellent</b></>
:<>Excellent</> }

</div>


</div>

</p>




</>

<br/>

         </div></div>
       
         
  </div>
 
  </div> 
      
<div className="row">
<div className="col-4">    

   <div
className="overflow-auto border-end p-2 bg-light"
style={{ maxWidth: "500px", maxHeight: "400px" }}
>

<h4>Quick setup:</h4>
<p className="text-secondary">Must have</p>

<p><a href='#1'><i className="bi bi-bezier"></i> <b>Design sales and operations : </b></a> 
<br/>  &nbsp; <a href='#1.1'> <i className="bi bi-arrow-return-right"></i>  &nbsp;  <i  className={accountStatus["1.1"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i> Add process categories </a>
<br/>  &nbsp; <a href='#1.2'> <i className="bi bi-arrow-return-right"></i>  &nbsp;  <i  className={accountStatus["1.2"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i> Design process </a></p>

<p>  <a href='#2'> <i className="bi bi-phone-fill"></i> <b>Add calls & tasks : </b> </a>
<br/>   &nbsp; <a href='#2.1'><i className="bi bi-arrow-return-right"></i>  &nbsp;  <i  className={accountStatus["2.1"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i> Add call & task categories  </a>

<br/>   &nbsp;<a href='#2.2'> <i className="bi bi-arrow-return-right"></i>  &nbsp;  <i  className={accountStatus["2.2"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i> Design calls & tasks </a></p>

<p> <a href='#3'><i  className={accountStatus["3"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>  &nbsp;  <i className="bi bi-person-badge"></i> <b> Add team </b> </a> </p>

<p className="text-secondary">Good to have</p>

<p> <a href='#4'><i  className={accountStatus["4"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>   &nbsp;  <i className="bi bi-ui-radios"></i> <b> Add custom input field </b> </a> </p>

<p> <a href='#5'><i  className={accountStatus["5"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>  &nbsp;  <i className="bi bi-question-circle-fill"></i> <b> Add questions and FAQs </b> </a> </p>

<p> <a href='#6'> <i  className={accountStatus["6"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>  &nbsp;  <i className="bi bi-person-fill"></i> <b> Add channel partners </b> </a> </p>

<p className="text-secondary">Nice to have</p>

<p> <a href='#7'><i  className={accountStatus["7"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>  &nbsp;  <i className="bi bi-globe"></i> <b> Add thirparty integration </b> </a> </p>

<p> <a href='#8'><i  className={accountStatus["8"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>  &nbsp;  <i className="bi bi-cpu-fill"></i> <b> Attach your app/web to SS </b> </a>  </p>

<p> <a href='#9'> <i  className={accountStatus["9"]?"bi bi-check-circle-fill text-success":"bi bi-circle text-secondary" }></i>  &nbsp;  <i className="bi bi-phone-vibrate-fill"></i> <b> Integrate cloud calling </b></a>  </p>



</div></div>
<div className="col-8">
<div
className="overflow-auto border-end p-2"
style={{ maxWidth: "100%", maxHeight: "400px" }}
>



<div style={{ flex: "1" }}>
<img src={rocket} height="200" alt="Rocket"></img>
</div>
<div style={{ flex: "1" }}>
<h1><b>5 Mins Setup</b> For Great Sales And Operations! </h1>
</div>
<br/>
<h5 id='1'><i className="bi bi-bezier"></i> <b>Design sales and operations </b> <span className="badge rounded-pill bg-danger border border-dark">Must have</span> <span className="badge rounded-pill bg-dark border border-dark"> <i class="bi bi-smartwatch"></i> 2 Min</span> </h5> 

<p id='1.1' ><b> 1. Add process categories </b>
<br/> Add all your sales and operations process categories, e.g., onboarding, sales outreach, customer support, KYC process. Click on setup > Process setup > Click <i className="bi bi-plus-circle-fill"></i> Add process button.
<br/>    
<Link
                     to="/sop"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>

<p id='1.2'><b> 2. Design process </b>
<br/> It's time to design the process categories you have added in the previous stage. Click on <i className="bi bi-robot"></i> to import processes used by top companies. e.g., Sales outreach :: New lead > Qualified lead > Demo scheduled > Demo completed > Trial started > Customer 
<br/>    
<Link
                     to="/sop"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>

<br/>
<h5 id='2'><i className="bi bi-phone-fill"></i> <b>Add calls & tasks :</b> <span className="badge rounded-pill bg-danger border border-dark">Must have</span> <span className="badge rounded-pill bg-dark border border-dark"> <i class="bi bi-smartwatch"></i> 2.5 Min</span>  </h5> 

<p id='2.1'><b>1. Add call & task categories </b>
<br/> Now let's add all the calls and task categories you and your team perform on a regular basis. Click on Setup > Calls and tasks setup > Click on <i className="bi bi-plus-circle-fill"></i> Add Category. eg: Cold call, Feedback call, Follow up call.  
<br/>   <Link
                     to="/soplab"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span> </Link></p>
<p id='2.2'><b>2. Design calls & tasks </b>
<br/> 
It's time to design calls and tasks in detail. Click on the <i
                     title="View"
                     className="bi bi-eye-fill"/> icon next to the call/task category. Enter call goal, use the script editor to design a call script, or click on  <i className="bi bi-robot"></i> AI generator to generate a call script. Add after-call instructions and set call brief scores.
<br/>  <Link
                     to="/soplab"
                   > <span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>

<p>Go to process setup, click to select any SOP category. In the stages, click on the 'add' button and insert calls or tasks. While your team is working on a lead, this will serve as assistance.</p> 

<br/>
<h5 id='3'><i className="bi bi-person-badge"></i> <b> Add team </b> <span className="badge rounded-pill bg-danger border border-dark">Must have</span> <span className="badge rounded-pill bg-dark border border-dark"> <i class="bi bi-smartwatch"></i> 0.5 Min</span> </h5> 
<p> Go to profile page and click on <i className="bi bi-person-plus-fill"></i> Add Team button <br/>
<Link
                     to="/profile"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>

<br/>
<p className="text-secondary">Good to have: </p>
<br/>
<h5 id='4'><i className="bi bi-ui-radios"></i> <b>Add custom input field </b> </h5> 
<p>If you want to add custom fields to your lead collection, click on the <i className="bi bi-plus-lg"></i> button on top, then click on <i class="bi bi-gear-fill"></i> 'Customize Form,' and add input fields of your choice.
{/* <br/>   <span className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span> */}
</p>

<br/>
<h5 id='5'> <i className="bi bi-question-circle-fill"></i> <b> Add questions and FAQs </b> </h5>
<p>You can add all the frequently asked questions to the system so that whenever your team comes across any, they can answer with confidence. Also, this will work as a training module. To do so, click on 'Setup' and then click on <i className="bi bi-question-circle"></i> 'Questions & FAQs' option.
<br/>  <Link
                     to="/questions"
                   > <span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>


<br/>
<h5 id='6'> <i className="bi bi-person-fill"></i> <b> Add channel partners or sources. </b>  </h5>

<p>Do you have an affiliate, channel partner, or source that shares data with you? Do you want to keep a report of all the leads that are shared by this source or just keep track? You should add a source. This will create two links: 1. Lead capture - to send you leads, 2. Lead view - to view all the leads. You can keep the sharing on or off and set a password as well. Click on 'Record,' then select  <i className="bi bi-journal-text"></i> 'Calls & Tasks,' now click on the <i className="bi bi-bezier2"></i> 'Source' button
<br/>  <Link
                     to="/callrecord"
                   > <span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>
<br/>
<p className="text-secondary">Nice to have: </p>
<br/>
<br/>
<h5 id='7'>  <i className="bi bi-globe"></i> <b> Add thirparty integration </b> </h5>
<p>If you are using multiple applications like an email service, sending SMS, WhatsApp, or just performing tasks on your application, you can turn any website with APIs into an application on Sales Sunday. Click on 'More,' then click on <i className="bi bi-cpu-fill"></i> 'Integration Lab' button.
<br/><Link
                     to="/integrationlab"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>


<br/>
<h5 id='8'><i className="bi bi-cpu-fill"></i> <b> Attach your app/web to SS </b> </h5>
<p>
Do you want to connect Sales Sunday to your landing page, contact us page, add a lead, update a lead, etc.? You can do it here!
</p>

<br/>
<h5 id='9'>  <i className="bi bi-phone-vibrate-fill"></i> <b> Integrate cloud calling </b> </h5>
<p>Do you want a cloud calling solution or want to connect with a cloud calling provider?
<br/>
<Link
                     to="/profile"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>



</div>

</div>
</div>

    </div>
    <div
      className="tab-pane fade"
      id="pills-profile1"
      role="tabpanel"
      aria-labelledby="pills-profile1-tab"
    >
      <br/>
     <div className="row">
  <div className="col-4"> 
  <div
className="overflow-auto p-2 bg-light  border-end"
style={{ maxWidth: "500px", maxHeight: "470px" }}
>
<h4>Daily use admin:</h4>
<br/>
<p> <a href='#admin1'> <i className="bi bi-plus-circle-fill"></i> <b> Add lead  </b> </a> 
<br/>  &nbsp; <a href='#admin1.1'> <i className="bi bi-arrow-return-right"></i>  &nbsp; Single lead </a>
<br/>  &nbsp; <a href='#admin1.2'> <i className="bi bi-arrow-return-right"></i>  &nbsp; Multiple leads </a>
</p>

<p> <a href='#admin2'> <i className="bi bi-person-circle"></i> <b> Check lead status  </b> </a> </p>
<p> <a href='#admin3'> <i className="bi bi-phone-fill"></i> <b> Calls and tasks  </b> </a> </p>
<p> <a href='#admin4'> <i className="bi bi-clipboard-data-fill"></i> <b> Monitor performance </b> </a> </p>
<p> <a href='#admin5'> <i className="bi bi-bar-chart-line-fill"></i> <b> Improve performance </b> </a> </p>

<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

</div>
    </div>
    <div className="col-8">  
    <div
className="overflow-auto border-end p-2"
style={{ maxWidth: "100%", maxHeight: "470px" }}
>
<div style={{ flex: "1" }}>
<img src={AdminUse} height="200" alt="admin use"></img>
</div>
<div style={{ flex: "1" }}>
<h1>Manage Your Organization With <b> Exceptional Sales and Operation! </b> </h1>
</div>
<br/>
<h5 id='admin1'><i className="bi bi-plus-circle-fill"></i> <b>Add lead </b></h5> 
<p id='admin1.1' >Add a single lead or multiple leads. Connect Sales Sunday seamlessly into your application, website, or landing page. You can also collect leads added from the channel partner lead capture link.
<br/><br/><b> 1. Single lead </b>
<br/>
Click on <i className="bi bi-plus-lg"></i> button on top. You can add a lead by filling out the form on the left-hand side. Alternatively, you can also add a lead by clicking on 'Records' > 'Lead Management,' then click on the middle right-hand side button <i className="bi bi-plus-lg"></i> 'Add lead,' and then click on <i className="bi bi-plus-circle-fill"></i> 'Single.'
<br/>    
<Link
                     to="/sopboard"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>
<p id='admin1.2' ><b> 2. Multiple leads </b>
<br/>
Click on 'Records' > 'Lead Management,' then click on the middle right-hand side button <i className="bi bi-plus-lg"></i> 'Add lead,' and then click on <i className="bi bi-file-earmark-spreadsheet"></i> 'Multi.'
<br/>    
<Link
                     to="/sopboard"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>
<br/>
<h5 id='admin2'><i className="bi bi-person-circle"></i> <b>Check lead status</b></h5> 
<p id='1.1' >Click on 'Records' > 'Lead Management,' select a pipeline, and you can check all the lead statuses.</p>
<br/>
<h5 id='admin3'><i className="bi bi-phone-fill"></i> <b>Calls and tasks</b></h5> 
<p id='1.1' >Click on 'Home,' and you will find calls and tasks assigned to your team. For more details, click on 'Records' > 'Calls & Tasks.' You will find 'Today,' 'Pending,' and 'To-do's,' which will give you complete data.</p>
<br/>
<h5 id='admin4'><i className="bi bi-clipboard-data-fill"></i> <b>Monitor performance</b></h5> 
<p id='1.1' >Click on 'Home,' and you will find calls and tasks assigned to your team. For more details, click on 'Records' > 'Calls & Tasks.' You will find 'Today,' 'Pending,' and 'To-dos,' which will give you complete data. </p>
<br/>
<h5 id='admin5'><i className="bi bi-bar-chart-line-fill"></i> <b>Improve performance</b></h5> 
<p id='1.1' >Click on 'Home,' and you will find calls and tasks assigned to your team. For more details, click on 'Records' > 'Calls & Tasks.' You will find 'Today,' 'Pending,' 'To-dos,' which will give you complete data. </p>



</div> 
    </div>
  </div>
    </div>
    <div
      className={admin == "false"?"tab-pane fade show active":"tab-pane fade"} 
      id="pills-contact1"
      
      role="tabpanel"
      aria-labelledby="pills-contact1-tab"
    >
      <br/>
     <div className="row">
  <div className="col-4 "> 
  <div
className="overflow-auto p-2 bg-light  border-end"
style={{ maxWidth: "500px", maxHeight: "470px" }}
>
<h4>Daily use team:</h4>
<br/>
<p> <a href='#user1'> <i className="bi bi-mortarboard-fill"></i> <b> Quick training  </b> </a> </p>
<p> <a href='#user2'> <i className="bi bi-plus-circle-fill"></i> <b> Add lead  </b> </a> 
<br/>  &nbsp; <a href='#user1.1'> <i className="bi bi-arrow-return-right"></i>  &nbsp; Single lead </a>
<br/>  &nbsp; <a href='#user1.2'> <i className="bi bi-arrow-return-right"></i>  &nbsp; Multiple leads </a></p>
<p> <a href='#user3'> <i className="bi bi-phone-fill"></i> <b> Calls and tasks  </b> </a> </p>
<p> <a href='#user4'> <i className="bi bi-person-circle"></i> <b> Check lead status  </b> </a> </p>

<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

</div>
    </div>
    <div className="col-8">  
    <div
className="overflow-auto border-end p-2"
style={{ maxWidth: "100%", maxHeight: "470px" }}
>
<div style={{ flex: "1" }}>
<img src={TeamUse} height="200" alt="team use"></img>
</div>
<div style={{ flex: "1" }}>
<h1>Make Your Everyday Job <b>Efficient, Stress-free, and Super Easy!</b></h1>
</div>
<br/>
<h5 id='user1'><i className="bi bi-mortarboard-fill"></i><b> Quick training</b></h5> 
<p id='user1.1' >Click on 'Records' > 'Lead Management,' select a pipeline, and you can check the status of all the leads. </p>
<br/>
<h5 id='user2'><i className="bi bi-plus-circle-fill"></i> <b>Add lead </b></h5> 
<p id='user1.2' >Add a single lead or multiple leads. Seamlessly connect Sales Sunday to your application, website, or landing page. You can also collect leads added from the channel partner lead capture link.
<br/><br/><b> 1. Single lead </b>
<br/>
Click on the <i className="bi bi-plus-lg"></i> button at the top. You can add a lead by filling out the form on the left-hand side. Alternatively, go to 'Records' > 'Lead Management,' then click on the middle right-hand side button <i className="bi bi-plus-lg"></i> 'Add lead,' and finally, click on <i className="bi bi-plus-circle-fill"></i> 'Single.'
<br/>    
<Link
                     to="/sopboard"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>
<p id='1.1' ><b> 2. Multiple leads </b>
<br/>
Click on 'Records' > 'Lead Management,' then click on the middle right-hand side button <i className="bi bi-plus-lg"></i> 'Add lead,' and finally, click on <i className="bi bi-file-earmark-spreadsheet"></i> 'Multi.'
<br/>    
<Link
                     to="/sopboard"
                   ><span onClick={() => setup1_back()} className="badge bg-light text-dark border border-dark cursor">Click here <i className="bi bi-arrow-right"></i></span></Link>
</p>
<br/>
<h5 id='user3'><i className="bi bi-phone-fill"></i> <b>Calls and tasks</b></h5> 
<p id='1.1' >Click on 'Home,' and you will find calls and tasks assigned to your team. For more details, click on 'Records' > 'Calls & Tasks.' There, you will find sections for 'Today,' 'Pending,' and 'To-dos,' providing you with complete data. </p>
<br/>
<h5 id='user4'><i className="bi bi-person-circle"></i> <b>Check lead status</b></h5> 
<p id='1.1' >Click on 'Records' > 'Lead Management,' select a pipeline, and you can check the status of all leads. </p>



</div> 
    </div>
  </div>
    </div>
  </div>
                  </div>
                
              </div>
              
           
             


 <br/>

 <div className="card-footer bg-transperent">
 Note: The more steps you complete, the better your sales and operations will become!
</div>
            </div>

          </div>

      {/* <br />
      <div className="container">
        <br />
        <div className="row">
          <div className="container">
            <h4>Finish your setup!</h4>
            <div className="progress progressbar">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-dark border"
                role="progressbar"
                aria-valuenow={
                  10 +
                  Object.values(progressStatus).filter((item) => item == true)
                    .length *
                    30
                }
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                  width: `${
                    10 +
                    Object.values(progressStatus).filter((item) => item == true)
                      .length *
                      30
                  }%`,
                }}
              >
                {10 +
                  Object.values(progressStatus).filter((item) => item == true)
                    .length *
                    30}
                %
              </div>
            </div>
            <br />
            <div className="row">
              {cardData?.map((elm) => (
                <div className="col-3">
                  <div className="card border border-dark">
                  <div className="position-absolute top-0 start-0"> <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
        {elm.rank}
        <span className="visually-hidden">unread messages</span>
      </span>
      </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        <i className={elm.icon} /> &nbsp;
                        {elm.display_name}
                      </h5>
                      <p className="card-text">{elm.description}</p>
                      <Link to={elm.route}>
                        <a className="btn btn-dark pop">
                          <i className="bi bi-arrow-right" /> {elm.button}
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

           
            <br />
          </div>
        </div>
      </div> */}
    
       
    </>
  );
}
