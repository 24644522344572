import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from "react-toastify";

export default function LandingForm() {
  const Auth = localStorage.getItem("Auth");
  const [csform, setcsform] = useState({
    Quary_Type: "",
    Description: ""
  });



  function updateCSForm(e) {
    e.preventDefault();
    setcsform({ ...csform, [e.target.name]: e.target.value });
  }

  function CSFormSubmit(){

    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${Auth}`,
    };
    axios
      .post(
        "/Connect_to_us",csform,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setcsform({
            Quary_Type: "",
            Description: ""
          });
          toast.success("Query submited successfully!", {
            position: "bottom-right",
            autoClose: 1000,
          });

        }
       
      })
      .catch((err) => {
        console.log(err);
       
      });
  
  
   }  


   return(
    <>
    <div align='left'>
    <div className="row">
                <div className="col-12">
                <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label"><i className="bi bi-headset"></i> Topic</label>
        <select name="Quary_Type" value={csform.Quary_Type} onChange={updateCSForm} className="form-select" aria-label="Default select example">
        <option selected>What is your query about?</option>
        <option value="Integration">Integration</option>
        <option value="Feedback">Feedback</option>
        <option value="Report a bug">Report a bug</option>
        <option value="Feature Request">Feature Request</option>
      </select>
      </div>
                </div>
             
              </div>
              <div className="row">
              <div>
          <label htmlFor="exampleFormControlTextarea1" className="form-label"><i className="bi bi-file-text-fill"></i> Description</label>
          <textarea name="Description" value={csform.Description} onChange={updateCSForm} className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
        </div>
              </div><br/> <div className="container">              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
             
                <button type="button" onClick={CSFormSubmit}  className="btn btn-dark btn-sm"><i className="bi bi-arrow-right"></i> Submit</button>
                </div>
                </div></div><ToastContainer /></>
   );


}