import React from "react";
import sales101 from "../media/sales101.png";
import speak from "../media/speak.png";
import salessunday4 from "../media/salesundaycard4.png";
import salessunday3 from "../media/salesundaycard3.png";
import salessunday2 from "../media/salesundaycard2.png";
import salessunday1 from "../media/salesundaycard1.png";



function Learn() {
  let salesundaCardData = [
    {
      id: 1,
      description_heading: "Step 1: Quick Step",
      description: "Learn how to quicky setup your System.",
      image: salessunday1,
      link: "https://app.tango.us/app/embed/b32f4035-ba31-43c6-982d-9b28e7b80273?iframe",
    },
    {
      id: 2,
      description_heading: "Step 2: How to Call",
      description: "Learn how to use Sales Sunday in real time.",
      image: salessunday2,
      link: "https://app.tango.us/app/embed/c640bde8-1a34-4c26-8744-be3dc3e1f5dd?iframe",
    },
    {
      id: 3,
      description_heading: "Step 3: Check Record",
      description: "Learn about diffrent records calls & Tasks.",
      image: salessunday3,
      link: "https://app.tango.us/app/embed/f0a19771-1023-476a-ab70-e45092647a82?iframe",
    },
    {
      id: 4,
      description_heading: "Step 4: Read Report",
      description: "Learn about diffrent Graphs, KPI's and Improve.",
      image: salessunday4,
      link: "https://app.tango.us/app/embed/30fa3331-0fd8-493b-b4ab-d1558aae38b2?iframe",
    },
  ];
  let dataSalesSundayUniversity = [
    {
      id: "pills-sales",
      display_name: "Sales",
      description_heading: "Sales 101",
      icon: "bi bi-headset",
      description:
        "10 Chapters that will tech you how to sell and understand a customer.",
      button_display_name: "Comming soon",
      image: sales101,
    },
    {
      id: "pills-profile",
      display_name: "Motivation",
      description_heading: "Motivation",
      icon: "bi bi-brightness-alt-high",
      description: "",
      button_display_name: "",
      image: "",
    },
    {
      id: "pills-contact",
      display_name: "Personality",
      description_heading: "",
      icon: "bi bi-people",
      description: "",
      button_display_name: "",
      image: "",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <h3>Sales Sunday University</h3>
          <br /> <br /> <br />
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={"nav-link active"}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                <i className="bi bi-laptop"></i> Sales Sunday
              </button>
            </li>
            {dataSalesSundayUniversity?.map((elm, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <button
                  className="nav-link"
                  id={`${elm.id}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#${elm.id}`}
                  type="button"
                  role="tab"
                  aria-controls={elm.id}
                  aria-selected="false"
                >
                  <i className={elm.icon}></i> {elm.display_name}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <br /><br />
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {salesundaCardData?.map((item,index) => (
                  <div className="card border rounded-circle" style={{ width: "18rem" }} key={index}>
                    <img src={item.image} className="card-img-top" alt="..." />
                    <div className="card-body card_design border border-light">
                      <h5 className="card-title">{item.description_heading}</h5>
                      <p className="card-text">{item.description}</p>

                      <a
                        href={item.link}
                        target="_blank"
                        className="btn btn-dark pop"
                      >
                        Learn <i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <br />
            {dataSalesSundayUniversity?.map((item, index) => (
              <div
                className="tab-pane fade"
                id={item.id}
                role="tabpanel"
                aria-labelledby={`${item.id}-tab`}
                key={index}
              >
                <br />
                {item.image ? (
                  <div className="card border rounded-circle" style={{ width: "18rem" }}>
                    <img src={item.image} className="card-img-top" alt="..." />
                    <div className="card-body card_design">
                      <h5 className="card-title">{item.description_heading}</h5>
                      <p className="card-text">{item.description}</p>
                      <a href="#" className="btn btn-dark pop">
                        {item.button_display_name}
                      </a>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Learn;
