import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialstate";

export const socketSlice = createSlice({
  name: "socket",
  initialState: initialState.socket,
  reducers: {
    updateWSConnection: (state, action) => {
      state.wsURL = action.payload.wsURL;
      state.isSocketActive = action.payload.isSocketActive;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWSConnection } = socketSlice.actions;

export default socketSlice.reducer;
