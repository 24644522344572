/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";

export default function dropDown({
  label,
  handleChange,
  feedOrgCategory = [],
  feedOrgTask = [],
  valueObj,
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (valueObj?.action?.task.length > 0) {
      setValue(`task ${valueObj?.action?.task}`);
    } else {
      setValue(`script ${valueObj?.action?.script}`);
    }
  }, [valueObj]);

  return (
    <div className="row mb-3">
      <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
        {label}:
      </label>
      <div className="col-sm-6">
        <select
          className="form-select form-select-sm "
          id="inputGroupSelect04"
          name="In Valid"
          value={value}
          aria-label="Example select with button addon"
          onChange={(e) => handleChange(e)}
        >
          <option value="" selected>
            Select Script/Task
          </option>
          <option value="">None</option>
          {feedOrgCategory &&
            feedOrgCategory?.map((item) => (
              <option
                className="bg-secondary text-white"
                value={`script ${item.script_type_name}`}
              >
                {item.script_type_name}
              </option>
            ))}
          {feedOrgTask &&
            feedOrgTask?.map((item) => (
              <option
                className="bg-primary text-white"
                value={`task ${item.heading}`}
              >
                {item.heading}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}
