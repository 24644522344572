import React, { Component, useEffect, useState, PureComponent } from "react";
import avatar from "../media/147144.png";
import axios from "axios";
import {
  PieChart,
  Pie,
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const organisation_name = localStorage.getItem("organisation_name");
const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const user_id = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");

function Playground() {
  const [feedRank, setFeedRank] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  }

  function get_usersRank() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get("/get_usersRank?organisation_id=".concat(org, "&RankType=Closing"), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setFeedRank(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  useEffect(() => {
    get_usersRank();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-3">
          <ol className="list-group">
            <p>
              User Rank <i className="bi bi-people-fill"></i>
            </p>

            {feedRank &&
              feedRank?.map((item) => (
                <>
                  <li className="list-group-item d-flex justify-content-between align-items-start bg-white text-dark mb-1 rounded">
                    <img
                      className="rounded-circle"
                      src={generateavatar(item.username, "White", "#004aad")}
                      width="35"
                      height="35"
                    ></img>
                    <div className="ms-2 me-auto">
                      <div>{item.username}</div>
                    </div>
                    <span className="badge bg-primary rounded-pill">
                      {item.todayscall}
                    </span>
                  </li>{" "}
                </>
              ))}
          </ol>
        </div>
        <div className="col-9"></div>
      </div>
    </div>
  );
}

export default Playground;
