/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function editableTextField({
  data,
  handleBlur,
  keyname,
  placeholder,
  type,
  options
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {

    if(data[keyname] == null){

      data[keyname] = ''; 

    }

    setValue(data[keyname]);
  }, [data]);


  const onChange = (e) => {
    setValue(e.target.value);
  };

  const blurFunction = (e) => {
    handleBlur(data, value, keyname);
    setOpen(false);
  };

  console.log(data);
  console.log(data[keyname]);


  return (
    <div>
      {!open ? (
        <div className="mb-1 hover-container cursor" onClick={() => setOpen(true)}>
{
  type == 'array'?
  <>{value &&
    value?.map((item) => (
     <> 
    {item}
       <br/></>
      ))}</>
  :<>{value}</>

}


          
          {value==''?<span className="text-secondary" >________</span>:null}
          <i
            className="bi bi-pencil-square"
            style={{ fontSize: "12px", marginLeft: "6px", cursor: "pointer" }}
            id="icon"
          ></i>
        </div>
      ) : (

        type == 'select'?<>
        <select className="form-select form-select-sm" aria-label="Default select example" onChange={onChange}
        onBlur={blurFunction}
        value={value || ""}
        name={keyname}>
  <option selected value={value}>{value}</option>
  
  {options &&
                        options?.map((item) => (
                          value != item?<option value={item}>{item}</option>
                          :null
                          
                          ))}
</select>

        </>
        :type == 'date'?<><input
        type="date"
        onChange={onChange}
        onBlur={blurFunction}
        value={value || ""}
        name={keyname}
        className="form-control-sm border border-secondary"
        placeholder={placeholder}
      /></>
      :type == 'datetime-local'?<><input
        type="datetime-local"
        onChange={onChange}
        onBlur={blurFunction}
        value={value || ""}
        name={keyname}
        className="form-control-sm border border-secondary"
        placeholder={placeholder}
      /></>
      : type === 'array' ? (
        <>
          <div>
            {value?.map((item, index) => (
              <div key={index}>
                <input
                  type="text"
                  value={item}
                  onChange={(e) => {
                    const newArray = [...value];
                    newArray[index] = e.target.value;
                    setValue(newArray);
                  }}
                  className="form-control-sm border border-secondary"
                  placeholder={`Element ${index + 1}`}
                />
                <button
                  onClick={() => {
                    const newArray = [...value];
                    newArray.splice(index, 1);
                    setValue(newArray);
                  }}
                  className="btn btn-transparent btn-sm "
                >
                  <i className="bi bi-x-circle-fill"></i>
                </button>
              </div>
            ))}
            
            <span onClick={() => {
                const newArray = [...value, '']; // You can set a default value or leave it empty
                setValue(newArray);
              }} className="badge bg-dark border border-dark cursor mb-3"><i className="bi bi-plus-circle-fill"></i> Add </span>
              <span onClick={blurFunction} className="badge bg-white border border-dark text-dark cursor ms-2"> Update </span>
           
          </div>
        </>
      )
        
        
        :<><input
        type="text"
        onChange={onChange}
        onBlur={blurFunction}
        value={value || ""}
        name={keyname}
        className="form-control-sm border border-secondary"
        placeholder={placeholder}
      /></>

        
        
      )}
    </div>
  );
}
