import React, {useEffect, useState} from 'react';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import ActionModel from './actionmodel';

const user_name = localStorage.getItem("user_name");
const Auth = localStorage.getItem("Auth");

function TableCard() {

    const [filterLeads, setFilterLeads] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const [listsop, setlistsop] = useState([]);

    const [selectoptions, setSelectoptions] = useState({});
    
    
  const [actiontype, setActiontype] = useState('');

  
  const handleActionClose = () => setShowAction(false);

  const handleActionShow = () => setShowAction(true);
    
  const handleSelectAction = (event) => {
    setActiontype(event.target.value);
  };

  function removeEverythingBeforeComma(inputString) {
    const parts = inputString.split(',');
    if (parts.length > 1) {
        return parts[1].trim();
    }
    return inputString.trim();
  }


    const handleSelectAllChange = () => {
        if (selectAll) {
          setSelectedRows([]);
        } else {
          setSelectedRows([...filterLeads]);
        }
    
        setSelectAll(!selectAll);
      };
    
      const handleCheckboxChange = (row) => {
        const newSelectedRows = [...selectedRows];
        const index = newSelectedRows.findIndex((selectedRow) => selectedRow.Id === row.Id);
    
        if (index === -1) {
          newSelectedRows.push(row);
        } else {
          newSelectedRows.splice(index, 1);
        }
    
        setSelectedRows(newSelectedRows);
        setSelectAll(false);
      };
    

      function calculatesopPercentage(arrayName, targetStage) {

        // Your provided object
        const stagesObject = selectoptions;
      
        // Get the array based on the provided arrayName
        const targetArray = stagesObject[arrayName];
      
        if (targetArray) {
          // Find the index of the targetStage in the array
          const stageIndex = targetArray.indexOf(targetStage);
      
          // Calculate the percentage
          const percentage = ((stageIndex + 1) / targetArray.length) * 100;
      
          return percentage.toFixed(0);
        }
      }  
  

      function updateOptions (value, keynamevalue,LeadData ){

    
        let finaldata = { ...LeadData, [keynamevalue]: value };
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth}`,
        };
        axios.post("/update_leads", finaldata, { headers }).then((data) => {
          if (data.status == "200") {
    
            // setLeadData(finaldata);

            // serachSop();
    
    
          }
        });
    
      }

    function submitAdvanceFilter () {

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth}`,
        };
        axios
          .post("/get_leads_advance",{
            "leadfilter": {
                "pipeline": "Partners",
                "pipelineCurrentStage": "At Risk"
            }
        }, {
            headers,
          })
          .then((data) => {
            if (data.status == "200") {
                setFilterLeads(data.data);
            }
    
          })
          .catch((err) => {
            console.log(err);
          });
      };  

      function convertDateFormat(dateString) {
        const dateObj = new Date(dateString);
        
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        
        return `${day}-${month}-${year}`;
    }    

    useEffect(()=>{

      submitAdvanceFilter();
    
  
    },[])

  return (
    <>
      

      <div className="container-fluid">
        <br/>
        
                          <div className="row">
                          <div className="col-3">{selectedRows.length > 0 ? (
                            <div>
                              <div class="input-group input-group-sm mb-2">
  <select class="form-select border border-dark" id="inputGroupSelect04" aria-label="Example select with button addon" value={actiontype} onChange={handleSelectAction}>
    <option selected>Choose action</option>
    {/* <option value='1'>Bulk edit</option> */}
    <option value="2">Copy email list</option>
    <option value="3">Copy contact number list</option>
  </select>
  <button class="btn btn-dark" onClick={handleActionShow} type="button">Submit</button>
</div>
                            </div>
                          ) : null}</div>
                          </div>
                          
                          <Modal show={showAction} onHide={handleActionClose} size="lg">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Bulk select</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
         <ActionModel bulkvalue={selectedRows} actiontype={actiontype} />
          

          



        </Modal.Body>
      </Modal>
        <table className="table table-striped">
  <thead>
    <tr>
    <th scope="col"> <input
              type="checkbox"
              onChange={handleSelectAllChange}
              checked={selectAll}
            /></th>
      <th scope="col">#</th>
      <th scope="col"><i className="bi bi-card-heading"></i> Lead Title</th>
      <th scope="col"><i className="bi bi-person-fill"></i> Contact person</th>
      <th scope="col"><i className="bi bi-building"></i> Organization</th>
      <th scope="col"><i className="bi bi-person-circle"></i> Lead Owner</th>
      <th scope="col"><i className="bi bi-geo-fill"></i> Status</th>
      <th scope="col"><i className="bi bi-clock"></i> Deadline</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>

  {filterLeads &&
                    filterLeads?.map((item) => (

                      <tr key={item.Id}>
                       <td>
                       <input
                type="checkbox"
                onChange={() => handleCheckboxChange(item)}
                checked={selectedRows.some((selectedRow) => selectedRow.Id === item.Id)}
              />
            </td>
                      <th scope="row"><span className={item.priority=='low'?'text-primary'
    :item.priority=='medium'?'text-warning'
  :item.priority=='high'?'text-danger':null
  
  }><i className="bi bi-circle-fill"></i>
  </span> <span className="badge bg-dark"># {item.TicketId}</span>

  
  </th>
                      <td>{item.title==""?<>{item.contactPerson}</>:<>{item.title}</>
                        }</td>
                      <td>{item.contactPerson}</td>
                      <td>{item.organization}</td>
                      <td>{removeEverythingBeforeComma(item.owner)}</td>
                      <td>
                      <div className="dropdown">

  <span className="badge bg-light text-dark cursor"id={'dropdownMenuLink' + item.Id}
    data-bs-toggle="dropdown" >
      <i className="bi bi-pencil-square"></i> {item.pipelineCurrentStage}</span> 
  <ul className="dropdown-menu" aria-labelledby={'dropdownMenuLink' + item.Id}>
    
  {listsop &&
                    listsop?.map((item1, index) => (
<>{

item.pipelineCurrentStage != item1.process_catagory?<><li>
<a className="dropdown-item" href="#" onClick={()=>updateOptions(item1.process_catagory, 'pipelineCurrentStage' ,item )}>
{item1.process_catagory}
</a>
</li></>:null
}
</>

                      ))}
    
   
  </ul>
</div>
                      </td>
                      <td>{convertDateFormat(item.expectedClosingDate)}</td>
                      <td><i className="bi bi-arrows-angle-expand arrowexpand" 
                      // onClick={()=>handleShowLead(item, item.pipeline)}
                      ></i></td>
                    </tr>

                    ))}

   


  </tbody>
</table>







</div>
      
    </>
  );
}

export default TableCard;
