import React, { Component } from "react";
import avatar from "../media/Podcast.png";

function Podcast() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <br />
            <br />
            <div align="center">
              <div
                className="card p-3 mb-5 border border-white shadow-sm bg-dark"
                style={{ width: "20rem" }}
              >
                <div className="card-body">
                  <img
                    className="rounded"
                    src={avatar}
                    width="200"
                    height="200"
                  ></img>

                  <br />
                  <br />
                  <figure>
                    <blockquote className="blockquote text-white">
                      <p>Sell like a Superman!</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                      Sales Sunday
                    </figcaption>
                  </figure>
                  <p className="text-secondary">
                    Sales Sunday is here to make sales easy! Sales is a skill
                    that can be tough. It's like playing guitar: the more you
                    practice the better you become. We at Sales Sunday are here
                    to teach and improve sales and help you build customer
                    relations
                  </p>
                  <h3 className="text-white">
                    <a
                      href="https://www.linkedin.com/company/salessunday/"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>{" "}
                    <a
                      href="https://open.spotify.com/show/7A7jEQukeslDKPZJj0nqCu"
                      target="_blank"
                    >
                      <i className="bi bi-spotify"></i>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="container">
              <br />
              <br />
              <h3>
                Episodes <i className="bi bi-music-player"></i>{" "}
              </h3>
              <br />
              <iframe
                src="https://anchor.fm/sales-sunday/embed/episodes/How-to-Sell-Anything-e1m72ad"
                height="160px"
                width="627px"
                frameBorder={0}
                scrolling="no"
              />
              <br />
              <iframe
                src="https://anchor.fm/sales-sunday/embed/episodes/How-to-become-a-top-salesperson-e1m72ov"
                height="160px"
                width="627px"
                frameBorder={0}
                scrolling="no"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Podcast;
