import React from "react";
import { Button } from 'react-bootstrap';
import logo from "../media/Sales.gif";
import LandingForm from "./LandingForm";
import { useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getselect_options
} from "../redux/slices/leadSlice";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


var url_string = window.location.href;
var url = new URL(url_string);
var og = url.searchParams.get("og");
var org = url.searchParams.get("og");
var sourcename = url.searchParams.get("q");

const Leadcapture = () => {



  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");
  const [orgData, setOrgData] = useState({
    organisation_type: "",
    organisation_category: "",
    total_employees: "",
    gst: "",
    state: "",
    pin_code: "",
    billing_address: "",
  });

 
  const [feedOrg, setOrgFeed] = useState("");
  const [profilepic, setProfilePic] = useState("");


  const [selectoptions, setSelectoptions] = useState("");

  const [ userFeed, setUserFeed] = useState([{'user_name' : '', 'id' : ''}]);

console.log(userFeed[0]['user_name'], 'suernmae')

  const [formData, setFormData] = useState({
    contactPerson: '',
    organization: '',
    title: '',
    value: 0,
    currency: 'INR',
    pipeline: '',
    pipelineCurrentStage: '',
    expectedClosingDate: '',
    Lead_Source:sourcename,
    owner: userFeed[0]['user_name'].concat('-',userFeed[0]['id']),
    Description: '',
    visibleTo: 'All users',
    phone: [''],
    email: [''],
    lead_status:""

  });

  console.log(formData, 'form data');

  const [formFields, setFormFields] = useState([]);


  const [sopOpt, setSopOpt] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  
  

  const handlePhoneChange = (index, value) => {
    setFormData((prevData) => {
      const phone = [...prevData.phone];
      phone[index] = value;
      return {
        ...prevData,
        phone
      };
    });
  };

  const handleEmailChange = (index, value) => {
    setFormData((prevData) => {
      const email = [...prevData.email];
      email[index] = value;
      return {
        ...prevData,
        email
      };
    });
  };

  const handleAddPhone = () => {
    setFormData((prevData) => ({
      ...prevData,
      phone: [...prevData.phone, '']
    }));
  };

  const handleRemovePhone = (index) => {
    setFormData((prevData) => {
      const phone = [...prevData.phone];
      phone.splice(index, 1);
      return {
        ...prevData,
        phone
      };
    });
  };

  const handleAddEmail = () => {
    setFormData((prevData) => ({
      ...prevData,
      email: [...prevData.email, '']
    }));
  };

  const handleRemoveEmail = (index) => {
    setFormData((prevData) => {
      const email = [...prevData.email];
      email.splice(index, 1);
      return {
        ...prevData,
        email
      };
    });
  };

  const validateForm = () => {
    const requiredFields = ['title','contactPerson', 'pipeline', 'pipelineCurrentStage']; // Add other required fields here
    const errors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field} is required`;
      }
    });

    return errors;
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
  
    // Use the callback form of setFormData to ensure the second update depends on the latest state
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value
      };
  
      // Retrieve the value at index 0 of selectoptions[updatedData.pipeline]
      let temp = selectoptions[updatedData.pipeline][0];
  
      // Update the pipelineCurrentStage in the same callback
      updatedData['pipelineCurrentStage'] = temp;
  
      // Return the updated data
      return updatedData;
    });
  };

  
function getOptionData() {
  dispatch(getselect_options({org })).then((res) => {
    if (res.payload.status == 200) {
      setSelectoptions(res.payload.data);
    }
  }); 
}
 
  function fetchOrgData() {
    
    const headers = {
      "Content-Type": "text/plain",
      
    };
    axios
      .get("/Source_Get_organisation?organisation_id=".concat(og), { headers })
      .then((data) => {
        if (data.status == "200") {
          setOrgFeed(data.data[0]);
          setProfilePic(data.data[0].ImageLink);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getAllsops() {
    
    const headers = {
      "Content-Type": "text/plain",
      
    };
    axios
      .get("/get_sop_name_open?organisation_id=".concat(og), { headers })
      .then((data) => {
        if (data.status == "200") {
          setSopOpt(data.data);
       
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getAllusers() {
    
    const headers = {
      "Content-Type": "text/plain",
      
    };
    axios
      .get("/getusers_open?organisation_id=".concat(og), { headers })
      .then((data) => {
        if (data.status == "200") {

          setUserFeed(data.data);
          setFormData({
            contactPerson: '',
            organization: '',
            title: '',
            value: 0,
            currency: 'INR',
            pipeline: '',
            pipelineCurrentStage: '',
            expectedClosingDate: '',
            Lead_Source:sourcename,
            owner: data.data[0]['user_name'].concat('-',data.data[0]['id']),
            Description: '',
            visibleTo: 'All users',
            phone: [''],
            email: [''],
            lead_status:""
        
          });
          
       
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

      ////get form data

      function getFormData() {
        const headers = {
          "Content-Type": "text/plain",
        };
        axios
          .get(
            "/get_addformfields_open?organisation_id=".concat(og),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setFormFields(data.data.data_format);
            }
    
            // setErrorMessage1(data.data.message);
          })
          .catch((err) => console.log("error", err));
      }
  
      const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
    
        if (Object.keys(errors).length > 0) {
          // Handle form errors, e.g., display error messages
          console.log('Form has errors:', errors);
    
          toast.warn("Required fields are missing!", {
            position: "bottom-left",
            autoClose: 1000,
          });
    
        } else {
          e.preventDefault();
          // Handle form submission and data storage
          sendLead();
        }
      };

      function sendLead() {
        const headers = {
          "Content-Type": 'application/json',
        };
        axios
          .post('/add_lead_open?organisation_id='.concat(og),formData, { headers })
          .then((data) => {
            if (data.status == "200") {
    
              toast.success("Lead add successfully!", {
                position: "bottom-left",
                autoClose: 1000,
              });
    
              setFormData({
                contactPerson: '',
                organization: '',
                title: '',
                value: 0,
                currency: 'INR',
                pipeline: '',
                pipelineCurrentStage: '',
                expectedClosingDate: '',
                Lead_Source:sourcename,
                owner: userFeed[0]['user_name'].concat('-',userFeed[0]['id']),
                Description: '',
                visibleTo: 'All users',
                phone: [''],
                email: [''],
                lead_status:""
            
              });
    
    
             
            }
      
          })
          .catch((err) => console.log(err));
      }
        
  function clearForm(){
    
  }
  
  useEffect(() => {
    fetchOrgData();
    getOptionData();
    getAllsops();
    getAllusers();
    getFormData();
    
  }, []);

    return(
        <>
            <nav className="navbar">
    <div className="container-fluid">
      <a className="navbar-brand" href="#">
        {profilepic!=""? <img src={profilepic} alt="" height={40} className="d-inline-block align-text-top" />: <img src={logo} alt="" width={55} height={40} className="d-inline-block align-text-top" />}
       
        &nbsp; <b>{feedOrg.organisation_name}</b>
      </a>
    
    </div>
  </nav>
  <div className="container">
        <div align='center' >
          <br/>
      <div className="card col-sm-4 col-12 border-dark border-3 shadow card_design">

        <div className="card-header border-0">
          <b><i className="bi bi-person-lines-fill"></i> Submit your lead here <i className="bi bi-arrow-down"></i></b>
        </div>
        <div className="card-body" align='left'>
        <div className='container mt-2'>
        <div className='row'>

<div className='col-12'>
  
<div className="input-group mb-3 ">

<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-circle-fill"></i></span>
<input
type="text"
name="title"
class="form-control form-control-sm border-secondary border-secondary"
value={formData.title}
onChange={handleChange}
placeholder="Lead title *"
/>
</div>

<div className="input-group mb-3 border-secondary">

<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-person-circle"></i></span>
<input
type="text"
name="contactPerson"
class="form-control form-control-sm border-secondary"
value={formData.contactPerson}
onChange={handleChange}
placeholder=" Contact person name *"
/>
</div>


<div className="input-group mb-3 border-secondary">
<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-building"></i></span>

<input
type="text"
name="organization"
class="form-control form-control-sm border-secondary"
value={formData.organization}
onChange={handleChange}
placeholder="Organization"
/>
</div>






<div className='row'>

<div className='col-12'>
<div className="input-group mb-3 border-secondary">

<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-funnel"></i></span>

<select
  className="form-select border-secondary"
  id="inputGroupSelect04"
  name="pipeline"
  value={formData.pipeline}
onChange={handleChange2}
  aria-label="Example select with button addon"
>
  <option selected value="">Choose pipeline</option>
   {sopOpt &&
                        sopOpt?.map((item) => (
                          <option value={item}>{item}</option>
                          ))}
</select>

</div>
</div>
{/* {formData.pipeline != ''?<>
<div className='col-12'>
<div className="input-group mb-3 border-secondary">
<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-geo-fill"></i></span>

<select
  className="form-select"
  id="inputGroupSelect04"
  name="pipelineCurrentStage"
  value={formData.pipelineCurrentStage}
onChange={handleChange}
  aria-label="Example select with button addon"
>
  <option selected="">Current stage</option>
  {selectoptions[formData.pipeline] &&
                                    selectoptions[formData.pipeline]?.map((item) => (
                                      <option value={item}>{item}</option>
                                      ))}
  
</select>
</div>
</div></>
:null

} */}



</div>

{formData.phone?.map((phone, index) => (
  <>
  <div key={index} className='row'>
         <div className='col-10'> 
         
         <div className="input-group mb-2">
         <span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-phone-fill"></i></span>

         <input
              type="text"
              value={phone}
              onChange={(e) => handlePhoneChange(index, e.target.value)}
              class="form-control form-control-sm border-secondary"
              placeholder="Contact number"
            />

         </div>
        
     </div>
         <div className='col-2'>
         {index > 0 && (
            <button type="button"  class="btn btn-dark btn-sm" onClick={() => handleRemovePhone(index)}>
              <i className="bi bi-x-lg"></i>
            </button>
          )}
         </div>
           
         
        </div>
  </>
        
      ))}

<span onClick={handleAddPhone} className="badge bg-dark cursor mb-3"><i className="bi bi-plus-circle-fill"></i> Add phone</span>

      {/* Email */}
      
      {formData.email?.map((email, index) => (
        <div key={index} className='row'>

<div className='col-10'>
<div className="input-group mb-2">
<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-envelope-fill"></i></span>

  <input
              type="text"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              class="form-control form-control-sm border-secondary"
              placeholder="Email id"
            />
            </div>
            </div>
<div className='col-2'>{index > 0 && (
            <button type="button"  class="btn btn-dark btn-sm" onClick={() => handleRemoveEmail(index)}>
              <i className="bi bi-x-lg"></i>
            </button>
          )}</div>
          
            
       
          
        </div>
      ))}
      
<span onClick={handleAddEmail} className="badge bg-dark cursor mb-2"><i className="bi bi-plus-circle-fill"></i> Add email</span>
      
      

<div className='row'>

  <div className='col-12'>
  <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Estimated lead value <i class="bi bi-arrow-down"></i>
                                </label>
  <div className="input-group mb-3 border-secondary">
  <span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-cash-coin"></i></span>

  <input
type="text"
name="value"
class="form-control form-control-sm border-secondary"
value={formData.value}
onChange={handleChange}
placeholder="Lead value"
/>

<select
  className="form-select border-secondary"
  id="inputGroupSelect04"
  name="currency"
  value={formData.currency}
onChange={handleChange}
  aria-label="Example select with button addon"
>
  <option value="INR">INR</option>
  <option value="USD">USD</option>
  <option value="EURO">EURO</option>
  <option value="AED">AED</option>
  </select>
</div>

  </div>
</div>



<>
  <p
  className="cursor"
    data-bs-toggle="collapse"
    data-bs-target="#collapseExample"
    aria-expanded="false"
    aria-controls="collapseExample"
  >
   Description <i className="bi bi-caret-down-fill"></i>
  </p>
  <div className="collapse" id="collapseExample">
    <div className="mb-3">
  <textarea
    className="form-control border border-secondary"
    id="exampleFormControlTextarea1"
    name="Description"
    value={formData.Description}
    onChange={handleChange}
    rows={3}
    defaultValue={""}
  />
    </div>
  </div>
</>



<div className="input-group mb-3 border-secondary">
<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-person-badge"></i></span>
<select
  className="form-select border-secondary"
  id="inputGroupSelect04"
  name="owner"
  value={formData.owner}
onChange={handleChange}
  aria-label="Example select with button addon"
><option selected="">Assign lead</option>
{userFeed &&
                                          userFeed?.map((item) => (
                                            <option
                                              value={
                                                item.user_name + "-" + item.id
                                              }
                                            >
                                              {item.user_name}-{item.role}
                                            </option>
                                          ))}
  </select>
</div>



{formFields != ''?
<p>Other options <i className="bi bi-arrow-down"></i></p>
:null  }


{formFields?.map((item,index)=>(

<><div className="input-group mb-3 border-secondary">

<span className="input-group-text border-secondary" id="basic-addon1"><i className="bi bi-circle-fill"></i> </span>


{

item.type =='select'?
<>
<select
  className="form-select border-secondary cursor"
  id="inputGroupSelect04"
  name={item.name}
  value={formData[item.name]}
onChange={handleChange}
  aria-label="Example select with button addon"
>
<><option>Select {item.name}</option></>
  {
    item.options?.map((item1,index)=>(
      <><option value={item1}>{item1}</option></>
    ))

  }

                                      
  </select>
</>

:<><input
type={item.type}
name={item.name}
class="form-control form-control-sm border-secondary"
// value={formData.expectedClosingDate}
value={formData[item.name]}
onChange={handleChange}
placeholder={item.name}
/></>

}


</div></>

))}

<div className="mb-5"><Button variant="dark" size="sm" onClick={handleSubmit} className="pop">
<i className="bi bi-arrow-right"></i> Submit
</Button>
</div>

</div>

</div>
</div>
        </div>
      </div>
      </div>
      </div>
     
        <p align='center'>
          <br/>
          Powered by <a href='http://salessunday.com/' target="_blank"><b>SalesSunday</b></a> | <i class="bi bi-lock-fill"></i> Secure connection.
        </p>
        <ToastContainer />

        </>
    );

}

export default Leadcapture;