import React, { Component, useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserData
} from "../redux/slices/homeSlice";

import {
  getselect_options
} from "../redux/slices/leadSlice";




import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  getSourceListData,
} from "../redux/slices/dataSlice";


import Customizeform from "./Customizeform";

const admin = localStorage.getItem("admin");

const org = localStorage.getItem("org");
const access_token = localStorage.getItem("Auth");
const Auth = localStorage.getItem("Auth");
const uid = localStorage.getItem("token");
const user_name = localStorage.getItem("user_name");



const Addlead = () => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 


  const [selectoptions, setSelectoptions] = useState({});

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    contactPerson: '',
    organization: '',
    title: '',
    value: 0,
    currency: 'INR',
    pipeline: '',
    pipelineCurrentStage: '',
    expectedClosingDate: '',
    Lead_Source:'',
    owner: user_name.concat('-',uid),
    Description: '',
    visibleTo: 'All users',
    phone: [''],
    email: [''],
    lead_status:""

  });


  console.log(formData);

  const [formFields, setFormFields] = useState([
  ]);

 



  const validateForm = () => {
    const requiredFields = ['title','contactPerson', 'pipeline', 'pipelineCurrentStage']; // Add other required fields here
    const errors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field} is required`;
      }
    });

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Handle form errors, e.g., display error messages
      console.log('Form has errors:', errors);

      toast.warn("Required fields are missing!", {
        position: "bottom-left",
        autoClose: 1000,
      });

    } else {
      e.preventDefault();
      // Handle form submission and data storage
      sendLead();
    }
  };


  const [feed, setFeed] = useState();
  console.log(formData);

  const [addsource, setAddscource] = useState("");
  const [sourceList, setSourceList] = useState([]);
  const [listsop, setlistsop] = useState([]);


  const [feedSopCategory, setSopCategory] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    let tempdata = formData.pipeline;

    // getSopProcess(formData.pipeline);


  };



  // function getSopProcess(item) {

  //   const headers = {
  //     "Content-Type": "text/plain",
  //     Authorization: `Bearer ${Auth}`,
  //   };


  //     axios
  //       .get("/Get_sop_process?SopName=".concat(item), {
  //         headers,
  //       })
  //       .then((data) => {
  //         if (data.status == "200") {
  //           setlistsop(data.data);

            
  //         }
          
  //       })
  //       .catch((err) => console.log(err));

   
  // }


  const handlePhoneChange = (index, value) => {
    setFormData((prevData) => {
      const phone = [...prevData.phone];
      phone[index] = value;
      return {
        ...prevData,
        phone
      };
    });
  };

  const handleEmailChange = (index, value) => {
    setFormData((prevData) => {
      const email = [...prevData.email];
      email[index] = value;
      return {
        ...prevData,
        email
      };
    });
  };

  const handleAddPhone = () => {
    setFormData((prevData) => ({
      ...prevData,
      phone: [...prevData.phone, '']
    }));
  };

  const handleRemovePhone = (index) => {
    setFormData((prevData) => {
      const phone = [...prevData.phone];
      phone.splice(index, 1);
      return {
        ...prevData,
        phone
      };
    });
  };

  const handleAddEmail = () => {
    setFormData((prevData) => ({
      ...prevData,
      email: [...prevData.email, '']
    }));
  };

  const handleRemoveEmail = (index) => {
    setFormData((prevData) => {
      const email = [...prevData.email];
      email.splice(index, 1);
      return {
        ...prevData,
        email
      };
    });
  };


/////////////////////////////////Api calls////////////////////////////////////

function fetchUserData() {
  dispatch(getUserData({ access_token, org })).then((res) => {
    if (res.payload.status == 200) {
      setFeed(res.payload.data);
    }
  });
  
}


function getOptionData() {
  dispatch(getselect_options({org })).then((res) => {
    if (res.payload.status == 200) {
      setSelectoptions(res.payload.data);
    }
  });
  
}


const getSourceList = () => {
  dispatch(getSourceListData({ access_token: Auth })).then((res) => {
    if (res.payload.status == "200") {
      setSourceList(res.payload.data);
    }
  });
};
const handleAddSource = () => {
  if (addsource) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post(`/Add_contactSource?Contact_Source=${addsource}`, {}, { headers })
      .then((data) => {
        if (data.status == "200") {
          setAddscource("");
          getSourceList();
          toast.success("Source Add Successfully!", {
            position: "bottom-left",
            autoClose: 1000,
          });
        }
      });
  }
};

  function getAllSop() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setSopCategory(data.data);
        }
      })
      .catch((err) => console.log(err));
  }

  function sendLead() {
    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/add_lead',formData, { headers })
      .then((data) => {
        if (data.status == "200") {

          toast.success("Lead add successfully!", {
            position: "bottom-left",
            autoClose: 1000,
          });

          setFormData({
            contactPerson: '',
            organization: '',
            title: '',
            value: 0,
            currency: 'INR',
            pipeline: '',
            pipelineCurrentStage: '',
            expectedClosingDate: '',
            Lead_Source:'',
            owner: user_name.concat('-',uid),
            Description: '',
            visibleTo: 'All users',
            phone: [''],
            email: [''],
            lead_status:""
          });


         
        }
  
      })
      .catch((err) => console.log(err));
  }


    ////get form data

    function getFormData() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/get_addformfields",
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setFormFields(data.data.data_format);
          }
  
          // setErrorMessage1(data.data.message);
        })
        .catch((err) => console.log("error", err));
    }


  useEffect(() => {
    getOptionData();
    fetchUserData();
    getAllSop();
    getSourceList();
    getFormData();
    
  }, [1]);

  // useEffect(() => {
  //   getSopProcess(formData.pipeline);
  // }, [formData.pipeline]);


  return (
<div className='container '>
<div
                  className="overflow-auto border-end p-2"
                  style={{  height: "95vh" }}
                >

<div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
  <button className="btn btn-outline-secondary btn-sm" type="button" onClick={handleShow}>
  <i class="bi bi-gear-fill"></i> Customize form
  </button>
</div>

      <Modal  size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><i className="bi bi-textarea-resize"></i> Add lead options </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <Customizeform/>          


        </Modal.Body>
      </Modal>
 


                 

<div className='row'>

<div className='col-12'>
  
<div className="input-group mb-3 border-dark">

<span className="input-group-text" id="basic-addon1"><i className="bi bi-circle-fill"></i></span>
<input
type="text"
name="title"
class="form-control form-control-sm"
value={formData.title}
onChange={handleChange}
placeholder="Lead title *"
/>
</div>

<div className="input-group mb-3 border-dark">

<span className="input-group-text" id="basic-addon1"><i className="bi bi-person-circle"></i></span>
<input
type="text"
name="contactPerson"
class="form-control form-control-sm"
value={formData.contactPerson}
onChange={handleChange}
placeholder=" Contact person name *"
/>
</div>


<div className="input-group mb-3 border-dark">
<span className="input-group-text" id="basic-addon1"><i className="bi bi-building"></i></span>

<input
type="text"
name="organization"
class="form-control form-control-sm"
value={formData.organization}
onChange={handleChange}
placeholder="Organization"
/>
</div>






<div className='row'>

<div className='col-12'>
<div className="input-group mb-3 border-dark">

<span className="input-group-text" id="basic-addon1"><i className="bi bi-funnel"></i></span>

<select
  className="form-select"
  id="inputGroupSelect04"
  name="pipeline"
  value={formData.pipeline}
onChange={handleChange2}
  aria-label="Example select with button addon"
>
  <option selected value="">Choose pipeline</option>
   {feedSopCategory &&
                        feedSopCategory?.map((item) => (
                          <option value={item}>{item}</option>
                          ))}
</select>

</div>
</div>
{formData.pipeline != ''?<>
<div className='col-12'>
<div className="input-group mb-3 border-dark">
<span className="input-group-text" id="basic-addon1"><i className="bi bi-geo-fill"></i></span>

<select
  className="form-select"
  id="inputGroupSelect04"
  name="pipelineCurrentStage"
  value={formData.pipelineCurrentStage}
onChange={handleChange}
  aria-label="Example select with button addon"
>
  <option selected="">Current stage</option>
  {selectoptions[formData.pipeline] &&
                                    selectoptions[formData.pipeline]?.map((item) => (
                                      <option value={item}>{item}</option>
                                      ))}
  
</select>
</div>
</div></>
:null

}



</div>

{formData.phone?.map((phone, index) => (
  <>
  <div key={index} className='row'>
         <div className='col-10'> 
         
         <div className="input-group mb-2">
         <span className="input-group-text" id="basic-addon1"><i className="bi bi-phone-fill"></i></span>

         <input
              type="text"
              value={phone}
              onChange={(e) => handlePhoneChange(index, e.target.value)}
              class="form-control form-control-sm"
              placeholder="Contact number"
            />

         </div>
        
     </div>
         <div className='col-2'>
         {index > 0 && (
            <button type="button"  class="btn btn-dark btn-sm" onClick={() => handleRemovePhone(index)}>
              <i className="bi bi-x-lg"></i>
            </button>
          )}
         </div>
           
         
        </div>
  </>
        
      ))}

<span onClick={handleAddPhone} className="badge bg-dark cursor mb-3"><i className="bi bi-plus-circle-fill"></i> Add phone</span>

      {/* Email */}
      
      {formData.email?.map((email, index) => (
        <div key={index} className='row'>

<div className='col-10'>
<div className="input-group mb-2">
<span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope-fill"></i></span>

  <input
              type="text"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              class="form-control form-control-sm"
              placeholder="Email id"
            />
            </div>
            </div>
<div className='col-2'>{index > 0 && (
            <button type="button"  class="btn btn-dark btn-sm" onClick={() => handleRemoveEmail(index)}>
              <i className="bi bi-x-lg"></i>
            </button>
          )}</div>
          
            
       
          
        </div>
      ))}
      
<span onClick={handleAddEmail} className="badge bg-dark cursor mb-2"><i className="bi bi-plus-circle-fill"></i> Add email</span>
      
      

<div className='row'>

  <div className='col-12'>
  <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Estimated lead value <i class="bi bi-arrow-down"></i>
                                </label>
  <div className="input-group mb-3 border-dark">
  <span className="input-group-text" id="basic-addon1"><i className="bi bi-cash-coin"></i></span>

  <input
type="text"
name="value"
class="form-control form-control-sm"
value={formData.value}
onChange={handleChange}
placeholder="Lead value"
/>

<select
  className="form-select"
  id="inputGroupSelect04"
  name="currency"
  value={formData.currency}
onChange={handleChange}
  aria-label="Example select with button addon"
>
  <option value="INR">INR</option>
  <option value="USD">USD</option>
  <option value="EURO">EURO</option>
  <option value="AED">AED</option>
  </select>
</div>

  </div>
</div>

<div className="row">
                          <div className="col-12">
                            <p
                              className="collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do you have a source?
                              <i className="bi bi-caret-down-fill"></i>
                            </p>
                          </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="row">
                            <div className="col-5">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select source
                                </label>
                                <select
                                  value={formData.Lead_Source}
                                  onChange={handleChange}
                                  name="Lead_Source"
                                  className="form-select form-select-sm mb-3 col-6 border border-dark"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item) => (
                                      <option value={item} selected>
                                        {item}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-7">
                             
                                <div className="mb-3">
                                  <label className="form-label">
                                    Add new source
                                  </label>
                                 
 
 <div className="input-group input-group-sm ">
  <input
    type="text"
    name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    }
    className="form-control border border-dark"
    placeholder="New source name"
    aria-label="New source name"
    aria-describedby="button-addon2"
  />
  <button
    className="btn btn-outline-secondary"
    type="button"
    id="button-addon2"
    disabled={!addsource}
    onClick={handleAddSource}
  >
    Add
  </button>
</div>

                                </div>
                           
                            </div>
                            
                          </div>
                        </div>


  <div className='col-12'>
<div className="input-group mb-3 border-dark">
<span className="input-group-text" id="basic-addon1"><i className="bi bi-fan"></i></span>

<select
  className="form-select"
  id="inputGroupSelect04"
  name="priority"
  value={formData.priority}
onChange={handleChange}
  aria-label="Example select with button addon"
>
  <option selected="">Priority</option>
  <option value="low">Low</option>
  <option value="medium">Medium</option>
  <option value="high">High</option>
</select>
</div>
</div>

<>
  <p
  className="cursor"
    data-bs-toggle="collapse"
    data-bs-target="#collapseExample"
    aria-expanded="false"
    aria-controls="collapseExample"
  >
   Description <i className="bi bi-caret-down-fill"></i>
  </p>
  <div className="collapse" id="collapseExample">
    <div className="mb-3">
  <textarea
    className="form-control"
    id="exampleFormControlTextarea1"
    name="Description"
    value={formData.Description}
    onChange={handleChange}
    rows={3}
    defaultValue={""}
  />
    </div>
  </div>
</>

<p>Closing Date <i class="bi bi-arrow-down"></i></p>

<div className="input-group mb-3 border-dark">

<span className="input-group-text" id="basic-addon1"><i className="bi bi-calendar"></i> </span>

<input
type="date"
name="expectedClosingDate"
class="form-control form-control-sm"
value={formData.expectedClosingDate}
onChange={handleChange}
placeholder="Expected closing date"
/>
</div>


<div className="input-group mb-3 border-dark">
<span className="input-group-text" id="basic-addon1"><i className="bi bi-person-badge"></i></span>
<select
  className="form-select"
  id="inputGroupSelect04"
  name="owner"
  value={formData.owner}
onChange={handleChange}
  aria-label="Example select with button addon"
><option selected="">Assign lead</option>
{feed &&
                                          feed?.map((item) => (
                                            <option
                                              value={
                                                item.user_name + "-" + item.id
                                              }
                                            >
                                              {item.user_name}-{item.role}
                                            </option>
                                          ))}
  </select>
</div>

<div className="input-group mb-3 border-dark">

<span className="input-group-text" id="basic-addon1"><i className="bi bi-eye-fill"></i></span>

<select
  className="form-select cursor"
  id="inputGroupSelect04"
  name="visibleTo"
  value={formData.visibleTo}
onChange={handleChange}
  aria-label="Example select with button addon"
><option selected="">Choose visible to</option>
<option value="Item owner">Item owner & admin </option>
<option value="All users">All users </option>
<option value="Only admin">Only admin </option>
                                      
  </select>
</div>

{formFields != ''?
<p>Other options <i className="bi bi-arrow-down"></i></p>
:null  }


{formFields
  .filter(item => {
    return (
      !item.setting ||
      item.setting === "All SOPs" ||
      (item.setting === "Custom SOPs" && item.selectedSops.includes(formData.pipeline))
    );
  })
  .map((item, index) => (
    <React.Fragment key={index}>
      {item.type == 'date' || item.type == 'datetime-local'?<><label className="form-label">{item.name}</label>
          </>:null }
      <div className="input-group mb-3 border-dark">
        <span className="input-group-text" id="basic-addon1">
          <i className="bi bi-circle-fill"></i>
        </span>
        
        {item.type === "select" ?  (
          <select
            className="form-select cursor"
            id="inputGroupSelect04"
            name={item.name}
            value={formData[item.name]}
            onChange={handleChange}
            aria-label="Example select with button addon"
          >
            <option>Select {item.name}</option>
            {item.options?.map((item1, index) => (
              <option key={index} value={item1}>
                {item1}
              </option>
            ))}
          </select>
        ) : (

          <>

          <input
            type={item.type}
            name={item.name}
            className="form-control form-control-sm"
            value={formData[item.name]}
            onChange={handleChange}
            placeholder={item.name}
          />
          </>
        )}
      </div>
    </React.Fragment>
  ))}




</div>

</div>
   
     
<div className="mb-5"><Button variant="dark" size="sm" onClick={handleSubmit} className="pop">
<i className="bi bi-arrow-right"></i> Submit
</Button>
<br/></div>


                </div>
             

    <ToastContainer />
</div>



   
  );
};

export default Addlead;
