import React, { Component } from "react";
import ContactUs from "./ContactUs";
import integrationpng from "../media/undraw_programming_re_kg9v.svg";


function Integration() {


  return (
    <>
     

      <div className="container">
        <br/><br/>
  <div className="row">
    <div className="col-7"><br/><br/><img src={integrationpng} height={400} /></div>
    <div className="col-5"><br/><br/><h4>Integration with Sales Sunday.</h4><br/><ContactUs/></div>
  </div>
      </div>
    </>
  );
}

export default Integration;
