import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip1 from 'react-bootstrap/Tooltip';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import {
  ResponsiveContainer,
  Sankey
} from "recharts";
import {
  Tooltip,
} from "recharts";
import { ToastContainer, toast } from "react-toastify";
import _, { functionsIn } from 'lodash';
import DemoSankeyNode from '../extra/DemoSankeyNode';
import nature from "../media/undraw_cabin_hkfr.svg";
import treepic from "../media/undraw_designer_re_5v95.svg";
import globesop from "../media/undraw_city_life_gnpr.svg";
import emptylist from "../media/undraw_no_data_re_kwbl.svg";
import Joyride from 'react-joyride';
import { useSelector, useDispatch } from "react-redux";
import { getProgressBarStatus } from "../redux/slices/homeSlice";
import Soppreview from "../components/Soppreview";

const admin = localStorage.getItem("admin");

const org = localStorage.getItem("org");
const Auth = localStorage.getItem("Auth");

const tostMsg = (item) => {
  toast.success(item, {
    position: "bottom-left",
    autoClose: 1000,
  });
};

const tostWaitMsg = () => {
  toast.info("Please Wait", {
    position: "bottom-left",
    autoClose: 1000,
  });
};

const steps =[
  // {title: "Add SOP", content:"Please include all your Standard Operating Procedures (SOPs) in this section, covering processes associated with sales, operations, and support. This encompasses activities such as customer onboarding, KYC processes, and customer support for your specific product or service.",target:"#popsript",placement:"bottom"},
  // {title: "Import SOP", content:"Choose a process employed by leading companies and integrate it into the newly added Standard Operating Procedure (SOP).",target:"#aisop",placement:"bottom"},
  // {title: "Add calls", content:"Click here to add and edit calls and tasks. Once completed, add calls and tasks into the stages of the Standard Operating Procedures (SOPs) you created earlier.",target:"#designcall",placement:"bottom"},
]


function Sop() {


  const dispatch = useDispatch();

  const renderTooltip = (props) => (
    <Tooltip1 id="button-tooltip" {...props}>
      {props}
    </Tooltip1>
  );

  const [livesop, setlivesop] = useState();
  const [livemodel, setlivemodel] = useState();

  const [block, setblock] = useState({ process_catagory: "", process_desc:"", Instructions:[]});

  console.log(block);
  const [feedSopCategory, setSopCategory] = useState();
  const [feedDelSopCategory, setDelSopCategory] = useState();
  const [feedOrgCategory, setOrgCategory] = useState();
  const [feedOrgTask, setOrgTask] = useState();
  const [errorMessage, setErrorMessage1] = useState("");
  const [sopScore, setsopScore] = useState("");
  const [sopdata, updatesopdata] = useState({
    sopname: "",
  });
  const [listsop, setlistsop] = useState([]);

  const[globalSop, setGlobalSop] = useState([{"Customer Support" : [
      {
          process_catagory : "Query received", 
          process_desc:"Upon receiving a customer query or ticket, ensure prompt acknowledgment, acknowledging receipt of the query or ticket within [specified timeframe, e.g., 1 hour]",
      Instructions: []},
      {
          process_catagory : "Investigation and Solution Provision", 
          process_desc:"Assign the ticket/query to the appropriate department or individual based on the nature of the inquiry. Communicate with the customer, updating them on the progress and proposed resolution steps",
      Instructions: []},
      {
          process_catagory : "Resolution and Validation", 
          process_desc:"Implement the solution and ensure its effectiveness in resolving the customer's query. Validate the solution with the customer through follow-up communication or confirmation.",
      Instructions: []},
      {
          process_catagory : "Closure and Documentation", 
          process_desc:"Once the issue is resolved and validated by the customer, close the ticket/query in the system. Document the resolution steps and any additional notes for future reference.",
      Instructions: []},
      {
          process_catagory : "Blocker Handling (If Applicable)", 
          process_desc:"Prioritize and escalate the issue to the relevant authority or higher support level. Communicate transparently with the customer about the delay or challenges faced and provide an estimated resolution time.",
      Instructions: []},
      {
          process_catagory : "Customer Satisfaction Check", 
          process_desc:"Conduct a post-resolution satisfaction check with the customer to ensure their needs were met and assess their overall satisfaction with the support received.",
      Instructions: []}
  ]},
  {"Staffing/HR companies" : [
    {
        process_catagory : "New lead", 
        process_desc:" This is a person who has expressed interest in your services, either by signing up for your newsletter, attending a webinar, or contacting you directly.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:" This is a lead who has been assessed and deemed to be a good fit for your services.",
    Instructions: []},
    {
        process_catagory : "Submitted to client", 
        process_desc:"This is a lead who has been submitted to a client for consideration.",
    Instructions: []},
    {
        process_catagory : "Interview scheduled", 
        process_desc:"This is a lead who has been scheduled to interview with a client.",
    Instructions: []},
    {
        process_catagory : "Offer made", 
        process_desc:"This is a lead who has been offered a job by a client.",
    Instructions: []},
    {
        process_catagory : "Offer accepted", 
        process_desc:"This is a lead who has accepted a job offer from a client.",
    Instructions: []},
    {
        process_catagory : "Placed", 
        process_desc:"This is a lead who has started their new job",
    Instructions: []}
]},
{"Insurance companies" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in your insurance products or services, either by filling out a form on your website, calling your office, or meeting with an agent.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your products or services.",
    Instructions: []},
    {
        process_catagory : "Quote provided", 
        process_desc:"This is a lead who has been provided with a quote for your products or services.",
    Instructions: []},
    {
        process_catagory : "Application submitted", 
        process_desc:"This is a lead who has submitted an application for your products or services.",
    Instructions: []},
    {
        process_catagory : "Policy issued", 
        process_desc:"This is a lead who has been issued an insurance policy.",
    Instructions: []},
    {
        process_catagory : "Active customer", 
        process_desc:"This is a customer who has an active insurance policy with you.",
    Instructions: []},

]},{"Loan companies" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in a loan from your company, either by filling out a form on your website, calling your office, or meeting with a loan officer.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for a loan from your company",
    Instructions: []},
    {
        process_catagory : "Application submitted", 
        process_desc:"This is a lead who has submitted an application for a loan from your company.",
    Instructions: []},
    {
        process_catagory : "Credit check", 
        process_desc:"This is a lead whose credit has been checked to determine their eligibility for a loan.",
    Instructions: []},

    {
        process_catagory : "Approval", 
        process_desc:"This is a lead who has been approved for a loan from your company.",
    Instructions: []},
    {
        process_catagory : "Funding", 
        process_desc:"This is a lead whose loan has been funded and they have received the money.",
    Instructions: []},

]},{"Startups" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in your product or service, either by signing up for your newsletter, attending a demo, or contacting you directly.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your product or service.",
    Instructions: []},
    {
        process_catagory : "Demo scheduled", 
        process_desc:"This is a lead who has been scheduled to demo your product or service.",
    Instructions: []},
    {
        process_catagory : "Demo completed", 
        process_desc:"This is a lead who has demoed your product or service.",
    Instructions: []},
    {
        process_catagory : "Trial started", 
        process_desc:"This is a lead who has started a trial of your product or service.",
    Instructions: []},
    {
        process_catagory : "Customer", 
        process_desc:"This is a lead who has converted into a paying customer.",
    Instructions: []},

]},{"E-commerce businesses" : [
    {
        process_catagory : "New visitor", 
        process_desc:"This is a person who has visited your website for the first time.",
    Instructions: []},
    {
        process_catagory : "Abandoned cart", 
        process_desc:"This is a visitor who has added items to their cart but left your website without completing their purchase.",
    Instructions: []},
    {
        process_catagory : "Returning visitor", 
        process_desc:"This is a visitor who has visited your website more than once.",
    Instructions: []},
    {
        process_catagory : "Customer", 
        process_desc:"This is a visitor who has made a purchase from your website.",
    Instructions: []},
    {
        process_catagory : "Loyal customer", 
        process_desc:"This is a customer who has made multiple purchases from your website or who has referred other customers to your website",
    Instructions: []},

]},{"SaaS businesses" : [
    {
        process_catagory : "Free trial user", 
        process_desc:"This is a person who has signed up for a free trial of your SaaS product.",
    Instructions: []},
    {
        process_catagory : "Paid user", 
        process_desc:"This is a user who has converted from a free trial user to a paid user.",
    Instructions: []},
    {
        process_catagory : "Churned user", 
        process_desc:"This is a user who has canceled their subscription to your SaaS product.",
    Instructions: []},

]},
{"Consulting businesses" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in your consulting services, either by signing up for your newsletter, attending a webinar, or contacting you directly.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your consulting services.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your consulting services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your consulting services.",
    Instructions: []},
    {
        process_catagory : "Project completed", 
        process_desc:"This is a client who has completed a project with you.",
    Instructions: []},

]},{"B2B businesses" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a business that has expressed interest in your products or services, either by filling out a form on your website, calling your office, or attending a trade show.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your products or services.",
    Instructions: []},
    {
        process_catagory : "Demo scheduled", 
        process_desc:"This is a lead that has been scheduled to demo your products or services.",
    Instructions: []},
    {
        process_catagory : "Demo completed", 
        process_desc:"This is a lead that has demoed your products or services.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your products or services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your products or services.",
    Instructions: []},
    {
        process_catagory : "Customer", 
        process_desc:"This is a business that is actively using your products or services.",
    Instructions: []},

]},{"Real estate agents" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in buying or selling a home, either by filling out a form on a real estate agent's website, calling their office, or attending an open house.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for the real estate agent's services.",
    Instructions: []},
    {
        process_catagory : "Showing scheduled", 
        process_desc:"This is a lead who has been scheduled to see a home with the real estate agent.",
    Instructions: []},
    {
        process_catagory : "Offer made", 
        process_desc:"This is a lead who has made an offer on a home.",
    Instructions: []},
    {
        process_catagory : "Offer accepted", 
        process_desc:"This is a lead whose offer on a home has been accepted.",
    Instructions: []},
    {
        process_catagory : "Closed sale", 
        process_desc:" This is a lead who has purchased a home with the real estate agent's assistance.",
    Instructions: []},

]},{"Gyms" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in joining a gym, either by filling out a form on a gym's website, calling their office, or visiting the gym in person.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for the gym's membership plans.",
    Instructions: []},
    {
        process_catagory : "Free trial scheduled", 
        process_desc:"This is a lead who has been scheduled for a free trial of the gym's facilities and services.",
    Instructions: []},
    {
        process_catagory : "Free trial completed", 
        process_desc:"This is a lead who has completed a free trial of the gym.",
    Instructions: []},
    {
        process_catagory : "Membership purchased", 
        process_desc:"This is a lead who has purchased a membership to the gym.",
    Instructions: []},
    {
        process_catagory : "Active member", 
        process_desc:"This is a member who is actively using the gym's facilities and services.",
    Instructions: []},

]},{"E-learning businesses" : [
    {
        process_catagory : "New visitor", 
        process_desc:"This is a person who has visited your website for the first time.",
    Instructions: []},
    {
        process_catagory : "Lead", 
        process_desc:"This is a visitor who has signed up for your email list or downloaded a freebie.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your paid course or membership.",
    Instructions: []},
    {
        process_catagory : "Customer", 
        process_desc:"This is a lead who has purchased your paid course or membership.",
    Instructions: []},
    {
        process_catagory : "Active student", 
        process_desc:"This is a customer who is actively engaged in your course or membership content.",
    Instructions: []},

]},{"Software companies" : [
    {
        process_catagory : "New visitor", 
        process_desc:"This is a person who has visited your website for the first time.",
    Instructions: []},
    {
        process_catagory : "Lead", 
        process_desc:"This is a visitor who has signed up for your email list or downloaded a free trial of your software.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your paid software subscription.",
    Instructions: []},
    {
        process_catagory : "Customer", 
        process_desc:"This is a lead who has purchased a paid subscription to your software.",
    Instructions: []},
    {
        process_catagory : "Active user", 
        process_desc:"This is a customer who is actively using your software.",
    Instructions: []},

]},{"Creative agencies" : [
    {
        process_catagory : "New lead", 
        process_desc:" This is a business that has expressed interest in your creative services, either by filling out a form on your website, calling your office, or attending a networking event.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your creative services.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your creative services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your creative services.",
    Instructions: []},
    {
        process_catagory : "Project completed", 
        process_desc:"This is a client who has completed a project with you.",
    Instructions: []},

]},{"Freelancers" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a potential client who has contacted you about your freelance services.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your freelance services.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your freelance services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your freelance services.",
    Instructions: []},
    {
        process_catagory : "Project completed", 
        process_desc:"This is a client who has completed a project with you.",
    Instructions: []},

]},{"Travel agencies" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in booking a trip, either by filling out a form on a travel agency's website, calling their office, or visiting the agency in person.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for the travel agency's services.",
    Instructions: []},
    {
        process_catagory : "Quote requested", 
        process_desc:" This is a lead who has requested a quote for a trip from the travel agency.",
    Instructions: []},
    {
        process_catagory : "Quote provided", 
        process_desc:"This is a lead who has been provided with a quote for a trip from the travel agency.",
    Instructions: []},
    {
        process_catagory : "Booking made", 
        process_desc:"This is a lead who has booked a trip with the travel agency.",
    Instructions: []},

]},{"Coaching businesses" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in your coaching services, either by signing up for your email list, downloading a freebie, or scheduling a consultation.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead who has been assessed and deemed to be a good fit for your coaching services.",
    Instructions: []},
    {
        process_catagory : "Sales call scheduled", 
        process_desc:"This is a lead that has been scheduled for a sales call with you.",
    Instructions: []},
    {
        process_catagory : "Sales call completed", 
        process_desc:"This is a lead that has completed a sales call with you.",
    Instructions: []},
    {
        process_catagory : "Package purchased", 
        process_desc:"This is a lead who has purchased a coaching package from you.",
    Instructions: []},

    {
        process_catagory : "Active client", 
        process_desc:"This is a client who is actively engaged in your coaching program.",
    Instructions: []},


]},
{"Consulting businesses (B2B)" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a business that has expressed interest in your consulting services, either by filling out a form on your website, calling your office, or attending a trade show.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your consulting services.",
    Instructions: []},
    {
        process_catagory : "Discovery call scheduled", 
        process_desc:"This is a lead that has been scheduled for a discovery call with you.",
    Instructions: []},
    {
        process_catagory : "Discovery call completed", 
        process_desc:"This is a lead that has completed a discovery call with you.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your consulting services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your consulting services.",
    Instructions: []},
    {
        process_catagory : "Client", 
        process_desc:"This is a business that is actively engaged in a consulting project with you.",
    Instructions: []},

]},{"E-learning businesses (B2B)" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a business that has expressed interest in your e-learning products or services, either by filling out a form on your website, calling your office, or attending a trade show.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your e-learning products or services.",
    Instructions: []},
    {
        process_catagory : "Demo scheduled", 
        process_desc:"This is a lead that has been scheduled to demo your e-learning products or services.",
    Instructions: []},
    {
        process_catagory : "Demo completed", 
        process_desc:"This is a lead that has demoed your e-learning products or services.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your e-learning products or services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your e-learning products or services.",
    Instructions: []},
    {
        process_catagory : "Customer", 
        process_desc:"This is a business that is actively using your e-learning products or services.",
    Instructions: []},

]},{"Web development businesses" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a business that has expressed interest in your web development services, either by filling out a form on your website, calling your office, or attending a networking event.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your web development services.",
    Instructions: []},
    {
        process_catagory : "Discovery call scheduled", 
        process_desc:"This is a lead that has been scheduled for a discovery call with you.",
    Instructions: []},
    {
        process_catagory : "Discovery call completed", 
        process_desc:"This is a lead that has completed a discovery call with you.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your web development services.",
    Instructions: []},
    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your web development services.",
    Instructions: []},
    {
        process_catagory : "Client", 
        process_desc:"This is a business that is actively working with you to develop their website.",
    Instructions: []},

]},{"Digital marketing businesses" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a business that has expressed interest in your digital marketing services, either by filling out a form on your website, calling your office, or attending a trade show.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your digital marketing services.",
    Instructions: []},
    {
        process_catagory : "Consultation scheduled", 
        process_desc:"This is a lead that has been scheduled for a consultation with you.",
    Instructions: []},
    {
        process_catagory : "Consultation completed", 
        process_desc:"This is a lead that has completed a consultation with you.",
    Instructions: []},
    {
        process_catagory : "Proposal sent", 
        process_desc:"This is a lead to whom you have sent a proposal for your digital marketing services.",
    Instructions: []},

    {
        process_catagory : "Contract signed", 
        process_desc:"This is a lead who has signed a contract for your digital marketing services.",
    Instructions: []},

    {
        process_catagory : "Client", 
        process_desc:"This is a business that is actively working with you to implement their digital marketing campaigns.",
    Instructions: []},



]},{"Law firms" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person or business that has expressed interest in your law firm's services, either by filling out a form on your website, calling your office, or attending a legal seminar.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your law firm's services.",
    Instructions: []},
    {
        process_catagory : "Consultation scheduled", 
        process_desc:"This is a lead that has been scheduled for a consultation with one of your attorneys.",
    Instructions: []},
    {
        process_catagory : "Consultation completed", 
        process_desc:"This is a lead that has completed a consultation with one of your attorneys.",
    Instructions: []},
    {
        process_catagory : "Retainer signed", 
        process_desc:"This is a lead who has signed a retainer agreement with your law firm.",
    Instructions: []},
    {
        process_catagory : "Client", 
        process_desc:"This is a person or business that is actively working with your law firm on a legal matter.",
    Instructions: []},

]},
{"Financial services firms" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person or business that has expressed interest in your financial services firm's services, either by filling out a form on your website, calling your office, or attending a financial planning seminar.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your financial services firm's services.",
    Instructions: []},
    {
        process_catagory : "Consultation scheduled", 
        process_desc:"This is a lead that has been scheduled for a consultation with one of your financial advisors.",
    Instructions: []},
    {
        process_catagory : "Consultation completed", 
        process_desc:"This is a lead that has completed a consultation with one of your financial advisors.",
    Instructions: []},
    {
        process_catagory : "Account opened", 
        process_desc:"This is a lead who has opened an account with your financial services firm.",
    Instructions: []},
    {
        process_catagory : "Client", 
        process_desc:"This is a person or business that is actively working with your financial services firm to manage their finances.",
    Instructions: []},

]},{"Health and wellness businesses" : [
    {
        process_catagory : "New lead", 
        process_desc:"This is a person who has expressed interest in your health and wellness business, either by filling out a form on your website, calling your office, or attending a health seminar.",
    Instructions: []},
    {
        process_catagory : "Qualified lead", 
        process_desc:"This is a lead that has been assessed and deemed to be a good fit for your health and wellness business.",
    Instructions: []},
    {
        process_catagory : "Consultation scheduled", 
        process_desc:"This is a lead that has been scheduled for a consultation with one of your health professionals.",
    Instructions: []},
    {
        process_catagory : "Consultation completed", 
        process_desc:"This is a lead that has completed a consultation with one of your health professionals.",
    Instructions: []},
    {
        process_catagory : "Treatment plan started", 
        process_desc:"This is a lead who has started a treatment plan with your health and wellness business.",
    Instructions: []},
    {
        process_catagory : "Active client", 
        process_desc:"This is a client who is currently working with your health and wellness business to achieve their health and wellness goals.",
    Instructions: []},

]}]);
   

const [slectedglobalsop, setslectedglobalsop] = useState({
 added : '',
 overwrite : ''
});


function updataslectedglobalsop(e) {

  setslectedglobalsop({ ...slectedglobalsop, [e.target.name]: e.target.value });
}

  const [graphdata, setgraphdata] = useState(
    {
      "nodes": [
        {
          "name": "Visit"
        },
        {
          "name": "Direct-Favourite"
        },
        {
          "name": "Page-Click"
        },
        {
          "name": "Detail-Favourite"
        },
        {
          "name": "Lost"
        }
      ],
      "links": [
        {
          "source": 0,
          "target": 1,
          "value": 3728.3
        },
        {
          "source": 0,
          "target": 2,
          "value": 354170
        },
        {
          "source": 2,
          "target": 3,
          "value": 62429
        },
        {
          "source": 2,
          "target": 4,
          "value": 291741
        }
      ]
    }
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredItems = globalSop.filter((item) => {
    const title = Object.keys(item)[0];
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    const lowercaseTitle = title.toLowerCase();
    return lowercaseTitle.includes(lowercaseSearchQuery);
  });

  const [currentGlobalSop, setCurrentGlobalSop] = useState([]);
  const [currentGlobalTitle, setCurrentGlobalTitle] = useState('');

  function checkadmin(){


    if(admin != 'true'){
  
      window.location.replace("https://dashboard.salessunday.com/");
  
    }
  
  
  }

  const [Instructionblock, setInstructionblock] = useState({
    blockid:"", blocktype:"", blockname:"" 
  });

  function getcurrentglobalsop(title){

    let temp = globalSop.find((item) => item[title]);

    setCurrentGlobalSop(temp[title]);
    setCurrentGlobalTitle(title);

    slectedglobalsop.added = title;




  }

  function addInstructionid(index){

    console.log(index);

    Instructionblock.blockid = index;

  }

  function updataInstructionblock(e) {

    setInstructionblock({ ...Instructionblock, [e.target.name]: e.target.value });
  }

  function updataBlockcat(e) {
    setblock({ ...block, [e.target.name]: e.target.value });
  }
  
  function clearBlock(){
    setblock({ process_catagory: "", process_desc: "",Instructions:[] });
  }

  function changesopdata(e) {
    e.preventDefault();
    updatesopdata({ ...sopdata, [e.target.name]: e.target.value });
  }

  

  function openview(item, item1){

    setlivemodel(item1);

    if(item1 == 'flow'){

      getSopProcess(item);
      setlivesop(item);
    
    }else if(item1 == 'graph'){

      setlivesop(item);
      getFlow(item);
      

    }

    
  }

//////////////////////////////////Api calls///////////////////////////////////////////////////////
function submitsop(e) {
  e.preventDefault();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Auth}`,
  };
  axios
    .post("/Add_sop_name", {"SopName": [
      sopdata.sopname
    ]}, { headers })
    .then((data) => {
      if (data.status == "200") {

        updatesopdata({
          sopname: "",
        });
        getSops();
        dispatch(getProgressBarStatus({ access_token: Auth }));

      }
      
    })
    .catch((err) => console.log(err));
}

function saveSopProcess(item1, item2,item3) {

  
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Auth}`,
  };

  if(item3 == 'add'){
    sopdata.sopname = item1;
    axios
    .post("/Add_sop_name", {"SopName": [
      sopdata.sopname
    ]}, { headers })
    .then((data) => {
      if (data.status == "200") {

        updatesopdata({
          sopname: "",
        });
        getSops();
        dispatch(getProgressBarStatus({ access_token: Auth }));

      }
      
    })
    .catch((err) => console.log(err));

  }
  
  axios
    .post("/SetUp_the_sop_process", {
      "SopName": item1,
      "process": item2
    }
    , { headers })
    .then((data) => {
      if (data.status == "200") {

        tostMsg('Changes saved!');

      }
      
    })
    .catch((err) => console.log(err));
}

function getSops() {
  const headers = {
    "Content-Type": "text/plain",
    Authorization: `Bearer ${Auth}`,
  };
  axios
    .get("/get_sop_name", {
      headers,
    })
    .then((data) => {
      if (data.status == "200") {
        setSopCategory(data.data);
      }
      setErrorMessage1(data.data.message);
    })
    .catch((err) => console.log(err));
}

function getdelSops() {
  const headers = {
    "Content-Type": "text/plain",
    Authorization: `Bearer ${Auth}`,
  };
  axios
    .get("/get_sop_name", {
      headers,
    })
    .then((data) => {
      if (data.status == "200") {
        setDelSopCategory(data.data);
      }
      setErrorMessage1(data.data.message);
    })
    .catch((err) => console.log(err));
}
  function getScript() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_all_script_types?orgID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgCategory(data.data);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getTask() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/GetTaskHeadings?organisationID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgTask(data.data);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getFlow(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_sop_reports?SopName=".concat(item), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          setgraphdata(data.data);

      
          

        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }
 
  function getSopProcess(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_sop_process?SopName=".concat(item), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setlistsop(data.data);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function delSop(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .put("/Delete_Operations",{
        SopName: item,
        delete: true
      } ,  {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          getSops();
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  const appendInstruction = () => {
  
    listsop[Instructionblock.blockid].Instructions = [...listsop[Instructionblock.blockid].Instructions, Instructionblock];

    let temp = listsop;

    setlistsop(temp);

    setInstructionblock({
      blockid:Instructionblock.blockid, blocktype:"", blockname:"" 
    });


  };


  function pushblock(index){
    // setlistsop(listsop => [...listsop,block] );


    const updatedListSop = [...listsop]; // Creating a copy of the original array

  // Inserting 'block' at the specified 'index' within the array
  updatedListSop.splice(index+1, 0, block);

  // Updating the state or variable with the modified array
  setlistsop(updatedListSop);

  const button = document.getElementById(index);
  button.click();

    clearBlock();

  }

  function removeblock(item){

    listsop.splice(item, 1);
    var newlist = listsop;

    setlistsop(newlist);
    clearBlock();
    
  }

  function soppercentage(item){



    if(item.links == null || item.links ==''){

      return 0;

    }else{

      return ((item.links[item.links.length-1]["value"] / item.links[0]["value"]) * 100).toFixed(1);

    }


   

    

  }

  

  useEffect(() => {
    checkadmin();
    getSops();
    getScript();
    getTask();
  }, [1]);

  return (
    <>
     <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-3 border-end">
        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                  
                  <button
                    type="button"
                    className={"btn btn-dark bg-gradient btn-sm" }
                    id="popsript"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseScript"
                    aria-expanded="false"
                    aria-controls="flush-collapseScript"
                  >
                    {" "}
                    <i className="bi bi-plus-circle-fill"></i> Add process
                  </button>
                  <button className="btn btn-light border-dark btn-sm" type="button" data-bs-toggle="modal" id="aisop" 
    data-bs-target="#sopModal"><i className="bi bi-building"></i> Pick a process</button>
    <Link
                            to="/learnform"
                          ><button className="btn btn-danger text-white btn-sm"><i className="bi bi-robot"></i>  Generate</button></Link>
                </div>
                <br />
        <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item bg-transparent">
                    <div
                      id="flush-collapseScript"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                    
                        <h5>What processes do you have?
                        &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Add your SOPs (set of tasks and calls in the order of customer journey). Eg: Onboarding, Service Name, etc.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                        </h5>
                        <div className="input-group input-group-sm mb-3">
        <input type="text" name='sopname' onChange={changesopdata}
                    value={sopdata.sopname} className="form-control  border-dark" placeholder="sop name" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button onClick={submitsop} className="btn bg-dark text-white" type="button" id="button-addon2">Submit</button>
      </div>
                        
            <br/>         
                    </div>
                  </div>
                  <div></div>
                </div>
        <mark>
        <i className="bi bi-bezier"></i> All process(SOPs) 
                    categories <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('All your sop(standard operating procedure) will be displayed here. eg: customer onbording, KYC, application verification, etc')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                  <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "800px" }}
                >
                  <ol className="list-group">
                      {feedSopCategory &&
                        feedSopCategory?.map((item) => (

                          livesop == item?<li  className="list-group-item d-flex justify-content-between align-items-start text-dark bg-info border border-dark">
                          <div className="ms-2 me-auto">
                            {item}
                          </div>

                          <i
                            title="View"
                            className="bi bi-eye-fill"
                            onClick={()=>openview(item, 'flow')}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          ></i>

                          <i title="Report" 
                          className="bi bi-clipboard2-data-fill"
                          onClick={()=>openview(item, 'graph')}
                          style={{ marginLeft: "8px", cursor: "pointer" }}  />
                          
                          
                          <i
                            title="Delete"
                            className="bi bi-trash3-fill"
                            onClick={()=>delSop(item)}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            
                          ></i>
                        </li>:<li  className="list-group-item d-flex justify-content-between align-items-start text-dark bg-light border border-dark">
                        <div className="ms-2 me-auto">
                          {item}
                        </div>


                        <i
                          title="View"
                          className="bi bi-eye-fill"
                          onClick={()=>openview(item, 'flow')}
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                        ></i>

                        <i title="Report" 
                          className="bi bi-clipboard2-data-fill"
                          onClick={()=>openview(item, 'graph')}
                          style={{ marginLeft: "8px", cursor: "pointer" }}  />

                        
                        <i
                          title="Delete"
                          className="bi bi-trash3-fill"
                          onClick={()=>delSop(item)}
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                          
                        ></i>
                      </li>

                          ))}

                          {feedSopCategory ==''?<div align='center'><br/><br/><br/><br/><br/><br/><img
          src={emptylist}  height={150}
        /><p><br/>SOPs not available</p></div> :null}
      
                  
                  </ol>
                </div>
        </div>
        <div className="col-12 col-sm-9" align='center'>
   
      
        {
          livemodel == 'flow'?
          (<div>
        
        
        <br/>
        <div className="row  border-top">
          <div className="col-6 border-end"><br/>
          <div className="row">
          <div className="col-8" align='left'><h5>
Design your operating stages <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Arrange all your calls and tasks on the bases of their order with their status. To calculate conversion rate drop rate.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
</h5></div>
          <div className="col-4">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button className="btn btn-light border-dark me-md-1 btn-sm" type="button" data-bs-toggle="modal"
    data-bs-target="#sopModal"><i className="bi bi-robot"></i></button>
         

      <button onClick={()=>saveSopProcess(livesop,listsop)} className="btn btn-dark btn-sm" type="button"><i className="bi bi-cloud-arrow-up"></i> Save</button>
    </div>
          </div>
        </div>
        <br/><br/>
        {
          listsop ==''?
          <div><img
          src={treepic}  height={200}
        /><br/>
        <br/>
       
        <div align='center' className="addcard"> <button type="button" class="btn btn-dark btn-sm" data-bs-toggle="collapse" data-bs-target={'#'+'collapse'+0} onClick={clearBlock} aria-expanded="false" aria-controls="collapseExample"><i className="bi bi-plus-circle-fill"></i> Add </button></div>
                       <br/> <>
                       <div className="col-12 col-sm-5">        
      <div className="collapse" id={'collapse'+0} align='left'>
        <div className="card card-body border border-dark shadow-lg">
        
        <div className="row">
          <div className="col-10"><div className="mb-2">
      <label htmlFor="exampleFormControlInput1" className="form-label">Set Stage:</label>
    <input type="text" name='process_catagory' onChange={updataBlockcat}
                    value={block.process_catagory} className="form-control form-control-sm border-dark bg-light" placeholder="Stage Name" aria-label="Process catagory name" aria-describedby="button-addon2" />
    </div></div>
          <div className="col-2"><div align ='right'><i className="bi bi-x-circle-fill" id={0} onClick={clearBlock} data-bs-toggle="collapse" data-bs-target={'#'+'collapse'+0}></i></div></div>
        </div>

  
    <div className="mb-2">
  <div data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
  <i className="bi bi-caret-down-fill"></i> Description (optional)
  </div>

<div className="collapse" id="collapseExample1">
  
  
    <textarea
    className="form-control form-control-sm border-dark bg-light"
    name='process_desc'
    value={block.process_desc}
    onChange={updataBlockcat}
    id="exampleFormControlTextarea1"
    rows={3}
    defaultValue={""}
  />
  </div>
</div>


    {
     block.process_catagory != ""?
     <><div class="d-grid gap-2 d-md-flex justify-content-md-start">
    <button type="button" onClick={()=>pushblock(0)} className="btn btn-dark text-ligh btn-sm"><i className="bi bi-arrow-right"></i> Submit</button>
    </div></>:null 
    }
    
    
    
        </div>
        <div align='center'><i className="bi bi-arrow-down"></i></div>

      </div>
      </div>
      
      </>
          
          </div>:null



        }
      <div className="col-12 col-sm-10">
        <br/>
      <ol className="list-group">
      {listsop &&
                      listsop?.map((item, index) => (<>
     <div className="card SOPtiledesign border-dark shadow">
    
  <div class="card-body " align='left'>
  <div className="row mb-1"><div className="col-10">
    <span className="badge rounded-pill bg-dark text-light">{index+1}</span> <b>{item.process_catagory}</b> 
      </div><div className="col-2" align='right'><i
                            title="Delete"
                            onClick={()=>removeblock(index)}
                            className="bi bi-trash3-fill"
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            
                          ></i></div></div>
                          
    
    {item.process_desc==null || item.process_desc==''?<></>:<div className="text-dark mb-1">{item.process_desc} <br/></div>}     

    
  <div className="text-secondary mb-1">Instructions <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("Add calls and tasks that are part of this stage, For example if it's a check KYC stage and you are suppose to make a call, add a call category if the category is not available click on design calls/tasks button on the right top")}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
  : </div> 
  {item.Instructions?.map((item,index)=>(<>
   {item.blocktype=='call'? <span className="badge bg-primary border border-dark"><i className="bi bi-telephone-fill"></i> {item.blockname}
   &nbsp;</span>:item.blocktype=='task'? <span className="badge bg-dark border border-dark"><i className="bi bi-bicycle"></i> {item.blockname}
   &nbsp;</span>:null} </>))}
  
  
  
  <a
  className="text-dark"
  onClick={()=>addInstructionid(index)}
  data-bs-toggle="collapse"
  href={'#multiCollapseExample1'+index}
  role="button"
  aria-expanded="false"
  aria-controls={'#multiCollapseExample1'+index}
>
<i className="bi bi-plus-square-fill"></i>
</a>

<div class="row"><div className="col">
  <div className="collapse multi-collapse" id={'multiCollapseExample1'+index}>
    <div className="card card-body bg-transparent  border-0">
    
    <div className="row"><div className="col-6">
      <div className="">
      <label htmlFor="exampleFormControlInput1" className="form-label">Select category:</label>
      <select className="form-select form-select-sm border border-dark" aria-label="Default select example" name='blocktype' value={Instructionblock.blocktype}
                  onChange={updataInstructionblock}>
              <option>Select category</option>
              <option value='call'>Call</option>     
              <option value='task'>Task</option>     
    </select>
    </div>
        </div>
      <div className="col-6">
      <div className="mb-3">
      <label htmlFor="exampleFormControlInput1" className="form-label">{Instructionblock.blocktype == "call"?<>Call category:</>
:Instructionblock.blocktype == "task"?<>Task category:</>:null}</label>
      
        {

          Instructionblock.blocktype == "call"?<><select className="form-select form-select-sm border border-dark" aria-label="Default select example" name='blockname' value={Instructionblock.blockname}
          onChange={updataInstructionblock}><option >Select call category</option>{feedOrgCategory &&
            feedOrgCategory?.map((item) => (
              <option value={item.script_type_name}>{item.script_type_name}</option>
              ))}    
              </select></>:Instructionblock.blocktype == "task"?<><select className="form-select form-select-sm border border-dark" aria-label="Default select example" name='blockname' value={Instructionblock.blockname}
              onChange={updataInstructionblock}><option >Select task category</option>{feedOrgTask &&
                
                feedOrgTask?.map((item) => (
                  <option value={item.heading}>{item.heading}</option>
                  ))}</select></>:null

          
        }
        
        
      
     

    </div>
        </div></div>

        {
          Instructionblock.blockname == "" || Instructionblock.blocktype == ""?<>
          </>:<button type="button" onClick={appendInstruction} className="btn btn-dark btn-sm">Add Instruction</button>
        }

{/* <button
    type="button"
    className="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
  >
    Launch demo modal
  </button> */}

  <div
    className="modal fade"
    id="exampleModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Modal title
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">...</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" className="btn btn-primary">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
        
      

    </div>
  </div>
</div>
</div>


 
  </div>
</div>
                        <div align='center' className="addcard"><i className="bi bi-plus-circle-fill" data-bs-toggle="collapse" data-bs-target={'#'+'collapse'+index} onClick={clearBlock} aria-expanded="false" aria-controls="collapseExample"></i><br/><i className="bi bi-arrow-down"></i></div>
                        <>
                        
      <div className="collapse" id={'collapse'+index} align='left'>
        <div className="card card-body border border-dark shadow-lg">
        
        <div className="row">
          <div className="col-10"><div className="mb-2">
      <label htmlFor="exampleFormControlInput1" className="form-label">Set Stage:</label>
    <input type="text" name='process_catagory' onChange={updataBlockcat}
                    value={block.process_catagory} className="form-control form-control-sm border-dark bg-light" placeholder="Stage Name" aria-label="Process catagory name" aria-describedby="button-addon2" />
    </div></div>
          <div className="col-2"><div align ='right'><i className="bi bi-x-circle-fill" id={index} onClick={clearBlock} data-bs-toggle="collapse" data-bs-target={'#'+'collapse'+index}></i></div></div>
        </div>

  
    <div className="mb-2">
  <div data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
  <i className="bi bi-caret-down-fill"></i> Description (optional)
  </div>

<div className="collapse" id="collapseExample1">
  
  
    <textarea
    className="form-control form-control-sm border-dark bg-light"
    name='process_desc'
    value={block.process_desc}
    onChange={updataBlockcat}
    id="exampleFormControlTextarea1"
    rows={3}
    defaultValue={""}
  />
  </div>
</div>


    {
     block.process_catagory != ""?
     <><div class="d-grid gap-2 d-md-flex justify-content-md-start">
    <button type="button" onClick={()=>pushblock(index)} className="btn btn-dark text-ligh btn-sm"><i className="bi bi-arrow-right"></i> Submit</button>
    </div></>:null 
    }
    
    
    
        </div>
        <div align='center'><i className="bi bi-arrow-down"></i></div>

      </div>
      
      </></>
                        ))}
                        </ol>
                       
      <br/>
      </div></div>
          <div className="col-6">
            <br/>
          <h5>
Lead management setup preview <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('When you add lead in this process/SOP it will be displayed here in the following manner.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
</h5>
            <br/>
          <Soppreview sop={listsop}/>

          </div>
        </div>
        
        
      
      </div>)
      :livemodel == 'graph'?<>
      <br/><br/><br/>
      <ResponsiveContainer width={900} height={400}>
      <Sankey
  width={960}
  height={800}
  data={graphdata}
  node={<DemoSankeyNode />}
  nodePadding={50}
  margin={{

    top: 100,
    bottom: 100,
  }}
  link={{ stroke: '#77c878' }}
>
  <Tooltip />
</Sankey>
      </ResponsiveContainer>
      
      </>:  

<div align='center'>
            <br/><br/>
            <br/><br/>

<img
            src={nature}  height={350}
          />
          <p><br/>SOP not selected</p>

  </div>


        }
        </div>
        
      </div>
      
     </div>
     

     <div id="bottomAI">
     <a
                                  href={
                                    "https://web.whatsapp.com/send/?text=I need help to design my sales and operations process&type=phone_number&app_absent=0&phone=%2B918554988039"
                                  }
                                  target="whatsapp"
                                >
              <button
                type="button"
                
                className="btn btn-dark rounded-circle"
              >
                <h4>
                <i className="bi bi-headset"></i>
                </h4>
              </button>
              </a>
            </div>
            <>

{/* Modal */}
<div
  className="modal fade"
  id="sopModal"
  tabIndex={-1}
  aria-labelledby="sopModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content">
      <div className="modal-header border-0">
        <h5 className="modal-title" id="sopModalLabel">
        <i className="bi bi-bezier"></i>  Processes(SOPs) used by leading companies <i className="bi bi-building"></i>
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
<div className="row">

               
<div className="col-3" align='left'>
<label>
      <i className="bi bi-search"></i> Search 
                </label>
      <div className="input-group">
<input
  type="text"
  className="form-control border border-dark inside2"
  placeholder="Search your company type"
  aria-label="Recipient's username" 
  aria-describedby="button-addon2"
  value={searchQuery}
 onChange={handleSearchInput}
/>

</div>
<br/>
<div
                className="overflow-auto p-3 border-end border-dark"
                style={{ maxWidth: "1000px", maxHeight: "700px" }}
              >
                <ul className="list-group" >
               
               {filteredItems?.map((item, index) => {
     const title = Object.keys(item)[0]; // Get the title from the object key
     return (
     <li  className="list-group-item d-flex justify-content-between align-items-start text-white bg-dark border border-white" key={index}>
                       <div className="ms-2 me-auto">
                         {title}
                       </div>

                       <i
                         title="View"
                         className="bi bi-eye-fill"
                         onClick={()=>getcurrentglobalsop(title)}
                         style={{ marginLeft: "8px", cursor: "pointer" }}
                       ></i>

               
                     </li>
   
     
     );
   })}
     </ul>
                </div>
                

</div>
<div className="col-9">

  
 


<div className="row border-top">

  <div className="col-6 border-end">
  <br/>
    <div className="row">
      
    <div> {
    currentGlobalSop!=''?<>
  <div className="row">
  <div className="col-6"><h4 align='center'>{currentGlobalTitle}</h4></div>
  <div className="col-6"><div className="d-grid gap-2 d-md-flex justify-content-md-end">
    
    <div className="dropdown">
<button
  className="btn btn-dark border border-dark btn-sm"
  type="button"
  id="dropdownMenu2"
  data-bs-toggle="collapse"
  data-bs-target="#collapseimport"
  aria-expanded="false"
  aria-controls="collapseimport"
>
  <i className="bi bi-cloud-download"></i> Import 
</button>
</div>

  </div></div>
  </div></>
    :null
  }
  <>
<br/>
<div align='right'>
<div className="collapse col-9" align='left' id="collapseimport">


  <div className="card">
  <div className="card-header">Add <b>{currentGlobalTitle}</b> process to your sops:</div>
  <div className="card-body" align='left'>


   <p>

   <i className="bi bi-dot"></i>  Overwrite to excisting sop: 

<div className="input-group input-group-sm mb-3">
<select className="form-select border-secondary bg-light" id="inputGroupSelect02"
name="overwrite"
value={slectedglobalsop.overwrite}
onChange={updataslectedglobalsop}>
<option selected="">Choose...</option>
{feedSopCategory &&
                 feedSopCategory?.map((item) => (
                   <><option value={item}>{item}</option></>
                 ))}
</select>

<button
className="btn btn-dark"
type="button"
onClick={()=>saveSopProcess(slectedglobalsop.overwrite,currentGlobalSop )}
id="button-addon2"
>
Submit
</button>
</div>


   <div align='center'>-or-</div> 

   <i className="bi bi-dot"></i> Create a new sop & import:

   <div className="input-group input-group-sm mb-3">
<input
  type="text"
  className="form-control border-secondary bg-light"
  placeholder="Sop Name"
  value={currentGlobalTitle + '-sop'}
  aria-label="Recipient's username"
  aria-describedby="button-addon2"
  name="added"
  onChange={updataslectedglobalsop}
/>
<button
  className="btn btn-dark"
  type="button"
  id="button-addon2"
  onClick={()=>saveSopProcess(slectedglobalsop.added,currentGlobalSop,'add' )}
>
  Submit
</button>
</div>

</p>  
</div>

  </div>
  <br/>
</div>
</div>

</>

  </div>
  <div align='center'>
    <div className="col-8" align='left'>
    {
      currentGlobalSop==''?
      <><br/><br/><br/><img
      src={globesop}  height={400}
    /></>
      :<>
      <ol className="list-group">
    {currentGlobalSop &&
                          currentGlobalSop?.map((item, index) => (
                            <> <div className="card SOPtiledesign border-dark shadow"><div class="card-body" align='left'>
                                <div className="row mb-1"><div className="col-12">
      <span className="badge rounded-pill bg-dark text-light">{index+1}</span> <b>{item.process_catagory}</b> 
        </div>
        <p>{item.process_desc}</p></div>
                              </div></div><div align='center'><i className="bi bi-arrow-down"></i></div></>
                            ))}
                            </ol>
      </>
    }
    </div>
    </div>
    
    </div>
  
    
     
                        </div>
                        <div className="col-6">
            <br/>
          <h5>
Lead management setup preview <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('When you add lead in this process/SOP it will be displayed here in the following manner.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
</h5>
            <br/>
          <Soppreview sop={currentGlobalSop}/>

          </div>           
                        
  </div>
  
 
</div>
</div>

      </div>
      
    </div>
  </div>
</div>
</>

     <ToastContainer />

     <Joyride continuous scrollToFirstStep showProgress showSkipButton 
     steps={steps} 
    //  styles={{
    //         options: {
    //           arrowColor: '#e3ffeb',
    //           backgroundColor: '#e3ffeb',
    //           overlayColor: 'rgba(79, 26, 0, 0.4)',
    //           primaryColor: '#000',
    //           textColor: '#004a14',
    //           width: 900,
    //           zIndex: 1000,
    //         }
    //       }} 
          />
    </>
  );
}

export default Sop;
