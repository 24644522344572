import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import relaxvideo from "../media/relax.mp4";
import { Link } from "react-router-dom";
import emptytarget from "../media/Team goals-pana.svg";

const user_name = localStorage.getItem("user_name");
const Auth = localStorage.getItem("Auth");

function TargetComp() {
 
    const [totalAssigned , setTotalAssigned ] = useState('0');
    const [totalCompleted , setTotalCompleted ] = useState('0');

    function addCommasToInteger(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    function get_target(){

    


        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June', 'July',
          'August', 'September', 'October', 'November', 'December'
        ];
        const monthName = monthNames[currentMonth];
    
    
        const currentYear = currentDate.getFullYear();
    
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth}`,
        };
        axios
          .get(
            `/get_target?month=${monthName}&year=${currentYear}`,
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              
    
              let totalAssigned = 0;
              let totalCompleted = 0;
     
    
              let target = data.data[0]["target"] 
    
              console.log(target,'target')
               
              // Loop through each object in the array
            
            target.forEach(item => {
              if (item.assigned) {
                  totalAssigned += parseInt(item.assigned);
              }
              if (item.completed) {
                  totalCompleted += parseInt(item.completed);
              }
          });
    
    
    setTotalAssigned(totalAssigned);
    setTotalCompleted(totalCompleted);
    
            }
           
          })
          .catch((err) => {
            console.log(err);
           
          });
    
      }  

      useEffect(() => {
        get_target();
    }, [1]);

  return (
    <>
      
      

      {totalAssigned != ""?<><div className="row" align='center'>
    <div className="col-12">
    
    <h4 class="text-danger" >
      <b>
    <i className="bi bi-award-fill"></i> {addCommasToInteger(totalCompleted)} 
  <span class="text-secondary"> / {addCommasToInteger(totalAssigned)}</span>
  </b>
</h4>
<p className="text-dark"> Completed vs Assigned target</p>

    </div>
    </div>
    <div className="col-12">
    <div className="progress">
  <div
    className="progress-bar"
    role="progressbar"
    style={{ width: `${(totalCompleted / totalAssigned) * 100}%` }}
    aria-valuenow={(totalCompleted / totalAssigned) * 100}
    aria-valuemin={0}
    aria-valuemax={100}
  >
    {Math.round((totalCompleted / totalAssigned) * 100)}%
  </div>
</div>
<br/>


    </div></>
            :<div align='center'>
            <img src={emptytarget} width="30%" /><p className="text-light">Please set this month's targets</p>
            <Link
                   to="/report"
                 ><button type="button" className="btn btn-sm btn-primary"> <i className="bi bi-bullseye"></i> Set targets</button></Link></div>}
      
    </>
  );
}

export default TargetComp;
