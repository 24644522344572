import React, { Component, useEffect, useState, useRef } from "react";
import { tocallvalue, progressElement, alterElement, alterCallBack, changeElement, changeFace, generateavatar , alterPercent, alterProgress} from '../helper';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getTasks,
  getScriptAll,
  getUserData,
  getAdminCard,
  getUserCard,
  getToCallAdmin,
  getToCallUser,
  getContactNumber,
  updateTasks,
  deleteContactToCall,
  addNewContact,
  generateNewFile,
  uploadConatctFile,
  addNewTask,
  todayCallAdmin,
  adminCallDues,
  getAllTaskAdmin,
  todayCallAdminsSriptCategory,
  getAllContact,
  getfollowContact,
  uploadCallRecoding,
  getAllTaskAdminFilter,
  pendingTodaysAdmintask,
  adminTaskDues,
  deteleCallRecord,
  getSourceListData,
} from "../redux/slices/dataSlice";

const org = localStorage.getItem("org");
const Auth = localStorage.getItem("Auth");

function Sidenav(arg1, arg2) {

  
    const [trackId, setTrackId] = useState();
    const [History, setHistory] = useState();
    const [RecLink, setRecLink] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    

    const dispatch = useDispatch();
    const audioRef = useRef();
    let audioformdata = new FormData();

    function openNav(item, itemId) {
      
      setHistory();
  
      searchContact(item);
      setTrackId(itemId);
  
      document.getElementById("mySidenav").style.width = "500px";
  
      document.getElementById("trackbtn").click();
  
  
    }
    
    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }

      function rec() {
        document.getElementById("rec").style.display = "block";
      }
      function rec_back() {
        document.getElementById("rec").style.display = "none";
      }

      const updateSong = (source) => {
        setRecLink(source);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.load();
          audioRef.current.play();
        }
      };


      function changeRec(item) {
        if (item.call_recoding == true) {
          return (
            <>
            <button
              onClick={() => getRec(item)}
              className="border-0 bg-transparent"
            >
              <i className="bi bi-play-circle-fill"></i>
            </button>
              <button
              // onClick={() => getCallAi(item.id)}
              className="border-0 bg-transparent"
            >
             <i className="bi bi-robot"></i>
            </button></>
          );
        } else if (item.call_recoding == false) {
          return (
            <button
              className="border-0 bg-transparent"
              data-bs-toggle="modal"
              data-bs-target="#audioModal"
              onClick={()=>setAudioId(item.id)}
            >
              <i className="bi bi-cloud-arrow-up"></i>
            </button>
          );
        } else {
          return (
            <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#audioModal"
            onClick={()=>setAudioId(item.id)}
          >
            <i className="bi bi-cloud-arrow-up"></i>
          </button>
          );
        }
      }

  
  
      function setAudioId(item){
  
       audioformdata.append("Recordid", item);
        
  
      }


   /////////////////////////Api Calls///////////////////////////////////////////

   function searchContact(item) {
    let phoneno = item;
    
    dispatch(
      getContactNumber({
        Auth,
        org,
        phoneNo: phoneno,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setHistory(res.payload.data.call_history);
      }
    });
  }

  function getRec(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/CallRecoding?".concat(
           "docucmet_id=",
          item.id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateSong(data.data);
          rec();
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
  

    searchContact('8149920883');

  }, [1]);


  return (
    <>  
    <><i
                                onClick={() =>
                                  openNav(arg1, arg2)
                                }
                                className="bi bi-geo-fill"
                              /></>
    <div className="card rec-popup p-2  border-0" id="rec">
    <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
    X
<span className="visually-hidden"></span>
</span>
      <audio controls ref={audioRef}>
        <source src={RecLink} />
        Your browser does not support the audio element.
      </audio>
    </div>

<div id="mySidenav" className="sidenav">
      <a
        href="javascript:void(0)"
        className="closebtn"
        onClick={closeNav}
      >
        ×
      </a>
      <div className="timeline">
        <div className="title">
          <h2 className="text-white">Prospect Journey</h2>
          <p className="text-white">(New to Old)</p>
          <a href={"#" + trackId}>
            <button
              type="button"
              className="btn btn-danger"
              id="trackbtn"
            >
              <i className="bi bi-geo-fill" />
            </button>
          </a>
        </div>

        <div className="events">
          {History &&
            History?.map((item) =>
              item.script_category != null ? (
                <div className="event" id={item.id} key={item.id}>
                  <div
                    className={trackId != item.id ? "knob" : "knob1"}
                  />

                  <div className="card bg-light">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <img
                            className="circle-pop2"
                            src={generateavatar(
                              item.added_by_username,
                              "White",
                              "#004aad"
                            )}
                            width="35"
                            height="35"
                          ></img>
                        </div>
                        <div className="col-6">
                          <h5 align="right" className="card-title">
                            <b> {item.script_category} </b>
                          </h5>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-3">
                          {item.call_type == "Incoming" ? (
                            <span className="badge bg-primary">
                              <i className="bi bi-telephone-inbound-fill"></i>
                              {" "}Incoming
                            </span>
                          ) : (
                            <span className="badge bg-success">
                              <i className="bi bi-telephone-outbound-fill"></i>
                              {" "}Outgoing
                            </span>
                          )}
                        </div>
                        <div className="col-3">
                          
                          <span className="badge bg-light text-dark border-dark">
                            {changeFace(item.clint_mood)}
                            {" "}{item.clint_mood}
                          </span>
                        </div>
                        <div className="col-3">
                        {changeRec(item)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="card-text">
                            <b>{item.added_by_username}</b> called this
                            number the call brief was
                            <b>{" "}{item.call_brief}</b>. It went till
                            <b>{" "}{item.call_status}</b>.
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-" + item.id}
                            className="btn btn-transparent btn-sm"
                          >
                            Description
                            <i className="bi bi-caret-down-fill"></i>
                          </button>
                        </div>
                        <div className="col-6">
                          <p align="right" className="card-text">
                            <small className="text-muted">
                              {alterElement(item)}&nbsp;
                            </small>
                          </p>
                        </div>
                      </div>

                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item bg-transparent">
                          <div
                            id={"flush-" + item.id}
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body ">
                              {item.description}
                            </div>
                          </div>
                        </div>
                      </div>

                      {progressElement(item)}
                      <div className="row">
                        <br/>
                       
                        </div>
                    </div>
                  </div>
                </div>
               ) :item.call_type != null ?
               <div className="event" id={item.id}><div
               className={trackId != item.id ? "knob" : "knob1"}
             /> <div className="card bg-danger">
             <div className="card-body">
             <div className="row">
                        <div className="col-6">
                          <img
                            className="rounded-circle"
                            src={generateavatar(
                              '?',
                              "White",
                              "#000"
                            )}
                            width="35"
                            height="35"
                          ></img>
                        </div>
                        <div className="col-6">
                          <h5 align="right" className="card-title">
                            <b> {"Unkown Call"} </b>
                          </h5>
                        </div>
                      </div>
                      <br/>
                      <div className="row">
                        <div className="col-3">
                          {item.call_type == "Incoming" ? (
                            <span className="badge bg-primary text-dark">
                              <i className="bi bi-telephone-inbound-fill"></i>
                              {" "}Incoming
                            </span>
                          ) : (
                            <span className="badge bg-success">
                              <i className="bi bi-telephone-outbound-fill"></i>
                              {" "}Outgoing
                            </span>
                          )}
                        </div>
                        <div className="col-3">
                        {changeRec(item)}
                        </div>
                      </div>
             <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
             <div className="row"><div className="col-12">
                          <p align="right" className="card-text">
                            <small className="text-dark">
                              {alterElement(item)}&nbsp;
                            </small>
                          </p>
                        </div></div>
              </div></div></div>
               : (
                <div className="event" id={item.id}>
                  <div
                    className={trackId != item.id ? "knob" : "knob1"}
                  />

                  <div className="card bg-info bg-gradient">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <img
                            className="rounded-circle"
                            src={generateavatar(
                              item.added_by_username,
                              "White",
                              "#004aad"
                            )}
                            width="35"
                            height="35"
                          ></img>
                        </div>
                        <div className="col-6">
                          <h5 align="right" className="card-title">
                            <b> {item.taskHeading} </b>
                          </h5>
                        </div>
                      </div>

                      <br />
                      <div className="row">
                        <div className="col-3">
                          {item.taskStatus == "Assigned" ? (
                            <span className="badge bg-primary">
                              <i className="bi bi-person-lines-fill"></i>
                              Assigned
                            </span>
                          ) : item.taskStatus == "In Progress" ? (
                            <span className="badge bg-warning text-dark">
                              <i className="bi bi-fan"></i> In Progress
                            </span>
                          ) : item.taskStatus == "Canceled" ? (
                            <span className="badge bg-danger">
                              <i className="bi bi-x-lg"></i> Canceled
                            </span>
                          ) : item.taskStatus == "Completed" ? (
                            <span className="badge bg-success">
                              <i className="bi bi-check-lg"></i>
                              Completed
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <p className="card-text">
                            <b>{item.added_by_username}</b> assigned
                            <b>{" "}{item.taskHeading}</b> task to
                            <b>{" "}{item.assineTO_userName}</b>
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-" + item.id}
                            className="btn btn-transparent btn-sm"
                          >
                            Description
                            <i className="bi bi-caret-down-fill"></i>
                          </button>
                        </div>
                        <div className="col-6">
                          <p align="right" className="card-text">
                            <small className="text-white">
                              {alterElement(item)}&nbsp;
                            </small>
                          </p>
                        </div>
                      </div>

                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item bg-transparent">
                          <div
                            id={"flush-" + item.id}
                            className="accordion-collapse collapse bg-transparent"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body  bg-transparent">
                              {item.description}
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              )
            )}

          <div className="line" />
        </div>
      </div>
    </div></>
  )
}

export default Sidenav;