import React, { Component, useEffect, useState, PureComponent } from "react";
import axios from "axios";
import Tooltip1 from 'react-bootstrap/Tooltip';
import { Chart } from "react-google-charts";

import nullreport from "../media/undraw_report_re_f5n5.svg";
import nullkpi from "../media/undraw_teacher_re_sico.svg";
import EditableTextField from "../pages/editableTextField";

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import graph from "../media/graph.mp4";
import selectmem from "../media/kpi.png";
import avatar from "../media/147144.png";
import Playground from "../pages/Playground.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function Report() {
  const organisation_name = localStorage.getItem("organisation_name");
  const org = localStorage.getItem("org");
  const Auth = localStorage.getItem("Auth");
  const admin = localStorage.getItem("admin");

  //////////Get Category//////////////////////////////////////////////////////////////////////////////

  const renderTooltip = (props) => (
    <Tooltip1 id="button-tooltip" {...props}>
      {props}
    </Tooltip1>
  );
  const [feedOrgCategory, setOrgCategory] = useState();

  const [feedSuggestion1, setfeedSuggestion1] = useState();
  const [feedSuggestion2, setfeedSuggestion2] = useState();
  const [feedSuggestion3, setfeedSuggestion3] = useState();
  const [feedSuggestion4, setfeedSuggestion4] = useState();
  const [feedSuggestion5, setfeedSuggestion5] = useState();
  const [feedSuggestion6, setfeedSuggestion6] = useState();

  const [feedQuestion1, setfeedQuestion1] = useState();
  const [feedQuestion2, setfeedQuestion2] = useState();
  const [feedQuestion3, setfeedQuestion3] = useState();
  const [feedQuestion4, setfeedQuestion4] = useState();
  const [feedQuestion5, setfeedQuestion5] = useState();
  const [feedQuestion6, setfeedQuestion6] = useState();

  const [team, setTeam] = useState();

  const [dailyteam, setDailyTeam] = useState();

  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [filterDate1, setFilterDate1] = useState({
    organisation_id: org,
    script_category: "",
    staring_date: "",
    ending_date: "",
  });

  const [filterDate2, setFilterDate2] = useState({
    organisation_id: org,
    script_category: "",
    staring_date: "",
    ending_date: "",
  });




  const handleEdit = (index, field, value) => {
    const newData = [...usertarget];
    newData[index][field] = value;
    setUsertarget(newData);
  };
  


  //////////////////////////KPI/////////////////////////////////////////////////////

  const [feedUser, setFeedUser] = useState();
  const [feedOrgCalls, setFeedOrg] = useState();
  const [feedOrgTask, setTaskOrg] = useState();
  const [feedCallsCat, setfeedCallsCat] = useState();
  const [feedTaskCat, setfeedTaskCat] = useState();
  const [allMood, setallMood] = useState();
  const [feedMood, setfeedMood] = useState();
  const [allCallsCat, setallCallsCat] = useState();
  const [liveuser, setliveuser] = useState("None");
  const [timecat, settimecat] = useState({ timeCat: "month" });

  const [leadPerformance, setLeadPerformance] = useState({});

  console.log(leadPerformance);
  

  const [kpicomp, setkpicomp] = useState({
    organisation_id: org,
    userid1: "",
    userid2: "",
    script_category: "None",
  });

  const [targetfilter, setTargetfilter] = useState({
    month : "August",
    year : "2024"
    
  });

  function changeTarget(e) {
    setTargetfilter({ ...targetfilter, [e.target.name]: e.target.value });
  }

  const [usertarget, setUsertarget] = useState([]);

  function updatKPIComp(e) {
    setkpicomp({ ...kpicomp, [e.target.name]: e.target.value });
  }

  const [pitchCatData, setPitchData] = useState({ pitchCat: "None" });

  function updataScriptData(e) {
    setPitchData({ ...pitchCatData, [e.target.name]: e.target.value });
  }


  const [data02, set_data02] = useState([
    { name: 'Unique call', value: 0 },
    { name: 'Repeted call', value: 0 },
  ]);

  const [data_status_kpi, set_data_status_kpi] = useState([
    {
      name: "Not valid",
      call: 0,
    },
    {
      name: "Did not pick",
      call: 0,
    },
    {
      name: "Cut Call",
      call: 0,
    },
    {
      name: "Call Later",
      call: 0,
    },
    {
      name: "Not Interested",
      call: 0,
    },

    {
      name: "Interested",
      call: 0,
    },
  ]);


  const [data_performance_kpi, set_data_performance_kpi] = useState([
    {
      name: "None",
      call: 0,
    },
    {
      name: "Salute",
      call: 0,
    },
    {
      name: "Pitch",
      call: 0,
    },
    {
      name: "Consult",
      call: 0,
    },
    {
      name: "Objection",
      call: 0,
    },
    {
      name: "Call Closing",
      call: 0,
    },
  ]);

  const [call_data, set_call_data] = useState([
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
    {
      call: 0,
    },
  ]);

  const [compdata, set_compdata] = useState([
    {
      name: "Date A",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date A",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date B",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date B",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date C",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date C",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date D",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date D",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date E",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date E",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date F",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date F",
      Interested: 0,
      Not_Interested: 0,
    },
    {
      name: "Date G",
      Interested: 0,
      Not_Interested: 0,
    },
  ]);


////////////////////////////////////////////////Report/////////////////////////////////////////////



const [ReportDate, setReportDate] = useState({
  date: "",
});
const [ReportUser, setReportUser] = useState();

const [ReportResponse, setReportResponse] = 
useState({
  Today_total_call: "",
  Repeted_calls: "",
  Unique_calls: "",

});

const [uniquecallreport, Setuniquecallreport] = useState();
const [repetedcallreport, Setrepetedcallreport] = useState();


const chartdata = [
  ["Task", "Hours per Day"],
  ["Unique call", uniquecallreport],
  ["Repeted call", repetedcallreport]
];

const [Today_Call_Briefs, SetToday_Call_Briefs] = useState();
const [Scripts_Call_Briefs, SetScripts_Call_Briefs] = useState();

console.log(Scripts_Call_Briefs);

function checkadmin(){


  if(admin != 'true'){

    window.location.replace("https://dashboard.salessunday.com/");

  }


}

function changeReportUser(item){

  setReportUser(item);
  setReportResponse('');
  SetScripts_Call_Briefs('');
  Setuniquecallreport('');
  Setrepetedcallreport('');
  set_data02();
  SetToday_Call_Briefs();
  get_UsersReport(item);
  // setReportDate({
  //   date: "",
  // });


}


function getUserNameById(usersArray, userId) {
  const user = usersArray.find(user => user.id === userId);

  if (user) {
    return user.user_name;
  } else {
    return null; // or any default value you prefer if the user is not found
  }
}

function formatCurrency(number,type) {
  // Use the toLocaleString method to add commas and format as currency
  let formatted = number.toLocaleString('en-US', { style: 'currency', currency: type });
  formatted = formatted.replace(/\.00$/, '');
  return formatted;
}




function updateReportData(e) {
  setReportDate({ ...ReportDate, [e.target.name]: e.target.value });
  }

  function updateReportUser(reportuserid){
    ReportUser.user = reportuserid;
  }
  
  

  function updataTimecat(e) {
    settimecat({ ...timecat, [e.target.name]: e.target.value });
  }

  function updataLiveCat(item) {
    scriptVote(item);
    getSuggestions(item);
    getQuestion(item);
  }

  function changeLiveUser(item) {
    item = item.user_id;

    setliveuser(item);
    setPitchData({ pitchCat: "None" });

    Task_Count(item);
    Scripts_call_Count(item);
    gettotal_mood(item);

    set_data_performance_kpi([
      {
        name: "None",
        call: 0,
      },
      {
        name: "Salute",
        call: 0,
      },
      {
        name: "Pitch",
        call: 0,
      },
      {
        name: "Consult",
        call: 0,
      },
      {
        name: "Objection",
        call: 0,
      },
      {
        name: "Call Closing",
        call: 0,
      },
    ]);

    set_data_status_kpi([
      {
        name: "Not valid",
        call: 0,
      },
      {
        name: "Did not pick",
        call: 0,
      },
      {
        name: "Cut Call",
        call: 0,
      },
      {
        name: "Call Later",
        call: 0,
      },
      {
        name: "Not Interested",
        call: 0,
      },

      {
        name: "Interested",
        call: 0,
      },
    ]);

    set_call_data([
      {
        call: 0,
      },
      {
        call: 0,
      },
      {
        call: 0,
      },
      {
        call: 0,
      },
      {
        call: 0,
      },
      {
        call: 0,
      },
      {
        call: 0,
      },
    ]);
  }


  function getCall() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_user_call_count?userid=".concat(
          liveuser,
          "&script_category=",
          pitchCatData.pitchCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          let user_call = Object.values(data.data);
          let user_date = Object.keys(data.data);

          set_call_data([
            {
              name: user_date[6],
              call: user_call[6],
            },
            {
              name: user_date[5],
              call: user_call[5],
            },
            {
              name: user_date[4],
              call: user_call[4],
            },
            {
              name: user_date[3],
              call: user_call[3],
            },
            {
              name: user_date[2],
              call: user_call[2],
            },
            {
              name: user_date[1],
              call: user_call[1],
            },
            {
              name: user_date[0],
              call: user_call[0],
            },
          ]);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function Scripts_call_Count(item) {
    if (item == null) {
      item = liveuser;
    }

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/Scripts_call_Count?organisation_id=".concat(
          org,
          "&userID=",
          item,
          "&script_category=",
          pitchCatData.pitchCat,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          setFeedOrg(data.data.totalCalls);
          setfeedCallsCat(data.data.script_type_calls);
          setallCallsCat(data.data.script_type);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function Task_Count(item) {
    if (item == null) {
      item = liveuser;
    }

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/TaskHeading_count?organisation_id=".concat(
          org,
          "&taskHeading=",
          "None",
          "&userID=",
          item,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          setTaskOrg(data.data.Total_Number_of_Task);
          setfeedTaskCat(data.data.Task_Created);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function get_Userstotal_calls() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/get_Userstotal_calls?organisation_id=".concat(
          org,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setFeedUser(data.data.totalUserCalls);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function get_UsersReport(item) {

    if(item ==null || item ==""){
      item = ReportUser; 
    }

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/Get_user_today_report?Date=".concat(
          ReportDate.date,
          "&UserID=",
          item
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setReportResponse(data.data);



          SetScripts_Call_Briefs(data.data.Scripts_Call_Briefs);
          Setuniquecallreport(data.data.Unique_calls);
          Setrepetedcallreport(data.data.Repeted_calls);
          
          
          set_data02([
            { name: 'Unique call', value: data.data.Unique_calls },
            { name: 'Repeted call', value: data.data.Repeted_calls }
          ]);

          var result =[];
          for(var i in data.data.Today_Call_Briefs)
          result.push([i, data.data.Today_Call_Briefs [i]]);
        
          SetToday_Call_Briefs(result);

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function getUserDaily() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/getusersdailyperformance?Date=".concat(
          ReportDate.date),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setDailyTeam(data.data);

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function gettotal_mood(item) {
    if (item == null) {
      item = liveuser;
    }

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/clientMood?organisation_id=".concat(
          org,
          "&userID=",
          item,
          "&script_category=",
          pitchCatData.pitchCat,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setallMood(data.data.totalCalls);
          setfeedMood(data.data.clint_mood);

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function get_user_status_performance() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/users_scripts_call_status?organisation_id=".concat(
          org,
          "&userID=",
          liveuser,
          "&script_type=",
          pitchCatData.pitchCat,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          set_data_status_kpi([
            {
              name: "Not valid",
              call: data.data.not_valis,
            },
            {
              name: "Did not pick",
              call: data.data.did_not_pick,
            },
            {
              name: "Cut Call",
              call: data.data.cut_call,
            },
            {
              name: "Call Later",
              call: data.data.call_later,
            },
            {
              name: "Not Interested",
              call: data.data.not_interested,
            },

            {
              name: "Interested",
              call: data.data.interested,
            },
          ]);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

    axios
      .get(
        "/users_scripts_call_performances?organisation_id=".concat(
          org,
          "&userID=",
          liveuser,
          "&script_type=",
          pitchCatData.pitchCat,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          set_data_performance_kpi([
            {
              name: "None",
              call: data.data.none,
            },
            {
              name: "Salute & Con",
              call: data.data.salute_confirmation,
            },
            {
              name: "Pitch",
              call: data.data.pitch_information,
            },
            {
              name: "Consult",
              call: data.data.ask_question_talk,
            },
            {
              name: "Objection",
              call: data.data.objection_handling,
            },
            {
              name: "Call Closing",
              call: data.data.closing,
            },
          ]);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

    getCall();
    Scripts_call_Count();
    Task_Count();

    axios
      .get(
        "/clientMood?organisation_id=".concat(
          org,
          "&userID=",
          liveuser,
          "&script_category=",
          pitchCatData.pitchCat,
          "&days=",
          timecat.timeCat
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setallMood(data.data.totalCalls);
          setfeedMood(data.data.clint_mood);

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function progress_element(item) {
    return (100 * item) / feedOrgCalls;
  }

  function progress_element_script(item1, item2) {
    let value = (100 * item1) / item2;

    return Math.round(value * 100) / 100;
  }

  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  }

  function updateFilterDate1(e) {
    setFilterDate1({ ...filterDate1, [e.target.name]: e.target.value });
  }

  function updateFilterDate2(e) {
    setFilterDate2({ ...filterDate2, [e.target.name]: e.target.value });
  }


  const [data_vote, set_data_vote] = useState([
    {
      name: "Step 1",
      down: 0,
      up: 0,
    },
    {
      name: "Step 2",
      down: 0,
      up: 0,
    },
    {
      name: "Step 3",
      down: 0,
      up: 0,
    },
    {
      name: "Step 4",
      down: 0,
      up: 0,
    },
    {
      name: "Step 5",
      down: 0,
      up: 0,
    },
    {
      name: "Call Savior",
      down: 0,
      up: 0,
    },
  ]);

  const [data_status, set_data_status] = useState([
    {
      name: "Not valid",
      call: 0,
    },
    {
      name: "Did not pick",
      call: 0,
    },
    {
      name: "Cut Call",
      call: 0,
    },
    {
      name: "Call Later",
      call: 0,
    },
    {
      name: "Not Interested",
      call: 0,
    },

    {
      name: "Interested",
      call: 0,
    },
  ]);

  const [data_performance, set_data_performance] = useState([
    {
      name: "None",
      call: 0,
    },
    {
      name: "Salute & Con",
      call: 0,
    },
    {
      name: "Pitch & Info",
      call: 0,
    },
    {
      name: "Question & Talk",
      call: 0,
    },
    {
      name: "Objection",
      call: 0,
    },
    {
      name: "Call Closing",
      call: 0,
    },
  ]);

  const [answer, setAnswer] = useState({ question_id: "", answers: "" });

  function updateAnswer(e) {
    setAnswer({ ...answer, [e.target.name]: e.target.value });
  }


  function fetchOrgData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_script_types?orgID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgCategory(data.data.scripts_types);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function generateavatar(text, foreground = "white", background = "black") {
    text = text.charAt(0);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    context.fillStyle = background;
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = "bold 100px verdana";
    context.fillStyle = foreground;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  }

  function getSuggestions(script) {
    var script = script;
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/getsuggestion?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part1"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedSuggestion1(data.data.suggestions);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getsuggestion?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part2"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedSuggestion2(data.data.suggestions);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getsuggestion?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part3"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedSuggestion3(data.data.suggestions);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getsuggestion?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part4"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedSuggestion4(data.data.suggestions);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getsuggestion?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part5"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedSuggestion5(data.data.suggestions);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getsuggestion?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part6"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedSuggestion6(data.data.suggestions);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getQuestion(script) {
    var script = script;
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/getquestions?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part1"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedQuestion1(data.data.questions_answer);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getquestions?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part2"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedQuestion2(data.data.questions_answer);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getquestions?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part3"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedQuestion3(data.data.questions_answer);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getquestions?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part4"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedQuestion4(data.data.questions_answer);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getquestions?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part5"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedQuestion5(data.data.questions_answer);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        "/getquestions?organization_id=".concat(
          org,
          "&script_catagory=",
          script,
          "&script_part=part6"
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setfeedQuestion6(data.data.questions_answer);
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function playVid() {
    document.getElementById("vid").play();
  }

  useEffect(() => {
    checkadmin();
    fetchOrgData();
    fetchUserData();
  }, [1]);

  function removeSpcae(item) {
    var callCategory = item;
    callCategory = callCategory.split(" ").join("");
    return callCategory;
  }

  function moodAlter(item) {
    if (item == "intense") {
      return (
        <i className="bi bi-emoji-angry-fill text-danger" title="Intense">
          {" "}
          <b>Intense</b>
        </i>
      );
    } else if (item == "unhappy") {
      return (
        <i className="bi bi-emoji-frown-fill text-dark" title="Unhappy">
          {" "}
          <b>Unhappy</b>
        </i>
      );
    } else if (item == "normal") {
      return (
        <i className="bi bi-emoji-smile-fill text-primary" title="Normal">
          {" "}
          <b>Normal</b>
        </i>
      );
    } else if (item == "happy") {
      return (
        <i className="bi bi-emoji-laughing-fill text-success" title="Happy">
          {" "}
          <b>Happy</b>
        </i>
      );
    } else if (item == "") {
      return (
        <i>
          <b>None</b>
        </i>
      );
    }
  }

  ///////////////////////////script vote///////////////////////////////////////////////////////

  function scriptVote(script) {
    var script = script;


    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_scrips_votes_count?orgid=".concat(
          org,
          "&script_catagory=",
          script
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {

          set_data_vote([
            {
              name: "Step 1",
              down: data.data[0].down_vote_count,
              up: data.data[0].up_vote_count,
            },
            {
              name: "Step 2",
              down: data.data[1].down_vote_count,
              up: data.data[1].up_vote_count,
            },
            {
              name: "Step 3",
              down: data.data[2].down_vote_count,
              up: data.data[2].up_vote_count,
            },
            {
              name: "Step 4",
              down: data.data[3].down_vote_count,
              up: data.data[3].up_vote_count,
            },
            {
              name: "Step 5",
              down: data.data[4].down_vote_count,
              up: data.data[4].up_vote_count,
            },
            {
              name: "Call Savior",
              down: data.data[5].down_vote_count,
              up: data.data[5].up_vote_count,
            },
          ]);

          //////////////////////////////Suggestion Call///////////////////////////////
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  ///////////////////////////Call Status///////////////////////////////////////////////////////

  function callStatus(script) {
    var script = script;

    filterDate1.script_category = script;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post("/get_call_status/", filterDate1, { headers })
      .then((data) => {
        if (data.status == "200") {

          set_data_status([
            {
              name: "Not valid",
              call: data.data.not_valis,
            },
            {
              name: "Did not pick",
              call: data.data.did_not_pick,
            },
            {
              name: "Cut Call",
              call: data.data.cut_call,
            },
            {
              name: "Call Later",
              call: data.data.call_later,
            },
            {
              name: "Not Interested",
              call: data.data.not_interested,
            },

            {
              name: "Interested",
              call: data.data.interested,
            },
          ]);

          //////////////////////////////Suggestion Call///////////////////////////////
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  //////////////////////////////Call Performance///////////////////////////////

  function callPerformance(script) {
    var script = script;

    filterDate2.script_category = script;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/get_call_performances/", filterDate2, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {

          set_data_performance([
            {
              name: "None",
              call: data.data.none,
            },
            {
              name: "Salute & Con",
              call: data.data.salute_confirmation,
            },
            {
              name: "Pitch & Info",
              call: data.data.pitch_information,
            },
            {
              name: "Question & Talk",
              call: data.data.ask_question_talk,
            },
            {
              name: "Objection",
              call: data.data.objection_handling,
            },
            {
              name: "Call Closing",
              call: data.data.closing,
            },
          ]);

          //////////////////////////////Suggestion Call///////////////////////////////
        }
        setErrorMessage1(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function alterElement(item) {
    var datetime = item.datetime;
    datetime = datetime.substring(0, 16);
    return datetime;
  }

  function sendAnswer(item) {
    var item_question_id = item;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post(
        "/Answers?question_id=".concat(
          item_question_id,
          "&answers=",
          answer.answers
        ),
        {},
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          answer.answers = "";
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function CompGraph() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/users_weekly_calls_comparison?organisation_id=".concat(
          org,
          "&userid1=",
          kpicomp.userid1,
          "&userid2=",
          kpicomp.userid2,
          "&script_category=",
          kpicomp.script_category
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          let user_call = Object.values(data.data);
          let user_date = user_call;
          let user_call1 = Object.keys(user_call[0]);
          let user_date1 = Object.values(user_date[0]);
          let user_call2 = Object.keys(user_call[1]);
          let user_date2 = Object.values(user_date[1]);


          set_compdata([
            {
              name: user_call1[0],
              Interested: user_date1[0].Interested,
              Not_Interested: user_date1[0].Not_Interested,
            },
            {
              name: user_call1[0],
              Interested: user_date2[0].Interested,
              Not_Interested: user_date2[0].Not_Interested,
            },
            {
              name: user_call1[1],
              Interested: user_date1[1].Interested,
              Not_Interested: user_date1[1].Not_Interested,
            },
            {
              name: user_call1[1],
              Interested: user_date2[1].Interested,
              Not_Interested: user_date2[1].Not_Interested,
            },
            {
              name: user_call1[2],
              Interested: user_date1[2].Interested,
              Not_Interested: user_date1[2].Not_Interested,
            },
            {
              name: user_call1[2],
              Interested: user_date2[2].Interested,
              Not_Interested: user_date2[2].Not_Interested,
            },
            {
              name: user_call1[3],
              Interested: user_date1[3].Interested,
              Not_Interested: user_date1[3].Not_Interested,
            },
            {
              name: user_call1[3],
              Interested: user_date2[3].Interested,
              Not_Interested: user_date2[3].Not_Interested,
            },
            {
              name: user_call1[4],
              Interested: user_date1[4].Interested,
              Not_Interested: user_date1[4].Not_Interested,
            },
            {
              name: user_call1[4],
              Interested: user_date2[4].Interested,
              Not_Interested: user_date2[4].Not_Interested,
            },
            {
              name: user_call1[5],
              Interested: user_date1[5].Interested,
              Not_Interested: user_date1[5].Not_Interested,
            },
            {
              name: user_call1[5],
              Interested: user_date2[5].Interested,
              Not_Interested: user_date2[5].Not_Interested,
            },
            {
              name: user_call1[6],
              Interested: user_date1[6].Interested,
              Not_Interested: user_date1[6].Not_Interested,
            },
            {
              name: user_call1[6],
              Interested: user_date2[6].Interested,
              Not_Interested: user_date2[6].Not_Interested,
            },
          ]);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function changeTimeCat() {
    setFeedUser("");
    setfeedCallsCat("");
    Scripts_call_Count();
    Task_Count();
    gettotal_mood();
    get_Userstotal_calls();
    setPitchData({ pitchCat: "None" });
    setliveuser("None");

    set_data_performance_kpi([
      {
        name: "None",
        call: 0,
      },
      {
        name: "Salute",
        call: 0,
      },
      {
        name: "Pitch",
        call: 0,
      },
      {
        name: "Consult",
        call: 0,
      },
      {
        name: "Objection",
        call: 0,
      },
      {
        name: "Call Closing",
        call: 0,
      },
    ]);

    set_data_status_kpi([
      {
        name: "Not valid",
        call: 0,
      },
      {
        name: "Did not pick",
        call: 0,
      },
      {
        name: "Cut Call",
        call: 0,
      },
      {
        name: "Call Later",
        call: 0,
      },
      {
        name: "Not Interested",
        call: 0,
      },

      {
        name: "Interested",
        call: 0,
      },
    ]);

    set_data_performance_kpi([
      {
        name: "None",
        call: 0,
      },
      {
        name: "Salute",
        call: 0,
      },
      {
        name: "Pitch",
        call: 0,
      },
      {
        name: "Consult",
        call: 0,
      },
      {
        name: "Objection",
        call: 0,
      },
      {
        name: "Call Closing",
        call: 0,
      },
    ]);

    set_data_status_kpi([
      {
        name: "Not valid",
        call: 0,
      },
      {
        name: "Did not pick",
        call: 0,
      },
      {
        name: "Cut Call",
        call: 0,
      },
      {
        name: "Call Later",
        call: 0,
      },
      {
        name: "Not Interested",
        call: 0,
      },

      {
        name: "Interested",
        call: 0,
      },
    ]);
  }

  function fetchUserData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/getusers?organisation_id=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setTeam(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function Lead_Performance(){
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/Get_lead_performance", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
         
          setLeadPerformance(data.data);
          
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function get_target(){
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(`/get_target?month=${targetfilter.month}&year=${targetfilter.year}`, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
         
          setUsertarget(data.data[0]['target']); 
        
          
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }


  function add_target() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post(
        "/add_target",
        {
          "organisation_id": org,
          "month": targetfilter.month,
          "year": targetfilter.year,
          "target": usertarget
      
      },
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          get_target();
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }



  function kpifunctions() {
    Lead_Performance();
    Scripts_call_Count();
    Task_Count();
    get_Userstotal_calls();
    gettotal_mood();
  }

  function reportfunctions(){
    
  }



  return (
    <div className="container">
      <nav>
        <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            <i className="bi bi-file-text"></i> Script
          </button>
          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            <i className="bi bi-geo-fill"></i> Status
          </button>
          <button
            className="nav-link"
            id="nav-perfor-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-perfor"
            type="button"
            role="tab"
            aria-controls="nav-perfor"
            aria-selected="false"
          >
            <i className="bi bi-bicycle"></i> Performance
          </button>
          <button
            className="nav-link"
            onClick={kpifunctions}
            id="nav-kpi-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-kpi"
            type="button"
            role="tab"
            aria-controls="nav-kpi"
            aria-selected="false"
          >
            <i className="bi bi-rulers"></i> KPI
          </button>
          <button
            className="nav-link"
            onClick={reportfunctions}
            id="nav-report-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-report"
            type="button"
            role="tab"
            aria-controls="nav-report"
            aria-selected="false"
          >
            <i className="bi bi-person-lines-fill"></i> Daily Report
          </button>
          <button
            className="nav-link"
            onClick={get_target}
            id="nav-target-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-target"
            type="button"
            role="tab"
            aria-controls="nav-target"
            aria-selected="false"
          >
            <i className="bi bi-bullseye"></i> Target
          </button>
          {/* <button
            className="nav-link"
            id="nav-play-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-play"
            type="button"
            role="tab"
            aria-controls="nav-play"
            aria-selected="false"
          >
            <i className="bi bi-controller"></i> Play Ground{" "}
          </button> */}
        </div>
        <br />
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active p-3 mb-5"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div className="row">
            <div className="col-12 col-sm-3">
              <br />
              <br />
              <p>
                <mark>
                  <i className="bi bi-file-text-fill"></i> Select script
                  category <i className="bi bi-arrow-down"></i>
                </mark>
              </p>
              <div
                className="overflow-auto p-1 bg-transparent"
                style={{ maxWidth: "500px", maxHeight: "400px" }}
              >
                <ul className="list-group">
                  {feedOrgCategory &&
                    feedOrgCategory?.map((item) => (
                      <li
                        className="list-group-item cursor listhover border-dark"
                        id="list-item"
                        onClick={() => updataLiveCat(item)}
                      >
                        {item}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div>
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      <i className="bi bi-bar-chart-line"></i> Vote
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      <i className="bi bi-chat-left-text"></i> Suggestions
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      <i className="bi bi-question-square"></i> Questions
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <ResponsiveContainer width="100%" height={450}>
                    <BarChart
                      width={850}
                      height={450}
                      data={data_vote}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="up" fill="#0275d8" />
                      <Bar dataKey="down" fill="#d9534f" />
                    </BarChart>

                    </ResponsiveContainer>
                  

                    <div align="left">
                      <a href="soplab">
                        <button type="button" className="btn btn-dark pop">
                          <i className="bi bi-pen" /> Edit Script
                        </button>
                      </a>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="container m-5 pb-5">
                      <div
                        className="overflow-auto p-3"
                        style={{ maxWidth: "1000px", maxHeight: "600px" }}
                      >
                        <p>&nbsp;Part 1 </p>

                        {feedSuggestion1 &&
                          feedSuggestion1?.map((item) => (
                            <div className="row">
                              <div className="col-1">
                                <br />
                                <img
                                  className="rounded-circle"
                                  src={avatar}
                                  width="40"
                                  height="40"
                                ></img>
                              </div>
                              <div className="col-9">
                                <a
                                  className="list-group-item list-group-item-action m-1 text-dark rounded"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-1">
                                      {item.suggest_by_username}
                                    </h6>
                                    <small>{alterElement(item)}</small>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between">
                                    <p className="mb-1">{item.text}</p>
                                    <small>
                                      <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      ></a>
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}

                        <p>&nbsp;Part 2 </p>
                        {feedSuggestion2 &&
                          feedSuggestion2?.map((item) => (
                            <div className="row">
                              <div className="col-1">
                                <br />
                                <img
                                  className="rounded-circle"
                                  src={avatar}
                                  width="40"
                                  height="40"
                                ></img>
                              </div>
                              <div className="col-9">
                                <a
                                  className="list-group-item list-group-item-action m-1 text-dark rounded"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-1">
                                      {item.suggest_by_username}
                                    </h6>
                                    <small>{alterElement(item)}</small>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between">
                                    <p className="mb-1">{item.text}</p>
                                    <small>
                                      <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      ></a>
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}

                        <p>&nbsp;Part 3 </p>
                        {feedSuggestion3 &&
                          feedSuggestion3?.map((item) => (
                            <div className="row">
                              <div className="col-1">
                                <br />
                                <img
                                  className="rounded-circle"
                                  src={avatar}
                                  width="40"
                                  height="40"
                                ></img>
                              </div>
                              <div className="col-9">
                                <a
                                  className="list-group-item list-group-item-action m-1 text-dark rounded"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-1">
                                      {item.suggest_by_username}
                                    </h6>
                                    <small>{alterElement(item)}</small>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between">
                                    <p className="mb-1">{item.text}</p>
                                    <small>
                                      <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      ></a>
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}

                        <p>&nbsp;Part 4 </p>
                        {feedSuggestion4 &&
                          feedSuggestion4?.map((item) => (
                            <div className="row">
                              <div className="col-1">
                                <br />
                                <img
                                  className="rounded-circle"
                                  src={avatar}
                                  width="40"
                                  height="40"
                                ></img>
                              </div>
                              <div className="col-9">
                                <a
                                  className="list-group-item list-group-item-action m-1 text-dark rounded"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-1">
                                      {item.suggest_by_username}
                                    </h6>
                                    <small>{alterElement(item)}</small>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between">
                                    <p className="mb-1">{item.text}</p>
                                    <small>
                                      <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      ></a>
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}

                        <p>&nbsp;Part 5 </p>
                        {feedSuggestion5 &&
                          feedSuggestion5?.map((item) => (
                            <div className="row">
                              <div className="col-1">
                                <br />
                                <img
                                  className="rounded-circle"
                                  src={avatar}
                                  width="40"
                                  height="40"
                                ></img>
                              </div>
                              <div className="col-9">
                                <a
                                  className="list-group-item list-group-item-action m-1 text-dark rounded"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-1">
                                      {item.suggest_by_username}
                                    </h6>
                                    <small>{alterElement(item)}</small>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between">
                                    <p className="mb-1">{item.text}</p>
                                    <small>
                                      <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      ></a>
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}

                        <p>&nbsp;Part 6 </p>
                        {feedSuggestion6 &&
                          feedSuggestion6?.map((item) => (
                            <div className="row">
                              <div className="col-1">
                                <br />
                                <img
                                  className="rounded-circle"
                                  src={avatar}
                                  width="40"
                                  height="40"
                                ></img>
                              </div>
                              <div className="col-9">
                                <a
                                  className="list-group-item list-group-item-action m-1 text-dark rounded"
                                  aria-current="true"
                                >
                                  <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-1">
                                      {item.suggest_by_username}
                                    </h6>
                                    <small>{alterElement(item)}</small>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between">
                                    <p className="mb-1">{item.text}</p>
                                    <small>
                                      <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      ></a>
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="container m-5 pb-5">
                      <div className="list-group">
                        <div className="accordion" id="accordionExample">
                          <div
                            className="overflow-auto p-3"
                            style={{ maxWidth: "1000px", maxHeight: "600px" }}
                          >
                            {" "}
                            <p>Part 1 </p>
                            {feedQuestion1 &&
                              feedQuestion1?.map((item) => (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#collapse" + item.question_id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "collapse" + item.question_id
                                      }
                                    >
                                      <img
                                        src={avatar}
                                        width="40"
                                        height="40"
                                      ></img>
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                          {item.ask_by_username}
                                        </div>

                                        {item.text}
                                      </div>
                                    </button>
                                  </h2>
                                  <div
                                    id={"collapse" + item.question_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                        <b>Answer</b>: {item.answer}
                                      </p>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="answers"
                                          value={answer.answers}
                                          onChange={updateAnswer}
                                          placeholder="answers"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                        />
                                        <button
                                          onClick={() =>
                                            sendAnswer(item.question_id)
                                          }
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          id="button-addon2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <p>Part 2 </p>
                            {feedQuestion2 &&
                              feedQuestion2?.map((item) => (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#collapse" + item.question_id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "collapse" + item.question_id
                                      }
                                    >
                                      <img
                                        src={avatar}
                                        width="40"
                                        height="40"
                                      ></img>
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                          {item.ask_by_username}
                                        </div>

                                        {item.text}
                                      </div>
                                    </button>
                                  </h2>
                                  <div
                                    id={"collapse" + item.question_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                        <b>Answer</b>: {item.answer}
                                      </p>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="answers"
                                          value={answer.answers}
                                          onChange={updateAnswer}
                                          placeholder="answers"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                        />
                                        <button
                                          onClick={() =>
                                            sendAnswer(item.question_id)
                                          }
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          id="button-addon2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <p>Part 3 </p>
                            {feedQuestion3 &&
                              feedQuestion3?.map((item) => (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#collapse" + item.question_id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "collapse" + item.question_id
                                      }
                                    >
                                      <img
                                        src={avatar}
                                        width="40"
                                        height="40"
                                      ></img>
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                          {item.ask_by_username}
                                        </div>

                                        {item.text}
                                      </div>
                                    </button>
                                  </h2>
                                  <div
                                    id={"collapse" + item.question_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                        <b>Answer</b>: {item.answer}
                                      </p>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="answers"
                                          value={answer.answers}
                                          onChange={updateAnswer}
                                          placeholder="answers"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                        />
                                        <button
                                          onClick={() =>
                                            sendAnswer(item.question_id)
                                          }
                                          className="btn btn-outline-secondary"
                                          type="submit"
                                          id="button-addon2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <p>Part 4 </p>
                            {feedQuestion4 &&
                              feedQuestion4?.map((item) => (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#collapse" + item.question_id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "collapse" + item.question_id
                                      }
                                    >
                                      <img
                                        src={avatar}
                                        width="40"
                                        height="40"
                                      ></img>
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                          {item.ask_by_username}
                                        </div>

                                        {item.text}
                                      </div>
                                    </button>
                                  </h2>
                                  <div
                                    id={"collapse" + item.question_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                        <b>Answer</b>: {item.answer}
                                      </p>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="answers"
                                          value={answer.answers}
                                          onChange={updateAnswer}
                                          placeholder="answers"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                        />
                                        <button
                                          className="btn btn-outline-secondary"
                                          onClick={() =>
                                            sendAnswer(item.question_id)
                                          }
                                          type="submit"
                                          id="button-addon2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <p>Part 5 </p>
                            {feedQuestion5 &&
                              feedQuestion5?.map((item) => (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#collapse" + item.question_id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "collapse" + item.question_id
                                      }
                                    >
                                      <img
                                        src={avatar}
                                        width="40"
                                        height="40"
                                      ></img>
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                          {item.ask_by_username}
                                        </div>

                                        {item.text}
                                      </div>
                                    </button>
                                  </h2>
                                  <div
                                    id={"collapse" + item.question_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                        <b>Answer</b>: {item.answer}
                                      </p>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="answers"
                                          value={answer.answers}
                                          onChange={updateAnswer}
                                          placeholder="answers"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                        />
                                        <button
                                          className="btn btn-outline-secondary"
                                          onClick={() =>
                                            sendAnswer(item.question_id)
                                          }
                                          type="submit"
                                          id="button-addon2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <p>Part 6 </p>
                            {feedQuestion6 &&
                              feedQuestion6?.map((item) => (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#collapse" + item.question_id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "collapse" + item.question_id
                                      }
                                    >
                                      <img
                                        src={avatar}
                                        width="40"
                                        height="40"
                                      ></img>
                                      <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                          {item.ask_by_username}
                                        </div>

                                        {item.text}
                                      </div>
                                    </button>
                                  </h2>
                                  <div
                                    id={"collapse" + item.question_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                        <b>Answer</b>: {item.answer}
                                      </p>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="answers"
                                          value={answer.answers}
                                          onChange={updateAnswer}
                                          placeholder="answers"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                        />
                                        <button
                                          className="btn btn-outline-secondary"
                                          onClick={() =>
                                            sendAnswer(item.question_id)
                                          }
                                          type="submit"
                                          id="button-addon2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade p-3 mb-10"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div className="d-flex align-items-start">
            <br />
            <br />
            <br />

            <div
              className="overflow-auto p-3 bg-transparent"
              style={{ maxWidth: "500px", maxHeight: "500px" }}
            >
              <p>
                <mark>
                  <i className="bi bi-file-text-fill"></i> Select script
                  category <i className="bi bi-arrow-down"></i>
                </mark>
              </p>
              <div
                className="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {feedOrgCategory &&
                  feedOrgCategory?.map((item) => (
                    <button
                      onClick={() => callStatus(item)}
                      className="nav-link"
                      id={"v-pills--" + removeSpcae(item) + "-tab"}
                      data-bs-toggle="pill"
                      data-bs-target={"#v-pills--" + removeSpcae(item)}
                      type="button"
                      role="tab"
                      aria-controls={"v-pills--" + removeSpcae(item)}
                      aria-selected="true"
                    >
                      {item}
                    </button>
                  ))}
              </div>
            </div>

            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex justify-content-center">
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img
                    src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/9.+Performance+empty.svg"
                    height={350}
                  />
                </div>
              </div>
              {feedOrgCategory &&
                feedOrgCategory?.map((item) => (
                  <div
                    className="tab-pane fade "
                    id={"v-pills--" + removeSpcae(item)}
                    role="tabpanel"
                    aria-labelledby={"v-pills--" + removeSpcae(item) + "-tab"}
                  >
                    <div className="row" align="left">
                      <div className="col-8">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <mark>
                  Select time range for status <i className="bi bi-arrow-down"></i>
                </mark>
                        <div className="input-group">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="date"
                            className="form-control border border-secondary "
                            placeholder="Start Date"
                            value={filterDate1.staring_date}
                            onChange={updateFilterDate1}
                            name="staring_date"
                          />
                          <input
                            type="date"
                            className="form-control border border-secondary "
                            placeholder="End Date"
                            value={filterDate1.ending_date}
                            onChange={updateFilterDate1}
                            name="ending_date"
                          />
                          <button
                            className="btn btn-dark"
                            onClick={() => callStatus(item)}
                            type="button"
                          >
                            <i className="bi bi-funnel"></i> Filter
                          </button>
                        </div>
                        <p>
                          {" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Note:
                          Default time period is 1 month
                        </p>
                      </div>
                    </div>
                    <br/>
                    <ResponsiveContainer width={840} height={377}>
                    <LineChart
                      width={840}
                      height={377}
                      data={data_status}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line dataKey="call" fill="#8884d8" />
                    </LineChart>

                    </ResponsiveContainer>
                    
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade p-3 mb-5"
          id="nav-perfor"
          role="tabpanel"
          aria-labelledby="nav-perfor-tab"
        >
          <div className="d-flex align-items-start">
            <br />
            <br />
            <br />
            <div
              className="overflow-auto p-3 bg-transparent"
              style={{ maxWidth: "500px", maxHeight: "500px" }}
            >
              <p>
                <mark>
                  <i className="bi bi-file-text-fill"></i> Select script
                  category <i className="bi bi-arrow-down"></i>
                </mark>
              </p>
              <div
                className="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {feedOrgCategory &&
                  feedOrgCategory?.map((item) => (
                    <button
                      onClick={() => callPerformance(item)}
                      className="nav-link"
                      id={"v-pills---" + removeSpcae(item) + "-tab"}
                      data-bs-toggle="pill"
                      data-bs-target={"#v-pills---" + removeSpcae(item)}
                      type="button"
                      role="tab"
                      aria-controls={"v-pills---" + removeSpcae(item)}
                      aria-selected="true"
                    >
                      {item}
                    </button>
                  ))}
              </div>
            </div>

            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex justify-content-center">
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img
                    src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/9.+Performance+empty.svg"
                    height={350}
                  />
                </div>
              </div>
              {feedOrgCategory &&
                feedOrgCategory?.map((item) => (
                  <div
                    className="tab-pane fade "
                    id={"v-pills---" + removeSpcae(item)}
                    role="tabpanel"
                    aria-labelledby={"v-pills---" + removeSpcae(item) + "-tab"}
                  >
                    <div className="row" align="left">
                      <div className="col-8">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <mark>
                  Select time range for performance <i className="bi bi-arrow-down"></i>
                </mark>
                        <div className="input-group">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="date"
                            className="form-control border-secondary"
                            placeholder="Start Date"
                            value={filterDate2.staring_date}
                            onChange={updateFilterDate2}
                            name="staring_date"
                          />
                          <input
                            type="date"
                            className="form-control border-secondary"
                            placeholder="End Date"
                            value={filterDate2.ending_date}
                            onChange={updateFilterDate2}
                            name="ending_date"
                          />
                          <button
                            className="btn btn-dark"
                            onClick={() => callPerformance(item)}
                            type="button"
                          >
                            <i className="bi bi-funnel"></i> Filter
                          </button>
                        </div>
                        <p>
                          {" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Note:
                          Default time period is 1 month
                        </p>
                      </div>
                    </div>
                    <br/>
                    <ResponsiveContainer width={840} height={377}>
                    <LineChart
                       width={840}
                       height={377}
                      data={data_performance}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line dataKey="call" fill="#8884d8" />
                    </LineChart>
                    </ResponsiveContainer>

                   
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade p-3 mb-5"
          id="nav-kpi"
          role="tabpanel"
          aria-labelledby="nav-kpi-tab"
        >
          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select bg-info text-dark border-secondary"
                    value={timecat.timeCat}
                    onChange={updataTimecat}
                    name="timeCat"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                  >
                    <option value="month">Month</option>
                    <option value="week">Week</option>
                  </select>
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => changeTimeCat()}
                    type="button"
                  >
                    Submit
                  </button>
                </div>
                <br />
              </div>
            </div>

            <br />
            <div className="row">
              
              <div className="col-12">
                <div className="row">
                  
               
                  <div className="col-12">
                    <>
                   
  <nav>
    <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
      <button
        className="nav-link active"
        id="nav-lead-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-lead"
        type="button"
        role="tab"
        aria-controls="nav-lead"
        aria-selected="true"
      >
        <i className="bi bi-person-fill"></i> Leads Performance
      </button>
      <button
        className="nav-link"
        id="nav-tasks-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-tasks"
        type="button"
        role="tab"
        aria-controls="nav-tasks"
        aria-selected="false"
      >
        <i className="bi bi-phone-fill"></i> Calls & Tasks Performance
      </button>
    </div>
  </nav>

  <div className="tab-content" id="nav-tabContent">
    <div
      className="tab-pane fade show active"
      id="nav-lead"
      role="tabpanel"
      aria-labelledby="nav-lead-tab"
    >
      <br/><br/>
  <div className="conatiner">

<div className="row">


      {Object.keys(leadPerformance)?.map((userId) => (
    
           <div className="col-3" key={userId}>
            
          <div className="card border-dark shadow mb-3">
  <div className="card-header bg-dark text-white">
  <i className="bi bi-person-badge-fill"></i> {getUserNameById(team, userId)}
    </div>
    <div className="card-body bg-dark">
          {leadPerformance[userId]?.map((sopData, index) => (
  
    
<div key={index}>
{Object.keys(sopData)?.map((sopName) => (
 <>
  
  
    <p className="text-light"><i className="bi bi-arrow-right"></i> {sopName} - <span className="badge rounded-pill bg-light text-dark"> {sopData[sopName].Total_leads} </span>
    </p>
    <p className="text-secondary">Leads breakdown: 
    <br/>
      <span className="badge bg-primary">Active : {sopData[sopName].Lead_status.Active}</span>&nbsp; 
      <span className="badge bg-success">Won : {sopData[sopName].Lead_status.Won} </span>&nbsp; 
      <span className="badge bg-danger">Lost : {sopData[sopName].Lead_status.Lost} </span>&nbsp; 
      <span className="badge bg-dark border border-light">Closed : {sopData[sopName].Lead_status.Closed}</span> 

      <br/>
  
      <br/>
      {sopData[sopName].Total &&
                      sopData[sopName].Total?.map((item) => (

                        <p className="text-secondary">Lead Value - <b className="text-white">{formatCurrency(item.amount,item.currency)}</b> <br/>
                        <span className="badge bg-primary">Active : {formatCurrency(item.Active,item.currency)} </span>&nbsp; 
                        <span className="badge bg-success">Won : {formatCurrency(item.Won,item.currency)} </span>&nbsp; 
                        <span className="badge bg-danger">Lost : {formatCurrency(item.Lost,item.currency)} </span>&nbsp; 
                        <span className="badge bg-dark border border-light">Closed : {formatCurrency(item.Closed,item.currency)}</span>  </p>

                        ))}
      </p>
      
  </>
))}
 <hr className="bg-white"/>
 </div>


 ))}  

 </div>
</div>
        </div>
      
      ))}
   
</div>


  </div>

                            


    </div>
    <div
      className="tab-pane fade"
      id="nav-tasks"
      role="tabpanel"
      aria-labelledby="nav-tasks-tab"
    ><br/><br/>
    <div className="container">
      <div className="row">
      <div className="col-3 right-shadow">
                <ol className="list-group">
                <mark>
                <i className="bi bi-people-fill"></i> User Performance &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Your team list with their progress. Click on the list and you can find detailed report.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
    </mark>

                  <div
                    className="overflow-auto bg-light"
                    style={{ maxWidth: "500px", maxHeight: "800px" }}
                  >
                    {feedUser &&
                      feedUser?.map((item) => (

                        item.user_id == liveuser? <>
                        <li
                          className="list-group-item cursor d-flex justify-content-between align-items-start bg-white text-dark border-0"
                          onClick={() => changeLiveUser(item)}
                        >
                          <img
                            className="rounded-circle"
                            src={generateavatar(
                              item.username,
                              "White",
                              "#004aad"
                            )}
                            width="35"
                            height="35"
                          ></img>
                          <div className="ms-2 me-auto">
                            <div>{item.username}</div>
                          </div>
                          <span className="badge bg-primary rounded-pill">
                            {item.Total_number_call}
                          </span>
                        </li>{" "}
                        <div className="progress" style={{ height: "3px" }}>
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{
                              width:
                                progress_element(item.Total_number_call) +
                                "%",
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </>: <>
                          <li
                            className="list-group-item cursor d-flex justify-content-between align-items-start bg-light text-dark border-0"
                            onClick={() => changeLiveUser(item)}
                          >
                            <img
                              className="rounded-circle"
                              src={generateavatar(
                                item.username,
                                "White",
                                "#004aad"
                              )}
                              width="35"
                              height="35"
                            ></img>
                            <div className="ms-2 me-auto">
                              <div>{item.username}</div>
                            </div>
                            <span className="badge bg-primary rounded-pill">
                              {item.Total_number_call}
                            </span>
                          </li>{" "}
                          <div className="progress" style={{ height: "3px" }}>
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              style={{
                                width:
                                  progress_element(item.Total_number_call) +
                                  "%",
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </>
                       
                      ))}
                  </div>
                </ol>
              </div>
      <div className="col-6">
                {liveuser != "None" ? (
                  <>
                    <div className="col-9">
                      <div className="container">
                      <div className="input-group input-group-sm">
                        <select
                          value={pitchCatData.pitchCat}
                          onChange={updataScriptData}
                          name="pitchCat"
                          className="form-select bg-dark text-white border border-dark"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                        >
                          <option selected>Select Category</option>
                          {allCallsCat &&
                            allCallsCat?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                        </select>
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => get_user_status_performance()}
                          type="button"
                        >
                          Submit
                        </button>
                      </div>
                      </div>
                     
                    </div>

                    <br />
                    <p>
                      <b>
                        {" "}
                        Call Status <i className="bi bi-arrow-down"></i>
                      </b>
                    </p>
                    <ResponsiveContainer width={500} height={237}>
                    <LineChart
                      width={500}
                      height={237}
                      data={data_status_kpi}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line dataKey="call" fill="#8884d8" />
                    </LineChart>
                    </ResponsiveContainer>
                    
                    <p>
                      <b>
                        Call Performance <i className="bi bi-arrow-down"></i>
                      </b>
                    </p>
                    <ResponsiveContainer width={500} height={237}>
                    <LineChart
                       width={500}
                       height={237}
                      data={data_performance_kpi}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line dataKey="call" fill="#8884d8" />
                    </LineChart>
                    </ResponsiveContainer>
                   

                    <p>
                      <b>
                        Last 7 days Calls <i className="bi bi-arrow-down"></i>
                      </b>
                    </p>

                    <ResponsiveContainer width={500} height={237}>
                      
                    <LineChart
                      width={500}
                      height={237}
                      data={call_data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line dataKey="call" fill="#8884d8" />
                    </LineChart>
                    </ResponsiveContainer>

                  </>
                ) : (
                  <div align="center">
                    <br />
                    <br />
                    <br />
                    <br />
                    <img
                      src={nullkpi}
                      height={300}
                    /> <p><br/>Select a user</p>
                  </div>
                )}
              </div>
              <div className="col-3">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    type="button"
                    className="btn btn-dark btn-sm shadow-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <i className="bi bi-person"></i> v/s{" "}
                    <i className="bi bi-person"></i>
                  </button>
                </div>

                <br />

                <div className="card bg-white shadow border-dark">
                  <div className="card-body" align="center">
                    <h1 className="card-title">{feedOrgCalls}</h1>
                    <p className="card-text text-dark">
                      Total Calls <i className="bi bi-phone-fill"></i>
                    </p>
                  </div>
                  <div
                    className="overflow-auto p-3"
                    style={{ maxWidth: "1000px", maxHeight: "400px" }}
                  >
                      {feedCallsCat &&
                        feedCallsCat?.map((item) => (
                          <>
                            <h6>
                              {item._id} <span className="badge rounded-pill bg-primary">{item.count}</span>
                            </h6>
                            <div className="progress border border-secondary">
                              <div
                                className="progress-bar bg-dark"
                                role="progressbar"
                                style={{
                                  width:
                                    progress_element_script(
                                      item.count,
                                      feedOrgCalls
                                    ) + "%",
                                }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              >
                                {progress_element_script(
                                  item.count,
                                  feedOrgCalls
                                ) + "%"}
                              </div>
                            </div>
                            <br />
                          </>
                        ))}
               
                  </div>
                  <div className="card-footer" align='center'>Call Split</div>
                </div>
                <br />
                <div className="card bg-white shadow border-dark">
                  <div className="card-body" align="center">
                    <h1 className="card-title">{allMood}</h1>
                    <p className="card-text text-dark">
                      Mood Index <i className="bi bi-bicycle"></i>
                    </p>
                  </div>

                  <div className="card-body">
                    {feedMood &&
                      feedMood?.map((item) => (
                        <>
                          <h6>
                            {moodAlter(item._id)}  <span className="badge rounded-pill bg-primary">{item.count}</span>
                          </h6>
                          <div className="progress  border border-secondary">
                            <div
                              className="progress-bar bg-dark "
                              role="progressbar"
                              style={{
                                width:
                                  progress_element_script(item.count, allMood) +
                                  "%",
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                              {progress_element_script(item.count, allMood) +
                                "%"}
                            </div>
                          </div>
                          <br />
                        </>
                      ))}
                  </div>
                   <div className="card-footer" align='center'>Prospect Sentiments</div>
                </div>
                <br />
                <div className="card bg-white shadow border-dark">
                  <div className="card-body" align="center">
                    <h1 className="card-title">{feedOrgTask}</h1>
                    <p className="card-text text-dark">
                      Total Tasks <i className="bi bi-bicycle"></i>
                    </p>
                  </div>
                  <div
                    className="overflow-auto p-3"
                    style={{ maxWidth: "1000px", maxHeight: "400px" }}
                  >
                      {feedTaskCat &&
                        feedTaskCat?.map((item) => (
                          <>
                            <h6>
                            <i className="bi bi-bicycle"></i> {item._id}  <span className="badge rounded-pill bg-primary">{item.count}</span>
                            </h6>
                            <div className="progress  border border-secondary">
                              <div
                                className="progress-bar bg-dark"
                                role="progressbar"
                                style={{
                                  width:
                                    progress_element_script(
                                      item.count,
                                      feedOrgTask
                                    ) + "%",
                                }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              >
                                {progress_element_script(
                                  item.count,
                                  feedOrgTask
                                ) + "%"}
                              </div>
                            </div>
                            <br />
                          </>
                        ))}
                    
                  </div>
                  <div className="card-footer" align='center'>Task Split</div>
                </div>
              </div>
      </div>
      </div>
    </div>
  </div>
</>

                  </div>
              
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Compare Users &nbsp;<OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('You can compare your reps or top performer.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-8">
                    <mark>
                  Select users you want to compare. <i className="bi bi-arrow-down"></i>
                </mark>
                
                      <div className="input-group input-group-sm">
                        <select
                          value={kpicomp.userid1}
                          onChange={updatKPIComp}
                          name="userid1"
                          className="form-select"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                        >
                          <option selected>Select User 1</option>
                          {feedUser &&
                            feedUser?.map((item) => (
                              <option value={item.user_id}>
                                {item.username}
                              </option>
                            ))}
                        </select>
                        <select
                          value={kpicomp.userid2}
                          onChange={updatKPIComp}
                          name="userid2"
                          className="form-select"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                        >
                          <option selected>Select User 2</option>
                          {feedUser &&
                            feedUser?.map((item) => (
                              <option value={item.user_id}>
                                {item.username}
                              </option>
                            ))}
                        </select>
                        <button
                          className="btn text-white bg-dark"
                          onClick={CompGraph}
                          type="button"
                        >
                          <i className="bi bi-arrow-right"></i> Submit
                        </button>
                      </div>
                    </div>
                  </div>

                  <ResponsiveContainer width={700} height={300}>
                  <BarChart
                    width={700}
                    height={300}
                    data={compdata}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Interested" stackId="a" fill="#1870d5" />
                    <Bar dataKey="Not_Interested" stackId="a" fill="#f46d75" />
                  </BarChart>
                  </ResponsiveContainer>

                  <br />
                  <mark>
                  Select category of comparison <i className="bi bi-arrow-down"></i>
                </mark>
                  <div className="row">
                    <div className="col-5">
                      <select
                        value={kpicomp.script_category}
                        onChange={updatKPIComp}
                        name="script_category"
                        className="form-select form-select-sm"
                        id="inputGroupSelect04"
                        aria-label="Example select with button addon"
                      >
                        <option selected>Select Call Category</option>
                        {allCallsCat &&
                          allCallsCat?.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-3">
                      <button
                        type="button"
                        onClick={CompGraph}
                        className="btn btn-dark btn-sm"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade p-3 mb-5"
          id="nav-report"
          role="tabpanel"
          aria-labelledby="nav-report-tab"
        >

<div className="row"><div className="col-4"></div> <div className="col-4"> <div className="input-group mb-3">
          <input type="date" name="date" value={ReportDate.date} onChange={updateReportData} className="form-control bg-info border-dark" placeholder="Recipient's username"  aria-describedby="button-addon2" />
          <button onClick={() => {
  getUserDaily();
  get_UsersReport();
}} className="btn btn-outline-dark" type="button" id="button-addon2">Go!</button>
        </div>
  </div></div>
  
<div className="row">
  
<div className="col-4">

                  <div
                        className="overflow-auto p-3"
                        style={{ maxWidth: "1000px", maxHeight: "500px" }}
                      >
                        <p>
                    All Users &nbsp;<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Your team list with their progress. Click on the list and you can find detailed report.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </p>
                        <ol className="list-group ">
                        <>
                          {dailyteam &&
                            dailyteam?.map((item, index) => (
                              
                              
                                ReportUser == item.id? <li onClick={() => changeReportUser(item.id)} key={index}
                                className="list-group-item cursor d-flex justify-content-between align-items-start bg-primary text-light "
                               
                              >
                                <img
                                  className="rounded-circle"
                                  src={generateavatar(
                                    item.user_name,
                                    "White",
                                    "#004aad"
                                  )}
                                  width="40"
                                  height="40"
                                ></img>
                                <div className="ms-2 me-auto">
                                  <div>{item.user_name}</div>
                                  <span className="badge bg-dark text-light">Assigned: {item.call_performance.Todays_calls}</span>&nbsp;
                                  <span className="badge bg-dark text-light">Called: {item.call_performance.TodayCallDone}</span>&nbsp;
                                  <span className={item.call_performance.CallPending > 100 ? "badge bg-dark text-light" : "badge bg-danger text-light"}>
  Pending: {item.call_performance.CallPending}
</span>

                                </div>
                               
                              </li>
                                : <li onClick={() => changeReportUser(item.id)} key={index}
                                className="list-group-item cursor d-flex justify-content-between align-items-start bg-light text-dark "
                               
                              >
                                <img
                                  className="rounded-circle"
                                  src={generateavatar(
                                    item.user_name,
                                    "White",
                                    "#004aad"
                                  )}
                                  width="35"
                                  height="35"
                                ></img>
                                <div className="ms-2 me-auto">
                                  <div>{item.user_name}</div>
                                  <span className="badge bg-dark text-light">Assigned: {item.call_performance.Todays_calls}</span>&nbsp;
                                  <span className="badge bg-dark text-light">Called: {item.call_performance.TodayCallDone}</span>&nbsp;
                                  <span className={item.call_performance.CallPending > 100 ? "badge bg-dark text-light" : "badge bg-danger text-light"}>
  Pending: {item.call_performance.CallPending}
</span>

                                </div>
                               
                              </li>
                          
                              
                          
                            ))}
                            </>
                        </ol>
                      </div>

                      
</div>
{
  ReportUser !=null?<div className="col-8">


  <div className="row">
  
  <p>
                      User Report &nbsp;<OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip('Select user and date and press go.')}
      >
        <i className="bi bi-info-circle-fill text-dark"></i>
      </OverlayTrigger>
                    </p>
   <div className="container">
  
    <div className="row">
    <div className="col-4">
    <div className="col-sm-12">
                        <div className="card text-white bg-dark mb-3">
                          <div className="card-body">
                            <h3 align="left">
                            <i className="bi bi-pie-chart-fill"></i>
                              <b> {ReportResponse.Today_total_call}</b>
                            </h3>
                            <p align="left" className="card-text">
                              
                            Total call
                            </p>
                           
                            {Today_Call_Briefs &&
                      Today_Call_Briefs?.map((item) => (
                        <>
                        <i className="bi bi-arrow-bar-right"></i> {item[0]} : {item[1]}<br/>
                        </>
                      ))}   
                          </div>
                        </div>
                      </div>
  
                      <div className="card text-dark bg-light border border-dark mb-3" style={{maxWidth: '18rem'}}>
          <div className="card-header">Call Breakdown &nbsp;<OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip('Total calls script category breakdown.')}
      >
        <i className="bi bi-info-circle-fill text-dark"></i>
      </OverlayTrigger></div>
          <div className="card-body">
                            <div>
            {Object.keys(Scripts_Call_Briefs).map(category => (
                <div key={category}>
                    <p><i className="bi bi-phone"></i> <b>{category}</b></p>
                    <ul className="list-group">
                        {Object.entries(Scripts_Call_Briefs[category]).map(([status, count]) => (
                            <li className="list-group-item d-flex justify-content-between align-items-start">
  <div className="ms-2 me-auto">{`${status}:` }</div>
  <span className="badge bg-dark rounded-pill">{`${count}`}</span>
</li>

                        ))}
                    </ul>
                    <br/>
                </div>
            ))}
        </div>
            {/* <p className="card-text">{Scripts_Call_Briefs}</p> */}
          </div>
        </div>                  
                      
      </div>
      <div className="col-8">
      <div className="row">
      <div className="col-sm-4">
                        <div className="card text-dark bg-light border border-dark mb-3">
                          <div className="card-body">
                            <h3 align="left">
                            <i className="bi bi-fingerprint"></i>
                              <b> {ReportResponse.Unique_calls}</b>
                            </h3>
                            <p align="left" className="card-text">
                              
                            Unique call
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="card text-dark bg-light border border-dark mb-3">
                          <div className="card-body">
                            <h3 align="left">
                            <i className="bi bi-arrow-repeat"></i>
                              <b> {ReportResponse.Repeted_calls}</b>
                            </h3>
                            <p align="left" className="card-text">
                              
                            Repeted call
                            </p>
                          </div>
                        </div>
                      </div>
                      </div>
                     Total call Breakdown &nbsp;<OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip('Total call breakdown. With Unique and repeted calls')}
      >
        <i className="bi bi-info-circle-fill text-dark"></i>
      </OverlayTrigger>
                      <Chart
        chartType="PieChart"
        data={chartdata}
        width={"100%"}
        height={"400px"}
      />
  
         
        </div>  
   
                      </div>
                      
  
  
      
  
                            
     
  
  
  
   </div>
  </div>
  </div>:<div className="col-8">
    <div align='center'><br/><br/><br/><br/><img
                  src={nullreport}  height={250}
                /><br/><p>Select date to see the report</p></div></div>
}

</div>

        

        </div>
        <div
          className="tab-pane fade p-3 mb-5"
          id="nav-target"
          role="tabpanel"
          aria-labelledby="nav-target-tab"
        >


<div className="col-4">

<div className="mb-3">
  <label for="exampleFormControlInput1" className="form-label">Select Month & Year: </label>

<div className="input-group input-group-sm">
  <select
    value={targetfilter.month}
    onChange={changeTarget}
    name="month"
    className="form-select border border-dark"
    id="inputGroupSelect04"
    aria-label="Example select with button addon"
  >
    <option>Choose month</option>
    <option value="January">January</option>
  <option value="February">February</option>
  <option value="March">March</option>
  <option value="April" selected>April</option>
  <option value="May">May</option>
  <option value="June">June</option>
  <option value="July">July</option>
  <option value="August">August</option>
  <option value="September">September</option>
  <option value="October">October</option>
  <option value="November">November</option>
  <option value="December">December</option>
  </select>
  <select
    value={targetfilter.year}
    onChange={changeTarget}
    name="year"
    className="form-select border border-dark"
    id="inputGroupSelect04"
    aria-label="Example select with button addon"
  >

    <option value={2018}>2018</option>
    <option value={2019}>2019</option>
    <option value={2020}>2020</option>
    <option value={2020}>2020</option>
    <option value={2021}>2021</option>
    <option value={2022}>2022</option>
    <option value={2023}>2023</option>
    <option value={2024} selected="">2024</option>
  </select>
  <button className="btn btn-dark" onClick={get_target} type="button">
    Submit
  </button>
</div>
</div>
</div>

<div className='row'>

<div className='col-8'>
<div className="d-grid gap-2 d-md-flex justify-content-md-end">
  <button className="btn btn-dark btn-sm" onClick={add_target} type="button">
  <i className="bi bi-cloud-arrow-up"></i> Save
  </button>
</div>
</div>

</div>

<div className="col-8">
<br/>
<table className="table">
  <thead>
    <tr>
      <th scope="col"><i className="bi bi-award-fill"></i> Rank</th>
      <th scope="col"><i className="bi bi-person-circle"></i> User Name</th>
      <th scope="col"><i className="bi bi-check-circle-fill"></i> Scored</th>
      <th scope="col"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-crosshair2" viewBox="0 0 16 16">
  <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0"/>
</svg> Assigned</th>
      <th scope="col"><i className="bi bi-geo-fill"></i> Target Status</th>
    </tr>
  </thead>
  <tbody>
  {usertarget &&
                    usertarget?.map((item,index) => (
                      <tr>
                      <th scope="row">{item.assigned!=''?<span className="badge rounded-pill bg-dark">{index+1}</span>:'--'}</th>
                      <td>{item.name}</td>
                      <td>  <input
                className="form-control form-control-sm"      
                type="text"
                value={item.completed}
                onChange={(e) => handleEdit(index, 'completed', e.target.value)}
              /></td>
                      <td><b>
                      <input
                className="form-control form-control-sm"      
                type="text"
                value={item.assigned}
                onChange={(e) => handleEdit(index, 'assigned', e.target.value)}
              />
           
            
           </b></td>
                      
                      <td><div className="progress">
                  <div
                    className="progress-bar bg-dark"
                    role="progressbar"
                    style={{ width: `${item.percentage}%` }}
                    aria-valuenow={item.percentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {item.percentage}%
                  </div>
                </div>
                </td>
                    </tr>
                    ))}

   
  </tbody>
</table>

</div>

        </div>
        <div
          className="tab-pane fade p-3 mb-5"
          id="nav-play"
          role="tabpanel"
          aria-labelledby="nav-play-tab"
        >
          <Playground />
        </div>
      </div>
    </div>
  );
}

export default Report;
