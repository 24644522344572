/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
import React from "react";
import axios from "axios";
import { getSharedData } from "../redux/slices/dataSlice";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router'
import logo from "../media/Sales.gif";
import LandingForm from "./LandingForm";
import { ToastContainer, toast } from "react-toastify";
import { tocallvalue, progressElement, alterElement, alterCallBack, changeElement, changeFace, generateavatar} from '../helper';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Ticketlink from "../components/ticketlink";
import {
  getselect_options
} from "../redux/slices/leadSlice";

const dominname = window.location.origin;


function removeEverythingBeforeComma(inputString) {
  const parts = inputString.split(',');
  if (parts.length > 1) {
      return parts[1].trim();
  }
  return inputString.trim();
}

function removeTimeFromString(inputString) {
  // Replace the "00:00:00" with an empty string
  let stringWithoutTime = inputString.replace(' 00:00:00', '');

  return stringWithoutTime;
}

export default function sharedCallView() {
  const dispatch = useDispatch();
  const [LeadData, setLeadData] = useState({
    "Data": [
     
    ],
    "Total": [
      {
        "amount": 0,
        "currency": "INR"
      }
    ],
    "Lead_status" : {'Active':'','Won':'','Lost':'','Closed':''},
  });
  var url_string = window.location.href;
  var url = new URL(url_string);
  var q = url.searchParams.get("q");
  var og = url.searchParams.get("og");
  var org = url.searchParams.get("og");
  const [trackId, setTrackId] = useState();
  const [History, setHistory1] = useState();
  const SourceAuth = localStorage.getItem("SourceAuth");
  const [access, setPassword] = useState({
    organisation_id: og,
    password: "",
  });
  const [leadform, setleadform] = useState({
    organisation_id: og,
    Contact_Source: q,
    customer_name: "",
    contact_number: "",
    email_address: "",
    description: ""
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [orgData, setOrgData] = useState({
    organisation_type: "",
    organisation_category: "",
    total_employees: "",
    gst: "",
    state: "",
    pin_code: "",
    billing_address: "",
  });
  const [feedOrg, setOrgFeed] = useState("");
  const [profilepic, setProfilePic] = useState("");
  
  const [show, setShow] = useState(false);
  const [tid, setTid] = useState('');

  const [selectoptions, setSelectoptions] = useState({});
 
  const [ftid, setFTid] = useState('');
  
  console.log(ftid);
  const handleClose = () => setShow(false);
  function showModel(item) {

    setTid(item.TicketId);
    setFTid(item._id);
    setShow(true);

  }



  const navigate = useNavigate()

  const getSharedDataList = () => {
    dispatch(getSharedData({ source: q, org: og, SourceAuth:SourceAuth })).then((res) => {
      setLeadData(res.payload.data);
    });
  };

  function getOptionData() {
    dispatch(getselect_options({ org })).then((res) => {
      if (res.payload.status == 200) {
        setSelectoptions(res.payload.data);
      }
    });
    
  }

  function formatCurrency(number,type) {
    // Use the toLocaleString method to add commas and format as currency
    let formatted = number.toLocaleString('en-US', { style: 'currency', currency: type });
    formatted = formatted.replace(/\.00$/, '');
    return formatted;
  }

  function calculatesopPercentage(arrayName, targetStage) {


    // Your provided object
    const stagesObject = selectoptions;
  
    // Get the array based on the provided arrayName
    const targetArray = stagesObject[arrayName];
  
    if (targetArray) {
      // Find the index of the targetStage in the array
      const stageIndex = targetArray.indexOf(targetStage);
  
      // Calculate the percentage
      const percentage = ((stageIndex + 1) / targetArray.length) * 100;
  
      console.log(percentage,'heelo');
      return percentage.toFixed(0);
    }
  }


  useEffect(() => {
    getSharedDataList();
    getOptionData();
  }, []);

  function openNav(item, itemId) {

  
    document.getElementById("mySidenav").style.width = "500px";

    setHistory1();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("trackbtn").click();
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  function updatePassword(e) {
    e.preventDefault();
    setPassword({ ...access, [e.target.name]: e.target.value });
  }

  
  

  function searchContact(item) {
    let phoneno = item;
    const headers = {
      "Content-Type": "text/plain",
      "Authorization": `Bearer ${SourceAuth}`,
    };
    axios
      .get(
        "/Source_find_contact?Contact_Source=".concat(
          q,
          "&organisation_id=",
          og,"&mobileNumber=",
          phoneno
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setHistory1(data.data);

        }
        
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }

  function AuthenticationSource() {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "/Source_Varification",{
          "organisation_id": og,
          "Contact_Source": q,
          "Password": access.password 
        },
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
         
          localStorage.setItem("SourceAuth", data.data.Auth);
          navigate(0);


        }
        
      })
      .catch((err) => {
        toast.error(err.response.data.detail, {
          position: "bottom-right",
          autoClose: 1000,
        });
      });
  }




  function terminate(){
    localStorage.clear("SourceAuth");
    navigate(0)
  }

  function fetchOrgData() {
    
    const headers = {
      "Content-Type": "text/plain",
      
    };
    axios
      .get("/Source_Get_organisation?organisation_id=".concat(og), { headers })
      .then((data) => {
        if (data.status == "200") {
          setOrgFeed(data.data[0]);
          setProfilePic(data.data[0].ImageLink);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchOrgData();
    
  }, []);




  return (
    
    <>  <nav className="navbar">
    <div className="container-fluid">
    <a className="navbar-brand" href="#">
        {profilepic!=''? <img src={profilepic} alt="" height={40} className="d-inline-block align-text-top" />: <></>}
       
        &nbsp; {feedOrg.organisation_name}
      </a>
      <div className=" justify-content-start">
        <button className="btn btn-outline-dark me-2 btn-sm" type="button"  data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-plus-circle-fill"></i> Add Lead</button>
       {SourceAuth != null
       ?<button onClick={terminate} className="btn btn-outline-danger me-2 btn-sm" type="button" ><i className="bi bi-door-closed-fill"></i> Logout</button>:null} 
      </div>
    </div>
  </nav>
  
    
     
     {
      SourceAuth != null
      ?<><div className="container">
      <br/>
      <div className="row">
      <p><i className="bi bi-people-fill"></i> Leads analysis:</p>
      </div>
        <div className="row">
        <div className="col-12 col-sm-3">
        
 
        <div className="card mb-3 text-white card_design3 shadow-lg rounded-3">
      <div className="card-body">
      <div className="row">
      <div className="col-7"> <h3 align="left">
                        <i className="bi bi-people"></i>
                          <b> {LeadData.Total_leads}</b>
                        </h3>
                        <p align="left" className="card-text text-secondary">
                        Total lead
                        </p></div>
          <div className="col-5">
            <span class="badge bg-primary bg-gradient">Active : {LeadData.Lead_status['Active']} </span>
            <span className="badge bg-success bg-gradient">Won : {LeadData.Lead_status['Won']} </span>
            <span className="badge bg-danger bg-gradient">Lost : {LeadData.Lead_status['Lost']}</span>
            <span className="badge bg-dark bg-gradient">Closed : {LeadData.Lead_status['Closed']}</span>
          </div>
          </div>
                       
                      </div>
        </div>
          
        
        </div>
        {LeadData.Total == ''?<div className="col-12 col-sm-3">
        <div className="card mb-3 text-white card_design3 shadow-lg rounded-3">
      <div className="card-body">
     

           <> <div className="row"> <div className="col-7"><h3 align="left">
           <i className="bi bi-cash"></i>
             <b> 0</b>
           </h3>
           <p align="left" className="card-text text-secondary">
           Currency
           </p></div><div className="col-5">
      <span class="badge bg-primary bg-gradient">Active : 0</span>
            <span className="badge bg-success bg-gradient">Won : 0</span>
            <span className="badge bg-danger bg-gradient">Lost : 0</span>
            <span className="badge bg-dark  bg-gradient">Closed : 0</span>
      </div> </div></>



                       
                      </div>
        </div>
        </div>:<div className="col-12 col-sm-3">
        <div className="card mb-3 text-white card_design3 shadow-lg rounded-3">
      <div className="card-body">
     
       {LeadData.Total?.map((item)=>(
           <> <div className="row"> <div className="col-7"><h3 align="left">
           <i className="bi bi-cash"></i>
             <b> {formatCurrency(item.amount,item.currency)}</b>
           </h3>
           <p align="left" className="card-text text-secondary">
           Total value
           </p></div><div className="col-5">
      <span class="badge bg-primary bg-gradient">Active : {item.Active}</span>
            <span className="badge bg-success bg-gradient">Won : {item.Won}</span>
            <span className="badge bg-danger bg-gradient">Lost : {item.Lost}</span>
            <span className="badge bg-dark  bg-gradient">Closed : {item.Closed}</span>
      </div> </div></>

        ))}
      
     
       
       
                       
                      </div>
        </div>
        </div>}
        
        </div>
      <br/>
      {/* <blockquote className="blockquote">
      <p align='center'><b>All your prospects/clients will be displayed here.</b></p>
      </blockquote> */}
    </div><div className="container"> 

    <div className="overflow-auto">
<table className="table table-striped caption-top">
<caption>All your prospects/clients will be displayed here</caption>
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col"><i className="bi bi-card-heading"></i> Lead Title</th>
      <th scope="col"><i className="bi bi-person-fill"></i> Contact person</th>
      <th scope="col"><i className="bi bi-cash"></i> Lead value</th>
      <th scope="col"><i className="bi bi-bezier"></i> Current process</th>
      <th scope="col"><i className="bi bi-geo-fill"></i> Current Stage</th>
      <th scope="col"><i className="bi bi-signpost"></i> Progress</th>
      <th scope="col"><i className="bi bi-person-circle"></i> Lead Manager</th>
      <th scope="col"><i className="bi bi-geo-fill"></i> Status </th>
      <th scope="col"><i className="bi bi-clock"></i> Deadline</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>

  {LeadData.Data &&
                    LeadData.Data?.map((item) => (

                      <tr>
                      <th scope="row"><span className={item.priority=='low'?'text-primary'
    :item.priority=='medium'?'text-warning'
  :item.priority=='high'?'text-danger':null
  
  }><i className="bi bi-circle-fill"></i>
  </span> <span className="badge bg-dark"># {item.TicketId}</span>

  
  </th>
                      <td>{item.title==""?<>{item.contactPerson}</>:<>{item.title}</>
                        }</td>
                      <td>{item.contactPerson}</td>
                      <td><b>{item.value}</b> {item.currency}</td>
                      <td><b>{item.pipeline}</b></td>
                      <td>
                     
                     <span className="badge bg-light text-dark border border-dark">{item.pipelineCurrentStage}</span>
                      </td>
                      <td><div className="progress border border-dark progress-bar-striped">
  <div
    className="progress-bar progress-bar progress-bar-striped progress-bar-animated bg-dark"
    role="progressbar"
    style={{ width: `${calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)}%` }}
    aria-valuenow={calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)}
    aria-valuemin={0}
    aria-valuemax={100}
  >
    {calculatesopPercentage(item.pipeline, item.pipelineCurrentStage)} %
  </div>
</div></td>
                      <td>{removeEverythingBeforeComma(item.owner)}</td>
                      <td>{item.lead_status==''?<><span className="badge bg-primary">{item.lead_status}</span></>
                      :item.lead_status=='Won'?<><span className="badge bg-success">{item.lead_status}</span></>
                    :item.lead_status=='Lost'?<><span className="badge bg-danger">{item.lead_status}</span></>
                  :item.lead_status=='Closed'?<><span className="badge bg-dark">{item.lead_status}</span></>:null}</td>
                      
                      <td>{removeTimeFromString(item.expectedClosingDate)}</td>
                      <td><i className="bi bi-share-fill cursor" onClick={()=>showModel(item)}></i></td>
                    </tr>

                    ))}

   


  </tbody>
</table>
</div>


      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Share Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Ticketlink tid={tid} ftid={ftid} org={og}  />
        </Modal.Body>
      </Modal></>
      :<>
      <div className="container">
        <div align='center' >
          <br/><br/>
      <div className="card col-sm-4 col-12">
        <div className="card-header">
          Login
        </div>
        <div className="card-body">
          <p align='left'>To access your leads enter access code <i className="bi bi-arrow-down"></i></p>
        <div className="input-group mb-3">
        <input type="password"  name="password" value={access.password} onChange={updatePassword} className="form-control border-dark" placeholder="Password" aria-label="Password" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={AuthenticationSource} type="button" id="button-addon2"><i class="bi bi-unlock-fill"></i> Submit</button>
      </div>
      <p align='left'><b>Note:</b> To change password contact admin.</p>
        </div>
      </div>
      </div>
      </div>
      </>

     } 
      
    
    <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
            
                       item.script_category != null ? (<div className="event" >
                          <div
                            className="knob"
                          />
                          <div className="card bg-light">
                          <div className="card-body">
                             <div className="row">
                                <div className="col-6">
                                  <img
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                          <br />
                          <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      &nbsp;Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      &nbsp;Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3"></div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>&nbsp;{item.call_brief}</b>. It went till 
                                    <b>&nbsp;{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item._id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement1(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item._id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              
                          </div>
                          </div>
                          </div>):(<div className="event" id={item._id}>
                          <div
                            className="knob"
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>{" "}
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Incomplete" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Incomplete
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>{" "}
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>&nbsp;{item.added_by_username}</b> assigned{" "}
                                    <b>&nbsp;{item.taskHeading}</b> task to{" "}
                                    <b>&nbsp;{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item._id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description{" "}
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement1(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item._id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)
                   
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>

        {/* Modal */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="staticBackdropLabel">Add Lead</h5>
                <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
               
              <LandingForm/>
           
              </div>
             
            <br/>
              <div className="modal-footer border-0 ">
              <div className="container"><i className="bi bi-link-45deg"></i> Share link <i className="bi bi-info-circle-fill"></i> 
              <br/>
              <mark>
              {dominname}/leadcapture?q={q}&og={og}
          </mark></div>
              </div>
              
            </div>
          </div>
        </div>
        
        <div className="container" align='center'>
          <br/><br/>
      <p>
          Powered by <a href='http://salessunday.com/' target="_blank"><b>SalesSunday</b></a> | <i class="bi bi-lock-fill"></i> Secure connection
        </p>
      </div>
      
      <ToastContainer />
    </>
  );
}
