import React, { createContext, useState, useContext } from 'react';

const initialState = {
  callData: {
    added_by_user: '',
    added_by_username: '',
    script_category: '',
    customer_name: "",
    contact_number: "",
    email_address: "",
    Contact_Source: "",
    call_brief: "",
    call_status: "",
    description: "",
    call_again_date: "",
    next_call_user: "",
    next_call_script_category: "",
    organisation_id: '',
    contact_search_at: "",
    call_started_at: "",
    call_ended_at: "",
    clint_mood: "",
  },
};




const useMyState = () => useState(initialState);

const MyContext = createContext(null);

export const useSharedState = () => {
  const value = useContext(MyContext);
  if (value === null) throw new Error('Please add SharedStateProvider');
  return value;
};

export const SharedStateProvider = ({ children }) => (
  <MyContext.Provider value={useMyState()}>{children}</MyContext.Provider>
);
