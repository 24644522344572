import React from 'react'

function Soppreview({sop}) {
    console.log(sop,'sop');
  return (
        <div>
       
    <br/>
    <div className="cell-wrapper border-0 bg-light" >
    {sop &&
                    sop?.map((item, index) => (<>

<div className="cell_preview" align='center'>
  <br/>
<h6 ><b>{item.process_catagory} </b>{" "}
{/* <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(item.process_desc)}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger> */}
</h6> 
  {" "}

                         
                         <hr className="light"/>
                         <br/>
                         <div className={'card border-dark mb-3 shadow tiledesign'}  align='left' style={{ maxWidth: "14rem" }}>
                         <div className="card-body text-dark bg-white rounded">
                         <div className="row">
<div className="col-10">

<div className="dropdown">
<span className="badge bg-dark">#Id</span> &nbsp;
 

</div>


<h6 className="card-title"><b>Lead title </b></h6>
</div>
<div className="col-2"><i className="bi bi-arrows-angle-expand arrowexpand" 

></i></div>

<div className='col-12'>
<p className="card-text text-secondary">
  <i className="bi bi-building"></i> Contact person - Org
  <br/><span className='text-dark'><i className="bi bi-person-circle"></i> Assigned person</span>
  </p>
  
</div>
</div>
                         </div>
                         <div className="card-footer text-muted">
    <i className="bi bi-clock"></i> Deadline date
  </div>
                         </div>
                         <div>
<span className='text-secondary'><i className="bi bi-arrow-up"></i> Your leads will look like this</span>
</div>

                        </div>
  
                        </>
                        ))}
    </div></div>
  )
}

export default Soppreview