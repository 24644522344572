import React, { useEffect } from 'react';

function Mapdiv() {
  useEffect(() => {
    const loadGoogleCharts = () => {
      const script = document.createElement('script');
      script.src = 'https://www.gstatic.com/charts/loader.js';
      script.onload = () => {
        if (window.google && window.google.charts) {
          window.google.charts.load('current', {
            packages: ['map'],
            mapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Use environment variable for the API key
          });
          window.google.charts.setOnLoadCallback(drawChart);
        } else {
          console.error('Google Charts library failed to load.');
        }
      };
      script.onerror = () => {
        console.error('Failed to load Google Charts script.');
      };
      document.body.appendChild(script);
    };

    const drawChart = () => {
      if (window.google && window.google.visualization) {
        const data = window.google.visualization.arrayToDataTable([
          ['Lat', 'Long', 'Name'],
          [17.31002, 78.14209, 'Abhi Kirana Store'],
          [17.310048075361873, 78.1416688057126, 'SREE AKSHAYA ELECTRONICS AND FURNITURE'],
          [17.311613017083516, 78.14086803494843, 'SRI Sai Fertilizer Seeds and Drip Irrigation'],
          [17.311753227780414, 78.14031951886331, 'Vikas tailor'],
          [17.310501379276985, 78.14275638607769, 'Shiva Kirana general store'],
          [17.31340917328564, 78.13932009810561, 'WHAM CHEVELLA SANGEETHA'],
          [17.31384806948617, 78.13874950323624, 'Shiva online service money transfer'],
          [17.314057872706435, 78.13872832260367, 'RAVI MONEY TRANSFER']
        ]);

        const map = new window.google.visualization.Map(document.getElementById('map_div'));
        map.draw(data, {
          showTooltip: true,
          showInfoWindow: true,
        });
      } else {
        console.error('Google Charts library is not loaded properly.');
      }
    };

    loadGoogleCharts();
  }, []);

  return (
    <div id="map_div" style={{ width: '100%', height: '100%' }}></div> // Adjust height as needed
  );
}

export default Mapdiv;
