import React, { Component } from "react";

const user_name = localStorage.getItem("user_name");

function Sunday() {


  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-7">
            <figure>
  <blockquote className="blockquote">
    <p><i className="bi bi-radioactive"></i> Improvement Suggestions <span className="badge bg-dark">Coming Soon!</span></p>
  </blockquote>
</figure>
<p>Access AI suggestions to improve your performance.</p>
<br/>

<div className="row">
<div
                className="overflow-auto"
                style={{ maxWidth: "1800px", maxHeight: "600px" }}
              >
<div className="card shadow p-1 mb-2 col-11 border-dark">
  <div className="card-body">
  <span className="badge bg-warning text-dark border border-dark">Objection</span>
  <br/>
  <div className="blur">
  The objection in this call transcription is that the customer did not receive the meeting link on their WhatsApp or email. Additionally, the customer is facing difficulty joining the meeting using their email and the Google Meet platform.
  </div>
  <br/>
  <br/>
  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
  <button className="btn btn-dark me-md-2 " type="button">
  <i className="bi bi-check-lg"></i> Useful
  </button>
  <button className="btn btn-light border border-dark " type="button">
  <i className="bi bi-x-lg"></i> Not Useful
  </button>
</div>

  </div>
</div>

<div className="card shadow-sm p-1 mb-2 col-11 border-dark">
  <div className="card-body">
  <span className="badge bg-info text-dark border border-dark">Suggestion</span>
  <br/>
  <div className="blur">
  To improve customer satisfaction in this situation, the sales representative, Aman, should take a few steps to resolve the customer's issues. Firstly, Aman should confirm the correct contact details and preferred method of communication for the customer. If the customer prefers email, Aman should ensure that the meeting link is sent promptly and that it is working correctly. If the customer is facing issues with the Google Meet platform, Aman should offer an alternative option to join the meeting. Additionally, Aman should follow up with the customer after sending the meeting link and ensure that they were able to join the meeting successfully. This will demonstrate care and concern for the customer and help to build a positive relationship.
  </div><br/>
  <br/>
  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
  <button className="btn btn-dark me-md-2 " type="button">
  <i className="bi bi-check-lg"></i> Useful
  </button>
  <button className="btn btn-light border border-dark " type="button">
  <i className="bi bi-x-lg"></i> Not Useful
  </button>
</div>
  </div>
</div><div className="card shadow-sm p-1 mb-2 col-11 border-dark">
  <div className="card-body">
  <span className="badge bg-info text-dark border border-dark">Suggestion</span>
  <br/>
  <div className="blur">
  To improve customer satisfaction in this situation, the sales representative, Aman, should take a few steps to resolve the customer's issues. Firstly, Aman should confirm the correct contact details and preferred method of communication for the customer. If the customer prefers email, Aman should ensure that the meeting link is sent promptly and that it is working correctly. If the customer is facing issues with the Google Meet platform, Aman should offer an alternative option to join the meeting. Additionally, Aman should follow up with the customer after sending the meeting link and ensure that they were able to join the meeting successfully. This will demonstrate care and concern for the customer and help to build a positive relationship.
  </div><br/>
  <br/>
  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
  <button className="btn btn-dark me-md-2 " type="button">
  <i className="bi bi-check-lg"></i> Useful
  </button>
  <button className="btn btn-light border border-dark " type="button">
  <i className="bi bi-x-lg"></i> Not Useful
  </button>
</div>
  </div>
</div>
  
</div></div>




          </div>
          <div className="col-5">
          Useful Suggestion: 
        
          <div className="row">
          <div
                className="overflow-auto"
                style={{ maxWidth: "1800px", maxHeight: "300px" }}
              >

<br/>
<div className="card shadow p-1 mb-2 col-11 border-dark">
  <div className="card-body">
  <span className="badge bg-warning text-dark border border-dark">Objection</span>
  <br/>
  <div className="blur">
  The objection in this call transcription is that the customer did not receive the meeting link on their WhatsApp or email. Additionally, the customer is facing difficulty joining the meeting using their email and the Google Meet platform.
  </div>

  </div>
</div>

<div className="card shadow-sm p-1 mb-2 col-11 border-dark">
  <div className="card-body">
  <span className="badge bg-info text-dark border border-dark">Suggestion</span>
  <br/>
  <div className="blur">
  To improve customer satisfaction in this situation, the sales representative, Aman, should take a few steps to resolve the customer's issues. Firstly, Aman should confirm the correct contact details and preferred method of communication for the customer. If the customer prefers email, Aman should ensure that the meeting link is sent promptly and that it is working correctly. If the customer is facing issues with the Google Meet platform, Aman should offer an alternative option to join the meeting. Additionally, Aman should follow up with the customer after sending the meeting link and ensure that they were able to join the meeting successfully. This will demonstrate care and concern for the customer and help to build a positive relationship.
 
  </div>

  </div>
</div>
  
</div>

                </div>
                <br/>
                Improvment letter:
                <div className="row">
          <div
                className="overflow-auto"
                style={{ maxWidth: "1800px", maxHeight: "300px" }}
              >
                <br/>


<div className="card shadow p-1 mb-2 col-11 border-dark">
  <div className="card-body blur">
  The objection in this call transcription is that the customer did not receive the meeting link on their WhatsApp or email. Additionally, the customer is facing difficulty joining the meeting using their email and the Google Meet platform.


  </div>
</div>

</div>

                </div>
           
            
          
    
          </div>
        </div>
      </div>
    </>
  );
}

export default Sunday;
