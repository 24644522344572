import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import logo from "../media/salessunday.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import avatar from "../media/147144.png";
import Script from "../media/script.mp4";
import letscall from "../media/undraw_night_calls_-5-jh7.svg";
import {
  getScripts,
  getContactNumber,
  getUserData,
  getCallGoals,
  getPar1Latest,
  getPar2Latest,
  getPar3Latest,
  getPar4Latest,
  getPar5Latest,
  getPar6Latest,
  addsuggestions,
  addquestions,
  addNewTask,
  getseachquestions,
  upvote,
  downvote,
  updateCallDetails,
  addNewCall,
  deleteContactToCall,
  findContactDetails,
  getscriptCallEndingInstruction,
  getTasks,
  getProgressBarStatus,
} from "../redux/slices/homeSlice";
import { getSourceListData, addNewContact } from "../redux/slices/dataSlice";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useWsConnection } from "./socket";
import LeadDetails from "../components/LeadDetails";
import EditableTextField from "./editableTextField";
import { useNavigate } from "react-router-dom";
import emptyscript from "../media/undraw_biking_kc-4-f.svg";
import { tocallvalue, progressElement, alterElement, alterCallBack, changeElement, changeFace, generateavatar , alterPercent, alterProgress} from '../helper';
const organisation_name = localStorage.getItem("organisation_name");
const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const user_id = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");
const admin = localStorage.getItem("admin");



function Call(props) {





  const dispatch = useDispatch();
  let location = useNavigate();
  const [status, updatestatus] = useState("askno");
  const wsConnection = useWsConnection();
  useEffect(() => {
    wsConnection.connectSocket(user_name);
  }, []);


  function rec() {
    document.getElementById("rec").style.display = "block";
  }
  function rec_back() {
    document.getElementById("rec").style.display = "none";
  }

  /////////////////// Timer///////////////////////////////////////////////////////////////

  // const [startDate, setStartDate] = useState(new Date());
  // const [diff, setDiff] = useState("00:00:00");
  const [timer, setTimer] = useState();
  const [details, setdetails] = useState(true);


  ////////////////////////countrycode//////////////////////////////////////////////////////////
  const [countrycode, setcountrycode] = useState({
    "countries": [
      {
        "code": "91",
        "name": "India"
      },
      {
        "code": "7 840",
        "name": "Abkhazia"
      },
      {
        "code": "93",
        "name": "Afghanistan"
      },
      {
        "code": "355",
        "name": "Albania"
      },
      {
        "code": "213",
        "name": "Algeria"
      },
      {
        "code": "1 684",
        "name": "American Samoa"
      },
      {
        "code": "376",
        "name": "Andorra"
      },
      {
        "code": "244",
        "name": "Angola"
      },
      {
        "code": "1 264",
        "name": "Anguilla"
      },
      {
        "code": "1 268",
        "name": "Antigua and Barbuda"
      },
      {
        "code": "54",
        "name": "Argentina"
      },
      {
        "code": "374",
        "name": "Armenia"
      },
      {
        "code": "297",
        "name": "Aruba"
      },
      {
        "code": "247",
        "name": "Ascension"
      },
      {
        "code": "61",
        "name": "Australia"
      },
      {
        "code": "672",
        "name": "Australian External Territories"
      },
      {
        "code": "43",
        "name": "Austria"
      },
      {
        "code": "994",
        "name": "Azerbaijan"
      },
      {
        "code": "1 242",
        "name": "Bahamas"
      },
      {
        "code": "973",
        "name": "Bahrain"
      },
      {
        "code": "880",
        "name": "Bangladesh"
      },
      {
        "code": "1 246",
        "name": "Barbados"
      },
      {
        "code": "1 268",
        "name": "Barbuda"
      },
      {
        "code": "375",
        "name": "Belarus"
      },
      {
        "code": "32",
        "name": "Belgium"
      },
      {
        "code": "501",
        "name": "Belize"
      },
      {
        "code": "229",
        "name": "Benin"
      },
      {
        "code": "1 441",
        "name": "Bermuda"
      },
      {
        "code": "975",
        "name": "Bhutan"
      },
      {
        "code": "591",
        "name": "Bolivia"
      },
      {
        "code": "387",
        "name": "Bosnia and Herzegovina"
      },
      {
        "code": "267",
        "name": "Botswana"
      },
      {
        "code": "55",
        "name": "Brazil"
      },
      {
        "code": "246",
        "name": "British Indian Ocean Territory"
      },
      {
        "code": "1 284",
        "name": "British Virgin Islands"
      },
      {
        "code": "673",
        "name": "Brunei"
      },
      {
        "code": "359",
        "name": "Bulgaria"
      },
      {
        "code": "226",
        "name": "Burkina Faso"
      },
      {
        "code": "257",
        "name": "Burundi"
      },
      {
        "code": "855",
        "name": "Cambodia"
      },
      {
        "code": "237",
        "name": "Cameroon"
      },
      {
        "code": "1",
        "name": "Canada"
      },
      {
        "code": "238",
        "name": "Cape Verde"
      },
      {
        "code": " 345",
        "name": "Cayman Islands"
      },
      {
        "code": "236",
        "name": "Central African Republic"
      },
      {
        "code": "235",
        "name": "Chad"
      },
      {
        "code": "56",
        "name": "Chile"
      },
      {
        "code": "86",
        "name": "China"
      },
      {
        "code": "61",
        "name": "Christmas Island"
      },
      {
        "code": "61",
        "name": "Cocos-Keeling Islands"
      },
      {
        "code": "57",
        "name": "Colombia"
      },
      {
        "code": "269",
        "name": "Comoros"
      },
      {
        "code": "242",
        "name": "Congo"
      },
      {
        "code": "243",
        "name": "Congo, Dem. Rep. of (Zaire)"
      },
      {
        "code": "682",
        "name": "Cook Islands"
      },
      {
        "code": "506",
        "name": "Costa Rica"
      },
      {
        "code": "385",
        "name": "Croatia"
      },
      {
        "code": "53",
        "name": "Cuba"
      },
      {
        "code": "599",
        "name": "Curacao"
      },
      {
        "code": "537",
        "name": "Cyprus"
      },
      {
        "code": "420",
        "name": "Czech Republic"
      },
      {
        "code": "45",
        "name": "Denmark"
      },
      {
        "code": "246",
        "name": "Diego Garcia"
      },
      {
        "code": "253",
        "name": "Djibouti"
      },
      {
        "code": "1 767",
        "name": "Dominica"
      },
      {
        "code": "1 809",
        "name": "Dominican Republic"
      },
      {
        "code": "670",
        "name": "East Timor"
      },
      {
        "code": "56",
        "name": "Easter Island"
      },
      {
        "code": "593",
        "name": "Ecuador"
      },
      {
        "code": "20",
        "name": "Egypt"
      },
      {
        "code": "503",
        "name": "El Salvador"
      },
      {
        "code": "240",
        "name": "Equatorial Guinea"
      },
      {
        "code": "291",
        "name": "Eritrea"
      },
      {
        "code": "372",
        "name": "Estonia"
      },
      {
        "code": "251",
        "name": "Ethiopia"
      },
      {
        "code": "500",
        "name": "Falkland Islands"
      },
      {
        "code": "298",
        "name": "Faroe Islands"
      },
      {
        "code": "679",
        "name": "Fiji"
      },
      {
        "code": "358",
        "name": "Finland"
      },
      {
        "code": "33",
        "name": "France"
      },
      {
        "code": "596",
        "name": "French Antilles"
      },
      {
        "code": "594",
        "name": "French Guiana"
      },
      {
        "code": "689",
        "name": "French Polynesia"
      },
      {
        "code": "241",
        "name": "Gabon"
      },
      {
        "code": "220",
        "name": "Gambia"
      },
      {
        "code": "995",
        "name": "Georgia"
      },
      {
        "code": "49",
        "name": "Germany"
      },
      {
        "code": "233",
        "name": "Ghana"
      },
      {
        "code": "350",
        "name": "Gibraltar"
      },
      {
        "code": "30",
        "name": "Greece"
      },
      {
        "code": "299",
        "name": "Greenland"
      },
      {
        "code": "1 473",
        "name": "Grenada"
      },
      {
        "code": "590",
        "name": "Guadeloupe"
      },
      {
        "code": "1 671",
        "name": "Guam"
      },
      {
        "code": "502",
        "name": "Guatemala"
      },
      {
        "code": "224",
        "name": "Guinea"
      },
      {
        "code": "245",
        "name": "Guinea-Bissau"
      },
      {
        "code": "595",
        "name": "Guyana"
      },
      {
        "code": "509",
        "name": "Haiti"
      },
      {
        "code": "504",
        "name": "Honduras"
      },
      {
        "code": "852",
        "name": "Hong Kong SAR China"
      },
      {
        "code": "36",
        "name": "Hungary"
      },
      {
        "code": "354",
        "name": "Iceland"
      },
      {
        "code": "91",
        "name": "India"
      },
      {
        "code": "62",
        "name": "Indonesia"
      },
      {
        "code": "98",
        "name": "Iran"
      },
      {
        "code": "964",
        "name": "Iraq"
      },
      {
        "code": "353",
        "name": "Ireland"
      },
      {
        "code": "972",
        "name": "Israel"
      },
      {
        "code": "39",
        "name": "Italy"
      },
      {
        "code": "225",
        "name": "Ivory Coast"
      },
      {
        "code": "1 876",
        "name": "Jamaica"
      },
      {
        "code": "81",
        "name": "Japan"
      },
      {
        "code": "962",
        "name": "Jordan"
      },
      {
        "code": "7 7",
        "name": "Kazakhstan"
      },
      {
        "code": "254",
        "name": "Kenya"
      },
      {
        "code": "686",
        "name": "Kiribati"
      },
      {
        "code": "965",
        "name": "Kuwait"
      },
      {
        "code": "996",
        "name": "Kyrgyzstan"
      },
      {
        "code": "856",
        "name": "Laos"
      },
      {
        "code": "371",
        "name": "Latvia"
      },
      {
        "code": "961",
        "name": "Lebanon"
      },
      {
        "code": "266",
        "name": "Lesotho"
      },
      {
        "code": "231",
        "name": "Liberia"
      },
      {
        "code": "218",
        "name": "Libya"
      },
      {
        "code": "423",
        "name": "Liechtenstein"
      },
      {
        "code": "370",
        "name": "Lithuania"
      },
      {
        "code": "352",
        "name": "Luxembourg"
      },
      {
        "code": "853",
        "name": "Macau SAR China"
      },
      {
        "code": "389",
        "name": "Macedonia"
      },
      {
        "code": "261",
        "name": "Madagascar"
      },
      {
        "code": "265",
        "name": "Malawi"
      },
      {
        "code": "60",
        "name": "Malaysia"
      },
      {
        "code": "960",
        "name": "Maldives"
      },
      {
        "code": "223",
        "name": "Mali"
      },
      {
        "code": "356",
        "name": "Malta"
      },
      {
        "code": "692",
        "name": "Marshall Islands"
      },
      {
        "code": "596",
        "name": "Martinique"
      },
      {
        "code": "222",
        "name": "Mauritania"
      },
      {
        "code": "230",
        "name": "Mauritius"
      },
      {
        "code": "262",
        "name": "Mayotte"
      },
      {
        "code": "52",
        "name": "Mexico"
      },
      {
        "code": "691",
        "name": "Micronesia"
      },
      {
        "code": "1 808",
        "name": "Midway Island"
      },
      {
        "code": "373",
        "name": "Moldova"
      },
      {
        "code": "377",
        "name": "Monaco"
      },
      {
        "code": "976",
        "name": "Mongolia"
      },
      {
        "code": "382",
        "name": "Montenegro"
      },
      {
        "code": "1664",
        "name": "Montserrat"
      },
      {
        "code": "212",
        "name": "Morocco"
      },
      {
        "code": "95",
        "name": "Myanmar"
      },
      {
        "code": "264",
        "name": "Namibia"
      },
      {
        "code": "674",
        "name": "Nauru"
      },
      {
        "code": "977",
        "name": "Nepal"
      },
      {
        "code": "31",
        "name": "Netherlands"
      },
      {
        "code": "599",
        "name": "Netherlands Antilles"
      },
      {
        "code": "1 869",
        "name": "Nevis"
      },
      {
        "code": "687",
        "name": "New Caledonia"
      },
      {
        "code": "64",
        "name": "New Zealand"
      },
      {
        "code": "505",
        "name": "Nicaragua"
      },
      {
        "code": "227",
        "name": "Niger"
      },
      {
        "code": "234",
        "name": "Nigeria"
      },
      {
        "code": "683",
        "name": "Niue"
      },
      {
        "code": "672",
        "name": "Norfolk Island"
      },
      {
        "code": "850",
        "name": "North Korea"
      },
      {
        "code": "1 670",
        "name": "Northern Mariana Islands"
      },
      {
        "code": "47",
        "name": "Norway"
      },
      {
        "code": "968",
        "name": "Oman"
      },
      {
        "code": "92",
        "name": "Pakistan"
      },
      {
        "code": "680",
        "name": "Palau"
      },
      {
        "code": "970",
        "name": "Palestinian Territory"
      },
      {
        "code": "507",
        "name": "Panama"
      },
      {
        "code": "675",
        "name": "Papua New Guinea"
      },
      {
        "code": "595",
        "name": "Paraguay"
      },
      {
        "code": "51",
        "name": "Peru"
      },
      {
        "code": "63",
        "name": "Philippines"
      },
      {
        "code": "48",
        "name": "Poland"
      },
      {
        "code": "351",
        "name": "Portugal"
      },
      {
        "code": "1 787",
        "name": "Puerto Rico"
      },
      {
        "code": "974",
        "name": "Qatar"
      },
      {
        "code": "262",
        "name": "Reunion"
      },
      {
        "code": "40",
        "name": "Romania"
      },
      {
        "code": "7",
        "name": "Russia"
      },
      {
        "code": "250",
        "name": "Rwanda"
      },
      {
        "code": "685",
        "name": "Samoa"
      },
      {
        "code": "378",
        "name": "San Marino"
      },
      {
        "code": "966",
        "name": "Saudi Arabia"
      },
      {
        "code": "221",
        "name": "Senegal"
      },
      {
        "code": "381",
        "name": "Serbia"
      },
      {
        "code": "248",
        "name": "Seychelles"
      },
      {
        "code": "232",
        "name": "Sierra Leone"
      },
      {
        "code": "65",
        "name": "Singapore"
      },
      {
        "code": "421",
        "name": "Slovakia"
      },
      {
        "code": "386",
        "name": "Slovenia"
      },
      {
        "code": "677",
        "name": "Solomon Islands"
      },
      {
        "code": "27",
        "name": "South Africa"
      },
      {
        "code": "500",
        "name": "South Georgia and the South Sandwich Islands"
      },
      {
        "code": "82",
        "name": "South Korea"
      },
      {
        "code": "34",
        "name": "Spain"
      },
      {
        "code": "94",
        "name": "Sri Lanka"
      },
      {
        "code": "249",
        "name": "Sudan"
      },
      {
        "code": "597",
        "name": "Suriname"
      },
      {
        "code": "268",
        "name": "Swaziland"
      },
      {
        "code": "46",
        "name": "Sweden"
      },
      {
        "code": "41",
        "name": "Switzerland"
      },
      {
        "code": "963",
        "name": "Syria"
      },
      {
        "code": "886",
        "name": "Taiwan"
      },
      {
        "code": "992",
        "name": "Tajikistan"
      },
      {
        "code": "255",
        "name": "Tanzania"
      },
      {
        "code": "66",
        "name": "Thailand"
      },
      {
        "code": "670",
        "name": "Timor Leste"
      },
      {
        "code": "228",
        "name": "Togo"
      },
      {
        "code": "690",
        "name": "Tokelau"
      },
      {
        "code": "676",
        "name": "Tonga"
      },
      {
        "code": "1 868",
        "name": "Trinidad and Tobago"
      },
      {
        "code": "216",
        "name": "Tunisia"
      },
      {
        "code": "90",
        "name": "Turkey"
      },
      {
        "code": "993",
        "name": "Turkmenistan"
      },
      {
        "code": "1 649",
        "name": "Turks and Caicos Islands"
      },
      {
        "code": "688",
        "name": "Tuvalu"
      },
      {
        "code": "1 340",
        "name": "U.S. Virgin Islands"
      },
      {
        "code": "256",
        "name": "Uganda"
      },
      {
        "code": "380",
        "name": "Ukraine"
      },
      {
        "code": "971",
        "name": "United Arab Emirates"
      },
      {
        "code": "44",
        "name": "United Kingdom"
      },
      {
        "code": "1",
        "name": "United States"
      },
      {
        "code": "598",
        "name": "Uruguay"
      },
      {
        "code": "998",
        "name": "Uzbekistan"
      },
      {
        "code": "678",
        "name": "Vanuatu"
      },
      {
        "code": "58",
        "name": "Venezuela"
      },
      {
        "code": "84",
        "name": "Vietnam"
      },
      {
        "code": "1 808",
        "name": "Wake Island"
      },
      {
        "code": "681",
        "name": "Wallis and Futuna"
      },
      {
        "code": "967",
        "name": "Yemen"
      },
      {
        "code": "260",
        "name": "Zambia"
      },
      {
        "code": "255",
        "name": "Zanzibar"
      },
      {
        "code": "263",
        "name": "Zimbabwe"
      }
    ]
  }
  );

  
  
  //////////Script //////////////////////////////////////////////////////////////////////////////

  const [feedOrgCategory, setOrgCategory] = useState();
  const [feedOrgTask, setOrgTask] = useState();

  const [errorMessage4, setErrorMessage1] = useState("");

  /////////////////////Set values of pitch////////////////////////////////////////////

  const [short_intro, updateshort_intro] = useState("");
  const [ask_permission, updateask_permission] = useState("");
  const [confirmation, updateconfirmation] = useState("");
  const [hero_line, updatehero_line] = useState("");
  const [if_no, updateif_no] = useState("");
  const [if_yes, updateif_yes] = useState("");
  const [short_pitch, updateshort_pitch] = useState("");
  const [if_postive, updateif_postive] = useState("");
  const [if_nagative, updateif_nagative] = useState("");
  const [ask_questions, updateask_questions] = useState("");
  const [objection, updateobjection] = useState("");
  const [conclusion, updateconclusion] = useState("");
  const [call_savior, updatecall_savior] = useState("");
  const [versionId1, updateversionId1] = useState("");
  const [versionId2, updateversionId2] = useState("");
  const [versionId3, updateversionId3] = useState("");
  const [versionId4, updateversionId4] = useState("");
  const [versionId5, updateversionId5] = useState("");
  const [versionId6, updateversionId6] = useState("");
  const [livecat, updatelivecat] = useState("");
  const [feed, setFeed] = useState();
  const [feedAnswer, setFeedAnswer] = useState("");
  const [feedSimilarity, setfeedSimilarity] = useState();

  ///////////////////////////////////Call detailes////////////////////////////////

  const [detailsCallCategory, setdetailsCallCategory] = useState();
  const [detailsDescription, setdetailsDescription] = useState();
  const [detailsCallTime, setdetailsCallTime] = useState();
  const [detailsCallBrief, setdetailsCallBrief] = useState();
  const [detailsCallStatus, setdetailsCallStatus] = useState();
  const [detailsMood, setdetailsMood] = useState();
  const [detailsDuration, setdetailsDuration] = useState();
  const [detailsName, setdetailsName] = useState("n/a");
  const [detailsEmailId, setdetailsEmailId] = useState("n/a");
  const [detailsRecentCall, setdetailsRecentCall] = useState("n/a");
  const [detailsFollowUp, setdetailsFollowUp] = useState("n/a");
  const [followUpScript, setFollowUpScript] = useState("");
  const [followUpnext_call_username, setFollowUpnext_call_username] =
    useState("");
  const [callGoal, setCallGoal] = useState("");
  const [toCallType, setToCallType] = useState("nun");
  const [History, setHistory] = useState();
  const [trackId, setTrackId] = useState();
  const [detailsCallBy, setdetailsCallBy] = useState("n/a");
  const [alreadycustomer, setalreadycustomer] = useState("wait");
  const [sundayTemp1, setsundayTemp1] = useState(
    "Hi, This call will create first impression."
  );
  const [afterCallScript, setAfterCallScript] = useState(null);
  const [sourceList, setSourceList] = useState([]);
  

  const audioRef = useRef();

  const [RecLink, setRecLink] = useState("");

  const [addCallData, setaddCallData] = useState({
    organisation_id: org,
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: "",
    contact_number: "",
    email_address: "",
    description: "",
    next_call_user: "",
    Contact_Source: "",
    next_call_script_category: "",
    call_again_date: "",
    call_again_date: "",
    TicketId: ""
  });


  ///////////////////////////////////////set value of contact///////////////////////////////////

  const tostUpdate = (item) => {
    var item = item;

    toast.success(item, {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const tostError = (message) => {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateSong = (source) => {
    setRecLink(source);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  function sunday1() {
    document.getElementById("sunday1").style.display = "block";
  }
  function sunday1_back() {
    document.getElementById("sunday1").style.display = "none";
  }

  function openNav(item, itemId) {
    setHistory();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("mySidenav").style.width = "500px";
    document.getElementById("main").style.marginLeft = "500px";
    document.getElementById("trackbtn").click();
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  const getSourceList = () => {
    dispatch(getSourceListData({ access_token: Auth })).then((res) => {
      if (res.payload.status == "200") {
        setSourceList(res.payload.data);
      }
    });
  };

  useEffect(() => {
    getSourceList();
  }, []);

  function searchContact(item) {
    let phoneno = item;
    dispatch(getContactNumber({ access_token, phoneNo: phoneno, org })).then(
      (res) => {
        if (res.payload.status == 200) {
          setHistory(res.payload.data.call_history);
        }
      }
    );
  }

  function getScript() {
    dispatch(getScripts({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setOrgCategory(res.payload.data.scripts_types);
      }
    });
  }

  function fetchUserData() {
    dispatch(getUserData({ access_token, org })).then((res) => {
      if (res.payload.status == 200) {
        setFeed(res.payload.data);
      }
    });
  }

  function findcallGoal(value) {
    dispatch(getCallGoals({ access_token, org, type: value })).then((res) => {
      if (res.payload.status == 200) {
        setCallGoal(res.payload.data.your_goal);
      }
    });
  }



  function checkCallBack() {

    var url_string = window.location.href;
    var url = new URL(url_string);
    var nextcall = url.searchParams.get("nextcall");
    var contactno = url.searchParams.get("contactno");
    var email = url.searchParams.get("email");
    var customer = url.searchParams.get("customer");
    var tocalltype = url.searchParams.get("calltype");
    var callsource = url.searchParams.get("callsource");
    var callId = url.searchParams.get("callId");
      
     
    console.log(props, 'call back');

    
    if( Object.keys(props).length != 0){


    var nextcall = props.data.nextcall;
    var contactno = props.data.contactno;
    var email = props.data.email;
    var customer = props.data.customer;
    callData.TicketId = props.data.tid;
  

    updatestatus("scriptcall");

    }
    
      
  
    if(url.searchParams.get("tid") != ''){

      callData.TicketId =  url.searchParams.get("tid");

    }

    if(url.searchParams.get("nextcall") != ''){

      callData.script_category =  url.searchParams.get("nextcall");
      updatelivecat( url.searchParams.get("nextcall"));
    }



    if (tocalltype != null) {
      setToCallType(tocalltype);
    }

    if (contactno != null) {
      callData.script_category = nextcall;
      callData.Contact_Source = callsource;
      callData.contact_number = contactno;
      taskData.contact_number = contactno;
      callData.email_address = email;
      taskData.email_address = email;
      callData.customer_name = customer;
      taskData.customer_name = customer;
      taskData.Contact_Source = callsource;


      const d = new Date();
      callData.call_started_at = d.toISOString();
      callData.contact_search_at = d.toISOString();

      
      findcallGoal(nextcall);
      getCallDetails();

      pitchCatData.pitchCat = nextcall;
      sendPitchCat();
      
      updatestatus("scriptcall");
      // starttimer()
    }
  }

  //////////////////////////////////////////////////Get pitch//////////////

  const [pitchCatData, setPitchData] = useState({ pitchCat: "" });

  function updataData(e) {
    setCallGoal("");
    setPitchData({ ...pitchCatData, [e.target.name]: e.target.value });
    findcallGoal(e.target.value);
  }

  function sendPitchCat() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    const d = new Date();
    let currentTime = d.toISOString();
    callData.contact_search_at = currentTime;

    dispatch(
      getPar1Latest({ access_token, org, type: pitchCatData.pitchCat })
    ).then((res) => {
      if (res.payload.status == 200) {
        localStorage.setItem("current_script", pitchCatData.pitchCat);
        updatelivecat(pitchCatData.pitchCat);
        callData.script_category = pitchCatData.pitchCat;
        updateversionId1(res.payload.data.version_id);
        updateshort_intro(res.payload.data.short_intro);
        updateask_permission(res.payload.data.ask_permission);
        updateconfirmation(res.payload.data.confirmation);
        updatehero_line(res.payload.data.hero_line);
        updateif_no(res.payload.data.if_no);
        updateif_yes(res.payload.data.if_yes);
      }
    });

    dispatch(
      getPar2Latest({ access_token, org, type: pitchCatData.pitchCat })
    ).then((res) => {
      if (res.payload.status == 200) {
        updateversionId2(res.payload.data.version_id);
        updateshort_pitch(res.payload.data.shot_pitch);
        updateif_nagative(res.payload.data.if_nagative);
        updateif_postive(res.payload.data.if_postive);
      }
    });

    dispatch(
      getPar3Latest({ access_token, org, type: pitchCatData.pitchCat })
    ).then((res) => {
      if (res.payload.status == 200) {
        updateversionId3(res.payload.data.version_id);
        updateask_questions(res.payload.data.ask_questions);
      }
    });

    dispatch(
      getPar4Latest({ access_token, org, type: pitchCatData.pitchCat })
    ).then((res) => {
      if (res.payload.status == 200) {
        updateversionId4(res.payload.data.version_id);
        updateobjection(res.payload.data.questions_set);
      }
    });

    dispatch(
      getPar5Latest({ access_token, org, type: pitchCatData.pitchCat })
    ).then((res) => {
      if (res.payload.status == 200) {
        updateversionId5(res.payload.data.version_id);
        updateconclusion(res.payload.data.conclusion);
      }
    });

    dispatch(
      getPar6Latest({ access_token, org, type: pitchCatData.pitchCat })
    ).then((res) => {
      if (res.payload.status == 200) {
        updateversionId6(res.payload.data.version_id);
        updatecall_savior(res.payload.data.call_savior);
      }
    });

    findcallGoal(pitchCatData.pitchCat);
    ChangeStatus("scriptcall");
    
  }

  ////////////////////////////////////////////////////Vote up and down///////////////////

  const [voteData, setVotetData] = useState({
    part1_vid: "",
    part1: "",
    part2_vid: versionId2,
    part2: "",
    part3_vid: "",
    part3: "",
    part4_vid: "",
    part4: "",
    part5_vid: "",
    part5: "",
    part6_vid: "",
    part6: "",
  });


  const [personsearchresult, setpersonsearchresult ] = useState([]);

  function updataVoteData(e) {
    setVotetData({ ...voteData, [e.target.name]: e.target.value });

    tostUpdate("Vote Updated");
  }

  ////////////////////////Update suggestion///////////////////////////////////////////////////////////

  const [suggestion1, setSuggestion1] = useState({
    script_version_id: "",
    organisation_id: org,
    suggest_by_user: user_id,
    suggest_by_username: user_name,
    text: "",
  });
  const [suggestion2, setSuggestion2] = useState({
    script_version_id: "",
    organisation_id: org,
    suggest_by_user: user_id,
    suggest_by_username: user_name,
    text: "",
  });
  const [suggestion3, setSuggestion3] = useState({
    script_version_id: "",
    organisation_id: org,
    suggest_by_user: user_id,
    suggest_by_username: user_name,
    text: "",
  });
  const [suggestion4, setSuggestion4] = useState({
    script_version_id: "",
    organisation_id: org,
    suggest_by_user: user_id,
    suggest_by_username: user_name,
    text: "",
  });
  const [suggestion5, setSuggestion5] = useState({
    script_version_id: "",
    organisation_id: org,
    suggest_by_user: user_id,
    suggest_by_username: user_name,
    text: "",
  });
  const [suggestion6, setSuggestion6] = useState({
    script_version_id: "",
    organisation_id: org,
    suggest_by_user: user_id,
    suggest_by_username: user_name,
    text: "",
  });

  const [question1, setQuestion1] = useState({
    script_version_id: "",
    organisation_id: org,
    ask_by_user: user_id,
    ask_by_username: user_name,
    text: "",
  });
  const [question2, setQuestion2] = useState({
    script_version_id: "",
    organisation_id: org,
    ask_by_user: user_id,
    ask_by_username: user_name,
    text: "",
  });
  const [question3, setQuestion3] = useState({
    script_version_id: "",
    organisation_id: org,
    ask_by_user: user_id,
    ask_by_username: user_name,
    text: "",
  });
  const [question4, setQuestion4] = useState({
    script_version_id: "",
    organisation_id: org,
    ask_by_user: user_id,
    ask_by_username: user_name,
    text: "",
  });
  const [question5, setQuestion5] = useState({
    script_version_id: "",
    organisation_id: org,
    ask_by_user: user_id,
    ask_by_username: user_name,
    text: "",
  });
  const [question6, setQuestion6] = useState({
    script_version_id: "",
    organisation_id: org,
    ask_by_user: user_id,
    ask_by_username: user_name,
    text: "",
  });

  /////////////////////////Update Call///////////////////////////////////////////////////////////////////////

  const [callData, setCallData] = useState({
    TicketId: 0,
    added_by_user: user_id,
    added_by_username: user_name,
    script_category: livecat,
    customer_name: "",
    contact_number: "",
    email_address: "",
    Contact_Source: "",
    call_brief: "",
    call_status: "",
    description: "",
    call_again_date: "",
    next_call_user: "",
    next_call_script_category: "",
    organisation_id: org,
    contact_search_at: "",
    call_started_at: "",
    call_ended_at: "",
    clint_mood: "",
  });

  console.log(callData);

  const [taskData, setTaskData] = useState({
    added_by_user: user_id,
    added_by_username: user_name,
    taskHeading: "",
    contact_number: callData.contact_number,
    email_address: callData.email_address,
    customer_name: callData.customer_name,
    Contact_Source: callData.Contact_Source,
    description: "",
    lastDate: "",
    assineTO_userName: "",
    assineTO_userID: "",
    organisation_id: org,
    TicketId: props && Object.keys(props).length !== 0 ? props.data.tid : null,
    
  });

  function changeCallData(e) {
    setCallData({ ...callData, [e.target.name]: e.target.value });
  }

  function SearchHome(){
    searchPerson( callData.contact_number, 'to_search' );
  }


  function setperson(item){

    setCallData({
      TicketId: 0,
      added_by_user: user_id,
      added_by_username: user_name,
      script_category: livecat,
      customer_name: "",
      contact_number: item,
      email_address: "",
      Contact_Source: "",
      call_brief: "",
      call_status: "",
      description: "",
      call_again_date: "",
      next_call_user: "",
      next_call_script_category: "",
      organisation_id: org,
      contact_search_at: "",
      call_started_at: "",
      call_ended_at: "",
      clint_mood: "",
    })

    ChangeStatus("details");


  }

  function searchPerson( ph, itemtype ) {

    let searchcall;

    if(itemtype == 'to_search'){

      searchcall =  {
        "to_search":ph,
        "customer_name":"",
        "contact_number":"",
        "email_address":""
    };

    }

    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/search_for_contact',searchcall, { headers })
      .then((data) => {
        if (data.status == "200") {

          setpersonsearchresult(data.data);
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
    
    
  }

  function changeCallDatacallBrif(e) {
    setCallData({ ...callData, [e.target.name]: e.target.value });
    sunday1();
  }
  
  function changeTaskData(e) {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
  }

  function updateSuggestion1(e) {
    setSuggestion1({ ...suggestion1, [e.target.name]: e.target.value });
  }
  function updateSuggestion2(e) {
    setSuggestion2({ ...suggestion2, [e.target.name]: e.target.value });
  }
  function updateSuggestion3(e) {
    setSuggestion3({ ...suggestion3, [e.target.name]: e.target.value });
  }
  function updateSuggestion4(e) {
    setSuggestion4({ ...suggestion4, [e.target.name]: e.target.value });
  }
  function updateSuggestion5(e) {
    setSuggestion5({ ...suggestion5, [e.target.name]: e.target.value });
  }
  function updateSuggestion6(e) {
    setSuggestion6({ ...suggestion6, [e.target.name]: e.target.value });
  }

  function updateQuestion1(e) {
    setQuestion1({ ...question1, [e.target.name]: e.target.value });
  }
  function updateQuestion2(e) {
    setQuestion2({ ...question2, [e.target.name]: e.target.value });
  }
  function updateQuestion3(e) {
    setQuestion3({ ...question3, [e.target.name]: e.target.value });
  }
  function updateQuestion4(e) {
    setQuestion4({ ...question4, [e.target.name]: e.target.value });
  }
  function updateQuestion5(e) {
    setQuestion5({ ...question5, [e.target.name]: e.target.value });
  }
  function updateQuestion6(e) {
    setQuestion6({ ...question6, [e.target.name]: e.target.value });
  }

  function changeCallBackData(e) {
    setaddCallData({ ...addCallData, [e.target.name]: e.target.value });
  }

  const tostMsg = () => {
    toast.success("Record Added Successfully!", {
      position: "bottom-left",
      autoClose: 1000,
    });
  };

  function openNav(item, itemId) {
    setHistory();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("mySidenav").style.width = "500px";

    document.getElementById("trackbtn").click();
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  const tostWaitMsg = () => {
  
    toast('🦄 Call Initiated!', {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
};    

  function clearcalldata(){

    setaddCallData({
      organisation_id: org,
      added_by_user: user_id,
      added_by_username: user_name,
      customer_name: "",
      contact_number: "",
      email_address: "",
      description: "",
      next_call_user: "",
      Contact_Source: "",
      next_call_script_category: "",
      call_again_date: "",
    });

  }


  function cleartaskdata(){

    setTaskData({
      added_by_user: user_id,
      added_by_username: user_name,
      taskHeading: "",
      contact_number: callData.contact_number,
      email_address: callData.email_address,
      customer_name: callData.customer_name,
      Contact_Source: callData.Contact_Source,
      description: "",
      lastDate: "",
      assineTO_userName: "",
      assineTO_userID: "",
      organisation_id: org,
      
    });

  }


  function fetchOrgData() {
  
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_organisation?organisation_id=".concat(org), { headers })
      .then((data) => {
        if (data.status == "200") {
          setdetails(data.data[0].Numbermassing);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function clicktocall(item) {
  
    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/click_to_call',{

        "contact_number":"+91".concat(item),

      }, { headers })
      .then((data) => {
        if (data.status == "200") {

          tostWaitMsg();
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  //////////////////////////////////Send Suggestion////////////////////////////////////////////

  function sendSuggestion1(e) {
    e.preventDefault();
    dispatch(addsuggestions({ access_token, data: suggestion1 })).then(
      (res) => {
        if (res.payload.status == 200) {
          setSuggestion1({
            script_version_id: "",
            organisation_id: org,
            suggest_by_user: user_id,
            suggest_by_username: user_name,
            text: "",
          });
          tostUpdate("Suggestion Sent");
        }
      }
    );
  }

  function sendSuggestion2(e) {
    e.preventDefault();

    dispatch(addsuggestions({ access_token, data: suggestion2 })).then(
      (res) => {
        if (res.payload.status == 200) {
          setSuggestion2({
            script_version_id: "",
            organisation_id: org,
            suggest_by_user: user_id,
            suggest_by_username: user_name,
            text: "",
          });
          tostUpdate("Suggestion Sent");
        }
      }
    );
  }

  function sendSuggestion3(e) {
    e.preventDefault();
    dispatch(addsuggestions({ access_token, data: suggestion3 })).then(
      (res) => {
        if (res.payload.status == 200) {
          setSuggestion3({
            script_version_id: "",
            organisation_id: org,
            suggest_by_user: user_id,
            suggest_by_username: user_name,
            text: "",
          });
          tostUpdate("Suggestion Sent");
        }
      }
    );
  }

  function sendSuggestion4(e) {
    e.preventDefault();

    dispatch(addsuggestions({ access_token, data: suggestion4 })).then(
      (res) => {
        if (res.payload.status == 200) {
          setSuggestion4({
            script_version_id: "",
            organisation_id: org,
            suggest_by_user: user_id,
            suggest_by_username: user_name,
            text: "",
          });
          tostUpdate("Suggestion Sent");
        }
      }
    );
  }

  function sendSuggestion5(e) {
    e.preventDefault();
    dispatch(addsuggestions({ access_token, data: suggestion5 })).then(
      (res) => {
        if (res.payload.status == 200) {
          setSuggestion5({
            script_version_id: "",
            organisation_id: org,
            suggest_by_user: user_id,
            suggest_by_username: user_name,
            text: "",
          });
          tostUpdate("Suggestion Sent");
        }
      }
    );
  }

  function sendSuggestion6(e) {
    e.preventDefault();
    dispatch(addsuggestions({ access_token, data: suggestion6 })).then(
      (res) => {
        if (res.payload.status == 200) {
          setSuggestion6({
            script_version_id: "",
            organisation_id: org,
            suggest_by_user: user_id,
            suggest_by_username: user_name,
            text: "",
          });
          tostUpdate("Suggestion Sent");
        }
      }
    );
  }

  function sendQuestion1(e) {
    e.preventDefault();
    dispatch(addquestions({ access_token, data: question1 })).then((res) => {
      if (res.payload.status == 200) {
        setQuestion1({
          script_version_id: "",
          organisation_id: org,
          ask_by_user: user_id,
          ask_by_username: user_name,
          text: "",
        });
        tostUpdate("Question Sent");
      }
    });
  }
  function sendQuestion2(e) {
    e.preventDefault();
    dispatch(addquestions({ access_token, data: question2 })).then((res) => {
      if (res.payload.status == 200) {
        setQuestion2({
          script_version_id: "",
          organisation_id: org,
          ask_by_user: user_id,
          ask_by_username: user_name,
          text: "",
        });
        tostUpdate("Question Sent");
      }
    });
  }
  function sendQuestion3(e) {
    dispatch(addquestions({ access_token, data: question3 })).then((res) => {
      if (res.payload.status == 200) {
        setQuestion3({
          script_version_id: "",
          organisation_id: org,
          ask_by_user: user_id,
          ask_by_username: user_name,
          text: "",
        });
        tostUpdate("Question Sent");
      }
    });
  }
  function sendQuestion4(e) {
    e.preventDefault();
    dispatch(addquestions({ access_token, data: question4 })).then((res) => {
      if (res.payload.status == 200) {
        setQuestion4({
          script_version_id: "",
          organisation_id: org,
          ask_by_user: user_id,
          ask_by_username: user_name,
          text: "",
        });
        tostUpdate("Question Sent");
      }
    });
  }
  function sendQuestion5(e) {
    e.preventDefault();
    dispatch(addquestions({ access_token, data: question5 })).then((res) => {
      if (res.payload.status == 200) {
        setQuestion5({
          script_version_id: "",
          organisation_id: org,
          ask_by_user: user_id,
          ask_by_username: user_name,
          text: "",
        });
        tostUpdate("Question Sent");
      }
    });
  }
  function sendQuestion6(e) {
    e.preventDefault();
    dispatch(addquestions({ access_token, data: question6 })).then((res) => {
      if (res.payload.status == 200) {
        setQuestion6({
          script_version_id: "",
          organisation_id: org,
          ask_by_user: user_id,
          ask_by_username: user_name,
          text: "",
        });
        tostUpdate("Question Sent");
      }
    });
  }

  function sendToCallData(e) {



    dispatch(
      addNewContact({
        access_token,
        data: {
          organisation_id: org,
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: callData.customer_name,
    contact_number: callData.contact_number,
    email_address: callData.email_address,
    description: addCallData.description,
    next_call_user: addCallData.next_call_user,
    Contact_Source: "",
    next_call_script_category: addCallData.next_call_script_category,
    call_again_date: addCallData.call_again_date,
    TicketId: props && Object.keys(props).length !== 0 ? props.data.tid : null,
        },
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setaddCallData({
          organisation_id: org,
          added_by_user: user_id,
          added_by_username: user_name,
          customer_name: "",
          contact_number: "",
          email_address: "",
          description: "",
          Contact_Source: "",
          next_call_user: "",
          next_call_script_category: "",
          call_again_date: "",
        });
        tostUpdate('Call Scheduled'); 
        
      }
    });
  }

  function getRec(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/CallRecoding?".concat(
           "docucmet_id=",
          item.id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateSong(data.data);
          rec();
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function changeRec(item) {
    if (item.call_recoding == true) {
      return (
        <button
          onClick={() => getRec(item)}
          className="border-0 bg-transparent"
        >
          <i className="bi bi-play-circle-fill"></i>
        </button>
      );
    } else {
      return null;
    }
  }

  
 
  function sendTask() {
    dispatch(addNewTask({ access_token, data: taskData })).then((res) => {
      if (res.payload.status == 200) {
        tostUpdate('Task Scheduled'); 
        
      }
    });
  }

  ////////////////////////////////////////////Question////////////////////////////////////

  const [searchData, setSearchData] = useState({
    organisation_id: org,
    questionText: "",
  });

  function updataSearch(e) {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  }

  const [errorMessage, setErrorMessage] = useState("");

  function startDictation() {
    if (window.hasOwnProperty("webkitSpeechRecognition")) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.continuous = false;
      recognition.interimResults = false;

      recognition.lang = "en-US";
      recognition.start();

      recognition.onresult = function (e) {
        document.getElementById("transcript").value =
          e.results[0][0].transcript;
        recognition.stop();
        document.getElementById("labnol").submit();
      };

      recognition.onerror = function (e) {
        recognition.stop();
      };
    }
  }

  function playVid() {
    document.getElementById("vid").play();
  }


  ////////Call Search function/////////////////////////////

  function searchQuestion() {
    dispatch(
      getseachquestions({
        access_token,
        org,
        question: searchData.questionText,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setFeedAnswer(res.payload.data.Answer);
        setfeedSimilarity(res.payload.data.similar);
        setSearchData({ organisation_id: org, questionText: "" });
      } else {
        tostError("No answer found");
      }
    });
  }

  // function starttimer(){
  //   setStartDate(new Date());
  //                           const timer = setInterval(() => {
  //                             let start = moment(startDate);
  //                             let end = moment(new Date());
  //                             let diff = end.diff(start);
  //                             let f = moment.utc(diff).format("HH:mm:ss");
  //                             setDiff(f);
  //                           }, 1000);
  //                           setTimer(timer);
  // }

  function ChangeStatus(item) {
    if (item == "askno") {
      updatestatus("askno");
      setCallData({
        TicketId: 0,
        added_by_user: user_id,
        added_by_username: user_name,
        script_category: livecat,
        customer_name: "",
        contact_number: "",
        email_address: "",
        call_brief: "",
        call_status: "",
        description: "",
        call_again_date: "",
        next_call_user: "",
        next_call_script_category: "",
        organisation_id: org,
        contact_search_at: "",
        call_started_at: "",
        call_ended_at: "",
        clint_mood: "",
      });
    } else if (item == "details") {
      let getPhoneSize = callData.contact_number;
      getPhoneSize = getPhoneSize.length;

      if (callData["contact_number"].length == 10 || callData["contact_number"].length == 11 || callData["contact_number"].length == 13) {
        const d = new Date();
        let currentTime = d.toISOString();
        callData.contact_search_at = currentTime;

        getCallDetails();

        updatestatus("details");
      }
    } else if (item == "scriptcall") {
      const d = new Date();
      let currentTime = d.toISOString();
      callData.call_started_at = currentTime;
      updatestatus("scriptcall");
      // starttimer();
    }
    
      dispatch(getProgressBarStatus({ access_token: Auth }));
    setCallGoal("");
  }

  function voteup(script_id) {
    const VoteIdData = { vesion_id: script_id };
    dispatch(
      upvote({
        access_token,
        data: VoteIdData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        tostMsg();
      }
    });
  }

  function votedown(script_id) {
    const VoteIdData = { vesion_id: script_id };
    dispatch(
      downvote({
        access_token,
        data: VoteIdData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        tostMsg();
      }
    });
  }



 

  function sendContactData() {
    const d = new Date();
    let currentTime = d.toISOString();
    callData.call_ended_at = currentTime;

    let endpoint;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    if (alreadycustomer == "true") {
      endpoint = "/update_call_details/";
    } else if (alreadycustomer == "false") {
      endpoint = "/add_new_call/";
    }

    if( Object.keys(props).length != 0){
      

      callData.TicketId = props.data.tid;

      // setTaskData((prevTaskData) => ({
      //   ...prevTaskData,
      //   TicketId: props.data.tid, 
      // }));

      // setaddCallData((prevCallData) => ({
      //   ...prevCallData,
      //   TicketId: props.data.tid, 
      // }));




    }


    axios
      .post(endpoint, callData, { headers })
      .then((data) => {
        if (data.status == "200") {
          dispatch(getProgressBarStatus({ access_token: Auth }));
          

if(taskData.taskHeading != '' ){

  sendTask();
}


if(addCallData.next_call_script_category != ''){
  sendToCallData();
}

     

          if (voteData.part1 == "up") {
            const script_id = voteData.part1_vid;
            voteup(script_id);
          }

          if (voteData.part1 == "down") {
            const script_id = voteData.part1_vid;
            votedown(script_id);
          }
          if (voteData.part2 == "up") {
            const script_id = voteData.part2_vid;
            voteup(script_id);
          }
          if (voteData.part2 == "down") {
            const script_id = voteData.part2_vid;
            votedown(script_id);
          }
          if (voteData.part3 == "up") {
            const script_id = voteData.part3_vid;
            voteup(script_id);
          }
          if (voteData.part3 == "down") {
            const script_id = voteData.part3_vid;
            votedown(script_id);
          }
          if (voteData.part4 == "up") {
            const script_id = voteData.part4_vid;
            voteup(script_id);
          }
          if (voteData.part4 == "down") {
            const script_id = voteData.part4_vid;
            votedown(script_id);
          }
          if (voteData.part5 == "up") {
            const script_id = voteData.part5_vid;
            voteup(script_id);
          }
          if (voteData.part5 == "down") {
            const script_id = voteData.part5_vid;
            votedown(script_id);
          }
          if (voteData.part6 == "up") {
            const script_id = voteData.part6_vid;
            voteup(script_id);
          }
          if (voteData.part6 == "down") {
            const script_id = voteData.part6_vid;
            votedown(script_id);
          }

          // if (taskData.taskHeading != "" || taskData.taskHeading != null) {
          //   sendTask();
          // }

          if (toCallType == "tocall") {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var callId = url.searchParams.get("callId");

            // const headers = {
            //   "Content-Type": "text/plain",
            //   Authorization: `Bearer ${Auth}`,
            // };

            // axios
            //   .delete(
            //     "/deleteContactToCall?".concat(
            //       "record_id=",
            //       callId
            //     ),
            //     { headers }
            //   )
            //   .then((data) => {
            //     if (data.status == "200") {
            //       window.location.replace("https://dashboard.salessunday.com/");
            //     }
            //     setErrorMessage(data.data.message);
            //   })
            //   .catch((err) => console.log(err));
            dispatch(
              deleteContactToCall({
                access_token,
                id: callId,
              })
            ).then((res) => {
              if (res.payload.status == 200) {
                location("/");
              }
            });
          } else if (toCallType == "callback") {
            location("/");
          }

          setCallData({
            added_by_user: user_id,
            added_by_username: user_name,
            script_category: livecat,
            customer_name: "",
            contact_number: "",
            email_address: "",
            Contact_Source: "",
            call_brief: "",
            call_status: "",
            description: "",
            call_again_date: "",
            next_call_user: "",
            next_call_script_category: "",
            organisation_id: org,
            call_type: "",
            contact_search_at: "",
            call_started_at: "",
            call_ended_at: "",
          });

          setTaskData({
            added_by_user: user_id,
            added_by_username: user_name,
            taskHeading: "",
            contact_number: "",
            email_address: "",
            customer_name: "",
            description: "",
            lastDate: "",
            assineTO_userName: "",
            assineTO_userID: "",
            Contact_Source: "",
            organisation_id: org,
          });

          updatestatus("askno");
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getCallDetails() {
    dispatch(
      findContactDetails({
        access_token,
        org,
        contact_number: callData.contact_number,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setdetailsCallCategory(res.payload.data.script_category);
        setdetailsDescription(res.payload.data.description);
        setdetailsCallTime(res.payload.data.added_at);
        setdetailsCallBrief(res.payload.data.call_brief);
        setdetailsCallStatus(res.payload.data.call_status);
        setdetailsMood(res.payload.data.clint_mood);
        setdetailsDuration();
        setdetailsName(res.payload.data.customer_name);
        setdetailsEmailId(res.payload.data.email_address);
        setdetailsRecentCall(res.payload.data.added_at);
        setdetailsFollowUp(res.payload.data.call_again_date);
        setdetailsCallBy(res.payload.data.added_by_username);
        setFollowUpScript(res.payload.data.script_category);
        setFollowUpnext_call_username(res.payload.data.next_call_username);
        callData.customer_name = res.payload.data.customer_name;
        callData.email_address = res.payload.data.email_address;
        setalreadycustomer("true");
      } else {
        setalreadycustomer("false");
        setdetailsCallCategory();
        setdetailsCallTime();
        setdetailsCallBrief();
        setdetailsCallStatus();
        setdetailsMood();
        setdetailsDuration();
        setdetailsName();
        setdetailsEmailId();
        setdetailsRecentCall("n/a");
        setdetailsFollowUp("n/a");
        setdetailsCallBy();
      }
    });
  }

  function getScriptCallingInstrucation() {
    dispatch(
      getscriptCallEndingInstruction({
        access_token,
        org,
        type: pitchCatData.pitchCat,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        let dataGroupby = _.groupBy(res.payload.data.instruction, "call_brief");
        setAfterCallScript(dataGroupby);
      }
    });
  }

  useEffect(() => {
    getScriptCallingInstrucation();

    if(admin != "true"){

      fetchOrgData();

    }

  }, [pitchCatData]);

  function getfrequency() {
    var TimeStampDate = new Date(callData.contact_search_at);
    var LastCallDate = new Date(detailsCallTime);

    // To calculate the time difference of two dates
    var Difference_In_Time = TimeStampDate.getTime() - LastCallDate.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    var Difference_In_Hour = Difference_In_Days * 24;

    var Difference_In_Min = Difference_In_Days * 24 * 60;

    var DD = Math.trunc(Difference_In_Days);
    var DH = Math.trunc(Difference_In_Hour);
    var DM = Difference_In_Min - Math.trunc(Difference_In_Hour) * 60;
    var DM = Math.trunc(DM);

    let customermood;

    if (detailsMood == "intense") {
      customermood = '<i className="bi bi-emoji-angry" title="Intense"></i>';
    } else if (detailsMood == "unhappy") {
      customermood = '<i className="bi bi-emoji-frown" title="Unhappy">';
    } else if (detailsMood == "normal") {
      customermood = '<i className="bi bi-emoji-smile" title="Normal"></i>';
    } else if (detailsMood == "happy") {
      customermood = '<i className="bi bi-emoji-laughing" title="Happy"></i>>';
    }

    if (DD == 0) {
      if (DM == 0) {
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <h5>
                  <kbd>
                    <b>High Frequncy</b>
                  </kbd>
                </h5>
              </div>
              <div className="col-6">
                <div className="progress border border-dark" style={{ height: "15px" }}>
                  <div
                    className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="text-secondary">
                  <small>
                    {" "}
                    Last Call: <b className="text-dark">{DH}</b> hrs ago{" "}
                  </small>
                </p>
              </div>
            </div>
          </div>
        );

        let temp =
          "The last call was made" +
          DH +
          "ago. This will be annoying if the lead is not expecting a call.";

        setsundayTemp1(temp);
      } else if (DH == 0) {
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <h5>
                  <kbd>
                    <b>High Frequncy</b>
                  </kbd>
                </h5>
              </div>
              <div className="col-6">
                <div className="progress border border-dark" style={{ height: "15px" }}>
                  <div
                    className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="text-secondary">
                  <small>
                    Last Call: <b className="text-dark">{DM}</b> min ago
                  </small>
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="row">
              <div className="col-6">
                <h5>
                  <kbd>
                    <b className="">High Frequncy</b>
                  </kbd>
                </h5>
              </div>
              <div className="col-6">
                <div className="progress border border-dark" style={{ height: "15px" }}>
                  <div
                    className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p className="text-secondary">
                  <small>
                    Last Call: <b className="text-dark">{DH}</b> hrs{" "}
                    <b className="text-dark">{DM}</b> min ago
                  </small>
                </p>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (DD <= 3 && DD >= 0) {
        return (
          <div className="row">
            <div className="col-6">
              <h5>
                <kbd>
                  <b>Medium Frequncy</b>
                </kbd>
              </h5>
            </div>
            <div className="col-6">
              <div className="progress border border-dark" style={{ height: "15px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                  role="progressbar"
                  style={{ width: "70%" }}
                  aria-valuenow={70}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <p className="text-secondary">
                <small>
                  {" "}
                  Last Call: <b className="text-dark">{DD}</b> days ago
                </small>
              </p>
            </div>
          </div>
        );
      } else if (DD >= 3 && DD <= 7) {
        return (
          <div className="row">
            <div className="col-6">
              <h5>
                <kbd>
                  <b>Less Frequncy</b>
                </kbd>
              </h5>
            </div>
            <div className="col-6">
              <div className="progress border border-dark" style={{ height: "15px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-primary progress-bar-animated"
                  role="progressbar"
                  style={{ width: "50%" }}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <p className="text-secondary">
                <small>
                  Last Call: <b className="text-dark">{DD}</b> days ago
                </small>
              </p>
            </div>
          </div>
        );
      } else if (DD > 7) {
        return (
          <div className="row">
            <div className="col-6">
              <h5>
                <kbd>
                  <b>Low Frequncy</b>
                </kbd>
              </h5>
            </div>
            <div className="col-6">
              <div className="progress border border-dark" style={{ height: "15px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                  role="progressbar"
                  style={{ width: "25%" }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>

              <p className="text-secondary">
                <small>
                  {" "}
                  Last Call: <b className="text-dark">{DD}</b> days ago
                </small>
              </p>
            </div>
          </div>
        );
      }
    }
  }
  const handleUpdateUserDetails = (data, value, keynamevalue) => {
    callData.call_type = "";
    callData[keynamevalue] = value;
    let finaldata = { ...callData, [keynamevalue]: value };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    if (alreadycustomer == "true") {
      axios
        .post("/Update_ClintDetails", finaldata, { headers })
        .then((data) => {
          if (data.status == "200") {
            if (keynamevalue === "email_address") {
              setdetailsEmailId(value);
            } else {
              setdetailsName(value);
            }
          }
        });
    }
  };

  function getdetails() {
    if (alreadycustomer == "true") {
      return (
        <>
          <div className="row">
            <div className="col-10">
              <figure>
                <blockquote className="blockquote text-dark">
                  <p className="text-pop">
                    <i className="bi bi-quote"></i>
                    <b>{detailsCallCategory}</b> on{" "}
                    <b>{detailsCallTime.substring(0, 16).replace("T", " ")}</b>.
                    The call brief was <b>{detailsCallBrief}</b>. The call went
                    till <b>{detailsCallStatus}</b>.
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer text-secondary">
                  {detailsCallBy}
                </figcaption>
              </figure>
            </div>
            <div className="col-2">
              {" "}
              <img
                className="circle-pop"
                src={generateavatar(detailsCallBy, "White", "#004aad")}
                width="40"
                height="40"
              ></img>
            </div>
            <div className="col-12">
              <button
                onClick={morebtn}
                id="myBtn"
                className="btn btn-light btn-sm"
              >
                Read More <i className="bi bi-caret-down-fill"></i>
              </button>
              <p>
                <span id="dots"></span>
                <span id="more">{detailsDescription}</span>
              </p>
            </div>
          </div>

          <div className="col-12">{getfrequency()}</div>

          
            <table className="table table-striped table-dark border border-white">
              <tbody>
                <tr>
                  <td>
                    <i className="bi bi-person-fill" /> Name:{" "}
                  </td>
                  <td>
                    {" "}
                    <code>
                      <EditableTextField
                        data={{ customer_name: detailsName }}
                        handleBlur={handleUpdateUserDetails}
                        keyname="customer_name"
                        placeholder="Name"
                      />
                      {/* <b>{detailsName}</b> */}
                    </code>
                  </td>
                </tr>
               <tr>
                  <td>
                    <i className="bi bi-telephone-fill" /> Contact No:{" "}
                  </td>
                  <td>{masknumber(callData.contact_number)}</td>
                </tr>
                
                <tr>
                  <td>
                    <i className="bi bi-envelope-fill" /> Email Id:
                  </td>
                  <td>
                    {/* {detailsEmailId} */}
                    <EditableTextField
                      data={{ email_address: detailsEmailId }}
                      handleBlur={handleUpdateUserDetails}
                      keyname="email_address"
                      placeholder="Email"
                    />
                  </td>
                </tr>
               
              </tbody>
            </table>
          
        </>
      );
    } else if (alreadycustomer == "wait") {
      return (
        <>
          <div className="row">
            <div className="col-10">
              <figure>
                <blockquote className="blockquote text-dark">
                  <p>Please Wait.</p>{" "}
                </blockquote>
                <figcaption className="blockquote-footer text-secondary">
                  Sunday
                </figcaption>
              </figure>
            </div>
            <div className="col-2">
              {" "}
              <img
                className="circle-pop"
                src={generateavatar("Sunday", "White", "#004aad")}
                width="40"
                height="40"
              ></img>
            </div>
          </div>
          <br />
            <table className="table table-striped table-dark border border-white">
              <tbody>
                <tr>
                  <td>
                    <i className="bi bi-person-fill" /> Name:{" "}
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      value={callData.customer_name}
                      onChange={changeCallData}
                      name="customer_name"
                      className="form-control bg-dark text-white inside3"
                      id="exampleFormControlInput1"
                      placeholder="Enter Name(optional)"
                    />
                  </td>
                </tr>
              <tr>
                  <td>
                    <i className="bi bi-telephone-fill" /> Contact No:{" "}
                  </td>
                  <td>{masknumber(callData.contact_number)}</td>
                </tr>
                <tr>
                  <td>
                    <i className="bi bi-envelope-fill" /> Email Id:
                  </td>
                  <td>
                    {" "}
                    <input
                      type="email"
                      value={callData.email_address}
                      onChange={changeCallData}
                      name="email_address"
                      className="form-control bg-dark text-white inside3"
                      id="exampleFormControlInput1"
                      placeholder="Enter Email(optional)"
                    />{" "}
                  </td>
                </tr>
               
              </tbody>
            </table>
        </>
      );
    } else {
      return (
        <>
          <div className="row">
            <div className="col-10">
              <figure>
                <blockquote className="blockquote text-dark">
                  <p>This contact number was not called.</p>
                </blockquote>
                <figcaption className="blockquote-footer text-secondary">
                  Sunday
                </figcaption>
              </figure>
            </div>
            <div className="col-2">
              {" "}
              <img
                className="circle-pop"
                src={generateavatar("Sunday", "White", "#004aad")}
                width="40"
                height="40"
              ></img>
            </div>
          </div>
          <br />
            <table className="table table-striped table-dark border border-white">
              <tbody>
                <tr>
                  <td>
                    <i className="bi bi-person-fill" /> Name:{" "}
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      value={callData.customer_name}
                      onChange={changeCallData}
                      name="customer_name"
                      className="form-control-sm bg-dark text-white inside3"
                      id="exampleFormControlInput1"
                      placeholder="Enter Name(optional)"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="bi bi-telephone-fill" /> Contact No:{" "}
                  </td>
                  <td>{masknumber(callData.contact_number)}</td>
                </tr>
                <tr>
                  <td>
                    <i className="bi bi-envelope-fill" /> Email Id:
                  </td>
                  <td>
                    {" "}
                    <input
                      type="email"
                      value={callData.email_address}
                      onChange={changeCallData}
                      name="email_address"
                      className="form-control-sm bg-dark text-white inside3"
                      id="exampleFormControlInput1"
                      placeholder="Enter Email(optional)"
                    />{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="bi bi-bezier2"></i> Source:
                  </td>
                  <td>
                    {" "}
                    <div>
                      <select
                        value={callData.Contact_Source}
                        onChange={changeCallData}
                        name="Contact_Source"
                        className="form-select-sm border text-white inside3"
                        aria-label="Default select example"
                        required
                      >
                        <option>Select source (optional)</option>
                        {sourceList &&
                          sourceList?.map((item) => (
                            <option value={item} selected>
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
        </>
      );
    }
  }

  function morebtn() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read More";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read Less";
      moreText.style.display = "inline";
    }
  }

  function getTask() {
    dispatch(
      getTasks({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setOrgTask(res.payload.data);
      }
    });
  }

  function masknumber(item){

    if( details == true){
     return item;
   
    }else{
     return item.slice(0, -3).concat('*** ');
    }
     } 

     const [searchQuery, setSearchQuery] = useState('');
     const [highlightedContent, setHighlightedContent] = useState('');
   
     const performSearch = () => {
       // Get the division containing the text
       const textDivision = document.getElementById('textDivision');
   
       // Clear any previous highlights
       const clearedContent = textDivision.innerHTML.replace(
         /<span class="highlighted">(.*?)<\/span>/g,
         '$1'
       );
       textDivision.innerHTML = clearedContent;
   
       // Perform the search and highlight the matches
       const regex = new RegExp(searchQuery, 'gi');
       const matches = textDivision.innerHTML.match(regex);
   
       if (matches) {
         // Highlight the matching occurrences
         const highlightedContent = textDivision.innerHTML.replace(
           regex,
           '<span class="highlighted">$&</span>'
         );
         textDivision.innerHTML = highlightedContent;
       }
     };
   
     const handleInputChange = (event) => {
       setSearchQuery(event.target.value);
     };

  //////////////////////////////////////////////////Call function//////////////////////////

  useEffect(() => {
    checkCallBack();
    getScript();
    fetchUserData();
    getTask();
  }, [1]);
  const callTaskScript = (call_brief) => {
    if (call_brief !== undefined && Object.keys(afterCallScript).length > 0) {
      let valueScript =
        afterCallScript[call_brief] &&
        afterCallScript[call_brief][0]?.action?.script;

      let valueTask = afterCallScript[call_brief]
        ? afterCallScript[call_brief][0]?.action?.task
        : "";

      if (valueScript?.length > 0) {
        return ` After this call you have to schedule ${valueScript} call.`;
      } else if (valueTask.length > 0) {
        return `After this call you have to create ${valueTask} task.`;
      } else {
        return "No after call instructions are provided by the admin";
      }
    } else {
      return "No after call instructions are provided by the admin";
    }
  };

  if (status == "askno") {
    //Ask user for contact no
    return (
      <div className="container">
        {" "}
        <mark className="bg-light text-dark"><h5><i className="bi bi-phone-fill" /> Smart call session <i className="bi bi-arrow-down"></i></h5></mark>
        <br />
        <br />
        <br />
        <div className="row align-items-center justify-content-md-center">
          <div className="col-12 col-sm-4 p-3 mb-5 border-0 animate1">
            <h3 align="center">
              <img src={letscall} height={150} />
            </h3>
     

            <div className="mb-3">
              <label
                htmlFor="phoneInputEmail1"
                className="form-label text-dark"
              >
                {" "}
                <i className="bi bi-telephone-fill"></i> Phone number you want to
                call?
              </label>
              <div className="row g-1">
              {/* <div className="col-3">
              <select className="form-select border-dark" aria-label="Default select example">
              {countrycode.countries &&
                          countrycode.countries?.map((item) => (
                            <option value={item.code}>
                             + {item.code} - {item.name}
                            </option>
                          ))}
</select>
              </div> */}
              <div className="col-12">
              <div className="input-group">
                
                <input
                  type="text"
                  name="contact_number"
                  value={callData.contact_number}
                  onChange={e => { changeCallData(e); }}
                  className="form-control inside2 border-dark"
                  placeholder="New Number / Search Details"
                  aria-label="Recipient's Name"
                  aria-describedby="button-addon2"
                />
                <button
                  className="btn btn-dark pop"
                  onClick={SearchHome}
                  type="button"
                  id="button-addon2"
                >
                  <i className="bi bi-search"></i>
                </button>
              
                {
              
                  (callData["contact_number"].length >= 10 || callData["contact_number"].length == 11 || callData["contact_number"].length == 12 || callData["contact_number"].length == 13)  ? 
                  <button
                  className="btn btn-dark border-start"
                  onClick={() => ChangeStatus("details")}
                  type="button"
                  id="button-addon2"
                >
                  <i className="bi bi-arrow-right" />
                </button>  
                  : <button
                  className="btn btn-dark border-start disabled"
                 
                  type="button"
                  id="button-addon2"
                >
                  <i className="bi bi-arrow-right" />
                </button>
                }
               
              </div>
              </div>
              </div>
            
              

<br/>
<div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
              <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item.contact_number)} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul>
</div>
            </div>
           

            
          </div>
        </div>
        <div id="bottomAI">
          <button
            type="button"
            onClick={() => sunday1()}
            className="btn btn-info rounded-circle"
          >
            <h4>
              <i className="bi bi-robot" />
            </h4>
          </button>
        </div>
        <div
          className="card w-25 chat-popup p-3 mb-5 border-0 shadow"
          id="sunday1"
        >
          <div className="card-body">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <h3>
                <i
                  onClick={() => sunday1_back()}
                  className="bi bi-x-circle-fill"
                />
              </h3>
            </div>
            <h5 className="card-title">
              Sunday Suggestion <i className="bi bi-emoji-sunglasses"></i>
            </h5>
            <p className="card-text">
              Enter the contact number so that I can start helping you.
            </p>
          </div>
        </div>
        <ToastContainer transition={Flip} />
      </div>
    );
  } else if (status == "details") {
    //Search Details,
    return (
      <div className="container">
        <div className="row align-items-center justify-content-md-center">
          <div className="col-12 col-sm-4 p-3 mb-5 border border-dark shadow  infocard">
            <h3 className="">
              <i
                onClick={() => ChangeStatus("askno")}
                className="bi bi-arrow-left-circle-fill"
              />
            </h3>
            {getdetails()}

            <div className="accordion" id="accordionExample">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  onClick={() => openNav(callData.contact_number, "trackbtn")}
                  className="btn pop border border-dark bg-white"
                  type="button"
                >
                  <i className="bi bi-geo-fill" /> Track
                </button>

                {alreadycustomer != "wait" ? (
                  <button
                    className="btn btn-dark me-md-2 collapsed pop border border-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <i className="bi bi-telephone-fill" /> Call
                  </button>
                ) : null}
              </div>

              <div className="accordion-item bg-transparent border-0">
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse "
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body ">
                    <p>Select Call Category</p>
                    <form onSubmit={sendPitchCat}>
                      <div className="input-group">
                        <select
                          value={pitchCatData.pitchCat}
                          onChange={updataData}
                          name="pitchCat"
                          className="form-select inside border-secondary"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          required
                        >
                          <option value="" selected>
                            Choose...
                          </option>
                          {feedOrgCategory &&
                            feedOrgCategory?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                        </select>
                      </div>
                      <br />
                      <p>{callGoal}</p>
                      {pitchCatData.pitchCat != "" ? (
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-dark me-md-2 collapsed pop border border-white"
                            type="submit"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="bi bi-arrow-right"></i> Let's Go!
                          </button>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div id="bottomAI">
          <button
            type="button"
            onClick={() => sunday1()}
            className="btn btn-info rounded-circle"
          >
            <h4>
              <i className="bi bi-robot" />
            </h4>
          </button>
        </div>

        <div
          className="card w-25 chat-popup p-3 mb-5 border-0 shadow"
          id="sunday1"
        >
          <div className="card-body">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <h3>
                <i
                  onClick={() => sunday1_back()}
                  className="bi bi-x-circle-fill"
                />
              </h3>
            </div>
            <h5 className="card-title">
              Sunday Suggestion <i className="bi bi-emoji-sunglasses"></i>
            </h5>
            <p className="card-text">
              Sorry I cannot study past calls from the system. You have to make
              few more calls. All the best!
            </p>
          </div>
        </div>

        <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>

        <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              <div className="row">
                                <br/>
                               
                                </div>
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
      </div>
    );
  } else if (status == "scriptcall") {
    return (
      <div className="container script">
        <div className="row">
          <div className="col-12 col-sm-8">
            {callGoal != ''?<p>
          <i className="bi bi-bullseye"></i> Goal behind this call: <b>{callGoal}</b>
          <br/>
    
         
</p>:null }
          
      
<div align='right'><div className="col-4"><div className="input-group mb-3 input-group-sm">
  <input
    type="text"
    value={searchQuery}
    onChange={handleInputChange}
    className="form-control border-dark"
    placeholder="Search Keyword (min 4)"
    aria-label="Recipient's username"
    aria-describedby="button-addon2"
  />
  {
      searchQuery.length  > 3? <button
      className="btn btn-dark"
      type="button"
      id="button-addon2"
      onClick={performSearch}
    >
      <i className="bi bi-search"></i>
    </button>: <button
    className="btn btn-dark"
    type="button"
    id="button-addon2"
    onClick={performSearch}
    disabled>
    <i className="bi bi-search"></i>
  </button>
    }
 
</div></div></div>
<div className="mb-3"> <i className="bi bi-phone-vibrate"></i> Phone call assistance: </div>

<div id="textDivision">
  <div
              className="accordion shadow-sm"
              id="accordionPanelsStayOpenExample"
            >
              {
                (short_intro ==='' && confirmation ==='' && hero_line ==='' && ask_permission ==='' && if_yes ==='' && if_no ==='')?null:<> <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button border accordbtn border-dark "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-1-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
                      />
                    </svg>
                    &nbsp; Salute & Confirmation - Greeting
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body accord-grad text-light">
                    <blockquote className="blockquote">
                      {short_intro}&nbsp;{confirmation}&nbsp;
                      <br />
                      <br /> <b className="text-white">{hero_line}</b>&nbsp;
                      <br />
                      <br /> {ask_permission}
                    </blockquote>

                    <div className="card bg-secondary text-dark">
                      <div className="card-body">
                        If Yes : <b>{if_yes}</b>
                        <hr />
                        If No : <b>{if_no}</b>
                      </div>
                    </div>
                    <br />

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-transparent text-white"
                          disabled
                        >
                          Vote:
                        </button>
                        <div onChange={updataVoteData}>
                          <input
                            type="hidden"
                            value={(voteData.part1_vid = versionId1)}
                            name="part1_vid"
                          />
                          <input
                            type="radio"
                            className="btn-check"
                            value="up"
                            checked={voteData.part1}
                            name="part1"
                            id="part11"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-primary" htmlFor="part11">
                            <i className="bi bi-arrow-up-circle"></i>
                          </label>
                          &nbsp; &nbsp;
                          <input
                            type="hidden"
                            value={(voteData.part1_vid = versionId1)}
                            name="part1_vid"
                          />
                          <input
                            type="radio"
                            className="btn-check"
                            value="down"
                            checked={voteData.part1}
                            name="part1"
                            id="part12"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-danger" htmlFor="part12">
                            <i className="bi bi-arrow-down-circle"></i>
                          </label>
                        </div>
                        &nbsp; &nbsp;
                        <button
                          className="btn btn-transparent text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          More <i className="bi bi-caret-down-fill"></i>
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="accordion " id="accordionExample">
                      <div className="accordion-item bg-transparent">
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              <i className="bi bi-chat-left-text"></i> Write
                              your Suggestion
                            </p>
                            <form onSubmit={sendSuggestion1}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (suggestion1.script_version_id = versionId1)
                                }
                                onChange={updateSuggestion1}
                                name="script_version_id"
                                placeholder="Suggestion"
                                aria-label="Suggestion"
                                aria-describedby="button-addon2"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={suggestion1.text}
                                  onChange={updateSuggestion1}
                                  name="text"
                                  placeholder="Suggestion"
                                  aria-label="Suggestion"
                                  aria-describedby="button-addon2"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon2"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                            <p>
                              <i className="bi bi-question-circle"></i> Add a
                              Question
                            </p>
                            <form onSubmit={sendQuestion1}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (question1.script_version_id = versionId1)
                                }
                                onChange={updateQuestion1}
                                name="script_version_id"
                                placeholder="Question"
                                aria-label="Question"
                                aria-describedby="button-addon2"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={question1.text}
                                  onChange={updateQuestion1}
                                  name="text"
                                  placeholder="Question"
                                  aria-label="Question"
                                  aria-describedby="button-addon2"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon2"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</>
              }
             {
              (short_pitch ==='' && if_postive ==='' && if_nagative ==='')?null:<><div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button border accordbtn border-dark  collapsed "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-2-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                  </svg>
                  &nbsp; Pitch and Information
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body accord-grad text-light">
                  <blockquote className="blockquote">
                    <p className=" text-white">
                      {short_pitch}
                    </p>
                  </blockquote>

                  <br />
                  <div className="card bg-secondary text-dark">
                    <div className="card-body">
                      If Postive : <b>{if_postive}</b>
                      <hr />
                      If Negative : <b>{if_nagative}</b>
                    </div>
                  </div>
                  <br />

                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <button
                        type="button"
                        className="btn btn-transparent text-white"
                        disabled
                      >
                        Vote:
                      </button>
                      <div onChange={updataVoteData}>
                        <input
                          type="hidden"
                          value={(voteData.part2_vid = versionId2)}
                          name="part2_vid"
                        />
                        <input
                          type="radio"
                          className="btn-check"
                          value="up"
                          checked={voteData.part2}
                          name="part2"
                          id="part21"
                          autoComplete="off"
                          defaultChecked
                        />
                        <label className="btn btn-primary" htmlFor="part21">
                          <i className="bi bi-arrow-up-circle"></i>
                        </label>
                        &nbsp; &nbsp;
                        <input
                          type="radio"
                          className="btn-check"
                          value="down"
                          checked={voteData.part2}
                          name="part2"
                          id="part22"
                          autoComplete="off"
                          defaultChecked
                        />
                        <label className="btn btn-danger" htmlFor="part22">
                          <i className="bi bi-arrow-down-circle"></i>
                        </label>
                      </div>
                      &nbsp; &nbsp;
                      <button
                        className="btn btn-transparent text-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        More <i className="bi bi-caret-down-fill"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="accordion " id="accordionExample">
                    <div className="accordion-item bg-transparent">
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            <i className="bi bi-chat-left-text"></i> Write
                            your Suggestion
                          </p>
                          <form onSubmit={sendSuggestion2}>
                            <input
                              type="hidden"
                              className="form-control"
                              value={
                                (suggestion2.script_version_id = versionId2)
                              }
                              onChange={updateSuggestion2}
                              name="script_version_id"
                              placeholder="Suggestion"
                              aria-label="Suggestion"
                              aria-describedby="button-addon2"
                            />
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control  bg-dark text-white inside3"
                                value={suggestion2.text}
                                onChange={updateSuggestion2}
                                name="text"
                                placeholder="Suggestion"
                                aria-label="Suggestion"
                                aria-describedby="button-addon2"
                              />
                              <button
                                className="btn btn-light"
                                type="submit"
                                id="button-addon2"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                          <p>
                            <i className="bi bi-question-circle"></i> Add a
                            Question
                          </p>
                          <form onSubmit={sendQuestion2}>
                            <input
                              type="hidden"
                              className="form-control"
                              value={
                                (question2.script_version_id = versionId2)
                              }
                              onChange={updateQuestion2}
                              name="script_version_id"
                              placeholder="Question"
                              aria-label="Question"
                              aria-describedby="button-addon2"
                            />
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control bg-dark text-white inside3"
                                value={question2.text}
                                onChange={updateQuestion2}
                                name="text"
                                placeholder="Question"
                                aria-label="Question"
                                aria-describedby="button-addon2"
                              />
                              <button
                                className="btn btn-light"
                                type="submit"
                                id="button-addon2"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></>

             }
              
             {
              ask_questions == ''?null:<><div className="accordion-item">
              <h2
                className="accordion-header"
                id="panelsStayOpen-headingThree"
              >
                <button
                  className="accordion-button border accordbtn border-dark  collapsed "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-3-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                  </svg>
                  &nbsp; Consult - Ask Question and Talk
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body accord-grad text-light">
                  <blockquote
                    className="blockquote"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {ask_questions}
                  </blockquote>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <button
                        type="button"
                        className="btn btn-transparent text-white"
                        disabled
                      >
                        Vote:
                      </button>
                      <div onChange={updataVoteData}>
                        <input
                          type="hidden"
                          value={(voteData.part3_vid = versionId3)}
                          name="part3_vid"
                        />
                        <input
                          type="radio"
                          className="btn-check"
                          value="up"
                          checked={voteData.part3}
                          name="part3"
                          id="part31"
                          autoComplete="off"
                          defaultChecked
                        />
                        <label className="btn btn-primary" htmlFor="part31">
                          <i className="bi bi-arrow-up-circle"></i>
                        </label>
                        &nbsp; &nbsp;
                        <input
                          type="radio"
                          className="btn-check"
                          value="down"
                          checked={voteData.part3}
                          name="part3"
                          id="part32"
                          autoComplete="off"
                          defaultChecked
                        />
                        <label className="btn btn-danger" htmlFor="part32">
                          <i className="bi bi-arrow-down-circle"></i>
                        </label>
                      </div>
                      &nbsp; &nbsp;
                      <button
                        className="btn btn-transparent text-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        More <i className="bi bi-caret-down-fill"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="accordion " id="accordionExample">
                    <div className="accordion-item bg-transparent">
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            <i className="bi bi-chat-left-text"></i> Write
                            your Suggestion
                          </p>
                          <form onSubmit={sendSuggestion3}>
                            <input
                              type="hidden"
                              className="form-control "
                              value={
                                (suggestion3.script_version_id = versionId3)
                              }
                              onChange={updateSuggestion3}
                              name="script_version_id"
                              placeholder="Suggestion"
                              aria-label="Suggestion"
                              aria-describedby="button-addon2"
                            />
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control bg-dark text-white inside3"
                                value={suggestion3.text}
                                onChange={updateSuggestion3}
                                name="text"
                                placeholder="Suggestion"
                                aria-label="Suggestion"
                                aria-describedby="button-addon2"
                              />
                              <button
                                className="btn btn-light"
                                type="submit"
                                id="button-addon2"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                          <p>
                            <i className="bi bi-question-circle"></i> Add a
                            Question
                          </p>
                          <form onSubmit={sendQuestion3}>
                            <input
                              type="hidden"
                              className="form-control"
                              value={
                                (question3.script_version_id = versionId3)
                              }
                              onChange={updateQuestion3}
                              name="script_version_id"
                              placeholder="Question"
                              aria-label="Question"
                              aria-describedby="button-addon3"
                            />
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control bg-dark text-white inside3"
                                value={question3.text}
                                onChange={updateQuestion3}
                                name="text"
                                placeholder="Question"
                                aria-label="Question"
                                aria-describedby="button-addon3"
                              />
                              <button
                                className="btn btn-light"
                                type="submit"
                                id="button-addon3"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></>

             }
              {objection.length ===0?null:<><div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFour"
                >
                  <button
                    className="accordion-button border accordbtn border-dark  collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-4-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176c-.218.352-.438.703-.657 1.055ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z" />
                    </svg>
                    &nbsp; Objection Handling
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div className="accordion-body accord-grad text-light">
                    <blockquote className="blockquote">
                      {objection &&
                        objection?.map((item) => (
                          <div>
                            <strong>{item.question}</strong>
                            <br />
                            <br />
                            <p>{item.answer}</p>
                          </div>
                        ))}
                    </blockquote>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-transparent text-white"
                          disabled
                        >
                          Vote:
                        </button>
                        <div onChange={updataVoteData}>
                          <input
                            type="hidden"
                            value={(voteData.part4_vid = versionId4)}
                            name="part4_vid"
                          />
                          <input
                            type="radio"
                            className="btn-check"
                            value="up"
                            checked={voteData.part4}
                            name="part4"
                            id="part41"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-primary" htmlFor="part41">
                            <i className="bi bi-arrow-up-circle"></i>
                          </label>
                          &nbsp; &nbsp;
                          <input
                            type="radio"
                            className="btn-check"
                            value="down"
                            checked={voteData.part4}
                            name="part4"
                            id="part42"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-danger" htmlFor="part42">
                            <i className="bi bi-arrow-down-circle"></i>
                          </label>
                        </div>
                        &nbsp; &nbsp;
                        <button
                          className="btn btn-transparent text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          More <i className="bi bi-caret-down-fill"></i>
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="accordion " id="accordionExample">
                      <div className="accordion-item bg-transparent">
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              <i className="bi bi-chat-left-text"></i> Write
                              your Suggestion
                            </p>
                            <form onSubmit={sendSuggestion4}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (suggestion4.script_version_id = versionId4)
                                }
                                onChange={updateSuggestion4}
                                name="script_version_id"
                                placeholder="Suggestion"
                                aria-label="Suggestion"
                                aria-describedby="button-addon2"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={suggestion4.text}
                                  onChange={updateSuggestion4}
                                  name="text"
                                  placeholder="Suggestion"
                                  aria-label="Suggestion"
                                  aria-describedby="button-addon2"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon2"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                            <p>
                              <i className="bi bi-question-circle"></i> Add a
                              Question
                            </p>
                            <form onSubmit={sendQuestion4}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (question4.script_version_id = versionId4)
                                }
                                onChange={updateQuestion4}
                                name="script_version_id"
                                placeholder="Question"
                                aria-label="Question"
                                aria-describedby="button-addon4"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={question4.text}
                                  onChange={updateQuestion4}
                                  name="text"
                                  placeholder="Question"
                                  aria-label="Question"
                                  aria-describedby="button-addon4"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon4"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></> }
              
              {conclusion ===''?null:<div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button border accordbtn border-dark  collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-5-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z" />
                    </svg>
                    &nbsp; Call Ending (call to action)
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body accord-grad text-light">
                    <blockquote
                      className="blockquote"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {conclusion}
                      <br />
                      <br />
                    </blockquote>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-transparent text-white"
                          disabled
                        >
                          Vote:
                        </button>
                        <div onChange={updataVoteData}>
                          <input
                            type="hidden"
                            value={(voteData.part5_vid = versionId5)}
                            name="part5_vid"
                          />
                          <input
                            type="radio"
                            className="btn-check"
                            value="up"
                            checked={voteData.part5}
                            name="part5"
                            id="part51"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-primary" htmlFor="part51">
                            <i className="bi bi-arrow-up-circle"></i>
                          </label>
                          &nbsp; &nbsp;
                          <input
                            type="radio"
                            className="btn-check"
                            value="down"
                            checked={voteData.part5}
                            name="part5"
                            id="part52"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-danger" htmlFor="part52">
                            <i className="bi bi-arrow-down-circle"></i>
                          </label>
                        </div>
                        &nbsp; &nbsp;
                        <button
                          className="btn btn-transparent text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          More <i className="bi bi-caret-down-fill"></i>
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="accordion " id="accordionExample">
                      <div className="accordion-item bg-transparent">
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body ">
                            <p>
                              <i className="bi bi-chat-left-text"></i> Write
                              your Suggestion
                            </p>
                            <form onSubmit={sendSuggestion5}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (suggestion5.script_version_id = versionId5)
                                }
                                onChange={updateSuggestion5}
                                name="script_version_id"
                                placeholder="Suggestion"
                                aria-label="Suggestion"
                                aria-describedby="button-addon2"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={suggestion5.text}
                                  onChange={updateSuggestion5}
                                  name="text"
                                  placeholder="Suggestion"
                                  aria-label="Suggestion"
                                  aria-describedby="button-addon2"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon2"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                            <p>
                              <i className="bi bi-question-circle"></i> Add a
                              Question
                            </p>
                            <form onSubmit={sendQuestion5}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (question5.script_version_id = versionId5)
                                }
                                onChange={updateQuestion5}
                                name="script_version_id"
                                placeholder="Question"
                                aria-label="Question"
                                aria-describedby="button-addon5"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={question5.text}
                                  onChange={updateQuestion5}
                                  name="text"
                                  placeholder="Question"
                                  aria-label="Question"
                                  aria-describedby="button-addon5"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon5"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              {call_savior === ''?null:<div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingFive"
                >
                  <button
                    className="accordion-button border accordbtn border-dark  collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseSix"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Call Savior
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingFive"
                >
                  <div className="accordion-body accord-grad text-light">
                    <blockquote className="blockquote">
                      {call_savior}
                    </blockquote>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-transparent text-white"
                          disabled
                        >
                          Vote:
                        </button>
                        <div onChange={updataVoteData}>
                          <input
                            type="hidden"
                            value={(voteData.part6_vid = versionId6)}
                            name="part6_vid"
                          />
                          <input
                            type="radio"
                            className="btn-check"
                            value="up"
                            checked={voteData.part6}
                            name="part6"
                            id="part61"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-primary" htmlFor="part61">
                            <i className="bi bi-arrow-up-circle"></i>
                          </label>
                          &nbsp; &nbsp;
                          <input
                            type="radio"
                            className="btn-check"
                            value="down"
                            checked={voteData.part6}
                            name="part6"
                            id="part62"
                            autoComplete="off"
                            defaultChecked
                          />
                          <label className="btn btn-danger" htmlFor="part62">
                            <i className="bi bi-arrow-down-circle"></i>
                          </label>
                        </div>
                        &nbsp; &nbsp;
                        <button
                          className="btn btn-transparent text-white collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          More <i className="bi bi-caret-down-fill"></i>
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="accordion " id="accordionExample">
                      <div className="accordion-item bg-transparent">
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              <i className="bi bi-chat-left-text"></i> Write
                              your Suggestion
                            </p>
                            <form onSubmit={sendSuggestion6}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (suggestion6.script_version_id = versionId6)
                                }
                                onChange={updateSuggestion6}
                                name="script_version_id"
                                placeholder="Suggestion"
                                aria-label="Suggestion"
                                aria-describedby="button-addon2"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={suggestion6.text}
                                  onChange={updateSuggestion6}
                                  name="text"
                                  placeholder="Suggestion"
                                  aria-label="Suggestion"
                                  aria-describedby="button-addon2"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon2"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                            <p>
                              <i className="bi bi-question-circle"></i> Add a
                              Question
                            </p>
                            <form onSubmit={sendQuestion6}>
                              <input
                                type="hidden"
                                className="form-control"
                                value={
                                  (question6.script_version_id = versionId6)
                                }
                                onChange={updateQuestion6}
                                name="script_version_id"
                                placeholder="Question"
                                aria-label="Question"
                                aria-describedby="button-addon6"
                              />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control bg-dark text-white inside3"
                                  value={question6.text}
                                  onChange={updateQuestion6}
                                  name="text"
                                  placeholder="Question"
                                  aria-label="Question"
                                  aria-describedby="button-addon6"
                                />
                                <button
                                  className="btn btn-light"
                                  type="submit"
                                  id="button-addon6"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {(short_intro ==='' && confirmation ==='' && hero_line ==='' && ask_permission ==='' && if_yes ==='' && if_no ==='' && short_pitch ==='' && if_postive ==='' && if_nagative ==='' && ask_questions == '' && objection.length ===0 && conclusion ==='' && call_savior === '')?
              <div align='center'><br/><img
              src={emptyscript}  height={350}
            /> <p><br/>Call assistance not found</p></div>
              :null}
              <div></div>
              
            </div>
            </div>

            <ToastContainer />
          </div>
          <div className="col-12 col-sm-4">
            <div>
              <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <i className="bi bi-person-lines-fill"></i> Record
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="lead-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#lead"
                    type="button"
                    role="tab"
                    aria-controls="lead"
                    aria-selected="false"
                  >
                    <i className="bi bi-ticket-fill"></i> Lead
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <i className="bi bi-chat-left-dots-fill"></i> Questions
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <br />
                  <div className="container  border border-dark infocard shadow p-3 mb-5" style={{ backgroundColor: '#fcfcfb' }}>
                    {getdetails()}
                    {/* <div style={{ cursor: "pointer" }}>
                      <i className="bi bi-pen" /> Take a Note
                    </div> */}



                    <div className="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        <i className="bi bi-pen-fill" /> Take a note
                      </label>
                      <textarea
                        value={callData.description}
                        onChange={changeCallData}
                        name="description"
                        className="form-control border border-dark inside2"
                        id="exampleFormControlTextarea1"
                        rows="4"
                      ></textarea>
                    </div>



<>
  <nav>
    <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
      <button
        className="nav-link border-0 bg-transparent"
        id="nav-addcall-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-addcall"
        type="button"
        role="tab"
        aria-controls="nav-addcall"
        aria-selected="true"
      >
        <i className="bi bi-phone-fill"></i> Add Call <i className="bi bi-caret-down-fill"></i>
      </button>
      <button
        className="nav-link border-0 bg-transparent"
        id="nav-addtask-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-addtask"
        type="button"
        role="tab"
        aria-controls="nav-addtask"
        aria-selected="false"
      >
       <i className="bi bi-bicycle"></i> Add Task<i className="bi bi-caret-down-fill"></i>
      </button>
    </div>
  </nav>
  <div className="tab-content" id="nav-tabContent">
    <div
      className="tab-pane fade"
      id="nav-addcall"
      role="tabpanel"
      aria-labelledby="nav-addcall-tab"
    >
      <div className="card bg-light">
  <div className="card-body">
                                  <div className="row bg-transparent">
                                  <div align='right' className="mb-1 col-12 bg-transparent">
                                  <i className="bi bi-x-circle-fill" onClick={clearcalldata}></i>
                                    </div>
                                    

                                    <div className="mb-1 col-6">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-file-earmark-text-fill" />{" "}
                                        Callback type
                                      </label>
                                      <select
                                        value={
                                          addCallData.next_call_script_category
                                        }
                                        onChange={changeCallBackData}
                                        name="next_call_script_category"
                                        className="form-select mb-3 col-6 border border-primary inside"
                                        aria-label="Default select example"
                                        required
                                      >
                                        <option>Select Category</option>
                                        {feedOrgCategory &&
                                          feedOrgCategory?.map((item) => (
                                            <option value={item}>{item}</option>
                                          ))}
                                      </select>
                                    </div>

                                    <div className="mb-1 col-6 bg-transparent">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-alarm-fill" /> Callback time
                                
                                      </label>
                                      <input
                                        type="datetime-local"
                                        value={addCallData.call_again_date}
                                        onChange={changeCallBackData}
                                        name="call_again_date"
                                        className="form-control border border-primary inside"
                                        id="exampleFormControlInput1"
                                        placeholder="Call Back"
                                      />
                                    </div>
                                    <div className="mb-1 col-12">
                                      <div className="mb-1">
                                        <label
                                          for="exampleFormControlTextarea1"
                                          className="form-label"
                                        >
                                          <i className="bi bi-file-text-fill" />{" "}
                                          Description
                                        </label>
                                        <textarea
                                          value={addCallData.description}
                                          onChange={changeCallBackData}
                                          name="description"
                                          className="form-control border border-primary inside2"
                                          id="exampleFormControlTextarea1"
                                          rows="2"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="mb-1 col-12">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-people-fill" />{" "}
                                        Assign call
                                      </label>
                                      <select
                                        value={addCallData.next_call_user}
                                        onChange={changeCallBackData}
                                        name="next_call_user"
                                        className="form-select mb-3 col-6 border border-primary inside"
                                        aria-label="Default select example"
                                        required
                                      >
                                        <option>Select Person</option>
                                        {feed &&
                                          feed?.map((item) => (
                                            <option
                                              value={
                                                item.id + "," + item.user_name
                                              }
                                            >
                                              {item.user_name}-{item.role}
                                            </option>
                                          ))}
                                      </select>
                                    </div>

                                    <p><b>Note:</b> CallBack will be added when call is submitted.</p>
                                    

                                  </div>

                                </div>
                                {/* <button onClick={sendToCallData} type="button" className="btn btn-dark"><i className="bi bi-plus-circle"></i> Add </button> */}
                                </div>
    </div>
    <div
      className="tab-pane fade"
      id="nav-addtask"
      role="tabpanel"
      aria-labelledby="nav-addtask-tab"
    >
       <div className="card bg-light">
  <div className="card-body">
                                  
                                  <div className="row bg-transparent">
                                  <div align='right' className="mb-1 col-12 bg-transparent">
                                  <i className="bi bi-x-circle-fill" onClick={cleartaskdata}></i>
                                    </div>
                                  <div className="mb-1 col-6">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-file-earmark-text-fill" />{" "}
                                        Task category
                                      </label>
                                      <select
                                        value={taskData.taskHeading}
                                        onChange={changeTaskData}
                                        name="taskHeading"
                                        className="form-select mb-3 col-6 border border-success inside"
                                        aria-label="Default select example"
                                        required
                                      >
                                        <option>Select task</option>

                                        {feedOrgTask &&
                                          feedOrgTask?.map((item) => (
                                            <option value={item.heading}>
                                              {item.heading} ({item.description}
                                              )
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="mb-1 col-6 bg-transparent">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-alarm-fill" /> Due
                                        date
                                      </label>
                                      <input
                                        type="datetime-local"
                                        value={taskData.lastDate}
                                        onChange={changeTaskData}
                                        name="lastDate"
                                        className="form-control border border-success inside"
                                        id="exampleFormControlInput1"
                                        placeholder="Call Back"
                                      />
                                      <input
                                        type="hidden"
                                        value={
                                          (taskData.contact_number =
                                            callData.contact_number)
                                        }
                                        name="contact_number"
                                      />
                                      <input
                                        type="hidden"
                                        value={
                                          (taskData.email_address =
                                            callData.email_address)
                                        }
                                        name="email_address"
                                      />
                                      <input
                                        type="hidden"
                                        value={
                                          (taskData.customer_name =
                                            callData.customer_name)
                                        }
                                        name="customer_name"
                                      />
                                    </div>
                                    
                                    <div className="mb-1 col-12">
                                      <div className="mb-1">
                                        <label
                                          for="exampleFormControlTextarea1"
                                          className="form-label"
                                        >
                                          <i className="bi bi-file-text-fill" />{" "}
                                          Task description
                                        </label>
                                        <textarea
                                          value={taskData.description}
                                          onChange={changeTaskData}
                                          name="description"
                                          className="form-control border border-success inside2"
                                          id="exampleFormControlTextarea1"
                                          rows="2"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="mb-1 col-12">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-people-fill" />{" "}
                                        Assign task to
                                      </label>
                                      <select
                                        value={taskData.assineTO_userID}
                                        onChange={changeTaskData}
                                        name="assineTO_userID"
                                        className="form-select mb-3 col-6 border border-success inside"
                                        aria-label="Default select example"
                                        required
                                      >
                                        <option>Select Person</option>
                                        {feed &&
                                          feed?.map((item) => (
                                            <option
                                              value={
                                                item.id + "," + item.user_name
                                              }
                                            >
                                              {item.user_name}-{item.role}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                  <p><b>Note:</b> Task will be added when call is submitted.</p>
                                </div>
                                {/* <button onClick={sendTask} type="button" className="btn btn-dark"><i className="bi bi-plus-circle"></i> Add</button> */}
                                </div>
    </div>
  </div>
</>
<br/>


                  



                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button
                        onClick={() =>
                          openNav(callData.contact_number, "trackbtn")
                        }
                        className="btn pop border border-dark bg-white"
                        type="button"
                      >
                        <i className="bi bi-geo-fill" /> Track
                      </button>
                      <button
                        className="btn btn-danger pop me-md-2 collapsed border border-dark"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseCall"
                        aria-expanded="false"
                        aria-controls="collapseCall"
                      >
                        <i className="bi bi-telephone" /> Call Ended?
                      </button>
                    </div>
                    <div className="accordion-item bg-transparent border-0">
                      <div
                        id="collapseCall"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingCall"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>How was your call?</p>

                          <div className="row">
                            <div className="mb-1 col-12">
                              <select
                                value={callData.call_type}
                                onChange={changeCallData}
                                name="call_type"
                                className="form-select mb-3 border border-secondary inside"
                                aria-label="Default select example"
                                required
                              >
                                <option>Call Type </option>
                                <option value="Incoming">Incoming</option>
                                <option value="Outgoing">Outgoing</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            {callData.call_type != null ? (
                              <>
                                <div className="mb-1 col-6">
                                  <select
                                    value={callData.call_brief}
                                    onChange={changeCallDatacallBrif}
                                    name="call_brief"
                                    className="form-select mb-3 border border-secondary inside"
                                    aria-label="Default select example"
                                    required
                                  >
                                    <option>Select Call Brief </option>
                                    <option value="In Valid">
                                      Invalid Number
                                    </option>
                                    <option value="Cut Call">Cut Call</option>
                                    <option value="Didn't Pick">
                                      Didn't Pick
                                    </option>
                                    <option value="Call Later">
                                      Call Later
                                    </option>
                                    <option value="Interested">
                                      Interested
                                    </option>
                                    <option value="Not Interested">
                                      Not Interested
                                    </option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>

                                {/* <p>
                                  {callData.call_brief &&
                                    callTaskScript(callData.call_brief)}
                                </p> */}
                              </>
                            ) : null}

                            {callData.call_brief == "Interested" ||
                            callData.call_brief == "Not Interested" ||
                            callData.call_brief == "Other" ? (
                              <>
                                {" "}
                                <div className="mb-1 col-6">
                                  <select
                                    value={callData.call_status}
                                    onChange={changeCallData}
                                    name="call_status"
                                    className="form-select mb-3 col-6 border border-secondary inside"
                                    aria-label="Default select example"
                                    required
                                  >
                                    <option>Select Part</option>
                                    <option value="None">None</option>
                                    <option
                                      className="bg-primary text-white"
                                      value="Salute & Confirmation"
                                    >
                                      Salute & Confirmation
                                    </option>
                                    <option
                                      className="bg-danger text-white"
                                      value="Pitch and Information"
                                    >
                                      Pitch and Information
                                    </option>
                                    <option
                                      className="bg-warning text-dark"
                                      value="Ask Question & Talk"
                                    >
                                      Ask Question & Talk
                                    </option>
                                    <option
                                      className="bg-white text-dark"
                                      value="Objection Handling"
                                    >
                                      Objection Handling
                                    </option>
                                    <option
                                      className="bg-success text-white"
                                      value="Closing"
                                    >
                                      Call Closing
                                    </option>
                                  </select>
                                </div>
                              </>
                            ) : null}
                          </div>
                          {callData.call_brief == "Interested" ||
                          callData.call_brief == "Not Interested" ? (
                            <>
                              <p>How was the person?</p>
                              <div className="row">
                              {callData.clint_mood == "intense"?(<div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "intense"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="intense"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood1"
                                    autoComplete="off"
                                    
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood1">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-angry-fill text-primary"
                                        title="Intense"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>) 
                                  :<div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "intense"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="intense"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood1"
                                    autoComplete="off"
                                    
                                    
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood1">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-angry-fill"
                                        title="Intense"
                                      ></i>
                                    </h3>
                                  </label>
                                </div> }

                                {callData.clint_mood == "unhappy"?(<div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "unhappy"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="unhappy"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood2"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood2">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-frown-fill text-primary"
                                        title="Unhappy"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>) 
                                  :<div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "unhappy"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="unhappy"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood2"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood2">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-frown-fill"
                                        title="Unhappy"
                                      ></i>
                                    </h3>
                                  </label>
                                </div> }

                                {callData.clint_mood == "normal"?(<div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "normal"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="normal"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood3"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood3">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-smile-fill text-primary"
                                        title="Normal"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>) 
                                  : <div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "normal"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="normal"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood3"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood3">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-smile-fill"
                                        title="Normal"
                                      ></i>
                                    </h3>
                                  </label>
                                </div> }

                                {callData.clint_mood == "happy"?(<div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "happy"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="happy"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood4"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood4">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-laughing-fill text-primary"
                                        title="Happy"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>) 
                                  : <div className="col-3">
                                  <input
                                    type="hidden"
                                    value={callData.clint_mood == "happy"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="happy"
                                    checked={callData.clint_mood}
                                    onChange={changeCallData}
                                    name="clint_mood"
                                    id="clint_mood4"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood4">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-laughing-fill"
                                        title="Happy"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>}    
                                
                                
                                
                                
                              </div>
                              <br />
                            </>
                          ) : null}

                          {callData.call_brief !== "" ? (
                            <>
                              <div className="mb-1">
                                <label
                                  for="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-text-fill" />{" "}
                                  Description
                                </label>
                                <textarea
                                  value={callData.description}
                                  onChange={changeCallData}
                                  name="description"
                                  className="form-control border border-dark inside2"
                                  id="exampleFormControlTextarea1"
                                  rows="4"
                                ></textarea>
                              </div>
                              <br />
                            </>
                          ) : null}

                          {callData.call_brief != "" ? (
                            <div
                              className="accordion accordion-flush bg-transparent "
                              id="accordionFlushExample"
                            >
                              <div
                                className="accordion-item bg-dark bg-transparent"
                                id="callback"
                              >
                                
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                  <button
                                    onClick={() => sendContactData()}
                                    className="btn btn-dark border border-white pop"
                                    type="button"
                                  >
                                    <i className="bi bi-arrow-right" />
                                    &nbsp;Submit
                                  </button>
                                </div>
                                <br/>
                                {
                                  <p><b>Note:</b> This session will be added or merged with call log of integrated calling platfrom.</p>
                                  
                                }
                               
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <br />
                  <div
                    align="center"
                    className="p-3 mb-5 border border-dark infocard shadow" style={{ backgroundColor: '#fcfcfb' }}
                  >
                    <div className="row">
                      <br />
                      <p align="left" className="text-dark">
                        Question Assistance <i className="bi bi-mic-fill"></i>
                      </p>
                      <br />
                      <div className="col-12">
                        <br />
                        <div className="input-group mb-3 speech">
                          <input
                            type="text"
                            className="form-control border border-dark inside2"
                            value={searchData.questionText}
                            onChange={updataSearch}
                            name="questionText"
                            placeholder="Ask your question"
                            aria-label="Question"
                            aria-describedby="button-addon2"
                          />
                          <button
                            onClick={() => startDictation()}
                            className="btn text-danger border border-dark bg-dark inside"
                            type="button"
                            id="button-addon2"
                          >
                            <i className="bi bi-mic-fill" />
                          </button>
                          <button
                            onClick={searchQuestion}
                            className="btn text-white border border-dark bg-dark pop"
                            type="button"
                            id="button-addon2"
                          >
                            <i className="bi bi-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                     
                    </div>

                    <br />
                    <samp className="text-dark">{feedAnswer}</samp>
                    <br />
                    <hr className="text-secondary" />
                    <div className="list-group">
                      <p align="left" className="text-secondary">
                        Similar Questions:
                      </p>
                      {feedSimilarity &&
                        feedSimilarity?.map((item) => (
                          <a
                            href="#"
                            className="list-group-item list-group-item-action bg-dark border border-secondary m-1 rounded"
                          >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1 text-white">
                                {item.question}
                              </h5>
                              <small className="text-muted text-secondary">
                                {alterPercent(item.similarities) + "%"}
                              </small>
                            </div>
                            <p align="left" className="mb-1 text-white">
                              {item.answer}
                            </p>

                            <div
                              className="progress bg-secondary"
                              style={{ height: "10px" }}
                            >
                              <div
                                className="progress-bar progress-bar-striped bg-info text-dark progress-bar-animated"
                                role="progressbar"
                                style={{
                                  width: alterProgress(item.similarities) + "%",
                                }}
                                aria-valuenow={alterPercent(item.similarities)}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              >
                                {alterProgress(item.similarities)}%
                              </div>
                            </div>
                          </a>
                        ))}
                      <br />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="lead"
                  role="tabpanel"
                  aria-labelledby="lead-tab"
                >
                  <br />
                  <div
                    align="center"
                    className="p-2 mb-5 border border-dark infocard shadow" style={{ backgroundColor: '#fcfcfb' }}
                  >
                    
                    <p align="left" className="text-dark p-1"><b>
                        Lead details <i className="bi bi-arrow-down"></i></b>
                      </p>
                      {Object.keys(props).length != 0?<LeadDetails leadData={{TicketId :props.data.tid}} leadType={'callview'}/>:null }
                     
                   
                    <br/>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="timer">
          
        {/* <kbd><i className="bi bi-stopwatch"></i> {diff}</kbd> */}
         
        </div>
        <div id="bottomCall">
          <button
            type="button"
            onClick={() => clicktocall(callData.contact_number)}
            className="btn btn-dark rounded-circle"
          >
            <h4>
            <i className="bi bi-phone-vibrate"></i>
            </h4>
          </button>
        </div>
        <div id="bottomAI">
          <button
            type="button"
            onClick={() => sunday1()}
            className="btn btn-info rounded-circle"
          >
            <h4>
              <i className="bi bi-robot" />
            </h4>
          </button>
        </div>

        <div className="card w-25 chat-popup p-3 mb-5 border-0" id="sunday1">
          <div className="card-body">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <h3>
                <i
                  onClick={() => sunday1_back()}
                  className="bi bi-x-circle-fill"
                />
              </h3>
            </div>
            <h5 className="card-title">
              Sunday Suggestion <i className="bi bi-emoji-sunglasses"></i>
            </h5>
            <p className="card-text">
              {/* The highlighted text is very important, try to read it as it is. */}
              {callData.call_brief && callTaskScript(callData.call_brief)}
            </p>
          </div>
        </div>


        <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>

        <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              <div className="row">
                                <br/>
                               
                                </div>
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
      </div>
    );
  }
}

export default Call;
