import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router'

export default function LandingForm() {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var q = url.searchParams.get("q");
  var og = url.searchParams.get("og");
  const [leadform, setleadform] = useState({
    organisation_id: og,
    Contact_Source: q,
    customer_name: "",
    contact_number: "",
    email_address: "",
    description: ""
  });
  const navigate = useNavigate()

  function updateLeadForm(e) {
    e.preventDefault();
    setleadform({ ...leadform, [e.target.name]: e.target.value });
  }

  function leadSubmit(){

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "/Add_Contacts",leadform,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          navigate(0);
  
        }
       
      })
      .catch((err) => {
        console.log(err);
       
      });
  
  
   }  


   return(
    <div align='left'>
    <div className="row">
                <div className="col-6">
                <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label"><i className="bi bi-person-fill"></i> Name</label>
        <input type="text" name="customer_name" value={leadform.customer_name} onChange={updateLeadForm}  className="form-control" id="exampleFormControlInput1" placeholder="Raju Sharma" />
      </div>
                </div>
                <div className="col-6">
                <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label"><i className="bi bi-phone-fill"></i> Contact Number</label>
        <input type="number" name="contact_number" value={leadform.contact_number} onChange={updateLeadForm} className="form-control" id="exampleFormControlInput1" placeholder="9174492088" />
      </div>
                </div>
              </div>
              <div className="row">
              <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label"><i className="bi bi-envelope-fill"></i> Email address (optional)</label>
          <input type="email" name="email_address" value={leadform.email_address} onChange={updateLeadForm} className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
        </div><div>
          <label htmlFor="exampleFormControlTextarea1" className="form-label"><i className="bi bi-file-text-fill"></i> Description</label>
          <textarea name="description" value={leadform.description} onChange={updateLeadForm} className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
        </div>
              </div><br/> <div className="container">              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
             
                <button type="button" onClick={leadSubmit}  className="btn btn-dark btn-sm"><i className="bi bi-arrow-right"></i> Submit</button>
                </div>
                </div></div>
   );


}