import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import addsingle from "../media/undraw_personal_file_re_5joy.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getTasks,
  getScriptAll,
  getUserData,
  getAdminCard,
  getUserCard,
  getToCallAdmin,
  getToCallUser,
  getContactNumber,
  updateTasks,
  deleteContactToCall,
  addNewContact,
  generateNewFile,
  uploadConatctFile,
  addNewTask,
  todayCallAdmin,
  adminCallDues,
  getAllTaskAdmin,
  todayCallAdminsSriptCategory,
  getAllContact,
  getfollowContact,
  uploadCallRecoding,
  getAllTaskAdminFilter,
  pendingTodaysAdmintask,
  adminTaskDues,
  deteleCallRecord,
  getSourceListData,
} from "../redux/slices/dataSlice";
import {
  findContactDetails
} from "../redux/slices/homeSlice";
import { useSelector, useDispatch } from "react-redux";
import Modal from 'react-bootstrap/Modal';

const user_email = localStorage.getItem("user_email");
const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const joining_date = localStorage.getItem("joining_date");
const role = localStorage.getItem("role");
const admin = localStorage.getItem("admin");
const Auth = localStorage.getItem("Auth");
const user_id = localStorage.getItem("token");

const access_token = localStorage.getItem("Auth");

function SetTasks(props) {


  const dispatch = useDispatch();
  const [searchelement, setsearchelement] = useState({
    tocall: ""
  });

  const [alreadycustomer, setalreadycustomer] = useState(false);

  const [personsearchresult, setpersonsearchresult ] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [feedOrgTask, setOrgTask] = useState();

  const [details, setdetails] = useState(true);

  const [feeduser, setFeedUser] = useState();
  const [feedOrgCategory, setOrgCategory] = useState();
  const [sourceList, setSourceList] = useState([]);

  const [addsource, setAddscource] = useState("");

  const [feedSopCategory, setSopCategory] = useState();

  const [listsop, setlistsop] = useState([]);

  const [callData, setCallData] = useState({
    added_by_user: user_id,
    added_by_username: user_name,
    script_category: "",
    customer_name: "",
    contact_number: "",
    email_address: "",
    Contact_Source: "",
    call_brief: "",
    call_status: "",
    description: "",
    call_again_date: "",
    next_call_user: "",
    next_call_script_category: "",
    organisation_id: org,
    contact_search_at: "",
    call_started_at: "",
    call_ended_at: "",
    clint_mood: "",
    TicketId: props.ticket
  });


  const [taskData, setTaskData] = useState({
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: "",
    email_address: "",
    taskHeading: "",
    contact_number: "",
    description: "",
    lastDate: "",
    assineTO_userID: "",
    organisation_id: org,
    TicketId: props.ticket
  });
  const [addCallData, setaddCallData] = useState({
    organisation_id: org,
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: "",
    contact_number: "",
    email_address: "",
    description: "",
    next_call_user: "",
    Contact_Source: "",
    next_call_script_category: "",
    call_again_date: "",
    TicketId: props.ticket
  });


  const [formData, setFormData] = useState({
    contactPerson: '',
    organization: '',
    title: '',
    value: 0,
    currency: 'INR',
    pipeline: '',
    pipelineCurrentStage: '',
    expectedClosingDate: '',
    Lead_Source:'',
    owner: '',
    Description: '',
    visibleTo: 'All users',
    phone: [''],
    email: ['']
  });

  

    function updataSearchElement(e) {
    setsearchelement({ ...searchelement, [e.target.name]: e.target.value });
  }


    function SearchHome(){
    searchPerson( searchelement.tocall, 'to_search' );
  }

  function changeCallData(e) {
    setaddCallData({ ...addCallData, [e.target.name]: e.target.value });
  }

  function changeTaskData(e) {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
  }

  function changeInteration(e) {
    setCallData({ ...callData, [e.target.name]: e.target.value });
  }

  function changeInteration1(e) {
    setCallData({ ...callData, [e.target.name]: e.target.value });
    getCallDetails(callData.contact_number);
  }


  function masknumber(item){
    if( details == true){
      return item;
    
     }else{
      return item.slice(0, -3).concat('*** ');
     }
     } 
  

     const handleChange2 = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
  
      getSopProcess(value);
  
  
    };   

  ////////Api endpoints////////

  function searchPerson( ph, itemtype ) {

    let searchcall;

    if(itemtype == 'to_search'){

      searchcall =  {
        "to_search":ph,
        "customer_name":"",
        "contact_number":"",
        "email_address":""
    };

    }

    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/search_for_contact',searchcall, { headers })
      .then((data) => {
        if (data.status == "200") {

          setpersonsearchresult(data.data);
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
    
    
  }


    function setperson(item,type){

    if(type =='task'){

      
    setTaskData({
      added_by_user: user_id,
      added_by_username: user_name,
      customer_name: item.customer_name,
      email_address: item.customer_name,
      taskHeading: "",
      contact_number: item.contact_number,
      description: "",
      lastDate: "",
      assineTO_userID: "",
      organisation_id: org,
      TicketId: props.ticket
    });

    }else if(type =='call'){


      setaddCallData({
        organisation_id: org,
        added_by_user: user_id,
        added_by_username: user_name,
        customer_name: item.customer_name,
        contact_number: item.contact_number,
        email_address:  item.customer_name,
        description: "",
        next_call_user: "",
        Contact_Source: "",
        next_call_script_category: "",
        call_again_date: "",
        TicketId: props.ticket
    });


    }



  }

  function getAllSop() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setSopCategory(data.data);
        }
      })
      .catch((err) => console.log(err));
  }

  const handleAddSource = () => {
    if (addsource) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .post(`/Add_contactSource?Contact_Source=${addsource}`, {}, { headers })
        .then((data) => {
          if (data.status == "200") {
            setAddscource("");
            getSourceList();
            toast.success("Source Add Successfully!", {
              position: "bottom-left",
              autoClose: 1000,
            });
          }
        });
    }
  };


    function setStartDate(){
    const d = new Date();
    let currentTime = d.toISOString();
    callData.contact_search_at = currentTime; 
    callData.call_started_at = currentTime; 
  }

  function getCallDetails() {
    dispatch(
      findContactDetails({
        access_token,
        org,
        contact_number: callData.contact_number,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setalreadycustomer(true);
      } else {
        setalreadycustomer(false);
      }
    });
  }


   function sendContactData() {

    const d = new Date();
    let currentTime = d.toISOString();
    callData.call_ended_at = currentTime;

    let endpoint;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    
    if (alreadycustomer == true) {
      endpoint = "/update_call_details/";
    } else if (alreadycustomer == false) {
      endpoint = "/add_new_call/";
    }

    axios
      .post(endpoint, callData, { headers })
      .then((data) => {
        if (data.status == "200") {
         
         
         

          setCallData({
            added_by_user: user_id,
            added_by_username: user_name,
            script_category: "",
            customer_name: "",
            contact_number: "",
            email_address: "",
            Contact_Source: "",
            call_brief: "",
            call_status: "",
            description: "",
            call_again_date: "",
            next_call_user: "",
            next_call_script_category: "",
            organisation_id: org,
            call_type: "",
            contact_search_at: "",
            call_started_at: "",
            call_ended_at: "",
          });


          
        }
        
      })
      .catch((err) => console.log(err));
  }


  const getSourceList = () => {
    dispatch(getSourceListData({ access_token: Auth })).then((res) => {
      if (res.payload.status == "200") {
        setSourceList(res.payload.data);
      }
    });
  };

  function sendToCallData(e) {
    dispatch(
      addNewContact({
        access_token,
        data: addCallData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setaddCallData({
          organisation_id: org,
          added_by_user: user_id,
          added_by_username: user_name,
          customer_name: "",
          contact_number: "",
          email_address: "",
          description: "",
          Contact_Source: "",
          next_call_user: "",
          next_call_script_category: "",
          call_again_date: "",
        });
        document.getElementById("close").click();
        getToCallData();
      }
    });
  }

  function sendTask() {

    if(taskData.contact_number == ""){

      const min = 100000; // Minimum value (inclusive) - 100,000
      const max = 999999; // Maximum value (inclusive) - 999,999
  
      let digit2 = Math.floor(Math.random() * (max - min + 1)) + min;
        let temphone = '####' + digit2.toString();
       taskData.contact_number = temphone;

    }
    
    dispatch(
      addNewTask({
        access_token,
        data: taskData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setTaskData({
          added_by_user: user_id,
          added_by_username: user_name,
          customer_name: "",
          email_address: "",
          taskHeading: "",
          contact_number: "",
          description: "",
          lastDate: "",
          assineTO_userName: "",
          assineTO_userID: "",
          organisation_id: org,
        });
        document.getElementById("close1").click();
        document.getElementById("task-tab").click();
      }
    });
  }

  function getTask() {
    dispatch(
      getTasks({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setOrgTask(res.payload.data);
      }
    });
  }

  function getToCallData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    

    if (admin == "true") {
      dispatch(
        getToCallAdmin({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
        
        }
      });
    } else {
      dispatch(
        getToCallUser({
          access_token,
          org,
          userId: user_id,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
        
        }
      });
    }
  }


  function fetchUserData() {
    dispatch(
      getUserData({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setFeedUser(res.payload.data);
      }
    });
  }

  function getScript() {
    dispatch(
      getScriptAll({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setOrgCategory(res.payload.data);
      }
    });
  }

  function getSopProcess(item) {

    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };


      axios
        .get("/Get_sop_process?SopName=".concat(item), {
          headers,
        })
        .then((data) => {
          if (data.status == "200") {
            setlistsop(data.data);

            console.log(data.data);


            
          }
          
        })
        .catch((err) => {
          setlistsop([]);
         
        });

  }
  
  function update_lead(){

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios.post("/update_leads", formData, { headers }).then((data) => {
      if (data.status == "200") {

        toast.success("Lead updated successfully!", {
          position: "bottom-left",
          autoClose: 1000,
        });


        // Get all elements with the closeButton class
const closeButtons = document.getElementsByClassName('btn-close');

// Loop through each element with the closeButton class
for (let i = 0; i < closeButtons.length; i++) {
  // Add a click event listener to each element
  closeButtons[i].addEventListener('click', function() {
    // Perform actions when the element is clicked (this is optional)
    console.log('Close button clicked');
  });

  // Trigger a click event programmatically on each element
  closeButtons[i].click();
}


      }
    });

  }


  useEffect(() => {
    getTask();
    getScript();
    fetchUserData();
    setStartDate();
    getAllSop();
    setFormData(props.leadData);
    
  }, [1]);

  return (

<>

<Modal.Header closeButton>
          <Modal.Title>{props.typeData=='interaction'?<><i className="bi bi-phone"></i></>:<><i className="bi bi-alarm"></i></>} {props.typeData =='call'?<>Assign call</>:props.typeData=='task'?<>Assign task</>:props.typeData=='interaction'?<>Add interaction</>:props.typeData=='changesop'?<>Change SOP</>:null } </Modal.Title>
        </Modal.Header>
        <Modal.Body>

<div className={props.typeData=='interaction'?'d-flex justify-content-center':'d-flex'}>
<div className={props.typeData=='interaction'?"col-9":"col-12"}>        

{
  props.typeData=='call'?<>
  <div className="row">
                      <div className="col-5">
                        <br/><br/>
                      <img
                              src={addsingle}
                              height={350}
                            />
                      </div>
                      <div className="col-7">
                      <div className="row">
                            <div className="col-12">
                            <label for="exampleFormControlInput1" class="form-label">Existing contact?</label>
                            <div className="input-group mb-3 input-group-sm">      
        <input type="text" name='tocall'  value={searchelement.tocall}
                                    onChange={updataSearchElement} className="form-control border-secondary" placeholder="Recipient's Name/Number/Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={SearchHome} type="button" id="button-addon2"><i className="bi bi-search"></i></button>
      </div>
</div>
                            </div>
                            <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
                            <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item,'call')} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul></div>
                          <p align='center'>-or-</p>
           
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-person-circle"></i>  Customer Name
                              </label>
                              <input
                                name="customer_name"
                                value={addCallData.customer_name}
                                onChange={changeCallData}
                                type="text"
                                className="form-control form-control-sm border-secondary"
                                id="exampleFormControlInput1"
                                placeholder="Raju Sharma"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-telephone-fill"></i>  Phone Number
                              </label>
                              <input
                                name="contact_number"
                                value={addCallData.contact_number}
                                onChange={changeCallData}
                                type="text"
                                className="form-control form-control-sm border-secondary"
                                id="exampleFormControlInput1"
                                placeholder="8149910885"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-envelope-fill"></i>  Email Id
                              </label>
                              <input
                                name="email_address"
                                value={addCallData.email_address}
                                onChange={changeCallData}
                                type="email"
                                className="form-control form-control-sm border-secondary"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-person-fill"></i> Assign to
                              </label>
                              <select
                                value={addCallData.next_call_user}
                                onChange={changeCallData}
                                name="next_call_user"
                                className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Person</option>
                                {feeduser &&
                                  feeduser?.map((item) => (
                                    <option
                                      value={item.id + "," + item.user_name}
                                    >
                                      {item.user_name}-{item.role}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-file-text-fill"></i> Select Script
                              </label>
                              <select
                                value={addCallData.next_call_script_category}
                                onChange={changeCallData}
                                name="next_call_script_category"
                                className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Category</option>
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <option value={item.script_type_name}>
                                      {item.script_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-alarm-fill"></i> Schedule
                              </label>
                              <input
                                name="call_again_date"
                                value={addCallData.call_again_date}
                                onChange={changeCallData}
                                type="datetime-local"
                                className="form-control form-control-sm border-secondary"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p
                              className="collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do you have a source?
                              <i className="bi bi-caret-down-fill"></i>
                            </p>
                          </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-5">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select source
                                </label>
                                <select
                                  value={addCallData.Contact_Source}
                                  onChange={changeCallData}
                                  name="Contact_Source"
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item) => (
                                      <option value={item} selected>
                                        {item}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Add new source
                                  </label>
                                  <input
                                    name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    }
                                    className="form-control form-control-sm border-secondary"
                                    placeholder="Type new source"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{ marginTop: "30px" }}
                            >
                              <div className="mb-3">
                                <div className="mb-3">
                                  <button
                                    className="btn btn-light"
                                    disabled={!addsource}
                                    onClick={handleAddSource}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                             <i className="bi bi-card-text"></i> Description
                            </label>
                            <textarea
                              value={addCallData.description}
                              onChange={changeCallData}
                              name="description"
                              className="form-control form-control-sm border-secondary"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                     
                        <div className="modal-footer border-0">
                        <button
                            className="btn btn-dark "
                            onClick={sendToCallData}
                          >
                            Submit
                          </button>

                        </div>
                      </div>
                      </div></>
  :props.typeData=='task'?<>
  <div className="modal-body">
                      <div className="row bg-transparent">
                        <div className="row">
                          <div className="col-5">
                            <br />
                            <br />
                            <br />
                            <img
                              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/3.+Add+task.svg"
                              height={250}
                            />
                          </div>
                          <div className="col-7">
                            <div className="row">
                            <div className="col-12">
                            <label for="exampleFormControlInput1" class="form-label">Existing contact?</label>
                            <div className="input-group mb-3 input-group-sm">      
        <input type="text" name='tocall'  value={searchelement.tocall}
                                    onChange={updataSearchElement} className="form-control border-dark" placeholder="Recipient's Name/Number/Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={SearchHome} type="button" id="button-addon2"><i className="bi bi-search"></i></button>
      </div>
</div>
                            </div>
                            <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
                            <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item,'task')} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul></div>
                          <p align='center'>-or-</p>
                            
                            <div className="row">

                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-person-circle"></i> Customer Name
                                  </label>
                                  <input
                                    name="customer_name"
                                    value={taskData.customer_name}
                                    onChange={changeTaskData}
                                    type="text"
                                    className="form-control mb-3 col-6 border border-secondary form-control-sm"
                                    id="exampleFormControlInput1"
                                    placeholder="Raju Sharma"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-phone-fill"></i> Phone
                                    Number
                                  </label>
                                  <input
                                    name="contact_number"
                                    value={taskData.contact_number}
                                    onChange={changeTaskData}
                                    className="form-control form-control-sm mb-3 col-6 border border-secondary"
                                    id="exampleFormControlInput1"
                                    placeholder="8149910885"
                                  />
                                </div>
                              </div>

                              <div className="mb-1 col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-phone-fill"></i> Email
                                  </label>
                                  <input
                                    name="email_address"
                                    value={taskData.email_address}
                                    onChange={changeTaskData}
                                    className="form-control form-control-sm mb-3 col-6 border border-secondary"
                                    id="exampleFormControlInput1"
                                    placeholder="user@gmail.com"
                                  />
                                </div>
                              </div>
                              <div className="mb-1 col-6 bg-transparent">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-earmark-text-fill" /> Task Category:
                                </label>
                                <select
                                  name="taskHeading"
                                  value={taskData.taskHeading}
                                  onChange={changeTaskData}
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option>Select Task</option>

                                  {feedOrgTask &&
                                    feedOrgTask?.map((item) => (
                                      <option value={item.heading}>
                                        {item.heading} ({item.description})
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="mb-1 col-6">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-people-fill" /> Assign
                                  task to
                                </label>
                                <select
                                  name="assineTO_userID"
                                  value={taskData.assineTO_userID}
                                  onChange={changeTaskData}
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option>Select Person</option>
                                  {feeduser &&
                                    feeduser?.map((item) => (
                                      <option
                                        value={item.id + "," + item.user_name}
                                        key={item.id}
                                      >
                                        {item.user_name}-{item.role}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-1 col-6 bg-transparent">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-alarm-fill" /> Due Date
                                </label>
                                <input
                                  type="datetime-local"
                                  name="lastDate"
                                  value={taskData.lastDate}
                                  onChange={changeTaskData}
                                  className="form-control form-control-sm border border-secondary"
                                  id="exampleFormControlInput1"
                                  placeholder="Call Back"
                                />
                              </div>
                            </div>

                            <div className="mb-1 col-12">
                              <div className="mb-1">
                                <label
                                  for="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-text-fill" /> Task
                                  Description
                                </label>
                                <textarea
                                  name="description"
                                  value={taskData.description}
                                  onChange={changeTaskData}
                                  className="form-control form-control-sm border border-secondary"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        className="btn btn-dark"
                        onClick={sendTask}
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
  </>
  :props.typeData=='interaction'?
  
  <><div className="modal-body">
          <div className="row">
          <div className="mb-1 col-12">
          <select
      value={callData.contact_number}
      onChange={changeInteration}
      name="contact_number"
      className="form-select mb-3 border border-secondary inside"
      aria-label="Default select example"
      required
    >
      <option>Choose contact number</option>
     

      {props.leadData.phone &&
                          props.leadData.phone?.map((item, index) => (
                            <option value={item}>{item}</option>
                            ))}
    </select>

            </div>
            </div>
            {callData.contact_number !=''?<><div className="row">
  <div className="mb-1 col-6">
    <select
      value={callData.call_type}
      onChange={changeInteration1}
      name="call_type"
      className="form-select mb-3 border border-secondary inside"
      aria-label="Default select example"
      required
    >
      <option>Call Type</option>
      <option value="Incoming">Incoming</option>
      <option value="Outgoing">Outgoing</option>
    </select>
  </div>
  {callData.call_type != null ? (
    <><div className="mb-1 col-6">
    <select
      value={callData.script_category}
      onChange={changeInteration}
      name="script_category"
      className="form-select mb-3 border border-secondary inside"
      aria-label="Default select example"
      required
    >
       <option>Select Category</option>
              {feedOrgCategory &&
                feedOrgCategory?.map((item) => (
                  <option value={item.script_type_name}>{item.script_type_name}</option>
                ))}
    </select>
  </div></>
  ):null}
  
</div></> :null}  
            
<div className="row">
  {callData.script_category != '' ? (
    <>
      <div className="mb-1 col-6">
        <select
          value={callData.call_brief}
          onChange={changeInteration}
          name="call_brief"
          className="form-select mb-3 border border-secondary inside"
          aria-label="Default select example"
          required
        >
          <option>Select Call Brief </option>
          <option value="In Valid">
            Invalid Number
          </option>
          <option value="Cut Call">Cut Call</option>
          <option value="Didn't Pick">
            Didn't Pick
          </option>
          <option value="Call Later">
            Call Later
          </option>
          <option value="Interested">
            Interested
          </option>
          <option value="Not Interested">
            Not Interested
          </option>
          <option value="Other">Other</option>
        </select>
      </div>

      {/* <p>
        {callData.call_brief &&
          callTaskScript(callData.call_brief)}
      </p> */}
    </>
  ) : null}

{callData.call_brief == "Interested" ||
  callData.call_brief == "Not Interested" ||
  callData.call_brief == "Other" ? (
    <>
      {" "}
      <div className="mb-1 col-6">
        <select
          value={callData.call_status}
          onChange={changeInteration}
          name="call_status"
          className="form-select mb-3 col-6 border border-secondary inside"
          aria-label="Default select example"
          required
        >
          <option>Select Part</option>
          <option value="None">None</option>
          <option
            className="bg-primary text-white"
            value="Salute & Confirmation"
          >
            Salute & Confirmation
          </option>
          <option
            className="bg-danger text-white"
            value="Pitch and Information"
          >
            Pitch and Information
          </option>
          <option
            className="bg-warning text-dark"
            value="Ask Question & Talk"
          >
            Ask Question & Talk
          </option>
          <option
            className="bg-white text-dark"
            value="Objection Handling"
          >
            Objection Handling
          </option>
          <option
            className="bg-success text-white"
            value="Closing"
          >
            Call Closing
          </option>
        </select>
      </div>
    </>
  ) : null}
</div>

{callData.call_brief == "Interested" ||
callData.call_brief == "Not Interested" ? (
  <>
    <p>How was the person?</p>
    <div className="row">
    {callData.clint_mood == "intense"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "intense"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="intense"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood1"
          autoComplete="off"
          
          defaultChecked
        />
        <label htmlFor="clint_mood1">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-angry-fill text-primary"
              title="Intense"
            ></i>
          </h3>
        </label>
      </div>) 
        :<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "intense"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="intense"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood1"
          autoComplete="off"
          
          
          defaultChecked
        />
        <label htmlFor="clint_mood1">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-angry-fill"
              title="Intense"
            ></i>
          </h3>
        </label>
      </div> }

      {callData.clint_mood == "unhappy"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "unhappy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="unhappy"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood2"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood2">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-frown-fill text-primary"
              title="Unhappy"
            ></i>
          </h3>
        </label>
      </div>) 
        :<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "unhappy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="unhappy"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood2"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood2">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-frown-fill"
              title="Unhappy"
            ></i>
          </h3>
        </label>
      </div> }

      {callData.clint_mood == "normal"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "normal"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="normal"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood3"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood3">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-smile-fill text-primary"
              title="Normal"
            ></i>
          </h3>
        </label>
      </div>) 
        : <div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "normal"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="normal"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood3"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood3">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-smile-fill"
              title="Normal"
            ></i>
          </h3>
        </label>
      </div> }

      {callData.clint_mood == "happy"?(<div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "happy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="happy"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood4"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood4">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-laughing-fill text-primary"
              title="Happy"
            ></i>
          </h3>
        </label>
      </div>) 
        : <div className="col-3">
        <input
          type="hidden"
          value={callData.clint_mood == "happy"}
          name="clint_mood"
        />
        <input
          type="radio"
          className="btn-check"
          value="happy"
          checked={callData.clint_mood}
          onChange={changeInteration}
          name="clint_mood"
          id="clint_mood4"
          autoComplete="off"
          defaultChecked
        />
        <label htmlFor="clint_mood4">
          <h3 className="emojiChange">
            <i
              className="bi bi-emoji-laughing-fill"
              title="Happy"
            ></i>
          </h3>
        </label>
      </div>}    
      
      
      
      
    </div>
    <br />
  </>
) : null}

{callData.call_brief !== "" ? (
  <>
    <div className="mb-1">
      <label
        for="exampleFormControlTextarea1"
        className="form-label"
      >
        <i className="bi bi-file-text-fill" />{" "}
        Description
      </label>
      <textarea
        value={callData.description}
        onChange={changeInteration}
        name="description"
        className="form-control border border-white inside2"
        id="exampleFormControlTextarea1"
        rows="3"
      ></textarea>
    </div>
    <br />
  </>
) : null}


{callData.call_brief != "" ? (
  <div
    className="accordion accordion-flush bg-transparent "
    id="accordionFlushExample"
  >
    <div
      className="accordion-item bg-dark bg-transparent"
      id="callback"
    >
      
      <div className="d-grid gap-2 d-md-flex justify-content-md-start">
        <button
          onClick={() => sendContactData()}
          className="btn btn-dark pop"
          type="button"
        >
          <i className="bi bi-arrow-right" />
          &nbsp;Submit
        </button>
      </div>
      <br/>
      
     
    </div>
  </div>
) : null}



  




  </div></>
  :props.typeData=='changesop'?<>
  <div className="row">
  <div className="col-5">
  <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">
    Current SOP
  </label>
  <h4>{props.leadData.pipeline}</h4> 

</div>

  </div>
  <div className="col-2" align='center'>
  <div className="mt-3">
  <h2><i className="bi bi-arrow-right"></i></h2>
  </div>
  </div>
  <div className="col-5">

  <div className="mb-3">
  <select className="form-select"
   name="pipeline"
   value={formData.pipeline}
   onChange={handleChange2}
  aria-label="Default select example">
  <option value='' selected="">Choose pipeline</option>
  {feedSopCategory &&
                        feedSopCategory?.map((item) => (
                          <option value={item}>{item}</option>
                          ))}
</select>

</div>
{formData.pipeline != null?<div className="mb-3">
  <select className="form-select" aria-label="Default select example">
  <option selected="">Pipeline stage</option>
  {listsop &&
                                    listsop?.map((item) => (
                                      <option value={item.process_catagory}>{item.process_catagory}</option>
                                      ))}
</select>

</div>

:null}

<button onClick={update_lead} className="btn btn-dark btn-sm">Submit</button>



  </div>
  </div>
  </>
  
  :null
}

</div>
</div>


        </Modal.Body>


</>
   
  );
}

export default SetTasks;
