import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import cs from "../media/cs.png";
import empty from "../media/empty.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { func } from "prop-types";
import EditableTextField from "./editableTextField";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";

const org = localStorage.getItem("org");
const organisation_name = localStorage.getItem("organisation_name");
const user_name = localStorage.getItem("user_name");
const user_id = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");

function Questions() {
  const [errorMessage, setErrorMessage] = useState("");
  const [feedQuestions, setFeedQuestions] = useState("");

  const [updateAns, setUpdateAns] = useState(null);

  const [inputFields, setInputFields] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  const obj = {
    organisation_id: org,
    added_by_userId: user_id,
    added_by_username: user_name,
    questionsSet: inputFields,
  };

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        question: "",
        answer: "",
      },
    ]);
  };

  let removeInputFields = (i) => {
    let rows = [...inputFields];
    rows.splice(i, 1);
    setInputFields(rows);
  };

  let handleChange = (i, e) => {
    let rows = [...inputFields];
    rows[i][e.target.name] = e.target.value;
    setInputFields(rows);
  };

  function submitTask() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/setup_questions_answer/", obj, { headers })
      .then((data) => {
        if (data.status == "200") {
          window.location.reload(false);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getQuestions() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_all_questions?organization_id=".concat(org), { headers })
      .then((data) => {
        if (data.status == "200") {

          setFeedQuestions(data.data);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function alterElement(item) {
    var datetime = item.datetime;
    datetime = datetime.substring(0, 16);
    datetime = datetime.replace("T", " ");
    return datetime;
  }

  function onBlurUpdateAnswer(data, ans) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post(
        "/Answers?question_id=".concat(data.question_id, "&answers=", ans),
        {},
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getQuestions();
  }, [1]);

  return (
    <>
      <div className="container">
     
        <div className="row">
          <div className="col-12 col-sm-5">
            <br/>
            <h5>
               Add Questions
            </h5>
            

            {inputFields?.map((element, index) => {
              return (
                <div className="row my-3" key={index}>
                  <div className="col-sm-10">
                    <div className="form-group text-info">
                      <input
                        type="text"
                        onChange={(e) => handleChange(index, e)}
                        value={element.question || ""}
                        name="question"
                        className="form-control border border-secondary"
                        placeholder="Question"
                      />
                    </div>
                    <br />
                    <div className="form-group text-info">
                      <input
                        type="text"
                        onChange={(e) => handleChange(index, e)}
                        value={element.answer || ""}
                        name="answer"
                        className="form-control  border border-secondary"
                        placeholder="Answer"
                      />
                    </div>
                  </div>

                  <div className="col">
                    {inputFields.length !== 1 ? (
                      <button
                        className="btn btn-danger"
                        onClick={removeInputFields}
                      >
                        x
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col-sm-12">
                <button
                  className="btn btn-dark "
                  onClick={() => addInputField()}
                >
                  <i className="bi bi-plus-circle"></i> Add
                </button>
                &nbsp;
                <button
                  className="btn btn-primary"
                  onClick={() => submitTask()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-7">
            <br/>
            <div className="container">    <h4>Questions Answered</h4>
            <p>Add questions and answers that your representatives can come accross.</p>
            </div>
        
            <div
                  className="overflow-auto p-3"
                  style={{ maxWidth: "1000px", maxHeight: "550px" }}
                >
            <div className="list-group">
              
                {feedQuestions &&
                  feedQuestions?.map((item, index) => (
                    <div>
                        <div className="list-group-item list-group-item-action" aria-current="true">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1"><i className="bi bi-question-circle-fill"></i> {item.text}</h5>
          <small> <i className="bi bi-person-circle"></i> <b>{item.ask_by_username}</b></small>
        </div>
        <EditableTextField
      data={item}
      handleBlur={onBlurUpdateAnswer}
      keyname="answer"
      placeholder="Answer"

    />
        <small><i className="bi bi-calendar-event"></i> {alterElement(item)}</small>
      </div>
                    </div>
                  ))}
              
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Questions;
