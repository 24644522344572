import React, { Component, useEffect, useState } from "react";
import axios from "axios";

const organisation_name = localStorage.getItem("organisation_name");
const organisation_id = localStorage.getItem("org");
const org = localStorage.getItem("org");
const Auth = localStorage.getItem("Auth");

function Setup() {
  //get organisation data
  const [feedOrg, setOrgFeed] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");

  const [orgData, setOrgData] = useState({
    organisation_name: organisation_name,
    organisation_type: "",
    organisation_category: "",
    total_employees: "",
    gst: "",
    state: "",
    pin_code: "",
    billing_address: "",
  });

  function sendOrgData(e) {
    setOrgData({ ...orgData, [e.target.name]: e.target.value });
  }

  const [status, updatestatus] = useState("0");

  useEffect(() => {
    fetchOrgData();
  }, []);

  function fetchOrgData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_organisation?organisation_id=".concat(organisation_id), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          if (data.data.organisation_category == " ") {
            updatestatus(30);
          } else {
            window.location.replace("https://dashboard.salessunday.com/");
          }
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function sendOData(e) {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .post("/organisation/", orgData, { headers })
      .then((data) => {
        if (data.status == "200") {
          window.location.replace("https://dashboard.salessunday.com/");
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  if (status == "30") {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="30"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "30%" }}
              >
                30%
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <br />
            <form onSubmit={sendOData}>
              <div className="row">
                <div className="mb-3 col-8">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Organisation Name
                  </label>
                  <input
                    type="text"
                    value={organisation_name}
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Sales Sunday"
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-6 ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Organisation Type
                  </label>
                  <input
                    className="form-control"
                    value={orgData.organisation_type}
                    onChange={sendOrgData}
                    name="organisation_type"
                    list="datalistType"
                    id="exampleDataList"
                    placeholder="Type to search..."
                  ></input>
                  <datalist id="datalistType">
                    <option value="Companies Limited by Shares">
                      Companies Limited by Shares
                    </option>
                    <option value="One Person Companies (OPC)">
                      One Person Companies (OPC)
                    </option>
                    <option value="Private Companies">Private Companies</option>
                    <option value="Public Companies">Public Companies</option>
                    <option value="Holding and Subsidiary Companies">
                      Holding and Subsidiary Companies
                    </option>
                    <option value="Associate Companies">
                      Associate Companies
                    </option>
                    <option value="Government Companies">
                      Government Companies
                    </option>
                    <option value="Proprietary">Proprietary</option>
                    <option value="Limited Liability Partnership(LLP)">
                      Limited Liability Partnership(LLP)
                    </option>
                    <option value="Company Branch">Company Branch</option>
                  </datalist>
                </div>
                <div className="mb-3 col-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Category of Working
                  </label>
                  <input
                    className="form-control"
                    value={orgData.organisation_category}
                    onChange={sendOrgData}
                    name="organisation_category"
                    list="datalistCategory"
                    id="exampleDataList"
                    placeholder="Type to search..."
                  ></input>
                  <datalist id="datalistCategory">
                    <option value="Aerospace">Aerospace</option>
                    <option value="Transport">Transport</option>
                    <option value="Computer">Computer</option>
                    <option value="Telecommunication">Telecommunication</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Construction">Construction</option>
                    <option value="Education">Education</option>
                    <option value="Pharmaceutical">Pharmaceutical</option>
                    <option value="Food">Food</option>
                    <option value="Health care">Health care</option>
                    <option value="Hospitality">Hospitality</option>
                    <option value="Entertainment">Entertainment</option>
                    <option value="News Media">News Media</option>
                    <option value="Energy">Energy</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Music">Music</option>
                    <option value="Mining">Mining</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Software">Software</option>
                  </datalist>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Total Employees{" "}
                  </label>
                  <input
                    type="text"
                    value={orgData.total_employees}
                    onChange={sendOrgData}
                    name="total_employees"
                    className="form-control"
                    id="exampleFormControlInput1"
                  />
                </div>
                <div className="mb-3 col-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    GST (optional)
                  </label>
                  <input
                    type="text"
                    value={orgData.gst}
                    onChange={sendOrgData}
                    name="gst"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="GST Number"
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-6">
                  <label htmlFor="exampleDataList" className="form-label">
                    State
                  </label>
                  <input
                    className="form-control"
                    value={orgData.state}
                    onChange={sendOrgData}
                    name="state"
                    list="datalistOptions"
                    id="exampleDataList"
                    placeholder="Type to search..."
                  ></input>
                  <datalist id="datalistOptions">
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadar and Nagar Haveli">
                      Dadar and Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </datalist>
                </div>
                <div className="mb-3 col-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Pin Code
                  </label>
                  <input
                    type="number"
                    value={orgData.pin_code}
                    onChange={sendOrgData}
                    name="pin_code"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="122002"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Billing Address
                </label>
                <input
                  type="text"
                  value={orgData.billing_address}
                  onChange={sendOrgData}
                  name="billing_address"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="21 jump street, California"
                />
              </div>
              <button type="submit" className="btn btn-dark">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "0%" }}
              >
                0%
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <br />
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Setup;
