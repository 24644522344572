/* eslint-disable no-undef */
import React from "react";
import {
  getSourceListData,
  deleteSourceList,
  changeShareType,
  getSourceListAllData,
} from "../redux/slices/dataSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ShareLink from "./shareLink";
import manageSource from "../media/undraw_data_extraction_re_0rd3.svg";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from "axios";

const org = localStorage.getItem("org");
const user_name = localStorage.getItem("user_name");
const user_id = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");


export default function ManageSouce({
  heading,
  description,
  method,
  item,
  modelid,
  trashData,
}) {
  const dispatch = useDispatch();
  const [sourceList, setSourceList] = useState([]);
  const [open, setOpen] = useState(false);
  const getSourceList = () => {
    dispatch(getSourceListAllData({ access_token: Auth })).then((res) => {
      if (res.payload.status == "200") {
        setSourceList(res.payload.data);
      }
    });
  };
  const [addsource, setAddscource] = useState("");
  useEffect(() => {
    getSourceList();
  }, []);

  const deleteManageSource = (item) => {
    dispatch(
      deleteSourceList({ access_token: Auth, source: item, value: true })
    ).then((res) => {
      if (res.payload.status == "200") {
        getSourceList();
        toast.success(res.payload.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
      }
    });
  };

  const changeShareLinkActiveDeavtive = (item, value) => {
    dispatch(
      changeShareType({ access_token: Auth, source: item, value: value })
    ).then((res) => {
      if (res.payload.status == "200") {
        getSourceList();
        toast.success(res.payload.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
      }
    });
  };


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function scriptStatus(item) {
    const script_status = item.isactivate;

    if (script_status == false) {
      return (
        <i
          className="i bi-toggle2-off"
          style={{ cursor: "pointer" }}
          onClick={() => changeStatus(item.script_type_name)}
        ></i>
      );
    } else if (script_status == true) {
      return (
        <i
          className="i bi-toggle2-on"
          style={{ cursor: "pointer" }}
          onClick={() => changeStatus(item.script_type_name)}
        ></i>
      );
    }
  }

  function addsourcelist(){

    if (addsource) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .post(`/Add_contactSource?Contact_Source=${addsource}`, {}, { headers })
        .then((data) => {
          if (data.status == "200") {
            setAddscource("");
            getSourceList();
            toast.success("Source Add Successfully!", {
              position: "bottom-left",
              autoClose: 1000,
            });
            getSourceList();

          }
        });
    }

  }

  const generteShareLink = (item) => {
    console.log("item", item);
    console.log(URL);
  };

  return (
    <div>
      <div
        className="modal fade"
        id={modelid}
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title" id="exampleModalLabel">
              <i className="bi bi-bezier2"></i> Manage Source
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {" "}
              <div className="row">
                <div className="col-6">
                <img src={manageSource} height={250} />
                &nbsp;&nbsp;<mark>
                     Add source
                    categories <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Categorize & Manage your leads sent by diffrent sources. eg: Facebook, Rahul DSA, Website')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                  <br/>
                <div className="row">
                <div className="input-group mb-3 p-3">
        <input type="text" className="form-control" name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    } placeholder="Add source" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn bg-dark text-white" onClick={addsourcelist} type="button" id="button-addon2">Submit</button>
      </div>
                  </div>  
                </div>
                <div className="col-6">
                

                &nbsp;&nbsp;<mark>
                     All source
                    categories <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Categorize & Manage your leads sent by diffrent sources. eg: Facebook, Rahul DSA, Website')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                  </mark>
                  <div
                className="overflow-auto p-3"
                style={{ maxWidth: "1000px", maxHeight: "700px" }}
              >
                
                  <ol className="list-group">
                    {sourceList &&
                      sourceList?.map((item, index) => (
                        <>
                          <ShareLink
                            index={index}
                            item={item}
                            deleteManageSource={deleteManageSource}
                            changeShareLinkActiveDeavtive={
                              changeShareLinkActiveDeavtive
                            }
                            generteShareLink={generteShareLink}
                          />
                        </>
                      ))}
                  </ol>
                
              </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
