import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const Auth = localStorage.getItem("Auth");

export const getrolepermission = createAsyncThunk(
  "getrolepermission",
  async ({ access_token }, { rejectWithValue }) => {
    try {
      return await axios.get(`/getrolepermission`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${Auth}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  } 

);

const rolePermissionSlice = createSlice({
  name: "rolePermission",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getrolepermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getrolepermission.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getrolepermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default rolePermissionSlice.reducer;