import React from 'react';

export default () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside>
      <div className="description ">You can drag these nodes</div>
      <div className='row'> <div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'New Lead')} draggable>
    <div className="card-body"><i className="bi bi-person"></i> New lead</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'New Call')} draggable>
    <div className="card-body"><i className="bi bi-telephone"></i> New call</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'New Task')} draggable>
    <div className="card-body"><i className="bi bi-bicycle"></i> New task</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Update Lead')} draggable>
    <div className="card-body"><i className="bi bi-pencil-square"></i> Update lead</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Update Call')} draggable>
    <div className="card-body"><i className="bi bi-pencil-square"></i> Update call</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Update Task')} draggable>
    <div className="card-body"><i className="bi bi-pencil-square"></i> Update task</div>
  </div>
</div>


<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Send Email')} draggable>
    <div className="card-body"><i className="bi bi-envelope"></i> Send Email</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Set Call')} draggable>
    <div className="card-body"><i className="bi bi-telephone-outbound"></i> Set Call</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'create Task')} draggable>
    <div className="card-body"><i className="bi bi-plus-square"></i> Create Task</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Send SMS')} draggable>
    <div className="card-body"><i className="bi bi-chat-dots"></i> Send SMS</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Send Whatsapp')} draggable>
    <div className="card-body"><i className="bi bi-whatsapp"></i> Send Whatsapp</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Wait')} draggable>
    <div className="card-body"><i className="bi bi-hourglass-split"></i> Wait</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'If Condition')} draggable>
    <div className="card-body"><i className="bi bi-question-circle"></i> If Condition</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Assign Lead')} draggable>
    <div className="card-body"><i className="bi bi-person-plus"></i> Assign Lead</div>
  </div>
</div>


<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Add Note')} draggable>
    <div className="card-body"><i className="bi bi-sticky"></i> Add Note</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Webhook')} draggable>
    <div className="card-body"><i className="bi bi-globe"></i> Webhook</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Distribute Lead')} draggable>
    <div className="card-body"><i className="bi bi-people"></i> Distribute Lead</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Set Field Visit')} draggable>
    <div className="card-body"><i className="bi bi-map"></i> Set Field Visit</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Send Notification')} draggable>
    <div className="card-body"><i className="bi bi-bell"></i> Send Notification</div>
  </div>
</div>



<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Escalate Case')} draggable>
    <div className="card-body"><i className="bi bi-arrow-up-circle"></i> Escalate Case</div>
  </div>
</div>

<div className='col-6 p-1'>
  <div className="card hand shadow" onDragStart={(event) => onDragStart(event, 'Close Deal')} draggable>
    <div className="card-body"><i className="bi bi-check-circle"></i> Close Deal</div>
  </div>
</div>
</div>
         <br/><br/>
    </aside>
  );
};
